import { RepositoryService } from '@get/services/repository';
import { SocieteProfil } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteProfilApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteProfils(params?: any): Observable<SocieteProfil[]> {
    return this.repo.getData<SocieteProfil[]>('societe-profil', params);
  }

  public getSocieteProfil(params: { idSocieteProfil: number; params?: any }): Observable<SocieteProfil> {
    return this.repo.getData<SocieteProfil>('societe-profil/' + params.idSocieteProfil, params.params);
  }

  public addSocieteProfil(societeProfil: Partial<SocieteProfil>): Observable<SocieteProfil> {
    return this.repo.create<SocieteProfil>('societe-profil', societeProfil);
  }

  public updateSocieteProfil(societeProfil: Partial<SocieteProfil>): Observable<SocieteProfil> {
    return this.repo.update('societe-profil', societeProfil);
  }

  public deleteSocieteProfil(idSocieteProfil: number): Observable<number> {
    return this.repo.delete('societe-profil/' + +idSocieteProfil);
  }
}

