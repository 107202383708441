import { RepositoryService } from '@get/services/repository';
import { UserPatrimoine } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUserPatrimoineApiService {
  constructor(protected repo: RepositoryService) {}

  public getUserPatrimoines(params?: any): Observable<UserPatrimoine[]> {
    return this.repo.getData<UserPatrimoine[]>('user-patrimoine', params);
  }

  public getUserPatrimoine(params: { idUserPatrimoine: number; params?: any }): Observable<UserPatrimoine> {
    return this.repo.getData<UserPatrimoine>('user-patrimoine/' + params.idUserPatrimoine, params.params);
  }

  public addUserPatrimoine(userPatrimoine: Partial<UserPatrimoine>): Observable<UserPatrimoine> {
    return this.repo.create<UserPatrimoine>('user-patrimoine', userPatrimoine);
  }

  public updateUserPatrimoine(userPatrimoine: Partial<UserPatrimoine>): Observable<UserPatrimoine> {
    return this.repo.update('user-patrimoine', userPatrimoine);
  }

  public deleteUserPatrimoine(idUserPatrimoine: number): Observable<number> {
    return this.repo.delete('user-patrimoine/' + +idUserPatrimoine);
  }
}

