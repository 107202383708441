import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteTerritoirePatrimoine, SocieteTerritoirePatrimoineEntityState } from '@get/api-interfaces';
import { SocieteTerritoirePatrimoineApiService } from '@get/store/api-services';
import { SocieteTerritoirePatrimoineGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteTerritoirePatrimoine } from '@get/store/configs/normalization';
import { SocieteTerritoirePatrimoineSelectors } from '@get/store/selectors';
import { SocieteTerritoirePatrimoineRelationsIds } from '@get/store/ids-interfaces';
import { SocieteTerritoireGeneratedActions } from '@get/store/actions';
import { PatrimoineGeneratedActions } from '@get/store/actions';

export function getDefaultAddSocieteTerritoirePatrimoineActions(societeTerritoirePatrimoine: SocieteTerritoirePatrimoineEntityState, ids?: SocieteTerritoirePatrimoineRelationsIds): Action[] {
  const actions: Action[] = [SocieteTerritoirePatrimoineGeneratedActions.normalizeManySocieteTerritoirePatrimoinesAfterUpsert({ societeTerritoirePatrimoines: [societeTerritoirePatrimoine] })];

  if (ids?.societeTerritoire) {
    actions.push(
      SocieteTerritoireGeneratedActions.addManySocieteTerritoirePatrimoineSuccess({
        idSocieteTerritoire: ids.societeTerritoire,
        idSocieteTerritoirePatrimoines: [societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine]
      })
    );
    actions.push(
      SocieteTerritoirePatrimoineGeneratedActions.addSocieteTerritoireSuccess({
        idSocieteTerritoirePatrimoine: societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine,
        idSocieteTerritoire: ids.societeTerritoire
      })
    );
  }

  if (ids?.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.addManySocieteTerritoirePatrimoineSuccess({
        idPatrimoine: ids.patrimoine,
        idSocieteTerritoirePatrimoines: [societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine]
      })
    );
    actions.push(
      SocieteTerritoirePatrimoineGeneratedActions.addPatrimoineSuccess({
        idSocieteTerritoirePatrimoine: societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine,
        idPatrimoine: ids.patrimoine
      })
    );
  }

  return actions;
}

export function getDefaultDeleteSocieteTerritoirePatrimoineActions(societeTerritoirePatrimoine: SocieteTerritoirePatrimoineEntityState): Action[] {
  const actions: Action[] = [SocieteTerritoirePatrimoineGeneratedActions.deleteOneSocieteTerritoirePatrimoineSuccess({ idSocieteTerritoirePatrimoine: societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine })];

  if (societeTerritoirePatrimoine.societeTerritoire) {
    actions.push(
      SocieteTerritoireGeneratedActions.deleteManySocieteTerritoirePatrimoineSuccess({
        idSocieteTerritoirePatrimoines: [societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine],
        idSocieteTerritoires: [societeTerritoirePatrimoine.societeTerritoire as number]
      })
    );
  }

  if (societeTerritoirePatrimoine.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.deleteManySocieteTerritoirePatrimoineSuccess({
        idSocieteTerritoirePatrimoines: [societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine],
        idPatrimoines: [societeTerritoirePatrimoine.patrimoine as number]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteTerritoirePatrimoineEffects {
  constructor(
    protected actions$: Actions,
    protected societeTerritoirePatrimoineApiService: SocieteTerritoirePatrimoineApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocieteTerritoirePatrimoines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteTerritoirePatrimoineGeneratedActions.getManySocieteTerritoirePatrimoines),
      switchMap(({ params }) =>
        this.societeTerritoirePatrimoineApiService.getSocieteTerritoirePatrimoines(params).pipe(
          map((societeTerritoirePatrimoines: SocieteTerritoirePatrimoine[]) => {
            return SocieteTerritoirePatrimoineGeneratedActions.normalizeManySocieteTerritoirePatrimoinesAfterUpsert({ societeTerritoirePatrimoines });
          }),
          catchError(error => of(SocieteTerritoirePatrimoineGeneratedActions.societeTerritoirePatrimoinesFailure({ error })))
        )
      )
    );
  });

  getOneSocieteTerritoirePatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteTerritoirePatrimoineGeneratedActions.getOneSocieteTerritoirePatrimoine),
      switchMap(idSocieteTerritoirePatrimoine =>
        this.societeTerritoirePatrimoineApiService.getSocieteTerritoirePatrimoine(idSocieteTerritoirePatrimoine).pipe(
          map((societeTerritoirePatrimoine: SocieteTerritoirePatrimoine) => {
            return SocieteTerritoirePatrimoineGeneratedActions.normalizeManySocieteTerritoirePatrimoinesAfterUpsert({ societeTerritoirePatrimoines: [societeTerritoirePatrimoine] });
          }),
          catchError(error => of(SocieteTerritoirePatrimoineGeneratedActions.societeTerritoirePatrimoinesFailure({ error })))
        )
      )
    );
  });

  upsertOneSocieteTerritoirePatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteTerritoirePatrimoineGeneratedActions.upsertOneSocieteTerritoirePatrimoine),
      concatMap(
        ({
          societeTerritoirePatrimoine,
          ids
        }: {
          societeTerritoirePatrimoine: Partial<SocieteTerritoirePatrimoine>;
          ids?: SocieteTerritoirePatrimoineRelationsIds;
        }) => {
          if (societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine) {
            return this.societeTerritoirePatrimoineApiService.updateSocieteTerritoirePatrimoine(societeTerritoirePatrimoine).pipe(
              map((societeTerritoirePatrimoineReturned: SocieteTerritoirePatrimoine) => {
                return SocieteTerritoirePatrimoineGeneratedActions.normalizeManySocieteTerritoirePatrimoinesAfterUpsert({ societeTerritoirePatrimoines: [societeTerritoirePatrimoineReturned] });
              }),
              catchError(error => of(SocieteTerritoirePatrimoineGeneratedActions.societeTerritoirePatrimoinesFailure({ error })))
            );
          } else {
            return this.societeTerritoirePatrimoineApiService.addSocieteTerritoirePatrimoine(societeTerritoirePatrimoine).pipe(
              mergeMap((societeTerritoirePatrimoineReturned: SocieteTerritoirePatrimoine) => getDefaultAddSocieteTerritoirePatrimoineActions(societeTerritoirePatrimoineReturned, ids)),
              catchError(error => of(SocieteTerritoirePatrimoineGeneratedActions.societeTerritoirePatrimoinesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteTerritoirePatrimoine$ = createEffect(() => {
    const selectSocieteTerritoirePatrimoineState$ = this.store$.select(SocieteTerritoirePatrimoineSelectors.selectSocieteTerritoirePatrimoineState);
    return this.actions$.pipe(
      ofType(SocieteTerritoirePatrimoineGeneratedActions.deleteOneSocieteTerritoirePatrimoine),
      withLatestFrom(selectSocieteTerritoirePatrimoineState$),
      concatMap(([{ idSocieteTerritoirePatrimoine }, state]) =>
        this.societeTerritoirePatrimoineApiService.deleteSocieteTerritoirePatrimoine(idSocieteTerritoirePatrimoine).pipe(
          mergeMap(_success => getDefaultDeleteSocieteTerritoirePatrimoineActions(state.entities[idSocieteTerritoirePatrimoine] as SocieteTerritoirePatrimoineEntityState)),
          catchError(error => of(SocieteTerritoirePatrimoineGeneratedActions.societeTerritoirePatrimoinesFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteTerritoirePatrimoinesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteTerritoirePatrimoineGeneratedActions.normalizeManySocieteTerritoirePatrimoinesAfterUpsert),
      concatMap(({ societeTerritoirePatrimoines }) => {
        const actions: Action[] = getActionsToNormalizeSocieteTerritoirePatrimoine(societeTerritoirePatrimoines, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteTerritoirePatrimoine] Normalization After Upsert Success')];
      })
    );
  });
}
