import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { CampagneUser, CampagneUserEntityState } from '@get/api-interfaces';
import { CampagneUserApiService } from '@get/store/api-services';
import { CampagneUserGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeCampagneUser } from '@get/store/configs/normalization';
import { CampagneUserSelectors } from '@get/store/selectors';
import { CampagneUserRelationsIds } from '@get/store/ids-interfaces';
import { UserGeneratedActions } from '@get/store/actions';
import { CampagneGeneratedActions } from '@get/store/actions';

export function getDefaultAddCampagneUserActions(campagneUser: CampagneUserEntityState, ids?: CampagneUserRelationsIds): Action[] {
  const actions: Action[] = [CampagneUserGeneratedActions.normalizeManyCampagneUsersAfterUpsert({ campagneUsers: [campagneUser] })];

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyCampagneUserSuccess({
        idUser: ids.user,
        idCampagneUsers: [campagneUser.idCampagneUser]
      })
    );
    actions.push(
      CampagneUserGeneratedActions.addUserSuccess({
        idCampagneUser: campagneUser.idCampagneUser,
        idUser: ids.user
      })
    );
  }

  if (ids?.campagne) {
    actions.push(
      CampagneGeneratedActions.addManyCampagneUserSuccess({
        idCampagne: ids.campagne,
        idCampagneUsers: [campagneUser.idCampagneUser]
      })
    );
    actions.push(
      CampagneUserGeneratedActions.addCampagneSuccess({
        idCampagneUser: campagneUser.idCampagneUser,
        idCampagne: ids.campagne
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCampagneUserActions(campagneUser: CampagneUserEntityState): Action[] {
  const actions: Action[] = [CampagneUserGeneratedActions.deleteOneCampagneUserSuccess({ idCampagneUser: campagneUser.idCampagneUser })];

  if (campagneUser.user) {
    actions.push(
      UserGeneratedActions.deleteManyCampagneUserSuccess({
        idCampagneUsers: [campagneUser.idCampagneUser],
        idUsers: [campagneUser.user as number]
      })
    );
  }

  if (campagneUser.campagne) {
    actions.push(
      CampagneGeneratedActions.deleteManyCampagneUserSuccess({
        idCampagneUsers: [campagneUser.idCampagneUser],
        idCampagnes: [campagneUser.campagne as number]
      })
    );
  }

  return actions;
}

export class GeneratedCampagneUserEffects {
  constructor(
    protected actions$: Actions,
    protected campagneUserApiService: CampagneUserApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCampagneUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneUserGeneratedActions.getManyCampagneUsers),
      switchMap(({ params }) =>
        this.campagneUserApiService.getCampagneUsers(params).pipe(
          map((campagneUsers: CampagneUser[]) => {
            return CampagneUserGeneratedActions.normalizeManyCampagneUsersAfterUpsert({ campagneUsers });
          }),
          catchError(error => of(CampagneUserGeneratedActions.campagneUsersFailure({ error })))
        )
      )
    );
  });

  getOneCampagneUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneUserGeneratedActions.getOneCampagneUser),
      switchMap(idCampagneUser =>
        this.campagneUserApiService.getCampagneUser(idCampagneUser).pipe(
          map((campagneUser: CampagneUser) => {
            return CampagneUserGeneratedActions.normalizeManyCampagneUsersAfterUpsert({ campagneUsers: [campagneUser] });
          }),
          catchError(error => of(CampagneUserGeneratedActions.campagneUsersFailure({ error })))
        )
      )
    );
  });

  upsertOneCampagneUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneUserGeneratedActions.upsertOneCampagneUser),
      concatMap(
        ({
          campagneUser,
          ids
        }: {
          campagneUser: Partial<CampagneUser>;
          ids?: CampagneUserRelationsIds;
        }) => {
          if (campagneUser.idCampagneUser) {
            return this.campagneUserApiService.updateCampagneUser(campagneUser).pipe(
              map((campagneUserReturned: CampagneUser) => {
                return CampagneUserGeneratedActions.normalizeManyCampagneUsersAfterUpsert({ campagneUsers: [campagneUserReturned] });
              }),
              catchError(error => of(CampagneUserGeneratedActions.campagneUsersFailure({ error })))
            );
          } else {
            return this.campagneUserApiService.addCampagneUser(campagneUser).pipe(
              mergeMap((campagneUserReturned: CampagneUser) => getDefaultAddCampagneUserActions(campagneUserReturned, ids)),
              catchError(error => of(CampagneUserGeneratedActions.campagneUsersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCampagneUser$ = createEffect(() => {
    const selectCampagneUserState$ = this.store$.select(CampagneUserSelectors.selectCampagneUserState);
    return this.actions$.pipe(
      ofType(CampagneUserGeneratedActions.deleteOneCampagneUser),
      withLatestFrom(selectCampagneUserState$),
      concatMap(([{ idCampagneUser }, state]) =>
        this.campagneUserApiService.deleteCampagneUser(idCampagneUser).pipe(
          mergeMap(_success => getDefaultDeleteCampagneUserActions(state.entities[idCampagneUser] as CampagneUserEntityState)),
          catchError(error => of(CampagneUserGeneratedActions.campagneUsersFailure({ error })))
        )
      )
    );
  });

  normalizeManyCampagneUsersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneUserGeneratedActions.normalizeManyCampagneUsersAfterUpsert),
      concatMap(({ campagneUsers }) => {
        const actions: Action[] = getActionsToNormalizeCampagneUser(campagneUsers, StoreActionType.upsert);
        return [getMultiAction(actions, '[CampagneUser] Normalization After Upsert Success')];
      })
    );
  });
}
