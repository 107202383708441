import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { ValeurComposant, ValeurComposantEntityState } from '@get/api-interfaces';
import { Composant, ComposantEntityState } from '@get/api-interfaces';
import { ValeurPatrimoine, ValeurPatrimoineEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { ValeurFichier, ValeurFichierEntityState } from '@get/api-interfaces';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoix, SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ValeurState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const valeurRelations: string[] = ['valeurComposants','composants','valeurPatrimoines','patrimoines','valeurFichiers','fichiers','societeCaracteristiqueChoices','societeCaracteristiques','users',];

export const { selectEntities, selectAll } = ValeurState.adapter.getSelectors();

export const selectValeurState = createFeatureSelector<ValeurState.IState>(ValeurState.valeurFeatureKey);

export const selectIsLoadedValeur = createSelector(
  selectValeurState,
  (state: ValeurState.IState) => state.isLoaded
);

export const selectIsLoadingValeur = createSelector(
  selectValeurState,
  (state: ValeurState.IState) => state.isLoading
);

export const selectIsReadyValeur = createSelector(
  selectValeurState,
  (state: ValeurState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedValeur = createSelector(
  selectValeurState,
  (state: ValeurState.IState) => state.isLoaded && !state.isLoading
);

export const selectValeursEntities = createSelector(selectValeurState, selectEntities);

export const selectValeursArray = createSelector(selectValeurState, selectAll);

export const selectIdValeursActive = createSelector(
  selectValeurState,
  (state: ValeurState.IState) => state.actives
);

const valeursInObject = (valeurs: Dictionary<ValeurEntityState>) => ({ valeurs })

const selectValeursEntitiesDictionary = createSelector(selectValeursEntities, valeursInObject);

const selectAllValeursObject = createSelector(selectValeursEntities, valeurs => {
  return hydrateAll({ valeurs });
});

const selectOneValeurDictionary = (idValeur : number) =>
  createSelector(selectValeursEntities, valeurs => ({
    valeurs: { [idValeur]: valeurs[idValeur] }
  }));

const selectOneValeurDictionaryWithoutChild = (idValeur : number) =>
  createSelector(selectValeursEntities, valeurs => ({
    valeur: valeurs[idValeur]
  }));

const selectActiveValeursEntities = createSelector(
  selectIdValeursActive,
  selectValeursEntities,
  (actives: number[], valeurs: Dictionary<ValeurEntityState>) => getValeursFromActives(actives, valeurs)
);

function getValeursFromActives(
  actives: number[],
  valeurs: Dictionary<ValeurEntityState>
): Dictionary<ValeurEntityState> {
  return actives.reduce((acc, idActive) => {
    if (valeurs[idActive]) {
      acc[idActive] = valeurs[idActive];
    }
    return acc;
  }, {} as Dictionary<ValeurEntityState>);
}

const selectAllValeursSelectors: Dictionary<Selector> = {};
export function selectAllValeurs(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Valeur>(
      schema,
      selectAllValeursSelectors,
      selectValeursEntitiesDictionary,
      getRelationSelectors,
      valeurRelations,
      hydrateAll,
      'valeur'
    );
  } else {
    return selectAllValeursObject;
  }
}

export function selectAllValeursDictionary(
  schema: SelectSchema = {},
  customKey: string = 'valeurs'
): Selector {
  return createSelector(selectAllValeurs(schema), result => {
    const res = { [customKey]: {} as Dictionary<ValeurEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.valeurs.length; i++) {
      res[customKey][result.valeurs[i].idValeur] = result.valeurs[i];
    }
    return res;
  });
}

export function selectOneValeur(
  schema: SelectSchema = {},
  idValeur: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneValeurDictionary(idValeur)];
  selectors.push(...getRelationSelectors(schema, valeurRelations, 'valeur'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneValeurDictionaryWithoutChild(idValeur);
  }
}

export function selectActiveValeurs(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveValeursEntities, valeurs => ({
      valeurs
    }))
  ];
  selectors.push(...getRelationSelectors(schema, valeurRelations, 'valeur'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  valeurs: Dictionary<ValeurEntityState>;
  societeCaracteristiqueChoices?: Dictionary<SocieteCaracteristiqueChoixEntityState>;
  societeCaracteristiques?: Dictionary<SocieteCaracteristiqueEntityState>;
  users?: Dictionary<UserEntityState>;
  valeurComposants?: Dictionary<ValeurComposantEntityState>;
  composants?: Dictionary<ComposantEntityState>;
  valeurPatrimoines?: Dictionary<ValeurPatrimoineEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
  valeurFichiers?: Dictionary<ValeurFichierEntityState>;
  fichiers?: Dictionary<FichierEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { valeurs: (Valeur | null)[] } {
  const {
    valeurs,
    societeCaracteristiqueChoices,
    societeCaracteristiques,
    users,
    valeurComposants,
    composants,
    valeurPatrimoines,
    patrimoines,
    valeurFichiers,
    fichiers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    valeurs: Object.keys(valeurs).map(idValeur =>
      hydrate(
        valeurs[idValeur] as ValeurEntityState,
        societeCaracteristiqueChoices,
        societeCaracteristiques,
        users,
        valeurComposants,
        composants,
        valeurPatrimoines,
        patrimoines,
        valeurFichiers,
        fichiers
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { valeur: ValeurEntityState | null } {
  const {
    valeurs,
    societeCaracteristiqueChoices,
    societeCaracteristiques,
    users,
    valeurComposants,
    composants,
    valeurPatrimoines,
    patrimoines,
    valeurFichiers,
    fichiers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const valeur = Object.values(valeurs)[0];
  return {
    valeur: hydrate(
      valeur as ValeurEntityState,
      societeCaracteristiqueChoices,
      societeCaracteristiques,
      users,
      valeurComposants,
      composants,
      valeurPatrimoines,
      patrimoines,
      valeurFichiers,
      fichiers
    )
  };
}

function hydrate(
  valeur: ValeurEntityState,
  societeCaracteristiqueChoixEntities?: Dictionary<SocieteCaracteristiqueChoixEntityState>,
  societeCaracteristiqueEntities?: Dictionary<SocieteCaracteristiqueEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  valeurComposantEntities?: Dictionary<ValeurComposantEntityState>,
  composantEntities?: Dictionary<ComposantEntityState>,
  valeurPatrimoineEntities?: Dictionary<ValeurPatrimoineEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
  valeurFichierEntities?: Dictionary<ValeurFichierEntityState>,
  fichierEntities?: Dictionary<FichierEntityState>,
): Valeur | null {
  if (!valeur) {
    return null;
  }

  const valeurHydrated: ValeurEntityState = { ...valeur };
  if (societeCaracteristiqueChoixEntities) {
    valeurHydrated.societeCaracteristiqueChoix = societeCaracteristiqueChoixEntities[valeur.societeCaracteristiqueChoix as number] as SocieteCaracteristiqueChoix;
  } else {
    delete valeurHydrated.societeCaracteristiqueChoix;
  }
  if (societeCaracteristiqueEntities) {
    valeurHydrated.societeCaracteristique = societeCaracteristiqueEntities[valeur.societeCaracteristique as number] as SocieteCaracteristique;
  } else {
    delete valeurHydrated.societeCaracteristique;
  }
  if (userEntities) {
    valeurHydrated.user = userEntities[valeur.user as number] as User;
  } else {
    delete valeurHydrated.user;
  }

  if (valeurComposantEntities) {
    valeurHydrated.valeurComposants = ((valeurHydrated.valeurComposants as number[]) || []).map(
      id => valeurComposantEntities[id]
    ) as ValeurComposant[];
  } else {
    delete valeurHydrated.valeurComposants;
  }

  if (composantEntities) {
    valeurHydrated.composants = ((valeurHydrated.composants as number[]) || []).map(
      id => composantEntities[id]
    ) as Composant[];
  } else {
    delete valeurHydrated.composants;
  }

  if (valeurPatrimoineEntities) {
    valeurHydrated.valeurPatrimoines = ((valeurHydrated.valeurPatrimoines as number[]) || []).map(
      id => valeurPatrimoineEntities[id]
    ) as ValeurPatrimoine[];
  } else {
    delete valeurHydrated.valeurPatrimoines;
  }

  if (patrimoineEntities) {
    valeurHydrated.patrimoines = ((valeurHydrated.patrimoines as number[]) || []).map(
      id => patrimoineEntities[id]
    ) as Patrimoine[];
  } else {
    delete valeurHydrated.patrimoines;
  }

  if (valeurFichierEntities) {
    valeurHydrated.valeurFichiers = ((valeurHydrated.valeurFichiers as number[]) || []).map(
      id => valeurFichierEntities[id]
    ) as ValeurFichier[];
  } else {
    delete valeurHydrated.valeurFichiers;
  }

  if (fichierEntities) {
    valeurHydrated.fichiers = ((valeurHydrated.fichiers as number[]) || []).map(
      id => fichierEntities[id]
    ) as Fichier[];
  } else {
    delete valeurHydrated.fichiers;
  }

  return valeurHydrated as Valeur;
}
