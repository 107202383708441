import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PatrimoineAncetre, PatrimoineAncetreEntityState } from '@get/api-interfaces';
import { PatrimoineAncetreRelationsIds } from '@get/store/ids-interfaces';

export const PatrimoineAncetreGeneratedActions = createActionGroup({
  source: 'Patrimoine Ancetre Generated',
  events: {
    'Get One Patrimoine Ancetre': props<{ idPatrimoineAncetre: number; params?: any }>(),
    'Get Many Patrimoine Ancetres': props<{ params: any }>(),
    'Add Many Actives Patrimoine Ancetres': props<{ idPatrimoineAncetres: number[] }>(),
    'Delete One Active Patrimoine Ancetre': props<{ idPatrimoineAncetre: number }>(),
    'Clear Actives Patrimoine Ancetres': emptyProps(),
    'Upsert One Patrimoine Ancetre': props<{ patrimoineAncetre: Partial<PatrimoineAncetre>; ids?: PatrimoineAncetreRelationsIds; }>(),
    'Upsert Many Patrimoine Ancetres': props<{ patrimoineAncetres: Partial<PatrimoineAncetre>[]; ids?: PatrimoineAncetreRelationsIds; }>(),
    'Upsert Many Patrimoine Ancetres Success': props<{ patrimoineAncetres: PatrimoineAncetreEntityState[] }>(),
    'Delete One Patrimoine Ancetre': props<{ idPatrimoineAncetre: number }>(),
    'Delete One Patrimoine Ancetre Success': props<{ idPatrimoineAncetre: number }>(),
    'Normalize Many Patrimoine Ancetres After Upsert': props<{ patrimoineAncetres: PatrimoineAncetreEntityState[] }>(),
    'Patrimoine Ancetres Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Patrimoine Ancetres': emptyProps(),
    'Add Ancetre Patrimoine Success': props<{ idPatrimoineAncetre: number; idAncetrePatrimoine: number }>(),
    'Delete Many Ancetre Patrimoine Success': props<{ idAncetrePatrimoine: number[]; idPatrimoineAncetres: number[] }>(),
    'Add Descendant Patrimoine Success': props<{ idPatrimoineAncetre: number; idDescendantPatrimoine: number }>(),
    'Delete Many Descendant Patrimoine Success': props<{ idDescendantPatrimoine: number[]; idPatrimoineAncetres: number[] }>()
  }
});
