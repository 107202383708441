import { GridError, SocieteEspaceFamille, SocieteEspaceFamilleDataRow } from '@get/api-interfaces';
import { ValidationErrorInterface } from '../validation-error.interface';

export function validateSocieteEspaceFamilleProperties(
  SocieteEspaceFamilleToCreate: Partial<SocieteEspaceFamille>
): ValidationErrorInterface<SocieteEspaceFamille>[] {
  const errors: ValidationErrorInterface<SocieteEspaceFamille>[] = [];
  if (!SocieteEspaceFamilleToCreate.libelleTechnique || !SocieteEspaceFamilleToCreate.idSocietePatrimoineHierarchie) {
    errors.push({
      properties: ['libelleTechnique', 'idSocietePatrimoineHierarchie'],
      message: 'You need a libelleTechnique or an idSocietePatrimoineHierarchie to do this action'
    });
  }
  return errors;
}

export function mergeSocieteEspaceFamilleAndGridErrors(
  SocieteEspaceFamilles: SocieteEspaceFamille[],
  gridErrors: GridError[]
): SocieteEspaceFamilleDataRow[] {
  const societeEspaceFamilleDataRows: SocieteEspaceFamilleDataRow[] = [
    ...(SocieteEspaceFamilles as SocieteEspaceFamilleDataRow[])
  ];
  gridErrors?.forEach(gridErrorSocieteEspaceFamille => {
    if (gridErrorSocieteEspaceFamille.error.idSocieteEspaceFamille > 0) {
      const index = societeEspaceFamilleDataRows.findIndex(
        espace => espace.idSocieteEspaceFamille === gridErrorSocieteEspaceFamille.error.idSocieteEspaceFamille
      );

      societeEspaceFamilleDataRows[index] = { ...gridErrorSocieteEspaceFamille.error };
    } else {
      societeEspaceFamilleDataRows.push({ ...gridErrorSocieteEspaceFamille.error });
    }
  });
  return societeEspaceFamilleDataRows;
}
