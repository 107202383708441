import { PatrimoineState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { patrimoineReducersGeneratedFunctions } from './patrimoine-generated.reducer';

const patrimoineReducersFunctions = [...patrimoineReducersGeneratedFunctions];

const patrimoineReducer = createReducer(PatrimoineState.initialState, ...patrimoineReducersFunctions);

export function reducer(state: PatrimoineState.IState | undefined, action: Action) {
  return patrimoineReducer(state, action);
}
