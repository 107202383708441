import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteConfigAnalyseSynthese, SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseApiService } from '@get/store/api-services';
import { SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteConfigAnalyseSynthese } from '@get/store/configs/normalization';
import { SocieteConfigAnalyseSyntheseSelectors } from '@get/store/selectors';
import { SocieteConfigAnalyseSyntheseRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { UserGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristique } from '@get/api-interfaces';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristique } from '@get/api-interfaces';

export function getDefaultAddSocieteConfigAnalyseSyntheseActions(societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseEntityState, ids?: SocieteConfigAnalyseSyntheseRelationsIds): Action[] {
  const actions: Action[] = [SocieteConfigAnalyseSyntheseGeneratedActions.normalizeManySocieteConfigAnalyseSynthesesAfterUpsert({ societeConfigAnalyseSyntheses: [societeConfigAnalyseSynthese] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManySocieteConfigAnalyseSyntheseSuccess({
        idSociete: ids.societe,
        idSocieteConfigAnalyseSyntheses: [societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese]
      })
    );
    actions.push(
      SocieteConfigAnalyseSyntheseGeneratedActions.addSocieteSuccess({
        idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManySocieteConfigAnalyseSyntheseSuccess({
        idUser: ids.user,
        idSocieteConfigAnalyseSyntheses: [societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese]
      })
    );
    actions.push(
      SocieteConfigAnalyseSyntheseGeneratedActions.addUserSuccess({
        idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese,
        idUser: ids.user
      })
    );
  }

  if (ids?.societeConfigAnalyseSyntheseSocieteCaracteristiques) {
    if (!Array.isArray(ids.societeConfigAnalyseSyntheseSocieteCaracteristiques)) {
      actions.push(
        SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.upsertOneSocieteConfigAnalyseSyntheseSocieteCaracteristique({
          societeConfigAnalyseSyntheseSocieteCaracteristique: {
            idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese,
            idSocieteConfigAnalyseSyntheseSocieteCaracteristique: ids.societeConfigAnalyseSyntheseSocieteCaracteristiques as number
          } as SocieteConfigAnalyseSyntheseSocieteCaracteristique
        })
      );
      actions.push(
        SocieteConfigAnalyseSyntheseGeneratedActions.addManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess({
          idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese,
          idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: [ids.societeConfigAnalyseSyntheseSocieteCaracteristiques as number]
        })
      );
    } else {
      actions.push(
        SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.upsertManySocieteConfigAnalyseSyntheseSocieteCaracteristiques({
          societeConfigAnalyseSyntheseSocieteCaracteristiques: (ids.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]).map(
            (idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number) => ({
              idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese,
              idSocieteConfigAnalyseSyntheseSocieteCaracteristique
            })
          ) as SocieteConfigAnalyseSyntheseSocieteCaracteristique[]
        })
      );
      actions.push(
        SocieteConfigAnalyseSyntheseGeneratedActions.addManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess({
          idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese,
          idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: ids.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]
        })
      );
    }
  }

  if (ids?.societeCaracteristiques) {
    if (!Array.isArray(ids.societeCaracteristiques)) {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertOneSocieteCaracteristique({
          societeCaracteristique: {
            idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese,
            idSocieteCaracteristique: ids.societeCaracteristiques as number
          } as SocieteCaracteristique & any
        })
      );
      actions.push(
        SocieteConfigAnalyseSyntheseGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese,
          idSocieteCaracteristiques: [ids.societeCaracteristiques as number]
        })
      );
    } else {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertManySocieteCaracteristiques({
          societeCaracteristiques: (ids.societeCaracteristiques as number[]).map(
            (idSocieteCaracteristique: number) => ({
              idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese,
              idSocieteCaracteristique
            })
          ) as SocieteCaracteristique[] & any[]
        })
      );
      actions.push(
        SocieteConfigAnalyseSyntheseGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese,
          idSocieteCaracteristiques: ids.societeCaracteristiques as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSocieteConfigAnalyseSyntheseActions(societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseEntityState): Action[] {
  const actions: Action[] = [SocieteConfigAnalyseSyntheseGeneratedActions.deleteOneSocieteConfigAnalyseSyntheseSuccess({ idSocieteConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese })];

  if (societeConfigAnalyseSynthese.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess({
        idSocieteConfigAnalyseSyntheses: [societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese],
        idSocietes: [societeConfigAnalyseSynthese.societe as number]
      })
    );
  }

  if (societeConfigAnalyseSynthese.user) {
    actions.push(
      UserGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess({
        idSocieteConfigAnalyseSyntheses: [societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese],
        idUsers: [societeConfigAnalyseSynthese.user as number]
      })
    );
  }

  if (societeConfigAnalyseSynthese.societeConfigAnalyseSyntheseSocieteCaracteristiques) {
    actions.push(
      SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess({
        idSocieteConfigAnalyseSyntheses: [societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese],
        idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: societeConfigAnalyseSynthese.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]
      })
    );
  }

  if (societeConfigAnalyseSynthese.societeCaracteristiques) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess({
        idSocieteConfigAnalyseSyntheses: [societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese],
        idSocieteCaracteristiques: societeConfigAnalyseSynthese.societeCaracteristiques as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteConfigAnalyseSyntheseEffects {
  constructor(
    protected actions$: Actions,
    protected societeConfigAnalyseSyntheseApiService: SocieteConfigAnalyseSyntheseApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocieteConfigAnalyseSyntheses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseGeneratedActions.getManySocieteConfigAnalyseSyntheses),
      switchMap(({ params }) =>
        this.societeConfigAnalyseSyntheseApiService.getSocieteConfigAnalyseSyntheses(params).pipe(
          map((societeConfigAnalyseSyntheses: SocieteConfigAnalyseSynthese[]) => {
            return SocieteConfigAnalyseSyntheseGeneratedActions.normalizeManySocieteConfigAnalyseSynthesesAfterUpsert({ societeConfigAnalyseSyntheses });
          }),
          catchError(error => of(SocieteConfigAnalyseSyntheseGeneratedActions.societeConfigAnalyseSynthesesFailure({ error })))
        )
      )
    );
  });

  getOneSocieteConfigAnalyseSynthese$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseGeneratedActions.getOneSocieteConfigAnalyseSynthese),
      switchMap(idSocieteConfigAnalyseSynthese =>
        this.societeConfigAnalyseSyntheseApiService.getSocieteConfigAnalyseSynthese(idSocieteConfigAnalyseSynthese).pipe(
          map((societeConfigAnalyseSynthese: SocieteConfigAnalyseSynthese) => {
            return SocieteConfigAnalyseSyntheseGeneratedActions.normalizeManySocieteConfigAnalyseSynthesesAfterUpsert({ societeConfigAnalyseSyntheses: [societeConfigAnalyseSynthese] });
          }),
          catchError(error => of(SocieteConfigAnalyseSyntheseGeneratedActions.societeConfigAnalyseSynthesesFailure({ error })))
        )
      )
    );
  });

  upsertOneSocieteConfigAnalyseSynthese$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseGeneratedActions.upsertOneSocieteConfigAnalyseSynthese),
      concatMap(
        ({
          societeConfigAnalyseSynthese,
          ids
        }: {
          societeConfigAnalyseSynthese: Partial<SocieteConfigAnalyseSynthese>;
          ids?: SocieteConfigAnalyseSyntheseRelationsIds;
        }) => {
          if (societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese) {
            return this.societeConfigAnalyseSyntheseApiService.updateSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthese).pipe(
              map((societeConfigAnalyseSyntheseReturned: SocieteConfigAnalyseSynthese) => {
                return SocieteConfigAnalyseSyntheseGeneratedActions.normalizeManySocieteConfigAnalyseSynthesesAfterUpsert({ societeConfigAnalyseSyntheses: [societeConfigAnalyseSyntheseReturned] });
              }),
              catchError(error => of(SocieteConfigAnalyseSyntheseGeneratedActions.societeConfigAnalyseSynthesesFailure({ error })))
            );
          } else {
            return this.societeConfigAnalyseSyntheseApiService.addSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthese).pipe(
              mergeMap((societeConfigAnalyseSyntheseReturned: SocieteConfigAnalyseSynthese) => getDefaultAddSocieteConfigAnalyseSyntheseActions(societeConfigAnalyseSyntheseReturned, ids)),
              catchError(error => of(SocieteConfigAnalyseSyntheseGeneratedActions.societeConfigAnalyseSynthesesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteConfigAnalyseSynthese$ = createEffect(() => {
    const selectSocieteConfigAnalyseSyntheseState$ = this.store$.select(SocieteConfigAnalyseSyntheseSelectors.selectSocieteConfigAnalyseSyntheseState);
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseGeneratedActions.deleteOneSocieteConfigAnalyseSynthese),
      withLatestFrom(selectSocieteConfigAnalyseSyntheseState$),
      concatMap(([{ idSocieteConfigAnalyseSynthese }, state]) =>
        this.societeConfigAnalyseSyntheseApiService.deleteSocieteConfigAnalyseSynthese(idSocieteConfigAnalyseSynthese).pipe(
          mergeMap(_success => getDefaultDeleteSocieteConfigAnalyseSyntheseActions(state.entities[idSocieteConfigAnalyseSynthese] as SocieteConfigAnalyseSyntheseEntityState)),
          catchError(error => of(SocieteConfigAnalyseSyntheseGeneratedActions.societeConfigAnalyseSynthesesFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteConfigAnalyseSynthesesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseGeneratedActions.normalizeManySocieteConfigAnalyseSynthesesAfterUpsert),
      concatMap(({ societeConfigAnalyseSyntheses }) => {
        const actions: Action[] = getActionsToNormalizeSocieteConfigAnalyseSynthese(societeConfigAnalyseSyntheses, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteConfigAnalyseSynthese] Normalization After Upsert Success')];
      })
    );
  });
}
