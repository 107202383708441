import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteConfigAnalyseSynthese } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteConfigAnalyseSyntheseRelationsIds } from '@get/store/ids-interfaces';
import { SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSyntheseSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedSocieteConfigAnalyseSyntheseService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteConfigAnalyseSyntheseSelectors.selectIsLoadedSocieteConfigAnalyseSynthese));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteConfigAnalyseSyntheseSelectors.selectIsLoadingSocieteConfigAnalyseSynthese));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteConfigAnalyseSyntheseSelectors.selectIsReadyAndLoadedSocieteConfigAnalyseSynthese as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteConfigAnalyseSyntheses(schema: SelectSchema = {}): Observable<SocieteConfigAnalyseSynthese[]> {
    return this.store$.pipe(select(SocieteConfigAnalyseSyntheseSelectors.selectAllSocieteConfigAnalyseSyntheses(schema))).pipe(
      switchMap(({ societeConfigAnalyseSyntheses }: { societeConfigAnalyseSyntheses: SocieteConfigAnalyseSynthese[] }) => {
        return this.getReady(schema).pipe(mapTo(societeConfigAnalyseSyntheses));
      })
    );
  }

  public selectOneSocieteConfigAnalyseSynthese(
    idSocieteConfigAnalyseSynthese: number,
    schema: SelectSchema = {}
  ): Observable<SocieteConfigAnalyseSynthese> {
    return this.store$
      .pipe(select(SocieteConfigAnalyseSyntheseSelectors.selectOneSocieteConfigAnalyseSynthese(schema, idSocieteConfigAnalyseSynthese)))
      .pipe(
        switchMap(({ societeConfigAnalyseSynthese }: { societeConfigAnalyseSynthese: SocieteConfigAnalyseSynthese }) => {
          return this.getReady(schema).pipe(mapTo(societeConfigAnalyseSynthese));
        })
      );
  }

  public selectAllActiveSocieteConfigAnalyseSyntheses(schema: SelectSchema = {}): Observable<SocieteConfigAnalyseSynthese[]> {
    return this.store$.pipe(select(SocieteConfigAnalyseSyntheseSelectors.selectActiveSocieteConfigAnalyseSyntheses(schema))).pipe(
      switchMap(({ societeConfigAnalyseSyntheses }: { societeConfigAnalyseSyntheses: SocieteConfigAnalyseSynthese[] }) => {
        return this.getReady(schema).pipe(mapTo(societeConfigAnalyseSyntheses));
      })
    );
  }

  public selectIdSocieteConfigAnalyseSynthesesActive(): Observable<number[]> {
    return this.store$.pipe(select(SocieteConfigAnalyseSyntheseSelectors.selectIdSocieteConfigAnalyseSynthesesActive)).pipe(
      switchMap((idSocieteConfigAnalyseSyntheses: number[]) => {
        return this.getReady().pipe(mapTo(idSocieteConfigAnalyseSyntheses));
      })
    );
  }

  public getOneSocieteConfigAnalyseSynthese(
    idSocieteConfigAnalyseSynthese: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteConfigAnalyseSynthese> {
    this.store$.dispatch(SocieteConfigAnalyseSyntheseGeneratedActions.getOneSocieteConfigAnalyseSynthese({ idSocieteConfigAnalyseSynthese, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteConfigAnalyseSyntheseGeneratedActions.normalizeManySocieteConfigAnalyseSynthesesAfterUpsert,
        SocieteConfigAnalyseSyntheseGeneratedActions.societeConfigAnalyseSynthesesFailure,
        true
      );
    }
  }

  public getManySocieteConfigAnalyseSyntheses(
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteConfigAnalyseSynthese[]> {
    this.store$.dispatch(SocieteConfigAnalyseSyntheseGeneratedActions.getManySocieteConfigAnalyseSyntheses({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteConfigAnalyseSyntheseGeneratedActions.normalizeManySocieteConfigAnalyseSynthesesAfterUpsert,
        SocieteConfigAnalyseSyntheseGeneratedActions.societeConfigAnalyseSynthesesFailure
      );
    }
  }

  public upsertOneSocieteConfigAnalyseSynthese(
    societeConfigAnalyseSynthese: Partial<SocieteConfigAnalyseSynthese>,
    ids: SocieteConfigAnalyseSyntheseRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteConfigAnalyseSynthese> {
    this.store$.dispatch(SocieteConfigAnalyseSyntheseGeneratedActions.upsertOneSocieteConfigAnalyseSynthese({ societeConfigAnalyseSynthese, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteConfigAnalyseSyntheseGeneratedActions.normalizeManySocieteConfigAnalyseSynthesesAfterUpsert,
        SocieteConfigAnalyseSyntheseGeneratedActions.societeConfigAnalyseSynthesesFailure,
        true
      );
    }
  }

  public deleteOneSocieteConfigAnalyseSynthese(
    idSocieteConfigAnalyseSynthese: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(SocieteConfigAnalyseSyntheseGeneratedActions.deleteOneSocieteConfigAnalyseSynthese({ idSocieteConfigAnalyseSynthese }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteConfigAnalyseSyntheseGeneratedActions.deleteOneSocieteConfigAnalyseSyntheseSuccess,
        SocieteConfigAnalyseSyntheseGeneratedActions.societeConfigAnalyseSynthesesFailure
      );
    }
  }

  public setActiveSocieteConfigAnalyseSyntheses(idSocieteConfigAnalyseSyntheses: number[]): void {
    this.store$.dispatch(SocieteConfigAnalyseSyntheseGeneratedActions.clearActivesSocieteConfigAnalyseSyntheses());
    this.store$.dispatch(SocieteConfigAnalyseSyntheseGeneratedActions.addManyActivesSocieteConfigAnalyseSyntheses({ idSocieteConfigAnalyseSyntheses }));
  }
}
