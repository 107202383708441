import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { FichierRelationsIds } from '@get/store/ids-interfaces';

export const FichierGeneratedActions = createActionGroup({
  source: 'Fichier Generated',
  events: {
    'Get One Fichier': props<{ idFichier: number; params?: any }>(),
    'Get Many Fichiers': props<{ params: any }>(),
    'Add Many Actives Fichiers': props<{ idFichiers: number[] }>(),
    'Delete One Active Fichier': props<{ idFichier: number }>(),
    'Clear Actives Fichiers': emptyProps(),
    'Upsert One Fichier': props<{ fichier: Partial<Fichier>; ids?: FichierRelationsIds; }>(),
    'Upsert Many Fichiers': props<{ fichiers: Partial<Fichier>[]; ids?: FichierRelationsIds; }>(),
    'Upsert Many Fichiers Success': props<{ fichiers: FichierEntityState[] }>(),
    'Delete One Fichier': props<{ idFichier: number }>(),
    'Delete One Fichier Success': props<{ idFichier: number }>(),
    'Normalize Many Fichiers After Upsert': props<{ fichiers: FichierEntityState[] }>(),
    'Fichiers Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Fichiers': emptyProps(),
    'Add Many Societe Composant Success': props<{ idFichier: number; idSocieteComposants: number[] }>(),
    'Delete Many Societe Composant Success': props<{ idSocieteComposants: number[]; idFichiers: number[] }>(),
    'Add Many Societe Composant Famille Success': props<{ idFichier: number; idSocieteComposantFamilles: number[] }>(),
    'Delete Many Societe Composant Famille Success': props<{ idSocieteComposantFamilles: number[]; idFichiers: number[] }>(),
    'Add Many Composant Groupe Success': props<{ idFichier: number; idComposantGroupes: number[] }>(),
    'Delete Many Composant Groupe Success': props<{ idComposantGroupes: number[]; idFichiers: number[] }>(),
    'Add Many Composant Template Success': props<{ idFichier: number; idComposantTemplates: number[] }>(),
    'Delete Many Composant Template Success': props<{ idComposantTemplates: number[]; idFichiers: number[] }>(),
    'Add Many Societe Caracteristique Choix Success': props<{ idFichier: number; idSocieteCaracteristiqueChoices: number[] }>(),
    'Delete Many Societe Caracteristique Choix Success': props<{ idSocieteCaracteristiqueChoices: number[]; idFichiers: number[] }>(),
    'Add Many Caracteristique Choix Template Success': props<{ idFichier: number; idCaracteristiqueChoixTemplates: number[] }>(),
    'Delete Many Caracteristique Choix Template Success': props<{ idCaracteristiqueChoixTemplates: number[]; idFichiers: number[] }>(),
    'Add Many Valeur Fichier Success': props<{ idFichier: number; idValeurFichiers: number[] }>(),
    'Delete Many Valeur Fichier Success': props<{ idValeurFichiers: number[]; idFichiers: number[] }>(),
    'Add Many Valeur Success': props<{ idFichier: number; idValeurs: number[] }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idFichiers: number[] }>(),
    'Add Organisation Success': props<{ idFichier: number; idOrganisation: number }>(),
    'Delete Many Organisation Success': props<{ idOrganisations: number[]; idFichiers: number[] }>()
  }
});
