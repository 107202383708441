import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { DroitGeneratedActions } from '@get/store/actions';
import { DroitState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { DroitEntityState } from '@get/api-interfaces';

export const droitReducersGeneratedFunctions: ReducerTypes<DroitState.IState, readonly ActionCreator[]>[] = [
  on(DroitGeneratedActions.getOneDroit, (state: DroitState.IState) => setLoadingsState(state, true)),
  on(DroitGeneratedActions.getManyDroits, (state: DroitState.IState) => setLoadingsState(state, true)),
  on(DroitGeneratedActions.upsertOneDroit, (state: DroitState.IState) => setLoadingsState(state, true)),

  on(DroitGeneratedActions.upsertManyDroitsSuccess, (state: DroitState.IState, { droits }) =>
    DroitState.adapter.upsertMany(droits, setLoadingsState(state, false))
  ),
  on(DroitGeneratedActions.deleteOneDroit, (state: DroitState.IState) => setLoadingsState(state, true)),
  on(DroitGeneratedActions.deleteOneDroitSuccess, (state: DroitState.IState, { idDroit }) =>
    DroitState.adapter.removeOne(idDroit, setLoadingsState(state, false))
  ),
  on(DroitGeneratedActions.clearActivesDroits, (state: DroitState.IState) => ({ ...state, actives: [] })),
  on(DroitGeneratedActions.addManyActivesDroits, (state: DroitState.IState, { idDroits }) => ({
    ...state,
    actives: state.actives.concat(idDroits)
  })),
  on(DroitGeneratedActions.deleteOneActiveDroit, (state: DroitState.IState, { idDroit }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idDroit)
  })),

  on(DroitGeneratedActions.clearDroits, () => DroitState.initialState),

  on(
    DroitGeneratedActions.addManySocieteProfilDroitSuccess,
    (state: DroitState.IState, { idDroit, idSocieteProfilDroits }) => {
      if (!state.entities[idDroit]) {
        return state;
      }
      const societeProfilDroits = (state.entities[idDroit]?.societeProfilDroits as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idDroit]: {
            ...state.entities[idDroit],
            societeProfilDroits: societeProfilDroits.concat(
              idSocieteProfilDroits.filter(id => societeProfilDroits.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    DroitGeneratedActions.deleteManySocieteProfilDroitSuccess,
    (state: DroitState.IState, { idSocieteProfilDroits, idDroits }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idDroits.reduce((entities, idDroit) => {
            if (!state.entities[idDroit]?.societeProfilDroits) {
              return entities;
            }
            entities[idDroit] = {
              ...state.entities[idDroit],
              societeProfilDroits: (state.entities[idDroit]?.societeProfilDroits as number[])?.filter(
                (idSocieteProfilDroit: number) => !idSocieteProfilDroits.some((id: number) => id === idSocieteProfilDroit)
              )
            } as DroitEntityState;
            return entities;
          }, {} as Dictionary<DroitEntityState>)
        }
      };
    }
  ),

  on(
    DroitGeneratedActions.addManySocieteProfilSuccess,
    (state: DroitState.IState, { idDroit, idSocieteProfils }) => {
      if (!state.entities[idDroit]) {
        return state;
      }
      const societeProfils = (state.entities[idDroit]?.societeProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idDroit]: {
            ...state.entities[idDroit],
            societeProfils: societeProfils.concat(
              idSocieteProfils.filter(id => societeProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    DroitGeneratedActions.deleteManySocieteProfilSuccess,
    (state: DroitState.IState, { idSocieteProfils, idDroits }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idDroits.reduce((entities, idDroit) => {
            if (!state.entities[idDroit]?.societeProfils) {
              return entities;
            }
            entities[idDroit] = {
              ...state.entities[idDroit],
              societeProfils: (state.entities[idDroit]?.societeProfils as number[])?.filter(
                (idSocieteProfil: number) => !idSocieteProfils.some((id: number) => id === idSocieteProfil)
              )
            } as DroitEntityState;
            return entities;
          }, {} as Dictionary<DroitEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: DroitState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): DroitState.IState {
  return { ...state, isLoaded, isLoading };
}
