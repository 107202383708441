import { RepositoryService } from '@get/services/repository';
import { Espace } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedEspaceApiService {
  constructor(protected repo: RepositoryService) {}

  public getEspaces(params?: any): Observable<Espace[]> {
    return this.repo.getData<Espace[]>('espace', params);
  }

  public getEspace(params: { idEspace: number; params?: any }): Observable<Espace> {
    return this.repo.getData<Espace>('espace/' + params.idEspace, params.params);
  }

  public addEspace(espace: Partial<Espace>): Observable<Espace> {
    return this.repo.create<Espace>('espace', espace);
  }

  public updateEspace(espace: Partial<Espace>): Observable<Espace> {
    return this.repo.update('espace', espace);
  }

  public deleteEspace(idEspace: number): Observable<number> {
    return this.repo.delete('espace/' + +idEspace);
  }
}

