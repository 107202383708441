import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UserPatrimoineGeneratedActions } from '@get/store/actions';
import { UserPatrimoineState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { UserPatrimoineEntityState } from '@get/api-interfaces';

export const userPatrimoineReducersGeneratedFunctions: ReducerTypes<UserPatrimoineState.IState, readonly ActionCreator[]>[] = [
  on(UserPatrimoineGeneratedActions.getOneUserPatrimoine, (state: UserPatrimoineState.IState) => setLoadingsState(state, true)),
  on(UserPatrimoineGeneratedActions.getManyUserPatrimoines, (state: UserPatrimoineState.IState) => setLoadingsState(state, true)),
  on(UserPatrimoineGeneratedActions.upsertOneUserPatrimoine, (state: UserPatrimoineState.IState) => setLoadingsState(state, true)),

  on(UserPatrimoineGeneratedActions.upsertManyUserPatrimoinesSuccess, (state: UserPatrimoineState.IState, { userPatrimoines }) =>
    UserPatrimoineState.adapter.upsertMany(userPatrimoines, setLoadingsState(state, false))
  ),
  on(UserPatrimoineGeneratedActions.deleteOneUserPatrimoine, (state: UserPatrimoineState.IState) => setLoadingsState(state, true)),
  on(UserPatrimoineGeneratedActions.deleteOneUserPatrimoineSuccess, (state: UserPatrimoineState.IState, { idUserPatrimoine }) =>
    UserPatrimoineState.adapter.removeOne(idUserPatrimoine, setLoadingsState(state, false))
  ),
  on(UserPatrimoineGeneratedActions.clearActivesUserPatrimoines, (state: UserPatrimoineState.IState) => ({ ...state, actives: [] })),
  on(UserPatrimoineGeneratedActions.addManyActivesUserPatrimoines, (state: UserPatrimoineState.IState, { idUserPatrimoines }) => ({
    ...state,
    actives: state.actives.concat(idUserPatrimoines)
  })),
  on(UserPatrimoineGeneratedActions.deleteOneActiveUserPatrimoine, (state: UserPatrimoineState.IState, { idUserPatrimoine }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idUserPatrimoine)
  })),

  on(UserPatrimoineGeneratedActions.clearUserPatrimoines, () => UserPatrimoineState.initialState),

  on(
    UserPatrimoineGeneratedActions.addUserSuccess,
    (state: UserPatrimoineState.IState, { idUserPatrimoine, idUser }) => {
      if (!state.entities[idUserPatrimoine]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserPatrimoine]: {
            ...state.entities[idUserPatrimoine],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    UserPatrimoineGeneratedActions.deleteManyUserSuccess,
    (state: UserPatrimoineState.IState, { idUsers, idUserPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserPatrimoines.reduce((entities, idUserPatrimoine) => {
            if (!state.entities[idUserPatrimoine]?.user) {
              return entities;
            }
            entities[idUserPatrimoine] = {
              ...state.entities[idUserPatrimoine],
              user: idUsers.some(
                (idUser: number) => idUser === state.entities[idUserPatrimoine]?.user
              )
                ? undefined
                : state.entities[idUserPatrimoine]?.user
            } as UserPatrimoineEntityState;
            return entities;
          }, {} as Dictionary<UserPatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    UserPatrimoineGeneratedActions.addPatrimoineSuccess,
    (state: UserPatrimoineState.IState, { idUserPatrimoine, idPatrimoine }) => {
      if (!state.entities[idUserPatrimoine]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUserPatrimoine]: {
            ...state.entities[idUserPatrimoine],
            patrimoine: idPatrimoine
          }
        }
      };
    }
  ),

  on(
    UserPatrimoineGeneratedActions.deleteManyPatrimoineSuccess,
    (state: UserPatrimoineState.IState, { idPatrimoines, idUserPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUserPatrimoines.reduce((entities, idUserPatrimoine) => {
            if (!state.entities[idUserPatrimoine]?.patrimoine) {
              return entities;
            }
            entities[idUserPatrimoine] = {
              ...state.entities[idUserPatrimoine],
              patrimoine: idPatrimoines.some(
                (idPatrimoine: number) => idPatrimoine === state.entities[idUserPatrimoine]?.patrimoine
              )
                ? undefined
                : state.entities[idUserPatrimoine]?.patrimoine
            } as UserPatrimoineEntityState;
            return entities;
          }, {} as Dictionary<UserPatrimoineEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: UserPatrimoineState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): UserPatrimoineState.IState {
  return { ...state, isLoaded, isLoading };
}
