<div mat-dialog-title [ngClass]="theme">
  <div>{{ title }}</div>
  <div class="close-dialog" [mat-dialog-close]="confirmationEnum.canceled" tabindex="-1">
    <i class="icon-ic_fluent_dismiss_24_regular flex items-center justify-center"></i>
  </div>
</div>

<div *ngIf="!isQuestionArray" mat-dialog-content>
  <p class="text-padding">{{ question }}</p>
</div>
<div *ngIf="isQuestionArray" mat-dialog-content>
  <p *ngFor="let q of questions" class="text-padding">{{ q }}</p>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="confirmationEnum.denied" tabindex="2">Non</button>
  <button
    tabindex="1"
    data-cy="confirmation-modal-yes-option"
    mat-flat-button
    color="primary"
    [mat-dialog-close]="confirmationEnum.valid"
  >
    Oui
  </button>
</div>
