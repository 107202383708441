import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteEspace } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteEspaceRelationsIds } from '@get/store/ids-interfaces';
import { SocieteEspaceGeneratedActions } from '@get/store/actions';
import { SocieteEspaceSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedSocieteEspaceService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteEspaceSelectors.selectIsLoadedSocieteEspace));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteEspaceSelectors.selectIsLoadingSocieteEspace));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteEspaceSelectors.selectIsReadyAndLoadedSocieteEspace as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteEspaces(schema: SelectSchema = {}): Observable<SocieteEspace[]> {
    return this.store$.pipe(select(SocieteEspaceSelectors.selectAllSocieteEspaces(schema))).pipe(
      switchMap(({ societeEspaces }: { societeEspaces: SocieteEspace[] }) => {
        return this.getReady(schema).pipe(mapTo(societeEspaces));
      })
    );
  }

  public selectOneSocieteEspace(
    idSocieteEspace: number,
    schema: SelectSchema = {}
  ): Observable<SocieteEspace> {
    return this.store$
      .pipe(select(SocieteEspaceSelectors.selectOneSocieteEspace(schema, idSocieteEspace)))
      .pipe(
        switchMap(({ societeEspace }: { societeEspace: SocieteEspace }) => {
          return this.getReady(schema).pipe(mapTo(societeEspace));
        })
      );
  }

  public selectAllActiveSocieteEspaces(schema: SelectSchema = {}): Observable<SocieteEspace[]> {
    return this.store$.pipe(select(SocieteEspaceSelectors.selectActiveSocieteEspaces(schema))).pipe(
      switchMap(({ societeEspaces }: { societeEspaces: SocieteEspace[] }) => {
        return this.getReady(schema).pipe(mapTo(societeEspaces));
      })
    );
  }

  public selectIdSocieteEspacesActive(): Observable<number[]> {
    return this.store$.pipe(select(SocieteEspaceSelectors.selectIdSocieteEspacesActive)).pipe(
      switchMap((idSocieteEspaces: number[]) => {
        return this.getReady().pipe(mapTo(idSocieteEspaces));
      })
    );
  }

  public getOneSocieteEspace(
    idSocieteEspace: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteEspace> {
    this.store$.dispatch(SocieteEspaceGeneratedActions.getOneSocieteEspace({ idSocieteEspace, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteEspaceGeneratedActions.normalizeManySocieteEspacesAfterUpsert,
        SocieteEspaceGeneratedActions.societeEspacesFailure,
        true
      );
    }
  }

  public getManySocieteEspaces(
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteEspace[]> {
    this.store$.dispatch(SocieteEspaceGeneratedActions.getManySocieteEspaces({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteEspaceGeneratedActions.normalizeManySocieteEspacesAfterUpsert,
        SocieteEspaceGeneratedActions.societeEspacesFailure
      );
    }
  }

  public upsertOneSocieteEspace(
    societeEspace: Partial<SocieteEspace>,
    ids: SocieteEspaceRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteEspace> {
    this.store$.dispatch(SocieteEspaceGeneratedActions.upsertOneSocieteEspace({ societeEspace, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteEspaceGeneratedActions.normalizeManySocieteEspacesAfterUpsert,
        SocieteEspaceGeneratedActions.societeEspacesFailure,
        true
      );
    }
  }

  public deleteOneSocieteEspace(
    idSocieteEspace: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(SocieteEspaceGeneratedActions.deleteOneSocieteEspace({ idSocieteEspace }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteEspaceGeneratedActions.deleteOneSocieteEspaceSuccess,
        SocieteEspaceGeneratedActions.societeEspacesFailure
      );
    }
  }

  public setActiveSocieteEspaces(idSocieteEspaces: number[]): void {
    this.store$.dispatch(SocieteEspaceGeneratedActions.clearActivesSocieteEspaces());
    this.store$.dispatch(SocieteEspaceGeneratedActions.addManyActivesSocieteEspaces({ idSocieteEspaces }));
  }
}
