import { createEntityAdapter } from '@ngrx/entity';
import { SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<SocietePatrimoineHierarchieEntityState>;

export const adapter = createEntityAdapter<SocietePatrimoineHierarchieEntityState>({
  selectId: o => o.idSocietePatrimoineHierarchie
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const societePatrimoineHierarchieFeatureKey = 'societePatrimoineHierarchie';
