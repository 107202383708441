import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueApiService } from '@get/store/api-services';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteCaracteristique } from '@get/store/configs/normalization';
import { SocieteCaracteristiqueSelectors } from '@get/store/selectors';
import { SocieteCaracteristiqueRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { CaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoix } from '@get/api-interfaces';
import { ValeurGeneratedActions } from '@get/store/actions';
import { Valeur } from '@get/api-interfaces';
import { CampagneSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { CampagneSocieteCaracteristique } from '@get/api-interfaces';
import { CampagneGeneratedActions } from '@get/store/actions';
import { Campagne } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristique } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSynthese } from '@get/api-interfaces';

export function getDefaultAddSocieteCaracteristiqueActions(societeCaracteristique: SocieteCaracteristiqueEntityState, ids?: SocieteCaracteristiqueRelationsIds): Action[] {
  const actions: Action[] = [SocieteCaracteristiqueGeneratedActions.normalizeManySocieteCaracteristiquesAfterUpsert({ societeCaracteristiques: [societeCaracteristique] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManySocieteCaracteristiqueSuccess({
        idSociete: ids.societe,
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique]
      })
    );
    actions.push(
      SocieteCaracteristiqueGeneratedActions.addSocieteSuccess({
        idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.caracteristiqueTemplate) {
    actions.push(
      CaracteristiqueTemplateGeneratedActions.addManySocieteCaracteristiqueSuccess({
        idCaracteristiqueTemplate: ids.caracteristiqueTemplate,
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique]
      })
    );
    actions.push(
      SocieteCaracteristiqueGeneratedActions.addCaracteristiqueTemplateSuccess({
        idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
        idCaracteristiqueTemplate: ids.caracteristiqueTemplate
      })
    );
  }

  if (ids?.societeComposant) {
    actions.push(
      SocieteComposantGeneratedActions.addManySocieteCaracteristiqueSuccess({
        idSocieteComposant: ids.societeComposant,
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique]
      })
    );
    actions.push(
      SocieteCaracteristiqueGeneratedActions.addSocieteComposantSuccess({
        idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
        idSocieteComposant: ids.societeComposant
      })
    );
  }

  if (ids?.societePatrimoineHierarchie) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.addManySocieteCaracteristiqueSuccess({
        idSocietePatrimoineHierarchie: ids.societePatrimoineHierarchie,
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique]
      })
    );
    actions.push(
      SocieteCaracteristiqueGeneratedActions.addSocietePatrimoineHierarchieSuccess({
        idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
        idSocietePatrimoineHierarchie: ids.societePatrimoineHierarchie
      })
    );
  }

  if (ids?.societeCaracteristiqueChoices) {
    if (!Array.isArray(ids.societeCaracteristiqueChoices)) {
      actions.push(
        SocieteCaracteristiqueChoixGeneratedActions.upsertOneSocieteCaracteristiqueChoix({
          societeCaracteristiqueChoix: {
            idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
            idSocieteCaracteristiqueChoix: ids.societeCaracteristiqueChoices as number
          } as SocieteCaracteristiqueChoix
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManySocieteCaracteristiqueChoixSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idSocieteCaracteristiqueChoices: [ids.societeCaracteristiqueChoices as number]
        })
      );
    } else {
      actions.push(
        SocieteCaracteristiqueChoixGeneratedActions.upsertManySocieteCaracteristiqueChoices({
          societeCaracteristiqueChoices: (ids.societeCaracteristiqueChoices as number[]).map(
            (idSocieteCaracteristiqueChoix: number) => ({
              idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
              idSocieteCaracteristiqueChoix
            })
          ) as SocieteCaracteristiqueChoix[]
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManySocieteCaracteristiqueChoixSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idSocieteCaracteristiqueChoices: ids.societeCaracteristiqueChoices as number[]
        })
      );
    }
  }

  if (ids?.valeurs) {
    if (!Array.isArray(ids.valeurs)) {
      actions.push(
        ValeurGeneratedActions.upsertOneValeur({
          valeur: {
            idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
            idValeur: ids.valeurs as number
          } as Valeur
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManyValeurSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idValeurs: [ids.valeurs as number]
        })
      );
    } else {
      actions.push(
        ValeurGeneratedActions.upsertManyValeurs({
          valeurs: (ids.valeurs as number[]).map(
            (idValeur: number) => ({
              idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
              idValeur
            })
          ) as Valeur[]
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManyValeurSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idValeurs: ids.valeurs as number[]
        })
      );
    }
  }

  if (ids?.campagneSocieteCaracteristiques) {
    if (!Array.isArray(ids.campagneSocieteCaracteristiques)) {
      actions.push(
        CampagneSocieteCaracteristiqueGeneratedActions.upsertOneCampagneSocieteCaracteristique({
          campagneSocieteCaracteristique: {
            idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
            idCampagneSocieteCaracteristique: ids.campagneSocieteCaracteristiques as number
          } as CampagneSocieteCaracteristique
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManyCampagneSocieteCaracteristiqueSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idCampagneSocieteCaracteristiques: [ids.campagneSocieteCaracteristiques as number]
        })
      );
    } else {
      actions.push(
        CampagneSocieteCaracteristiqueGeneratedActions.upsertManyCampagneSocieteCaracteristiques({
          campagneSocieteCaracteristiques: (ids.campagneSocieteCaracteristiques as number[]).map(
            (idCampagneSocieteCaracteristique: number) => ({
              idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
              idCampagneSocieteCaracteristique
            })
          ) as CampagneSocieteCaracteristique[]
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManyCampagneSocieteCaracteristiqueSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idCampagneSocieteCaracteristiques: ids.campagneSocieteCaracteristiques as number[]
        })
      );
    }
  }

  if (ids?.campagnes) {
    if (!Array.isArray(ids.campagnes)) {
      actions.push(
        CampagneGeneratedActions.upsertOneCampagne({
          campagne: {
            idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
            idCampagne: ids.campagnes as number
          } as Campagne & any
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManyCampagneSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idCampagnes: [ids.campagnes as number]
        })
      );
    } else {
      actions.push(
        CampagneGeneratedActions.upsertManyCampagnes({
          campagnes: (ids.campagnes as number[]).map(
            (idCampagne: number) => ({
              idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
              idCampagne
            })
          ) as Campagne[] & any[]
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManyCampagneSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idCampagnes: ids.campagnes as number[]
        })
      );
    }
  }

  if (ids?.societeConfigAnalyseSyntheseSocieteCaracteristiques) {
    if (!Array.isArray(ids.societeConfigAnalyseSyntheseSocieteCaracteristiques)) {
      actions.push(
        SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.upsertOneSocieteConfigAnalyseSyntheseSocieteCaracteristique({
          societeConfigAnalyseSyntheseSocieteCaracteristique: {
            idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
            idSocieteConfigAnalyseSyntheseSocieteCaracteristique: ids.societeConfigAnalyseSyntheseSocieteCaracteristiques as number
          } as SocieteConfigAnalyseSyntheseSocieteCaracteristique
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: [ids.societeConfigAnalyseSyntheseSocieteCaracteristiques as number]
        })
      );
    } else {
      actions.push(
        SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.upsertManySocieteConfigAnalyseSyntheseSocieteCaracteristiques({
          societeConfigAnalyseSyntheseSocieteCaracteristiques: (ids.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]).map(
            (idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number) => ({
              idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
              idSocieteConfigAnalyseSyntheseSocieteCaracteristique
            })
          ) as SocieteConfigAnalyseSyntheseSocieteCaracteristique[]
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: ids.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]
        })
      );
    }
  }

  if (ids?.societeConfigAnalyseSyntheses) {
    if (!Array.isArray(ids.societeConfigAnalyseSyntheses)) {
      actions.push(
        SocieteConfigAnalyseSyntheseGeneratedActions.upsertOneSocieteConfigAnalyseSynthese({
          societeConfigAnalyseSynthese: {
            idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
            idSocieteConfigAnalyseSynthese: ids.societeConfigAnalyseSyntheses as number
          } as SocieteConfigAnalyseSynthese & any
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManySocieteConfigAnalyseSyntheseSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idSocieteConfigAnalyseSyntheses: [ids.societeConfigAnalyseSyntheses as number]
        })
      );
    } else {
      actions.push(
        SocieteConfigAnalyseSyntheseGeneratedActions.upsertManySocieteConfigAnalyseSyntheses({
          societeConfigAnalyseSyntheses: (ids.societeConfigAnalyseSyntheses as number[]).map(
            (idSocieteConfigAnalyseSynthese: number) => ({
              idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
              idSocieteConfigAnalyseSynthese
            })
          ) as SocieteConfigAnalyseSynthese[] & any[]
        })
      );
      actions.push(
        SocieteCaracteristiqueGeneratedActions.addManySocieteConfigAnalyseSyntheseSuccess({
          idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique,
          idSocieteConfigAnalyseSyntheses: ids.societeConfigAnalyseSyntheses as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSocieteCaracteristiqueActions(societeCaracteristique: SocieteCaracteristiqueEntityState): Action[] {
  const actions: Action[] = [SocieteCaracteristiqueGeneratedActions.deleteOneSocieteCaracteristiqueSuccess({ idSocieteCaracteristique: societeCaracteristique.idSocieteCaracteristique })];

  if (societeCaracteristique.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManySocieteCaracteristiqueSuccess({
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique],
        idSocietes: [societeCaracteristique.societe as number]
      })
    );
  }

  if (societeCaracteristique.caracteristiqueTemplate) {
    actions.push(
      CaracteristiqueTemplateGeneratedActions.deleteManySocieteCaracteristiqueSuccess({
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique],
        idCaracteristiqueTemplates: [societeCaracteristique.caracteristiqueTemplate as number]
      })
    );
  }

  if (societeCaracteristique.societeComposant) {
    actions.push(
      SocieteComposantGeneratedActions.deleteManySocieteCaracteristiqueSuccess({
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique],
        idSocieteComposants: [societeCaracteristique.societeComposant as number]
      })
    );
  }

  if (societeCaracteristique.societePatrimoineHierarchie) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.deleteManySocieteCaracteristiqueSuccess({
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique],
        idSocietePatrimoineHierarchies: [societeCaracteristique.societePatrimoineHierarchie as number]
      })
    );
  }

  if (societeCaracteristique.societeCaracteristiqueChoices) {
    actions.push(
      SocieteCaracteristiqueChoixGeneratedActions.deleteManySocieteCaracteristiqueSuccess({
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique],
        idSocieteCaracteristiqueChoices: societeCaracteristique.societeCaracteristiqueChoices as number[]
      })
    );
  }

  if (societeCaracteristique.valeurs) {
    actions.push(
      ValeurGeneratedActions.deleteManySocieteCaracteristiqueSuccess({
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique],
        idValeurs: societeCaracteristique.valeurs as number[]
      })
    );
  }

  if (societeCaracteristique.campagneSocieteCaracteristiques) {
    actions.push(
      CampagneSocieteCaracteristiqueGeneratedActions.deleteManySocieteCaracteristiqueSuccess({
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique],
        idCampagneSocieteCaracteristiques: societeCaracteristique.campagneSocieteCaracteristiques as number[]
      })
    );
  }

  if (societeCaracteristique.campagnes) {
    actions.push(
      CampagneGeneratedActions.deleteManySocieteCaracteristiqueSuccess({
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique],
        idCampagnes: societeCaracteristique.campagnes as number[]
      })
    );
  }

  if (societeCaracteristique.societeConfigAnalyseSyntheseSocieteCaracteristiques) {
    actions.push(
      SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.deleteManySocieteCaracteristiqueSuccess({
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique],
        idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: societeCaracteristique.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]
      })
    );
  }

  if (societeCaracteristique.societeConfigAnalyseSyntheses) {
    actions.push(
      SocieteConfigAnalyseSyntheseGeneratedActions.deleteManySocieteCaracteristiqueSuccess({
        idSocieteCaracteristiques: [societeCaracteristique.idSocieteCaracteristique],
        idSocieteConfigAnalyseSyntheses: societeCaracteristique.societeConfigAnalyseSyntheses as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteCaracteristiqueEffects {
  constructor(
    protected actions$: Actions,
    protected societeCaracteristiqueApiService: SocieteCaracteristiqueApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocieteCaracteristiques$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteCaracteristiqueGeneratedActions.getManySocieteCaracteristiques),
      switchMap(({ params }) =>
        this.societeCaracteristiqueApiService.getSocieteCaracteristiques(params).pipe(
          map((societeCaracteristiques: SocieteCaracteristique[]) => {
            return SocieteCaracteristiqueGeneratedActions.normalizeManySocieteCaracteristiquesAfterUpsert({ societeCaracteristiques });
          }),
          catchError(error => of(SocieteCaracteristiqueGeneratedActions.societeCaracteristiquesFailure({ error })))
        )
      )
    );
  });

  getOneSocieteCaracteristique$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteCaracteristiqueGeneratedActions.getOneSocieteCaracteristique),
      switchMap(idSocieteCaracteristique =>
        this.societeCaracteristiqueApiService.getSocieteCaracteristique(idSocieteCaracteristique).pipe(
          map((societeCaracteristique: SocieteCaracteristique) => {
            return SocieteCaracteristiqueGeneratedActions.normalizeManySocieteCaracteristiquesAfterUpsert({ societeCaracteristiques: [societeCaracteristique] });
          }),
          catchError(error => of(SocieteCaracteristiqueGeneratedActions.societeCaracteristiquesFailure({ error })))
        )
      )
    );
  });

  upsertOneSocieteCaracteristique$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteCaracteristiqueGeneratedActions.upsertOneSocieteCaracteristique),
      concatMap(
        ({
          societeCaracteristique,
          ids
        }: {
          societeCaracteristique: Partial<SocieteCaracteristique>;
          ids?: SocieteCaracteristiqueRelationsIds;
        }) => {
          if (societeCaracteristique.idSocieteCaracteristique) {
            return this.societeCaracteristiqueApiService.updateSocieteCaracteristique(societeCaracteristique).pipe(
              map((societeCaracteristiqueReturned: SocieteCaracteristique) => {
                return SocieteCaracteristiqueGeneratedActions.normalizeManySocieteCaracteristiquesAfterUpsert({ societeCaracteristiques: [societeCaracteristiqueReturned] });
              }),
              catchError(error => of(SocieteCaracteristiqueGeneratedActions.societeCaracteristiquesFailure({ error })))
            );
          } else {
            return this.societeCaracteristiqueApiService.addSocieteCaracteristique(societeCaracteristique).pipe(
              mergeMap((societeCaracteristiqueReturned: SocieteCaracteristique) => getDefaultAddSocieteCaracteristiqueActions(societeCaracteristiqueReturned, ids)),
              catchError(error => of(SocieteCaracteristiqueGeneratedActions.societeCaracteristiquesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteCaracteristique$ = createEffect(() => {
    const selectSocieteCaracteristiqueState$ = this.store$.select(SocieteCaracteristiqueSelectors.selectSocieteCaracteristiqueState);
    return this.actions$.pipe(
      ofType(SocieteCaracteristiqueGeneratedActions.deleteOneSocieteCaracteristique),
      withLatestFrom(selectSocieteCaracteristiqueState$),
      concatMap(([{ idSocieteCaracteristique }, state]) =>
        this.societeCaracteristiqueApiService.deleteSocieteCaracteristique(idSocieteCaracteristique).pipe(
          mergeMap(_success => getDefaultDeleteSocieteCaracteristiqueActions(state.entities[idSocieteCaracteristique] as SocieteCaracteristiqueEntityState)),
          catchError(error => of(SocieteCaracteristiqueGeneratedActions.societeCaracteristiquesFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteCaracteristiquesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteCaracteristiqueGeneratedActions.normalizeManySocieteCaracteristiquesAfterUpsert),
      concatMap(({ societeCaracteristiques }) => {
        const actions: Action[] = getActionsToNormalizeSocieteCaracteristique(societeCaracteristiques, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteCaracteristique] Normalization After Upsert Success')];
      })
    );
  });
}
