import { RepositoryService } from '@get/services/repository';
import { SocietePatrimoineHierarchieAncetre } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocietePatrimoineHierarchieAncetreApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocietePatrimoineHierarchieAncetres(params?: any): Observable<SocietePatrimoineHierarchieAncetre[]> {
    return this.repo.getData<SocietePatrimoineHierarchieAncetre[]>('societe-patrimoine-hierarchie-ancetre', params);
  }

  public getSocietePatrimoineHierarchieAncetre(params: { idSocietePatrimoineHierarchieAncetre: number; params?: any }): Observable<SocietePatrimoineHierarchieAncetre> {
    return this.repo.getData<SocietePatrimoineHierarchieAncetre>('societe-patrimoine-hierarchie-ancetre/' + params.idSocietePatrimoineHierarchieAncetre, params.params);
  }

  public addSocietePatrimoineHierarchieAncetre(societePatrimoineHierarchieAncetre: Partial<SocietePatrimoineHierarchieAncetre>): Observable<SocietePatrimoineHierarchieAncetre> {
    return this.repo.create<SocietePatrimoineHierarchieAncetre>('societe-patrimoine-hierarchie-ancetre', societePatrimoineHierarchieAncetre);
  }

  public updateSocietePatrimoineHierarchieAncetre(societePatrimoineHierarchieAncetre: Partial<SocietePatrimoineHierarchieAncetre>): Observable<SocietePatrimoineHierarchieAncetre> {
    return this.repo.update('societe-patrimoine-hierarchie-ancetre', societePatrimoineHierarchieAncetre);
  }

  public deleteSocietePatrimoineHierarchieAncetre(idSocietePatrimoineHierarchieAncetre: number): Observable<number> {
    return this.repo.delete('societe-patrimoine-hierarchie-ancetre/' + +idSocietePatrimoineHierarchieAncetre);
  }
}

