import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteEspaceFamille, SocieteEspaceFamilleEntityState } from '@get/api-interfaces';
import { SocieteEspaceFamilleApiService } from '@get/store/api-services';
import { SocieteEspaceFamilleGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteEspaceFamille } from '@get/store/configs/normalization';
import { SocieteEspaceFamilleSelectors } from '@get/store/selectors';
import { SocieteEspaceFamilleRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';
import { SocieteComposantRattachementGeneratedActions } from '@get/store/actions';
import { SocieteComposantRattachement } from '@get/api-interfaces';
import { SocieteEspaceGeneratedActions } from '@get/store/actions';
import { SocieteEspace } from '@get/api-interfaces';

export function getDefaultAddSocieteEspaceFamilleActions(societeEspaceFamille: SocieteEspaceFamilleEntityState, ids?: SocieteEspaceFamilleRelationsIds): Action[] {
  const actions: Action[] = [SocieteEspaceFamilleGeneratedActions.normalizeManySocieteEspaceFamillesAfterUpsert({ societeEspaceFamilles: [societeEspaceFamille] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManySocieteEspaceFamilleSuccess({
        idSociete: ids.societe,
        idSocieteEspaceFamilles: [societeEspaceFamille.idSocieteEspaceFamille]
      })
    );
    actions.push(
      SocieteEspaceFamilleGeneratedActions.addSocieteSuccess({
        idSocieteEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.societePatrimoineHierarchie) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.addManySocieteEspaceFamilleSuccess({
        idSocietePatrimoineHierarchie: ids.societePatrimoineHierarchie,
        idSocieteEspaceFamilles: [societeEspaceFamille.idSocieteEspaceFamille]
      })
    );
    actions.push(
      SocieteEspaceFamilleGeneratedActions.addSocietePatrimoineHierarchieSuccess({
        idSocieteEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille,
        idSocietePatrimoineHierarchie: ids.societePatrimoineHierarchie
      })
    );
  }

  if (ids?.societeComposantRattachements) {
    if (!Array.isArray(ids.societeComposantRattachements)) {
      actions.push(
        SocieteComposantRattachementGeneratedActions.upsertOneSocieteComposantRattachement({
          societeComposantRattachement: {
            idSocieteEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille,
            idSocieteComposantRattachement: ids.societeComposantRattachements as number
          } as SocieteComposantRattachement
        })
      );
      actions.push(
        SocieteEspaceFamilleGeneratedActions.addManySocieteComposantRattachementSuccess({
          idSocieteEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille,
          idSocieteComposantRattachements: [ids.societeComposantRattachements as number]
        })
      );
    } else {
      actions.push(
        SocieteComposantRattachementGeneratedActions.upsertManySocieteComposantRattachements({
          societeComposantRattachements: (ids.societeComposantRattachements as number[]).map(
            (idSocieteComposantRattachement: number) => ({
              idSocieteEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille,
              idSocieteComposantRattachement
            })
          ) as SocieteComposantRattachement[]
        })
      );
      actions.push(
        SocieteEspaceFamilleGeneratedActions.addManySocieteComposantRattachementSuccess({
          idSocieteEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille,
          idSocieteComposantRattachements: ids.societeComposantRattachements as number[]
        })
      );
    }
  }

  if (ids?.societeEspaces) {
    if (!Array.isArray(ids.societeEspaces)) {
      actions.push(
        SocieteEspaceGeneratedActions.upsertOneSocieteEspace({
          societeEspace: {
            idSocieteEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille,
            idSocieteEspace: ids.societeEspaces as number
          } as SocieteEspace
        })
      );
      actions.push(
        SocieteEspaceFamilleGeneratedActions.addManySocieteEspaceSuccess({
          idSocieteEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille,
          idSocieteEspaces: [ids.societeEspaces as number]
        })
      );
    } else {
      actions.push(
        SocieteEspaceGeneratedActions.upsertManySocieteEspaces({
          societeEspaces: (ids.societeEspaces as number[]).map(
            (idSocieteEspace: number) => ({
              idSocieteEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille,
              idSocieteEspace
            })
          ) as SocieteEspace[]
        })
      );
      actions.push(
        SocieteEspaceFamilleGeneratedActions.addManySocieteEspaceSuccess({
          idSocieteEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille,
          idSocieteEspaces: ids.societeEspaces as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSocieteEspaceFamilleActions(societeEspaceFamille: SocieteEspaceFamilleEntityState): Action[] {
  const actions: Action[] = [SocieteEspaceFamilleGeneratedActions.deleteOneSocieteEspaceFamilleSuccess({ idSocieteEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille })];

  if (societeEspaceFamille.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManySocieteEspaceFamilleSuccess({
        idSocieteEspaceFamilles: [societeEspaceFamille.idSocieteEspaceFamille],
        idSocietes: [societeEspaceFamille.societe as number]
      })
    );
  }

  if (societeEspaceFamille.societePatrimoineHierarchie) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.deleteManySocieteEspaceFamilleSuccess({
        idSocieteEspaceFamilles: [societeEspaceFamille.idSocieteEspaceFamille],
        idSocietePatrimoineHierarchies: [societeEspaceFamille.societePatrimoineHierarchie as number]
      })
    );
  }

  if (societeEspaceFamille.societeComposantRattachements) {
    actions.push(
      SocieteComposantRattachementGeneratedActions.deleteManySocieteEspaceFamilleSuccess({
        idSocieteEspaceFamilles: [societeEspaceFamille.idSocieteEspaceFamille],
        idSocieteComposantRattachements: societeEspaceFamille.societeComposantRattachements as number[]
      })
    );
  }

  if (societeEspaceFamille.societeEspaces) {
    actions.push(
      SocieteEspaceGeneratedActions.deleteManySocieteEspaceFamilleSuccess({
        idSocieteEspaceFamilles: [societeEspaceFamille.idSocieteEspaceFamille],
        idSocieteEspaces: societeEspaceFamille.societeEspaces as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteEspaceFamilleEffects {
  constructor(
    protected actions$: Actions,
    protected societeEspaceFamilleApiService: SocieteEspaceFamilleApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocieteEspaceFamilles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteEspaceFamilleGeneratedActions.getManySocieteEspaceFamilles),
      switchMap(({ params }) =>
        this.societeEspaceFamilleApiService.getSocieteEspaceFamilles(params).pipe(
          map((societeEspaceFamilles: SocieteEspaceFamille[]) => {
            return SocieteEspaceFamilleGeneratedActions.normalizeManySocieteEspaceFamillesAfterUpsert({ societeEspaceFamilles });
          }),
          catchError(error => of(SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure({ error })))
        )
      )
    );
  });

  getOneSocieteEspaceFamille$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteEspaceFamilleGeneratedActions.getOneSocieteEspaceFamille),
      switchMap(idSocieteEspaceFamille =>
        this.societeEspaceFamilleApiService.getSocieteEspaceFamille(idSocieteEspaceFamille).pipe(
          map((societeEspaceFamille: SocieteEspaceFamille) => {
            return SocieteEspaceFamilleGeneratedActions.normalizeManySocieteEspaceFamillesAfterUpsert({ societeEspaceFamilles: [societeEspaceFamille] });
          }),
          catchError(error => of(SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure({ error })))
        )
      )
    );
  });

  upsertOneSocieteEspaceFamille$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteEspaceFamilleGeneratedActions.upsertOneSocieteEspaceFamille),
      concatMap(
        ({
          societeEspaceFamille,
          ids
        }: {
          societeEspaceFamille: Partial<SocieteEspaceFamille>;
          ids?: SocieteEspaceFamilleRelationsIds;
        }) => {
          if (societeEspaceFamille.idSocieteEspaceFamille) {
            return this.societeEspaceFamilleApiService.updateSocieteEspaceFamille(societeEspaceFamille).pipe(
              map((societeEspaceFamilleReturned: SocieteEspaceFamille) => {
                return SocieteEspaceFamilleGeneratedActions.normalizeManySocieteEspaceFamillesAfterUpsert({ societeEspaceFamilles: [societeEspaceFamilleReturned] });
              }),
              catchError(error => of(SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure({ error })))
            );
          } else {
            return this.societeEspaceFamilleApiService.addSocieteEspaceFamille(societeEspaceFamille).pipe(
              mergeMap((societeEspaceFamilleReturned: SocieteEspaceFamille) => getDefaultAddSocieteEspaceFamilleActions(societeEspaceFamilleReturned, ids)),
              catchError(error => of(SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteEspaceFamille$ = createEffect(() => {
    const selectSocieteEspaceFamilleState$ = this.store$.select(SocieteEspaceFamilleSelectors.selectSocieteEspaceFamilleState);
    return this.actions$.pipe(
      ofType(SocieteEspaceFamilleGeneratedActions.deleteOneSocieteEspaceFamille),
      withLatestFrom(selectSocieteEspaceFamilleState$),
      concatMap(([{ idSocieteEspaceFamille }, state]) =>
        this.societeEspaceFamilleApiService.deleteSocieteEspaceFamille(idSocieteEspaceFamille).pipe(
          mergeMap(_success => getDefaultDeleteSocieteEspaceFamilleActions(state.entities[idSocieteEspaceFamille] as SocieteEspaceFamilleEntityState)),
          catchError(error => of(SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteEspaceFamillesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteEspaceFamilleGeneratedActions.normalizeManySocieteEspaceFamillesAfterUpsert),
      concatMap(({ societeEspaceFamilles }) => {
        const actions: Action[] = getActionsToNormalizeSocieteEspaceFamille(societeEspaceFamilles, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteEspaceFamille] Normalization After Upsert Success')];
      })
    );
  });
}
