import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserSocieteProfil, UserSocieteProfilEntityState } from '@get/api-interfaces';
import { UserSocieteProfilRelationsIds } from '@get/store/ids-interfaces';

export const UserSocieteProfilGeneratedActions = createActionGroup({
  source: 'User Societe Profil Generated',
  events: {
    'Get One User Societe Profil': props<{ idUserSocieteProfil: number; params?: any }>(),
    'Get Many User Societe Profils': props<{ params: any }>(),
    'Add Many Actives User Societe Profils': props<{ idUserSocieteProfils: number[] }>(),
    'Delete One Active User Societe Profil': props<{ idUserSocieteProfil: number }>(),
    'Clear Actives User Societe Profils': emptyProps(),
    'Upsert One User Societe Profil': props<{ userSocieteProfil: Partial<UserSocieteProfil>; ids?: UserSocieteProfilRelationsIds; }>(),
    'Upsert Many User Societe Profils': props<{ userSocieteProfils: Partial<UserSocieteProfil>[]; ids?: UserSocieteProfilRelationsIds; }>(),
    'Upsert Many User Societe Profils Success': props<{ userSocieteProfils: UserSocieteProfilEntityState[] }>(),
    'Delete One User Societe Profil': props<{ idUserSocieteProfil: number }>(),
    'Delete One User Societe Profil Success': props<{ idUserSocieteProfil: number }>(),
    'Normalize Many User Societe Profils After Upsert': props<{ userSocieteProfils: UserSocieteProfilEntityState[] }>(),
    'User Societe Profils Failure': props<{ error: HttpErrorResponse }>(),
    'Clear User Societe Profils': emptyProps(),
    'Add User Success': props<{ idUserSocieteProfil: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idUserSocieteProfils: number[] }>(),
    'Add Societe Profil Success': props<{ idUserSocieteProfil: number; idSocieteProfil: number }>(),
    'Delete Many Societe Profil Success': props<{ idSocieteProfils: number[]; idUserSocieteProfils: number[] }>()
  }
});
