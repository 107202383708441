import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocietePatrimoineHierarchieAncetre, SocietePatrimoineHierarchieAncetreEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchieAncetreRelationsIds } from '@get/store/ids-interfaces';

export const SocietePatrimoineHierarchieAncetreGeneratedActions = createActionGroup({
  source: 'Societe Patrimoine Hierarchie Ancetre Generated',
  events: {
    'Get One Societe Patrimoine Hierarchie Ancetre': props<{ idSocietePatrimoineHierarchieAncetre: number; params?: any }>(),
    'Get Many Societe Patrimoine Hierarchie Ancetres': props<{ params: any }>(),
    'Add Many Actives Societe Patrimoine Hierarchie Ancetres': props<{ idSocietePatrimoineHierarchieAncetres: number[] }>(),
    'Delete One Active Societe Patrimoine Hierarchie Ancetre': props<{ idSocietePatrimoineHierarchieAncetre: number }>(),
    'Clear Actives Societe Patrimoine Hierarchie Ancetres': emptyProps(),
    'Upsert One Societe Patrimoine Hierarchie Ancetre': props<{ societePatrimoineHierarchieAncetre: Partial<SocietePatrimoineHierarchieAncetre>; ids?: SocietePatrimoineHierarchieAncetreRelationsIds; }>(),
    'Upsert Many Societe Patrimoine Hierarchie Ancetres': props<{ societePatrimoineHierarchieAncetres: Partial<SocietePatrimoineHierarchieAncetre>[]; ids?: SocietePatrimoineHierarchieAncetreRelationsIds; }>(),
    'Upsert Many Societe Patrimoine Hierarchie Ancetres Success': props<{ societePatrimoineHierarchieAncetres: SocietePatrimoineHierarchieAncetreEntityState[] }>(),
    'Delete One Societe Patrimoine Hierarchie Ancetre': props<{ idSocietePatrimoineHierarchieAncetre: number }>(),
    'Delete One Societe Patrimoine Hierarchie Ancetre Success': props<{ idSocietePatrimoineHierarchieAncetre: number }>(),
    'Normalize Many Societe Patrimoine Hierarchie Ancetres After Upsert': props<{ societePatrimoineHierarchieAncetres: SocietePatrimoineHierarchieAncetreEntityState[] }>(),
    'Societe Patrimoine Hierarchie Ancetres Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Patrimoine Hierarchie Ancetres': emptyProps(),
    'Add Hierarchie Ancetre Success': props<{ idSocietePatrimoineHierarchieAncetre: number; idHierarchieAncetre: number }>(),
    'Delete Many Hierarchie Ancetre Success': props<{ idHierarchieAncetre: number[]; idSocietePatrimoineHierarchieAncetres: number[] }>(),
    'Add Hierarchie Descendant Success': props<{ idSocietePatrimoineHierarchieAncetre: number; idHierarchieDescendant: number }>(),
    'Delete Many Hierarchie Descendant Success': props<{ idHierarchieDescendant: number[]; idSocietePatrimoineHierarchieAncetres: number[] }>()
  }
});
