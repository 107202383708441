import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { PatrimoineAncetreGeneratedActions } from '@get/store/actions';
import { PatrimoineAncetreState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { PatrimoineAncetreEntityState } from '@get/api-interfaces';

export const patrimoineAncetreReducersGeneratedFunctions: ReducerTypes<PatrimoineAncetreState.IState, readonly ActionCreator[]>[] = [
  on(PatrimoineAncetreGeneratedActions.getOnePatrimoineAncetre, (state: PatrimoineAncetreState.IState) => setLoadingsState(state, true)),
  on(PatrimoineAncetreGeneratedActions.getManyPatrimoineAncetres, (state: PatrimoineAncetreState.IState) => setLoadingsState(state, true)),
  on(PatrimoineAncetreGeneratedActions.upsertOnePatrimoineAncetre, (state: PatrimoineAncetreState.IState) => setLoadingsState(state, true)),

  on(PatrimoineAncetreGeneratedActions.upsertManyPatrimoineAncetresSuccess, (state: PatrimoineAncetreState.IState, { patrimoineAncetres }) =>
    PatrimoineAncetreState.adapter.upsertMany(patrimoineAncetres, setLoadingsState(state, false))
  ),
  on(PatrimoineAncetreGeneratedActions.deleteOnePatrimoineAncetre, (state: PatrimoineAncetreState.IState) => setLoadingsState(state, true)),
  on(PatrimoineAncetreGeneratedActions.deleteOnePatrimoineAncetreSuccess, (state: PatrimoineAncetreState.IState, { idPatrimoineAncetre }) =>
    PatrimoineAncetreState.adapter.removeOne(idPatrimoineAncetre, setLoadingsState(state, false))
  ),
  on(PatrimoineAncetreGeneratedActions.clearActivesPatrimoineAncetres, (state: PatrimoineAncetreState.IState) => ({ ...state, actives: [] })),
  on(PatrimoineAncetreGeneratedActions.addManyActivesPatrimoineAncetres, (state: PatrimoineAncetreState.IState, { idPatrimoineAncetres }) => ({
    ...state,
    actives: state.actives.concat(idPatrimoineAncetres)
  })),
  on(PatrimoineAncetreGeneratedActions.deleteOneActivePatrimoineAncetre, (state: PatrimoineAncetreState.IState, { idPatrimoineAncetre }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idPatrimoineAncetre)
  })),

  on(PatrimoineAncetreGeneratedActions.clearPatrimoineAncetres, () => PatrimoineAncetreState.initialState),

  on(
    PatrimoineAncetreGeneratedActions.addAncetrePatrimoineSuccess,
    (state: PatrimoineAncetreState.IState, { idPatrimoineAncetre, idAncetrePatrimoine }) => {
      if (!state.entities[idPatrimoineAncetre]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoineAncetre]: {
            ...state.entities[idPatrimoineAncetre],
            ancetrePatrimoine: idAncetrePatrimoine
          }
        }
      };
    }
  ),

  on(
    PatrimoineAncetreGeneratedActions.deleteManyAncetrePatrimoineSuccess,
    (state: PatrimoineAncetreState.IState, { idAncetrePatrimoine, idPatrimoineAncetres }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoineAncetres.reduce((entities, idPatrimoineAncetre) => {
            if (!state.entities[idPatrimoineAncetre]?.ancetrePatrimoine) {
              return entities;
            }
            entities[idPatrimoineAncetre] = {
              ...state.entities[idPatrimoineAncetre],
              patrimoine: idAncetrePatrimoine.some(
                (idPatrimoine: number) => idPatrimoine === state.entities[idPatrimoineAncetre]?.ancetrePatrimoine
              )
                ? undefined
                : state.entities[idPatrimoineAncetre]?.ancetrePatrimoine
            } as PatrimoineAncetreEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineAncetreEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineAncetreGeneratedActions.addDescendantPatrimoineSuccess,
    (state: PatrimoineAncetreState.IState, { idPatrimoineAncetre, idDescendantPatrimoine }) => {
      if (!state.entities[idPatrimoineAncetre]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoineAncetre]: {
            ...state.entities[idPatrimoineAncetre],
            descendantPatrimoine: idDescendantPatrimoine
          }
        }
      };
    }
  ),

  on(
    PatrimoineAncetreGeneratedActions.deleteManyDescendantPatrimoineSuccess,
    (state: PatrimoineAncetreState.IState, { idDescendantPatrimoine, idPatrimoineAncetres }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoineAncetres.reduce((entities, idPatrimoineAncetre) => {
            if (!state.entities[idPatrimoineAncetre]?.descendantPatrimoine) {
              return entities;
            }
            entities[idPatrimoineAncetre] = {
              ...state.entities[idPatrimoineAncetre],
              patrimoine: idDescendantPatrimoine.some(
                (idPatrimoine: number) => idPatrimoine === state.entities[idPatrimoineAncetre]?.descendantPatrimoine
              )
                ? undefined
                : state.entities[idPatrimoineAncetre]?.descendantPatrimoine
            } as PatrimoineAncetreEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineAncetreEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: PatrimoineAncetreState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): PatrimoineAncetreState.IState {
  return { ...state, isLoaded, isLoading };
}
