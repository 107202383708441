import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * IMPORTANT: RxDB creates rxjs observables outside of angulars zone
 * So you have to import the rxjs patch to ensure changedetection works correctly.
 * @link https://www.bennadel.com/blog/3448-binding-rxjs-observable-sources-outside-of-the-ngzone-in-angular-6-0-2.htm
 */
import 'zone.js/plugins/zone-patch-rxjs';

@Component({
  selector: 'get-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  constructor(private readonly iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.addIcons();
  }

  private async addIcons(): Promise<void> {
    this.iconRegistry.addSvgIcon(
      'settings',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/settings.svg')
    );
    this.iconRegistry.addSvgIcon(
      'profil',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/profil.svg')
    );
    this.iconRegistry.addSvgIcon(
      'campagne',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/campagne.svg')
    );
    this.iconRegistry.addSvgIcon(
      'analyser',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/analyser.svg')
    );
    this.iconRegistry.addSvgIcon(
      'consulter',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/consulter.svg')
    );
    this.iconRegistry.addSvgIcon(
      'alert',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/alert.svg')
    );
    this.iconRegistry.addSvgIcon(
      'question',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/question.svg')
    );
    this.iconRegistry.addSvgIcon(
      'workProgramming',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/settings.svg')
    );
  }
}
