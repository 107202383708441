import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { FichierApiService } from '@get/store/api-services';
import { FichierGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeFichier } from '@get/store/configs/normalization';
import { FichierSelectors } from '@get/store/selectors';
import { FichierRelationsIds } from '@get/store/ids-interfaces';
import { OrganisationGeneratedActions } from '@get/store/actions';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocieteComposant } from '@get/api-interfaces';
import { SocieteComposantFamilleGeneratedActions } from '@get/store/actions';
import { SocieteComposantFamille } from '@get/api-interfaces';
import { ComposantGroupeGeneratedActions } from '@get/store/actions';
import { ComposantGroupe } from '@get/api-interfaces';
import { ComposantTemplateGeneratedActions } from '@get/store/actions';
import { ComposantTemplate } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoix } from '@get/api-interfaces';
import { CaracteristiqueChoixTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueChoixTemplate } from '@get/api-interfaces';
import { ValeurFichierGeneratedActions } from '@get/store/actions';
import { ValeurFichier } from '@get/api-interfaces';
import { ValeurGeneratedActions } from '@get/store/actions';
import { Valeur } from '@get/api-interfaces';

export function getDefaultAddFichierActions(fichier: FichierEntityState, ids?: FichierRelationsIds): Action[] {
  const actions: Action[] = [FichierGeneratedActions.normalizeManyFichiersAfterUpsert({ fichiers: [fichier] })];

  if (ids?.organisation) {
    actions.push(
      OrganisationGeneratedActions.addManyFichierSuccess({
        idOrganisation: ids.organisation,
        idFichiers: [fichier.idFichier]
      })
    );
    actions.push(
      FichierGeneratedActions.addOrganisationSuccess({
        idFichier: fichier.idFichier,
        idOrganisation: ids.organisation
      })
    );
  }

  if (ids?.societeComposants) {
    if (!Array.isArray(ids.societeComposants)) {
      actions.push(
        SocieteComposantGeneratedActions.upsertOneSocieteComposant({
          societeComposant: {
            idFichier: fichier.idFichier,
            idSocieteComposant: ids.societeComposants as number
          } as SocieteComposant
        })
      );
      actions.push(
        FichierGeneratedActions.addManySocieteComposantSuccess({
          idFichier: fichier.idFichier,
          idSocieteComposants: [ids.societeComposants as number]
        })
      );
    } else {
      actions.push(
        SocieteComposantGeneratedActions.upsertManySocieteComposants({
          societeComposants: (ids.societeComposants as number[]).map(
            (idSocieteComposant: number) => ({
              idFichier: fichier.idFichier,
              idSocieteComposant
            })
          ) as SocieteComposant[]
        })
      );
      actions.push(
        FichierGeneratedActions.addManySocieteComposantSuccess({
          idFichier: fichier.idFichier,
          idSocieteComposants: ids.societeComposants as number[]
        })
      );
    }
  }

  if (ids?.societeComposantFamilles) {
    if (!Array.isArray(ids.societeComposantFamilles)) {
      actions.push(
        SocieteComposantFamilleGeneratedActions.upsertOneSocieteComposantFamille({
          societeComposantFamille: {
            idFichier: fichier.idFichier,
            idSocieteComposantFamille: ids.societeComposantFamilles as number
          } as SocieteComposantFamille
        })
      );
      actions.push(
        FichierGeneratedActions.addManySocieteComposantFamilleSuccess({
          idFichier: fichier.idFichier,
          idSocieteComposantFamilles: [ids.societeComposantFamilles as number]
        })
      );
    } else {
      actions.push(
        SocieteComposantFamilleGeneratedActions.upsertManySocieteComposantFamilles({
          societeComposantFamilles: (ids.societeComposantFamilles as number[]).map(
            (idSocieteComposantFamille: number) => ({
              idFichier: fichier.idFichier,
              idSocieteComposantFamille
            })
          ) as SocieteComposantFamille[]
        })
      );
      actions.push(
        FichierGeneratedActions.addManySocieteComposantFamilleSuccess({
          idFichier: fichier.idFichier,
          idSocieteComposantFamilles: ids.societeComposantFamilles as number[]
        })
      );
    }
  }

  if (ids?.composantGroupes) {
    if (!Array.isArray(ids.composantGroupes)) {
      actions.push(
        ComposantGroupeGeneratedActions.upsertOneComposantGroupe({
          composantGroupe: {
            idFichier: fichier.idFichier,
            idComposantGroupe: ids.composantGroupes as number
          } as ComposantGroupe
        })
      );
      actions.push(
        FichierGeneratedActions.addManyComposantGroupeSuccess({
          idFichier: fichier.idFichier,
          idComposantGroupes: [ids.composantGroupes as number]
        })
      );
    } else {
      actions.push(
        ComposantGroupeGeneratedActions.upsertManyComposantGroupes({
          composantGroupes: (ids.composantGroupes as number[]).map(
            (idComposantGroupe: number) => ({
              idFichier: fichier.idFichier,
              idComposantGroupe
            })
          ) as ComposantGroupe[]
        })
      );
      actions.push(
        FichierGeneratedActions.addManyComposantGroupeSuccess({
          idFichier: fichier.idFichier,
          idComposantGroupes: ids.composantGroupes as number[]
        })
      );
    }
  }

  if (ids?.composantTemplates) {
    if (!Array.isArray(ids.composantTemplates)) {
      actions.push(
        ComposantTemplateGeneratedActions.upsertOneComposantTemplate({
          composantTemplate: {
            idFichier: fichier.idFichier,
            idComposantTemplate: ids.composantTemplates as number
          } as ComposantTemplate
        })
      );
      actions.push(
        FichierGeneratedActions.addManyComposantTemplateSuccess({
          idFichier: fichier.idFichier,
          idComposantTemplates: [ids.composantTemplates as number]
        })
      );
    } else {
      actions.push(
        ComposantTemplateGeneratedActions.upsertManyComposantTemplates({
          composantTemplates: (ids.composantTemplates as number[]).map(
            (idComposantTemplate: number) => ({
              idFichier: fichier.idFichier,
              idComposantTemplate
            })
          ) as ComposantTemplate[]
        })
      );
      actions.push(
        FichierGeneratedActions.addManyComposantTemplateSuccess({
          idFichier: fichier.idFichier,
          idComposantTemplates: ids.composantTemplates as number[]
        })
      );
    }
  }

  if (ids?.societeCaracteristiqueChoices) {
    if (!Array.isArray(ids.societeCaracteristiqueChoices)) {
      actions.push(
        SocieteCaracteristiqueChoixGeneratedActions.upsertOneSocieteCaracteristiqueChoix({
          societeCaracteristiqueChoix: {
            idFichier: fichier.idFichier,
            idSocieteCaracteristiqueChoix: ids.societeCaracteristiqueChoices as number
          } as SocieteCaracteristiqueChoix
        })
      );
      actions.push(
        FichierGeneratedActions.addManySocieteCaracteristiqueChoixSuccess({
          idFichier: fichier.idFichier,
          idSocieteCaracteristiqueChoices: [ids.societeCaracteristiqueChoices as number]
        })
      );
    } else {
      actions.push(
        SocieteCaracteristiqueChoixGeneratedActions.upsertManySocieteCaracteristiqueChoices({
          societeCaracteristiqueChoices: (ids.societeCaracteristiqueChoices as number[]).map(
            (idSocieteCaracteristiqueChoix: number) => ({
              idFichier: fichier.idFichier,
              idSocieteCaracteristiqueChoix
            })
          ) as SocieteCaracteristiqueChoix[]
        })
      );
      actions.push(
        FichierGeneratedActions.addManySocieteCaracteristiqueChoixSuccess({
          idFichier: fichier.idFichier,
          idSocieteCaracteristiqueChoices: ids.societeCaracteristiqueChoices as number[]
        })
      );
    }
  }

  if (ids?.caracteristiqueChoixTemplates) {
    if (!Array.isArray(ids.caracteristiqueChoixTemplates)) {
      actions.push(
        CaracteristiqueChoixTemplateGeneratedActions.upsertOneCaracteristiqueChoixTemplate({
          caracteristiqueChoixTemplate: {
            idFichier: fichier.idFichier,
            idCaracteristiqueChoixTemplate: ids.caracteristiqueChoixTemplates as number
          } as CaracteristiqueChoixTemplate
        })
      );
      actions.push(
        FichierGeneratedActions.addManyCaracteristiqueChoixTemplateSuccess({
          idFichier: fichier.idFichier,
          idCaracteristiqueChoixTemplates: [ids.caracteristiqueChoixTemplates as number]
        })
      );
    } else {
      actions.push(
        CaracteristiqueChoixTemplateGeneratedActions.upsertManyCaracteristiqueChoixTemplates({
          caracteristiqueChoixTemplates: (ids.caracteristiqueChoixTemplates as number[]).map(
            (idCaracteristiqueChoixTemplate: number) => ({
              idFichier: fichier.idFichier,
              idCaracteristiqueChoixTemplate
            })
          ) as CaracteristiqueChoixTemplate[]
        })
      );
      actions.push(
        FichierGeneratedActions.addManyCaracteristiqueChoixTemplateSuccess({
          idFichier: fichier.idFichier,
          idCaracteristiqueChoixTemplates: ids.caracteristiqueChoixTemplates as number[]
        })
      );
    }
  }

  if (ids?.valeurFichiers) {
    if (!Array.isArray(ids.valeurFichiers)) {
      actions.push(
        ValeurFichierGeneratedActions.upsertOneValeurFichier({
          valeurFichier: {
            idFichier: fichier.idFichier,
            idValeurFichier: ids.valeurFichiers as number
          } as ValeurFichier
        })
      );
      actions.push(
        FichierGeneratedActions.addManyValeurFichierSuccess({
          idFichier: fichier.idFichier,
          idValeurFichiers: [ids.valeurFichiers as number]
        })
      );
    } else {
      actions.push(
        ValeurFichierGeneratedActions.upsertManyValeurFichiers({
          valeurFichiers: (ids.valeurFichiers as number[]).map(
            (idValeurFichier: number) => ({
              idFichier: fichier.idFichier,
              idValeurFichier
            })
          ) as ValeurFichier[]
        })
      );
      actions.push(
        FichierGeneratedActions.addManyValeurFichierSuccess({
          idFichier: fichier.idFichier,
          idValeurFichiers: ids.valeurFichiers as number[]
        })
      );
    }
  }

  if (ids?.valeurs) {
    if (!Array.isArray(ids.valeurs)) {
      actions.push(
        ValeurGeneratedActions.upsertOneValeur({
          valeur: {
            idFichier: fichier.idFichier,
            idValeur: ids.valeurs as number
          } as Valeur & any
        })
      );
      actions.push(
        FichierGeneratedActions.addManyValeurSuccess({
          idFichier: fichier.idFichier,
          idValeurs: [ids.valeurs as number]
        })
      );
    } else {
      actions.push(
        ValeurGeneratedActions.upsertManyValeurs({
          valeurs: (ids.valeurs as number[]).map(
            (idValeur: number) => ({
              idFichier: fichier.idFichier,
              idValeur
            })
          ) as Valeur[] & any[]
        })
      );
      actions.push(
        FichierGeneratedActions.addManyValeurSuccess({
          idFichier: fichier.idFichier,
          idValeurs: ids.valeurs as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteFichierActions(fichier: FichierEntityState): Action[] {
  const actions: Action[] = [FichierGeneratedActions.deleteOneFichierSuccess({ idFichier: fichier.idFichier })];

  if (fichier.organisation) {
    actions.push(
      OrganisationGeneratedActions.deleteManyFichierSuccess({
        idFichiers: [fichier.idFichier],
        idOrganisations: [fichier.organisation as number]
      })
    );
  }

  if (fichier.societeComposants) {
    actions.push(
      SocieteComposantGeneratedActions.deleteManyFichierSuccess({
        idFichiers: [fichier.idFichier],
        idSocieteComposants: fichier.societeComposants as number[]
      })
    );
  }

  if (fichier.societeComposantFamilles) {
    actions.push(
      SocieteComposantFamilleGeneratedActions.deleteManyFichierSuccess({
        idFichiers: [fichier.idFichier],
        idSocieteComposantFamilles: fichier.societeComposantFamilles as number[]
      })
    );
  }

  if (fichier.composantGroupes) {
    actions.push(
      ComposantGroupeGeneratedActions.deleteManyFichierSuccess({
        idFichiers: [fichier.idFichier],
        idComposantGroupes: fichier.composantGroupes as number[]
      })
    );
  }

  if (fichier.composantTemplates) {
    actions.push(
      ComposantTemplateGeneratedActions.deleteManyFichierSuccess({
        idFichiers: [fichier.idFichier],
        idComposantTemplates: fichier.composantTemplates as number[]
      })
    );
  }

  if (fichier.societeCaracteristiqueChoices) {
    actions.push(
      SocieteCaracteristiqueChoixGeneratedActions.deleteManyFichierSuccess({
        idFichiers: [fichier.idFichier],
        idSocieteCaracteristiqueChoices: fichier.societeCaracteristiqueChoices as number[]
      })
    );
  }

  if (fichier.caracteristiqueChoixTemplates) {
    actions.push(
      CaracteristiqueChoixTemplateGeneratedActions.deleteManyFichierSuccess({
        idFichiers: [fichier.idFichier],
        idCaracteristiqueChoixTemplates: fichier.caracteristiqueChoixTemplates as number[]
      })
    );
  }

  if (fichier.valeurFichiers) {
    actions.push(
      ValeurFichierGeneratedActions.deleteManyFichierSuccess({
        idFichiers: [fichier.idFichier],
        idValeurFichiers: fichier.valeurFichiers as number[]
      })
    );
  }

  if (fichier.valeurs) {
    actions.push(
      ValeurGeneratedActions.deleteManyFichierSuccess({
        idFichiers: [fichier.idFichier],
        idValeurs: fichier.valeurs as number[]
      })
    );
  }

  return actions;
}

export class GeneratedFichierEffects {
  constructor(
    protected actions$: Actions,
    protected fichierApiService: FichierApiService,
    protected store$: Store<AppState>
  ) {}

  getManyFichiers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FichierGeneratedActions.getManyFichiers),
      switchMap(({ params }) =>
        this.fichierApiService.getFichiers(params).pipe(
          map((fichiers: Fichier[]) => {
            return FichierGeneratedActions.normalizeManyFichiersAfterUpsert({ fichiers });
          }),
          catchError(error => of(FichierGeneratedActions.fichiersFailure({ error })))
        )
      )
    );
  });

  getOneFichier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FichierGeneratedActions.getOneFichier),
      switchMap(idFichier =>
        this.fichierApiService.getFichier(idFichier).pipe(
          map((fichier: Fichier) => {
            return FichierGeneratedActions.normalizeManyFichiersAfterUpsert({ fichiers: [fichier] });
          }),
          catchError(error => of(FichierGeneratedActions.fichiersFailure({ error })))
        )
      )
    );
  });

  upsertOneFichier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FichierGeneratedActions.upsertOneFichier),
      concatMap(
        ({
          fichier,
          ids
        }: {
          fichier: Partial<Fichier>;
          ids?: FichierRelationsIds;
        }) => {
          if (fichier.idFichier) {
            return this.fichierApiService.updateFichier(fichier).pipe(
              map((fichierReturned: Fichier) => {
                return FichierGeneratedActions.normalizeManyFichiersAfterUpsert({ fichiers: [fichierReturned] });
              }),
              catchError(error => of(FichierGeneratedActions.fichiersFailure({ error })))
            );
          } else {
            return this.fichierApiService.addFichier(fichier).pipe(
              mergeMap((fichierReturned: Fichier) => getDefaultAddFichierActions(fichierReturned, ids)),
              catchError(error => of(FichierGeneratedActions.fichiersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneFichier$ = createEffect(() => {
    const selectFichierState$ = this.store$.select(FichierSelectors.selectFichierState);
    return this.actions$.pipe(
      ofType(FichierGeneratedActions.deleteOneFichier),
      withLatestFrom(selectFichierState$),
      concatMap(([{ idFichier }, state]) =>
        this.fichierApiService.deleteFichier(idFichier).pipe(
          mergeMap(_success => getDefaultDeleteFichierActions(state.entities[idFichier] as FichierEntityState)),
          catchError(error => of(FichierGeneratedActions.fichiersFailure({ error })))
        )
      )
    );
  });

  normalizeManyFichiersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FichierGeneratedActions.normalizeManyFichiersAfterUpsert),
      concatMap(({ fichiers }) => {
        const actions: Action[] = getActionsToNormalizeFichier(fichiers, StoreActionType.upsert);
        return [getMultiAction(actions, '[Fichier] Normalization After Upsert Success')];
      })
    );
  });
}
