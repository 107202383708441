import { Injectable } from '@angular/core';
import { SocieteCaracteristique, SocieteCaracteristiqueToHandle } from '@get/api-interfaces';
import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';
import { GeneratedSocieteCaracteristiqueApiService } from './societe-caracteristique-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteCaracteristiqueApiService extends GeneratedSocieteCaracteristiqueApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public handleManySocieteCaracteristiques(
    params: SocieteCaracteristiqueToHandle
  ): Observable<SocieteCaracteristique[]> {
    return this.repo.create('societe-caracteristique/handle-many', params);
  }
}
