import { Injectable } from '@angular/core';
import { Organisation } from '@get/api-interfaces';
import { OrganisationActions, OrganisationGeneratedActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { catchApiActions } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GeneratedOrganisationService } from './organisation-generated.service';
import { SocieteService } from './societe.service';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService extends GeneratedOrganisationService {
  public currentOrganisation?: Organisation;
  constructor(store$: Store<AppState>, actions$: Actions, private readonly societeService: SocieteService) {
    super(store$, actions$);
  }

  public getSocietes() {
    return this.currentOrganisation?.societes;
  }

  public setCurrentOrganisation(organisation: Organisation): void {
    if (organisation) {
      this.currentOrganisation = organisation;
      this.societeService.setCurrentSociete(organisation.societes[0]);
    }
  }

  public getUserOrganisation(idUser: number, params: any = {}, getResult?: boolean): void | Observable<Organisation> {
    this.store$.dispatch(OrganisationActions.getUserOrganisation({ idUser, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganisationGeneratedActions.normalizeManyOrganisationsAfterUpsert,
        OrganisationGeneratedActions.organisationsFailure,
        true
      );
    }
  }
}
