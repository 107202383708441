import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteEspaceFamille, SocieteEspaceFamilleEntityState } from '@get/api-interfaces';
import { SocieteEspaceFamilleRelationsIds } from '@get/store/ids-interfaces';

export const SocieteEspaceFamilleGeneratedActions = createActionGroup({
  source: 'Societe Espace Famille Generated',
  events: {
    'Get One Societe Espace Famille': props<{ idSocieteEspaceFamille: number; params?: any }>(),
    'Get Many Societe Espace Familles': props<{ params: any }>(),
    'Add Many Actives Societe Espace Familles': props<{ idSocieteEspaceFamilles: number[] }>(),
    'Delete One Active Societe Espace Famille': props<{ idSocieteEspaceFamille: number }>(),
    'Clear Actives Societe Espace Familles': emptyProps(),
    'Upsert One Societe Espace Famille': props<{ societeEspaceFamille: Partial<SocieteEspaceFamille>; ids?: SocieteEspaceFamilleRelationsIds; }>(),
    'Upsert Many Societe Espace Familles': props<{ societeEspaceFamilles: Partial<SocieteEspaceFamille>[]; ids?: SocieteEspaceFamilleRelationsIds; }>(),
    'Upsert Many Societe Espace Familles Success': props<{ societeEspaceFamilles: SocieteEspaceFamilleEntityState[] }>(),
    'Delete One Societe Espace Famille': props<{ idSocieteEspaceFamille: number }>(),
    'Delete One Societe Espace Famille Success': props<{ idSocieteEspaceFamille: number }>(),
    'Normalize Many Societe Espace Familles After Upsert': props<{ societeEspaceFamilles: SocieteEspaceFamilleEntityState[] }>(),
    'Societe Espace Familles Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Espace Familles': emptyProps(),
    'Add Many Societe Composant Rattachement Success': props<{ idSocieteEspaceFamille: number; idSocieteComposantRattachements: number[] }>(),
    'Delete Many Societe Composant Rattachement Success': props<{ idSocieteComposantRattachements: number[]; idSocieteEspaceFamilles: number[] }>(),
    'Add Many Societe Espace Success': props<{ idSocieteEspaceFamille: number; idSocieteEspaces: number[] }>(),
    'Delete Many Societe Espace Success': props<{ idSocieteEspaces: number[]; idSocieteEspaceFamilles: number[] }>(),
    'Add Societe Success': props<{ idSocieteEspaceFamille: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idSocieteEspaceFamilles: number[] }>(),
    'Add Societe Patrimoine Hierarchie Success': props<{ idSocieteEspaceFamille: number; idSocietePatrimoineHierarchie: number }>(),
    'Delete Many Societe Patrimoine Hierarchie Success': props<{ idSocietePatrimoineHierarchies: number[]; idSocieteEspaceFamilles: number[] }>()
  }
});
