import { createEntityAdapter } from '@ngrx/entity';
import { DroitEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<DroitEntityState>;

export const adapter = createEntityAdapter<DroitEntityState>({
  selectId: o => o.idDroit
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const droitFeatureKey = 'droit';
