import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Campagne } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { CampagneRelationsIds } from '@get/store/ids-interfaces';
import { CampagneGeneratedActions } from '@get/store/actions';
import { CampagneSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCampagneService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CampagneSelectors.selectIsLoadedCampagne));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CampagneSelectors.selectIsLoadingCampagne));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      CampagneSelectors.selectIsReadyAndLoadedCampagne as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCampagnes(schema: SelectSchema = {}): Observable<Campagne[]> {
    return this.store$.pipe(select(CampagneSelectors.selectAllCampagnes(schema))).pipe(
      switchMap(({ campagnes }: { campagnes: Campagne[] }) => {
        return this.getReady(schema).pipe(mapTo(campagnes));
      })
    );
  }

  public selectOneCampagne(
    idCampagne: number,
    schema: SelectSchema = {}
  ): Observable<Campagne> {
    return this.store$
      .pipe(select(CampagneSelectors.selectOneCampagne(schema, idCampagne)))
      .pipe(
        switchMap(({ campagne }: { campagne: Campagne }) => {
          return this.getReady(schema).pipe(mapTo(campagne));
        })
      );
  }

  public selectAllActiveCampagnes(schema: SelectSchema = {}): Observable<Campagne[]> {
    return this.store$.pipe(select(CampagneSelectors.selectActiveCampagnes(schema))).pipe(
      switchMap(({ campagnes }: { campagnes: Campagne[] }) => {
        return this.getReady(schema).pipe(mapTo(campagnes));
      })
    );
  }

  public selectIdCampagnesActive(): Observable<number[]> {
    return this.store$.pipe(select(CampagneSelectors.selectIdCampagnesActive)).pipe(
      switchMap((idCampagnes: number[]) => {
        return this.getReady().pipe(mapTo(idCampagnes));
      })
    );
  }

  public getOneCampagne(
    idCampagne: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<Campagne> {
    this.store$.dispatch(CampagneGeneratedActions.getOneCampagne({ idCampagne, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CampagneGeneratedActions.normalizeManyCampagnesAfterUpsert,
        CampagneGeneratedActions.campagnesFailure,
        true
      );
    }
  }

  public getManyCampagnes(
    params: any = {},
    getResult?: boolean
  ): void | Observable<Campagne[]> {
    this.store$.dispatch(CampagneGeneratedActions.getManyCampagnes({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CampagneGeneratedActions.normalizeManyCampagnesAfterUpsert,
        CampagneGeneratedActions.campagnesFailure
      );
    }
  }

  public upsertOneCampagne(
    campagne: Partial<Campagne>,
    ids: CampagneRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Campagne> {
    this.store$.dispatch(CampagneGeneratedActions.upsertOneCampagne({ campagne, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CampagneGeneratedActions.normalizeManyCampagnesAfterUpsert,
        CampagneGeneratedActions.campagnesFailure,
        true
      );
    }
  }

  public deleteOneCampagne(
    idCampagne: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(CampagneGeneratedActions.deleteOneCampagne({ idCampagne }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CampagneGeneratedActions.deleteOneCampagneSuccess,
        CampagneGeneratedActions.campagnesFailure
      );
    }
  }

  public setActiveCampagnes(idCampagnes: number[]): void {
    this.store$.dispatch(CampagneGeneratedActions.clearActivesCampagnes());
    this.store$.dispatch(CampagneGeneratedActions.addManyActivesCampagnes({ idCampagnes }));
  }
}
