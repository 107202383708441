import { RepositoryService } from '@get/services/repository';
import { PatrimoineAncetre } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedPatrimoineAncetreApiService {
  constructor(protected repo: RepositoryService) {}

  public getPatrimoineAncetres(params?: any): Observable<PatrimoineAncetre[]> {
    return this.repo.getData<PatrimoineAncetre[]>('patrimoine-ancetre', params);
  }

  public getPatrimoineAncetre(params: { idPatrimoineAncetre: number; params?: any }): Observable<PatrimoineAncetre> {
    return this.repo.getData<PatrimoineAncetre>('patrimoine-ancetre/' + params.idPatrimoineAncetre, params.params);
  }

  public addPatrimoineAncetre(patrimoineAncetre: Partial<PatrimoineAncetre>): Observable<PatrimoineAncetre> {
    return this.repo.create<PatrimoineAncetre>('patrimoine-ancetre', patrimoineAncetre);
  }

  public updatePatrimoineAncetre(patrimoineAncetre: Partial<PatrimoineAncetre>): Observable<PatrimoineAncetre> {
    return this.repo.update('patrimoine-ancetre', patrimoineAncetre);
  }

  public deletePatrimoineAncetre(idPatrimoineAncetre: number): Observable<number> {
    return this.repo.delete('patrimoine-ancetre/' + +idPatrimoineAncetre);
  }
}

