import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteProfilDroit, SocieteProfilDroitEntityState } from '@get/api-interfaces';
import { SocieteProfilDroitApiService } from '@get/store/api-services';
import { SocieteProfilDroitGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteProfilDroit } from '@get/store/configs/normalization';
import { SocieteProfilDroitSelectors } from '@get/store/selectors';
import { SocieteProfilDroitRelationsIds } from '@get/store/ids-interfaces';
import { SocieteProfilGeneratedActions } from '@get/store/actions';
import { DroitGeneratedActions } from '@get/store/actions';

export function getDefaultAddSocieteProfilDroitActions(societeProfilDroit: SocieteProfilDroitEntityState, ids?: SocieteProfilDroitRelationsIds): Action[] {
  const actions: Action[] = [SocieteProfilDroitGeneratedActions.normalizeManySocieteProfilDroitsAfterUpsert({ societeProfilDroits: [societeProfilDroit] })];

  if (ids?.societeProfil) {
    actions.push(
      SocieteProfilGeneratedActions.addManySocieteProfilDroitSuccess({
        idSocieteProfil: ids.societeProfil,
        idSocieteProfilDroits: [societeProfilDroit.idSocieteProfilDroit]
      })
    );
    actions.push(
      SocieteProfilDroitGeneratedActions.addSocieteProfilSuccess({
        idSocieteProfilDroit: societeProfilDroit.idSocieteProfilDroit,
        idSocieteProfil: ids.societeProfil
      })
    );
  }

  if (ids?.droit) {
    actions.push(
      DroitGeneratedActions.addManySocieteProfilDroitSuccess({
        idDroit: ids.droit,
        idSocieteProfilDroits: [societeProfilDroit.idSocieteProfilDroit]
      })
    );
    actions.push(
      SocieteProfilDroitGeneratedActions.addDroitSuccess({
        idSocieteProfilDroit: societeProfilDroit.idSocieteProfilDroit,
        idDroit: ids.droit
      })
    );
  }

  return actions;
}

export function getDefaultDeleteSocieteProfilDroitActions(societeProfilDroit: SocieteProfilDroitEntityState): Action[] {
  const actions: Action[] = [SocieteProfilDroitGeneratedActions.deleteOneSocieteProfilDroitSuccess({ idSocieteProfilDroit: societeProfilDroit.idSocieteProfilDroit })];

  if (societeProfilDroit.societeProfil) {
    actions.push(
      SocieteProfilGeneratedActions.deleteManySocieteProfilDroitSuccess({
        idSocieteProfilDroits: [societeProfilDroit.idSocieteProfilDroit],
        idSocieteProfils: [societeProfilDroit.societeProfil as number]
      })
    );
  }

  if (societeProfilDroit.droit) {
    actions.push(
      DroitGeneratedActions.deleteManySocieteProfilDroitSuccess({
        idSocieteProfilDroits: [societeProfilDroit.idSocieteProfilDroit],
        idDroits: [societeProfilDroit.droit as number]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteProfilDroitEffects {
  constructor(
    protected actions$: Actions,
    protected societeProfilDroitApiService: SocieteProfilDroitApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocieteProfilDroits$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteProfilDroitGeneratedActions.getManySocieteProfilDroits),
      switchMap(({ params }) =>
        this.societeProfilDroitApiService.getSocieteProfilDroits(params).pipe(
          map((societeProfilDroits: SocieteProfilDroit[]) => {
            return SocieteProfilDroitGeneratedActions.normalizeManySocieteProfilDroitsAfterUpsert({ societeProfilDroits });
          }),
          catchError(error => of(SocieteProfilDroitGeneratedActions.societeProfilDroitsFailure({ error })))
        )
      )
    );
  });

  getOneSocieteProfilDroit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteProfilDroitGeneratedActions.getOneSocieteProfilDroit),
      switchMap(idSocieteProfilDroit =>
        this.societeProfilDroitApiService.getSocieteProfilDroit(idSocieteProfilDroit).pipe(
          map((societeProfilDroit: SocieteProfilDroit) => {
            return SocieteProfilDroitGeneratedActions.normalizeManySocieteProfilDroitsAfterUpsert({ societeProfilDroits: [societeProfilDroit] });
          }),
          catchError(error => of(SocieteProfilDroitGeneratedActions.societeProfilDroitsFailure({ error })))
        )
      )
    );
  });

  upsertOneSocieteProfilDroit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteProfilDroitGeneratedActions.upsertOneSocieteProfilDroit),
      concatMap(
        ({
          societeProfilDroit,
          ids
        }: {
          societeProfilDroit: Partial<SocieteProfilDroit>;
          ids?: SocieteProfilDroitRelationsIds;
        }) => {
          if (societeProfilDroit.idSocieteProfilDroit) {
            return this.societeProfilDroitApiService.updateSocieteProfilDroit(societeProfilDroit).pipe(
              map((societeProfilDroitReturned: SocieteProfilDroit) => {
                return SocieteProfilDroitGeneratedActions.normalizeManySocieteProfilDroitsAfterUpsert({ societeProfilDroits: [societeProfilDroitReturned] });
              }),
              catchError(error => of(SocieteProfilDroitGeneratedActions.societeProfilDroitsFailure({ error })))
            );
          } else {
            return this.societeProfilDroitApiService.addSocieteProfilDroit(societeProfilDroit).pipe(
              mergeMap((societeProfilDroitReturned: SocieteProfilDroit) => getDefaultAddSocieteProfilDroitActions(societeProfilDroitReturned, ids)),
              catchError(error => of(SocieteProfilDroitGeneratedActions.societeProfilDroitsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteProfilDroit$ = createEffect(() => {
    const selectSocieteProfilDroitState$ = this.store$.select(SocieteProfilDroitSelectors.selectSocieteProfilDroitState);
    return this.actions$.pipe(
      ofType(SocieteProfilDroitGeneratedActions.deleteOneSocieteProfilDroit),
      withLatestFrom(selectSocieteProfilDroitState$),
      concatMap(([{ idSocieteProfilDroit }, state]) =>
        this.societeProfilDroitApiService.deleteSocieteProfilDroit(idSocieteProfilDroit).pipe(
          mergeMap(_success => getDefaultDeleteSocieteProfilDroitActions(state.entities[idSocieteProfilDroit] as SocieteProfilDroitEntityState)),
          catchError(error => of(SocieteProfilDroitGeneratedActions.societeProfilDroitsFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteProfilDroitsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteProfilDroitGeneratedActions.normalizeManySocieteProfilDroitsAfterUpsert),
      concatMap(({ societeProfilDroits }) => {
        const actions: Action[] = getActionsToNormalizeSocieteProfilDroit(societeProfilDroits, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteProfilDroit] Normalization After Upsert Success')];
      })
    );
  });
}
