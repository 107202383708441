import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSyntheseState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';

export const societeConfigAnalyseSyntheseReducersGeneratedFunctions: ReducerTypes<SocieteConfigAnalyseSyntheseState.IState, readonly ActionCreator[]>[] = [
  on(SocieteConfigAnalyseSyntheseGeneratedActions.getOneSocieteConfigAnalyseSynthese, (state: SocieteConfigAnalyseSyntheseState.IState) => setLoadingsState(state, true)),
  on(SocieteConfigAnalyseSyntheseGeneratedActions.getManySocieteConfigAnalyseSyntheses, (state: SocieteConfigAnalyseSyntheseState.IState) => setLoadingsState(state, true)),
  on(SocieteConfigAnalyseSyntheseGeneratedActions.upsertOneSocieteConfigAnalyseSynthese, (state: SocieteConfigAnalyseSyntheseState.IState) => setLoadingsState(state, true)),

  on(SocieteConfigAnalyseSyntheseGeneratedActions.upsertManySocieteConfigAnalyseSynthesesSuccess, (state: SocieteConfigAnalyseSyntheseState.IState, { societeConfigAnalyseSyntheses }) =>
    SocieteConfigAnalyseSyntheseState.adapter.upsertMany(societeConfigAnalyseSyntheses, setLoadingsState(state, false))
  ),
  on(SocieteConfigAnalyseSyntheseGeneratedActions.deleteOneSocieteConfigAnalyseSynthese, (state: SocieteConfigAnalyseSyntheseState.IState) => setLoadingsState(state, true)),
  on(SocieteConfigAnalyseSyntheseGeneratedActions.deleteOneSocieteConfigAnalyseSyntheseSuccess, (state: SocieteConfigAnalyseSyntheseState.IState, { idSocieteConfigAnalyseSynthese }) =>
    SocieteConfigAnalyseSyntheseState.adapter.removeOne(idSocieteConfigAnalyseSynthese, setLoadingsState(state, false))
  ),
  on(SocieteConfigAnalyseSyntheseGeneratedActions.clearActivesSocieteConfigAnalyseSyntheses, (state: SocieteConfigAnalyseSyntheseState.IState) => ({ ...state, actives: [] })),
  on(SocieteConfigAnalyseSyntheseGeneratedActions.addManyActivesSocieteConfigAnalyseSyntheses, (state: SocieteConfigAnalyseSyntheseState.IState, { idSocieteConfigAnalyseSyntheses }) => ({
    ...state,
    actives: state.actives.concat(idSocieteConfigAnalyseSyntheses)
  })),
  on(SocieteConfigAnalyseSyntheseGeneratedActions.deleteOneActiveSocieteConfigAnalyseSynthese, (state: SocieteConfigAnalyseSyntheseState.IState, { idSocieteConfigAnalyseSynthese }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idSocieteConfigAnalyseSynthese)
  })),

  on(SocieteConfigAnalyseSyntheseGeneratedActions.clearSocieteConfigAnalyseSyntheses, () => SocieteConfigAnalyseSyntheseState.initialState),

  on(
    SocieteConfigAnalyseSyntheseGeneratedActions.addManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess,
    (state: SocieteConfigAnalyseSyntheseState.IState, { idSocieteConfigAnalyseSynthese, idSocieteConfigAnalyseSyntheseSocieteCaracteristiques }) => {
      if (!state.entities[idSocieteConfigAnalyseSynthese]) {
        return state;
      }
      const societeConfigAnalyseSyntheseSocieteCaracteristiques = (state.entities[idSocieteConfigAnalyseSynthese]?.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteConfigAnalyseSynthese]: {
            ...state.entities[idSocieteConfigAnalyseSynthese],
            societeConfigAnalyseSyntheseSocieteCaracteristiques: societeConfigAnalyseSyntheseSocieteCaracteristiques.concat(
              idSocieteConfigAnalyseSyntheseSocieteCaracteristiques.filter(id => societeConfigAnalyseSyntheseSocieteCaracteristiques.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteConfigAnalyseSyntheseGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess,
    (state: SocieteConfigAnalyseSyntheseState.IState, { idSocieteConfigAnalyseSyntheseSocieteCaracteristiques, idSocieteConfigAnalyseSyntheses }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteConfigAnalyseSyntheses.reduce((entities, idSocieteConfigAnalyseSynthese) => {
            if (!state.entities[idSocieteConfigAnalyseSynthese]?.societeConfigAnalyseSyntheseSocieteCaracteristiques) {
              return entities;
            }
            entities[idSocieteConfigAnalyseSynthese] = {
              ...state.entities[idSocieteConfigAnalyseSynthese],
              societeConfigAnalyseSyntheseSocieteCaracteristiques: (state.entities[idSocieteConfigAnalyseSynthese]?.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[])?.filter(
                (idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number) => !idSocieteConfigAnalyseSyntheseSocieteCaracteristiques.some((id: number) => id === idSocieteConfigAnalyseSyntheseSocieteCaracteristique)
              )
            } as SocieteConfigAnalyseSyntheseEntityState;
            return entities;
          }, {} as Dictionary<SocieteConfigAnalyseSyntheseEntityState>)
        }
      };
    }
  ),

  on(
    SocieteConfigAnalyseSyntheseGeneratedActions.addManySocieteCaracteristiqueSuccess,
    (state: SocieteConfigAnalyseSyntheseState.IState, { idSocieteConfigAnalyseSynthese, idSocieteCaracteristiques }) => {
      if (!state.entities[idSocieteConfigAnalyseSynthese]) {
        return state;
      }
      const societeCaracteristiques = (state.entities[idSocieteConfigAnalyseSynthese]?.societeCaracteristiques as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteConfigAnalyseSynthese]: {
            ...state.entities[idSocieteConfigAnalyseSynthese],
            societeCaracteristiques: societeCaracteristiques.concat(
              idSocieteCaracteristiques.filter(id => societeCaracteristiques.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteConfigAnalyseSyntheseGeneratedActions.deleteManySocieteCaracteristiqueSuccess,
    (state: SocieteConfigAnalyseSyntheseState.IState, { idSocieteCaracteristiques, idSocieteConfigAnalyseSyntheses }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteConfigAnalyseSyntheses.reduce((entities, idSocieteConfigAnalyseSynthese) => {
            if (!state.entities[idSocieteConfigAnalyseSynthese]?.societeCaracteristiques) {
              return entities;
            }
            entities[idSocieteConfigAnalyseSynthese] = {
              ...state.entities[idSocieteConfigAnalyseSynthese],
              societeCaracteristiques: (state.entities[idSocieteConfigAnalyseSynthese]?.societeCaracteristiques as number[])?.filter(
                (idSocieteCaracteristique: number) => !idSocieteCaracteristiques.some((id: number) => id === idSocieteCaracteristique)
              )
            } as SocieteConfigAnalyseSyntheseEntityState;
            return entities;
          }, {} as Dictionary<SocieteConfigAnalyseSyntheseEntityState>)
        }
      };
    }
  ),

  on(
    SocieteConfigAnalyseSyntheseGeneratedActions.addSocieteSuccess,
    (state: SocieteConfigAnalyseSyntheseState.IState, { idSocieteConfigAnalyseSynthese, idSociete }) => {
      if (!state.entities[idSocieteConfigAnalyseSynthese]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteConfigAnalyseSynthese]: {
            ...state.entities[idSocieteConfigAnalyseSynthese],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    SocieteConfigAnalyseSyntheseGeneratedActions.deleteManySocieteSuccess,
    (state: SocieteConfigAnalyseSyntheseState.IState, { idSocietes, idSocieteConfigAnalyseSyntheses }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteConfigAnalyseSyntheses.reduce((entities, idSocieteConfigAnalyseSynthese) => {
            if (!state.entities[idSocieteConfigAnalyseSynthese]?.societe) {
              return entities;
            }
            entities[idSocieteConfigAnalyseSynthese] = {
              ...state.entities[idSocieteConfigAnalyseSynthese],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idSocieteConfigAnalyseSynthese]?.societe
              )
                ? undefined
                : state.entities[idSocieteConfigAnalyseSynthese]?.societe
            } as SocieteConfigAnalyseSyntheseEntityState;
            return entities;
          }, {} as Dictionary<SocieteConfigAnalyseSyntheseEntityState>)
        }
      };
    }
  ),

  on(
    SocieteConfigAnalyseSyntheseGeneratedActions.addUserSuccess,
    (state: SocieteConfigAnalyseSyntheseState.IState, { idSocieteConfigAnalyseSynthese, idUser }) => {
      if (!state.entities[idSocieteConfigAnalyseSynthese]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteConfigAnalyseSynthese]: {
            ...state.entities[idSocieteConfigAnalyseSynthese],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    SocieteConfigAnalyseSyntheseGeneratedActions.deleteManyUserSuccess,
    (state: SocieteConfigAnalyseSyntheseState.IState, { idUsers, idSocieteConfigAnalyseSyntheses }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteConfigAnalyseSyntheses.reduce((entities, idSocieteConfigAnalyseSynthese) => {
            if (!state.entities[idSocieteConfigAnalyseSynthese]?.user) {
              return entities;
            }
            entities[idSocieteConfigAnalyseSynthese] = {
              ...state.entities[idSocieteConfigAnalyseSynthese],
              user: idUsers.some(
                (idUser: number) => idUser === state.entities[idSocieteConfigAnalyseSynthese]?.user
              )
                ? undefined
                : state.entities[idSocieteConfigAnalyseSynthese]?.user
            } as SocieteConfigAnalyseSyntheseEntityState;
            return entities;
          }, {} as Dictionary<SocieteConfigAnalyseSyntheseEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteConfigAnalyseSyntheseState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): SocieteConfigAnalyseSyntheseState.IState {
  return { ...state, isLoaded, isLoading };
}
