import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoix, SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { CampagneSocieteCaracteristique, CampagneSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { Campagne, CampagneEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristique, SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSynthese, SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { CaracteristiqueTemplate, CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteCaracteristiqueState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeCaracteristiqueRelations: string[] = ['societeCaracteristiqueChoices','valeurs','campagneSocieteCaracteristiques','campagnes','societeConfigAnalyseSyntheseSocieteCaracteristiques','societeConfigAnalyseSyntheses','societes','caracteristiqueTemplates','societeComposants','societePatrimoineHierarchies',];

export const { selectEntities, selectAll } = SocieteCaracteristiqueState.adapter.getSelectors();

export const selectSocieteCaracteristiqueState = createFeatureSelector<SocieteCaracteristiqueState.IState>(SocieteCaracteristiqueState.societeCaracteristiqueFeatureKey);

export const selectIsLoadedSocieteCaracteristique = createSelector(
  selectSocieteCaracteristiqueState,
  (state: SocieteCaracteristiqueState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteCaracteristique = createSelector(
  selectSocieteCaracteristiqueState,
  (state: SocieteCaracteristiqueState.IState) => state.isLoading
);

export const selectIsReadySocieteCaracteristique = createSelector(
  selectSocieteCaracteristiqueState,
  (state: SocieteCaracteristiqueState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteCaracteristique = createSelector(
  selectSocieteCaracteristiqueState,
  (state: SocieteCaracteristiqueState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteCaracteristiquesEntities = createSelector(selectSocieteCaracteristiqueState, selectEntities);

export const selectSocieteCaracteristiquesArray = createSelector(selectSocieteCaracteristiqueState, selectAll);

export const selectIdSocieteCaracteristiquesActive = createSelector(
  selectSocieteCaracteristiqueState,
  (state: SocieteCaracteristiqueState.IState) => state.actives
);

const societeCaracteristiquesInObject = (societeCaracteristiques: Dictionary<SocieteCaracteristiqueEntityState>) => ({ societeCaracteristiques })

const selectSocieteCaracteristiquesEntitiesDictionary = createSelector(selectSocieteCaracteristiquesEntities, societeCaracteristiquesInObject);

const selectAllSocieteCaracteristiquesObject = createSelector(selectSocieteCaracteristiquesEntities, societeCaracteristiques => {
  return hydrateAll({ societeCaracteristiques });
});

const selectOneSocieteCaracteristiqueDictionary = (idSocieteCaracteristique : number) =>
  createSelector(selectSocieteCaracteristiquesEntities, societeCaracteristiques => ({
    societeCaracteristiques: { [idSocieteCaracteristique]: societeCaracteristiques[idSocieteCaracteristique] }
  }));

const selectOneSocieteCaracteristiqueDictionaryWithoutChild = (idSocieteCaracteristique : number) =>
  createSelector(selectSocieteCaracteristiquesEntities, societeCaracteristiques => ({
    societeCaracteristique: societeCaracteristiques[idSocieteCaracteristique]
  }));

const selectActiveSocieteCaracteristiquesEntities = createSelector(
  selectIdSocieteCaracteristiquesActive,
  selectSocieteCaracteristiquesEntities,
  (actives: number[], societeCaracteristiques: Dictionary<SocieteCaracteristiqueEntityState>) => getSocieteCaracteristiquesFromActives(actives, societeCaracteristiques)
);

function getSocieteCaracteristiquesFromActives(
  actives: number[],
  societeCaracteristiques: Dictionary<SocieteCaracteristiqueEntityState>
): Dictionary<SocieteCaracteristiqueEntityState> {
  return actives.reduce((acc, idActive) => {
    if (societeCaracteristiques[idActive]) {
      acc[idActive] = societeCaracteristiques[idActive];
    }
    return acc;
  }, {} as Dictionary<SocieteCaracteristiqueEntityState>);
}

const selectAllSocieteCaracteristiquesSelectors: Dictionary<Selector> = {};
export function selectAllSocieteCaracteristiques(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteCaracteristique>(
      schema,
      selectAllSocieteCaracteristiquesSelectors,
      selectSocieteCaracteristiquesEntitiesDictionary,
      getRelationSelectors,
      societeCaracteristiqueRelations,
      hydrateAll,
      'societeCaracteristique'
    );
  } else {
    return selectAllSocieteCaracteristiquesObject;
  }
}

export function selectAllSocieteCaracteristiquesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'societeCaracteristiques'
): Selector {
  return createSelector(selectAllSocieteCaracteristiques(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteCaracteristiqueEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeCaracteristiques.length; i++) {
      res[customKey][result.societeCaracteristiques[i].idSocieteCaracteristique] = result.societeCaracteristiques[i];
    }
    return res;
  });
}

export function selectOneSocieteCaracteristique(
  schema: SelectSchema = {},
  idSocieteCaracteristique: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteCaracteristiqueDictionary(idSocieteCaracteristique)];
  selectors.push(...getRelationSelectors(schema, societeCaracteristiqueRelations, 'societeCaracteristique'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteCaracteristiqueDictionaryWithoutChild(idSocieteCaracteristique);
  }
}

export function selectActiveSocieteCaracteristiques(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveSocieteCaracteristiquesEntities, societeCaracteristiques => ({
      societeCaracteristiques
    }))
  ];
  selectors.push(...getRelationSelectors(schema, societeCaracteristiqueRelations, 'societeCaracteristique'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  societeCaracteristiques: Dictionary<SocieteCaracteristiqueEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  caracteristiqueTemplates?: Dictionary<CaracteristiqueTemplateEntityState>;
  societeComposants?: Dictionary<SocieteComposantEntityState>;
  societePatrimoineHierarchies?: Dictionary<SocietePatrimoineHierarchieEntityState>;
  societeCaracteristiqueChoices?: Dictionary<SocieteCaracteristiqueChoixEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
  campagneSocieteCaracteristiques?: Dictionary<CampagneSocieteCaracteristiqueEntityState>;
  campagnes?: Dictionary<CampagneEntityState>;
  societeConfigAnalyseSyntheseSocieteCaracteristiques?: Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState>;
  societeConfigAnalyseSyntheses?: Dictionary<SocieteConfigAnalyseSyntheseEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeCaracteristiques: (SocieteCaracteristique | null)[] } {
  const {
    societeCaracteristiques,
    societes,
    caracteristiqueTemplates,
    societeComposants,
    societePatrimoineHierarchies,
    societeCaracteristiqueChoices,
    valeurs,
    campagneSocieteCaracteristiques,
    campagnes,
    societeConfigAnalyseSyntheseSocieteCaracteristiques,
    societeConfigAnalyseSyntheses
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeCaracteristiques: Object.keys(societeCaracteristiques).map(idSocieteCaracteristique =>
      hydrate(
        societeCaracteristiques[idSocieteCaracteristique] as SocieteCaracteristiqueEntityState,
        societes,
        caracteristiqueTemplates,
        societeComposants,
        societePatrimoineHierarchies,
        societeCaracteristiqueChoices,
        valeurs,
        campagneSocieteCaracteristiques,
        campagnes,
        societeConfigAnalyseSyntheseSocieteCaracteristiques,
        societeConfigAnalyseSyntheses
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeCaracteristique: SocieteCaracteristiqueEntityState | null } {
  const {
    societeCaracteristiques,
    societes,
    caracteristiqueTemplates,
    societeComposants,
    societePatrimoineHierarchies,
    societeCaracteristiqueChoices,
    valeurs,
    campagneSocieteCaracteristiques,
    campagnes,
    societeConfigAnalyseSyntheseSocieteCaracteristiques,
    societeConfigAnalyseSyntheses
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeCaracteristique = Object.values(societeCaracteristiques)[0];
  return {
    societeCaracteristique: hydrate(
      societeCaracteristique as SocieteCaracteristiqueEntityState,
      societes,
      caracteristiqueTemplates,
      societeComposants,
      societePatrimoineHierarchies,
      societeCaracteristiqueChoices,
      valeurs,
      campagneSocieteCaracteristiques,
      campagnes,
      societeConfigAnalyseSyntheseSocieteCaracteristiques,
      societeConfigAnalyseSyntheses
    )
  };
}

function hydrate(
  societeCaracteristique: SocieteCaracteristiqueEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  caracteristiqueTemplateEntities?: Dictionary<CaracteristiqueTemplateEntityState>,
  societeComposantEntities?: Dictionary<SocieteComposantEntityState>,
  societePatrimoineHierarchieEntities?: Dictionary<SocietePatrimoineHierarchieEntityState>,
  societeCaracteristiqueChoixEntities?: Dictionary<SocieteCaracteristiqueChoixEntityState>,
  valeurEntities?: Dictionary<ValeurEntityState>,
  campagneSocieteCaracteristiqueEntities?: Dictionary<CampagneSocieteCaracteristiqueEntityState>,
  campagneEntities?: Dictionary<CampagneEntityState>,
  societeConfigAnalyseSyntheseSocieteCaracteristiqueEntities?: Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState>,
  societeConfigAnalyseSyntheseEntities?: Dictionary<SocieteConfigAnalyseSyntheseEntityState>,
): SocieteCaracteristique | null {
  if (!societeCaracteristique) {
    return null;
  }

  const societeCaracteristiqueHydrated: SocieteCaracteristiqueEntityState = { ...societeCaracteristique };
  if (societeEntities) {
    societeCaracteristiqueHydrated.societe = societeEntities[societeCaracteristique.societe as number] as Societe;
  } else {
    delete societeCaracteristiqueHydrated.societe;
  }
  if (caracteristiqueTemplateEntities) {
    societeCaracteristiqueHydrated.caracteristiqueTemplate = caracteristiqueTemplateEntities[societeCaracteristique.caracteristiqueTemplate as number] as CaracteristiqueTemplate;
  } else {
    delete societeCaracteristiqueHydrated.caracteristiqueTemplate;
  }
  if (societeComposantEntities) {
    societeCaracteristiqueHydrated.societeComposant = societeComposantEntities[societeCaracteristique.societeComposant as number] as SocieteComposant;
  } else {
    delete societeCaracteristiqueHydrated.societeComposant;
  }
  if (societePatrimoineHierarchieEntities) {
    societeCaracteristiqueHydrated.societePatrimoineHierarchie = societePatrimoineHierarchieEntities[societeCaracteristique.societePatrimoineHierarchie as number] as SocietePatrimoineHierarchie;
  } else {
    delete societeCaracteristiqueHydrated.societePatrimoineHierarchie;
  }

  if (societeCaracteristiqueChoixEntities) {
    societeCaracteristiqueHydrated.societeCaracteristiqueChoices = ((societeCaracteristiqueHydrated.societeCaracteristiqueChoices as number[]) || []).map(
      id => societeCaracteristiqueChoixEntities[id]
    ) as SocieteCaracteristiqueChoix[];
  } else {
    delete societeCaracteristiqueHydrated.societeCaracteristiqueChoices;
  }

  if (valeurEntities) {
    societeCaracteristiqueHydrated.valeurs = ((societeCaracteristiqueHydrated.valeurs as number[]) || []).map(
      id => valeurEntities[id]
    ) as Valeur[];
  } else {
    delete societeCaracteristiqueHydrated.valeurs;
  }

  if (campagneSocieteCaracteristiqueEntities) {
    societeCaracteristiqueHydrated.campagneSocieteCaracteristiques = ((societeCaracteristiqueHydrated.campagneSocieteCaracteristiques as number[]) || []).map(
      id => campagneSocieteCaracteristiqueEntities[id]
    ) as CampagneSocieteCaracteristique[];
  } else {
    delete societeCaracteristiqueHydrated.campagneSocieteCaracteristiques;
  }

  if (campagneEntities) {
    societeCaracteristiqueHydrated.campagnes = ((societeCaracteristiqueHydrated.campagnes as number[]) || []).map(
      id => campagneEntities[id]
    ) as Campagne[];
  } else {
    delete societeCaracteristiqueHydrated.campagnes;
  }

  if (societeConfigAnalyseSyntheseSocieteCaracteristiqueEntities) {
    societeCaracteristiqueHydrated.societeConfigAnalyseSyntheseSocieteCaracteristiques = ((societeCaracteristiqueHydrated.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]) || []).map(
      id => societeConfigAnalyseSyntheseSocieteCaracteristiqueEntities[id]
    ) as SocieteConfigAnalyseSyntheseSocieteCaracteristique[];
  } else {
    delete societeCaracteristiqueHydrated.societeConfigAnalyseSyntheseSocieteCaracteristiques;
  }

  if (societeConfigAnalyseSyntheseEntities) {
    societeCaracteristiqueHydrated.societeConfigAnalyseSyntheses = ((societeCaracteristiqueHydrated.societeConfigAnalyseSyntheses as number[]) || []).map(
      id => societeConfigAnalyseSyntheseEntities[id]
    ) as SocieteConfigAnalyseSynthese[];
  } else {
    delete societeCaracteristiqueHydrated.societeConfigAnalyseSyntheses;
  }

  return societeCaracteristiqueHydrated as SocieteCaracteristique;
}
