import { CaracteristiqueChoixTemplate, SocieteCaracteristiqueChoix } from '@get/api-interfaces';

export function sortCaracteristiqueChoices(
  choices: SocieteCaracteristiqueChoix[] | CaracteristiqueChoixTemplate[]
): SocieteCaracteristiqueChoix[] | CaracteristiqueChoixTemplate[] {
  return choices?.sort(
    (
      CaracteristiqueChoixA: SocieteCaracteristiqueChoix | CaracteristiqueChoixTemplate,
      CaracteristiqueChoixB: SocieteCaracteristiqueChoix | CaracteristiqueChoixTemplate
    ) => CaracteristiqueChoixA.ordre - CaracteristiqueChoixB.ordre
  );
}
