import { RepositoryService } from '@get/services/repository';
import { CampagneUser } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCampagneUserApiService {
  constructor(protected repo: RepositoryService) {}

  public getCampagneUsers(params?: any): Observable<CampagneUser[]> {
    return this.repo.getData<CampagneUser[]>('campagne-user', params);
  }

  public getCampagneUser(params: { idCampagneUser: number; params?: any }): Observable<CampagneUser> {
    return this.repo.getData<CampagneUser>('campagne-user/' + params.idCampagneUser, params.params);
  }

  public addCampagneUser(campagneUser: Partial<CampagneUser>): Observable<CampagneUser> {
    return this.repo.create<CampagneUser>('campagne-user', campagneUser);
  }

  public updateCampagneUser(campagneUser: Partial<CampagneUser>): Observable<CampagneUser> {
    return this.repo.update('campagne-user', campagneUser);
  }

  public deleteCampagneUser(idCampagneUser: number): Observable<number> {
    return this.repo.delete('campagne-user/' + +idCampagneUser);
  }
}

