import dayjs from 'dayjs';

export function formatDate(date: Date, format = 'DD/MM/YY'): string {
  return dayjs(date).isValid() ? dayjs(date).format(format) : '';
}

export function formatDateFullYear(date: Date): string {
  return formatDate(date, 'DD/MM/YYYY');
}

export function formatDateTime(date: Date): string {
  return formatDate(date, 'DD/MM/YY HH:mm');
}

export function formatTime(date: Date): string {
  return formatDate(date, 'HH:mm');
}

export function daysFromNow(date: Date): string {
  return date ? dayjs(new Date()).diff(dayjs(date), 'day') + 'j' : '';
}

export function dayjsDate(date: Date): dayjs.Dayjs | null {
  return date && dayjs(date).isValid() ? dayjs(date) : null;
}
