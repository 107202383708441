import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ComposantTemplateGeneratedActions } from '@get/store/actions';
import { ComposantTemplateState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ComposantTemplateEntityState } from '@get/api-interfaces';

export const composantTemplateReducersGeneratedFunctions: ReducerTypes<ComposantTemplateState.IState, readonly ActionCreator[]>[] = [
  on(ComposantTemplateGeneratedActions.getOneComposantTemplate, (state: ComposantTemplateState.IState) => setLoadingsState(state, true)),
  on(ComposantTemplateGeneratedActions.getManyComposantTemplates, (state: ComposantTemplateState.IState) => setLoadingsState(state, true)),
  on(ComposantTemplateGeneratedActions.upsertOneComposantTemplate, (state: ComposantTemplateState.IState) => setLoadingsState(state, true)),

  on(ComposantTemplateGeneratedActions.upsertManyComposantTemplatesSuccess, (state: ComposantTemplateState.IState, { composantTemplates }) =>
    ComposantTemplateState.adapter.upsertMany(composantTemplates, setLoadingsState(state, false))
  ),
  on(ComposantTemplateGeneratedActions.deleteOneComposantTemplate, (state: ComposantTemplateState.IState) => setLoadingsState(state, true)),
  on(ComposantTemplateGeneratedActions.deleteOneComposantTemplateSuccess, (state: ComposantTemplateState.IState, { idComposantTemplate }) =>
    ComposantTemplateState.adapter.removeOne(idComposantTemplate, setLoadingsState(state, false))
  ),
  on(ComposantTemplateGeneratedActions.clearActivesComposantTemplates, (state: ComposantTemplateState.IState) => ({ ...state, actives: [] })),
  on(ComposantTemplateGeneratedActions.addManyActivesComposantTemplates, (state: ComposantTemplateState.IState, { idComposantTemplates }) => ({
    ...state,
    actives: state.actives.concat(idComposantTemplates)
  })),
  on(ComposantTemplateGeneratedActions.deleteOneActiveComposantTemplate, (state: ComposantTemplateState.IState, { idComposantTemplate }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idComposantTemplate)
  })),

  on(ComposantTemplateGeneratedActions.clearComposantTemplates, () => ComposantTemplateState.initialState),

  on(
    ComposantTemplateGeneratedActions.addManyCaracteristiqueTemplateSuccess,
    (state: ComposantTemplateState.IState, { idComposantTemplate, idCaracteristiqueTemplates }) => {
      if (!state.entities[idComposantTemplate]) {
        return state;
      }
      const caracteristiqueTemplates = (state.entities[idComposantTemplate]?.caracteristiqueTemplates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposantTemplate]: {
            ...state.entities[idComposantTemplate],
            caracteristiqueTemplates: caracteristiqueTemplates.concat(
              idCaracteristiqueTemplates.filter(id => caracteristiqueTemplates.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ComposantTemplateGeneratedActions.deleteManyCaracteristiqueTemplateSuccess,
    (state: ComposantTemplateState.IState, { idCaracteristiqueTemplates, idComposantTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposantTemplates.reduce((entities, idComposantTemplate) => {
            if (!state.entities[idComposantTemplate]?.caracteristiqueTemplates) {
              return entities;
            }
            entities[idComposantTemplate] = {
              ...state.entities[idComposantTemplate],
              caracteristiqueTemplates: (state.entities[idComposantTemplate]?.caracteristiqueTemplates as number[])?.filter(
                (idCaracteristiqueTemplate: number) => !idCaracteristiqueTemplates.some((id: number) => id === idCaracteristiqueTemplate)
              )
            } as ComposantTemplateEntityState;
            return entities;
          }, {} as Dictionary<ComposantTemplateEntityState>)
        }
      };
    }
  ),

  on(
    ComposantTemplateGeneratedActions.addManySocieteComposantSuccess,
    (state: ComposantTemplateState.IState, { idComposantTemplate, idSocieteComposants }) => {
      if (!state.entities[idComposantTemplate]) {
        return state;
      }
      const societeComposants = (state.entities[idComposantTemplate]?.societeComposants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposantTemplate]: {
            ...state.entities[idComposantTemplate],
            societeComposants: societeComposants.concat(
              idSocieteComposants.filter(id => societeComposants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ComposantTemplateGeneratedActions.deleteManySocieteComposantSuccess,
    (state: ComposantTemplateState.IState, { idSocieteComposants, idComposantTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposantTemplates.reduce((entities, idComposantTemplate) => {
            if (!state.entities[idComposantTemplate]?.societeComposants) {
              return entities;
            }
            entities[idComposantTemplate] = {
              ...state.entities[idComposantTemplate],
              societeComposants: (state.entities[idComposantTemplate]?.societeComposants as number[])?.filter(
                (idSocieteComposant: number) => !idSocieteComposants.some((id: number) => id === idSocieteComposant)
              )
            } as ComposantTemplateEntityState;
            return entities;
          }, {} as Dictionary<ComposantTemplateEntityState>)
        }
      };
    }
  ),

  on(
    ComposantTemplateGeneratedActions.addFichierSuccess,
    (state: ComposantTemplateState.IState, { idComposantTemplate, idFichier }) => {
      if (!state.entities[idComposantTemplate]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposantTemplate]: {
            ...state.entities[idComposantTemplate],
            fichier: idFichier
          }
        }
      };
    }
  ),

  on(
    ComposantTemplateGeneratedActions.deleteManyFichierSuccess,
    (state: ComposantTemplateState.IState, { idFichiers, idComposantTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposantTemplates.reduce((entities, idComposantTemplate) => {
            if (!state.entities[idComposantTemplate]?.fichier) {
              return entities;
            }
            entities[idComposantTemplate] = {
              ...state.entities[idComposantTemplate],
              fichier: idFichiers.some(
                (idFichier: number) => idFichier === state.entities[idComposantTemplate]?.fichier
              )
                ? undefined
                : state.entities[idComposantTemplate]?.fichier
            } as ComposantTemplateEntityState;
            return entities;
          }, {} as Dictionary<ComposantTemplateEntityState>)
        }
      };
    }
  ),

  on(
    ComposantTemplateGeneratedActions.addComposantGroupeSuccess,
    (state: ComposantTemplateState.IState, { idComposantTemplate, idComposantGroupe }) => {
      if (!state.entities[idComposantTemplate]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposantTemplate]: {
            ...state.entities[idComposantTemplate],
            composantGroupe: idComposantGroupe
          }
        }
      };
    }
  ),

  on(
    ComposantTemplateGeneratedActions.deleteManyComposantGroupeSuccess,
    (state: ComposantTemplateState.IState, { idComposantGroupes, idComposantTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposantTemplates.reduce((entities, idComposantTemplate) => {
            if (!state.entities[idComposantTemplate]?.composantGroupe) {
              return entities;
            }
            entities[idComposantTemplate] = {
              ...state.entities[idComposantTemplate],
              composantGroupe: idComposantGroupes.some(
                (idComposantGroupe: number) => idComposantGroupe === state.entities[idComposantTemplate]?.composantGroupe
              )
                ? undefined
                : state.entities[idComposantTemplate]?.composantGroupe
            } as ComposantTemplateEntityState;
            return entities;
          }, {} as Dictionary<ComposantTemplateEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ComposantTemplateState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): ComposantTemplateState.IState {
  return { ...state, isLoaded, isLoading };
}
