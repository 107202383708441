import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteEspace, SocieteEspaceEntityState } from '@get/api-interfaces';
import { Espace, EspaceEntityState } from '@get/api-interfaces';
import { SocieteEspaceFamille, SocieteEspaceFamilleEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteEspaceState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeEspaceRelations: string[] = ['espaces','societeEspaceFamilles',];

export const { selectEntities, selectAll } = SocieteEspaceState.adapter.getSelectors();

export const selectSocieteEspaceState = createFeatureSelector<SocieteEspaceState.IState>(SocieteEspaceState.societeEspaceFeatureKey);

export const selectIsLoadedSocieteEspace = createSelector(
  selectSocieteEspaceState,
  (state: SocieteEspaceState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteEspace = createSelector(
  selectSocieteEspaceState,
  (state: SocieteEspaceState.IState) => state.isLoading
);

export const selectIsReadySocieteEspace = createSelector(
  selectSocieteEspaceState,
  (state: SocieteEspaceState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteEspace = createSelector(
  selectSocieteEspaceState,
  (state: SocieteEspaceState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteEspacesEntities = createSelector(selectSocieteEspaceState, selectEntities);

export const selectSocieteEspacesArray = createSelector(selectSocieteEspaceState, selectAll);

export const selectIdSocieteEspacesActive = createSelector(
  selectSocieteEspaceState,
  (state: SocieteEspaceState.IState) => state.actives
);

const societeEspacesInObject = (societeEspaces: Dictionary<SocieteEspaceEntityState>) => ({ societeEspaces })

const selectSocieteEspacesEntitiesDictionary = createSelector(selectSocieteEspacesEntities, societeEspacesInObject);

const selectAllSocieteEspacesObject = createSelector(selectSocieteEspacesEntities, societeEspaces => {
  return hydrateAll({ societeEspaces });
});

const selectOneSocieteEspaceDictionary = (idSocieteEspace : number) =>
  createSelector(selectSocieteEspacesEntities, societeEspaces => ({
    societeEspaces: { [idSocieteEspace]: societeEspaces[idSocieteEspace] }
  }));

const selectOneSocieteEspaceDictionaryWithoutChild = (idSocieteEspace : number) =>
  createSelector(selectSocieteEspacesEntities, societeEspaces => ({
    societeEspace: societeEspaces[idSocieteEspace]
  }));

const selectActiveSocieteEspacesEntities = createSelector(
  selectIdSocieteEspacesActive,
  selectSocieteEspacesEntities,
  (actives: number[], societeEspaces: Dictionary<SocieteEspaceEntityState>) => getSocieteEspacesFromActives(actives, societeEspaces)
);

function getSocieteEspacesFromActives(
  actives: number[],
  societeEspaces: Dictionary<SocieteEspaceEntityState>
): Dictionary<SocieteEspaceEntityState> {
  return actives.reduce((acc, idActive) => {
    if (societeEspaces[idActive]) {
      acc[idActive] = societeEspaces[idActive];
    }
    return acc;
  }, {} as Dictionary<SocieteEspaceEntityState>);
}

const selectAllSocieteEspacesSelectors: Dictionary<Selector> = {};
export function selectAllSocieteEspaces(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteEspace>(
      schema,
      selectAllSocieteEspacesSelectors,
      selectSocieteEspacesEntitiesDictionary,
      getRelationSelectors,
      societeEspaceRelations,
      hydrateAll,
      'societeEspace'
    );
  } else {
    return selectAllSocieteEspacesObject;
  }
}

export function selectAllSocieteEspacesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'societeEspaces'
): Selector {
  return createSelector(selectAllSocieteEspaces(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteEspaceEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeEspaces.length; i++) {
      res[customKey][result.societeEspaces[i].idSocieteEspace] = result.societeEspaces[i];
    }
    return res;
  });
}

export function selectOneSocieteEspace(
  schema: SelectSchema = {},
  idSocieteEspace: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteEspaceDictionary(idSocieteEspace)];
  selectors.push(...getRelationSelectors(schema, societeEspaceRelations, 'societeEspace'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteEspaceDictionaryWithoutChild(idSocieteEspace);
  }
}

export function selectActiveSocieteEspaces(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveSocieteEspacesEntities, societeEspaces => ({
      societeEspaces
    }))
  ];
  selectors.push(...getRelationSelectors(schema, societeEspaceRelations, 'societeEspace'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  societeEspaces: Dictionary<SocieteEspaceEntityState>;
  societeEspaceFamilles?: Dictionary<SocieteEspaceFamilleEntityState>;
  espaces?: Dictionary<EspaceEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeEspaces: (SocieteEspace | null)[] } {
  const {
    societeEspaces,
    societeEspaceFamilles,
    espaces
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeEspaces: Object.keys(societeEspaces).map(idSocieteEspace =>
      hydrate(
        societeEspaces[idSocieteEspace] as SocieteEspaceEntityState,
        societeEspaceFamilles,
        espaces
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeEspace: SocieteEspaceEntityState | null } {
  const {
    societeEspaces,
    societeEspaceFamilles,
    espaces
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeEspace = Object.values(societeEspaces)[0];
  return {
    societeEspace: hydrate(
      societeEspace as SocieteEspaceEntityState,
      societeEspaceFamilles,
      espaces
    )
  };
}

function hydrate(
  societeEspace: SocieteEspaceEntityState,
  societeEspaceFamilleEntities?: Dictionary<SocieteEspaceFamilleEntityState>,
  espaceEntities?: Dictionary<EspaceEntityState>,
): SocieteEspace | null {
  if (!societeEspace) {
    return null;
  }

  const societeEspaceHydrated: SocieteEspaceEntityState = { ...societeEspace };
  if (societeEspaceFamilleEntities) {
    societeEspaceHydrated.societeEspaceFamille = societeEspaceFamilleEntities[societeEspace.societeEspaceFamille as number] as SocieteEspaceFamille;
  } else {
    delete societeEspaceHydrated.societeEspaceFamille;
  }

  if (espaceEntities) {
    societeEspaceHydrated.espaces = ((societeEspaceHydrated.espaces as number[]) || []).map(
      id => espaceEntities[id]
    ) as Espace[];
  } else {
    delete societeEspaceHydrated.espaces;
  }

  return societeEspaceHydrated as SocieteEspace;
}
