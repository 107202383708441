import { RepositoryService } from '@get/services/repository';
import { ValeurPatrimoine } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedValeurPatrimoineApiService {
  constructor(protected repo: RepositoryService) {}

  public getValeurPatrimoines(params?: any): Observable<ValeurPatrimoine[]> {
    return this.repo.getData<ValeurPatrimoine[]>('valeur-patrimoine', params);
  }

  public getValeurPatrimoine(params: { idValeurPatrimoine: number; params?: any }): Observable<ValeurPatrimoine> {
    return this.repo.getData<ValeurPatrimoine>('valeur-patrimoine/' + params.idValeurPatrimoine, params.params);
  }

  public addValeurPatrimoine(valeurPatrimoine: Partial<ValeurPatrimoine>): Observable<ValeurPatrimoine> {
    return this.repo.create<ValeurPatrimoine>('valeur-patrimoine', valeurPatrimoine);
  }

  public updateValeurPatrimoine(valeurPatrimoine: Partial<ValeurPatrimoine>): Observable<ValeurPatrimoine> {
    return this.repo.update('valeur-patrimoine', valeurPatrimoine);
  }

  public deleteValeurPatrimoine(idValeurPatrimoine: number): Observable<number> {
    return this.repo.delete('valeur-patrimoine/' + +idValeurPatrimoine);
  }
}

