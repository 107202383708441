import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteEspaceFamilleGeneratedActions } from '@get/store/actions';
import { SocieteEspaceFamilleState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteEspaceFamilleEntityState } from '@get/api-interfaces';

export const societeEspaceFamilleReducersGeneratedFunctions: ReducerTypes<SocieteEspaceFamilleState.IState, readonly ActionCreator[]>[] = [
  on(SocieteEspaceFamilleGeneratedActions.getOneSocieteEspaceFamille, (state: SocieteEspaceFamilleState.IState) => setLoadingsState(state, true)),
  on(SocieteEspaceFamilleGeneratedActions.getManySocieteEspaceFamilles, (state: SocieteEspaceFamilleState.IState) => setLoadingsState(state, true)),
  on(SocieteEspaceFamilleGeneratedActions.upsertOneSocieteEspaceFamille, (state: SocieteEspaceFamilleState.IState) => setLoadingsState(state, true)),

  on(SocieteEspaceFamilleGeneratedActions.upsertManySocieteEspaceFamillesSuccess, (state: SocieteEspaceFamilleState.IState, { societeEspaceFamilles }) =>
    SocieteEspaceFamilleState.adapter.upsertMany(societeEspaceFamilles, setLoadingsState(state, false))
  ),
  on(SocieteEspaceFamilleGeneratedActions.deleteOneSocieteEspaceFamille, (state: SocieteEspaceFamilleState.IState) => setLoadingsState(state, true)),
  on(SocieteEspaceFamilleGeneratedActions.deleteOneSocieteEspaceFamilleSuccess, (state: SocieteEspaceFamilleState.IState, { idSocieteEspaceFamille }) =>
    SocieteEspaceFamilleState.adapter.removeOne(idSocieteEspaceFamille, setLoadingsState(state, false))
  ),
  on(SocieteEspaceFamilleGeneratedActions.clearActivesSocieteEspaceFamilles, (state: SocieteEspaceFamilleState.IState) => ({ ...state, actives: [] })),
  on(SocieteEspaceFamilleGeneratedActions.addManyActivesSocieteEspaceFamilles, (state: SocieteEspaceFamilleState.IState, { idSocieteEspaceFamilles }) => ({
    ...state,
    actives: state.actives.concat(idSocieteEspaceFamilles)
  })),
  on(SocieteEspaceFamilleGeneratedActions.deleteOneActiveSocieteEspaceFamille, (state: SocieteEspaceFamilleState.IState, { idSocieteEspaceFamille }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idSocieteEspaceFamille)
  })),

  on(SocieteEspaceFamilleGeneratedActions.clearSocieteEspaceFamilles, () => SocieteEspaceFamilleState.initialState),

  on(
    SocieteEspaceFamilleGeneratedActions.addManySocieteComposantRattachementSuccess,
    (state: SocieteEspaceFamilleState.IState, { idSocieteEspaceFamille, idSocieteComposantRattachements }) => {
      if (!state.entities[idSocieteEspaceFamille]) {
        return state;
      }
      const societeComposantRattachements = (state.entities[idSocieteEspaceFamille]?.societeComposantRattachements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteEspaceFamille]: {
            ...state.entities[idSocieteEspaceFamille],
            societeComposantRattachements: societeComposantRattachements.concat(
              idSocieteComposantRattachements.filter(id => societeComposantRattachements.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteEspaceFamilleGeneratedActions.deleteManySocieteComposantRattachementSuccess,
    (state: SocieteEspaceFamilleState.IState, { idSocieteComposantRattachements, idSocieteEspaceFamilles }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteEspaceFamilles.reduce((entities, idSocieteEspaceFamille) => {
            if (!state.entities[idSocieteEspaceFamille]?.societeComposantRattachements) {
              return entities;
            }
            entities[idSocieteEspaceFamille] = {
              ...state.entities[idSocieteEspaceFamille],
              societeComposantRattachements: (state.entities[idSocieteEspaceFamille]?.societeComposantRattachements as number[])?.filter(
                (idSocieteComposantRattachement: number) => !idSocieteComposantRattachements.some((id: number) => id === idSocieteComposantRattachement)
              )
            } as SocieteEspaceFamilleEntityState;
            return entities;
          }, {} as Dictionary<SocieteEspaceFamilleEntityState>)
        }
      };
    }
  ),

  on(
    SocieteEspaceFamilleGeneratedActions.addManySocieteEspaceSuccess,
    (state: SocieteEspaceFamilleState.IState, { idSocieteEspaceFamille, idSocieteEspaces }) => {
      if (!state.entities[idSocieteEspaceFamille]) {
        return state;
      }
      const societeEspaces = (state.entities[idSocieteEspaceFamille]?.societeEspaces as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteEspaceFamille]: {
            ...state.entities[idSocieteEspaceFamille],
            societeEspaces: societeEspaces.concat(
              idSocieteEspaces.filter(id => societeEspaces.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteEspaceFamilleGeneratedActions.deleteManySocieteEspaceSuccess,
    (state: SocieteEspaceFamilleState.IState, { idSocieteEspaces, idSocieteEspaceFamilles }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteEspaceFamilles.reduce((entities, idSocieteEspaceFamille) => {
            if (!state.entities[idSocieteEspaceFamille]?.societeEspaces) {
              return entities;
            }
            entities[idSocieteEspaceFamille] = {
              ...state.entities[idSocieteEspaceFamille],
              societeEspaces: (state.entities[idSocieteEspaceFamille]?.societeEspaces as number[])?.filter(
                (idSocieteEspace: number) => !idSocieteEspaces.some((id: number) => id === idSocieteEspace)
              )
            } as SocieteEspaceFamilleEntityState;
            return entities;
          }, {} as Dictionary<SocieteEspaceFamilleEntityState>)
        }
      };
    }
  ),

  on(
    SocieteEspaceFamilleGeneratedActions.addSocieteSuccess,
    (state: SocieteEspaceFamilleState.IState, { idSocieteEspaceFamille, idSociete }) => {
      if (!state.entities[idSocieteEspaceFamille]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteEspaceFamille]: {
            ...state.entities[idSocieteEspaceFamille],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    SocieteEspaceFamilleGeneratedActions.deleteManySocieteSuccess,
    (state: SocieteEspaceFamilleState.IState, { idSocietes, idSocieteEspaceFamilles }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteEspaceFamilles.reduce((entities, idSocieteEspaceFamille) => {
            if (!state.entities[idSocieteEspaceFamille]?.societe) {
              return entities;
            }
            entities[idSocieteEspaceFamille] = {
              ...state.entities[idSocieteEspaceFamille],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idSocieteEspaceFamille]?.societe
              )
                ? undefined
                : state.entities[idSocieteEspaceFamille]?.societe
            } as SocieteEspaceFamilleEntityState;
            return entities;
          }, {} as Dictionary<SocieteEspaceFamilleEntityState>)
        }
      };
    }
  ),

  on(
    SocieteEspaceFamilleGeneratedActions.addSocietePatrimoineHierarchieSuccess,
    (state: SocieteEspaceFamilleState.IState, { idSocieteEspaceFamille, idSocietePatrimoineHierarchie }) => {
      if (!state.entities[idSocieteEspaceFamille]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteEspaceFamille]: {
            ...state.entities[idSocieteEspaceFamille],
            societePatrimoineHierarchie: idSocietePatrimoineHierarchie
          }
        }
      };
    }
  ),

  on(
    SocieteEspaceFamilleGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess,
    (state: SocieteEspaceFamilleState.IState, { idSocietePatrimoineHierarchies, idSocieteEspaceFamilles }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteEspaceFamilles.reduce((entities, idSocieteEspaceFamille) => {
            if (!state.entities[idSocieteEspaceFamille]?.societePatrimoineHierarchie) {
              return entities;
            }
            entities[idSocieteEspaceFamille] = {
              ...state.entities[idSocieteEspaceFamille],
              societePatrimoineHierarchie: idSocietePatrimoineHierarchies.some(
                (idSocietePatrimoineHierarchie: number) => idSocietePatrimoineHierarchie === state.entities[idSocieteEspaceFamille]?.societePatrimoineHierarchie
              )
                ? undefined
                : state.entities[idSocieteEspaceFamille]?.societePatrimoineHierarchie
            } as SocieteEspaceFamilleEntityState;
            return entities;
          }, {} as Dictionary<SocieteEspaceFamilleEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteEspaceFamilleState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): SocieteEspaceFamilleState.IState {
  return { ...state, isLoaded, isLoading };
}
