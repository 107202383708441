import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { UserNewsletter, UserNewsletterEntityState } from '@get/api-interfaces';
import { UserNewsletterApiService } from '@get/store/api-services';
import { UserNewsletterGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeUserNewsletter } from '@get/store/configs/normalization';
import { UserNewsletterSelectors } from '@get/store/selectors';
import { UserNewsletterRelationsIds } from '@get/store/ids-interfaces';
import { UserGeneratedActions } from '@get/store/actions';
import { NewsletterGeneratedActions } from '@get/store/actions';

export function getDefaultAddUserNewsletterActions(userNewsletter: UserNewsletterEntityState, ids?: UserNewsletterRelationsIds): Action[] {
  const actions: Action[] = [UserNewsletterGeneratedActions.normalizeManyUserNewslettersAfterUpsert({ userNewsletters: [userNewsletter] })];

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyUserNewsletterSuccess({
        idUser: ids.user,
        idUserNewsletters: [userNewsletter.idUserNewsletter]
      })
    );
    actions.push(
      UserNewsletterGeneratedActions.addUserSuccess({
        idUserNewsletter: userNewsletter.idUserNewsletter,
        idUser: ids.user
      })
    );
  }

  if (ids?.newsletter) {
    actions.push(
      NewsletterGeneratedActions.addManyUserNewsletterSuccess({
        idNewsletter: ids.newsletter,
        idUserNewsletters: [userNewsletter.idUserNewsletter]
      })
    );
    actions.push(
      UserNewsletterGeneratedActions.addNewsletterSuccess({
        idUserNewsletter: userNewsletter.idUserNewsletter,
        idNewsletter: ids.newsletter
      })
    );
  }

  return actions;
}

export function getDefaultDeleteUserNewsletterActions(userNewsletter: UserNewsletterEntityState): Action[] {
  const actions: Action[] = [UserNewsletterGeneratedActions.deleteOneUserNewsletterSuccess({ idUserNewsletter: userNewsletter.idUserNewsletter })];

  if (userNewsletter.user) {
    actions.push(
      UserGeneratedActions.deleteManyUserNewsletterSuccess({
        idUserNewsletters: [userNewsletter.idUserNewsletter],
        idUsers: [userNewsletter.user as number]
      })
    );
  }

  if (userNewsletter.newsletter) {
    actions.push(
      NewsletterGeneratedActions.deleteManyUserNewsletterSuccess({
        idUserNewsletters: [userNewsletter.idUserNewsletter],
        idNewsletters: [userNewsletter.newsletter as number]
      })
    );
  }

  return actions;
}

export class GeneratedUserNewsletterEffects {
  constructor(
    protected actions$: Actions,
    protected userNewsletterApiService: UserNewsletterApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUserNewsletters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserNewsletterGeneratedActions.getManyUserNewsletters),
      switchMap(({ params }) =>
        this.userNewsletterApiService.getUserNewsletters(params).pipe(
          map((userNewsletters: UserNewsletter[]) => {
            return UserNewsletterGeneratedActions.normalizeManyUserNewslettersAfterUpsert({ userNewsletters });
          }),
          catchError(error => of(UserNewsletterGeneratedActions.userNewslettersFailure({ error })))
        )
      )
    );
  });

  getOneUserNewsletter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserNewsletterGeneratedActions.getOneUserNewsletter),
      switchMap(idUserNewsletter =>
        this.userNewsletterApiService.getUserNewsletter(idUserNewsletter).pipe(
          map((userNewsletter: UserNewsletter) => {
            return UserNewsletterGeneratedActions.normalizeManyUserNewslettersAfterUpsert({ userNewsletters: [userNewsletter] });
          }),
          catchError(error => of(UserNewsletterGeneratedActions.userNewslettersFailure({ error })))
        )
      )
    );
  });

  upsertOneUserNewsletter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserNewsletterGeneratedActions.upsertOneUserNewsletter),
      concatMap(
        ({
          userNewsletter,
          ids
        }: {
          userNewsletter: Partial<UserNewsletter>;
          ids?: UserNewsletterRelationsIds;
        }) => {
          if (userNewsletter.idUserNewsletter) {
            return this.userNewsletterApiService.updateUserNewsletter(userNewsletter).pipe(
              map((userNewsletterReturned: UserNewsletter) => {
                return UserNewsletterGeneratedActions.normalizeManyUserNewslettersAfterUpsert({ userNewsletters: [userNewsletterReturned] });
              }),
              catchError(error => of(UserNewsletterGeneratedActions.userNewslettersFailure({ error })))
            );
          } else {
            return this.userNewsletterApiService.addUserNewsletter(userNewsletter).pipe(
              mergeMap((userNewsletterReturned: UserNewsletter) => getDefaultAddUserNewsletterActions(userNewsletterReturned, ids)),
              catchError(error => of(UserNewsletterGeneratedActions.userNewslettersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneUserNewsletter$ = createEffect(() => {
    const selectUserNewsletterState$ = this.store$.select(UserNewsletterSelectors.selectUserNewsletterState);
    return this.actions$.pipe(
      ofType(UserNewsletterGeneratedActions.deleteOneUserNewsletter),
      withLatestFrom(selectUserNewsletterState$),
      concatMap(([{ idUserNewsletter }, state]) =>
        this.userNewsletterApiService.deleteUserNewsletter(idUserNewsletter).pipe(
          mergeMap(_success => getDefaultDeleteUserNewsletterActions(state.entities[idUserNewsletter] as UserNewsletterEntityState)),
          catchError(error => of(UserNewsletterGeneratedActions.userNewslettersFailure({ error })))
        )
      )
    );
  });

  normalizeManyUserNewslettersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserNewsletterGeneratedActions.normalizeManyUserNewslettersAfterUpsert),
      concatMap(({ userNewsletters }) => {
        const actions: Action[] = getActionsToNormalizeUserNewsletter(userNewsletters, StoreActionType.upsert);
        return [getMultiAction(actions, '[UserNewsletter] Normalization After Upsert Success')];
      })
    );
  });
}
