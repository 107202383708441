import { RepositoryService } from '@get/services/repository';
import { SocieteTerritoireUser } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteTerritoireUserApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteTerritoireUsers(params?: any): Observable<SocieteTerritoireUser[]> {
    return this.repo.getData<SocieteTerritoireUser[]>('societe-territoire-user', params);
  }

  public getSocieteTerritoireUser(params: { idSocieteTerritoireUser: number; params?: any }): Observable<SocieteTerritoireUser> {
    return this.repo.getData<SocieteTerritoireUser>('societe-territoire-user/' + params.idSocieteTerritoireUser, params.params);
  }

  public addSocieteTerritoireUser(societeTerritoireUser: Partial<SocieteTerritoireUser>): Observable<SocieteTerritoireUser> {
    return this.repo.create<SocieteTerritoireUser>('societe-territoire-user', societeTerritoireUser);
  }

  public updateSocieteTerritoireUser(societeTerritoireUser: Partial<SocieteTerritoireUser>): Observable<SocieteTerritoireUser> {
    return this.repo.update('societe-territoire-user', societeTerritoireUser);
  }

  public deleteSocieteTerritoireUser(idSocieteTerritoireUser: number): Observable<number> {
    return this.repo.delete('societe-territoire-user/' + +idSocieteTerritoireUser);
  }
}

