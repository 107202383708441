import { Environment } from "@get/api-interfaces";

const backUrl = "https://api.test.get-and-share.com";

export const environment: Environment = {
  test: true,
  backUrl,
  apiUrl: `${backUrl}/api`,
  iconsUrl: `${backUrl}/icons`,
  imagesUrl: `${backUrl}/images`
};
