import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { FichierGeneratedActions } from '@get/store/actions';
import { FichierState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { FichierEntityState } from '@get/api-interfaces';

export const fichierReducersGeneratedFunctions: ReducerTypes<FichierState.IState, readonly ActionCreator[]>[] = [
  on(FichierGeneratedActions.getOneFichier, (state: FichierState.IState) => setLoadingsState(state, true)),
  on(FichierGeneratedActions.getManyFichiers, (state: FichierState.IState) => setLoadingsState(state, true)),
  on(FichierGeneratedActions.upsertOneFichier, (state: FichierState.IState) => setLoadingsState(state, true)),

  on(FichierGeneratedActions.upsertManyFichiersSuccess, (state: FichierState.IState, { fichiers }) =>
    FichierState.adapter.upsertMany(fichiers, setLoadingsState(state, false))
  ),
  on(FichierGeneratedActions.deleteOneFichier, (state: FichierState.IState) => setLoadingsState(state, true)),
  on(FichierGeneratedActions.deleteOneFichierSuccess, (state: FichierState.IState, { idFichier }) =>
    FichierState.adapter.removeOne(idFichier, setLoadingsState(state, false))
  ),
  on(FichierGeneratedActions.clearActivesFichiers, (state: FichierState.IState) => ({ ...state, actives: [] })),
  on(FichierGeneratedActions.addManyActivesFichiers, (state: FichierState.IState, { idFichiers }) => ({
    ...state,
    actives: state.actives.concat(idFichiers)
  })),
  on(FichierGeneratedActions.deleteOneActiveFichier, (state: FichierState.IState, { idFichier }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idFichier)
  })),

  on(FichierGeneratedActions.clearFichiers, () => FichierState.initialState),

  on(
    FichierGeneratedActions.addManySocieteComposantSuccess,
    (state: FichierState.IState, { idFichier, idSocieteComposants }) => {
      if (!state.entities[idFichier]) {
        return state;
      }
      const societeComposants = (state.entities[idFichier]?.societeComposants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFichier]: {
            ...state.entities[idFichier],
            societeComposants: societeComposants.concat(
              idSocieteComposants.filter(id => societeComposants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.deleteManySocieteComposantSuccess,
    (state: FichierState.IState, { idSocieteComposants, idFichiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFichiers.reduce((entities, idFichier) => {
            if (!state.entities[idFichier]?.societeComposants) {
              return entities;
            }
            entities[idFichier] = {
              ...state.entities[idFichier],
              societeComposants: (state.entities[idFichier]?.societeComposants as number[])?.filter(
                (idSocieteComposant: number) => !idSocieteComposants.some((id: number) => id === idSocieteComposant)
              )
            } as FichierEntityState;
            return entities;
          }, {} as Dictionary<FichierEntityState>)
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.addManySocieteComposantFamilleSuccess,
    (state: FichierState.IState, { idFichier, idSocieteComposantFamilles }) => {
      if (!state.entities[idFichier]) {
        return state;
      }
      const societeComposantFamilles = (state.entities[idFichier]?.societeComposantFamilles as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFichier]: {
            ...state.entities[idFichier],
            societeComposantFamilles: societeComposantFamilles.concat(
              idSocieteComposantFamilles.filter(id => societeComposantFamilles.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.deleteManySocieteComposantFamilleSuccess,
    (state: FichierState.IState, { idSocieteComposantFamilles, idFichiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFichiers.reduce((entities, idFichier) => {
            if (!state.entities[idFichier]?.societeComposantFamilles) {
              return entities;
            }
            entities[idFichier] = {
              ...state.entities[idFichier],
              societeComposantFamilles: (state.entities[idFichier]?.societeComposantFamilles as number[])?.filter(
                (idSocieteComposantFamille: number) => !idSocieteComposantFamilles.some((id: number) => id === idSocieteComposantFamille)
              )
            } as FichierEntityState;
            return entities;
          }, {} as Dictionary<FichierEntityState>)
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.addManyComposantGroupeSuccess,
    (state: FichierState.IState, { idFichier, idComposantGroupes }) => {
      if (!state.entities[idFichier]) {
        return state;
      }
      const composantGroupes = (state.entities[idFichier]?.composantGroupes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFichier]: {
            ...state.entities[idFichier],
            composantGroupes: composantGroupes.concat(
              idComposantGroupes.filter(id => composantGroupes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.deleteManyComposantGroupeSuccess,
    (state: FichierState.IState, { idComposantGroupes, idFichiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFichiers.reduce((entities, idFichier) => {
            if (!state.entities[idFichier]?.composantGroupes) {
              return entities;
            }
            entities[idFichier] = {
              ...state.entities[idFichier],
              composantGroupes: (state.entities[idFichier]?.composantGroupes as number[])?.filter(
                (idComposantGroupe: number) => !idComposantGroupes.some((id: number) => id === idComposantGroupe)
              )
            } as FichierEntityState;
            return entities;
          }, {} as Dictionary<FichierEntityState>)
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.addManyComposantTemplateSuccess,
    (state: FichierState.IState, { idFichier, idComposantTemplates }) => {
      if (!state.entities[idFichier]) {
        return state;
      }
      const composantTemplates = (state.entities[idFichier]?.composantTemplates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFichier]: {
            ...state.entities[idFichier],
            composantTemplates: composantTemplates.concat(
              idComposantTemplates.filter(id => composantTemplates.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.deleteManyComposantTemplateSuccess,
    (state: FichierState.IState, { idComposantTemplates, idFichiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFichiers.reduce((entities, idFichier) => {
            if (!state.entities[idFichier]?.composantTemplates) {
              return entities;
            }
            entities[idFichier] = {
              ...state.entities[idFichier],
              composantTemplates: (state.entities[idFichier]?.composantTemplates as number[])?.filter(
                (idComposantTemplate: number) => !idComposantTemplates.some((id: number) => id === idComposantTemplate)
              )
            } as FichierEntityState;
            return entities;
          }, {} as Dictionary<FichierEntityState>)
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.addManySocieteCaracteristiqueChoixSuccess,
    (state: FichierState.IState, { idFichier, idSocieteCaracteristiqueChoices }) => {
      if (!state.entities[idFichier]) {
        return state;
      }
      const societeCaracteristiqueChoices = (state.entities[idFichier]?.societeCaracteristiqueChoices as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFichier]: {
            ...state.entities[idFichier],
            societeCaracteristiqueChoices: societeCaracteristiqueChoices.concat(
              idSocieteCaracteristiqueChoices.filter(id => societeCaracteristiqueChoices.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.deleteManySocieteCaracteristiqueChoixSuccess,
    (state: FichierState.IState, { idSocieteCaracteristiqueChoices, idFichiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFichiers.reduce((entities, idFichier) => {
            if (!state.entities[idFichier]?.societeCaracteristiqueChoices) {
              return entities;
            }
            entities[idFichier] = {
              ...state.entities[idFichier],
              societeCaracteristiqueChoices: (state.entities[idFichier]?.societeCaracteristiqueChoices as number[])?.filter(
                (idSocieteCaracteristiqueChoix: number) => !idSocieteCaracteristiqueChoices.some((id: number) => id === idSocieteCaracteristiqueChoix)
              )
            } as FichierEntityState;
            return entities;
          }, {} as Dictionary<FichierEntityState>)
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.addManyCaracteristiqueChoixTemplateSuccess,
    (state: FichierState.IState, { idFichier, idCaracteristiqueChoixTemplates }) => {
      if (!state.entities[idFichier]) {
        return state;
      }
      const caracteristiqueChoixTemplates = (state.entities[idFichier]?.caracteristiqueChoixTemplates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFichier]: {
            ...state.entities[idFichier],
            caracteristiqueChoixTemplates: caracteristiqueChoixTemplates.concat(
              idCaracteristiqueChoixTemplates.filter(id => caracteristiqueChoixTemplates.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.deleteManyCaracteristiqueChoixTemplateSuccess,
    (state: FichierState.IState, { idCaracteristiqueChoixTemplates, idFichiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFichiers.reduce((entities, idFichier) => {
            if (!state.entities[idFichier]?.caracteristiqueChoixTemplates) {
              return entities;
            }
            entities[idFichier] = {
              ...state.entities[idFichier],
              caracteristiqueChoixTemplates: (state.entities[idFichier]?.caracteristiqueChoixTemplates as number[])?.filter(
                (idCaracteristiqueChoixTemplate: number) => !idCaracteristiqueChoixTemplates.some((id: number) => id === idCaracteristiqueChoixTemplate)
              )
            } as FichierEntityState;
            return entities;
          }, {} as Dictionary<FichierEntityState>)
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.addManyValeurFichierSuccess,
    (state: FichierState.IState, { idFichier, idValeurFichiers }) => {
      if (!state.entities[idFichier]) {
        return state;
      }
      const valeurFichiers = (state.entities[idFichier]?.valeurFichiers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFichier]: {
            ...state.entities[idFichier],
            valeurFichiers: valeurFichiers.concat(
              idValeurFichiers.filter(id => valeurFichiers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.deleteManyValeurFichierSuccess,
    (state: FichierState.IState, { idValeurFichiers, idFichiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFichiers.reduce((entities, idFichier) => {
            if (!state.entities[idFichier]?.valeurFichiers) {
              return entities;
            }
            entities[idFichier] = {
              ...state.entities[idFichier],
              valeurFichiers: (state.entities[idFichier]?.valeurFichiers as number[])?.filter(
                (idValeurFichier: number) => !idValeurFichiers.some((id: number) => id === idValeurFichier)
              )
            } as FichierEntityState;
            return entities;
          }, {} as Dictionary<FichierEntityState>)
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.addManyValeurSuccess,
    (state: FichierState.IState, { idFichier, idValeurs }) => {
      if (!state.entities[idFichier]) {
        return state;
      }
      const valeurs = (state.entities[idFichier]?.valeurs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFichier]: {
            ...state.entities[idFichier],
            valeurs: valeurs.concat(
              idValeurs.filter(id => valeurs.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.deleteManyValeurSuccess,
    (state: FichierState.IState, { idValeurs, idFichiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFichiers.reduce((entities, idFichier) => {
            if (!state.entities[idFichier]?.valeurs) {
              return entities;
            }
            entities[idFichier] = {
              ...state.entities[idFichier],
              valeurs: (state.entities[idFichier]?.valeurs as number[])?.filter(
                (idValeur: number) => !idValeurs.some((id: number) => id === idValeur)
              )
            } as FichierEntityState;
            return entities;
          }, {} as Dictionary<FichierEntityState>)
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.addOrganisationSuccess,
    (state: FichierState.IState, { idFichier, idOrganisation }) => {
      if (!state.entities[idFichier]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idFichier]: {
            ...state.entities[idFichier],
            organisation: idOrganisation
          }
        }
      };
    }
  ),

  on(
    FichierGeneratedActions.deleteManyOrganisationSuccess,
    (state: FichierState.IState, { idOrganisations, idFichiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFichiers.reduce((entities, idFichier) => {
            if (!state.entities[idFichier]?.organisation) {
              return entities;
            }
            entities[idFichier] = {
              ...state.entities[idFichier],
              organisation: idOrganisations.some(
                (idOrganisation: number) => idOrganisation === state.entities[idFichier]?.organisation
              )
                ? undefined
                : state.entities[idFichier]?.organisation
            } as FichierEntityState;
            return entities;
          }, {} as Dictionary<FichierEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: FichierState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): FichierState.IState {
  return { ...state, isLoaded, isLoading };
}
