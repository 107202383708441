import { RepositoryService } from '@get/services/repository';
import { CaracteristiqueTemplate } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCaracteristiqueTemplateApiService {
  constructor(protected repo: RepositoryService) {}

  public getCaracteristiqueTemplates(params?: any): Observable<CaracteristiqueTemplate[]> {
    return this.repo.getData<CaracteristiqueTemplate[]>('caracteristique-template', params);
  }

  public getCaracteristiqueTemplate(params: { idCaracteristiqueTemplate: number; params?: any }): Observable<CaracteristiqueTemplate> {
    return this.repo.getData<CaracteristiqueTemplate>('caracteristique-template/' + params.idCaracteristiqueTemplate, params.params);
  }

  public addCaracteristiqueTemplate(caracteristiqueTemplate: Partial<CaracteristiqueTemplate>): Observable<CaracteristiqueTemplate> {
    return this.repo.create<CaracteristiqueTemplate>('caracteristique-template', caracteristiqueTemplate);
  }

  public updateCaracteristiqueTemplate(caracteristiqueTemplate: Partial<CaracteristiqueTemplate>): Observable<CaracteristiqueTemplate> {
    return this.repo.update('caracteristique-template', caracteristiqueTemplate);
  }

  public deleteCaracteristiqueTemplate(idCaracteristiqueTemplate: number): Observable<number> {
    return this.repo.delete('caracteristique-template/' + +idCaracteristiqueTemplate);
  }
}

