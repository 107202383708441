import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { CampagnePatrimoine, CampagnePatrimoineEntityState } from '@get/api-interfaces';
import { CampagnePatrimoineApiService } from '@get/store/api-services';
import { CampagnePatrimoineGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeCampagnePatrimoine } from '@get/store/configs/normalization';
import { CampagnePatrimoineSelectors } from '@get/store/selectors';
import { CampagnePatrimoineRelationsIds } from '@get/store/ids-interfaces';
import { CampagneGeneratedActions } from '@get/store/actions';
import { PatrimoineGeneratedActions } from '@get/store/actions';

export function getDefaultAddCampagnePatrimoineActions(campagnePatrimoine: CampagnePatrimoineEntityState, ids?: CampagnePatrimoineRelationsIds): Action[] {
  const actions: Action[] = [CampagnePatrimoineGeneratedActions.normalizeManyCampagnePatrimoinesAfterUpsert({ campagnePatrimoines: [campagnePatrimoine] })];

  if (ids?.campagne) {
    actions.push(
      CampagneGeneratedActions.addManyCampagnePatrimoineSuccess({
        idCampagne: ids.campagne,
        idCampagnePatrimoines: [campagnePatrimoine.idCampagnePatrimoine]
      })
    );
    actions.push(
      CampagnePatrimoineGeneratedActions.addCampagneSuccess({
        idCampagnePatrimoine: campagnePatrimoine.idCampagnePatrimoine,
        idCampagne: ids.campagne
      })
    );
  }

  if (ids?.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.addManyCampagnePatrimoineSuccess({
        idPatrimoine: ids.patrimoine,
        idCampagnePatrimoines: [campagnePatrimoine.idCampagnePatrimoine]
      })
    );
    actions.push(
      CampagnePatrimoineGeneratedActions.addPatrimoineSuccess({
        idCampagnePatrimoine: campagnePatrimoine.idCampagnePatrimoine,
        idPatrimoine: ids.patrimoine
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCampagnePatrimoineActions(campagnePatrimoine: CampagnePatrimoineEntityState): Action[] {
  const actions: Action[] = [CampagnePatrimoineGeneratedActions.deleteOneCampagnePatrimoineSuccess({ idCampagnePatrimoine: campagnePatrimoine.idCampagnePatrimoine })];

  if (campagnePatrimoine.campagne) {
    actions.push(
      CampagneGeneratedActions.deleteManyCampagnePatrimoineSuccess({
        idCampagnePatrimoines: [campagnePatrimoine.idCampagnePatrimoine],
        idCampagnes: [campagnePatrimoine.campagne as number]
      })
    );
  }

  if (campagnePatrimoine.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyCampagnePatrimoineSuccess({
        idCampagnePatrimoines: [campagnePatrimoine.idCampagnePatrimoine],
        idPatrimoines: [campagnePatrimoine.patrimoine as number]
      })
    );
  }

  return actions;
}

export class GeneratedCampagnePatrimoineEffects {
  constructor(
    protected actions$: Actions,
    protected campagnePatrimoineApiService: CampagnePatrimoineApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCampagnePatrimoines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagnePatrimoineGeneratedActions.getManyCampagnePatrimoines),
      switchMap(({ params }) =>
        this.campagnePatrimoineApiService.getCampagnePatrimoines(params).pipe(
          map((campagnePatrimoines: CampagnePatrimoine[]) => {
            return CampagnePatrimoineGeneratedActions.normalizeManyCampagnePatrimoinesAfterUpsert({ campagnePatrimoines });
          }),
          catchError(error => of(CampagnePatrimoineGeneratedActions.campagnePatrimoinesFailure({ error })))
        )
      )
    );
  });

  getOneCampagnePatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagnePatrimoineGeneratedActions.getOneCampagnePatrimoine),
      switchMap(idCampagnePatrimoine =>
        this.campagnePatrimoineApiService.getCampagnePatrimoine(idCampagnePatrimoine).pipe(
          map((campagnePatrimoine: CampagnePatrimoine) => {
            return CampagnePatrimoineGeneratedActions.normalizeManyCampagnePatrimoinesAfterUpsert({ campagnePatrimoines: [campagnePatrimoine] });
          }),
          catchError(error => of(CampagnePatrimoineGeneratedActions.campagnePatrimoinesFailure({ error })))
        )
      )
    );
  });

  upsertOneCampagnePatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagnePatrimoineGeneratedActions.upsertOneCampagnePatrimoine),
      concatMap(
        ({
          campagnePatrimoine,
          ids
        }: {
          campagnePatrimoine: Partial<CampagnePatrimoine>;
          ids?: CampagnePatrimoineRelationsIds;
        }) => {
          if (campagnePatrimoine.idCampagnePatrimoine) {
            return this.campagnePatrimoineApiService.updateCampagnePatrimoine(campagnePatrimoine).pipe(
              map((campagnePatrimoineReturned: CampagnePatrimoine) => {
                return CampagnePatrimoineGeneratedActions.normalizeManyCampagnePatrimoinesAfterUpsert({ campagnePatrimoines: [campagnePatrimoineReturned] });
              }),
              catchError(error => of(CampagnePatrimoineGeneratedActions.campagnePatrimoinesFailure({ error })))
            );
          } else {
            return this.campagnePatrimoineApiService.addCampagnePatrimoine(campagnePatrimoine).pipe(
              mergeMap((campagnePatrimoineReturned: CampagnePatrimoine) => getDefaultAddCampagnePatrimoineActions(campagnePatrimoineReturned, ids)),
              catchError(error => of(CampagnePatrimoineGeneratedActions.campagnePatrimoinesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCampagnePatrimoine$ = createEffect(() => {
    const selectCampagnePatrimoineState$ = this.store$.select(CampagnePatrimoineSelectors.selectCampagnePatrimoineState);
    return this.actions$.pipe(
      ofType(CampagnePatrimoineGeneratedActions.deleteOneCampagnePatrimoine),
      withLatestFrom(selectCampagnePatrimoineState$),
      concatMap(([{ idCampagnePatrimoine }, state]) =>
        this.campagnePatrimoineApiService.deleteCampagnePatrimoine(idCampagnePatrimoine).pipe(
          mergeMap(_success => getDefaultDeleteCampagnePatrimoineActions(state.entities[idCampagnePatrimoine] as CampagnePatrimoineEntityState)),
          catchError(error => of(CampagnePatrimoineGeneratedActions.campagnePatrimoinesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCampagnePatrimoinesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagnePatrimoineGeneratedActions.normalizeManyCampagnePatrimoinesAfterUpsert),
      concatMap(({ campagnePatrimoines }) => {
        const actions: Action[] = getActionsToNormalizeCampagnePatrimoine(campagnePatrimoines, StoreActionType.upsert);
        return [getMultiAction(actions, '[CampagnePatrimoine] Normalization After Upsert Success')];
      })
    );
  });
}
