import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ValeurComposant, ValeurComposantEntityState } from '@get/api-interfaces';
import { ValeurComposantApiService } from '@get/store/api-services';
import { ValeurComposantGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeValeurComposant } from '@get/store/configs/normalization';
import { ValeurComposantSelectors } from '@get/store/selectors';
import { ValeurComposantRelationsIds } from '@get/store/ids-interfaces';
import { ValeurGeneratedActions } from '@get/store/actions';
import { ComposantGeneratedActions } from '@get/store/actions';

export function getDefaultAddValeurComposantActions(valeurComposant: ValeurComposantEntityState, ids?: ValeurComposantRelationsIds): Action[] {
  const actions: Action[] = [ValeurComposantGeneratedActions.normalizeManyValeurComposantsAfterUpsert({ valeurComposants: [valeurComposant] })];

  if (ids?.valeur) {
    actions.push(
      ValeurGeneratedActions.addManyValeurComposantSuccess({
        idValeur: ids.valeur,
        idValeurComposants: [valeurComposant.idValeurComposant]
      })
    );
    actions.push(
      ValeurComposantGeneratedActions.addValeurSuccess({
        idValeurComposant: valeurComposant.idValeurComposant,
        idValeur: ids.valeur
      })
    );
  }

  if (ids?.composant) {
    actions.push(
      ComposantGeneratedActions.addManyValeurComposantSuccess({
        idComposant: ids.composant,
        idValeurComposants: [valeurComposant.idValeurComposant]
      })
    );
    actions.push(
      ValeurComposantGeneratedActions.addComposantSuccess({
        idValeurComposant: valeurComposant.idValeurComposant,
        idComposant: ids.composant
      })
    );
  }

  return actions;
}

export function getDefaultDeleteValeurComposantActions(valeurComposant: ValeurComposantEntityState): Action[] {
  const actions: Action[] = [ValeurComposantGeneratedActions.deleteOneValeurComposantSuccess({ idValeurComposant: valeurComposant.idValeurComposant })];

  if (valeurComposant.valeur) {
    actions.push(
      ValeurGeneratedActions.deleteManyValeurComposantSuccess({
        idValeurComposants: [valeurComposant.idValeurComposant],
        idValeurs: [valeurComposant.valeur as number]
      })
    );
  }

  if (valeurComposant.composant) {
    actions.push(
      ComposantGeneratedActions.deleteManyValeurComposantSuccess({
        idValeurComposants: [valeurComposant.idValeurComposant],
        idComposants: [valeurComposant.composant as number]
      })
    );
  }

  return actions;
}

export class GeneratedValeurComposantEffects {
  constructor(
    protected actions$: Actions,
    protected valeurComposantApiService: ValeurComposantApiService,
    protected store$: Store<AppState>
  ) {}

  getManyValeurComposants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurComposantGeneratedActions.getManyValeurComposants),
      switchMap(({ params }) =>
        this.valeurComposantApiService.getValeurComposants(params).pipe(
          map((valeurComposants: ValeurComposant[]) => {
            return ValeurComposantGeneratedActions.normalizeManyValeurComposantsAfterUpsert({ valeurComposants });
          }),
          catchError(error => of(ValeurComposantGeneratedActions.valeurComposantsFailure({ error })))
        )
      )
    );
  });

  getOneValeurComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurComposantGeneratedActions.getOneValeurComposant),
      switchMap(idValeurComposant =>
        this.valeurComposantApiService.getValeurComposant(idValeurComposant).pipe(
          map((valeurComposant: ValeurComposant) => {
            return ValeurComposantGeneratedActions.normalizeManyValeurComposantsAfterUpsert({ valeurComposants: [valeurComposant] });
          }),
          catchError(error => of(ValeurComposantGeneratedActions.valeurComposantsFailure({ error })))
        )
      )
    );
  });

  upsertOneValeurComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurComposantGeneratedActions.upsertOneValeurComposant),
      concatMap(
        ({
          valeurComposant,
          ids
        }: {
          valeurComposant: Partial<ValeurComposant>;
          ids?: ValeurComposantRelationsIds;
        }) => {
          if (valeurComposant.idValeurComposant) {
            return this.valeurComposantApiService.updateValeurComposant(valeurComposant).pipe(
              map((valeurComposantReturned: ValeurComposant) => {
                return ValeurComposantGeneratedActions.normalizeManyValeurComposantsAfterUpsert({ valeurComposants: [valeurComposantReturned] });
              }),
              catchError(error => of(ValeurComposantGeneratedActions.valeurComposantsFailure({ error })))
            );
          } else {
            return this.valeurComposantApiService.addValeurComposant(valeurComposant).pipe(
              mergeMap((valeurComposantReturned: ValeurComposant) => getDefaultAddValeurComposantActions(valeurComposantReturned, ids)),
              catchError(error => of(ValeurComposantGeneratedActions.valeurComposantsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneValeurComposant$ = createEffect(() => {
    const selectValeurComposantState$ = this.store$.select(ValeurComposantSelectors.selectValeurComposantState);
    return this.actions$.pipe(
      ofType(ValeurComposantGeneratedActions.deleteOneValeurComposant),
      withLatestFrom(selectValeurComposantState$),
      concatMap(([{ idValeurComposant }, state]) =>
        this.valeurComposantApiService.deleteValeurComposant(idValeurComposant).pipe(
          mergeMap(_success => getDefaultDeleteValeurComposantActions(state.entities[idValeurComposant] as ValeurComposantEntityState)),
          catchError(error => of(ValeurComposantGeneratedActions.valeurComposantsFailure({ error })))
        )
      )
    );
  });

  normalizeManyValeurComposantsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurComposantGeneratedActions.normalizeManyValeurComposantsAfterUpsert),
      concatMap(({ valeurComposants }) => {
        const actions: Action[] = getActionsToNormalizeValeurComposant(valeurComposants, StoreActionType.upsert);
        return [getMultiAction(actions, '[ValeurComposant] Normalization After Upsert Success')];
      })
    );
  });
}
