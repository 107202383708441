import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { PatrimoineAncetre, PatrimoineAncetreEntityState } from '@get/api-interfaces';
import { PatrimoineAncetreApiService } from '@get/store/api-services';
import { PatrimoineAncetreGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizePatrimoineAncetre } from '@get/store/configs/normalization';
import { PatrimoineAncetreSelectors } from '@get/store/selectors';
import { PatrimoineAncetreRelationsIds } from '@get/store/ids-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';

export function getDefaultAddPatrimoineAncetreActions(patrimoineAncetre: PatrimoineAncetreEntityState, ids?: PatrimoineAncetreRelationsIds): Action[] {
  const actions: Action[] = [PatrimoineAncetreGeneratedActions.normalizeManyPatrimoineAncetresAfterUpsert({ patrimoineAncetres: [patrimoineAncetre] })];

  if (ids?.ancetrePatrimoine) {
    actions.push(
      PatrimoineGeneratedActions.addManyDescendantsSuccess({
        idPatrimoine: ids.ancetrePatrimoine,
        idDescendants: [patrimoineAncetre.idPatrimoineAncetre]
      })
    );
    actions.push(
      PatrimoineAncetreGeneratedActions.addAncetrePatrimoineSuccess({
        idPatrimoineAncetre: patrimoineAncetre.idPatrimoineAncetre,
        idAncetrePatrimoine: ids.ancetrePatrimoine
      })
    );
  }

  if (ids?.descendantPatrimoine) {
    actions.push(
      PatrimoineGeneratedActions.addManyAncetresSuccess({
        idPatrimoine: ids.descendantPatrimoine,
        idAncetres: [patrimoineAncetre.idPatrimoineAncetre]
      })
    );
    actions.push(
      PatrimoineAncetreGeneratedActions.addDescendantPatrimoineSuccess({
        idPatrimoineAncetre: patrimoineAncetre.idPatrimoineAncetre,
        idDescendantPatrimoine: ids.descendantPatrimoine
      })
    );
  }

  return actions;
}

export function getDefaultDeletePatrimoineAncetreActions(patrimoineAncetre: PatrimoineAncetreEntityState): Action[] {
  const actions: Action[] = [PatrimoineAncetreGeneratedActions.deleteOnePatrimoineAncetreSuccess({ idPatrimoineAncetre: patrimoineAncetre.idPatrimoineAncetre })];

  if (patrimoineAncetre.ancetrePatrimoine) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyDescendantsSuccess({
        idDescendants: [patrimoineAncetre.idPatrimoineAncetre],
        idPatrimoines: [patrimoineAncetre.ancetrePatrimoine as number]
      })
    );
  }

  if (patrimoineAncetre.descendantPatrimoine) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyAncetresSuccess({
        idAncetres: [patrimoineAncetre.idPatrimoineAncetre],
        idPatrimoines: [patrimoineAncetre.descendantPatrimoine as number]
      })
    );
  }

  return actions;
}

export class GeneratedPatrimoineAncetreEffects {
  constructor(
    protected actions$: Actions,
    protected patrimoineAncetreApiService: PatrimoineAncetreApiService,
    protected store$: Store<AppState>
  ) {}

  getManyPatrimoineAncetres$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineAncetreGeneratedActions.getManyPatrimoineAncetres),
      switchMap(({ params }) =>
        this.patrimoineAncetreApiService.getPatrimoineAncetres(params).pipe(
          map((patrimoineAncetres: PatrimoineAncetre[]) => {
            return PatrimoineAncetreGeneratedActions.normalizeManyPatrimoineAncetresAfterUpsert({ patrimoineAncetres });
          }),
          catchError(error => of(PatrimoineAncetreGeneratedActions.patrimoineAncetresFailure({ error })))
        )
      )
    );
  });

  getOnePatrimoineAncetre$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineAncetreGeneratedActions.getOnePatrimoineAncetre),
      switchMap(idPatrimoineAncetre =>
        this.patrimoineAncetreApiService.getPatrimoineAncetre(idPatrimoineAncetre).pipe(
          map((patrimoineAncetre: PatrimoineAncetre) => {
            return PatrimoineAncetreGeneratedActions.normalizeManyPatrimoineAncetresAfterUpsert({ patrimoineAncetres: [patrimoineAncetre] });
          }),
          catchError(error => of(PatrimoineAncetreGeneratedActions.patrimoineAncetresFailure({ error })))
        )
      )
    );
  });

  upsertOnePatrimoineAncetre$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineAncetreGeneratedActions.upsertOnePatrimoineAncetre),
      concatMap(
        ({
          patrimoineAncetre,
          ids
        }: {
          patrimoineAncetre: Partial<PatrimoineAncetre>;
          ids?: PatrimoineAncetreRelationsIds;
        }) => {
          if (patrimoineAncetre.idPatrimoineAncetre) {
            return this.patrimoineAncetreApiService.updatePatrimoineAncetre(patrimoineAncetre).pipe(
              map((patrimoineAncetreReturned: PatrimoineAncetre) => {
                return PatrimoineAncetreGeneratedActions.normalizeManyPatrimoineAncetresAfterUpsert({ patrimoineAncetres: [patrimoineAncetreReturned] });
              }),
              catchError(error => of(PatrimoineAncetreGeneratedActions.patrimoineAncetresFailure({ error })))
            );
          } else {
            return this.patrimoineAncetreApiService.addPatrimoineAncetre(patrimoineAncetre).pipe(
              mergeMap((patrimoineAncetreReturned: PatrimoineAncetre) => getDefaultAddPatrimoineAncetreActions(patrimoineAncetreReturned, ids)),
              catchError(error => of(PatrimoineAncetreGeneratedActions.patrimoineAncetresFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOnePatrimoineAncetre$ = createEffect(() => {
    const selectPatrimoineAncetreState$ = this.store$.select(PatrimoineAncetreSelectors.selectPatrimoineAncetreState);
    return this.actions$.pipe(
      ofType(PatrimoineAncetreGeneratedActions.deleteOnePatrimoineAncetre),
      withLatestFrom(selectPatrimoineAncetreState$),
      concatMap(([{ idPatrimoineAncetre }, state]) =>
        this.patrimoineAncetreApiService.deletePatrimoineAncetre(idPatrimoineAncetre).pipe(
          mergeMap(_success => getDefaultDeletePatrimoineAncetreActions(state.entities[idPatrimoineAncetre] as PatrimoineAncetreEntityState)),
          catchError(error => of(PatrimoineAncetreGeneratedActions.patrimoineAncetresFailure({ error })))
        )
      )
    );
  });

  normalizeManyPatrimoineAncetresAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineAncetreGeneratedActions.normalizeManyPatrimoineAncetresAfterUpsert),
      concatMap(({ patrimoineAncetres }) => {
        const actions: Action[] = getActionsToNormalizePatrimoineAncetre(patrimoineAncetres, StoreActionType.upsert);
        return [getMultiAction(actions, '[PatrimoineAncetre] Normalization After Upsert Success')];
      })
    );
  });
}
