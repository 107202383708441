import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ValeurPatrimoine, ValeurPatrimoineEntityState } from '@get/api-interfaces';
import { ValeurPatrimoineApiService } from '@get/store/api-services';
import { ValeurPatrimoineGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeValeurPatrimoine } from '@get/store/configs/normalization';
import { ValeurPatrimoineSelectors } from '@get/store/selectors';
import { ValeurPatrimoineRelationsIds } from '@get/store/ids-interfaces';
import { ValeurGeneratedActions } from '@get/store/actions';
import { PatrimoineGeneratedActions } from '@get/store/actions';

export function getDefaultAddValeurPatrimoineActions(valeurPatrimoine: ValeurPatrimoineEntityState, ids?: ValeurPatrimoineRelationsIds): Action[] {
  const actions: Action[] = [ValeurPatrimoineGeneratedActions.normalizeManyValeurPatrimoinesAfterUpsert({ valeurPatrimoines: [valeurPatrimoine] })];

  if (ids?.valeur) {
    actions.push(
      ValeurGeneratedActions.addManyValeurPatrimoineSuccess({
        idValeur: ids.valeur,
        idValeurPatrimoines: [valeurPatrimoine.idValeurPatrimoine]
      })
    );
    actions.push(
      ValeurPatrimoineGeneratedActions.addValeurSuccess({
        idValeurPatrimoine: valeurPatrimoine.idValeurPatrimoine,
        idValeur: ids.valeur
      })
    );
  }

  if (ids?.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.addManyValeurPatrimoineSuccess({
        idPatrimoine: ids.patrimoine,
        idValeurPatrimoines: [valeurPatrimoine.idValeurPatrimoine]
      })
    );
    actions.push(
      ValeurPatrimoineGeneratedActions.addPatrimoineSuccess({
        idValeurPatrimoine: valeurPatrimoine.idValeurPatrimoine,
        idPatrimoine: ids.patrimoine
      })
    );
  }

  return actions;
}

export function getDefaultDeleteValeurPatrimoineActions(valeurPatrimoine: ValeurPatrimoineEntityState): Action[] {
  const actions: Action[] = [ValeurPatrimoineGeneratedActions.deleteOneValeurPatrimoineSuccess({ idValeurPatrimoine: valeurPatrimoine.idValeurPatrimoine })];

  if (valeurPatrimoine.valeur) {
    actions.push(
      ValeurGeneratedActions.deleteManyValeurPatrimoineSuccess({
        idValeurPatrimoines: [valeurPatrimoine.idValeurPatrimoine],
        idValeurs: [valeurPatrimoine.valeur as number]
      })
    );
  }

  if (valeurPatrimoine.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyValeurPatrimoineSuccess({
        idValeurPatrimoines: [valeurPatrimoine.idValeurPatrimoine],
        idPatrimoines: [valeurPatrimoine.patrimoine as number]
      })
    );
  }

  return actions;
}

export class GeneratedValeurPatrimoineEffects {
  constructor(
    protected actions$: Actions,
    protected valeurPatrimoineApiService: ValeurPatrimoineApiService,
    protected store$: Store<AppState>
  ) {}

  getManyValeurPatrimoines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurPatrimoineGeneratedActions.getManyValeurPatrimoines),
      switchMap(({ params }) =>
        this.valeurPatrimoineApiService.getValeurPatrimoines(params).pipe(
          map((valeurPatrimoines: ValeurPatrimoine[]) => {
            return ValeurPatrimoineGeneratedActions.normalizeManyValeurPatrimoinesAfterUpsert({ valeurPatrimoines });
          }),
          catchError(error => of(ValeurPatrimoineGeneratedActions.valeurPatrimoinesFailure({ error })))
        )
      )
    );
  });

  getOneValeurPatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurPatrimoineGeneratedActions.getOneValeurPatrimoine),
      switchMap(idValeurPatrimoine =>
        this.valeurPatrimoineApiService.getValeurPatrimoine(idValeurPatrimoine).pipe(
          map((valeurPatrimoine: ValeurPatrimoine) => {
            return ValeurPatrimoineGeneratedActions.normalizeManyValeurPatrimoinesAfterUpsert({ valeurPatrimoines: [valeurPatrimoine] });
          }),
          catchError(error => of(ValeurPatrimoineGeneratedActions.valeurPatrimoinesFailure({ error })))
        )
      )
    );
  });

  upsertOneValeurPatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurPatrimoineGeneratedActions.upsertOneValeurPatrimoine),
      concatMap(
        ({
          valeurPatrimoine,
          ids
        }: {
          valeurPatrimoine: Partial<ValeurPatrimoine>;
          ids?: ValeurPatrimoineRelationsIds;
        }) => {
          if (valeurPatrimoine.idValeurPatrimoine) {
            return this.valeurPatrimoineApiService.updateValeurPatrimoine(valeurPatrimoine).pipe(
              map((valeurPatrimoineReturned: ValeurPatrimoine) => {
                return ValeurPatrimoineGeneratedActions.normalizeManyValeurPatrimoinesAfterUpsert({ valeurPatrimoines: [valeurPatrimoineReturned] });
              }),
              catchError(error => of(ValeurPatrimoineGeneratedActions.valeurPatrimoinesFailure({ error })))
            );
          } else {
            return this.valeurPatrimoineApiService.addValeurPatrimoine(valeurPatrimoine).pipe(
              mergeMap((valeurPatrimoineReturned: ValeurPatrimoine) => getDefaultAddValeurPatrimoineActions(valeurPatrimoineReturned, ids)),
              catchError(error => of(ValeurPatrimoineGeneratedActions.valeurPatrimoinesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneValeurPatrimoine$ = createEffect(() => {
    const selectValeurPatrimoineState$ = this.store$.select(ValeurPatrimoineSelectors.selectValeurPatrimoineState);
    return this.actions$.pipe(
      ofType(ValeurPatrimoineGeneratedActions.deleteOneValeurPatrimoine),
      withLatestFrom(selectValeurPatrimoineState$),
      concatMap(([{ idValeurPatrimoine }, state]) =>
        this.valeurPatrimoineApiService.deleteValeurPatrimoine(idValeurPatrimoine).pipe(
          mergeMap(_success => getDefaultDeleteValeurPatrimoineActions(state.entities[idValeurPatrimoine] as ValeurPatrimoineEntityState)),
          catchError(error => of(ValeurPatrimoineGeneratedActions.valeurPatrimoinesFailure({ error })))
        )
      )
    );
  });

  normalizeManyValeurPatrimoinesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurPatrimoineGeneratedActions.normalizeManyValeurPatrimoinesAfterUpsert),
      concatMap(({ valeurPatrimoines }) => {
        const actions: Action[] = getActionsToNormalizeValeurPatrimoine(valeurPatrimoines, StoreActionType.upsert);
        return [getMultiAction(actions, '[ValeurPatrimoine] Normalization After Upsert Success')];
      })
    );
  });
}
