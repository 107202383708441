import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Espace, EspaceEntityState } from '@get/api-interfaces';
import { Composant, ComposantEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { SocieteEspace, SocieteEspaceEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { EspaceState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const espaceRelations: string[] = ['composants','patrimoines','societeEspaces',];

export const { selectEntities, selectAll } = EspaceState.adapter.getSelectors();

export const selectEspaceState = createFeatureSelector<EspaceState.IState>(EspaceState.espaceFeatureKey);

export const selectIsLoadedEspace = createSelector(
  selectEspaceState,
  (state: EspaceState.IState) => state.isLoaded
);

export const selectIsLoadingEspace = createSelector(
  selectEspaceState,
  (state: EspaceState.IState) => state.isLoading
);

export const selectIsReadyEspace = createSelector(
  selectEspaceState,
  (state: EspaceState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedEspace = createSelector(
  selectEspaceState,
  (state: EspaceState.IState) => state.isLoaded && !state.isLoading
);

export const selectEspacesEntities = createSelector(selectEspaceState, selectEntities);

export const selectEspacesArray = createSelector(selectEspaceState, selectAll);

export const selectIdEspacesActive = createSelector(
  selectEspaceState,
  (state: EspaceState.IState) => state.actives
);

const espacesInObject = (espaces: Dictionary<EspaceEntityState>) => ({ espaces })

const selectEspacesEntitiesDictionary = createSelector(selectEspacesEntities, espacesInObject);

const selectAllEspacesObject = createSelector(selectEspacesEntities, espaces => {
  return hydrateAll({ espaces });
});

const selectOneEspaceDictionary = (idEspace : number) =>
  createSelector(selectEspacesEntities, espaces => ({
    espaces: { [idEspace]: espaces[idEspace] }
  }));

const selectOneEspaceDictionaryWithoutChild = (idEspace : number) =>
  createSelector(selectEspacesEntities, espaces => ({
    espace: espaces[idEspace]
  }));

const selectActiveEspacesEntities = createSelector(
  selectIdEspacesActive,
  selectEspacesEntities,
  (actives: number[], espaces: Dictionary<EspaceEntityState>) => getEspacesFromActives(actives, espaces)
);

function getEspacesFromActives(
  actives: number[],
  espaces: Dictionary<EspaceEntityState>
): Dictionary<EspaceEntityState> {
  return actives.reduce((acc, idActive) => {
    if (espaces[idActive]) {
      acc[idActive] = espaces[idActive];
    }
    return acc;
  }, {} as Dictionary<EspaceEntityState>);
}

const selectAllEspacesSelectors: Dictionary<Selector> = {};
export function selectAllEspaces(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Espace>(
      schema,
      selectAllEspacesSelectors,
      selectEspacesEntitiesDictionary,
      getRelationSelectors,
      espaceRelations,
      hydrateAll,
      'espace'
    );
  } else {
    return selectAllEspacesObject;
  }
}

export function selectAllEspacesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'espaces'
): Selector {
  return createSelector(selectAllEspaces(schema), result => {
    const res = { [customKey]: {} as Dictionary<EspaceEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.espaces.length; i++) {
      res[customKey][result.espaces[i].idEspace] = result.espaces[i];
    }
    return res;
  });
}

export function selectOneEspace(
  schema: SelectSchema = {},
  idEspace: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneEspaceDictionary(idEspace)];
  selectors.push(...getRelationSelectors(schema, espaceRelations, 'espace'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneEspaceDictionaryWithoutChild(idEspace);
  }
}

export function selectActiveEspaces(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveEspacesEntities, espaces => ({
      espaces
    }))
  ];
  selectors.push(...getRelationSelectors(schema, espaceRelations, 'espace'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  espaces: Dictionary<EspaceEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
  societeEspaces?: Dictionary<SocieteEspaceEntityState>;
  composants?: Dictionary<ComposantEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { espaces: (Espace | null)[] } {
  const {
    espaces,
    patrimoines,
    societeEspaces,
    composants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    espaces: Object.keys(espaces).map(idEspace =>
      hydrate(
        espaces[idEspace] as EspaceEntityState,
        patrimoines,
        societeEspaces,
        composants
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { espace: EspaceEntityState | null } {
  const {
    espaces,
    patrimoines,
    societeEspaces,
    composants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const espace = Object.values(espaces)[0];
  return {
    espace: hydrate(
      espace as EspaceEntityState,
      patrimoines,
      societeEspaces,
      composants
    )
  };
}

function hydrate(
  espace: EspaceEntityState,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
  societeEspaceEntities?: Dictionary<SocieteEspaceEntityState>,
  composantEntities?: Dictionary<ComposantEntityState>,
): Espace | null {
  if (!espace) {
    return null;
  }

  const espaceHydrated: EspaceEntityState = { ...espace };
  if (patrimoineEntities) {
    espaceHydrated.patrimoine = patrimoineEntities[espace.patrimoine as number] as Patrimoine;
  } else {
    delete espaceHydrated.patrimoine;
  }
  if (societeEspaceEntities) {
    espaceHydrated.societeEspace = societeEspaceEntities[espace.societeEspace as number] as SocieteEspace;
  } else {
    delete espaceHydrated.societeEspace;
  }

  if (composantEntities) {
    espaceHydrated.composants = ((espaceHydrated.composants as number[]) || []).map(
      id => composantEntities[id]
    ) as Composant[];
  } else {
    delete espaceHydrated.composants;
  }

  return espaceHydrated as Espace;
}
