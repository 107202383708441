import { GridError, SocieteTerritoire, SocieteTerritoireDataRow } from '@get/api-interfaces';
import { ValidationErrorInterface } from '../validation-error.interface';

export function validateSocieteTerritoireProperties(
  SocieteTerritoireToCreate: Partial<SocieteTerritoire>
): ValidationErrorInterface<SocieteTerritoire>[] {
  const errors: ValidationErrorInterface<SocieteTerritoire>[] = [];
  if (!SocieteTerritoireToCreate.libelleTechnique || !SocieteTerritoireToCreate.libelle) {
    errors.push({
      properties: ['libelleTechnique', 'libelle'],
      message: 'You need a libelleTechnique and libelle to do this action'
    });
  }
  return errors;
}

export function mergeSocieteTerritoireAndGridErrors(
  SocieteTerritoires: SocieteTerritoire[],
  gridErrors: GridError[]
): SocieteTerritoireDataRow[] {
  const societeTerritoireDataRows: SocieteTerritoireDataRow[] = [...(SocieteTerritoires as SocieteTerritoireDataRow[])];
  gridErrors?.forEach(gridErrorSocieteTerritoire => {
    if (gridErrorSocieteTerritoire.error.idSocieteTerritoire > 0) {
      const index = societeTerritoireDataRows.findIndex(
        espace => espace.idSocieteTerritoire === gridErrorSocieteTerritoire.error.idSocieteTerritoire
      );

      societeTerritoireDataRows[index] = { ...gridErrorSocieteTerritoire.error };
    } else {
      societeTerritoireDataRows.push({ ...gridErrorSocieteTerritoire.error });
    }
  });
  return societeTerritoireDataRows;
}
