import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ValeurFichier, ValeurFichierEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ValeurFichierState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const valeurFichierRelations: string[] = ['valeurs','fichiers',];

export const { selectEntities, selectAll } = ValeurFichierState.adapter.getSelectors();

export const selectValeurFichierState = createFeatureSelector<ValeurFichierState.IState>(ValeurFichierState.valeurFichierFeatureKey);

export const selectIsLoadedValeurFichier = createSelector(
  selectValeurFichierState,
  (state: ValeurFichierState.IState) => state.isLoaded
);

export const selectIsLoadingValeurFichier = createSelector(
  selectValeurFichierState,
  (state: ValeurFichierState.IState) => state.isLoading
);

export const selectIsReadyValeurFichier = createSelector(
  selectValeurFichierState,
  (state: ValeurFichierState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedValeurFichier = createSelector(
  selectValeurFichierState,
  (state: ValeurFichierState.IState) => state.isLoaded && !state.isLoading
);

export const selectValeurFichiersEntities = createSelector(selectValeurFichierState, selectEntities);

export const selectValeurFichiersArray = createSelector(selectValeurFichierState, selectAll);

export const selectIdValeurFichiersActive = createSelector(
  selectValeurFichierState,
  (state: ValeurFichierState.IState) => state.actives
);

const valeurFichiersInObject = (valeurFichiers: Dictionary<ValeurFichierEntityState>) => ({ valeurFichiers })

const selectValeurFichiersEntitiesDictionary = createSelector(selectValeurFichiersEntities, valeurFichiersInObject);

const selectAllValeurFichiersObject = createSelector(selectValeurFichiersEntities, valeurFichiers => {
  return hydrateAll({ valeurFichiers });
});

const selectOneValeurFichierDictionary = (idValeurFichier : number) =>
  createSelector(selectValeurFichiersEntities, valeurFichiers => ({
    valeurFichiers: { [idValeurFichier]: valeurFichiers[idValeurFichier] }
  }));

const selectOneValeurFichierDictionaryWithoutChild = (idValeurFichier : number) =>
  createSelector(selectValeurFichiersEntities, valeurFichiers => ({
    valeurFichier: valeurFichiers[idValeurFichier]
  }));

const selectActiveValeurFichiersEntities = createSelector(
  selectIdValeurFichiersActive,
  selectValeurFichiersEntities,
  (actives: number[], valeurFichiers: Dictionary<ValeurFichierEntityState>) => getValeurFichiersFromActives(actives, valeurFichiers)
);

function getValeurFichiersFromActives(
  actives: number[],
  valeurFichiers: Dictionary<ValeurFichierEntityState>
): Dictionary<ValeurFichierEntityState> {
  return actives.reduce((acc, idActive) => {
    if (valeurFichiers[idActive]) {
      acc[idActive] = valeurFichiers[idActive];
    }
    return acc;
  }, {} as Dictionary<ValeurFichierEntityState>);
}

const selectAllValeurFichiersSelectors: Dictionary<Selector> = {};
export function selectAllValeurFichiers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ValeurFichier>(
      schema,
      selectAllValeurFichiersSelectors,
      selectValeurFichiersEntitiesDictionary,
      getRelationSelectors,
      valeurFichierRelations,
      hydrateAll,
      'valeurFichier'
    );
  } else {
    return selectAllValeurFichiersObject;
  }
}

export function selectAllValeurFichiersDictionary(
  schema: SelectSchema = {},
  customKey: string = 'valeurFichiers'
): Selector {
  return createSelector(selectAllValeurFichiers(schema), result => {
    const res = { [customKey]: {} as Dictionary<ValeurFichierEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.valeurFichiers.length; i++) {
      res[customKey][result.valeurFichiers[i].idValeurFichier] = result.valeurFichiers[i];
    }
    return res;
  });
}

export function selectOneValeurFichier(
  schema: SelectSchema = {},
  idValeurFichier: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneValeurFichierDictionary(idValeurFichier)];
  selectors.push(...getRelationSelectors(schema, valeurFichierRelations, 'valeurFichier'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneValeurFichierDictionaryWithoutChild(idValeurFichier);
  }
}

export function selectActiveValeurFichiers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveValeurFichiersEntities, valeurFichiers => ({
      valeurFichiers
    }))
  ];
  selectors.push(...getRelationSelectors(schema, valeurFichierRelations, 'valeurFichier'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  valeurFichiers: Dictionary<ValeurFichierEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
  fichiers?: Dictionary<FichierEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { valeurFichiers: (ValeurFichier | null)[] } {
  const {
    valeurFichiers,
    valeurs,
    fichiers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    valeurFichiers: Object.keys(valeurFichiers).map(idValeurFichier =>
      hydrate(
        valeurFichiers[idValeurFichier] as ValeurFichierEntityState,
        valeurs,
        fichiers
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { valeurFichier: ValeurFichierEntityState | null } {
  const {
    valeurFichiers,
    valeurs,
    fichiers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const valeurFichier = Object.values(valeurFichiers)[0];
  return {
    valeurFichier: hydrate(
      valeurFichier as ValeurFichierEntityState,
      valeurs,
      fichiers
    )
  };
}

function hydrate(
  valeurFichier: ValeurFichierEntityState,
  valeurEntities?: Dictionary<ValeurEntityState>,
  fichierEntities?: Dictionary<FichierEntityState>,
): ValeurFichier | null {
  if (!valeurFichier) {
    return null;
  }

  const valeurFichierHydrated: ValeurFichierEntityState = { ...valeurFichier };
  if (valeurEntities) {
    valeurFichierHydrated.valeur = valeurEntities[valeurFichier.valeur as number] as Valeur;
  } else {
    delete valeurFichierHydrated.valeur;
  }
  if (fichierEntities) {
    valeurFichierHydrated.fichier = fichierEntities[valeurFichier.fichier as number] as Fichier;
  } else {
    delete valeurFichierHydrated.fichier;
  }

  return valeurFichierHydrated as ValeurFichier;
}
