import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Societe } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { SocieteSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedSocieteService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteSelectors.selectIsLoadedSociete));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteSelectors.selectIsLoadingSociete));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteSelectors.selectIsReadyAndLoadedSociete as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocietes(schema: SelectSchema = {}): Observable<Societe[]> {
    return this.store$.pipe(select(SocieteSelectors.selectAllSocietes(schema))).pipe(
      switchMap(({ societes }: { societes: Societe[] }) => {
        return this.getReady(schema).pipe(mapTo(societes));
      })
    );
  }

  public selectOneSociete(
    idSociete: number,
    schema: SelectSchema = {}
  ): Observable<Societe> {
    return this.store$
      .pipe(select(SocieteSelectors.selectOneSociete(schema, idSociete)))
      .pipe(
        switchMap(({ societe }: { societe: Societe }) => {
          return this.getReady(schema).pipe(mapTo(societe));
        })
      );
  }

  public selectAllActiveSocietes(schema: SelectSchema = {}): Observable<Societe[]> {
    return this.store$.pipe(select(SocieteSelectors.selectActiveSocietes(schema))).pipe(
      switchMap(({ societes }: { societes: Societe[] }) => {
        return this.getReady(schema).pipe(mapTo(societes));
      })
    );
  }

  public selectIdSocietesActive(): Observable<number[]> {
    return this.store$.pipe(select(SocieteSelectors.selectIdSocietesActive)).pipe(
      switchMap((idSocietes: number[]) => {
        return this.getReady().pipe(mapTo(idSocietes));
      })
    );
  }

  public getOneSociete(
    idSociete: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<Societe> {
    this.store$.dispatch(SocieteGeneratedActions.getOneSociete({ idSociete, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteGeneratedActions.normalizeManySocietesAfterUpsert,
        SocieteGeneratedActions.societesFailure,
        true
      );
    }
  }

  public getManySocietes(
    params: any = {},
    getResult?: boolean
  ): void | Observable<Societe[]> {
    this.store$.dispatch(SocieteGeneratedActions.getManySocietes({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteGeneratedActions.normalizeManySocietesAfterUpsert,
        SocieteGeneratedActions.societesFailure
      );
    }
  }

  public upsertOneSociete(
    societe: Partial<Societe>,
    ids: SocieteRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Societe> {
    this.store$.dispatch(SocieteGeneratedActions.upsertOneSociete({ societe, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteGeneratedActions.normalizeManySocietesAfterUpsert,
        SocieteGeneratedActions.societesFailure,
        true
      );
    }
  }

  public deleteOneSociete(
    idSociete: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(SocieteGeneratedActions.deleteOneSociete({ idSociete }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteGeneratedActions.deleteOneSocieteSuccess,
        SocieteGeneratedActions.societesFailure
      );
    }
  }

  public setActiveSocietes(idSocietes: number[]): void {
    this.store$.dispatch(SocieteGeneratedActions.clearActivesSocietes());
    this.store$.dispatch(SocieteGeneratedActions.addManyActivesSocietes({ idSocietes }));
  }
}
