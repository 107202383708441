import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Droit } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { DroitRelationsIds } from '@get/store/ids-interfaces';
import { DroitGeneratedActions } from '@get/store/actions';
import { DroitSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedDroitService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(DroitSelectors.selectIsLoadedDroit));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(DroitSelectors.selectIsLoadingDroit));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      DroitSelectors.selectIsReadyAndLoadedDroit as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllDroits(schema: SelectSchema = {}): Observable<Droit[]> {
    return this.store$.pipe(select(DroitSelectors.selectAllDroits(schema))).pipe(
      switchMap(({ droits }: { droits: Droit[] }) => {
        return this.getReady(schema).pipe(mapTo(droits));
      })
    );
  }

  public selectOneDroit(
    idDroit: number,
    schema: SelectSchema = {}
  ): Observable<Droit> {
    return this.store$
      .pipe(select(DroitSelectors.selectOneDroit(schema, idDroit)))
      .pipe(
        switchMap(({ droit }: { droit: Droit }) => {
          return this.getReady(schema).pipe(mapTo(droit));
        })
      );
  }

  public selectAllActiveDroits(schema: SelectSchema = {}): Observable<Droit[]> {
    return this.store$.pipe(select(DroitSelectors.selectActiveDroits(schema))).pipe(
      switchMap(({ droits }: { droits: Droit[] }) => {
        return this.getReady(schema).pipe(mapTo(droits));
      })
    );
  }

  public selectIdDroitsActive(): Observable<number[]> {
    return this.store$.pipe(select(DroitSelectors.selectIdDroitsActive)).pipe(
      switchMap((idDroits: number[]) => {
        return this.getReady().pipe(mapTo(idDroits));
      })
    );
  }

  public getOneDroit(
    idDroit: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<Droit> {
    this.store$.dispatch(DroitGeneratedActions.getOneDroit({ idDroit, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        DroitGeneratedActions.normalizeManyDroitsAfterUpsert,
        DroitGeneratedActions.droitsFailure,
        true
      );
    }
  }

  public getManyDroits(
    params: any = {},
    getResult?: boolean
  ): void | Observable<Droit[]> {
    this.store$.dispatch(DroitGeneratedActions.getManyDroits({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        DroitGeneratedActions.normalizeManyDroitsAfterUpsert,
        DroitGeneratedActions.droitsFailure
      );
    }
  }

  public upsertOneDroit(
    droit: Partial<Droit>,
    ids: DroitRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Droit> {
    this.store$.dispatch(DroitGeneratedActions.upsertOneDroit({ droit, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        DroitGeneratedActions.normalizeManyDroitsAfterUpsert,
        DroitGeneratedActions.droitsFailure,
        true
      );
    }
  }

  public deleteOneDroit(
    idDroit: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(DroitGeneratedActions.deleteOneDroit({ idDroit }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        DroitGeneratedActions.deleteOneDroitSuccess,
        DroitGeneratedActions.droitsFailure
      );
    }
  }

  public setActiveDroits(idDroits: number[]): void {
    this.store$.dispatch(DroitGeneratedActions.clearActivesDroits());
    this.store$.dispatch(DroitGeneratedActions.addManyActivesDroits({ idDroits }));
  }
}
