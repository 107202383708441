import {
  CaracteristiqueObjectPerSocieteComposant,
  GridError,
  SocieteCaracteristique,
  SocieteCaracteristiqueDataRow,
  SocieteCaracteristiqueEntry
} from '@get/api-interfaces';
import { ValidationErrorInterface } from '../validation-error.interface';

export function validateSocieteCaracteristiqueProperties(
  societeCaracToCreate: Partial<SocieteCaracteristique>
): ValidationErrorInterface<SocieteCaracteristique>[] {
  const errors: ValidationErrorInterface<SocieteCaracteristique>[] = [];
  if (
    (!societeCaracToCreate.idSocieteComposant && !societeCaracToCreate.idSocietePatrimoineHierarchie) ||
    !societeCaracToCreate.type ||
    !societeCaracToCreate.libelleTechnique
  ) {
    errors.push({
      properties: ['idSocieteComposant', 'idSocietePatrimoineHierarchie', 'type', 'libelleTechnique'],
      message:
        'You need an idSocieteComposant or an idSocietePatrimoineHierarchie, a type and a libelleTechnique to do this action'
    });
  }
  if (societeCaracToCreate.idSocieteComposant && societeCaracToCreate.idSocietePatrimoineHierarchie) {
    errors.push({
      properties: ['idSocieteComposant', 'idSocietePatrimoineHierarchie'],
      message: 'You can t specify an idSocieteComposant and an idSocietePatrimoineHierarchie to do this action'
    });
  }
  return errors;
}

export function mergeSocieteCaracteristiqueAndGridErrors(
  societeCaracteristiques: SocieteCaracteristique[],
  gridErrors: GridError[]
): SocieteCaracteristiqueDataRow[] {
  const societeCaracteristiqueDataRows: SocieteCaracteristiqueDataRow[] = [
    ...(societeCaracteristiques as SocieteCaracteristiqueDataRow[])
  ];
  gridErrors.forEach(gridError => {
    if (gridError.error.idSocieteCaracteristique > 0) {
      const index = societeCaracteristiqueDataRows.findIndex(
        carac => carac.idSocieteCaracteristique === gridError.error.idSocieteCaracteristique
      );

      societeCaracteristiqueDataRows[index] = { ...gridError.error };
    } else {
      societeCaracteristiqueDataRows.push({ ...gridError.error });
    }
  });

  return societeCaracteristiqueDataRows;
}

export function transformCaracteristiquesToObjectPerComponent(
  societeCaracteristiques: SocieteCaracteristique[]
): CaracteristiqueObjectPerSocieteComposant {
  return (
    societeCaracteristiques?.reduce((acc, curr) => {
      acc[curr.societeComposant?.idSocieteComposant] = acc[curr.societeComposant?.idSocieteComposant] || {
        libelle: curr.societeComposant?.libelle.trimEnd(),
        nb: 0
      };
      acc[curr.societeComposant?.idSocieteComposant].nb++;
      return acc;
    }, {} as CaracteristiqueObjectPerSocieteComposant) || {}
  );
}

export function sortSocieteCaracteristiques(values: SocieteCaracteristique[]): SocieteCaracteristique[] {
  return values?.sort((a, b) => a?.ordre - b?.ordre)?.sort((a, b) => a?.ligne - b?.ligne);
}

export function getSortedSocieteCaracteristiquesEntries(
  societeCaracteristiques: SocieteCaracteristique[]
): SocieteCaracteristiqueEntry[] {
  return Object.entries(
    societeCaracteristiques?.reduce((acc, societeCaracteristique) => {
      acc[societeCaracteristique?.titreGroupement] = {
        ...(acc[societeCaracteristique?.titreGroupement] || {}),
        [societeCaracteristique?.ligne]: (
          acc[societeCaracteristique?.titreGroupement]?.[societeCaracteristique?.ligne] || []
        ).concat(societeCaracteristique)
      };
      return acc;
    }, {} as { [grp: string]: { [ligne: number]: SocieteCaracteristique[] } })
  )
    ?.sort((a, b) => (a[0] === 'null' ? -1 : b[0] === 'null' ? 1 : a[0].localeCompare(b[0])))
    ?.map((entry, index) => ({
      grp: entry[0],
      index,
      societeCaracteristiques: Object.values(entry[1])
        .map(societeCaracs => societeCaracs?.sort((a, b) => a?.ordre - b?.ordre))
        ?.sort((a, b) => a?.[0]?.ligne - b?.[0]?.ligne)
    }));
}
