import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import {
  hydrateAll,
  selectSocieteCaracteristiquesEntities,
  societeCaracteristiqueRelations
} from './societe-caracteristique-generated.selectors';

export * from './societe-caracteristique-generated.selectors';

const filterSocieteCaracteristiquePatrimoines = (
  societeCaracteristiquePatrimoines: Dictionary<SocieteCaracteristiqueEntityState>
) =>
  Object.keys(societeCaracteristiquePatrimoines)
    .filter(
      idSocieteCaracteristiques => !societeCaracteristiquePatrimoines[idSocieteCaracteristiques]?.idSocieteComposant
    )
    .reduce(
      (acc, idSocieteCaracteristiques) =>
        Object.assign(acc, {
          [idSocieteCaracteristiques]: societeCaracteristiquePatrimoines[idSocieteCaracteristiques]
        }),
      {}
    );

const societeCaracteristiquePatrimoinesInObject = (
  societeCaracteristiques: Dictionary<SocieteCaracteristiqueEntityState>
) => ({
  societeCaracteristiques: filterSocieteCaracteristiquePatrimoines(societeCaracteristiques)
});

const selectSocieteCaracteristiquePatrimoinesEntitiesDictionary = createSelector(
  selectSocieteCaracteristiquesEntities,
  societeCaracteristiquePatrimoinesInObject
);

const selectAllSocieteCaracteristiquePatrimoinesObject = createSelector(
  selectSocieteCaracteristiquesEntities,
  societeCaracteristiques => {
    return hydrateAll({
      societeCaracteristiques: filterSocieteCaracteristiquePatrimoines(societeCaracteristiques)
    });
  }
);

const selectAllSocieteCaracteristiquePatrimoinesSelectors: Dictionary<Selector> = {};
export function selectAllSocieteCaracteristiquePatrimoines(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteCaracteristique>(
      schema,
      selectAllSocieteCaracteristiquePatrimoinesSelectors,
      selectSocieteCaracteristiquePatrimoinesEntitiesDictionary,
      getRelationSelectors,
      societeCaracteristiqueRelations,
      hydrateAll,
      'societeCaracteristique'
    );
  } else {
    return selectAllSocieteCaracteristiquePatrimoinesObject;
  }
}

const filterSocieteCaracteristiqueComposants = (
  societeCaracteristiqueComposants: Dictionary<SocieteCaracteristiqueEntityState>
) =>
  Object.keys(societeCaracteristiqueComposants)
    .filter(
      idSocieteCaracteristiques =>
        !societeCaracteristiqueComposants[idSocieteCaracteristiques]?.idSocietePatrimoineHierarchie
    )
    .reduce(
      (acc, idSocieteCaracteristiques) =>
        Object.assign(acc, {
          [idSocieteCaracteristiques]: societeCaracteristiqueComposants[idSocieteCaracteristiques]
        }),
      {}
    );

const societeCaracteristiqueComposantsInObject = (
  societeCaracteristiques: Dictionary<SocieteCaracteristiqueEntityState>
) => ({
  societeCaracteristiques: filterSocieteCaracteristiqueComposants(societeCaracteristiques)
});

const selectSocieteCaracteristiqueComposantsEntitiesDictionary = createSelector(
  selectSocieteCaracteristiquesEntities,
  societeCaracteristiqueComposantsInObject
);

const selectAllSocieteCaracteristiqueComposantsObject = createSelector(
  selectSocieteCaracteristiquesEntities,
  societeCaracteristiques => {
    return hydrateAll({
      societeCaracteristiques: filterSocieteCaracteristiqueComposants(societeCaracteristiques)
    });
  }
);

const selectAllSocieteCaracteristiqueComposantsSelectors: Dictionary<Selector> = {};
export function selectAllSocieteCaracteristiqueComposants(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteCaracteristique>(
      schema,
      selectAllSocieteCaracteristiqueComposantsSelectors,
      selectSocieteCaracteristiqueComposantsEntitiesDictionary,
      getRelationSelectors,
      societeCaracteristiqueRelations,
      hydrateAll,
      'societeCaracteristique'
    );
  } else {
    return selectAllSocieteCaracteristiqueComposantsObject;
  }
}
