import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { ValeurApiService } from '@get/store/api-services';
import { ValeurGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeValeur } from '@get/store/configs/normalization';
import { ValeurSelectors } from '@get/store/selectors';
import { ValeurRelationsIds } from '@get/store/ids-interfaces';
import { SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { UserGeneratedActions } from '@get/store/actions';
import { ValeurComposantGeneratedActions } from '@get/store/actions';
import { ValeurComposant } from '@get/api-interfaces';
import { ComposantGeneratedActions } from '@get/store/actions';
import { Composant } from '@get/api-interfaces';
import { ValeurPatrimoineGeneratedActions } from '@get/store/actions';
import { ValeurPatrimoine } from '@get/api-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { Patrimoine } from '@get/api-interfaces';
import { ValeurFichierGeneratedActions } from '@get/store/actions';
import { ValeurFichier } from '@get/api-interfaces';
import { FichierGeneratedActions } from '@get/store/actions';
import { Fichier } from '@get/api-interfaces';

export function getDefaultAddValeurActions(valeur: ValeurEntityState, ids?: ValeurRelationsIds): Action[] {
  const actions: Action[] = [ValeurGeneratedActions.normalizeManyValeursAfterUpsert({ valeurs: [valeur] })];

  if (ids?.societeCaracteristiqueChoix) {
    actions.push(
      SocieteCaracteristiqueChoixGeneratedActions.addManyValeurSuccess({
        idSocieteCaracteristiqueChoix: ids.societeCaracteristiqueChoix,
        idValeurs: [valeur.idValeur]
      })
    );
    actions.push(
      ValeurGeneratedActions.addSocieteCaracteristiqueChoixSuccess({
        idValeur: valeur.idValeur,
        idSocieteCaracteristiqueChoix: ids.societeCaracteristiqueChoix
      })
    );
  }

  if (ids?.societeCaracteristique) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.addManyValeurSuccess({
        idSocieteCaracteristique: ids.societeCaracteristique,
        idValeurs: [valeur.idValeur]
      })
    );
    actions.push(
      ValeurGeneratedActions.addSocieteCaracteristiqueSuccess({
        idValeur: valeur.idValeur,
        idSocieteCaracteristique: ids.societeCaracteristique
      })
    );
  }

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyValeurSuccess({
        idUser: ids.user,
        idValeurs: [valeur.idValeur]
      })
    );
    actions.push(
      ValeurGeneratedActions.addUserSuccess({
        idValeur: valeur.idValeur,
        idUser: ids.user
      })
    );
  }

  if (ids?.valeurComposants) {
    if (!Array.isArray(ids.valeurComposants)) {
      actions.push(
        ValeurComposantGeneratedActions.upsertOneValeurComposant({
          valeurComposant: {
            idValeur: valeur.idValeur,
            idValeurComposant: ids.valeurComposants as number
          } as ValeurComposant
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyValeurComposantSuccess({
          idValeur: valeur.idValeur,
          idValeurComposants: [ids.valeurComposants as number]
        })
      );
    } else {
      actions.push(
        ValeurComposantGeneratedActions.upsertManyValeurComposants({
          valeurComposants: (ids.valeurComposants as number[]).map(
            (idValeurComposant: number) => ({
              idValeur: valeur.idValeur,
              idValeurComposant
            })
          ) as ValeurComposant[]
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyValeurComposantSuccess({
          idValeur: valeur.idValeur,
          idValeurComposants: ids.valeurComposants as number[]
        })
      );
    }
  }

  if (ids?.composants) {
    if (!Array.isArray(ids.composants)) {
      actions.push(
        ComposantGeneratedActions.upsertOneComposant({
          composant: {
            idValeur: valeur.idValeur,
            idComposant: ids.composants as number
          } as Composant & any
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyComposantSuccess({
          idValeur: valeur.idValeur,
          idComposants: [ids.composants as number]
        })
      );
    } else {
      actions.push(
        ComposantGeneratedActions.upsertManyComposants({
          composants: (ids.composants as number[]).map(
            (idComposant: number) => ({
              idValeur: valeur.idValeur,
              idComposant
            })
          ) as Composant[] & any[]
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyComposantSuccess({
          idValeur: valeur.idValeur,
          idComposants: ids.composants as number[]
        })
      );
    }
  }

  if (ids?.valeurPatrimoines) {
    if (!Array.isArray(ids.valeurPatrimoines)) {
      actions.push(
        ValeurPatrimoineGeneratedActions.upsertOneValeurPatrimoine({
          valeurPatrimoine: {
            idValeur: valeur.idValeur,
            idValeurPatrimoine: ids.valeurPatrimoines as number
          } as ValeurPatrimoine
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyValeurPatrimoineSuccess({
          idValeur: valeur.idValeur,
          idValeurPatrimoines: [ids.valeurPatrimoines as number]
        })
      );
    } else {
      actions.push(
        ValeurPatrimoineGeneratedActions.upsertManyValeurPatrimoines({
          valeurPatrimoines: (ids.valeurPatrimoines as number[]).map(
            (idValeurPatrimoine: number) => ({
              idValeur: valeur.idValeur,
              idValeurPatrimoine
            })
          ) as ValeurPatrimoine[]
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyValeurPatrimoineSuccess({
          idValeur: valeur.idValeur,
          idValeurPatrimoines: ids.valeurPatrimoines as number[]
        })
      );
    }
  }

  if (ids?.patrimoines) {
    if (!Array.isArray(ids.patrimoines)) {
      actions.push(
        PatrimoineGeneratedActions.upsertOnePatrimoine({
          patrimoine: {
            idValeur: valeur.idValeur,
            idPatrimoine: ids.patrimoines as number
          } as Patrimoine & any
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyPatrimoineSuccess({
          idValeur: valeur.idValeur,
          idPatrimoines: [ids.patrimoines as number]
        })
      );
    } else {
      actions.push(
        PatrimoineGeneratedActions.upsertManyPatrimoines({
          patrimoines: (ids.patrimoines as number[]).map(
            (idPatrimoine: number) => ({
              idValeur: valeur.idValeur,
              idPatrimoine
            })
          ) as Patrimoine[] & any[]
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyPatrimoineSuccess({
          idValeur: valeur.idValeur,
          idPatrimoines: ids.patrimoines as number[]
        })
      );
    }
  }

  if (ids?.valeurFichiers) {
    if (!Array.isArray(ids.valeurFichiers)) {
      actions.push(
        ValeurFichierGeneratedActions.upsertOneValeurFichier({
          valeurFichier: {
            idValeur: valeur.idValeur,
            idValeurFichier: ids.valeurFichiers as number
          } as ValeurFichier
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyValeurFichierSuccess({
          idValeur: valeur.idValeur,
          idValeurFichiers: [ids.valeurFichiers as number]
        })
      );
    } else {
      actions.push(
        ValeurFichierGeneratedActions.upsertManyValeurFichiers({
          valeurFichiers: (ids.valeurFichiers as number[]).map(
            (idValeurFichier: number) => ({
              idValeur: valeur.idValeur,
              idValeurFichier
            })
          ) as ValeurFichier[]
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyValeurFichierSuccess({
          idValeur: valeur.idValeur,
          idValeurFichiers: ids.valeurFichiers as number[]
        })
      );
    }
  }

  if (ids?.fichiers) {
    if (!Array.isArray(ids.fichiers)) {
      actions.push(
        FichierGeneratedActions.upsertOneFichier({
          fichier: {
            idValeur: valeur.idValeur,
            idFichier: ids.fichiers as number
          } as Fichier & any
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyFichierSuccess({
          idValeur: valeur.idValeur,
          idFichiers: [ids.fichiers as number]
        })
      );
    } else {
      actions.push(
        FichierGeneratedActions.upsertManyFichiers({
          fichiers: (ids.fichiers as number[]).map(
            (idFichier: number) => ({
              idValeur: valeur.idValeur,
              idFichier
            })
          ) as Fichier[] & any[]
        })
      );
      actions.push(
        ValeurGeneratedActions.addManyFichierSuccess({
          idValeur: valeur.idValeur,
          idFichiers: ids.fichiers as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteValeurActions(valeur: ValeurEntityState): Action[] {
  const actions: Action[] = [ValeurGeneratedActions.deleteOneValeurSuccess({ idValeur: valeur.idValeur })];

  if (valeur.societeCaracteristiqueChoix) {
    actions.push(
      SocieteCaracteristiqueChoixGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idSocieteCaracteristiqueChoices: [valeur.societeCaracteristiqueChoix as number]
      })
    );
  }

  if (valeur.societeCaracteristique) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idSocieteCaracteristiques: [valeur.societeCaracteristique as number]
      })
    );
  }

  if (valeur.user) {
    actions.push(
      UserGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idUsers: [valeur.user as number]
      })
    );
  }

  if (valeur.valeurComposants) {
    actions.push(
      ValeurComposantGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idValeurComposants: valeur.valeurComposants as number[]
      })
    );
  }

  if (valeur.composants) {
    actions.push(
      ComposantGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idComposants: valeur.composants as number[]
      })
    );
  }

  if (valeur.valeurPatrimoines) {
    actions.push(
      ValeurPatrimoineGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idValeurPatrimoines: valeur.valeurPatrimoines as number[]
      })
    );
  }

  if (valeur.patrimoines) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idPatrimoines: valeur.patrimoines as number[]
      })
    );
  }

  if (valeur.valeurFichiers) {
    actions.push(
      ValeurFichierGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idValeurFichiers: valeur.valeurFichiers as number[]
      })
    );
  }

  if (valeur.fichiers) {
    actions.push(
      FichierGeneratedActions.deleteManyValeurSuccess({
        idValeurs: [valeur.idValeur],
        idFichiers: valeur.fichiers as number[]
      })
    );
  }

  return actions;
}

export class GeneratedValeurEffects {
  constructor(
    protected actions$: Actions,
    protected valeurApiService: ValeurApiService,
    protected store$: Store<AppState>
  ) {}

  getManyValeurs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurGeneratedActions.getManyValeurs),
      switchMap(({ params }) =>
        this.valeurApiService.getValeurs(params).pipe(
          map((valeurs: Valeur[]) => {
            return ValeurGeneratedActions.normalizeManyValeursAfterUpsert({ valeurs });
          }),
          catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
        )
      )
    );
  });

  getOneValeur$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurGeneratedActions.getOneValeur),
      switchMap(idValeur =>
        this.valeurApiService.getValeur(idValeur).pipe(
          map((valeur: Valeur) => {
            return ValeurGeneratedActions.normalizeManyValeursAfterUpsert({ valeurs: [valeur] });
          }),
          catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
        )
      )
    );
  });

  upsertOneValeur$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurGeneratedActions.upsertOneValeur),
      concatMap(
        ({
          valeur,
          ids
        }: {
          valeur: Partial<Valeur>;
          ids?: ValeurRelationsIds;
        }) => {
          if (valeur.idValeur) {
            return this.valeurApiService.updateValeur(valeur).pipe(
              map((valeurReturned: Valeur) => {
                return ValeurGeneratedActions.normalizeManyValeursAfterUpsert({ valeurs: [valeurReturned] });
              }),
              catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
            );
          } else {
            return this.valeurApiService.addValeur(valeur).pipe(
              mergeMap((valeurReturned: Valeur) => getDefaultAddValeurActions(valeurReturned, ids)),
              catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneValeur$ = createEffect(() => {
    const selectValeurState$ = this.store$.select(ValeurSelectors.selectValeurState);
    return this.actions$.pipe(
      ofType(ValeurGeneratedActions.deleteOneValeur),
      withLatestFrom(selectValeurState$),
      concatMap(([{ idValeur }, state]) =>
        this.valeurApiService.deleteValeur(idValeur).pipe(
          mergeMap(_success => getDefaultDeleteValeurActions(state.entities[idValeur] as ValeurEntityState)),
          catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
        )
      )
    );
  });

  normalizeManyValeursAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurGeneratedActions.normalizeManyValeursAfterUpsert),
      concatMap(({ valeurs }) => {
        const actions: Action[] = getActionsToNormalizeValeur(valeurs, StoreActionType.upsert);
        return [getMultiAction(actions, '[Valeur] Normalization After Upsert Success')];
      })
    );
  });
}
