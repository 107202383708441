import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FichierApiService } from '@get/store/api-services';
import { GeneratedFichierEffects } from './fichier-generated.effects';

@Injectable()
export class FichierEffects extends GeneratedFichierEffects {
  constructor(actions$: Actions, fichierApiService: FichierApiService, store$: Store<AppState>) {
    super(actions$, fichierApiService, store$);
  }
}
