import { RepositoryService } from '@get/services/repository';
import { SocieteProfilDroit } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteProfilDroitApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteProfilDroits(params?: any): Observable<SocieteProfilDroit[]> {
    return this.repo.getData<SocieteProfilDroit[]>('societe-profil-droit', params);
  }

  public getSocieteProfilDroit(params: { idSocieteProfilDroit: number; params?: any }): Observable<SocieteProfilDroit> {
    return this.repo.getData<SocieteProfilDroit>('societe-profil-droit/' + params.idSocieteProfilDroit, params.params);
  }

  public addSocieteProfilDroit(societeProfilDroit: Partial<SocieteProfilDroit>): Observable<SocieteProfilDroit> {
    return this.repo.create<SocieteProfilDroit>('societe-profil-droit', societeProfilDroit);
  }

  public updateSocieteProfilDroit(societeProfilDroit: Partial<SocieteProfilDroit>): Observable<SocieteProfilDroit> {
    return this.repo.update('societe-profil-droit', societeProfilDroit);
  }

  public deleteSocieteProfilDroit(idSocieteProfilDroit: number): Observable<number> {
    return this.repo.delete('societe-profil-droit/' + +idSocieteProfilDroit);
  }
}

