import {
  ComposantAttendu,
  DynamicType,
  Patrimoine,
  PatrimoineDataRow,
  SocieteTerritoire,
  SocieteTerritoireWithChildren
} from '@get/api-interfaces';
import { GridError } from '@get/api-interfaces';
import { ValidationErrorInterface } from '../validation-error.interface';

export function calculateAvancementForPatrimoines(patrimoines: Partial<Patrimoine>[]): Partial<Patrimoine>[] {
  return patrimoines.map((patrimoine): Partial<Patrimoine> => {
    const summedUpRatio = patrimoine.composantAttendus?.reduce(
      (acc, composantAttendu) => {
        return {
          ratioSum:
            acc.ratioSum +
            (composantAttendu.nbReponsesAttendu === 0
              ? 0
              : (composantAttendu.nbReponsesRempli ?? 0) / composantAttendu.nbReponsesAttendu),
          nbRatios: acc.nbRatios + 1
        };
      },
      {
        ratioSum: 0,
        nbRatios: 0
      }
    );

    return {
      idPatrimoine: patrimoine.idPatrimoine,
      idSocietePatrimoineHierarchie: patrimoine.idSocietePatrimoineHierarchie,
      idSociete: patrimoine.idSociete,
      avancement:
        !summedUpRatio || summedUpRatio?.nbRatios === 0 ? 0 : (summedUpRatio.ratioSum / summedUpRatio.nbRatios) * 100
    };
  });
}

export function calculateComposantAttenduRatioAfterCreatingComposant(
  patrimoine: Patrimoine,
  idSocieteComposant: number
): ComposantAttendu[] {
  const composantAttenduForTargetSocieteComposant = patrimoine?.composantAttendus?.find(
    ca => ca.idSocieteComposant === idSocieteComposant
  );
  const composantAttendusToUpdate: ComposantAttendu[] = [];
  if (
    composantAttenduForTargetSocieteComposant &&
    (composantAttenduForTargetSocieteComposant.calcule ||
      (!composantAttenduForTargetSocieteComposant.calcule &&
        composantAttenduForTargetSocieteComposant.nbReponsesRempli === 0))
  ) {
    composantAttendusToUpdate.push({
      ...composantAttenduForTargetSocieteComposant,
      calcule: false,
      nbReponsesRempli: composantAttenduForTargetSocieteComposant.nbReponsesRempli + 1
    });
  } else {
    return [];
  }

  for (let i = 0; i < patrimoine?.ancetres?.length; i++) {
    const ancetre = patrimoine.ancetres[i];
    const composantAttenduForAncetreForTargetSocieteComposant = ancetre.ancetrePatrimoine?.composantAttendus?.find(
      ca => ca.idSocieteComposant === idSocieteComposant
    );
    if (composantAttenduForAncetreForTargetSocieteComposant) {
      composantAttendusToUpdate.push({
        ...composantAttenduForAncetreForTargetSocieteComposant,
        calcule: false,
        nbReponsesRempli: composantAttenduForAncetreForTargetSocieteComposant.nbReponsesRempli + 1
      });
    }
  }
  return composantAttendusToUpdate;
}

export function fillDownwardsTree(
  idSocieteTerritoire: number,
  elementObj: DynamicType<SocieteTerritoireWithChildren>,
  treeArray: number[] = []
): number[] {
  const elem = elementObj?.[idSocieteTerritoire];
  if (!elem || elem?.toutPatrimoine) {
    return treeArray;
  }
  treeArray?.push(idSocieteTerritoire);
  if (elem?.children?.length) {
    for (let i = 0; i < elem?.children?.length; i++) {
      treeArray = fillDownwardsTree(elem?.children[i]?.idSocieteTerritoire, elementObj, treeArray);
    }
  }
  return treeArray;
}

export function fillUpwardsTree(
  idSocieteTerritoire: number,
  elementObj: DynamicType<SocieteTerritoire>,
  treeArray: number[] = []
): number[] {
  const elem = elementObj[idSocieteTerritoire];
  if (!elem || elem?.toutPatrimoine) {
    return treeArray;
  }
  treeArray.push(idSocieteTerritoire);
  if (elem?.idTerritoireParent) {
    return fillUpwardsTree(elem?.idTerritoireParent, elementObj, treeArray);
  }
  return treeArray;
}

export function fillUpwardsEmptyTree(
  idSocieteTerritoire: number,
  elementObj: DynamicType<SocieteTerritoireWithChildren>,
  patrimoineTerritoires: DynamicType<boolean>,
  treeArray: number[] = []
): number[] {
  const elem = elementObj[idSocieteTerritoire];
  if (!elem || elem?.toutPatrimoine) {
    return treeArray;
  }
  const parent = elementObj[elem.idTerritoireParent];
  if (parent) {
    const doAnyBrotherHaveLink = !parent.children
      ?.filter(el => el.idSocieteTerritoire !== elem.idSocieteTerritoire)
      ?.map(el => el.idSocieteTerritoire)
      ?.every(el => !patrimoineTerritoires[el]);
    if (!parent?.toutPatrimoine && !doAnyBrotherHaveLink) {
      treeArray.push(elem.idTerritoireParent);
      return fillUpwardsEmptyTree(elem.idTerritoireParent, elementObj, patrimoineTerritoires, treeArray);
    }
  }
  return treeArray;
}

export function validatePatrimoineProperties(
  patrimoineToCreate: Partial<Patrimoine>
): ValidationErrorInterface<Patrimoine>[] {
  const errors: ValidationErrorInterface<Patrimoine>[] = [];
  if (!patrimoineToCreate.reference) {
    errors.push({
      properties: ['reference'],
      message: 'You need a reference to do this action'
    });
  }
  return errors;
}

export function mergePatrimoineAndGridErrors(patrimoines: Patrimoine[], gridErrors: GridError[]): PatrimoineDataRow[] {
  const patrimoineDataRows: PatrimoineDataRow[] = [...(patrimoines as PatrimoineDataRow[])];
  gridErrors?.forEach(gridErrorPatrimoine => {
    if (gridErrorPatrimoine.error.idPatrimoine > 0) {
      const index = patrimoineDataRows.findIndex(
        patrimoine => patrimoine.idPatrimoine === gridErrorPatrimoine.error.idPatrimoine
      );

      patrimoineDataRows[index] = { ...gridErrorPatrimoine.error };
    } else {
      patrimoineDataRows.push({ ...gridErrorPatrimoine.error });
    }
  });
  return patrimoineDataRows;
}
