import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganisationAzureLogin, OrganisationAzureLoginEntityState } from '@get/api-interfaces';
import { Organisation, OrganisationEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { OrganisationAzureLoginState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const organisationAzureLoginRelations: string[] = ['organisations',];

export const { selectEntities, selectAll } = OrganisationAzureLoginState.adapter.getSelectors();

export const selectOrganisationAzureLoginState = createFeatureSelector<OrganisationAzureLoginState.IState>(OrganisationAzureLoginState.organisationAzureLoginFeatureKey);

export const selectIsLoadedOrganisationAzureLogin = createSelector(
  selectOrganisationAzureLoginState,
  (state: OrganisationAzureLoginState.IState) => state.isLoaded
);

export const selectIsLoadingOrganisationAzureLogin = createSelector(
  selectOrganisationAzureLoginState,
  (state: OrganisationAzureLoginState.IState) => state.isLoading
);

export const selectIsReadyOrganisationAzureLogin = createSelector(
  selectOrganisationAzureLoginState,
  (state: OrganisationAzureLoginState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganisationAzureLogin = createSelector(
  selectOrganisationAzureLoginState,
  (state: OrganisationAzureLoginState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganisationAzureLoginsEntities = createSelector(selectOrganisationAzureLoginState, selectEntities);

export const selectOrganisationAzureLoginsArray = createSelector(selectOrganisationAzureLoginState, selectAll);

export const selectIdOrganisationAzureLoginsActive = createSelector(
  selectOrganisationAzureLoginState,
  (state: OrganisationAzureLoginState.IState) => state.actives
);

const organisationAzureLoginsInObject = (organisationAzureLogins: Dictionary<OrganisationAzureLoginEntityState>) => ({ organisationAzureLogins })

const selectOrganisationAzureLoginsEntitiesDictionary = createSelector(selectOrganisationAzureLoginsEntities, organisationAzureLoginsInObject);

const selectAllOrganisationAzureLoginsObject = createSelector(selectOrganisationAzureLoginsEntities, organisationAzureLogins => {
  return hydrateAll({ organisationAzureLogins });
});

const selectOneOrganisationAzureLoginDictionary = (idOrganisationAzureLogin : number) =>
  createSelector(selectOrganisationAzureLoginsEntities, organisationAzureLogins => ({
    organisationAzureLogins: { [idOrganisationAzureLogin]: organisationAzureLogins[idOrganisationAzureLogin] }
  }));

const selectOneOrganisationAzureLoginDictionaryWithoutChild = (idOrganisationAzureLogin : number) =>
  createSelector(selectOrganisationAzureLoginsEntities, organisationAzureLogins => ({
    organisationAzureLogin: organisationAzureLogins[idOrganisationAzureLogin]
  }));

const selectActiveOrganisationAzureLoginsEntities = createSelector(
  selectIdOrganisationAzureLoginsActive,
  selectOrganisationAzureLoginsEntities,
  (actives: number[], organisationAzureLogins: Dictionary<OrganisationAzureLoginEntityState>) => getOrganisationAzureLoginsFromActives(actives, organisationAzureLogins)
);

function getOrganisationAzureLoginsFromActives(
  actives: number[],
  organisationAzureLogins: Dictionary<OrganisationAzureLoginEntityState>
): Dictionary<OrganisationAzureLoginEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organisationAzureLogins[idActive]) {
      acc[idActive] = organisationAzureLogins[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganisationAzureLoginEntityState>);
}

const selectAllOrganisationAzureLoginsSelectors: Dictionary<Selector> = {};
export function selectAllOrganisationAzureLogins(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganisationAzureLogin>(
      schema,
      selectAllOrganisationAzureLoginsSelectors,
      selectOrganisationAzureLoginsEntitiesDictionary,
      getRelationSelectors,
      organisationAzureLoginRelations,
      hydrateAll,
      'organisationAzureLogin'
    );
  } else {
    return selectAllOrganisationAzureLoginsObject;
  }
}

export function selectAllOrganisationAzureLoginsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organisationAzureLogins'
): Selector {
  return createSelector(selectAllOrganisationAzureLogins(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganisationAzureLoginEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organisationAzureLogins.length; i++) {
      res[customKey][result.organisationAzureLogins[i].idOrganisationAzureLogin] = result.organisationAzureLogins[i];
    }
    return res;
  });
}

export function selectOneOrganisationAzureLogin(
  schema: SelectSchema = {},
  idOrganisationAzureLogin: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneOrganisationAzureLoginDictionary(idOrganisationAzureLogin)];
  selectors.push(...getRelationSelectors(schema, organisationAzureLoginRelations, 'organisationAzureLogin'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganisationAzureLoginDictionaryWithoutChild(idOrganisationAzureLogin);
  }
}

export function selectActiveOrganisationAzureLogins(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganisationAzureLoginsEntities, organisationAzureLogins => ({
      organisationAzureLogins
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organisationAzureLoginRelations, 'organisationAzureLogin'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organisationAzureLogins: Dictionary<OrganisationAzureLoginEntityState>;
  organisations?: Dictionary<OrganisationEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organisationAzureLogins: (OrganisationAzureLogin | null)[] } {
  const {
    organisationAzureLogins,
    organisations
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organisationAzureLogins: Object.keys(organisationAzureLogins).map(idOrganisationAzureLogin =>
      hydrate(
        organisationAzureLogins[idOrganisationAzureLogin] as OrganisationAzureLoginEntityState,
        organisations
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organisationAzureLogin: OrganisationAzureLoginEntityState | null } {
  const {
    organisationAzureLogins,
    organisations
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organisationAzureLogin = Object.values(organisationAzureLogins)[0];
  return {
    organisationAzureLogin: hydrate(
      organisationAzureLogin as OrganisationAzureLoginEntityState,
      organisations
    )
  };
}

function hydrate(
  organisationAzureLogin: OrganisationAzureLoginEntityState,
  organisationEntities?: Dictionary<OrganisationEntityState>,
): OrganisationAzureLogin | null {
  if (!organisationAzureLogin) {
    return null;
  }

  const organisationAzureLoginHydrated: OrganisationAzureLoginEntityState = { ...organisationAzureLogin };
  if (organisationEntities) {
    organisationAzureLoginHydrated.organisation = organisationEntities[organisationAzureLogin.organisation as number] as Organisation;
  } else {
    delete organisationAzureLoginHydrated.organisation;
  }

  return organisationAzureLoginHydrated as OrganisationAzureLogin;
}
