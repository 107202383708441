import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@get/environment';
import * as Sentry from '@sentry/angular-ivy';
import { addRxPlugin } from 'rxdb';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration-schema';
import { AppModule } from './app/app.module';

// TODO: Check nx cloud (to improve cache and CI performances)

if (environment.production) {
  enableProdMode();
}

function getCurrentEnv(): 'integration' | 'production' | 'qualif' | 'development' {
  if (environment.integration) {
    return 'integration';
  } else if (environment.qualif) {
    return 'qualif';
  } else if (environment.production) {
    return 'production';
  } else {
    return 'development';
  }
}

Sentry.init({
  dsn: 'https://977225450c124bfc80a5aa5bbc97b057@o1171300.ingest.sentry.io/6313655',
  environment: getCurrentEnv(),
  enabled: getCurrentEnv() !== 'development',
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ['localhost', environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],
  tracesSampleRate: 1.0
});

if (getCurrentEnv() === 'development') {
  addRxPlugin(RxDBDevModePlugin);
}
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBCleanupPlugin);
addRxPlugin(RxDBMigrationPlugin);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
