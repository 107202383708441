import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { OrganisationNewsletter, OrganisationNewsletterEntityState } from '@get/api-interfaces';
import { OrganisationNewsletterApiService } from '@get/store/api-services';
import { OrganisationNewsletterGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeOrganisationNewsletter } from '@get/store/configs/normalization';
import { OrganisationNewsletterSelectors } from '@get/store/selectors';
import { OrganisationNewsletterRelationsIds } from '@get/store/ids-interfaces';
import { OrganisationGeneratedActions } from '@get/store/actions';
import { NewsletterGeneratedActions } from '@get/store/actions';

export function getDefaultAddOrganisationNewsletterActions(organisationNewsletter: OrganisationNewsletterEntityState, ids?: OrganisationNewsletterRelationsIds): Action[] {
  const actions: Action[] = [OrganisationNewsletterGeneratedActions.normalizeManyOrganisationNewslettersAfterUpsert({ organisationNewsletters: [organisationNewsletter] })];

  if (ids?.organisation) {
    actions.push(
      OrganisationGeneratedActions.addManyOrganisationNewsletterSuccess({
        idOrganisation: ids.organisation,
        idOrganisationNewsletters: [organisationNewsletter.idOrganisationNewsletter]
      })
    );
    actions.push(
      OrganisationNewsletterGeneratedActions.addOrganisationSuccess({
        idOrganisationNewsletter: organisationNewsletter.idOrganisationNewsletter,
        idOrganisation: ids.organisation
      })
    );
  }

  if (ids?.newsletter) {
    actions.push(
      NewsletterGeneratedActions.addManyOrganisationNewsletterSuccess({
        idNewsletter: ids.newsletter,
        idOrganisationNewsletters: [organisationNewsletter.idOrganisationNewsletter]
      })
    );
    actions.push(
      OrganisationNewsletterGeneratedActions.addNewsletterSuccess({
        idOrganisationNewsletter: organisationNewsletter.idOrganisationNewsletter,
        idNewsletter: ids.newsletter
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganisationNewsletterActions(organisationNewsletter: OrganisationNewsletterEntityState): Action[] {
  const actions: Action[] = [OrganisationNewsletterGeneratedActions.deleteOneOrganisationNewsletterSuccess({ idOrganisationNewsletter: organisationNewsletter.idOrganisationNewsletter })];

  if (organisationNewsletter.organisation) {
    actions.push(
      OrganisationGeneratedActions.deleteManyOrganisationNewsletterSuccess({
        idOrganisationNewsletters: [organisationNewsletter.idOrganisationNewsletter],
        idOrganisations: [organisationNewsletter.organisation as number]
      })
    );
  }

  if (organisationNewsletter.newsletter) {
    actions.push(
      NewsletterGeneratedActions.deleteManyOrganisationNewsletterSuccess({
        idOrganisationNewsletters: [organisationNewsletter.idOrganisationNewsletter],
        idNewsletters: [organisationNewsletter.newsletter as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganisationNewsletterEffects {
  constructor(
    protected actions$: Actions,
    protected organisationNewsletterApiService: OrganisationNewsletterApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganisationNewsletters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationNewsletterGeneratedActions.getManyOrganisationNewsletters),
      switchMap(({ params }) =>
        this.organisationNewsletterApiService.getOrganisationNewsletters(params).pipe(
          map((organisationNewsletters: OrganisationNewsletter[]) => {
            return OrganisationNewsletterGeneratedActions.normalizeManyOrganisationNewslettersAfterUpsert({ organisationNewsletters });
          }),
          catchError(error => of(OrganisationNewsletterGeneratedActions.organisationNewslettersFailure({ error })))
        )
      )
    );
  });

  getOneOrganisationNewsletter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationNewsletterGeneratedActions.getOneOrganisationNewsletter),
      switchMap(idOrganisationNewsletter =>
        this.organisationNewsletterApiService.getOrganisationNewsletter(idOrganisationNewsletter).pipe(
          map((organisationNewsletter: OrganisationNewsletter) => {
            return OrganisationNewsletterGeneratedActions.normalizeManyOrganisationNewslettersAfterUpsert({ organisationNewsletters: [organisationNewsletter] });
          }),
          catchError(error => of(OrganisationNewsletterGeneratedActions.organisationNewslettersFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganisationNewsletter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationNewsletterGeneratedActions.upsertOneOrganisationNewsletter),
      concatMap(
        ({
          organisationNewsletter,
          ids
        }: {
          organisationNewsletter: Partial<OrganisationNewsletter>;
          ids?: OrganisationNewsletterRelationsIds;
        }) => {
          if (organisationNewsletter.idOrganisationNewsletter) {
            return this.organisationNewsletterApiService.updateOrganisationNewsletter(organisationNewsletter).pipe(
              map((organisationNewsletterReturned: OrganisationNewsletter) => {
                return OrganisationNewsletterGeneratedActions.normalizeManyOrganisationNewslettersAfterUpsert({ organisationNewsletters: [organisationNewsletterReturned] });
              }),
              catchError(error => of(OrganisationNewsletterGeneratedActions.organisationNewslettersFailure({ error })))
            );
          } else {
            return this.organisationNewsletterApiService.addOrganisationNewsletter(organisationNewsletter).pipe(
              mergeMap((organisationNewsletterReturned: OrganisationNewsletter) => getDefaultAddOrganisationNewsletterActions(organisationNewsletterReturned, ids)),
              catchError(error => of(OrganisationNewsletterGeneratedActions.organisationNewslettersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganisationNewsletter$ = createEffect(() => {
    const selectOrganisationNewsletterState$ = this.store$.select(OrganisationNewsletterSelectors.selectOrganisationNewsletterState);
    return this.actions$.pipe(
      ofType(OrganisationNewsletterGeneratedActions.deleteOneOrganisationNewsletter),
      withLatestFrom(selectOrganisationNewsletterState$),
      concatMap(([{ idOrganisationNewsletter }, state]) =>
        this.organisationNewsletterApiService.deleteOrganisationNewsletter(idOrganisationNewsletter).pipe(
          mergeMap(_success => getDefaultDeleteOrganisationNewsletterActions(state.entities[idOrganisationNewsletter] as OrganisationNewsletterEntityState)),
          catchError(error => of(OrganisationNewsletterGeneratedActions.organisationNewslettersFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganisationNewslettersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationNewsletterGeneratedActions.normalizeManyOrganisationNewslettersAfterUpsert),
      concatMap(({ organisationNewsletters }) => {
        const actions: Action[] = getActionsToNormalizeOrganisationNewsletter(organisationNewsletters, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganisationNewsletter] Normalization After Upsert Success')];
      })
    );
  });
}
