import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchieRelationsIds } from '@get/store/ids-interfaces';

export const SocietePatrimoineHierarchieGeneratedActions = createActionGroup({
  source: 'Societe Patrimoine Hierarchie Generated',
  events: {
    'Get One Societe Patrimoine Hierarchie': props<{ idSocietePatrimoineHierarchie: number; params?: any }>(),
    'Get Many Societe Patrimoine Hierarchies': props<{ params: any }>(),
    'Add Many Actives Societe Patrimoine Hierarchies': props<{ idSocietePatrimoineHierarchies: number[] }>(),
    'Delete One Active Societe Patrimoine Hierarchie': props<{ idSocietePatrimoineHierarchie: number }>(),
    'Clear Actives Societe Patrimoine Hierarchies': emptyProps(),
    'Upsert One Societe Patrimoine Hierarchie': props<{ societePatrimoineHierarchie: Partial<SocietePatrimoineHierarchie>; ids?: SocietePatrimoineHierarchieRelationsIds; }>(),
    'Upsert Many Societe Patrimoine Hierarchies': props<{ societePatrimoineHierarchies: Partial<SocietePatrimoineHierarchie>[]; ids?: SocietePatrimoineHierarchieRelationsIds; }>(),
    'Upsert Many Societe Patrimoine Hierarchies Success': props<{ societePatrimoineHierarchies: SocietePatrimoineHierarchieEntityState[] }>(),
    'Delete One Societe Patrimoine Hierarchie': props<{ idSocietePatrimoineHierarchie: number }>(),
    'Delete One Societe Patrimoine Hierarchie Success': props<{ idSocietePatrimoineHierarchie: number }>(),
    'Normalize Many Societe Patrimoine Hierarchies After Upsert': props<{ societePatrimoineHierarchies: SocietePatrimoineHierarchieEntityState[] }>(),
    'Societe Patrimoine Hierarchies Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Patrimoine Hierarchies': emptyProps(),
    'Add Many Societe Caracteristique Success': props<{ idSocietePatrimoineHierarchie: number; idSocieteCaracteristiques: number[] }>(),
    'Delete Many Societe Caracteristique Success': props<{ idSocieteCaracteristiques: number[]; idSocietePatrimoineHierarchies: number[] }>(),
    'Add Many Patrimoine Success': props<{ idSocietePatrimoineHierarchie: number; idPatrimoines: number[] }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idSocietePatrimoineHierarchies: number[] }>(),
    'Add Many Hierarchie Ancetres Success': props<{ idSocietePatrimoineHierarchie: number; idHierarchieAncetres: number[] }>(),
    'Delete Many Hierarchie Ancetres Success': props<{ idHierarchieAncetres: number[]; idSocietePatrimoineHierarchies: number[] }>(),
    'Add Many Hierarchie Descendants Success': props<{ idSocietePatrimoineHierarchie: number; idHierarchieDescendants: number[] }>(),
    'Delete Many Hierarchie Descendants Success': props<{ idHierarchieDescendants: number[]; idSocietePatrimoineHierarchies: number[] }>(),
    'Add Many Societe Composant Rattachement Success': props<{ idSocietePatrimoineHierarchie: number; idSocieteComposantRattachements: number[] }>(),
    'Delete Many Societe Composant Rattachement Success': props<{ idSocieteComposantRattachements: number[]; idSocietePatrimoineHierarchies: number[] }>(),
    'Add Many Societe Espace Famille Success': props<{ idSocietePatrimoineHierarchie: number; idSocieteEspaceFamilles: number[] }>(),
    'Delete Many Societe Espace Famille Success': props<{ idSocieteEspaceFamilles: number[]; idSocietePatrimoineHierarchies: number[] }>(),
    'Add Societe Success': props<{ idSocietePatrimoineHierarchie: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idSocietePatrimoineHierarchies: number[] }>(),
    'Add Patrimoine Hierarchie Template Success': props<{ idSocietePatrimoineHierarchie: number; idPatrimoineHierarchieTemplate: number }>(),
    'Delete Many Patrimoine Hierarchie Template Success': props<{ idPatrimoineHierarchieTemplates: number[]; idSocietePatrimoineHierarchies: number[] }>()
  }
});
