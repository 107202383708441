import { RepositoryService } from '@get/services/repository';
import { ValeurComposant } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedValeurComposantApiService {
  constructor(protected repo: RepositoryService) {}

  public getValeurComposants(params?: any): Observable<ValeurComposant[]> {
    return this.repo.getData<ValeurComposant[]>('valeur-composant', params);
  }

  public getValeurComposant(params: { idValeurComposant: number; params?: any }): Observable<ValeurComposant> {
    return this.repo.getData<ValeurComposant>('valeur-composant/' + params.idValeurComposant, params.params);
  }

  public addValeurComposant(valeurComposant: Partial<ValeurComposant>): Observable<ValeurComposant> {
    return this.repo.create<ValeurComposant>('valeur-composant', valeurComposant);
  }

  public updateValeurComposant(valeurComposant: Partial<ValeurComposant>): Observable<ValeurComposant> {
    return this.repo.update('valeur-composant', valeurComposant);
  }

  public deleteValeurComposant(idValeurComposant: number): Observable<number> {
    return this.repo.delete('valeur-composant/' + +idValeurComposant);
  }
}

