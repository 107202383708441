import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { CampagneSocieteCaracteristique, CampagneSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { CampagneSocieteCaracteristiqueApiService } from '@get/store/api-services';
import { CampagneSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeCampagneSocieteCaracteristique } from '@get/store/configs/normalization';
import { CampagneSocieteCaracteristiqueSelectors } from '@get/store/selectors';
import { CampagneSocieteCaracteristiqueRelationsIds } from '@get/store/ids-interfaces';
import { CampagneGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';

export function getDefaultAddCampagneSocieteCaracteristiqueActions(campagneSocieteCaracteristique: CampagneSocieteCaracteristiqueEntityState, ids?: CampagneSocieteCaracteristiqueRelationsIds): Action[] {
  const actions: Action[] = [CampagneSocieteCaracteristiqueGeneratedActions.normalizeManyCampagneSocieteCaracteristiquesAfterUpsert({ campagneSocieteCaracteristiques: [campagneSocieteCaracteristique] })];

  if (ids?.campagne) {
    actions.push(
      CampagneGeneratedActions.addManyCampagneSocieteCaracteristiqueSuccess({
        idCampagne: ids.campagne,
        idCampagneSocieteCaracteristiques: [campagneSocieteCaracteristique.idCampagneSocieteCaracteristique]
      })
    );
    actions.push(
      CampagneSocieteCaracteristiqueGeneratedActions.addCampagneSuccess({
        idCampagneSocieteCaracteristique: campagneSocieteCaracteristique.idCampagneSocieteCaracteristique,
        idCampagne: ids.campagne
      })
    );
  }

  if (ids?.societeCaracteristique) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.addManyCampagneSocieteCaracteristiqueSuccess({
        idSocieteCaracteristique: ids.societeCaracteristique,
        idCampagneSocieteCaracteristiques: [campagneSocieteCaracteristique.idCampagneSocieteCaracteristique]
      })
    );
    actions.push(
      CampagneSocieteCaracteristiqueGeneratedActions.addSocieteCaracteristiqueSuccess({
        idCampagneSocieteCaracteristique: campagneSocieteCaracteristique.idCampagneSocieteCaracteristique,
        idSocieteCaracteristique: ids.societeCaracteristique
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCampagneSocieteCaracteristiqueActions(campagneSocieteCaracteristique: CampagneSocieteCaracteristiqueEntityState): Action[] {
  const actions: Action[] = [CampagneSocieteCaracteristiqueGeneratedActions.deleteOneCampagneSocieteCaracteristiqueSuccess({ idCampagneSocieteCaracteristique: campagneSocieteCaracteristique.idCampagneSocieteCaracteristique })];

  if (campagneSocieteCaracteristique.campagne) {
    actions.push(
      CampagneGeneratedActions.deleteManyCampagneSocieteCaracteristiqueSuccess({
        idCampagneSocieteCaracteristiques: [campagneSocieteCaracteristique.idCampagneSocieteCaracteristique],
        idCampagnes: [campagneSocieteCaracteristique.campagne as number]
      })
    );
  }

  if (campagneSocieteCaracteristique.societeCaracteristique) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManyCampagneSocieteCaracteristiqueSuccess({
        idCampagneSocieteCaracteristiques: [campagneSocieteCaracteristique.idCampagneSocieteCaracteristique],
        idSocieteCaracteristiques: [campagneSocieteCaracteristique.societeCaracteristique as number]
      })
    );
  }

  return actions;
}

export class GeneratedCampagneSocieteCaracteristiqueEffects {
  constructor(
    protected actions$: Actions,
    protected campagneSocieteCaracteristiqueApiService: CampagneSocieteCaracteristiqueApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCampagneSocieteCaracteristiques$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneSocieteCaracteristiqueGeneratedActions.getManyCampagneSocieteCaracteristiques),
      switchMap(({ params }) =>
        this.campagneSocieteCaracteristiqueApiService.getCampagneSocieteCaracteristiques(params).pipe(
          map((campagneSocieteCaracteristiques: CampagneSocieteCaracteristique[]) => {
            return CampagneSocieteCaracteristiqueGeneratedActions.normalizeManyCampagneSocieteCaracteristiquesAfterUpsert({ campagneSocieteCaracteristiques });
          }),
          catchError(error => of(CampagneSocieteCaracteristiqueGeneratedActions.campagneSocieteCaracteristiquesFailure({ error })))
        )
      )
    );
  });

  getOneCampagneSocieteCaracteristique$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneSocieteCaracteristiqueGeneratedActions.getOneCampagneSocieteCaracteristique),
      switchMap(idCampagneSocieteCaracteristique =>
        this.campagneSocieteCaracteristiqueApiService.getCampagneSocieteCaracteristique(idCampagneSocieteCaracteristique).pipe(
          map((campagneSocieteCaracteristique: CampagneSocieteCaracteristique) => {
            return CampagneSocieteCaracteristiqueGeneratedActions.normalizeManyCampagneSocieteCaracteristiquesAfterUpsert({ campagneSocieteCaracteristiques: [campagneSocieteCaracteristique] });
          }),
          catchError(error => of(CampagneSocieteCaracteristiqueGeneratedActions.campagneSocieteCaracteristiquesFailure({ error })))
        )
      )
    );
  });

  upsertOneCampagneSocieteCaracteristique$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneSocieteCaracteristiqueGeneratedActions.upsertOneCampagneSocieteCaracteristique),
      concatMap(
        ({
          campagneSocieteCaracteristique,
          ids
        }: {
          campagneSocieteCaracteristique: Partial<CampagneSocieteCaracteristique>;
          ids?: CampagneSocieteCaracteristiqueRelationsIds;
        }) => {
          if (campagneSocieteCaracteristique.idCampagneSocieteCaracteristique) {
            return this.campagneSocieteCaracteristiqueApiService.updateCampagneSocieteCaracteristique(campagneSocieteCaracteristique).pipe(
              map((campagneSocieteCaracteristiqueReturned: CampagneSocieteCaracteristique) => {
                return CampagneSocieteCaracteristiqueGeneratedActions.normalizeManyCampagneSocieteCaracteristiquesAfterUpsert({ campagneSocieteCaracteristiques: [campagneSocieteCaracteristiqueReturned] });
              }),
              catchError(error => of(CampagneSocieteCaracteristiqueGeneratedActions.campagneSocieteCaracteristiquesFailure({ error })))
            );
          } else {
            return this.campagneSocieteCaracteristiqueApiService.addCampagneSocieteCaracteristique(campagneSocieteCaracteristique).pipe(
              mergeMap((campagneSocieteCaracteristiqueReturned: CampagneSocieteCaracteristique) => getDefaultAddCampagneSocieteCaracteristiqueActions(campagneSocieteCaracteristiqueReturned, ids)),
              catchError(error => of(CampagneSocieteCaracteristiqueGeneratedActions.campagneSocieteCaracteristiquesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCampagneSocieteCaracteristique$ = createEffect(() => {
    const selectCampagneSocieteCaracteristiqueState$ = this.store$.select(CampagneSocieteCaracteristiqueSelectors.selectCampagneSocieteCaracteristiqueState);
    return this.actions$.pipe(
      ofType(CampagneSocieteCaracteristiqueGeneratedActions.deleteOneCampagneSocieteCaracteristique),
      withLatestFrom(selectCampagneSocieteCaracteristiqueState$),
      concatMap(([{ idCampagneSocieteCaracteristique }, state]) =>
        this.campagneSocieteCaracteristiqueApiService.deleteCampagneSocieteCaracteristique(idCampagneSocieteCaracteristique).pipe(
          mergeMap(_success => getDefaultDeleteCampagneSocieteCaracteristiqueActions(state.entities[idCampagneSocieteCaracteristique] as CampagneSocieteCaracteristiqueEntityState)),
          catchError(error => of(CampagneSocieteCaracteristiqueGeneratedActions.campagneSocieteCaracteristiquesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCampagneSocieteCaracteristiquesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneSocieteCaracteristiqueGeneratedActions.normalizeManyCampagneSocieteCaracteristiquesAfterUpsert),
      concatMap(({ campagneSocieteCaracteristiques }) => {
        const actions: Action[] = getActionsToNormalizeCampagneSocieteCaracteristique(campagneSocieteCaracteristiques, StoreActionType.upsert);
        return [getMultiAction(actions, '[CampagneSocieteCaracteristique] Normalization After Upsert Success')];
      })
    );
  });
}
