import { createEntityAdapter } from '@ngrx/entity';
import { SocieteEspaceEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<SocieteEspaceEntityState>;

export const adapter = createEntityAdapter<SocieteEspaceEntityState>({
  selectId: o => o.idSocieteEspace
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const societeEspaceFeatureKey = 'societeEspace';
