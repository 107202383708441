import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { PatrimoineState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { PatrimoineEntityState } from '@get/api-interfaces';

export const patrimoineReducersGeneratedFunctions: ReducerTypes<PatrimoineState.IState, readonly ActionCreator[]>[] = [
  on(PatrimoineGeneratedActions.getOnePatrimoine, (state: PatrimoineState.IState) => setLoadingsState(state, true)),
  on(PatrimoineGeneratedActions.getManyPatrimoines, (state: PatrimoineState.IState) => setLoadingsState(state, true)),
  on(PatrimoineGeneratedActions.upsertOnePatrimoine, (state: PatrimoineState.IState) => setLoadingsState(state, true)),

  on(PatrimoineGeneratedActions.upsertManyPatrimoinesSuccess, (state: PatrimoineState.IState, { patrimoines }) =>
    PatrimoineState.adapter.upsertMany(patrimoines, setLoadingsState(state, false))
  ),
  on(PatrimoineGeneratedActions.deleteOnePatrimoine, (state: PatrimoineState.IState) => setLoadingsState(state, true)),
  on(PatrimoineGeneratedActions.deleteOnePatrimoineSuccess, (state: PatrimoineState.IState, { idPatrimoine }) =>
    PatrimoineState.adapter.removeOne(idPatrimoine, setLoadingsState(state, false))
  ),
  on(PatrimoineGeneratedActions.clearActivesPatrimoines, (state: PatrimoineState.IState) => ({ ...state, actives: [] })),
  on(PatrimoineGeneratedActions.addManyActivesPatrimoines, (state: PatrimoineState.IState, { idPatrimoines }) => ({
    ...state,
    actives: state.actives.concat(idPatrimoines)
  })),
  on(PatrimoineGeneratedActions.deleteOneActivePatrimoine, (state: PatrimoineState.IState, { idPatrimoine }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idPatrimoine)
  })),

  on(PatrimoineGeneratedActions.clearPatrimoines, () => PatrimoineState.initialState),

  on(
    PatrimoineGeneratedActions.addManyUserPatrimoineSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idUserPatrimoines }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const userPatrimoines = (state.entities[idPatrimoine]?.userPatrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            userPatrimoines: userPatrimoines.concat(
              idUserPatrimoines.filter(id => userPatrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManyUserPatrimoineSuccess,
    (state: PatrimoineState.IState, { idUserPatrimoines, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.userPatrimoines) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              userPatrimoines: (state.entities[idPatrimoine]?.userPatrimoines as number[])?.filter(
                (idUserPatrimoine: number) => !idUserPatrimoines.some((id: number) => id === idUserPatrimoine)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addManyUserSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idUsers }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const users = (state.entities[idPatrimoine]?.users as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            users: users.concat(
              idUsers.filter(id => users.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManyUserSuccess,
    (state: PatrimoineState.IState, { idUsers, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.users) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              users: (state.entities[idPatrimoine]?.users as number[])?.filter(
                (idUser: number) => !idUsers.some((id: number) => id === idUser)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addManyValeurPatrimoineSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idValeurPatrimoines }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const valeurPatrimoines = (state.entities[idPatrimoine]?.valeurPatrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            valeurPatrimoines: valeurPatrimoines.concat(
              idValeurPatrimoines.filter(id => valeurPatrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManyValeurPatrimoineSuccess,
    (state: PatrimoineState.IState, { idValeurPatrimoines, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.valeurPatrimoines) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              valeurPatrimoines: (state.entities[idPatrimoine]?.valeurPatrimoines as number[])?.filter(
                (idValeurPatrimoine: number) => !idValeurPatrimoines.some((id: number) => id === idValeurPatrimoine)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addManyValeurSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idValeurs }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const valeurs = (state.entities[idPatrimoine]?.valeurs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            valeurs: valeurs.concat(
              idValeurs.filter(id => valeurs.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManyValeurSuccess,
    (state: PatrimoineState.IState, { idValeurs, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.valeurs) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              valeurs: (state.entities[idPatrimoine]?.valeurs as number[])?.filter(
                (idValeur: number) => !idValeurs.some((id: number) => id === idValeur)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addManyAncetresSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idAncetres }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const patrimoineAncetres = (state.entities[idPatrimoine]?.ancetres as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            patrimoineAncetres: patrimoineAncetres.concat(
              idAncetres.filter(id => patrimoineAncetres.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManyAncetresSuccess,
    (state: PatrimoineState.IState, { idAncetres : idAncetres, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.ancetres) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              patrimoineAncetres: (state.entities[idPatrimoine]?.ancetres as number[])?.filter(
                (idPatrimoineAncetre: number) => !idAncetres.some((id: number) => id === idPatrimoineAncetre)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addManyDescendantsSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idDescendants }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const patrimoineAncetres = (state.entities[idPatrimoine]?.descendants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            patrimoineAncetres: patrimoineAncetres.concat(
              idDescendants.filter(id => patrimoineAncetres.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManyDescendantsSuccess,
    (state: PatrimoineState.IState, { idDescendants : idDescendants, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.descendants) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              patrimoineAncetres: (state.entities[idPatrimoine]?.descendants as number[])?.filter(
                (idPatrimoineAncetre: number) => !idDescendants.some((id: number) => id === idPatrimoineAncetre)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addManyEspaceSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idEspaces }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const espaces = (state.entities[idPatrimoine]?.espaces as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            espaces: espaces.concat(
              idEspaces.filter(id => espaces.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManyEspaceSuccess,
    (state: PatrimoineState.IState, { idEspaces, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.espaces) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              espaces: (state.entities[idPatrimoine]?.espaces as number[])?.filter(
                (idEspace: number) => !idEspaces.some((id: number) => id === idEspace)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addManyCampagnePatrimoineSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idCampagnePatrimoines }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const campagnePatrimoines = (state.entities[idPatrimoine]?.campagnePatrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            campagnePatrimoines: campagnePatrimoines.concat(
              idCampagnePatrimoines.filter(id => campagnePatrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManyCampagnePatrimoineSuccess,
    (state: PatrimoineState.IState, { idCampagnePatrimoines, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.campagnePatrimoines) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              campagnePatrimoines: (state.entities[idPatrimoine]?.campagnePatrimoines as number[])?.filter(
                (idCampagnePatrimoine: number) => !idCampagnePatrimoines.some((id: number) => id === idCampagnePatrimoine)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addManyCampagneSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idCampagnes }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const campagnes = (state.entities[idPatrimoine]?.campagnes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            campagnes: campagnes.concat(
              idCampagnes.filter(id => campagnes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManyCampagneSuccess,
    (state: PatrimoineState.IState, { idCampagnes, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.campagnes) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              campagnes: (state.entities[idPatrimoine]?.campagnes as number[])?.filter(
                (idCampagne: number) => !idCampagnes.some((id: number) => id === idCampagne)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addManySocieteTerritoirePatrimoineSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idSocieteTerritoirePatrimoines }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const societeTerritoirePatrimoines = (state.entities[idPatrimoine]?.societeTerritoirePatrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            societeTerritoirePatrimoines: societeTerritoirePatrimoines.concat(
              idSocieteTerritoirePatrimoines.filter(id => societeTerritoirePatrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManySocieteTerritoirePatrimoineSuccess,
    (state: PatrimoineState.IState, { idSocieteTerritoirePatrimoines, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.societeTerritoirePatrimoines) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              societeTerritoirePatrimoines: (state.entities[idPatrimoine]?.societeTerritoirePatrimoines as number[])?.filter(
                (idSocieteTerritoirePatrimoine: number) => !idSocieteTerritoirePatrimoines.some((id: number) => id === idSocieteTerritoirePatrimoine)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addManySocieteTerritoireSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idSocieteTerritoires }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const societeTerritoires = (state.entities[idPatrimoine]?.societeTerritoires as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            societeTerritoires: societeTerritoires.concat(
              idSocieteTerritoires.filter(id => societeTerritoires.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManySocieteTerritoireSuccess,
    (state: PatrimoineState.IState, { idSocieteTerritoires, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.societeTerritoires) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              societeTerritoires: (state.entities[idPatrimoine]?.societeTerritoires as number[])?.filter(
                (idSocieteTerritoire: number) => !idSocieteTerritoires.some((id: number) => id === idSocieteTerritoire)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addManyComposantAttenduSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idComposantAttendus }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      }
      const composantAttendus = (state.entities[idPatrimoine]?.composantAttendus as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            composantAttendus: composantAttendus.concat(
              idComposantAttendus.filter(id => composantAttendus.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManyComposantAttenduSuccess,
    (state: PatrimoineState.IState, { idComposantAttendus, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.composantAttendus) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              composantAttendus: (state.entities[idPatrimoine]?.composantAttendus as number[])?.filter(
                (idComposantAttendu: number) => !idComposantAttendus.some((id: number) => id === idComposantAttendu)
              )
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addSocietePatrimoineHierarchieSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idSocietePatrimoineHierarchie }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            societePatrimoineHierarchie: idSocietePatrimoineHierarchie
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess,
    (state: PatrimoineState.IState, { idSocietePatrimoineHierarchies, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.societePatrimoineHierarchie) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              societePatrimoineHierarchie: idSocietePatrimoineHierarchies.some(
                (idSocietePatrimoineHierarchie: number) => idSocietePatrimoineHierarchie === state.entities[idPatrimoine]?.societePatrimoineHierarchie
              )
                ? undefined
                : state.entities[idPatrimoine]?.societePatrimoineHierarchie
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.addSocieteSuccess,
    (state: PatrimoineState.IState, { idPatrimoine, idSociete }) => {
      if (!state.entities[idPatrimoine]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idPatrimoine]: {
            ...state.entities[idPatrimoine],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    PatrimoineGeneratedActions.deleteManySocieteSuccess,
    (state: PatrimoineState.IState, { idSocietes, idPatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idPatrimoines.reduce((entities, idPatrimoine) => {
            if (!state.entities[idPatrimoine]?.societe) {
              return entities;
            }
            entities[idPatrimoine] = {
              ...state.entities[idPatrimoine],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idPatrimoine]?.societe
              )
                ? undefined
                : state.entities[idPatrimoine]?.societe
            } as PatrimoineEntityState;
            return entities;
          }, {} as Dictionary<PatrimoineEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: PatrimoineState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): PatrimoineState.IState {
  return { ...state, isLoaded, isLoading };
}
