import { ModulePathKeyEnum } from '@enums';
import { MenuSection } from '@get/api-interfaces';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MenuUtils {
  export const LogOutLabel = 'Déconnexion';

  export const appMenuSections: MenuSection[] = [
    {
      id: '0',
      label: 'Administration',
      icon: 'settings',
      align: 'bottom',
      route: ModulePathKeyEnum.admin,
      admin: true,
      dataCy: 'administration'
    },
    {
      id: '1',
      label: 'User',
      icon: 'profil',
      route: ModulePathKeyEnum.user,
      align: 'bottom',
      dataCy: 'profil'
    },
    {
      id: '2',
      label: 'Notifications',
      icon: 'alert',
      align: 'bottom',
      route: ModulePathKeyEnum.notification,
      dataCy: 'notification'
    },
    {
      id: '3',
      label: 'Support Get',
      icon: 'question',
      align: 'bottom',
      route: ModulePathKeyEnum.support,
      dataCy: 'support'
    },
    {
      id: '4',
      label: LogOutLabel,
      icon: '',
      dataCy: 'logout',
      align: 'bottom'
    },
    {
      id: '5',
      label: 'Societe',
      icon: '',
      route: ModulePathKeyEnum.societe,
      dataCy: 'societe'
    },
    {
      id: '6',
      label: 'Consulter et enrichir',
      icon: 'consulter',
      route: ModulePathKeyEnum.consult,
      dataCy: 'consult'
    },
    {
      id: '7',
      label: 'Campagnes collecte',
      icon: 'campagne',
      route: ModulePathKeyEnum.campaign,
      dataCy: 'campaign'
    },
    {
      id: '8',
      label: 'Analyser',
      icon: 'analyser',
      route: ModulePathKeyEnum.analyze,
      dataCy: 'analyze'
    },
    {
      id: '9',
      label: 'Programmation',
      icon: 'workProgramming',
      route: ModulePathKeyEnum.workProgramming,
      dataCy: 'workProgramming'
    }
  ];
}
