import { Injectable } from '@angular/core';
import { RepositoryService } from '@get/services/repository';
import { GeneratedUserApiService } from './user-api-generated.service';
import { User } from '@get/api-interfaces';
import { Observable, firstValueFrom } from 'rxjs';
import { LibelleInternRightsEnum } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends GeneratedUserApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getManyForCampaign(params?: any): Observable<User[]> {
    return this.repo.getData<User[]>('user/for-campaign', params);
  }

  public updateUserPassword(user: User): Observable<Object> {
    return this.repo.update('user/update-password', user);
  }
}
