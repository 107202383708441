import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteProfilDroit } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteProfilDroitRelationsIds } from '@get/store/ids-interfaces';
import { SocieteProfilDroitGeneratedActions } from '@get/store/actions';
import { SocieteProfilDroitSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedSocieteProfilDroitService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteProfilDroitSelectors.selectIsLoadedSocieteProfilDroit));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteProfilDroitSelectors.selectIsLoadingSocieteProfilDroit));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteProfilDroitSelectors.selectIsReadyAndLoadedSocieteProfilDroit as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteProfilDroits(schema: SelectSchema = {}): Observable<SocieteProfilDroit[]> {
    return this.store$.pipe(select(SocieteProfilDroitSelectors.selectAllSocieteProfilDroits(schema))).pipe(
      switchMap(({ societeProfilDroits }: { societeProfilDroits: SocieteProfilDroit[] }) => {
        return this.getReady(schema).pipe(mapTo(societeProfilDroits));
      })
    );
  }

  public selectOneSocieteProfilDroit(
    idSocieteProfilDroit: number,
    schema: SelectSchema = {}
  ): Observable<SocieteProfilDroit> {
    return this.store$
      .pipe(select(SocieteProfilDroitSelectors.selectOneSocieteProfilDroit(schema, idSocieteProfilDroit)))
      .pipe(
        switchMap(({ societeProfilDroit }: { societeProfilDroit: SocieteProfilDroit }) => {
          return this.getReady(schema).pipe(mapTo(societeProfilDroit));
        })
      );
  }

  public selectAllActiveSocieteProfilDroits(schema: SelectSchema = {}): Observable<SocieteProfilDroit[]> {
    return this.store$.pipe(select(SocieteProfilDroitSelectors.selectActiveSocieteProfilDroits(schema))).pipe(
      switchMap(({ societeProfilDroits }: { societeProfilDroits: SocieteProfilDroit[] }) => {
        return this.getReady(schema).pipe(mapTo(societeProfilDroits));
      })
    );
  }

  public selectIdSocieteProfilDroitsActive(): Observable<number[]> {
    return this.store$.pipe(select(SocieteProfilDroitSelectors.selectIdSocieteProfilDroitsActive)).pipe(
      switchMap((idSocieteProfilDroits: number[]) => {
        return this.getReady().pipe(mapTo(idSocieteProfilDroits));
      })
    );
  }

  public getOneSocieteProfilDroit(
    idSocieteProfilDroit: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteProfilDroit> {
    this.store$.dispatch(SocieteProfilDroitGeneratedActions.getOneSocieteProfilDroit({ idSocieteProfilDroit, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteProfilDroitGeneratedActions.normalizeManySocieteProfilDroitsAfterUpsert,
        SocieteProfilDroitGeneratedActions.societeProfilDroitsFailure,
        true
      );
    }
  }

  public getManySocieteProfilDroits(
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteProfilDroit[]> {
    this.store$.dispatch(SocieteProfilDroitGeneratedActions.getManySocieteProfilDroits({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteProfilDroitGeneratedActions.normalizeManySocieteProfilDroitsAfterUpsert,
        SocieteProfilDroitGeneratedActions.societeProfilDroitsFailure
      );
    }
  }

  public upsertOneSocieteProfilDroit(
    societeProfilDroit: Partial<SocieteProfilDroit>,
    ids: SocieteProfilDroitRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteProfilDroit> {
    this.store$.dispatch(SocieteProfilDroitGeneratedActions.upsertOneSocieteProfilDroit({ societeProfilDroit, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteProfilDroitGeneratedActions.normalizeManySocieteProfilDroitsAfterUpsert,
        SocieteProfilDroitGeneratedActions.societeProfilDroitsFailure,
        true
      );
    }
  }

  public deleteOneSocieteProfilDroit(
    idSocieteProfilDroit: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(SocieteProfilDroitGeneratedActions.deleteOneSocieteProfilDroit({ idSocieteProfilDroit }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteProfilDroitGeneratedActions.deleteOneSocieteProfilDroitSuccess,
        SocieteProfilDroitGeneratedActions.societeProfilDroitsFailure
      );
    }
  }

  public setActiveSocieteProfilDroits(idSocieteProfilDroits: number[]): void {
    this.store$.dispatch(SocieteProfilDroitGeneratedActions.clearActivesSocieteProfilDroits());
    this.store$.dispatch(SocieteProfilDroitGeneratedActions.addManyActivesSocieteProfilDroits({ idSocieteProfilDroits }));
  }
}
