import { RepositoryService } from '@get/services/repository';
import { Fichier } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedFichierApiService {
  constructor(protected repo: RepositoryService) {}

  public getFichiers(params?: any): Observable<Fichier[]> {
    return this.repo.getData<Fichier[]>('fichier', params);
  }

  public getFichier(params: { idFichier: number; params?: any }): Observable<Fichier> {
    return this.repo.getData<Fichier>('fichier/' + params.idFichier, params.params);
  }

  public addFichier(fichier: Partial<Fichier>): Observable<Fichier> {
    return this.repo.create<Fichier>('fichier', fichier);
  }

  public updateFichier(fichier: Partial<Fichier>): Observable<Fichier> {
    return this.repo.update('fichier', fichier);
  }

  public deleteFichier(idFichier: number): Observable<number> {
    return this.repo.delete('fichier/' + +idFichier);
  }
}

