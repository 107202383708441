import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ComposantTemplate, ComposantTemplateEntityState } from '@get/api-interfaces';
import { CaracteristiqueTemplate, CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { ComposantGroupe, ComposantGroupeEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ComposantTemplateState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const composantTemplateRelations: string[] = ['caracteristiqueTemplates','societeComposants','fichiers','composantGroupes',];

export const { selectEntities, selectAll } = ComposantTemplateState.adapter.getSelectors();

export const selectComposantTemplateState = createFeatureSelector<ComposantTemplateState.IState>(ComposantTemplateState.composantTemplateFeatureKey);

export const selectIsLoadedComposantTemplate = createSelector(
  selectComposantTemplateState,
  (state: ComposantTemplateState.IState) => state.isLoaded
);

export const selectIsLoadingComposantTemplate = createSelector(
  selectComposantTemplateState,
  (state: ComposantTemplateState.IState) => state.isLoading
);

export const selectIsReadyComposantTemplate = createSelector(
  selectComposantTemplateState,
  (state: ComposantTemplateState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedComposantTemplate = createSelector(
  selectComposantTemplateState,
  (state: ComposantTemplateState.IState) => state.isLoaded && !state.isLoading
);

export const selectComposantTemplatesEntities = createSelector(selectComposantTemplateState, selectEntities);

export const selectComposantTemplatesArray = createSelector(selectComposantTemplateState, selectAll);

export const selectIdComposantTemplatesActive = createSelector(
  selectComposantTemplateState,
  (state: ComposantTemplateState.IState) => state.actives
);

const composantTemplatesInObject = (composantTemplates: Dictionary<ComposantTemplateEntityState>) => ({ composantTemplates })

const selectComposantTemplatesEntitiesDictionary = createSelector(selectComposantTemplatesEntities, composantTemplatesInObject);

const selectAllComposantTemplatesObject = createSelector(selectComposantTemplatesEntities, composantTemplates => {
  return hydrateAll({ composantTemplates });
});

const selectOneComposantTemplateDictionary = (idComposantTemplate : number) =>
  createSelector(selectComposantTemplatesEntities, composantTemplates => ({
    composantTemplates: { [idComposantTemplate]: composantTemplates[idComposantTemplate] }
  }));

const selectOneComposantTemplateDictionaryWithoutChild = (idComposantTemplate : number) =>
  createSelector(selectComposantTemplatesEntities, composantTemplates => ({
    composantTemplate: composantTemplates[idComposantTemplate]
  }));

const selectActiveComposantTemplatesEntities = createSelector(
  selectIdComposantTemplatesActive,
  selectComposantTemplatesEntities,
  (actives: number[], composantTemplates: Dictionary<ComposantTemplateEntityState>) => getComposantTemplatesFromActives(actives, composantTemplates)
);

function getComposantTemplatesFromActives(
  actives: number[],
  composantTemplates: Dictionary<ComposantTemplateEntityState>
): Dictionary<ComposantTemplateEntityState> {
  return actives.reduce((acc, idActive) => {
    if (composantTemplates[idActive]) {
      acc[idActive] = composantTemplates[idActive];
    }
    return acc;
  }, {} as Dictionary<ComposantTemplateEntityState>);
}

const selectAllComposantTemplatesSelectors: Dictionary<Selector> = {};
export function selectAllComposantTemplates(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ComposantTemplate>(
      schema,
      selectAllComposantTemplatesSelectors,
      selectComposantTemplatesEntitiesDictionary,
      getRelationSelectors,
      composantTemplateRelations,
      hydrateAll,
      'composantTemplate'
    );
  } else {
    return selectAllComposantTemplatesObject;
  }
}

export function selectAllComposantTemplatesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'composantTemplates'
): Selector {
  return createSelector(selectAllComposantTemplates(schema), result => {
    const res = { [customKey]: {} as Dictionary<ComposantTemplateEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.composantTemplates.length; i++) {
      res[customKey][result.composantTemplates[i].idComposantTemplate] = result.composantTemplates[i];
    }
    return res;
  });
}

export function selectOneComposantTemplate(
  schema: SelectSchema = {},
  idComposantTemplate: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneComposantTemplateDictionary(idComposantTemplate)];
  selectors.push(...getRelationSelectors(schema, composantTemplateRelations, 'composantTemplate'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneComposantTemplateDictionaryWithoutChild(idComposantTemplate);
  }
}

export function selectActiveComposantTemplates(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveComposantTemplatesEntities, composantTemplates => ({
      composantTemplates
    }))
  ];
  selectors.push(...getRelationSelectors(schema, composantTemplateRelations, 'composantTemplate'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  composantTemplates: Dictionary<ComposantTemplateEntityState>;
  fichiers?: Dictionary<FichierEntityState>;
  composantGroupes?: Dictionary<ComposantGroupeEntityState>;
  caracteristiqueTemplates?: Dictionary<CaracteristiqueTemplateEntityState>;
  societeComposants?: Dictionary<SocieteComposantEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { composantTemplates: (ComposantTemplate | null)[] } {
  const {
    composantTemplates,
    fichiers,
    composantGroupes,
    caracteristiqueTemplates,
    societeComposants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    composantTemplates: Object.keys(composantTemplates).map(idComposantTemplate =>
      hydrate(
        composantTemplates[idComposantTemplate] as ComposantTemplateEntityState,
        fichiers,
        composantGroupes,
        caracteristiqueTemplates,
        societeComposants
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { composantTemplate: ComposantTemplateEntityState | null } {
  const {
    composantTemplates,
    fichiers,
    composantGroupes,
    caracteristiqueTemplates,
    societeComposants
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const composantTemplate = Object.values(composantTemplates)[0];
  return {
    composantTemplate: hydrate(
      composantTemplate as ComposantTemplateEntityState,
      fichiers,
      composantGroupes,
      caracteristiqueTemplates,
      societeComposants
    )
  };
}

function hydrate(
  composantTemplate: ComposantTemplateEntityState,
  fichierEntities?: Dictionary<FichierEntityState>,
  composantGroupeEntities?: Dictionary<ComposantGroupeEntityState>,
  caracteristiqueTemplateEntities?: Dictionary<CaracteristiqueTemplateEntityState>,
  societeComposantEntities?: Dictionary<SocieteComposantEntityState>,
): ComposantTemplate | null {
  if (!composantTemplate) {
    return null;
  }

  const composantTemplateHydrated: ComposantTemplateEntityState = { ...composantTemplate };
  if (fichierEntities) {
    composantTemplateHydrated.fichier = fichierEntities[composantTemplate.fichier as number] as Fichier;
  } else {
    delete composantTemplateHydrated.fichier;
  }
  if (composantGroupeEntities) {
    composantTemplateHydrated.composantGroupe = composantGroupeEntities[composantTemplate.composantGroupe as number] as ComposantGroupe;
  } else {
    delete composantTemplateHydrated.composantGroupe;
  }

  if (caracteristiqueTemplateEntities) {
    composantTemplateHydrated.caracteristiqueTemplates = ((composantTemplateHydrated.caracteristiqueTemplates as number[]) || []).map(
      id => caracteristiqueTemplateEntities[id]
    ) as CaracteristiqueTemplate[];
  } else {
    delete composantTemplateHydrated.caracteristiqueTemplates;
  }

  if (societeComposantEntities) {
    composantTemplateHydrated.societeComposants = ((composantTemplateHydrated.societeComposants as number[]) || []).map(
      id => societeComposantEntities[id]
    ) as SocieteComposant[];
  } else {
    delete composantTemplateHydrated.societeComposants;
  }

  return composantTemplateHydrated as ComposantTemplate;
}
