import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CaracteristiqueChoixTemplate } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { CaracteristiqueChoixTemplateRelationsIds } from '@get/store/ids-interfaces';
import { CaracteristiqueChoixTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueChoixTemplateSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCaracteristiqueChoixTemplateService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(CaracteristiqueChoixTemplateSelectors.selectIsLoadedCaracteristiqueChoixTemplate));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(CaracteristiqueChoixTemplateSelectors.selectIsLoadingCaracteristiqueChoixTemplate));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      CaracteristiqueChoixTemplateSelectors.selectIsReadyAndLoadedCaracteristiqueChoixTemplate as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCaracteristiqueChoixTemplates(schema: SelectSchema = {}): Observable<CaracteristiqueChoixTemplate[]> {
    return this.store$.pipe(select(CaracteristiqueChoixTemplateSelectors.selectAllCaracteristiqueChoixTemplates(schema))).pipe(
      switchMap(({ caracteristiqueChoixTemplates }: { caracteristiqueChoixTemplates: CaracteristiqueChoixTemplate[] }) => {
        return this.getReady(schema).pipe(mapTo(caracteristiqueChoixTemplates));
      })
    );
  }

  public selectOneCaracteristiqueChoixTemplate(
    idCaracteristiqueChoixTemplate: number,
    schema: SelectSchema = {}
  ): Observable<CaracteristiqueChoixTemplate> {
    return this.store$
      .pipe(select(CaracteristiqueChoixTemplateSelectors.selectOneCaracteristiqueChoixTemplate(schema, idCaracteristiqueChoixTemplate)))
      .pipe(
        switchMap(({ caracteristiqueChoixTemplate }: { caracteristiqueChoixTemplate: CaracteristiqueChoixTemplate }) => {
          return this.getReady(schema).pipe(mapTo(caracteristiqueChoixTemplate));
        })
      );
  }

  public selectAllActiveCaracteristiqueChoixTemplates(schema: SelectSchema = {}): Observable<CaracteristiqueChoixTemplate[]> {
    return this.store$.pipe(select(CaracteristiqueChoixTemplateSelectors.selectActiveCaracteristiqueChoixTemplates(schema))).pipe(
      switchMap(({ caracteristiqueChoixTemplates }: { caracteristiqueChoixTemplates: CaracteristiqueChoixTemplate[] }) => {
        return this.getReady(schema).pipe(mapTo(caracteristiqueChoixTemplates));
      })
    );
  }

  public selectIdCaracteristiqueChoixTemplatesActive(): Observable<number[]> {
    return this.store$.pipe(select(CaracteristiqueChoixTemplateSelectors.selectIdCaracteristiqueChoixTemplatesActive)).pipe(
      switchMap((idCaracteristiqueChoixTemplates: number[]) => {
        return this.getReady().pipe(mapTo(idCaracteristiqueChoixTemplates));
      })
    );
  }

  public getOneCaracteristiqueChoixTemplate(
    idCaracteristiqueChoixTemplate: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<CaracteristiqueChoixTemplate> {
    this.store$.dispatch(CaracteristiqueChoixTemplateGeneratedActions.getOneCaracteristiqueChoixTemplate({ idCaracteristiqueChoixTemplate, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CaracteristiqueChoixTemplateGeneratedActions.normalizeManyCaracteristiqueChoixTemplatesAfterUpsert,
        CaracteristiqueChoixTemplateGeneratedActions.caracteristiqueChoixTemplatesFailure,
        true
      );
    }
  }

  public getManyCaracteristiqueChoixTemplates(
    params: any = {},
    getResult?: boolean
  ): void | Observable<CaracteristiqueChoixTemplate[]> {
    this.store$.dispatch(CaracteristiqueChoixTemplateGeneratedActions.getManyCaracteristiqueChoixTemplates({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CaracteristiqueChoixTemplateGeneratedActions.normalizeManyCaracteristiqueChoixTemplatesAfterUpsert,
        CaracteristiqueChoixTemplateGeneratedActions.caracteristiqueChoixTemplatesFailure
      );
    }
  }

  public upsertOneCaracteristiqueChoixTemplate(
    caracteristiqueChoixTemplate: Partial<CaracteristiqueChoixTemplate>,
    ids: CaracteristiqueChoixTemplateRelationsIds = {},
    getResult?: boolean
  ): void | Observable<CaracteristiqueChoixTemplate> {
    this.store$.dispatch(CaracteristiqueChoixTemplateGeneratedActions.upsertOneCaracteristiqueChoixTemplate({ caracteristiqueChoixTemplate, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CaracteristiqueChoixTemplateGeneratedActions.normalizeManyCaracteristiqueChoixTemplatesAfterUpsert,
        CaracteristiqueChoixTemplateGeneratedActions.caracteristiqueChoixTemplatesFailure,
        true
      );
    }
  }

  public deleteOneCaracteristiqueChoixTemplate(
    idCaracteristiqueChoixTemplate: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(CaracteristiqueChoixTemplateGeneratedActions.deleteOneCaracteristiqueChoixTemplate({ idCaracteristiqueChoixTemplate }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CaracteristiqueChoixTemplateGeneratedActions.deleteOneCaracteristiqueChoixTemplateSuccess,
        CaracteristiqueChoixTemplateGeneratedActions.caracteristiqueChoixTemplatesFailure
      );
    }
  }

  public setActiveCaracteristiqueChoixTemplates(idCaracteristiqueChoixTemplates: number[]): void {
    this.store$.dispatch(CaracteristiqueChoixTemplateGeneratedActions.clearActivesCaracteristiqueChoixTemplates());
    this.store$.dispatch(CaracteristiqueChoixTemplateGeneratedActions.addManyActivesCaracteristiqueChoixTemplates({ idCaracteristiqueChoixTemplates }));
  }
}
