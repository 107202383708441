import {
  AdminPatrimoineListQueryParams,
  AnalyzePerPatrimoineParams,
  AnalyzeSyntheticPatrimoinesQueryParams,
  FetchPatrimoineForCampagne,
  PatrimoineRattachementFindOptions,
  UpdatePatrimoineRattachement,
  Valeur
} from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const PatrimoineActions = createActionGroup({
  source: 'Patrimoine',
  events: {
    'Get One Patrimoine Complete': props<{ idPatrimoine: number; params?: any }>(),
    'Get One Patrimoine With Composant Valeurs': props<{ idPatrimoine: number; params?: any }>(),
    'Get Many Patrimoines With Valeurs': props<{ params: any }>(),
    'Get Many Patrimoines Rattachement': props<{ params: PatrimoineRattachementFindOptions }>(),
    'Get Many Patrimoines For User Rattachement': props<{ params: PatrimoineRattachementFindOptions }>(),
    'Get Descendants Patrimoine': props<{ idPatrimoine: number; params?: any }>(),
    'Get Many Patrimoines Progress': props<{ params: any }>(),
    'Get Patrimoines For Per Type Composant': props<{ params: any }>(),
    'Get Many Patrimoine Analyze': props<{ params: AnalyzePerPatrimoineParams }>(),
    'Get One Patrimoine For Campagne': props<{ params: FetchPatrimoineForCampagne }>(),
    'Fetch Analyze Synthetic Patrimoines': props<{ params: AnalyzeSyntheticPatrimoinesQueryParams }>(),
    'Fetch Admin Patrimoine List': props<{ params: AdminPatrimoineListQueryParams }>(),
    'Upsert One Patrimoine Rattachement': props<{ params: UpdatePatrimoineRattachement }>(),
    'Upsert One Valeur': props<{ valeur: Partial<Valeur> }>(),
    'Delete All Patrimoines': props<{ idSociete: number }>(),
    'Delete All Patrimoines Success': props<{ idSociete: number }>(),
    'Delete One Valeur Fichier': props<{ idValeurFichier: number }>(),
    'Delete One Valeur Fichier Success': props<{ idValeurFichier: number }>(),
    'Delete One Patrimoine And Return All Deleted Patrimoines': props<{ idPatrimoine: number }>()
  }
});
