import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteComposantRattachement, SocieteComposantRattachementEntityState } from '@get/api-interfaces';
import { SocieteComposantRattachementRelationsIds } from '@get/store/ids-interfaces';

export const SocieteComposantRattachementGeneratedActions = createActionGroup({
  source: 'Societe Composant Rattachement Generated',
  events: {
    'Get One Societe Composant Rattachement': props<{ idSocieteComposantRattachement: number; params?: any }>(),
    'Get Many Societe Composant Rattachements': props<{ params: any }>(),
    'Add Many Actives Societe Composant Rattachements': props<{ idSocieteComposantRattachements: number[] }>(),
    'Delete One Active Societe Composant Rattachement': props<{ idSocieteComposantRattachement: number }>(),
    'Clear Actives Societe Composant Rattachements': emptyProps(),
    'Upsert One Societe Composant Rattachement': props<{ societeComposantRattachement: Partial<SocieteComposantRattachement>; ids?: SocieteComposantRattachementRelationsIds; }>(),
    'Upsert Many Societe Composant Rattachements': props<{ societeComposantRattachements: Partial<SocieteComposantRattachement>[]; ids?: SocieteComposantRattachementRelationsIds; }>(),
    'Upsert Many Societe Composant Rattachements Success': props<{ societeComposantRattachements: SocieteComposantRattachementEntityState[] }>(),
    'Delete One Societe Composant Rattachement': props<{ idSocieteComposantRattachement: number }>(),
    'Delete One Societe Composant Rattachement Success': props<{ idSocieteComposantRattachement: number }>(),
    'Normalize Many Societe Composant Rattachements After Upsert': props<{ societeComposantRattachements: SocieteComposantRattachementEntityState[] }>(),
    'Societe Composant Rattachements Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Composant Rattachements': emptyProps(),
    'Add Societe Composant Success': props<{ idSocieteComposantRattachement: number; idSocieteComposant: number }>(),
    'Delete Many Societe Composant Success': props<{ idSocieteComposants: number[]; idSocieteComposantRattachements: number[] }>(),
    'Add Societe Espace Famille Success': props<{ idSocieteComposantRattachement: number; idSocieteEspaceFamille: number }>(),
    'Delete Many Societe Espace Famille Success': props<{ idSocieteEspaceFamilles: number[]; idSocieteComposantRattachements: number[] }>(),
    'Add Societe Patrimoine Hierarchie Success': props<{ idSocieteComposantRattachement: number; idSocietePatrimoineHierarchie: number }>(),
    'Delete Many Societe Patrimoine Hierarchie Success': props<{ idSocietePatrimoineHierarchies: number[]; idSocieteComposantRattachements: number[] }>()
  }
});
