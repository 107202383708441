import { createEntityAdapter } from '@ngrx/entity';
import { SocieteComposantFamilleEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<SocieteComposantFamilleEntityState>;

export const adapter = createEntityAdapter<SocieteComposantFamilleEntityState>({
  selectId: o => o.idSocieteComposantFamille
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const societeComposantFamilleFeatureKey = 'societeComposantFamille';
