import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Newsletter } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { NewsletterRelationsIds } from '@get/store/ids-interfaces';
import { NewsletterGeneratedActions } from '@get/store/actions';
import { NewsletterSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedNewsletterService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(NewsletterSelectors.selectIsLoadedNewsletter));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(NewsletterSelectors.selectIsLoadingNewsletter));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      NewsletterSelectors.selectIsReadyAndLoadedNewsletter as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllNewsletters(schema: SelectSchema = {}): Observable<Newsletter[]> {
    return this.store$.pipe(select(NewsletterSelectors.selectAllNewsletters(schema))).pipe(
      switchMap(({ newsletters }: { newsletters: Newsletter[] }) => {
        return this.getReady(schema).pipe(mapTo(newsletters));
      })
    );
  }

  public selectOneNewsletter(
    idNewsletter: number,
    schema: SelectSchema = {}
  ): Observable<Newsletter> {
    return this.store$
      .pipe(select(NewsletterSelectors.selectOneNewsletter(schema, idNewsletter)))
      .pipe(
        switchMap(({ newsletter }: { newsletter: Newsletter }) => {
          return this.getReady(schema).pipe(mapTo(newsletter));
        })
      );
  }

  public selectAllActiveNewsletters(schema: SelectSchema = {}): Observable<Newsletter[]> {
    return this.store$.pipe(select(NewsletterSelectors.selectActiveNewsletters(schema))).pipe(
      switchMap(({ newsletters }: { newsletters: Newsletter[] }) => {
        return this.getReady(schema).pipe(mapTo(newsletters));
      })
    );
  }

  public selectIdNewslettersActive(): Observable<number[]> {
    return this.store$.pipe(select(NewsletterSelectors.selectIdNewslettersActive)).pipe(
      switchMap((idNewsletters: number[]) => {
        return this.getReady().pipe(mapTo(idNewsletters));
      })
    );
  }

  public getOneNewsletter(
    idNewsletter: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<Newsletter> {
    this.store$.dispatch(NewsletterGeneratedActions.getOneNewsletter({ idNewsletter, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        NewsletterGeneratedActions.normalizeManyNewslettersAfterUpsert,
        NewsletterGeneratedActions.newslettersFailure,
        true
      );
    }
  }

  public getManyNewsletters(
    params: any = {},
    getResult?: boolean
  ): void | Observable<Newsletter[]> {
    this.store$.dispatch(NewsletterGeneratedActions.getManyNewsletters({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        NewsletterGeneratedActions.normalizeManyNewslettersAfterUpsert,
        NewsletterGeneratedActions.newslettersFailure
      );
    }
  }

  public upsertOneNewsletter(
    newsletter: Partial<Newsletter>,
    ids: NewsletterRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Newsletter> {
    this.store$.dispatch(NewsletterGeneratedActions.upsertOneNewsletter({ newsletter, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        NewsletterGeneratedActions.normalizeManyNewslettersAfterUpsert,
        NewsletterGeneratedActions.newslettersFailure,
        true
      );
    }
  }

  public deleteOneNewsletter(
    idNewsletter: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(NewsletterGeneratedActions.deleteOneNewsletter({ idNewsletter }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        NewsletterGeneratedActions.deleteOneNewsletterSuccess,
        NewsletterGeneratedActions.newslettersFailure
      );
    }
  }

  public setActiveNewsletters(idNewsletters: number[]): void {
    this.store$.dispatch(NewsletterGeneratedActions.clearActivesNewsletters());
    this.store$.dispatch(NewsletterGeneratedActions.addManyActivesNewsletters({ idNewsletters }));
  }
}
