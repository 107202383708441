import { CaracteristiqueTypeEnum } from '@enums';
import { SocieteCaracteristique, Valeur, ValueEntry } from '@get/api-interfaces';
import { formatDate } from '@utils';

export function formatActualValue(value: any, societeCaracteristique?: SocieteCaracteristique): string {
  if (
    (societeCaracteristique && societeCaracteristique?.type === CaracteristiqueTypeEnum.date && value) ||
    (!societeCaracteristique && value instanceof Date)
  ) {
    return formatDate(value, 'DD/MM/YY');
  }
  if (typeof value === 'boolean') {
    return value ? 'Oui' : 'Non';
  }
  return value;
}

export function getSortedValeursDoubleArray(valeurs: Valeur[]): Valeur[][] {
  const obj =
    valeurs?.reduce((acc, value) => {
      acc[value?.societeCaracteristique?.ligne] = (acc[value?.societeCaracteristique?.ligne] || []).concat(value);
      return acc;
    }, {} as { [idx: number]: Valeur[] }) || {};
  return Object.values(obj)
    ?.map(values => values?.sort((a, b) => a?.societeCaracteristique?.ordre - b?.societeCaracteristique?.ordre))
    ?.sort((a, b) => a?.[0]?.societeCaracteristique?.ligne - b?.[0]?.societeCaracteristique?.ligne);
}

export function getSortedValeursEntries(valeurs: Valeur[]): ValueEntry[] {
  return Object.entries(
    valeurs?.reduce((acc, value) => {
      acc[value?.societeCaracteristique?.titreGroupement] = {
        ...(acc[value?.societeCaracteristique?.titreGroupement] || {}),
        [value?.societeCaracteristique?.ligne]: (
          acc[value?.societeCaracteristique?.titreGroupement]?.[value?.societeCaracteristique?.ligne] || []
        ).concat(value)
      };
      return acc;
    }, {} as { [grp: string]: { [ligne: number]: Valeur[] } }) || {}
  )
    ?.sort((a, b) => (a[0] === 'null' ? -1 : b[0] === 'null' ? 1 : a[0].localeCompare(b[0])))
    ?.map((entry, index) => ({
      grp: entry[0],
      index,
      valeurs: Object.values(entry[1])
        .map(values => values?.sort((a, b) => a?.societeCaracteristique?.ordre - b?.societeCaracteristique?.ordre))
        ?.sort((a, b) => a?.[0]?.societeCaracteristique?.ligne - b?.[0]?.societeCaracteristique?.ligne)
    }));
}
