/* eslint-disable max-lines */
import { StoreActionType } from '@enums';
import { Action } from '@ngrx/store';
import { CampagneGeneratedActions } from '@get/store/actions';
import { Campagne, CampagneEntityState } from '@get/api-interfaces';
import { CampagnePatrimoineGeneratedActions } from '@get/store/actions';
import { CampagnePatrimoine, CampagnePatrimoineEntityState } from '@get/api-interfaces';
import { CampagneSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { CampagneSocieteCaracteristique, CampagneSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { CampagneUserGeneratedActions } from '@get/store/actions';
import { CampagneUser, CampagneUserEntityState } from '@get/api-interfaces';
import { CaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueTemplate, CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { CaracteristiqueChoixTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueChoixTemplate, CaracteristiqueChoixTemplateEntityState } from '@get/api-interfaces';
import { ComposantGeneratedActions } from '@get/store/actions';
import { Composant, ComposantEntityState } from '@get/api-interfaces';
import { ComposantAttenduGeneratedActions } from '@get/store/actions';
import { ComposantAttendu, ComposantAttenduEntityState } from '@get/api-interfaces';
import { ComposantGroupeGeneratedActions } from '@get/store/actions';
import { ComposantGroupe, ComposantGroupeEntityState } from '@get/api-interfaces';
import { ComposantTemplateGeneratedActions } from '@get/store/actions';
import { ComposantTemplate, ComposantTemplateEntityState } from '@get/api-interfaces';
import { DroitGeneratedActions } from '@get/store/actions';
import { Droit, DroitEntityState } from '@get/api-interfaces';
import { EspaceGeneratedActions } from '@get/store/actions';
import { Espace, EspaceEntityState } from '@get/api-interfaces';
import { FichierGeneratedActions } from '@get/store/actions';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { NewsletterGeneratedActions } from '@get/store/actions';
import { Newsletter, NewsletterEntityState } from '@get/api-interfaces';
import { OrganisationAzureLoginGeneratedActions } from '@get/store/actions';
import { OrganisationAzureLogin, OrganisationAzureLoginEntityState } from '@get/api-interfaces';
import { OrganisationGeneratedActions } from '@get/store/actions';
import { Organisation, OrganisationEntityState } from '@get/api-interfaces';
import { OrganisationNewsletterGeneratedActions } from '@get/store/actions';
import { OrganisationNewsletter, OrganisationNewsletterEntityState } from '@get/api-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { PatrimoineAncetreGeneratedActions } from '@get/store/actions';
import { PatrimoineAncetre, PatrimoineAncetreEntityState } from '@get/api-interfaces';
import { PatrimoineHierarchieTemplateGeneratedActions } from '@get/store/actions';
import { PatrimoineHierarchieTemplateEntityState } from '@get/api-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoix, SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSynthese, SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristique, SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { SocieteComposantFamilleGeneratedActions } from '@get/store/actions';
import { SocieteComposantFamille, SocieteComposantFamilleEntityState } from '@get/api-interfaces';
import { SocieteComposantRattachementGeneratedActions } from '@get/store/actions';
import { SocieteComposantRattachement, SocieteComposantRattachementEntityState } from '@get/api-interfaces';
import { SocieteEspaceGeneratedActions } from '@get/store/actions';
import { SocieteEspace, SocieteEspaceEntityState } from '@get/api-interfaces';
import { SocieteEspaceFamilleGeneratedActions } from '@get/store/actions';
import { SocieteEspaceFamille, SocieteEspaceFamilleEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchieAncetreGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchieAncetre, SocietePatrimoineHierarchieAncetreEntityState } from '@get/api-interfaces';
import { SocieteProfilGeneratedActions } from '@get/store/actions';
import { SocieteProfil, SocieteProfilEntityState } from '@get/api-interfaces';
import { SocieteProfilDroitGeneratedActions } from '@get/store/actions';
import { SocieteProfilDroit, SocieteProfilDroitEntityState } from '@get/api-interfaces';
import { SocieteTerritoireGeneratedActions } from '@get/store/actions';
import { SocieteTerritoire, SocieteTerritoireEntityState } from '@get/api-interfaces';
import { SocieteTerritoirePatrimoineGeneratedActions } from '@get/store/actions';
import { SocieteTerritoirePatrimoine, SocieteTerritoirePatrimoineEntityState } from '@get/api-interfaces';
import { SocieteTerritoireUserGeneratedActions } from '@get/store/actions';
import { SocieteTerritoireUser, SocieteTerritoireUserEntityState } from '@get/api-interfaces';
import { UserGeneratedActions } from '@get/store/actions';
import { User, UserEntityState } from '@get/api-interfaces';
import { UserNewsletterGeneratedActions } from '@get/store/actions';
import { UserNewsletter, UserNewsletterEntityState } from '@get/api-interfaces';
import { UserPatrimoineGeneratedActions } from '@get/store/actions';
import { UserPatrimoine, UserPatrimoineEntityState } from '@get/api-interfaces';
import { UserSocieteProfilGeneratedActions } from '@get/store/actions';
import { UserSocieteProfil, UserSocieteProfilEntityState } from '@get/api-interfaces';
import { ValeurGeneratedActions } from '@get/store/actions';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { ValeurFichierGeneratedActions } from '@get/store/actions';
import { ValeurFichier, ValeurFichierEntityState } from '@get/api-interfaces';
import { ValeurComposantGeneratedActions } from '@get/store/actions';
import { ValeurComposant, ValeurComposantEntityState } from '@get/api-interfaces';
import { ValeurPatrimoineGeneratedActions } from '@get/store/actions';
import { ValeurPatrimoine, ValeurPatrimoineEntityState } from '@get/api-interfaces';

/**
 *  Campagne
 */
export function getActionsToNormalizeCampagne(
  campagnes: CampagneEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const campagneUsersToNormalize: CampagneUserEntityState[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};
  const campagnePatrimoinesToNormalize: CampagnePatrimoineEntityState[] = [];
  const campagneSocieteCaracteristiquesToNormalize: CampagneSocieteCaracteristiqueEntityState[] = [];

  const normalizedCampagnes = campagnes.map((campagne: CampagneEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    campagneNormalizePatrimoine(campagne, patrimoinesToNormalize, relations);
    campagneNormalizeCampagneUser(campagne, campagneUsersToNormalize, relations);
    campagneNormalizeUser(campagne, usersToNormalize, relations);
    campagneNormalizeSocieteCaracteristique(campagne, societeCaracteristiquesToNormalize, relations);
    campagneNormalizeCampagnePatrimoine(campagne, campagnePatrimoinesToNormalize, relations);
    campagneNormalizeCampagneSocieteCaracteristique(campagne, campagneSocieteCaracteristiquesToNormalize, relations);
    campagneNormalizeSociete(campagne, societesToNormalize, relations);

    return {
      ...campagne,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  if (campagneUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagneUser(campagneUsersToNormalize, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }
  if (campagnePatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagnePatrimoine(campagnePatrimoinesToNormalize, actionType));
  }
  if (campagneSocieteCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagneSocieteCaracteristique(campagneSocieteCaracteristiquesToNormalize, actionType));
  }

  const campagneActionCreator = CampagneGeneratedActions.upsertManyCampagnesSuccess;
  actions.push(campagneActionCreator({ campagnes: normalizedCampagnes }));

  return actions;
}

function campagneNormalizePatrimoine(
  campagne: CampagneEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campagne.patrimoines?.length && typeof campagne.patrimoines[0] !== 'number') {
    const idsPatrimoines: number[] = [];
    for (let i = 0; i < campagne.patrimoines.length; i++) {
      const patrimoine: Patrimoine | number = campagne.patrimoines[i];

      if (typeof patrimoine !== 'number') {
        if (!patrimoinesToNormalize[patrimoine.idPatrimoine]) {
          patrimoinesToNormalize[patrimoine.idPatrimoine] = {
            ...patrimoine,
            campagnes: patrimoine.campagnes?.concat() ?? []
          };
        }
        (patrimoinesToNormalize[patrimoine.idPatrimoine].campagnes as number[]).push(campagne.idCampagne);
        idsPatrimoines.push(patrimoine.idPatrimoine);
      } else {
        idsPatrimoines.push(patrimoine);
      }
    }
    relations['patrimoines'] = idsPatrimoines;
  }
}

function campagneNormalizeCampagneUser(
  campagne: CampagneEntityState,
  campagneUsersToNormalize: CampagneUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (campagne.campagneUsers?.length && typeof campagne.campagneUsers[0] !== 'number') {
    const idsCampagneUsers: number[] = [];
    for (let i = 0; i < campagne.campagneUsers.length; i++) {
      const campagneUser: CampagneUser | number = campagne.campagneUsers[i];
      if (typeof campagneUser !== 'number') {
        campagneUsersToNormalize.push({ ...campagneUser, campagne: campagne.idCampagne });
        idsCampagneUsers.push(campagneUser.idCampagneUser);
      } else {
        idsCampagneUsers.push(campagneUser);
      }
    }
    relations['campagneUsers'] = idsCampagneUsers;
  }
}

function campagneNormalizeUser(
  campagne: CampagneEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campagne.users?.length && typeof campagne.users[0] !== 'number') {
    const idsUsers: number[] = [];
    for (let i = 0; i < campagne.users.length; i++) {
      const user: User | number = campagne.users[i];

      if (typeof user !== 'number') {
        if (!usersToNormalize[user.idUser]) {
          usersToNormalize[user.idUser] = {
            ...user,
            campagnes: user.campagnes?.concat() ?? []
          };
        }
        (usersToNormalize[user.idUser].campagnes as number[]).push(campagne.idCampagne);
        idsUsers.push(user.idUser);
      } else {
        idsUsers.push(user);
      }
    }
    relations['users'] = idsUsers;
  }
}

function campagneNormalizeSocieteCaracteristique(
  campagne: CampagneEntityState,
  societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campagne.societeCaracteristiques?.length && typeof campagne.societeCaracteristiques[0] !== 'number') {
    const idsSocieteCaracteristiques: number[] = [];
    for (let i = 0; i < campagne.societeCaracteristiques.length; i++) {
      const societeCaracteristique: SocieteCaracteristique | number = campagne.societeCaracteristiques[i];

      if (typeof societeCaracteristique !== 'number') {
        if (!societeCaracteristiquesToNormalize[societeCaracteristique.idSocieteCaracteristique]) {
          societeCaracteristiquesToNormalize[societeCaracteristique.idSocieteCaracteristique] = {
            ...societeCaracteristique,
            campagnes: societeCaracteristique.campagnes?.concat() ?? []
          };
        }
        (societeCaracteristiquesToNormalize[societeCaracteristique.idSocieteCaracteristique].campagnes as number[]).push(campagne.idCampagne);
        idsSocieteCaracteristiques.push(societeCaracteristique.idSocieteCaracteristique);
      } else {
        idsSocieteCaracteristiques.push(societeCaracteristique);
      }
    }
    relations['societeCaracteristiques'] = idsSocieteCaracteristiques;
  }
}

function campagneNormalizeCampagnePatrimoine(
  campagne: CampagneEntityState,
  campagnePatrimoinesToNormalize: CampagnePatrimoineEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (campagne.campagnePatrimoines?.length && typeof campagne.campagnePatrimoines[0] !== 'number') {
    const idsCampagnePatrimoines: number[] = [];
    for (let i = 0; i < campagne.campagnePatrimoines.length; i++) {
      const campagnePatrimoine: CampagnePatrimoine | number = campagne.campagnePatrimoines[i];
      if (typeof campagnePatrimoine !== 'number') {
        campagnePatrimoinesToNormalize.push({ ...campagnePatrimoine, campagne: campagne.idCampagne });
        idsCampagnePatrimoines.push(campagnePatrimoine.idCampagnePatrimoine);
      } else {
        idsCampagnePatrimoines.push(campagnePatrimoine);
      }
    }
    relations['campagnePatrimoines'] = idsCampagnePatrimoines;
  }
}

function campagneNormalizeCampagneSocieteCaracteristique(
  campagne: CampagneEntityState,
  campagneSocieteCaracteristiquesToNormalize: CampagneSocieteCaracteristiqueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (campagne.campagneSocieteCaracteristiques?.length && typeof campagne.campagneSocieteCaracteristiques[0] !== 'number') {
    const idsCampagneSocieteCaracteristiques: number[] = [];
    for (let i = 0; i < campagne.campagneSocieteCaracteristiques.length; i++) {
      const campagneSocieteCaracteristique: CampagneSocieteCaracteristique | number = campagne.campagneSocieteCaracteristiques[i];
      if (typeof campagneSocieteCaracteristique !== 'number') {
        campagneSocieteCaracteristiquesToNormalize.push({ ...campagneSocieteCaracteristique, campagne: campagne.idCampagne });
        idsCampagneSocieteCaracteristiques.push(campagneSocieteCaracteristique.idCampagneSocieteCaracteristique);
      } else {
        idsCampagneSocieteCaracteristiques.push(campagneSocieteCaracteristique);
      }
    }
    relations['campagneSocieteCaracteristiques'] = idsCampagneSocieteCaracteristiques;
  }
}

function campagneNormalizeSociete(
  campagne: CampagneEntityState,
  societesToNormalize: { [id: number]: SocieteEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campagne.societe && typeof campagne.societe !== 'number') {
    if (!societesToNormalize[campagne.societe.idSociete]) {
      societesToNormalize[campagne.societe.idSociete] = {
        ...campagne.societe,
        campagnes: campagne.societe.campagnes?.concat() ?? []
      };
    }
    (societesToNormalize[campagne.societe.idSociete].campagnes as number[]).push(
      campagne.idCampagne
    );
    relations['societe'] = campagne.societe.idSociete;
  }
}

/**
 *  CampagnePatrimoine
 */
export function getActionsToNormalizeCampagnePatrimoine(
  campagnePatrimoines: CampagnePatrimoineEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedCampagnePatrimoines = campagnePatrimoines.map((campagnePatrimoine: CampagnePatrimoineEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    campagnePatrimoineNormalizeCampagne(campagnePatrimoine, campagnesToNormalize, relations);
    campagnePatrimoineNormalizePatrimoine(campagnePatrimoine, patrimoinesToNormalize, relations);

    return {
      ...campagnePatrimoine,
      ...relations
    };
  });
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }

  const campagnePatrimoineActionCreator = CampagnePatrimoineGeneratedActions.upsertManyCampagnePatrimoinesSuccess;
  actions.push(campagnePatrimoineActionCreator({ campagnePatrimoines: normalizedCampagnePatrimoines }));

  return actions;
}

function campagnePatrimoineNormalizeCampagne(
  campagnePatrimoine: CampagnePatrimoineEntityState,
  campagnesToNormalize: { [id: number]: CampagneEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campagnePatrimoine.campagne && typeof campagnePatrimoine.campagne !== 'number') {
    if (!campagnesToNormalize[campagnePatrimoine.campagne.idCampagne]) {
      campagnesToNormalize[campagnePatrimoine.campagne.idCampagne] = {
        ...campagnePatrimoine.campagne,
        campagnePatrimoines: campagnePatrimoine.campagne.campagnePatrimoines?.concat() ?? []
      };
    }
    (campagnesToNormalize[campagnePatrimoine.campagne.idCampagne].campagnePatrimoines as number[]).push(
      campagnePatrimoine.idCampagnePatrimoine
    );
    relations['campagne'] = campagnePatrimoine.campagne.idCampagne;
  }
}

function campagnePatrimoineNormalizePatrimoine(
  campagnePatrimoine: CampagnePatrimoineEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campagnePatrimoine.patrimoine && typeof campagnePatrimoine.patrimoine !== 'number') {
    if (!patrimoinesToNormalize[campagnePatrimoine.patrimoine.idPatrimoine]) {
      patrimoinesToNormalize[campagnePatrimoine.patrimoine.idPatrimoine] = {
        ...campagnePatrimoine.patrimoine,
        campagnePatrimoines: campagnePatrimoine.patrimoine.campagnePatrimoines?.concat() ?? []
      };
    }
    (patrimoinesToNormalize[campagnePatrimoine.patrimoine.idPatrimoine].campagnePatrimoines as number[]).push(
      campagnePatrimoine.idCampagnePatrimoine
    );
    relations['patrimoine'] = campagnePatrimoine.patrimoine.idPatrimoine;
  }
}

/**
 *  CampagneSocieteCaracteristique
 */
export function getActionsToNormalizeCampagneSocieteCaracteristique(
  campagneSocieteCaracteristiques: CampagneSocieteCaracteristiqueEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};
  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};

  const normalizedCampagneSocieteCaracteristiques = campagneSocieteCaracteristiques.map((campagneSocieteCaracteristique: CampagneSocieteCaracteristiqueEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    campagneSocieteCaracteristiqueNormalizeCampagne(campagneSocieteCaracteristique, campagnesToNormalize, relations);
    campagneSocieteCaracteristiqueNormalizeSocieteCaracteristique(campagneSocieteCaracteristique, societeCaracteristiquesToNormalize, relations);

    return {
      ...campagneSocieteCaracteristique,
      ...relations
    };
  });
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }

  const campagneSocieteCaracteristiqueActionCreator = CampagneSocieteCaracteristiqueGeneratedActions.upsertManyCampagneSocieteCaracteristiquesSuccess;
  actions.push(campagneSocieteCaracteristiqueActionCreator({ campagneSocieteCaracteristiques: normalizedCampagneSocieteCaracteristiques }));

  return actions;
}

function campagneSocieteCaracteristiqueNormalizeCampagne(
  campagneSocieteCaracteristique: CampagneSocieteCaracteristiqueEntityState,
  campagnesToNormalize: { [id: number]: CampagneEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campagneSocieteCaracteristique.campagne && typeof campagneSocieteCaracteristique.campagne !== 'number') {
    if (!campagnesToNormalize[campagneSocieteCaracteristique.campagne.idCampagne]) {
      campagnesToNormalize[campagneSocieteCaracteristique.campagne.idCampagne] = {
        ...campagneSocieteCaracteristique.campagne,
        campagneSocieteCaracteristiques: campagneSocieteCaracteristique.campagne.campagneSocieteCaracteristiques?.concat() ?? []
      };
    }
    (campagnesToNormalize[campagneSocieteCaracteristique.campagne.idCampagne].campagneSocieteCaracteristiques as number[]).push(
      campagneSocieteCaracteristique.idCampagneSocieteCaracteristique
    );
    relations['campagne'] = campagneSocieteCaracteristique.campagne.idCampagne;
  }
}

function campagneSocieteCaracteristiqueNormalizeSocieteCaracteristique(
  campagneSocieteCaracteristique: CampagneSocieteCaracteristiqueEntityState,
  societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campagneSocieteCaracteristique.societeCaracteristique && typeof campagneSocieteCaracteristique.societeCaracteristique !== 'number') {
    if (!societeCaracteristiquesToNormalize[campagneSocieteCaracteristique.societeCaracteristique.idSocieteCaracteristique]) {
      societeCaracteristiquesToNormalize[campagneSocieteCaracteristique.societeCaracteristique.idSocieteCaracteristique] = {
        ...campagneSocieteCaracteristique.societeCaracteristique,
        campagneSocieteCaracteristiques: campagneSocieteCaracteristique.societeCaracteristique.campagneSocieteCaracteristiques?.concat() ?? []
      };
    }
    (societeCaracteristiquesToNormalize[campagneSocieteCaracteristique.societeCaracteristique.idSocieteCaracteristique].campagneSocieteCaracteristiques as number[]).push(
      campagneSocieteCaracteristique.idCampagneSocieteCaracteristique
    );
    relations['societeCaracteristique'] = campagneSocieteCaracteristique.societeCaracteristique.idSocieteCaracteristique;
  }
}

/**
 *  CampagneUser
 */
export function getActionsToNormalizeCampagneUser(
  campagneUsers: CampagneUserEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};

  const normalizedCampagneUsers = campagneUsers.map((campagneUser: CampagneUserEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    campagneUserNormalizeUser(campagneUser, usersToNormalize, relations);
    campagneUserNormalizeCampagne(campagneUser, campagnesToNormalize, relations);

    return {
      ...campagneUser,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }

  const campagneUserActionCreator = CampagneUserGeneratedActions.upsertManyCampagneUsersSuccess;
  actions.push(campagneUserActionCreator({ campagneUsers: normalizedCampagneUsers }));

  return actions;
}

function campagneUserNormalizeUser(
  campagneUser: CampagneUserEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campagneUser.user && typeof campagneUser.user !== 'number') {
    if (!usersToNormalize[campagneUser.user.idUser]) {
      usersToNormalize[campagneUser.user.idUser] = {
        ...campagneUser.user,
        campagneUsers: campagneUser.user.campagneUsers?.concat() ?? []
      };
    }
    (usersToNormalize[campagneUser.user.idUser].campagneUsers as number[]).push(
      campagneUser.idCampagneUser
    );
    relations['user'] = campagneUser.user.idUser;
  }
}

function campagneUserNormalizeCampagne(
  campagneUser: CampagneUserEntityState,
  campagnesToNormalize: { [id: number]: CampagneEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campagneUser.campagne && typeof campagneUser.campagne !== 'number') {
    if (!campagnesToNormalize[campagneUser.campagne.idCampagne]) {
      campagnesToNormalize[campagneUser.campagne.idCampagne] = {
        ...campagneUser.campagne,
        campagneUsers: campagneUser.campagne.campagneUsers?.concat() ?? []
      };
    }
    (campagnesToNormalize[campagneUser.campagne.idCampagne].campagneUsers as number[]).push(
      campagneUser.idCampagneUser
    );
    relations['campagne'] = campagneUser.campagne.idCampagne;
  }
}

/**
 *  CaracteristiqueTemplate
 */
export function getActionsToNormalizeCaracteristiqueTemplate(
  caracteristiqueTemplates: CaracteristiqueTemplateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const composantTemplatesToNormalize: { [id: number]: ComposantTemplateEntityState } = {};
  const societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[] = [];
  const caracteristiqueChoixTemplatesToNormalize: CaracteristiqueChoixTemplateEntityState[] = [];

  const normalizedCaracteristiqueTemplates = caracteristiqueTemplates.map((caracteristiqueTemplate: CaracteristiqueTemplateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    caracteristiqueTemplateNormalizeSocieteCaracteristique(caracteristiqueTemplate, societeCaracteristiquesToNormalize, relations);
    caracteristiqueTemplateNormalizeCaracteristiqueChoixTemplate(caracteristiqueTemplate, caracteristiqueChoixTemplatesToNormalize, relations);
    caracteristiqueTemplateNormalizeComposantTemplate(caracteristiqueTemplate, composantTemplatesToNormalize, relations);

    return {
      ...caracteristiqueTemplate,
      ...relations
    };
  });
  const composantTemplatesToNormalizeArray = Object.values(composantTemplatesToNormalize);
  if (composantTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposantTemplate(composantTemplatesToNormalizeArray, actionType));
  }
  if (societeCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalize, actionType));
  }
  if (caracteristiqueChoixTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueChoixTemplate(caracteristiqueChoixTemplatesToNormalize, actionType));
  }

  const caracteristiqueTemplateActionCreator = CaracteristiqueTemplateGeneratedActions.upsertManyCaracteristiqueTemplatesSuccess;
  actions.push(caracteristiqueTemplateActionCreator({ caracteristiqueTemplates: normalizedCaracteristiqueTemplates }));

  return actions;
}

function caracteristiqueTemplateNormalizeSocieteCaracteristique(
  caracteristiqueTemplate: CaracteristiqueTemplateEntityState,
  societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (caracteristiqueTemplate.societeCaracteristiques?.length && typeof caracteristiqueTemplate.societeCaracteristiques[0] !== 'number') {
    const idsSocieteCaracteristiques: number[] = [];
    for (let i = 0; i < caracteristiqueTemplate.societeCaracteristiques.length; i++) {
      const societeCaracteristique: SocieteCaracteristique | number = caracteristiqueTemplate.societeCaracteristiques[i];
      if (typeof societeCaracteristique !== 'number') {
        societeCaracteristiquesToNormalize.push({ ...societeCaracteristique, caracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate });
        idsSocieteCaracteristiques.push(societeCaracteristique.idSocieteCaracteristique);
      } else {
        idsSocieteCaracteristiques.push(societeCaracteristique);
      }
    }
    relations['societeCaracteristiques'] = idsSocieteCaracteristiques;
  }
}

function caracteristiqueTemplateNormalizeCaracteristiqueChoixTemplate(
  caracteristiqueTemplate: CaracteristiqueTemplateEntityState,
  caracteristiqueChoixTemplatesToNormalize: CaracteristiqueChoixTemplateEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (caracteristiqueTemplate.caracteristiqueChoixTemplates?.length && typeof caracteristiqueTemplate.caracteristiqueChoixTemplates[0] !== 'number') {
    const idsCaracteristiqueChoixTemplates: number[] = [];
    for (let i = 0; i < caracteristiqueTemplate.caracteristiqueChoixTemplates.length; i++) {
      const caracteristiqueChoixTemplate: CaracteristiqueChoixTemplate | number = caracteristiqueTemplate.caracteristiqueChoixTemplates[i];
      if (typeof caracteristiqueChoixTemplate !== 'number') {
        caracteristiqueChoixTemplatesToNormalize.push({ ...caracteristiqueChoixTemplate, caracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate });
        idsCaracteristiqueChoixTemplates.push(caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate);
      } else {
        idsCaracteristiqueChoixTemplates.push(caracteristiqueChoixTemplate);
      }
    }
    relations['caracteristiqueChoixTemplates'] = idsCaracteristiqueChoixTemplates;
  }
}

function caracteristiqueTemplateNormalizeComposantTemplate(
  caracteristiqueTemplate: CaracteristiqueTemplateEntityState,
  composantTemplatesToNormalize: { [id: number]: ComposantTemplateEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (caracteristiqueTemplate.composantTemplate && typeof caracteristiqueTemplate.composantTemplate !== 'number') {
    if (!composantTemplatesToNormalize[caracteristiqueTemplate.composantTemplate.idComposantTemplate]) {
      composantTemplatesToNormalize[caracteristiqueTemplate.composantTemplate.idComposantTemplate] = {
        ...caracteristiqueTemplate.composantTemplate,
        caracteristiqueTemplates: caracteristiqueTemplate.composantTemplate.caracteristiqueTemplates?.concat() ?? []
      };
    }
    (composantTemplatesToNormalize[caracteristiqueTemplate.composantTemplate.idComposantTemplate].caracteristiqueTemplates as number[]).push(
      caracteristiqueTemplate.idCaracteristiqueTemplate
    );
    relations['composantTemplate'] = caracteristiqueTemplate.composantTemplate.idComposantTemplate;
  }
}

/**
 *  CaracteristiqueChoixTemplate
 */
export function getActionsToNormalizeCaracteristiqueChoixTemplate(
  caracteristiqueChoixTemplates: CaracteristiqueChoixTemplateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const caracteristiqueTemplatesToNormalize: { [id: number]: CaracteristiqueTemplateEntityState } = {};
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const societeCaracteristiqueChoicesToNormalize: SocieteCaracteristiqueChoixEntityState[] = [];

  const normalizedCaracteristiqueChoixTemplates = caracteristiqueChoixTemplates.map((caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    caracteristiqueChoixTemplateNormalizeSocieteCaracteristiqueChoix(caracteristiqueChoixTemplate, societeCaracteristiqueChoicesToNormalize, relations);
    caracteristiqueChoixTemplateNormalizeCaracteristiqueTemplate(caracteristiqueChoixTemplate, caracteristiqueTemplatesToNormalize, relations);
    caracteristiqueChoixTemplateNormalizeFichier(caracteristiqueChoixTemplate, fichiersToNormalize, relations);

    return {
      ...caracteristiqueChoixTemplate,
      ...relations
    };
  });
  const caracteristiqueTemplatesToNormalizeArray = Object.values(caracteristiqueTemplatesToNormalize);
  if (caracteristiqueTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueTemplate(caracteristiqueTemplatesToNormalizeArray, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  if (societeCaracteristiqueChoicesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristiqueChoix(societeCaracteristiqueChoicesToNormalize, actionType));
  }

  const caracteristiqueChoixTemplateActionCreator = CaracteristiqueChoixTemplateGeneratedActions.upsertManyCaracteristiqueChoixTemplatesSuccess;
  actions.push(caracteristiqueChoixTemplateActionCreator({ caracteristiqueChoixTemplates: normalizedCaracteristiqueChoixTemplates }));

  return actions;
}

function caracteristiqueChoixTemplateNormalizeSocieteCaracteristiqueChoix(
  caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateEntityState,
  societeCaracteristiqueChoicesToNormalize: SocieteCaracteristiqueChoixEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (caracteristiqueChoixTemplate.societeCaracteristiqueChoices?.length && typeof caracteristiqueChoixTemplate.societeCaracteristiqueChoices[0] !== 'number') {
    const idsSocieteCaracteristiqueChoices: number[] = [];
    for (let i = 0; i < caracteristiqueChoixTemplate.societeCaracteristiqueChoices.length; i++) {
      const societeCaracteristiqueChoix: SocieteCaracteristiqueChoix | number = caracteristiqueChoixTemplate.societeCaracteristiqueChoices[i];
      if (typeof societeCaracteristiqueChoix !== 'number') {
        societeCaracteristiqueChoicesToNormalize.push({ ...societeCaracteristiqueChoix, caracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate });
        idsSocieteCaracteristiqueChoices.push(societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix);
      } else {
        idsSocieteCaracteristiqueChoices.push(societeCaracteristiqueChoix);
      }
    }
    relations['societeCaracteristiqueChoices'] = idsSocieteCaracteristiqueChoices;
  }
}

function caracteristiqueChoixTemplateNormalizeCaracteristiqueTemplate(
  caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateEntityState,
  caracteristiqueTemplatesToNormalize: { [id: number]: CaracteristiqueTemplateEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (caracteristiqueChoixTemplate.caracteristiqueTemplate && typeof caracteristiqueChoixTemplate.caracteristiqueTemplate !== 'number') {
    if (!caracteristiqueTemplatesToNormalize[caracteristiqueChoixTemplate.caracteristiqueTemplate.idCaracteristiqueTemplate]) {
      caracteristiqueTemplatesToNormalize[caracteristiqueChoixTemplate.caracteristiqueTemplate.idCaracteristiqueTemplate] = {
        ...caracteristiqueChoixTemplate.caracteristiqueTemplate,
        caracteristiqueChoixTemplates: caracteristiqueChoixTemplate.caracteristiqueTemplate.caracteristiqueChoixTemplates?.concat() ?? []
      };
    }
    (caracteristiqueTemplatesToNormalize[caracteristiqueChoixTemplate.caracteristiqueTemplate.idCaracteristiqueTemplate].caracteristiqueChoixTemplates as number[]).push(
      caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate
    );
    relations['caracteristiqueTemplate'] = caracteristiqueChoixTemplate.caracteristiqueTemplate.idCaracteristiqueTemplate;
  }
}

function caracteristiqueChoixTemplateNormalizeFichier(
  caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateEntityState,
  fichiersToNormalize: { [id: number]: FichierEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (caracteristiqueChoixTemplate.fichier && typeof caracteristiqueChoixTemplate.fichier !== 'number') {
    if (!fichiersToNormalize[caracteristiqueChoixTemplate.fichier.idFichier]) {
      fichiersToNormalize[caracteristiqueChoixTemplate.fichier.idFichier] = {
        ...caracteristiqueChoixTemplate.fichier,
        caracteristiqueChoixTemplates: caracteristiqueChoixTemplate.fichier.caracteristiqueChoixTemplates?.concat() ?? []
      };
    }
    (fichiersToNormalize[caracteristiqueChoixTemplate.fichier.idFichier].caracteristiqueChoixTemplates as number[]).push(
      caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate
    );
    relations['fichier'] = caracteristiqueChoixTemplate.fichier.idFichier;
  }
}

/**
 *  Composant
 */
export function getActionsToNormalizeComposant(
  composants: ComposantEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const espacesToNormalize: { [id: number]: EspaceEntityState } = {};
  const societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const valeurComposantsToNormalize: ValeurComposantEntityState[] = [];
  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};

  const normalizedComposants = composants.map((composant: ComposantEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    composantNormalizeValeurComposant(composant, valeurComposantsToNormalize, relations);
    composantNormalizeValeur(composant, valeursToNormalize, relations);
    composantNormalizeEspace(composant, espacesToNormalize, relations);
    composantNormalizeSocieteComposant(composant, societeComposantsToNormalize, relations);
    composantNormalizeUser(composant, usersToNormalize, relations);

    return {
      ...composant,
      ...relations
    };
  });
  const espacesToNormalizeArray = Object.values(espacesToNormalize);
  if (espacesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeEspace(espacesToNormalizeArray, actionType));
  }
  const societeComposantsToNormalizeArray = Object.values(societeComposantsToNormalize);
  if (societeComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (valeurComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurComposant(valeurComposantsToNormalize, actionType));
  }
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }

  const composantActionCreator = ComposantGeneratedActions.upsertManyComposantsSuccess;
  actions.push(composantActionCreator({ composants: normalizedComposants }));

  return actions;
}

function composantNormalizeValeurComposant(
  composant: ComposantEntityState,
  valeurComposantsToNormalize: ValeurComposantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (composant.valeurComposants?.length && typeof composant.valeurComposants[0] !== 'number') {
    const idsValeurComposants: number[] = [];
    for (let i = 0; i < composant.valeurComposants.length; i++) {
      const valeurComposant: ValeurComposant | number = composant.valeurComposants[i];
      if (typeof valeurComposant !== 'number') {
        valeurComposantsToNormalize.push({ ...valeurComposant, composant: composant.idComposant });
        idsValeurComposants.push(valeurComposant.idValeurComposant);
      } else {
        idsValeurComposants.push(valeurComposant);
      }
    }
    relations['valeurComposants'] = idsValeurComposants;
  }
}

function composantNormalizeValeur(
  composant: ComposantEntityState,
  valeursToNormalize: { [id: number]: ValeurEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (composant.valeurs?.length && typeof composant.valeurs[0] !== 'number') {
    const idsValeurs: number[] = [];
    for (let i = 0; i < composant.valeurs.length; i++) {
      const valeur: Valeur | number = composant.valeurs[i];

      if (typeof valeur !== 'number') {
        if (!valeursToNormalize[valeur.idValeur]) {
          valeursToNormalize[valeur.idValeur] = {
            ...valeur,
            composants: valeur.composants?.concat() ?? []
          };
        }
        (valeursToNormalize[valeur.idValeur].composants as number[]).push(composant.idComposant);
        idsValeurs.push(valeur.idValeur);
      } else {
        idsValeurs.push(valeur);
      }
    }
    relations['valeurs'] = idsValeurs;
  }
}

function composantNormalizeEspace(
  composant: ComposantEntityState,
  espacesToNormalize: { [id: number]: EspaceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (composant.espace && typeof composant.espace !== 'number') {
    if (!espacesToNormalize[composant.espace.idEspace]) {
      espacesToNormalize[composant.espace.idEspace] = {
        ...composant.espace,
        composants: composant.espace.composants?.concat() ?? []
      };
    }
    (espacesToNormalize[composant.espace.idEspace].composants as number[]).push(
      composant.idComposant
    );
    relations['espace'] = composant.espace.idEspace;
  }
}

function composantNormalizeSocieteComposant(
  composant: ComposantEntityState,
  societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (composant.societeComposant && typeof composant.societeComposant !== 'number') {
    if (!societeComposantsToNormalize[composant.societeComposant.idSocieteComposant]) {
      societeComposantsToNormalize[composant.societeComposant.idSocieteComposant] = {
        ...composant.societeComposant,
        composants: composant.societeComposant.composants?.concat() ?? []
      };
    }
    (societeComposantsToNormalize[composant.societeComposant.idSocieteComposant].composants as number[]).push(
      composant.idComposant
    );
    relations['societeComposant'] = composant.societeComposant.idSocieteComposant;
  }
}

function composantNormalizeUser(
  composant: ComposantEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (composant.user && typeof composant.user !== 'number') {
    if (!usersToNormalize[composant.user.idUser]) {
      usersToNormalize[composant.user.idUser] = {
        ...composant.user,
        composants: composant.user.composants?.concat() ?? []
      };
    }
    (usersToNormalize[composant.user.idUser].composants as number[]).push(
      composant.idComposant
    );
    relations['user'] = composant.user.idUser;
  }
}

/**
 *  ComposantAttendu
 */
export function getActionsToNormalizeComposantAttendu(
  composantAttendus: ComposantAttenduEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedComposantAttendus = composantAttendus.map((composantAttendu: ComposantAttenduEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    composantAttenduNormalizeSocieteComposant(composantAttendu, societeComposantsToNormalize, relations);
    composantAttenduNormalizePatrimoine(composantAttendu, patrimoinesToNormalize, relations);
    composantAttenduNormalizeUser(composantAttendu, usersToNormalize, relations);

    return {
      ...composantAttendu,
      ...relations
    };
  });
  const societeComposantsToNormalizeArray = Object.values(societeComposantsToNormalize);
  if (societeComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const composantAttenduActionCreator = ComposantAttenduGeneratedActions.upsertManyComposantAttendusSuccess;
  actions.push(composantAttenduActionCreator({ composantAttendus: normalizedComposantAttendus }));

  return actions;
}

function composantAttenduNormalizeSocieteComposant(
  composantAttendu: ComposantAttenduEntityState,
  societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (composantAttendu.societeComposant && typeof composantAttendu.societeComposant !== 'number') {
    if (!societeComposantsToNormalize[composantAttendu.societeComposant.idSocieteComposant]) {
      societeComposantsToNormalize[composantAttendu.societeComposant.idSocieteComposant] = {
        ...composantAttendu.societeComposant,
        composantAttendus: composantAttendu.societeComposant.composantAttendus?.concat() ?? []
      };
    }
    (societeComposantsToNormalize[composantAttendu.societeComposant.idSocieteComposant].composantAttendus as number[]).push(
      composantAttendu.idComposantAttendu
    );
    relations['societeComposant'] = composantAttendu.societeComposant.idSocieteComposant;
  }
}

function composantAttenduNormalizePatrimoine(
  composantAttendu: ComposantAttenduEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (composantAttendu.patrimoine && typeof composantAttendu.patrimoine !== 'number') {
    if (!patrimoinesToNormalize[composantAttendu.patrimoine.idPatrimoine]) {
      patrimoinesToNormalize[composantAttendu.patrimoine.idPatrimoine] = {
        ...composantAttendu.patrimoine,
        composantAttendus: composantAttendu.patrimoine.composantAttendus?.concat() ?? []
      };
    }
    (patrimoinesToNormalize[composantAttendu.patrimoine.idPatrimoine].composantAttendus as number[]).push(
      composantAttendu.idComposantAttendu
    );
    relations['patrimoine'] = composantAttendu.patrimoine.idPatrimoine;
  }
}

function composantAttenduNormalizeUser(
  composantAttendu: ComposantAttenduEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (composantAttendu.user && typeof composantAttendu.user !== 'number') {
    if (!usersToNormalize[composantAttendu.user.idUser]) {
      usersToNormalize[composantAttendu.user.idUser] = {
        ...composantAttendu.user,
        composantAttendus: composantAttendu.user.composantAttendus?.concat() ?? []
      };
    }
    (usersToNormalize[composantAttendu.user.idUser].composantAttendus as number[]).push(
      composantAttendu.idComposantAttendu
    );
    relations['user'] = composantAttendu.user.idUser;
  }
}

/**
 *  ComposantGroupe
 */
export function getActionsToNormalizeComposantGroupe(
  composantGroupes: ComposantGroupeEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const societeComposantsToNormalize: SocieteComposantEntityState[] = [];
  const composantTemplatesToNormalize: ComposantTemplateEntityState[] = [];

  const normalizedComposantGroupes = composantGroupes.map((composantGroupe: ComposantGroupeEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    composantGroupeNormalizeSocieteComposant(composantGroupe, societeComposantsToNormalize, relations);
    composantGroupeNormalizeComposantTemplate(composantGroupe, composantTemplatesToNormalize, relations);
    composantGroupeNormalizeFichier(composantGroupe, fichiersToNormalize, relations);

    return {
      ...composantGroupe,
      ...relations
    };
  });
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  if (societeComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalize, actionType));
  }
  if (composantTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantTemplate(composantTemplatesToNormalize, actionType));
  }

  const composantGroupeActionCreator = ComposantGroupeGeneratedActions.upsertManyComposantGroupesSuccess;
  actions.push(composantGroupeActionCreator({ composantGroupes: normalizedComposantGroupes }));

  return actions;
}

function composantGroupeNormalizeSocieteComposant(
  composantGroupe: ComposantGroupeEntityState,
  societeComposantsToNormalize: SocieteComposantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (composantGroupe.societeComposants?.length && typeof composantGroupe.societeComposants[0] !== 'number') {
    const idsSocieteComposants: number[] = [];
    for (let i = 0; i < composantGroupe.societeComposants.length; i++) {
      const societeComposant: SocieteComposant | number = composantGroupe.societeComposants[i];
      if (typeof societeComposant !== 'number') {
        societeComposantsToNormalize.push({ ...societeComposant, composantGroupe: composantGroupe.idComposantGroupe });
        idsSocieteComposants.push(societeComposant.idSocieteComposant);
      } else {
        idsSocieteComposants.push(societeComposant);
      }
    }
    relations['societeComposants'] = idsSocieteComposants;
  }
}

function composantGroupeNormalizeComposantTemplate(
  composantGroupe: ComposantGroupeEntityState,
  composantTemplatesToNormalize: ComposantTemplateEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (composantGroupe.composantTemplates?.length && typeof composantGroupe.composantTemplates[0] !== 'number') {
    const idsComposantTemplates: number[] = [];
    for (let i = 0; i < composantGroupe.composantTemplates.length; i++) {
      const composantTemplate: ComposantTemplate | number = composantGroupe.composantTemplates[i];
      if (typeof composantTemplate !== 'number') {
        composantTemplatesToNormalize.push({ ...composantTemplate, composantGroupe: composantGroupe.idComposantGroupe });
        idsComposantTemplates.push(composantTemplate.idComposantTemplate);
      } else {
        idsComposantTemplates.push(composantTemplate);
      }
    }
    relations['composantTemplates'] = idsComposantTemplates;
  }
}

function composantGroupeNormalizeFichier(
  composantGroupe: ComposantGroupeEntityState,
  fichiersToNormalize: { [id: number]: FichierEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (composantGroupe.fichier && typeof composantGroupe.fichier !== 'number') {
    if (!fichiersToNormalize[composantGroupe.fichier.idFichier]) {
      fichiersToNormalize[composantGroupe.fichier.idFichier] = {
        ...composantGroupe.fichier,
        composantGroupes: composantGroupe.fichier.composantGroupes?.concat() ?? []
      };
    }
    (fichiersToNormalize[composantGroupe.fichier.idFichier].composantGroupes as number[]).push(
      composantGroupe.idComposantGroupe
    );
    relations['fichier'] = composantGroupe.fichier.idFichier;
  }
}

/**
 *  ComposantTemplate
 */
export function getActionsToNormalizeComposantTemplate(
  composantTemplates: ComposantTemplateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const composantGroupesToNormalize: { [id: number]: ComposantGroupeEntityState } = {};
  const caracteristiqueTemplatesToNormalize: CaracteristiqueTemplateEntityState[] = [];
  const societeComposantsToNormalize: SocieteComposantEntityState[] = [];

  const normalizedComposantTemplates = composantTemplates.map((composantTemplate: ComposantTemplateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    composantTemplateNormalizeCaracteristiqueTemplate(composantTemplate, caracteristiqueTemplatesToNormalize, relations);
    composantTemplateNormalizeSocieteComposant(composantTemplate, societeComposantsToNormalize, relations);
    composantTemplateNormalizeFichier(composantTemplate, fichiersToNormalize, relations);
    composantTemplateNormalizeComposantGroupe(composantTemplate, composantGroupesToNormalize, relations);

    return {
      ...composantTemplate,
      ...relations
    };
  });
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  const composantGroupesToNormalizeArray = Object.values(composantGroupesToNormalize);
  if (composantGroupesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposantGroupe(composantGroupesToNormalizeArray, actionType));
  }
  if (caracteristiqueTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueTemplate(caracteristiqueTemplatesToNormalize, actionType));
  }
  if (societeComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalize, actionType));
  }

  const composantTemplateActionCreator = ComposantTemplateGeneratedActions.upsertManyComposantTemplatesSuccess;
  actions.push(composantTemplateActionCreator({ composantTemplates: normalizedComposantTemplates }));

  return actions;
}

function composantTemplateNormalizeCaracteristiqueTemplate(
  composantTemplate: ComposantTemplateEntityState,
  caracteristiqueTemplatesToNormalize: CaracteristiqueTemplateEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (composantTemplate.caracteristiqueTemplates?.length && typeof composantTemplate.caracteristiqueTemplates[0] !== 'number') {
    const idsCaracteristiqueTemplates: number[] = [];
    for (let i = 0; i < composantTemplate.caracteristiqueTemplates.length; i++) {
      const caracteristiqueTemplate: CaracteristiqueTemplate | number = composantTemplate.caracteristiqueTemplates[i];
      if (typeof caracteristiqueTemplate !== 'number') {
        caracteristiqueTemplatesToNormalize.push({ ...caracteristiqueTemplate, composantTemplate: composantTemplate.idComposantTemplate });
        idsCaracteristiqueTemplates.push(caracteristiqueTemplate.idCaracteristiqueTemplate);
      } else {
        idsCaracteristiqueTemplates.push(caracteristiqueTemplate);
      }
    }
    relations['caracteristiqueTemplates'] = idsCaracteristiqueTemplates;
  }
}

function composantTemplateNormalizeSocieteComposant(
  composantTemplate: ComposantTemplateEntityState,
  societeComposantsToNormalize: SocieteComposantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (composantTemplate.societeComposants?.length && typeof composantTemplate.societeComposants[0] !== 'number') {
    const idsSocieteComposants: number[] = [];
    for (let i = 0; i < composantTemplate.societeComposants.length; i++) {
      const societeComposant: SocieteComposant | number = composantTemplate.societeComposants[i];
      if (typeof societeComposant !== 'number') {
        societeComposantsToNormalize.push({ ...societeComposant, composantTemplate: composantTemplate.idComposantTemplate });
        idsSocieteComposants.push(societeComposant.idSocieteComposant);
      } else {
        idsSocieteComposants.push(societeComposant);
      }
    }
    relations['societeComposants'] = idsSocieteComposants;
  }
}

function composantTemplateNormalizeFichier(
  composantTemplate: ComposantTemplateEntityState,
  fichiersToNormalize: { [id: number]: FichierEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (composantTemplate.fichier && typeof composantTemplate.fichier !== 'number') {
    if (!fichiersToNormalize[composantTemplate.fichier.idFichier]) {
      fichiersToNormalize[composantTemplate.fichier.idFichier] = {
        ...composantTemplate.fichier,
        composantTemplates: composantTemplate.fichier.composantTemplates?.concat() ?? []
      };
    }
    (fichiersToNormalize[composantTemplate.fichier.idFichier].composantTemplates as number[]).push(
      composantTemplate.idComposantTemplate
    );
    relations['fichier'] = composantTemplate.fichier.idFichier;
  }
}

function composantTemplateNormalizeComposantGroupe(
  composantTemplate: ComposantTemplateEntityState,
  composantGroupesToNormalize: { [id: number]: ComposantGroupeEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (composantTemplate.composantGroupe && typeof composantTemplate.composantGroupe !== 'number') {
    if (!composantGroupesToNormalize[composantTemplate.composantGroupe.idComposantGroupe]) {
      composantGroupesToNormalize[composantTemplate.composantGroupe.idComposantGroupe] = {
        ...composantTemplate.composantGroupe,
        composantTemplates: composantTemplate.composantGroupe.composantTemplates?.concat() ?? []
      };
    }
    (composantGroupesToNormalize[composantTemplate.composantGroupe.idComposantGroupe].composantTemplates as number[]).push(
      composantTemplate.idComposantTemplate
    );
    relations['composantGroupe'] = composantTemplate.composantGroupe.idComposantGroupe;
  }
}

/**
 *  Droit
 */
export function getActionsToNormalizeDroit(
  droits: DroitEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societeProfilDroitsToNormalize: SocieteProfilDroitEntityState[] = [];
  const societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState } = {};

  const normalizedDroits = droits.map((droit: DroitEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    droitNormalizeSocieteProfilDroit(droit, societeProfilDroitsToNormalize, relations);
    droitNormalizeSocieteProfil(droit, societeProfilsToNormalize, relations);

    return {
      ...droit,
      ...relations
    };
  });
  if (societeProfilDroitsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteProfilDroit(societeProfilDroitsToNormalize, actionType));
  }
  const societeProfilsToNormalizeArray = Object.values(societeProfilsToNormalize);
  if (societeProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteProfil(societeProfilsToNormalizeArray, actionType));
  }

  const droitActionCreator = DroitGeneratedActions.upsertManyDroitsSuccess;
  actions.push(droitActionCreator({ droits: normalizedDroits }));

  return actions;
}

function droitNormalizeSocieteProfilDroit(
  droit: DroitEntityState,
  societeProfilDroitsToNormalize: SocieteProfilDroitEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (droit.societeProfilDroits?.length && typeof droit.societeProfilDroits[0] !== 'number') {
    const idsSocieteProfilDroits: number[] = [];
    for (let i = 0; i < droit.societeProfilDroits.length; i++) {
      const societeProfilDroit: SocieteProfilDroit | number = droit.societeProfilDroits[i];
      if (typeof societeProfilDroit !== 'number') {
        societeProfilDroitsToNormalize.push({ ...societeProfilDroit, droit: droit.idDroit });
        idsSocieteProfilDroits.push(societeProfilDroit.idSocieteProfilDroit);
      } else {
        idsSocieteProfilDroits.push(societeProfilDroit);
      }
    }
    relations['societeProfilDroits'] = idsSocieteProfilDroits;
  }
}

function droitNormalizeSocieteProfil(
  droit: DroitEntityState,
  societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (droit.societeProfils?.length && typeof droit.societeProfils[0] !== 'number') {
    const idsSocieteProfils: number[] = [];
    for (let i = 0; i < droit.societeProfils.length; i++) {
      const societeProfil: SocieteProfil | number = droit.societeProfils[i];

      if (typeof societeProfil !== 'number') {
        if (!societeProfilsToNormalize[societeProfil.idSocieteProfil]) {
          societeProfilsToNormalize[societeProfil.idSocieteProfil] = {
            ...societeProfil,
            droits: societeProfil.droits?.concat() ?? []
          };
        }
        (societeProfilsToNormalize[societeProfil.idSocieteProfil].droits as number[]).push(droit.idDroit);
        idsSocieteProfils.push(societeProfil.idSocieteProfil);
      } else {
        idsSocieteProfils.push(societeProfil);
      }
    }
    relations['societeProfils'] = idsSocieteProfils;
  }
}

/**
 *  Espace
 */
export function getActionsToNormalizeEspace(
  espaces: EspaceEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const societeEspacesToNormalize: { [id: number]: SocieteEspaceEntityState } = {};
  const composantsToNormalize: ComposantEntityState[] = [];

  const normalizedEspaces = espaces.map((espace: EspaceEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    espaceNormalizeComposant(espace, composantsToNormalize, relations);
    espaceNormalizePatrimoine(espace, patrimoinesToNormalize, relations);
    espaceNormalizeSocieteEspace(espace, societeEspacesToNormalize, relations);

    return {
      ...espace,
      ...relations
    };
  });
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  const societeEspacesToNormalizeArray = Object.values(societeEspacesToNormalize);
  if (societeEspacesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteEspace(societeEspacesToNormalizeArray, actionType));
  }
  if (composantsToNormalize.length) {
    actions.push(...getActionsToNormalizeComposant(composantsToNormalize, actionType));
  }

  const espaceActionCreator = EspaceGeneratedActions.upsertManyEspacesSuccess;
  actions.push(espaceActionCreator({ espaces: normalizedEspaces }));

  return actions;
}

function espaceNormalizeComposant(
  espace: EspaceEntityState,
  composantsToNormalize: ComposantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (espace.composants?.length && typeof espace.composants[0] !== 'number') {
    const idsComposants: number[] = [];
    for (let i = 0; i < espace.composants.length; i++) {
      const composant: Composant | number = espace.composants[i];
      if (typeof composant !== 'number') {
        composantsToNormalize.push({ ...composant, espace: espace.idEspace });
        idsComposants.push(composant.idComposant);
      } else {
        idsComposants.push(composant);
      }
    }
    relations['composants'] = idsComposants;
  }
}

function espaceNormalizePatrimoine(
  espace: EspaceEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (espace.patrimoine && typeof espace.patrimoine !== 'number') {
    if (!patrimoinesToNormalize[espace.patrimoine.idPatrimoine]) {
      patrimoinesToNormalize[espace.patrimoine.idPatrimoine] = {
        ...espace.patrimoine,
        espaces: espace.patrimoine.espaces?.concat() ?? []
      };
    }
    (patrimoinesToNormalize[espace.patrimoine.idPatrimoine].espaces as number[]).push(
      espace.idEspace
    );
    relations['patrimoine'] = espace.patrimoine.idPatrimoine;
  }
}

function espaceNormalizeSocieteEspace(
  espace: EspaceEntityState,
  societeEspacesToNormalize: { [id: number]: SocieteEspaceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (espace.societeEspace && typeof espace.societeEspace !== 'number') {
    if (!societeEspacesToNormalize[espace.societeEspace.idSocieteEspace]) {
      societeEspacesToNormalize[espace.societeEspace.idSocieteEspace] = {
        ...espace.societeEspace,
        espaces: espace.societeEspace.espaces?.concat() ?? []
      };
    }
    (societeEspacesToNormalize[espace.societeEspace.idSocieteEspace].espaces as number[]).push(
      espace.idEspace
    );
    relations['societeEspace'] = espace.societeEspace.idSocieteEspace;
  }
}

/**
 *  Fichier
 */
export function getActionsToNormalizeFichier(
  fichiers: FichierEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};
  const societeComposantsToNormalize: SocieteComposantEntityState[] = [];
  const societeComposantFamillesToNormalize: SocieteComposantFamilleEntityState[] = [];
  const composantGroupesToNormalize: ComposantGroupeEntityState[] = [];
  const composantTemplatesToNormalize: ComposantTemplateEntityState[] = [];
  const societeCaracteristiqueChoicesToNormalize: SocieteCaracteristiqueChoixEntityState[] = [];
  const caracteristiqueChoixTemplatesToNormalize: CaracteristiqueChoixTemplateEntityState[] = [];
  const valeurFichiersToNormalize: ValeurFichierEntityState[] = [];
  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};

  const normalizedFichiers = fichiers.map((fichier: FichierEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    fichierNormalizeSocieteComposant(fichier, societeComposantsToNormalize, relations);
    fichierNormalizeSocieteComposantFamille(fichier, societeComposantFamillesToNormalize, relations);
    fichierNormalizeComposantGroupe(fichier, composantGroupesToNormalize, relations);
    fichierNormalizeComposantTemplate(fichier, composantTemplatesToNormalize, relations);
    fichierNormalizeSocieteCaracteristiqueChoix(fichier, societeCaracteristiqueChoicesToNormalize, relations);
    fichierNormalizeCaracteristiqueChoixTemplate(fichier, caracteristiqueChoixTemplatesToNormalize, relations);
    fichierNormalizeValeurFichier(fichier, valeurFichiersToNormalize, relations);
    fichierNormalizeValeur(fichier, valeursToNormalize, relations);
    fichierNormalizeOrganisation(fichier, organisationsToNormalize, relations);

    return {
      ...fichier,
      ...relations
    };
  });
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }
  if (societeComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalize, actionType));
  }
  if (societeComposantFamillesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposantFamille(societeComposantFamillesToNormalize, actionType));
  }
  if (composantGroupesToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantGroupe(composantGroupesToNormalize, actionType));
  }
  if (composantTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantTemplate(composantTemplatesToNormalize, actionType));
  }
  if (societeCaracteristiqueChoicesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristiqueChoix(societeCaracteristiqueChoicesToNormalize, actionType));
  }
  if (caracteristiqueChoixTemplatesToNormalize.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueChoixTemplate(caracteristiqueChoixTemplatesToNormalize, actionType));
  }
  if (valeurFichiersToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurFichier(valeurFichiersToNormalize, actionType));
  }
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }

  const fichierActionCreator = FichierGeneratedActions.upsertManyFichiersSuccess;
  actions.push(fichierActionCreator({ fichiers: normalizedFichiers }));

  return actions;
}

function fichierNormalizeSocieteComposant(
  fichier: FichierEntityState,
  societeComposantsToNormalize: SocieteComposantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (fichier.societeComposants?.length && typeof fichier.societeComposants[0] !== 'number') {
    const idsSocieteComposants: number[] = [];
    for (let i = 0; i < fichier.societeComposants.length; i++) {
      const societeComposant: SocieteComposant | number = fichier.societeComposants[i];
      if (typeof societeComposant !== 'number') {
        societeComposantsToNormalize.push({ ...societeComposant, fichier: fichier.idFichier });
        idsSocieteComposants.push(societeComposant.idSocieteComposant);
      } else {
        idsSocieteComposants.push(societeComposant);
      }
    }
    relations['societeComposants'] = idsSocieteComposants;
  }
}

function fichierNormalizeSocieteComposantFamille(
  fichier: FichierEntityState,
  societeComposantFamillesToNormalize: SocieteComposantFamilleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (fichier.societeComposantFamilles?.length && typeof fichier.societeComposantFamilles[0] !== 'number') {
    const idsSocieteComposantFamilles: number[] = [];
    for (let i = 0; i < fichier.societeComposantFamilles.length; i++) {
      const societeComposantFamille: SocieteComposantFamille | number = fichier.societeComposantFamilles[i];
      if (typeof societeComposantFamille !== 'number') {
        societeComposantFamillesToNormalize.push({ ...societeComposantFamille, fichier: fichier.idFichier });
        idsSocieteComposantFamilles.push(societeComposantFamille.idSocieteComposantFamille);
      } else {
        idsSocieteComposantFamilles.push(societeComposantFamille);
      }
    }
    relations['societeComposantFamilles'] = idsSocieteComposantFamilles;
  }
}

function fichierNormalizeComposantGroupe(
  fichier: FichierEntityState,
  composantGroupesToNormalize: ComposantGroupeEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (fichier.composantGroupes?.length && typeof fichier.composantGroupes[0] !== 'number') {
    const idsComposantGroupes: number[] = [];
    for (let i = 0; i < fichier.composantGroupes.length; i++) {
      const composantGroupe: ComposantGroupe | number = fichier.composantGroupes[i];
      if (typeof composantGroupe !== 'number') {
        composantGroupesToNormalize.push({ ...composantGroupe, fichier: fichier.idFichier });
        idsComposantGroupes.push(composantGroupe.idComposantGroupe);
      } else {
        idsComposantGroupes.push(composantGroupe);
      }
    }
    relations['composantGroupes'] = idsComposantGroupes;
  }
}

function fichierNormalizeComposantTemplate(
  fichier: FichierEntityState,
  composantTemplatesToNormalize: ComposantTemplateEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (fichier.composantTemplates?.length && typeof fichier.composantTemplates[0] !== 'number') {
    const idsComposantTemplates: number[] = [];
    for (let i = 0; i < fichier.composantTemplates.length; i++) {
      const composantTemplate: ComposantTemplate | number = fichier.composantTemplates[i];
      if (typeof composantTemplate !== 'number') {
        composantTemplatesToNormalize.push({ ...composantTemplate, fichier: fichier.idFichier });
        idsComposantTemplates.push(composantTemplate.idComposantTemplate);
      } else {
        idsComposantTemplates.push(composantTemplate);
      }
    }
    relations['composantTemplates'] = idsComposantTemplates;
  }
}

function fichierNormalizeSocieteCaracteristiqueChoix(
  fichier: FichierEntityState,
  societeCaracteristiqueChoicesToNormalize: SocieteCaracteristiqueChoixEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (fichier.societeCaracteristiqueChoices?.length && typeof fichier.societeCaracteristiqueChoices[0] !== 'number') {
    const idsSocieteCaracteristiqueChoices: number[] = [];
    for (let i = 0; i < fichier.societeCaracteristiqueChoices.length; i++) {
      const societeCaracteristiqueChoix: SocieteCaracteristiqueChoix | number = fichier.societeCaracteristiqueChoices[i];
      if (typeof societeCaracteristiqueChoix !== 'number') {
        societeCaracteristiqueChoicesToNormalize.push({ ...societeCaracteristiqueChoix, fichier: fichier.idFichier });
        idsSocieteCaracteristiqueChoices.push(societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix);
      } else {
        idsSocieteCaracteristiqueChoices.push(societeCaracteristiqueChoix);
      }
    }
    relations['societeCaracteristiqueChoices'] = idsSocieteCaracteristiqueChoices;
  }
}

function fichierNormalizeCaracteristiqueChoixTemplate(
  fichier: FichierEntityState,
  caracteristiqueChoixTemplatesToNormalize: CaracteristiqueChoixTemplateEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (fichier.caracteristiqueChoixTemplates?.length && typeof fichier.caracteristiqueChoixTemplates[0] !== 'number') {
    const idsCaracteristiqueChoixTemplates: number[] = [];
    for (let i = 0; i < fichier.caracteristiqueChoixTemplates.length; i++) {
      const caracteristiqueChoixTemplate: CaracteristiqueChoixTemplate | number = fichier.caracteristiqueChoixTemplates[i];
      if (typeof caracteristiqueChoixTemplate !== 'number') {
        caracteristiqueChoixTemplatesToNormalize.push({ ...caracteristiqueChoixTemplate, fichier: fichier.idFichier });
        idsCaracteristiqueChoixTemplates.push(caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate);
      } else {
        idsCaracteristiqueChoixTemplates.push(caracteristiqueChoixTemplate);
      }
    }
    relations['caracteristiqueChoixTemplates'] = idsCaracteristiqueChoixTemplates;
  }
}

function fichierNormalizeValeurFichier(
  fichier: FichierEntityState,
  valeurFichiersToNormalize: ValeurFichierEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (fichier.valeurFichiers?.length && typeof fichier.valeurFichiers[0] !== 'number') {
    const idsValeurFichiers: number[] = [];
    for (let i = 0; i < fichier.valeurFichiers.length; i++) {
      const valeurFichier: ValeurFichier | number = fichier.valeurFichiers[i];
      if (typeof valeurFichier !== 'number') {
        valeurFichiersToNormalize.push({ ...valeurFichier, fichier: fichier.idFichier });
        idsValeurFichiers.push(valeurFichier.idValeurFichier);
      } else {
        idsValeurFichiers.push(valeurFichier);
      }
    }
    relations['valeurFichiers'] = idsValeurFichiers;
  }
}

function fichierNormalizeValeur(
  fichier: FichierEntityState,
  valeursToNormalize: { [id: number]: ValeurEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (fichier.valeurs?.length && typeof fichier.valeurs[0] !== 'number') {
    const idsValeurs: number[] = [];
    for (let i = 0; i < fichier.valeurs.length; i++) {
      const valeur: Valeur | number = fichier.valeurs[i];

      if (typeof valeur !== 'number') {
        if (!valeursToNormalize[valeur.idValeur]) {
          valeursToNormalize[valeur.idValeur] = {
            ...valeur,
            fichiers: valeur.fichiers?.concat() ?? []
          };
        }
        (valeursToNormalize[valeur.idValeur].fichiers as number[]).push(fichier.idFichier);
        idsValeurs.push(valeur.idValeur);
      } else {
        idsValeurs.push(valeur);
      }
    }
    relations['valeurs'] = idsValeurs;
  }
}

function fichierNormalizeOrganisation(
  fichier: FichierEntityState,
  organisationsToNormalize: { [id: number]: OrganisationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (fichier.organisation && typeof fichier.organisation !== 'number') {
    if (!organisationsToNormalize[fichier.organisation.idOrganisation]) {
      organisationsToNormalize[fichier.organisation.idOrganisation] = {
        ...fichier.organisation,
        fichiers: fichier.organisation.fichiers?.concat() ?? []
      };
    }
    (organisationsToNormalize[fichier.organisation.idOrganisation].fichiers as number[]).push(
      fichier.idFichier
    );
    relations['organisation'] = fichier.organisation.idOrganisation;
  }
}

/**
 *  Newsletter
 */
export function getActionsToNormalizeNewsletter(
  newsletters: NewsletterEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const userNewslettersToNormalize: UserNewsletterEntityState[] = [];
  const organisationNewslettersToNormalize: OrganisationNewsletterEntityState[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};

  const normalizedNewsletters = newsletters.map((newsletter: NewsletterEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    newsletterNormalizeUserNewsletter(newsletter, userNewslettersToNormalize, relations);
    newsletterNormalizeOrganisationNewsletter(newsletter, organisationNewslettersToNormalize, relations);
    newsletterNormalizeUser(newsletter, usersToNormalize, relations);
    newsletterNormalizeOrganisation(newsletter, organisationsToNormalize, relations);

    return {
      ...newsletter,
      ...relations
    };
  });
  if (userNewslettersToNormalize.length) {
    actions.push(...getActionsToNormalizeUserNewsletter(userNewslettersToNormalize, actionType));
  }
  if (organisationNewslettersToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganisationNewsletter(organisationNewslettersToNormalize, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }

  const newsletterActionCreator = NewsletterGeneratedActions.upsertManyNewslettersSuccess;
  actions.push(newsletterActionCreator({ newsletters: normalizedNewsletters }));

  return actions;
}

function newsletterNormalizeUserNewsletter(
  newsletter: NewsletterEntityState,
  userNewslettersToNormalize: UserNewsletterEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (newsletter.userNewsletters?.length && typeof newsletter.userNewsletters[0] !== 'number') {
    const idsUserNewsletters: number[] = [];
    for (let i = 0; i < newsletter.userNewsletters.length; i++) {
      const userNewsletter: UserNewsletter | number = newsletter.userNewsletters[i];
      if (typeof userNewsletter !== 'number') {
        userNewslettersToNormalize.push({ ...userNewsletter, newsletter: newsletter.idNewsletter });
        idsUserNewsletters.push(userNewsletter.idUserNewsletter);
      } else {
        idsUserNewsletters.push(userNewsletter);
      }
    }
    relations['userNewsletters'] = idsUserNewsletters;
  }
}

function newsletterNormalizeOrganisationNewsletter(
  newsletter: NewsletterEntityState,
  organisationNewslettersToNormalize: OrganisationNewsletterEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (newsletter.organisationNewsletters?.length && typeof newsletter.organisationNewsletters[0] !== 'number') {
    const idsOrganisationNewsletters: number[] = [];
    for (let i = 0; i < newsletter.organisationNewsletters.length; i++) {
      const organisationNewsletter: OrganisationNewsletter | number = newsletter.organisationNewsletters[i];
      if (typeof organisationNewsletter !== 'number') {
        organisationNewslettersToNormalize.push({ ...organisationNewsletter, newsletter: newsletter.idNewsletter });
        idsOrganisationNewsletters.push(organisationNewsletter.idOrganisationNewsletter);
      } else {
        idsOrganisationNewsletters.push(organisationNewsletter);
      }
    }
    relations['organisationNewsletters'] = idsOrganisationNewsletters;
  }
}

function newsletterNormalizeUser(
  newsletter: NewsletterEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (newsletter.users?.length && typeof newsletter.users[0] !== 'number') {
    const idsUsers: number[] = [];
    for (let i = 0; i < newsletter.users.length; i++) {
      const user: User | number = newsletter.users[i];

      if (typeof user !== 'number') {
        if (!usersToNormalize[user.idUser]) {
          usersToNormalize[user.idUser] = {
            ...user,
            newsletters: user.newsletters?.concat() ?? []
          };
        }
        (usersToNormalize[user.idUser].newsletters as number[]).push(newsletter.idNewsletter);
        idsUsers.push(user.idUser);
      } else {
        idsUsers.push(user);
      }
    }
    relations['users'] = idsUsers;
  }
}

function newsletterNormalizeOrganisation(
  newsletter: NewsletterEntityState,
  organisationsToNormalize: { [id: number]: OrganisationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (newsletter.organisations?.length && typeof newsletter.organisations[0] !== 'number') {
    const idsOrganisations: number[] = [];
    for (let i = 0; i < newsletter.organisations.length; i++) {
      const organisation: Organisation | number = newsletter.organisations[i];

      if (typeof organisation !== 'number') {
        if (!organisationsToNormalize[organisation.idOrganisation]) {
          organisationsToNormalize[organisation.idOrganisation] = {
            ...organisation,
            newsletters: organisation.newsletters?.concat() ?? []
          };
        }
        (organisationsToNormalize[organisation.idOrganisation].newsletters as number[]).push(newsletter.idNewsletter);
        idsOrganisations.push(organisation.idOrganisation);
      } else {
        idsOrganisations.push(organisation);
      }
    }
    relations['organisations'] = idsOrganisations;
  }
}

/**
 *  OrganisationAzureLogin
 */
export function getActionsToNormalizeOrganisationAzureLogin(
  organisationAzureLogins: OrganisationAzureLoginEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};

  const normalizedOrganisationAzureLogins = organisationAzureLogins.map((organisationAzureLogin: OrganisationAzureLoginEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organisationAzureLoginNormalizeOrganisation(organisationAzureLogin, organisationsToNormalize, relations);

    return {
      ...organisationAzureLogin,
      ...relations
    };
  });
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }

  const organisationAzureLoginActionCreator = OrganisationAzureLoginGeneratedActions.upsertManyOrganisationAzureLoginsSuccess;
  actions.push(organisationAzureLoginActionCreator({ organisationAzureLogins: normalizedOrganisationAzureLogins }));

  return actions;
}

function organisationAzureLoginNormalizeOrganisation(
  organisationAzureLogin: OrganisationAzureLoginEntityState,
  organisationsToNormalize: { [id: number]: OrganisationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organisationAzureLogin.organisation && typeof organisationAzureLogin.organisation !== 'number') {
    if (!organisationsToNormalize[organisationAzureLogin.organisation.idOrganisation]) {
      organisationsToNormalize[organisationAzureLogin.organisation.idOrganisation] = {
        ...organisationAzureLogin.organisation,
        organisationAzureLogins: organisationAzureLogin.organisation.organisationAzureLogins?.concat() ?? []
      };
    }
    (organisationsToNormalize[organisationAzureLogin.organisation.idOrganisation].organisationAzureLogins as number[]).push(
      organisationAzureLogin.idOrganisationAzureLogin
    );
    relations['organisation'] = organisationAzureLogin.organisation.idOrganisation;
  }
}

/**
 *  Organisation
 */
export function getActionsToNormalizeOrganisation(
  organisations: OrganisationEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societesToNormalize: SocieteEntityState[] = [];
  const usersToNormalize: UserEntityState[] = [];
  const fichiersToNormalize: FichierEntityState[] = [];
  const organisationAzureLoginsToNormalize: OrganisationAzureLoginEntityState[] = [];
  const organisationNewslettersToNormalize: OrganisationNewsletterEntityState[] = [];
  const newslettersToNormalize: { [id: number]: NewsletterEntityState } = {};

  const normalizedOrganisations = organisations.map((organisation: OrganisationEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organisationNormalizeSociete(organisation, societesToNormalize, relations);
    organisationNormalizeUser(organisation, usersToNormalize, relations);
    organisationNormalizeFichier(organisation, fichiersToNormalize, relations);
    organisationNormalizeOrganisationAzureLogin(organisation, organisationAzureLoginsToNormalize, relations);
    organisationNormalizeOrganisationNewsletter(organisation, organisationNewslettersToNormalize, relations);
    organisationNormalizeNewsletter(organisation, newslettersToNormalize, relations);

    return {
      ...organisation,
      ...relations
    };
  });
  if (societesToNormalize.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalize, actionType));
  }
  if (usersToNormalize.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalize, actionType));
  }
  if (fichiersToNormalize.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalize, actionType));
  }
  if (organisationAzureLoginsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganisationAzureLogin(organisationAzureLoginsToNormalize, actionType));
  }
  if (organisationNewslettersToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganisationNewsletter(organisationNewslettersToNormalize, actionType));
  }
  const newslettersToNormalizeArray = Object.values(newslettersToNormalize);
  if (newslettersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeNewsletter(newslettersToNormalizeArray, actionType));
  }

  const organisationActionCreator = OrganisationGeneratedActions.upsertManyOrganisationsSuccess;
  actions.push(organisationActionCreator({ organisations: normalizedOrganisations }));

  return actions;
}

function organisationNormalizeSociete(
  organisation: OrganisationEntityState,
  societesToNormalize: SocieteEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organisation.societes?.length && typeof organisation.societes[0] !== 'number') {
    const idsSocietes: number[] = [];
    for (let i = 0; i < organisation.societes.length; i++) {
      const societe: Societe | number = organisation.societes[i];
      if (typeof societe !== 'number') {
        societesToNormalize.push({ ...societe, organisation: organisation.idOrganisation });
        idsSocietes.push(societe.idSociete);
      } else {
        idsSocietes.push(societe);
      }
    }
    relations['societes'] = idsSocietes;
  }
}

function organisationNormalizeUser(
  organisation: OrganisationEntityState,
  usersToNormalize: UserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organisation.users?.length && typeof organisation.users[0] !== 'number') {
    const idsUsers: number[] = [];
    for (let i = 0; i < organisation.users.length; i++) {
      const user: User | number = organisation.users[i];
      if (typeof user !== 'number') {
        usersToNormalize.push({ ...user, organisation: organisation.idOrganisation });
        idsUsers.push(user.idUser);
      } else {
        idsUsers.push(user);
      }
    }
    relations['users'] = idsUsers;
  }
}

function organisationNormalizeFichier(
  organisation: OrganisationEntityState,
  fichiersToNormalize: FichierEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organisation.fichiers?.length && typeof organisation.fichiers[0] !== 'number') {
    const idsFichiers: number[] = [];
    for (let i = 0; i < organisation.fichiers.length; i++) {
      const fichier: Fichier | number = organisation.fichiers[i];
      if (typeof fichier !== 'number') {
        fichiersToNormalize.push({ ...fichier, organisation: organisation.idOrganisation });
        idsFichiers.push(fichier.idFichier);
      } else {
        idsFichiers.push(fichier);
      }
    }
    relations['fichiers'] = idsFichiers;
  }
}

function organisationNormalizeOrganisationAzureLogin(
  organisation: OrganisationEntityState,
  organisationAzureLoginsToNormalize: OrganisationAzureLoginEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organisation.organisationAzureLogins?.length && typeof organisation.organisationAzureLogins[0] !== 'number') {
    const idsOrganisationAzureLogins: number[] = [];
    for (let i = 0; i < organisation.organisationAzureLogins.length; i++) {
      const organisationAzureLogin: OrganisationAzureLogin | number = organisation.organisationAzureLogins[i];
      if (typeof organisationAzureLogin !== 'number') {
        organisationAzureLoginsToNormalize.push({ ...organisationAzureLogin, organisation: organisation.idOrganisation });
        idsOrganisationAzureLogins.push(organisationAzureLogin.idOrganisationAzureLogin);
      } else {
        idsOrganisationAzureLogins.push(organisationAzureLogin);
      }
    }
    relations['organisationAzureLogins'] = idsOrganisationAzureLogins;
  }
}

function organisationNormalizeOrganisationNewsletter(
  organisation: OrganisationEntityState,
  organisationNewslettersToNormalize: OrganisationNewsletterEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organisation.organisationNewsletters?.length && typeof organisation.organisationNewsletters[0] !== 'number') {
    const idsOrganisationNewsletters: number[] = [];
    for (let i = 0; i < organisation.organisationNewsletters.length; i++) {
      const organisationNewsletter: OrganisationNewsletter | number = organisation.organisationNewsletters[i];
      if (typeof organisationNewsletter !== 'number') {
        organisationNewslettersToNormalize.push({ ...organisationNewsletter, organisation: organisation.idOrganisation });
        idsOrganisationNewsletters.push(organisationNewsletter.idOrganisationNewsletter);
      } else {
        idsOrganisationNewsletters.push(organisationNewsletter);
      }
    }
    relations['organisationNewsletters'] = idsOrganisationNewsletters;
  }
}

function organisationNormalizeNewsletter(
  organisation: OrganisationEntityState,
  newslettersToNormalize: { [id: number]: NewsletterEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organisation.newsletters?.length && typeof organisation.newsletters[0] !== 'number') {
    const idsNewsletters: number[] = [];
    for (let i = 0; i < organisation.newsletters.length; i++) {
      const newsletter: Newsletter | number = organisation.newsletters[i];

      if (typeof newsletter !== 'number') {
        if (!newslettersToNormalize[newsletter.idNewsletter]) {
          newslettersToNormalize[newsletter.idNewsletter] = {
            ...newsletter,
            organisations: newsletter.organisations?.concat() ?? []
          };
        }
        (newslettersToNormalize[newsletter.idNewsletter].organisations as number[]).push(organisation.idOrganisation);
        idsNewsletters.push(newsletter.idNewsletter);
      } else {
        idsNewsletters.push(newsletter);
      }
    }
    relations['newsletters'] = idsNewsletters;
  }
}

/**
 *  OrganisationNewsletter
 */
export function getActionsToNormalizeOrganisationNewsletter(
  organisationNewsletters: OrganisationNewsletterEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};
  const newslettersToNormalize: { [id: number]: NewsletterEntityState } = {};

  const normalizedOrganisationNewsletters = organisationNewsletters.map((organisationNewsletter: OrganisationNewsletterEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organisationNewsletterNormalizeOrganisation(organisationNewsletter, organisationsToNormalize, relations);
    organisationNewsletterNormalizeNewsletter(organisationNewsletter, newslettersToNormalize, relations);

    return {
      ...organisationNewsletter,
      ...relations
    };
  });
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }
  const newslettersToNormalizeArray = Object.values(newslettersToNormalize);
  if (newslettersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeNewsletter(newslettersToNormalizeArray, actionType));
  }

  const organisationNewsletterActionCreator = OrganisationNewsletterGeneratedActions.upsertManyOrganisationNewslettersSuccess;
  actions.push(organisationNewsletterActionCreator({ organisationNewsletters: normalizedOrganisationNewsletters }));

  return actions;
}

function organisationNewsletterNormalizeOrganisation(
  organisationNewsletter: OrganisationNewsletterEntityState,
  organisationsToNormalize: { [id: number]: OrganisationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organisationNewsletter.organisation && typeof organisationNewsletter.organisation !== 'number') {
    if (!organisationsToNormalize[organisationNewsletter.organisation.idOrganisation]) {
      organisationsToNormalize[organisationNewsletter.organisation.idOrganisation] = {
        ...organisationNewsletter.organisation,
        organisationNewsletters: organisationNewsletter.organisation.organisationNewsletters?.concat() ?? []
      };
    }
    (organisationsToNormalize[organisationNewsletter.organisation.idOrganisation].organisationNewsletters as number[]).push(
      organisationNewsletter.idOrganisationNewsletter
    );
    relations['organisation'] = organisationNewsletter.organisation.idOrganisation;
  }
}

function organisationNewsletterNormalizeNewsletter(
  organisationNewsletter: OrganisationNewsletterEntityState,
  newslettersToNormalize: { [id: number]: NewsletterEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organisationNewsletter.newsletter && typeof organisationNewsletter.newsletter !== 'number') {
    if (!newslettersToNormalize[organisationNewsletter.newsletter.idNewsletter]) {
      newslettersToNormalize[organisationNewsletter.newsletter.idNewsletter] = {
        ...organisationNewsletter.newsletter,
        organisationNewsletters: organisationNewsletter.newsletter.organisationNewsletters?.concat() ?? []
      };
    }
    (newslettersToNormalize[organisationNewsletter.newsletter.idNewsletter].organisationNewsletters as number[]).push(
      organisationNewsletter.idOrganisationNewsletter
    );
    relations['newsletter'] = organisationNewsletter.newsletter.idNewsletter;
  }
}

/**
 *  Patrimoine
 */
export function getActionsToNormalizePatrimoine(
  patrimoines: PatrimoineEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};
  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const userPatrimoinesToNormalize: UserPatrimoineEntityState[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const valeurPatrimoinesToNormalize: ValeurPatrimoineEntityState[] = [];
  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};
  const ancetresToNormalize: PatrimoineAncetreEntityState[] = [];
  const descendantsToNormalize: PatrimoineAncetreEntityState[] = [];
  const espacesToNormalize: EspaceEntityState[] = [];
  const campagnePatrimoinesToNormalize: CampagnePatrimoineEntityState[] = [];
  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};
  const societeTerritoirePatrimoinesToNormalize: SocieteTerritoirePatrimoineEntityState[] = [];
  const societeTerritoiresToNormalize: { [id: number]: SocieteTerritoireEntityState } = {};
  const composantAttendusToNormalize: ComposantAttenduEntityState[] = [];

  const normalizedPatrimoines = patrimoines.map((patrimoine: PatrimoineEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    patrimoineNormalizeUserPatrimoine(patrimoine, userPatrimoinesToNormalize, relations);
    patrimoineNormalizeUser(patrimoine, usersToNormalize, relations);
    patrimoineNormalizeValeurPatrimoine(patrimoine, valeurPatrimoinesToNormalize, relations);
    patrimoineNormalizeValeur(patrimoine, valeursToNormalize, relations);
    patrimoineNormalizeAncetres(patrimoine, ancetresToNormalize, relations);
    patrimoineNormalizeDescendants(patrimoine, descendantsToNormalize, relations);
    patrimoineNormalizeEspace(patrimoine, espacesToNormalize, relations);
    patrimoineNormalizeCampagnePatrimoine(patrimoine, campagnePatrimoinesToNormalize, relations);
    patrimoineNormalizeCampagne(patrimoine, campagnesToNormalize, relations);
    patrimoineNormalizeSocieteTerritoirePatrimoine(patrimoine, societeTerritoirePatrimoinesToNormalize, relations);
    patrimoineNormalizeSocieteTerritoire(patrimoine, societeTerritoiresToNormalize, relations);
    patrimoineNormalizeComposantAttendu(patrimoine, composantAttendusToNormalize, relations);
    patrimoineNormalizeSocietePatrimoineHierarchie(patrimoine, societePatrimoineHierarchiesToNormalize, relations);
    patrimoineNormalizeSociete(patrimoine, societesToNormalize, relations);

    return {
      ...patrimoine,
      ...relations
    };
  });
  const societePatrimoineHierarchiesToNormalizeArray = Object.values(societePatrimoineHierarchiesToNormalize);
  if (societePatrimoineHierarchiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalizeArray, actionType));
  }
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  if (userPatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeUserPatrimoine(userPatrimoinesToNormalize, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (valeurPatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurPatrimoine(valeurPatrimoinesToNormalize, actionType));
  }
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }
  if (ancetresToNormalize.length) {
    actions.push(...getActionsToNormalizePatrimoineAncetre(ancetresToNormalize, actionType));
  }
  if (descendantsToNormalize.length) {
    actions.push(...getActionsToNormalizePatrimoineAncetre(descendantsToNormalize, actionType));
  }
  if (espacesToNormalize.length) {
    actions.push(...getActionsToNormalizeEspace(espacesToNormalize, actionType));
  }
  if (campagnePatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagnePatrimoine(campagnePatrimoinesToNormalize, actionType));
  }
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }
  if (societeTerritoirePatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoirePatrimoine(societeTerritoirePatrimoinesToNormalize, actionType));
  }
  const societeTerritoiresToNormalizeArray = Object.values(societeTerritoiresToNormalize);
  if (societeTerritoiresToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoire(societeTerritoiresToNormalizeArray, actionType));
  }
  if (composantAttendusToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantAttendu(composantAttendusToNormalize, actionType));
  }

  const patrimoineActionCreator = PatrimoineGeneratedActions.upsertManyPatrimoinesSuccess;
  actions.push(patrimoineActionCreator({ patrimoines: normalizedPatrimoines }));

  return actions;
}

function patrimoineNormalizeUserPatrimoine(
  patrimoine: PatrimoineEntityState,
  userPatrimoinesToNormalize: UserPatrimoineEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.userPatrimoines?.length && typeof patrimoine.userPatrimoines[0] !== 'number') {
    const idsUserPatrimoines: number[] = [];
    for (let i = 0; i < patrimoine.userPatrimoines.length; i++) {
      const userPatrimoine: UserPatrimoine | number = patrimoine.userPatrimoines[i];
      if (typeof userPatrimoine !== 'number') {
        userPatrimoinesToNormalize.push({ ...userPatrimoine, patrimoine: patrimoine.idPatrimoine });
        idsUserPatrimoines.push(userPatrimoine.idUserPatrimoine);
      } else {
        idsUserPatrimoines.push(userPatrimoine);
      }
    }
    relations['userPatrimoines'] = idsUserPatrimoines;
  }
}

function patrimoineNormalizeUser(
  patrimoine: PatrimoineEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.users?.length && typeof patrimoine.users[0] !== 'number') {
    const idsUsers: number[] = [];
    for (let i = 0; i < patrimoine.users.length; i++) {
      const user: User | number = patrimoine.users[i];

      if (typeof user !== 'number') {
        if (!usersToNormalize[user.idUser]) {
          usersToNormalize[user.idUser] = {
            ...user,
            patrimoines: user.patrimoines?.concat() ?? []
          };
        }
        (usersToNormalize[user.idUser].patrimoines as number[]).push(patrimoine.idPatrimoine);
        idsUsers.push(user.idUser);
      } else {
        idsUsers.push(user);
      }
    }
    relations['users'] = idsUsers;
  }
}

function patrimoineNormalizeValeurPatrimoine(
  patrimoine: PatrimoineEntityState,
  valeurPatrimoinesToNormalize: ValeurPatrimoineEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.valeurPatrimoines?.length && typeof patrimoine.valeurPatrimoines[0] !== 'number') {
    const idsValeurPatrimoines: number[] = [];
    for (let i = 0; i < patrimoine.valeurPatrimoines.length; i++) {
      const valeurPatrimoine: ValeurPatrimoine | number = patrimoine.valeurPatrimoines[i];
      if (typeof valeurPatrimoine !== 'number') {
        valeurPatrimoinesToNormalize.push({ ...valeurPatrimoine, patrimoine: patrimoine.idPatrimoine });
        idsValeurPatrimoines.push(valeurPatrimoine.idValeurPatrimoine);
      } else {
        idsValeurPatrimoines.push(valeurPatrimoine);
      }
    }
    relations['valeurPatrimoines'] = idsValeurPatrimoines;
  }
}

function patrimoineNormalizeValeur(
  patrimoine: PatrimoineEntityState,
  valeursToNormalize: { [id: number]: ValeurEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.valeurs?.length && typeof patrimoine.valeurs[0] !== 'number') {
    const idsValeurs: number[] = [];
    for (let i = 0; i < patrimoine.valeurs.length; i++) {
      const valeur: Valeur | number = patrimoine.valeurs[i];

      if (typeof valeur !== 'number') {
        if (!valeursToNormalize[valeur.idValeur]) {
          valeursToNormalize[valeur.idValeur] = {
            ...valeur,
            patrimoines: valeur.patrimoines?.concat() ?? []
          };
        }
        (valeursToNormalize[valeur.idValeur].patrimoines as number[]).push(patrimoine.idPatrimoine);
        idsValeurs.push(valeur.idValeur);
      } else {
        idsValeurs.push(valeur);
      }
    }
    relations['valeurs'] = idsValeurs;
  }
}

function patrimoineNormalizeAncetres(
  patrimoine: PatrimoineEntityState,
  patrimoineAncetresToNormalize: PatrimoineAncetreEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.ancetres?.length && typeof patrimoine.ancetres[0] !== 'number') {
    const idsPatrimoineAncetres: number[] = [];
    for (let i = 0; i < patrimoine.ancetres.length; i++) {
      const patrimoineAncetre: PatrimoineAncetre | number = patrimoine.ancetres[i];
      if (typeof patrimoineAncetre !== 'number') {
        patrimoineAncetresToNormalize.push({ ...patrimoineAncetre, descendantPatrimoine: patrimoine.idPatrimoine });
        idsPatrimoineAncetres.push(patrimoineAncetre.idPatrimoineAncetre);
      } else {
        idsPatrimoineAncetres.push(patrimoineAncetre);
      }
    }
    relations['ancetres'] = idsPatrimoineAncetres;
  }
}

function patrimoineNormalizeDescendants(
  patrimoine: PatrimoineEntityState,
  patrimoineAncetresToNormalize: PatrimoineAncetreEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.descendants?.length && typeof patrimoine.descendants[0] !== 'number') {
    const idsPatrimoineAncetres: number[] = [];
    for (let i = 0; i < patrimoine.descendants.length; i++) {
      const patrimoineAncetre: PatrimoineAncetre | number = patrimoine.descendants[i];
      if (typeof patrimoineAncetre !== 'number') {
        patrimoineAncetresToNormalize.push({ ...patrimoineAncetre, ancetrePatrimoine: patrimoine.idPatrimoine });
        idsPatrimoineAncetres.push(patrimoineAncetre.idPatrimoineAncetre);
      } else {
        idsPatrimoineAncetres.push(patrimoineAncetre);
      }
    }
    relations['descendants'] = idsPatrimoineAncetres;
  }
}

function patrimoineNormalizeEspace(
  patrimoine: PatrimoineEntityState,
  espacesToNormalize: EspaceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.espaces?.length && typeof patrimoine.espaces[0] !== 'number') {
    const idsEspaces: number[] = [];
    for (let i = 0; i < patrimoine.espaces.length; i++) {
      const espace: Espace | number = patrimoine.espaces[i];
      if (typeof espace !== 'number') {
        espacesToNormalize.push({ ...espace, patrimoine: patrimoine.idPatrimoine });
        idsEspaces.push(espace.idEspace);
      } else {
        idsEspaces.push(espace);
      }
    }
    relations['espaces'] = idsEspaces;
  }
}

function patrimoineNormalizeCampagnePatrimoine(
  patrimoine: PatrimoineEntityState,
  campagnePatrimoinesToNormalize: CampagnePatrimoineEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.campagnePatrimoines?.length && typeof patrimoine.campagnePatrimoines[0] !== 'number') {
    const idsCampagnePatrimoines: number[] = [];
    for (let i = 0; i < patrimoine.campagnePatrimoines.length; i++) {
      const campagnePatrimoine: CampagnePatrimoine | number = patrimoine.campagnePatrimoines[i];
      if (typeof campagnePatrimoine !== 'number') {
        campagnePatrimoinesToNormalize.push({ ...campagnePatrimoine, patrimoine: patrimoine.idPatrimoine });
        idsCampagnePatrimoines.push(campagnePatrimoine.idCampagnePatrimoine);
      } else {
        idsCampagnePatrimoines.push(campagnePatrimoine);
      }
    }
    relations['campagnePatrimoines'] = idsCampagnePatrimoines;
  }
}

function patrimoineNormalizeCampagne(
  patrimoine: PatrimoineEntityState,
  campagnesToNormalize: { [id: number]: CampagneEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.campagnes?.length && typeof patrimoine.campagnes[0] !== 'number') {
    const idsCampagnes: number[] = [];
    for (let i = 0; i < patrimoine.campagnes.length; i++) {
      const campagne: Campagne | number = patrimoine.campagnes[i];

      if (typeof campagne !== 'number') {
        if (!campagnesToNormalize[campagne.idCampagne]) {
          campagnesToNormalize[campagne.idCampagne] = {
            ...campagne,
            patrimoines: campagne.patrimoines?.concat() ?? []
          };
        }
        (campagnesToNormalize[campagne.idCampagne].patrimoines as number[]).push(patrimoine.idPatrimoine);
        idsCampagnes.push(campagne.idCampagne);
      } else {
        idsCampagnes.push(campagne);
      }
    }
    relations['campagnes'] = idsCampagnes;
  }
}

function patrimoineNormalizeSocieteTerritoirePatrimoine(
  patrimoine: PatrimoineEntityState,
  societeTerritoirePatrimoinesToNormalize: SocieteTerritoirePatrimoineEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.societeTerritoirePatrimoines?.length && typeof patrimoine.societeTerritoirePatrimoines[0] !== 'number') {
    const idsSocieteTerritoirePatrimoines: number[] = [];
    for (let i = 0; i < patrimoine.societeTerritoirePatrimoines.length; i++) {
      const societeTerritoirePatrimoine: SocieteTerritoirePatrimoine | number = patrimoine.societeTerritoirePatrimoines[i];
      if (typeof societeTerritoirePatrimoine !== 'number') {
        societeTerritoirePatrimoinesToNormalize.push({ ...societeTerritoirePatrimoine, patrimoine: patrimoine.idPatrimoine });
        idsSocieteTerritoirePatrimoines.push(societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine);
      } else {
        idsSocieteTerritoirePatrimoines.push(societeTerritoirePatrimoine);
      }
    }
    relations['societeTerritoirePatrimoines'] = idsSocieteTerritoirePatrimoines;
  }
}

function patrimoineNormalizeSocieteTerritoire(
  patrimoine: PatrimoineEntityState,
  societeTerritoiresToNormalize: { [id: number]: SocieteTerritoireEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.societeTerritoires?.length && typeof patrimoine.societeTerritoires[0] !== 'number') {
    const idsSocieteTerritoires: number[] = [];
    for (let i = 0; i < patrimoine.societeTerritoires.length; i++) {
      const societeTerritoire: SocieteTerritoire | number = patrimoine.societeTerritoires[i];

      if (typeof societeTerritoire !== 'number') {
        if (!societeTerritoiresToNormalize[societeTerritoire.idSocieteTerritoire]) {
          societeTerritoiresToNormalize[societeTerritoire.idSocieteTerritoire] = {
            ...societeTerritoire,
            patrimoines: societeTerritoire.patrimoines?.concat() ?? []
          };
        }
        (societeTerritoiresToNormalize[societeTerritoire.idSocieteTerritoire].patrimoines as number[]).push(patrimoine.idPatrimoine);
        idsSocieteTerritoires.push(societeTerritoire.idSocieteTerritoire);
      } else {
        idsSocieteTerritoires.push(societeTerritoire);
      }
    }
    relations['societeTerritoires'] = idsSocieteTerritoires;
  }
}

function patrimoineNormalizeComposantAttendu(
  patrimoine: PatrimoineEntityState,
  composantAttendusToNormalize: ComposantAttenduEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.composantAttendus?.length && typeof patrimoine.composantAttendus[0] !== 'number') {
    const idsComposantAttendus: number[] = [];
    for (let i = 0; i < patrimoine.composantAttendus.length; i++) {
      const composantAttendu: ComposantAttendu | number = patrimoine.composantAttendus[i];
      if (typeof composantAttendu !== 'number') {
        composantAttendusToNormalize.push({ ...composantAttendu, patrimoine: patrimoine.idPatrimoine });
        idsComposantAttendus.push(composantAttendu.idComposantAttendu);
      } else {
        idsComposantAttendus.push(composantAttendu);
      }
    }
    relations['composantAttendus'] = idsComposantAttendus;
  }
}

function patrimoineNormalizeSocietePatrimoineHierarchie(
  patrimoine: PatrimoineEntityState,
  societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.societePatrimoineHierarchie && typeof patrimoine.societePatrimoineHierarchie !== 'number') {
    if (!societePatrimoineHierarchiesToNormalize[patrimoine.societePatrimoineHierarchie.idSocietePatrimoineHierarchie]) {
      societePatrimoineHierarchiesToNormalize[patrimoine.societePatrimoineHierarchie.idSocietePatrimoineHierarchie] = {
        ...patrimoine.societePatrimoineHierarchie,
        patrimoines: patrimoine.societePatrimoineHierarchie.patrimoines?.concat() ?? []
      };
    }
    (societePatrimoineHierarchiesToNormalize[patrimoine.societePatrimoineHierarchie.idSocietePatrimoineHierarchie].patrimoines as number[]).push(
      patrimoine.idPatrimoine
    );
    relations['societePatrimoineHierarchie'] = patrimoine.societePatrimoineHierarchie.idSocietePatrimoineHierarchie;
  }
}

function patrimoineNormalizeSociete(
  patrimoine: PatrimoineEntityState,
  societesToNormalize: { [id: number]: SocieteEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoine.societe && typeof patrimoine.societe !== 'number') {
    if (!societesToNormalize[patrimoine.societe.idSociete]) {
      societesToNormalize[patrimoine.societe.idSociete] = {
        ...patrimoine.societe,
        patrimoines: patrimoine.societe.patrimoines?.concat() ?? []
      };
    }
    (societesToNormalize[patrimoine.societe.idSociete].patrimoines as number[]).push(
      patrimoine.idPatrimoine
    );
    relations['societe'] = patrimoine.societe.idSociete;
  }
}

/**
 *  PatrimoineAncetre
 */
export function getActionsToNormalizePatrimoineAncetre(
  patrimoineAncetres: PatrimoineAncetreEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const ancetrePatrimoineToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const descendantPatrimoineToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedPatrimoineAncetres = patrimoineAncetres.map((patrimoineAncetre: PatrimoineAncetreEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    patrimoineAncetreNormalizeAncetrePatrimoine(patrimoineAncetre, ancetrePatrimoineToNormalize, relations);
    patrimoineAncetreNormalizeDescendantPatrimoine(patrimoineAncetre, descendantPatrimoineToNormalize, relations);

    return {
      ...patrimoineAncetre,
      ...relations
    };
  });
  const ancetrePatrimoineToNormalizeArray = Object.values(ancetrePatrimoineToNormalize);
  if (ancetrePatrimoineToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(ancetrePatrimoineToNormalizeArray, actionType));
  }
  const descendantPatrimoineToNormalizeArray = Object.values(descendantPatrimoineToNormalize);
  if (descendantPatrimoineToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(descendantPatrimoineToNormalizeArray, actionType));
  }

  const patrimoineAncetreActionCreator = PatrimoineAncetreGeneratedActions.upsertManyPatrimoineAncetresSuccess;
  actions.push(patrimoineAncetreActionCreator({ patrimoineAncetres: normalizedPatrimoineAncetres }));

  return actions;
}

function patrimoineAncetreNormalizeAncetrePatrimoine(
  patrimoineAncetre: PatrimoineAncetreEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoineAncetre.ancetrePatrimoine && typeof patrimoineAncetre.ancetrePatrimoine !== 'number') {
    if (!patrimoinesToNormalize[patrimoineAncetre.ancetrePatrimoine.idPatrimoine]) {
      patrimoinesToNormalize[patrimoineAncetre.ancetrePatrimoine.idPatrimoine] = {
        ...patrimoineAncetre.ancetrePatrimoine,
        descendants: patrimoineAncetre.ancetrePatrimoine.descendants?.concat() ?? []
      };
    }
    (patrimoinesToNormalize[patrimoineAncetre.ancetrePatrimoine.idPatrimoine].descendants as number[]).push(
      patrimoineAncetre.idPatrimoineAncetre
    );
    relations['ancetrePatrimoine'] = patrimoineAncetre.ancetrePatrimoine.idPatrimoine;
  }
}

function patrimoineAncetreNormalizeDescendantPatrimoine(
  patrimoineAncetre: PatrimoineAncetreEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoineAncetre.descendantPatrimoine && typeof patrimoineAncetre.descendantPatrimoine !== 'number') {
    if (!patrimoinesToNormalize[patrimoineAncetre.descendantPatrimoine.idPatrimoine]) {
      patrimoinesToNormalize[patrimoineAncetre.descendantPatrimoine.idPatrimoine] = {
        ...patrimoineAncetre.descendantPatrimoine,
        ancetres: patrimoineAncetre.descendantPatrimoine.ancetres?.concat() ?? []
      };
    }
    (patrimoinesToNormalize[patrimoineAncetre.descendantPatrimoine.idPatrimoine].ancetres as number[]).push(
      patrimoineAncetre.idPatrimoineAncetre
    );
    relations['descendantPatrimoine'] = patrimoineAncetre.descendantPatrimoine.idPatrimoine;
  }
}

/**
 *  PatrimoineHierarchieTemplate
 */
export function getActionsToNormalizePatrimoineHierarchieTemplate(
  patrimoineHierarchieTemplates: PatrimoineHierarchieTemplateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societePatrimoineHierarchiesToNormalize: SocietePatrimoineHierarchieEntityState[] = [];

  const normalizedPatrimoineHierarchieTemplates = patrimoineHierarchieTemplates.map((patrimoineHierarchieTemplate: PatrimoineHierarchieTemplateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    patrimoineHierarchieTemplateNormalizeSocietePatrimoineHierarchie(patrimoineHierarchieTemplate, societePatrimoineHierarchiesToNormalize, relations);

    return {
      ...patrimoineHierarchieTemplate,
      ...relations
    };
  });
  if (societePatrimoineHierarchiesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalize, actionType));
  }

  const patrimoineHierarchieTemplateActionCreator = PatrimoineHierarchieTemplateGeneratedActions.upsertManyPatrimoineHierarchieTemplatesSuccess;
  actions.push(patrimoineHierarchieTemplateActionCreator({ patrimoineHierarchieTemplates: normalizedPatrimoineHierarchieTemplates }));

  return actions;
}

function patrimoineHierarchieTemplateNormalizeSocietePatrimoineHierarchie(
  patrimoineHierarchieTemplate: PatrimoineHierarchieTemplateEntityState,
  societePatrimoineHierarchiesToNormalize: SocietePatrimoineHierarchieEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (patrimoineHierarchieTemplate.societePatrimoineHierarchies?.length && typeof patrimoineHierarchieTemplate.societePatrimoineHierarchies[0] !== 'number') {
    const idsSocietePatrimoineHierarchies: number[] = [];
    for (let i = 0; i < patrimoineHierarchieTemplate.societePatrimoineHierarchies.length; i++) {
      const societePatrimoineHierarchie: SocietePatrimoineHierarchie | number = patrimoineHierarchieTemplate.societePatrimoineHierarchies[i];
      if (typeof societePatrimoineHierarchie !== 'number') {
        societePatrimoineHierarchiesToNormalize.push({ ...societePatrimoineHierarchie, patrimoineHierarchieTemplate: patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate });
        idsSocietePatrimoineHierarchies.push(societePatrimoineHierarchie.idSocietePatrimoineHierarchie);
      } else {
        idsSocietePatrimoineHierarchies.push(societePatrimoineHierarchie);
      }
    }
    relations['societePatrimoineHierarchies'] = idsSocietePatrimoineHierarchies;
  }
}

/**
 *  Societe
 */
export function getActionsToNormalizeSociete(
  societes: SocieteEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};
  const societeComposantsToNormalize: SocieteComposantEntityState[] = [];
  const societePatrimoineHierarchiesToNormalize: SocietePatrimoineHierarchieEntityState[] = [];
  const societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[] = [];
  const societeComposantFamillesToNormalize: SocieteComposantFamilleEntityState[] = [];
  const societeEspaceFamillesToNormalize: SocieteEspaceFamilleEntityState[] = [];
  const campagnesToNormalize: CampagneEntityState[] = [];
  const societeTerritoiresToNormalize: SocieteTerritoireEntityState[] = [];
  const societeProfilsToNormalize: SocieteProfilEntityState[] = [];
  const patrimoinesToNormalize: PatrimoineEntityState[] = [];
  const societeConfigAnalyseSynthesesToNormalize: SocieteConfigAnalyseSyntheseEntityState[] = [];

  const normalizedSocietes = societes.map((societe: SocieteEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeNormalizeSocieteComposant(societe, societeComposantsToNormalize, relations);
    societeNormalizeSocietePatrimoineHierarchie(societe, societePatrimoineHierarchiesToNormalize, relations);
    societeNormalizeSocieteCaracteristique(societe, societeCaracteristiquesToNormalize, relations);
    societeNormalizeSocieteComposantFamille(societe, societeComposantFamillesToNormalize, relations);
    societeNormalizeSocieteEspaceFamille(societe, societeEspaceFamillesToNormalize, relations);
    societeNormalizeCampagne(societe, campagnesToNormalize, relations);
    societeNormalizeSocieteTerritoire(societe, societeTerritoiresToNormalize, relations);
    societeNormalizeSocieteProfil(societe, societeProfilsToNormalize, relations);
    societeNormalizePatrimoine(societe, patrimoinesToNormalize, relations);
    societeNormalizeSocieteConfigAnalyseSynthese(societe, societeConfigAnalyseSynthesesToNormalize, relations);
    societeNormalizeOrganisation(societe, organisationsToNormalize, relations);

    return {
      ...societe,
      ...relations
    };
  });
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }
  if (societeComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalize, actionType));
  }
  if (societePatrimoineHierarchiesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalize, actionType));
  }
  if (societeCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalize, actionType));
  }
  if (societeComposantFamillesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposantFamille(societeComposantFamillesToNormalize, actionType));
  }
  if (societeEspaceFamillesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteEspaceFamille(societeEspaceFamillesToNormalize, actionType));
  }
  if (campagnesToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalize, actionType));
  }
  if (societeTerritoiresToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoire(societeTerritoiresToNormalize, actionType));
  }
  if (societeProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteProfil(societeProfilsToNormalize, actionType));
  }
  if (patrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalize, actionType));
  }
  if (societeConfigAnalyseSynthesesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthesesToNormalize, actionType));
  }

  const societeActionCreator = SocieteGeneratedActions.upsertManySocietesSuccess;
  actions.push(societeActionCreator({ societes: normalizedSocietes }));

  return actions;
}

function societeNormalizeSocieteComposant(
  societe: SocieteEntityState,
  societeComposantsToNormalize: SocieteComposantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societe.societeComposants?.length && typeof societe.societeComposants[0] !== 'number') {
    const idsSocieteComposants: number[] = [];
    for (let i = 0; i < societe.societeComposants.length; i++) {
      const societeComposant: SocieteComposant | number = societe.societeComposants[i];
      if (typeof societeComposant !== 'number') {
        societeComposantsToNormalize.push({ ...societeComposant, societe: societe.idSociete });
        idsSocieteComposants.push(societeComposant.idSocieteComposant);
      } else {
        idsSocieteComposants.push(societeComposant);
      }
    }
    relations['societeComposants'] = idsSocieteComposants;
  }
}

function societeNormalizeSocietePatrimoineHierarchie(
  societe: SocieteEntityState,
  societePatrimoineHierarchiesToNormalize: SocietePatrimoineHierarchieEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societe.societePatrimoineHierarchies?.length && typeof societe.societePatrimoineHierarchies[0] !== 'number') {
    const idsSocietePatrimoineHierarchies: number[] = [];
    for (let i = 0; i < societe.societePatrimoineHierarchies.length; i++) {
      const societePatrimoineHierarchie: SocietePatrimoineHierarchie | number = societe.societePatrimoineHierarchies[i];
      if (typeof societePatrimoineHierarchie !== 'number') {
        societePatrimoineHierarchiesToNormalize.push({ ...societePatrimoineHierarchie, societe: societe.idSociete });
        idsSocietePatrimoineHierarchies.push(societePatrimoineHierarchie.idSocietePatrimoineHierarchie);
      } else {
        idsSocietePatrimoineHierarchies.push(societePatrimoineHierarchie);
      }
    }
    relations['societePatrimoineHierarchies'] = idsSocietePatrimoineHierarchies;
  }
}

function societeNormalizeSocieteCaracteristique(
  societe: SocieteEntityState,
  societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societe.societeCaracteristiques?.length && typeof societe.societeCaracteristiques[0] !== 'number') {
    const idsSocieteCaracteristiques: number[] = [];
    for (let i = 0; i < societe.societeCaracteristiques.length; i++) {
      const societeCaracteristique: SocieteCaracteristique | number = societe.societeCaracteristiques[i];
      if (typeof societeCaracteristique !== 'number') {
        societeCaracteristiquesToNormalize.push({ ...societeCaracteristique, societe: societe.idSociete });
        idsSocieteCaracteristiques.push(societeCaracteristique.idSocieteCaracteristique);
      } else {
        idsSocieteCaracteristiques.push(societeCaracteristique);
      }
    }
    relations['societeCaracteristiques'] = idsSocieteCaracteristiques;
  }
}

function societeNormalizeSocieteComposantFamille(
  societe: SocieteEntityState,
  societeComposantFamillesToNormalize: SocieteComposantFamilleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societe.societeComposantFamilles?.length && typeof societe.societeComposantFamilles[0] !== 'number') {
    const idsSocieteComposantFamilles: number[] = [];
    for (let i = 0; i < societe.societeComposantFamilles.length; i++) {
      const societeComposantFamille: SocieteComposantFamille | number = societe.societeComposantFamilles[i];
      if (typeof societeComposantFamille !== 'number') {
        societeComposantFamillesToNormalize.push({ ...societeComposantFamille, societe: societe.idSociete });
        idsSocieteComposantFamilles.push(societeComposantFamille.idSocieteComposantFamille);
      } else {
        idsSocieteComposantFamilles.push(societeComposantFamille);
      }
    }
    relations['societeComposantFamilles'] = idsSocieteComposantFamilles;
  }
}

function societeNormalizeSocieteEspaceFamille(
  societe: SocieteEntityState,
  societeEspaceFamillesToNormalize: SocieteEspaceFamilleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societe.societeEspaceFamilles?.length && typeof societe.societeEspaceFamilles[0] !== 'number') {
    const idsSocieteEspaceFamilles: number[] = [];
    for (let i = 0; i < societe.societeEspaceFamilles.length; i++) {
      const societeEspaceFamille: SocieteEspaceFamille | number = societe.societeEspaceFamilles[i];
      if (typeof societeEspaceFamille !== 'number') {
        societeEspaceFamillesToNormalize.push({ ...societeEspaceFamille, societe: societe.idSociete });
        idsSocieteEspaceFamilles.push(societeEspaceFamille.idSocieteEspaceFamille);
      } else {
        idsSocieteEspaceFamilles.push(societeEspaceFamille);
      }
    }
    relations['societeEspaceFamilles'] = idsSocieteEspaceFamilles;
  }
}

function societeNormalizeCampagne(
  societe: SocieteEntityState,
  campagnesToNormalize: CampagneEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societe.campagnes?.length && typeof societe.campagnes[0] !== 'number') {
    const idsCampagnes: number[] = [];
    for (let i = 0; i < societe.campagnes.length; i++) {
      const campagne: Campagne | number = societe.campagnes[i];
      if (typeof campagne !== 'number') {
        campagnesToNormalize.push({ ...campagne, societe: societe.idSociete });
        idsCampagnes.push(campagne.idCampagne);
      } else {
        idsCampagnes.push(campagne);
      }
    }
    relations['campagnes'] = idsCampagnes;
  }
}

function societeNormalizeSocieteTerritoire(
  societe: SocieteEntityState,
  societeTerritoiresToNormalize: SocieteTerritoireEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societe.societeTerritoires?.length && typeof societe.societeTerritoires[0] !== 'number') {
    const idsSocieteTerritoires: number[] = [];
    for (let i = 0; i < societe.societeTerritoires.length; i++) {
      const societeTerritoire: SocieteTerritoire | number = societe.societeTerritoires[i];
      if (typeof societeTerritoire !== 'number') {
        societeTerritoiresToNormalize.push({ ...societeTerritoire, societe: societe.idSociete });
        idsSocieteTerritoires.push(societeTerritoire.idSocieteTerritoire);
      } else {
        idsSocieteTerritoires.push(societeTerritoire);
      }
    }
    relations['societeTerritoires'] = idsSocieteTerritoires;
  }
}

function societeNormalizeSocieteProfil(
  societe: SocieteEntityState,
  societeProfilsToNormalize: SocieteProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societe.societeProfils?.length && typeof societe.societeProfils[0] !== 'number') {
    const idsSocieteProfils: number[] = [];
    for (let i = 0; i < societe.societeProfils.length; i++) {
      const societeProfil: SocieteProfil | number = societe.societeProfils[i];
      if (typeof societeProfil !== 'number') {
        societeProfilsToNormalize.push({ ...societeProfil, societe: societe.idSociete });
        idsSocieteProfils.push(societeProfil.idSocieteProfil);
      } else {
        idsSocieteProfils.push(societeProfil);
      }
    }
    relations['societeProfils'] = idsSocieteProfils;
  }
}

function societeNormalizePatrimoine(
  societe: SocieteEntityState,
  patrimoinesToNormalize: PatrimoineEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societe.patrimoines?.length && typeof societe.patrimoines[0] !== 'number') {
    const idsPatrimoines: number[] = [];
    for (let i = 0; i < societe.patrimoines.length; i++) {
      const patrimoine: Patrimoine | number = societe.patrimoines[i];
      if (typeof patrimoine !== 'number') {
        patrimoinesToNormalize.push({ ...patrimoine, societe: societe.idSociete });
        idsPatrimoines.push(patrimoine.idPatrimoine);
      } else {
        idsPatrimoines.push(patrimoine);
      }
    }
    relations['patrimoines'] = idsPatrimoines;
  }
}

function societeNormalizeSocieteConfigAnalyseSynthese(
  societe: SocieteEntityState,
  societeConfigAnalyseSynthesesToNormalize: SocieteConfigAnalyseSyntheseEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societe.societeConfigAnalyseSyntheses?.length && typeof societe.societeConfigAnalyseSyntheses[0] !== 'number') {
    const idsSocieteConfigAnalyseSyntheses: number[] = [];
    for (let i = 0; i < societe.societeConfigAnalyseSyntheses.length; i++) {
      const societeConfigAnalyseSynthese: SocieteConfigAnalyseSynthese | number = societe.societeConfigAnalyseSyntheses[i];
      if (typeof societeConfigAnalyseSynthese !== 'number') {
        societeConfigAnalyseSynthesesToNormalize.push({ ...societeConfigAnalyseSynthese, societe: societe.idSociete });
        idsSocieteConfigAnalyseSyntheses.push(societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese);
      } else {
        idsSocieteConfigAnalyseSyntheses.push(societeConfigAnalyseSynthese);
      }
    }
    relations['societeConfigAnalyseSyntheses'] = idsSocieteConfigAnalyseSyntheses;
  }
}

function societeNormalizeOrganisation(
  societe: SocieteEntityState,
  organisationsToNormalize: { [id: number]: OrganisationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societe.organisation && typeof societe.organisation !== 'number') {
    if (!organisationsToNormalize[societe.organisation.idOrganisation]) {
      organisationsToNormalize[societe.organisation.idOrganisation] = {
        ...societe.organisation,
        societes: societe.organisation.societes?.concat() ?? []
      };
    }
    (organisationsToNormalize[societe.organisation.idOrganisation].societes as number[]).push(
      societe.idSociete
    );
    relations['organisation'] = societe.organisation.idOrganisation;
  }
}

/**
 *  SocieteCaracteristique
 */
export function getActionsToNormalizeSocieteCaracteristique(
  societeCaracteristiques: SocieteCaracteristiqueEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const caracteristiqueTemplatesToNormalize: { [id: number]: CaracteristiqueTemplateEntityState } = {};
  const societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState } = {};
  const societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};
  const societeCaracteristiqueChoicesToNormalize: SocieteCaracteristiqueChoixEntityState[] = [];
  const valeursToNormalize: ValeurEntityState[] = [];
  const campagneSocieteCaracteristiquesToNormalize: CampagneSocieteCaracteristiqueEntityState[] = [];
  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};
  const societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState[] = [];
  const societeConfigAnalyseSynthesesToNormalize: { [id: number]: SocieteConfigAnalyseSyntheseEntityState } = {};

  const normalizedSocieteCaracteristiques = societeCaracteristiques.map((societeCaracteristique: SocieteCaracteristiqueEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeCaracteristiqueNormalizeSocieteCaracteristiqueChoix(societeCaracteristique, societeCaracteristiqueChoicesToNormalize, relations);
    societeCaracteristiqueNormalizeValeur(societeCaracteristique, valeursToNormalize, relations);
    societeCaracteristiqueNormalizeCampagneSocieteCaracteristique(societeCaracteristique, campagneSocieteCaracteristiquesToNormalize, relations);
    societeCaracteristiqueNormalizeCampagne(societeCaracteristique, campagnesToNormalize, relations);
    societeCaracteristiqueNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeCaracteristique, societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize, relations);
    societeCaracteristiqueNormalizeSocieteConfigAnalyseSynthese(societeCaracteristique, societeConfigAnalyseSynthesesToNormalize, relations);
    societeCaracteristiqueNormalizeSociete(societeCaracteristique, societesToNormalize, relations);
    societeCaracteristiqueNormalizeCaracteristiqueTemplate(societeCaracteristique, caracteristiqueTemplatesToNormalize, relations);
    societeCaracteristiqueNormalizeSocieteComposant(societeCaracteristique, societeComposantsToNormalize, relations);
    societeCaracteristiqueNormalizeSocietePatrimoineHierarchie(societeCaracteristique, societePatrimoineHierarchiesToNormalize, relations);

    return {
      ...societeCaracteristique,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const caracteristiqueTemplatesToNormalizeArray = Object.values(caracteristiqueTemplatesToNormalize);
  if (caracteristiqueTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueTemplate(caracteristiqueTemplatesToNormalizeArray, actionType));
  }
  const societeComposantsToNormalizeArray = Object.values(societeComposantsToNormalize);
  if (societeComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalizeArray, actionType));
  }
  const societePatrimoineHierarchiesToNormalizeArray = Object.values(societePatrimoineHierarchiesToNormalize);
  if (societePatrimoineHierarchiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalizeArray, actionType));
  }
  if (societeCaracteristiqueChoicesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristiqueChoix(societeCaracteristiqueChoicesToNormalize, actionType));
  }
  if (valeursToNormalize.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalize, actionType));
  }
  if (campagneSocieteCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagneSocieteCaracteristique(campagneSocieteCaracteristiquesToNormalize, actionType));
  }
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }
  if (societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize, actionType));
  }
  const societeConfigAnalyseSynthesesToNormalizeArray = Object.values(societeConfigAnalyseSynthesesToNormalize);
  if (societeConfigAnalyseSynthesesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthesesToNormalizeArray, actionType));
  }

  const societeCaracteristiqueActionCreator = SocieteCaracteristiqueGeneratedActions.upsertManySocieteCaracteristiquesSuccess;
  actions.push(societeCaracteristiqueActionCreator({ societeCaracteristiques: normalizedSocieteCaracteristiques }));

  return actions;
}

function societeCaracteristiqueNormalizeSocieteCaracteristiqueChoix(
  societeCaracteristique: SocieteCaracteristiqueEntityState,
  societeCaracteristiqueChoicesToNormalize: SocieteCaracteristiqueChoixEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristique.societeCaracteristiqueChoices?.length && typeof societeCaracteristique.societeCaracteristiqueChoices[0] !== 'number') {
    const idsSocieteCaracteristiqueChoices: number[] = [];
    for (let i = 0; i < societeCaracteristique.societeCaracteristiqueChoices.length; i++) {
      const societeCaracteristiqueChoix: SocieteCaracteristiqueChoix | number = societeCaracteristique.societeCaracteristiqueChoices[i];
      if (typeof societeCaracteristiqueChoix !== 'number') {
        societeCaracteristiqueChoicesToNormalize.push({ ...societeCaracteristiqueChoix, societeCaracteristique: societeCaracteristique.idSocieteCaracteristique });
        idsSocieteCaracteristiqueChoices.push(societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix);
      } else {
        idsSocieteCaracteristiqueChoices.push(societeCaracteristiqueChoix);
      }
    }
    relations['societeCaracteristiqueChoices'] = idsSocieteCaracteristiqueChoices;
  }
}

function societeCaracteristiqueNormalizeValeur(
  societeCaracteristique: SocieteCaracteristiqueEntityState,
  valeursToNormalize: ValeurEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristique.valeurs?.length && typeof societeCaracteristique.valeurs[0] !== 'number') {
    const idsValeurs: number[] = [];
    for (let i = 0; i < societeCaracteristique.valeurs.length; i++) {
      const valeur: Valeur | number = societeCaracteristique.valeurs[i];
      if (typeof valeur !== 'number') {
        valeursToNormalize.push({ ...valeur, societeCaracteristique: societeCaracteristique.idSocieteCaracteristique });
        idsValeurs.push(valeur.idValeur);
      } else {
        idsValeurs.push(valeur);
      }
    }
    relations['valeurs'] = idsValeurs;
  }
}

function societeCaracteristiqueNormalizeCampagneSocieteCaracteristique(
  societeCaracteristique: SocieteCaracteristiqueEntityState,
  campagneSocieteCaracteristiquesToNormalize: CampagneSocieteCaracteristiqueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristique.campagneSocieteCaracteristiques?.length && typeof societeCaracteristique.campagneSocieteCaracteristiques[0] !== 'number') {
    const idsCampagneSocieteCaracteristiques: number[] = [];
    for (let i = 0; i < societeCaracteristique.campagneSocieteCaracteristiques.length; i++) {
      const campagneSocieteCaracteristique: CampagneSocieteCaracteristique | number = societeCaracteristique.campagneSocieteCaracteristiques[i];
      if (typeof campagneSocieteCaracteristique !== 'number') {
        campagneSocieteCaracteristiquesToNormalize.push({ ...campagneSocieteCaracteristique, societeCaracteristique: societeCaracteristique.idSocieteCaracteristique });
        idsCampagneSocieteCaracteristiques.push(campagneSocieteCaracteristique.idCampagneSocieteCaracteristique);
      } else {
        idsCampagneSocieteCaracteristiques.push(campagneSocieteCaracteristique);
      }
    }
    relations['campagneSocieteCaracteristiques'] = idsCampagneSocieteCaracteristiques;
  }
}

function societeCaracteristiqueNormalizeCampagne(
  societeCaracteristique: SocieteCaracteristiqueEntityState,
  campagnesToNormalize: { [id: number]: CampagneEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristique.campagnes?.length && typeof societeCaracteristique.campagnes[0] !== 'number') {
    const idsCampagnes: number[] = [];
    for (let i = 0; i < societeCaracteristique.campagnes.length; i++) {
      const campagne: Campagne | number = societeCaracteristique.campagnes[i];

      if (typeof campagne !== 'number') {
        if (!campagnesToNormalize[campagne.idCampagne]) {
          campagnesToNormalize[campagne.idCampagne] = {
            ...campagne,
            societeCaracteristiques: campagne.societeCaracteristiques?.concat() ?? []
          };
        }
        (campagnesToNormalize[campagne.idCampagne].societeCaracteristiques as number[]).push(societeCaracteristique.idSocieteCaracteristique);
        idsCampagnes.push(campagne.idCampagne);
      } else {
        idsCampagnes.push(campagne);
      }
    }
    relations['campagnes'] = idsCampagnes;
  }
}

function societeCaracteristiqueNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(
  societeCaracteristique: SocieteCaracteristiqueEntityState,
  societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristique.societeConfigAnalyseSyntheseSocieteCaracteristiques?.length && typeof societeCaracteristique.societeConfigAnalyseSyntheseSocieteCaracteristiques[0] !== 'number') {
    const idsSocieteConfigAnalyseSyntheseSocieteCaracteristiques: number[] = [];
    for (let i = 0; i < societeCaracteristique.societeConfigAnalyseSyntheseSocieteCaracteristiques.length; i++) {
      const societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristique | number = societeCaracteristique.societeConfigAnalyseSyntheseSocieteCaracteristiques[i];
      if (typeof societeConfigAnalyseSyntheseSocieteCaracteristique !== 'number') {
        societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize.push({ ...societeConfigAnalyseSyntheseSocieteCaracteristique, societeCaracteristique: societeCaracteristique.idSocieteCaracteristique });
        idsSocieteConfigAnalyseSyntheseSocieteCaracteristiques.push(societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique);
      } else {
        idsSocieteConfigAnalyseSyntheseSocieteCaracteristiques.push(societeConfigAnalyseSyntheseSocieteCaracteristique);
      }
    }
    relations['societeConfigAnalyseSyntheseSocieteCaracteristiques'] = idsSocieteConfigAnalyseSyntheseSocieteCaracteristiques;
  }
}

function societeCaracteristiqueNormalizeSocieteConfigAnalyseSynthese(
  societeCaracteristique: SocieteCaracteristiqueEntityState,
  societeConfigAnalyseSynthesesToNormalize: { [id: number]: SocieteConfigAnalyseSyntheseEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristique.societeConfigAnalyseSyntheses?.length && typeof societeCaracteristique.societeConfigAnalyseSyntheses[0] !== 'number') {
    const idsSocieteConfigAnalyseSyntheses: number[] = [];
    for (let i = 0; i < societeCaracteristique.societeConfigAnalyseSyntheses.length; i++) {
      const societeConfigAnalyseSynthese: SocieteConfigAnalyseSynthese | number = societeCaracteristique.societeConfigAnalyseSyntheses[i];

      if (typeof societeConfigAnalyseSynthese !== 'number') {
        if (!societeConfigAnalyseSynthesesToNormalize[societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese]) {
          societeConfigAnalyseSynthesesToNormalize[societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese] = {
            ...societeConfigAnalyseSynthese,
            societeCaracteristiques: societeConfigAnalyseSynthese.societeCaracteristiques?.concat() ?? []
          };
        }
        (societeConfigAnalyseSynthesesToNormalize[societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese].societeCaracteristiques as number[]).push(societeCaracteristique.idSocieteCaracteristique);
        idsSocieteConfigAnalyseSyntheses.push(societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese);
      } else {
        idsSocieteConfigAnalyseSyntheses.push(societeConfigAnalyseSynthese);
      }
    }
    relations['societeConfigAnalyseSyntheses'] = idsSocieteConfigAnalyseSyntheses;
  }
}

function societeCaracteristiqueNormalizeSociete(
  societeCaracteristique: SocieteCaracteristiqueEntityState,
  societesToNormalize: { [id: number]: SocieteEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristique.societe && typeof societeCaracteristique.societe !== 'number') {
    if (!societesToNormalize[societeCaracteristique.societe.idSociete]) {
      societesToNormalize[societeCaracteristique.societe.idSociete] = {
        ...societeCaracteristique.societe,
        societeCaracteristiques: societeCaracteristique.societe.societeCaracteristiques?.concat() ?? []
      };
    }
    (societesToNormalize[societeCaracteristique.societe.idSociete].societeCaracteristiques as number[]).push(
      societeCaracteristique.idSocieteCaracteristique
    );
    relations['societe'] = societeCaracteristique.societe.idSociete;
  }
}

function societeCaracteristiqueNormalizeCaracteristiqueTemplate(
  societeCaracteristique: SocieteCaracteristiqueEntityState,
  caracteristiqueTemplatesToNormalize: { [id: number]: CaracteristiqueTemplateEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristique.caracteristiqueTemplate && typeof societeCaracteristique.caracteristiqueTemplate !== 'number') {
    if (!caracteristiqueTemplatesToNormalize[societeCaracteristique.caracteristiqueTemplate.idCaracteristiqueTemplate]) {
      caracteristiqueTemplatesToNormalize[societeCaracteristique.caracteristiqueTemplate.idCaracteristiqueTemplate] = {
        ...societeCaracteristique.caracteristiqueTemplate,
        societeCaracteristiques: societeCaracteristique.caracteristiqueTemplate.societeCaracteristiques?.concat() ?? []
      };
    }
    (caracteristiqueTemplatesToNormalize[societeCaracteristique.caracteristiqueTemplate.idCaracteristiqueTemplate].societeCaracteristiques as number[]).push(
      societeCaracteristique.idSocieteCaracteristique
    );
    relations['caracteristiqueTemplate'] = societeCaracteristique.caracteristiqueTemplate.idCaracteristiqueTemplate;
  }
}

function societeCaracteristiqueNormalizeSocieteComposant(
  societeCaracteristique: SocieteCaracteristiqueEntityState,
  societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristique.societeComposant && typeof societeCaracteristique.societeComposant !== 'number') {
    if (!societeComposantsToNormalize[societeCaracteristique.societeComposant.idSocieteComposant]) {
      societeComposantsToNormalize[societeCaracteristique.societeComposant.idSocieteComposant] = {
        ...societeCaracteristique.societeComposant,
        societeCaracteristiques: societeCaracteristique.societeComposant.societeCaracteristiques?.concat() ?? []
      };
    }
    (societeComposantsToNormalize[societeCaracteristique.societeComposant.idSocieteComposant].societeCaracteristiques as number[]).push(
      societeCaracteristique.idSocieteCaracteristique
    );
    relations['societeComposant'] = societeCaracteristique.societeComposant.idSocieteComposant;
  }
}

function societeCaracteristiqueNormalizeSocietePatrimoineHierarchie(
  societeCaracteristique: SocieteCaracteristiqueEntityState,
  societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristique.societePatrimoineHierarchie && typeof societeCaracteristique.societePatrimoineHierarchie !== 'number') {
    if (!societePatrimoineHierarchiesToNormalize[societeCaracteristique.societePatrimoineHierarchie.idSocietePatrimoineHierarchie]) {
      societePatrimoineHierarchiesToNormalize[societeCaracteristique.societePatrimoineHierarchie.idSocietePatrimoineHierarchie] = {
        ...societeCaracteristique.societePatrimoineHierarchie,
        societeCaracteristiques: societeCaracteristique.societePatrimoineHierarchie.societeCaracteristiques?.concat() ?? []
      };
    }
    (societePatrimoineHierarchiesToNormalize[societeCaracteristique.societePatrimoineHierarchie.idSocietePatrimoineHierarchie].societeCaracteristiques as number[]).push(
      societeCaracteristique.idSocieteCaracteristique
    );
    relations['societePatrimoineHierarchie'] = societeCaracteristique.societePatrimoineHierarchie.idSocietePatrimoineHierarchie;
  }
}

/**
 *  SocieteCaracteristiqueChoix
 */
export function getActionsToNormalizeSocieteCaracteristiqueChoix(
  societeCaracteristiqueChoices: SocieteCaracteristiqueChoixEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const caracteristiqueChoixTemplatesToNormalize: { [id: number]: CaracteristiqueChoixTemplateEntityState } = {};
  const valeursToNormalize: ValeurEntityState[] = [];

  const normalizedSocieteCaracteristiqueChoices = societeCaracteristiqueChoices.map((societeCaracteristiqueChoix: SocieteCaracteristiqueChoixEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeCaracteristiqueChoixNormalizeValeur(societeCaracteristiqueChoix, valeursToNormalize, relations);
    societeCaracteristiqueChoixNormalizeSocieteCaracteristique(societeCaracteristiqueChoix, societeCaracteristiquesToNormalize, relations);
    societeCaracteristiqueChoixNormalizeFichier(societeCaracteristiqueChoix, fichiersToNormalize, relations);
    societeCaracteristiqueChoixNormalizeCaracteristiqueChoixTemplate(societeCaracteristiqueChoix, caracteristiqueChoixTemplatesToNormalize, relations);

    return {
      ...societeCaracteristiqueChoix,
      ...relations
    };
  });
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  const caracteristiqueChoixTemplatesToNormalizeArray = Object.values(caracteristiqueChoixTemplatesToNormalize);
  if (caracteristiqueChoixTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCaracteristiqueChoixTemplate(caracteristiqueChoixTemplatesToNormalizeArray, actionType));
  }
  if (valeursToNormalize.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalize, actionType));
  }

  const societeCaracteristiqueChoixActionCreator = SocieteCaracteristiqueChoixGeneratedActions.upsertManySocieteCaracteristiqueChoicesSuccess;
  actions.push(societeCaracteristiqueChoixActionCreator({ societeCaracteristiqueChoices: normalizedSocieteCaracteristiqueChoices }));

  return actions;
}

function societeCaracteristiqueChoixNormalizeValeur(
  societeCaracteristiqueChoix: SocieteCaracteristiqueChoixEntityState,
  valeursToNormalize: ValeurEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristiqueChoix.valeurs?.length && typeof societeCaracteristiqueChoix.valeurs[0] !== 'number') {
    const idsValeurs: number[] = [];
    for (let i = 0; i < societeCaracteristiqueChoix.valeurs.length; i++) {
      const valeur: Valeur | number = societeCaracteristiqueChoix.valeurs[i];
      if (typeof valeur !== 'number') {
        valeursToNormalize.push({ ...valeur, societeCaracteristiqueChoix: societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix });
        idsValeurs.push(valeur.idValeur);
      } else {
        idsValeurs.push(valeur);
      }
    }
    relations['valeurs'] = idsValeurs;
  }
}

function societeCaracteristiqueChoixNormalizeSocieteCaracteristique(
  societeCaracteristiqueChoix: SocieteCaracteristiqueChoixEntityState,
  societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristiqueChoix.societeCaracteristique && typeof societeCaracteristiqueChoix.societeCaracteristique !== 'number') {
    if (!societeCaracteristiquesToNormalize[societeCaracteristiqueChoix.societeCaracteristique.idSocieteCaracteristique]) {
      societeCaracteristiquesToNormalize[societeCaracteristiqueChoix.societeCaracteristique.idSocieteCaracteristique] = {
        ...societeCaracteristiqueChoix.societeCaracteristique,
        societeCaracteristiqueChoices: societeCaracteristiqueChoix.societeCaracteristique.societeCaracteristiqueChoices?.concat() ?? []
      };
    }
    (societeCaracteristiquesToNormalize[societeCaracteristiqueChoix.societeCaracteristique.idSocieteCaracteristique].societeCaracteristiqueChoices as number[]).push(
      societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix
    );
    relations['societeCaracteristique'] = societeCaracteristiqueChoix.societeCaracteristique.idSocieteCaracteristique;
  }
}

function societeCaracteristiqueChoixNormalizeFichier(
  societeCaracteristiqueChoix: SocieteCaracteristiqueChoixEntityState,
  fichiersToNormalize: { [id: number]: FichierEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristiqueChoix.fichier && typeof societeCaracteristiqueChoix.fichier !== 'number') {
    if (!fichiersToNormalize[societeCaracteristiqueChoix.fichier.idFichier]) {
      fichiersToNormalize[societeCaracteristiqueChoix.fichier.idFichier] = {
        ...societeCaracteristiqueChoix.fichier,
        societeCaracteristiqueChoices: societeCaracteristiqueChoix.fichier.societeCaracteristiqueChoices?.concat() ?? []
      };
    }
    (fichiersToNormalize[societeCaracteristiqueChoix.fichier.idFichier].societeCaracteristiqueChoices as number[]).push(
      societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix
    );
    relations['fichier'] = societeCaracteristiqueChoix.fichier.idFichier;
  }
}

function societeCaracteristiqueChoixNormalizeCaracteristiqueChoixTemplate(
  societeCaracteristiqueChoix: SocieteCaracteristiqueChoixEntityState,
  caracteristiqueChoixTemplatesToNormalize: { [id: number]: CaracteristiqueChoixTemplateEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeCaracteristiqueChoix.caracteristiqueChoixTemplate && typeof societeCaracteristiqueChoix.caracteristiqueChoixTemplate !== 'number') {
    if (!caracteristiqueChoixTemplatesToNormalize[societeCaracteristiqueChoix.caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate]) {
      caracteristiqueChoixTemplatesToNormalize[societeCaracteristiqueChoix.caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate] = {
        ...societeCaracteristiqueChoix.caracteristiqueChoixTemplate,
        societeCaracteristiqueChoices: societeCaracteristiqueChoix.caracteristiqueChoixTemplate.societeCaracteristiqueChoices?.concat() ?? []
      };
    }
    (caracteristiqueChoixTemplatesToNormalize[societeCaracteristiqueChoix.caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate].societeCaracteristiqueChoices as number[]).push(
      societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix
    );
    relations['caracteristiqueChoixTemplate'] = societeCaracteristiqueChoix.caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate;
  }
}

/**
 *  SocieteConfigAnalyseSynthese
 */
export function getActionsToNormalizeSocieteConfigAnalyseSynthese(
  societeConfigAnalyseSyntheses: SocieteConfigAnalyseSyntheseEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState[] = [];
  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};

  const normalizedSocieteConfigAnalyseSyntheses = societeConfigAnalyseSyntheses.map((societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeConfigAnalyseSyntheseNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeConfigAnalyseSynthese, societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize, relations);
    societeConfigAnalyseSyntheseNormalizeSocieteCaracteristique(societeConfigAnalyseSynthese, societeCaracteristiquesToNormalize, relations);
    societeConfigAnalyseSyntheseNormalizeSociete(societeConfigAnalyseSynthese, societesToNormalize, relations);
    societeConfigAnalyseSyntheseNormalizeUser(societeConfigAnalyseSynthese, usersToNormalize, relations);

    return {
      ...societeConfigAnalyseSynthese,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize, actionType));
  }
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }

  const societeConfigAnalyseSyntheseActionCreator = SocieteConfigAnalyseSyntheseGeneratedActions.upsertManySocieteConfigAnalyseSynthesesSuccess;
  actions.push(societeConfigAnalyseSyntheseActionCreator({ societeConfigAnalyseSyntheses: normalizedSocieteConfigAnalyseSyntheses }));

  return actions;
}

function societeConfigAnalyseSyntheseNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(
  societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseEntityState,
  societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeConfigAnalyseSynthese.societeConfigAnalyseSyntheseSocieteCaracteristiques?.length && typeof societeConfigAnalyseSynthese.societeConfigAnalyseSyntheseSocieteCaracteristiques[0] !== 'number') {
    const idsSocieteConfigAnalyseSyntheseSocieteCaracteristiques: number[] = [];
    for (let i = 0; i < societeConfigAnalyseSynthese.societeConfigAnalyseSyntheseSocieteCaracteristiques.length; i++) {
      const societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristique | number = societeConfigAnalyseSynthese.societeConfigAnalyseSyntheseSocieteCaracteristiques[i];
      if (typeof societeConfigAnalyseSyntheseSocieteCaracteristique !== 'number') {
        societeConfigAnalyseSyntheseSocieteCaracteristiquesToNormalize.push({ ...societeConfigAnalyseSyntheseSocieteCaracteristique, societeConfigAnalyseSynthese: societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese });
        idsSocieteConfigAnalyseSyntheseSocieteCaracteristiques.push(societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique);
      } else {
        idsSocieteConfigAnalyseSyntheseSocieteCaracteristiques.push(societeConfigAnalyseSyntheseSocieteCaracteristique);
      }
    }
    relations['societeConfigAnalyseSyntheseSocieteCaracteristiques'] = idsSocieteConfigAnalyseSyntheseSocieteCaracteristiques;
  }
}

function societeConfigAnalyseSyntheseNormalizeSocieteCaracteristique(
  societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseEntityState,
  societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeConfigAnalyseSynthese.societeCaracteristiques?.length && typeof societeConfigAnalyseSynthese.societeCaracteristiques[0] !== 'number') {
    const idsSocieteCaracteristiques: number[] = [];
    for (let i = 0; i < societeConfigAnalyseSynthese.societeCaracteristiques.length; i++) {
      const societeCaracteristique: SocieteCaracteristique | number = societeConfigAnalyseSynthese.societeCaracteristiques[i];

      if (typeof societeCaracteristique !== 'number') {
        if (!societeCaracteristiquesToNormalize[societeCaracteristique.idSocieteCaracteristique]) {
          societeCaracteristiquesToNormalize[societeCaracteristique.idSocieteCaracteristique] = {
            ...societeCaracteristique,
            societeConfigAnalyseSyntheses: societeCaracteristique.societeConfigAnalyseSyntheses?.concat() ?? []
          };
        }
        (societeCaracteristiquesToNormalize[societeCaracteristique.idSocieteCaracteristique].societeConfigAnalyseSyntheses as number[]).push(societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese);
        idsSocieteCaracteristiques.push(societeCaracteristique.idSocieteCaracteristique);
      } else {
        idsSocieteCaracteristiques.push(societeCaracteristique);
      }
    }
    relations['societeCaracteristiques'] = idsSocieteCaracteristiques;
  }
}

function societeConfigAnalyseSyntheseNormalizeSociete(
  societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseEntityState,
  societesToNormalize: { [id: number]: SocieteEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeConfigAnalyseSynthese.societe && typeof societeConfigAnalyseSynthese.societe !== 'number') {
    if (!societesToNormalize[societeConfigAnalyseSynthese.societe.idSociete]) {
      societesToNormalize[societeConfigAnalyseSynthese.societe.idSociete] = {
        ...societeConfigAnalyseSynthese.societe,
        societeConfigAnalyseSyntheses: societeConfigAnalyseSynthese.societe.societeConfigAnalyseSyntheses?.concat() ?? []
      };
    }
    (societesToNormalize[societeConfigAnalyseSynthese.societe.idSociete].societeConfigAnalyseSyntheses as number[]).push(
      societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese
    );
    relations['societe'] = societeConfigAnalyseSynthese.societe.idSociete;
  }
}

function societeConfigAnalyseSyntheseNormalizeUser(
  societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeConfigAnalyseSynthese.user && typeof societeConfigAnalyseSynthese.user !== 'number') {
    if (!usersToNormalize[societeConfigAnalyseSynthese.user.idUser]) {
      usersToNormalize[societeConfigAnalyseSynthese.user.idUser] = {
        ...societeConfigAnalyseSynthese.user,
        societeConfigAnalyseSyntheses: societeConfigAnalyseSynthese.user.societeConfigAnalyseSyntheses?.concat() ?? []
      };
    }
    (usersToNormalize[societeConfigAnalyseSynthese.user.idUser].societeConfigAnalyseSyntheses as number[]).push(
      societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese
    );
    relations['user'] = societeConfigAnalyseSynthese.user.idUser;
  }
}

/**
 *  SocieteConfigAnalyseSyntheseSocieteCaracteristique
 */
export function getActionsToNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(
  societeConfigAnalyseSyntheseSocieteCaracteristiques: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societeConfigAnalyseSynthesesToNormalize: { [id: number]: SocieteConfigAnalyseSyntheseEntityState } = {};
  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};

  const normalizedSocieteConfigAnalyseSyntheseSocieteCaracteristiques = societeConfigAnalyseSyntheseSocieteCaracteristiques.map((societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeConfigAnalyseSyntheseSocieteCaracteristiqueNormalizeSocieteConfigAnalyseSynthese(societeConfigAnalyseSyntheseSocieteCaracteristique, societeConfigAnalyseSynthesesToNormalize, relations);
    societeConfigAnalyseSyntheseSocieteCaracteristiqueNormalizeSocieteCaracteristique(societeConfigAnalyseSyntheseSocieteCaracteristique, societeCaracteristiquesToNormalize, relations);

    return {
      ...societeConfigAnalyseSyntheseSocieteCaracteristique,
      ...relations
    };
  });
  const societeConfigAnalyseSynthesesToNormalizeArray = Object.values(societeConfigAnalyseSynthesesToNormalize);
  if (societeConfigAnalyseSynthesesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthesesToNormalizeArray, actionType));
  }
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }

  const societeConfigAnalyseSyntheseSocieteCaracteristiqueActionCreator = SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.upsertManySocieteConfigAnalyseSyntheseSocieteCaracteristiquesSuccess;
  actions.push(societeConfigAnalyseSyntheseSocieteCaracteristiqueActionCreator({ societeConfigAnalyseSyntheseSocieteCaracteristiques: normalizedSocieteConfigAnalyseSyntheseSocieteCaracteristiques }));

  return actions;
}

function societeConfigAnalyseSyntheseSocieteCaracteristiqueNormalizeSocieteConfigAnalyseSynthese(
  societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState,
  societeConfigAnalyseSynthesesToNormalize: { [id: number]: SocieteConfigAnalyseSyntheseEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeConfigAnalyseSyntheseSocieteCaracteristique.societeConfigAnalyseSynthese && typeof societeConfigAnalyseSyntheseSocieteCaracteristique.societeConfigAnalyseSynthese !== 'number') {
    if (!societeConfigAnalyseSynthesesToNormalize[societeConfigAnalyseSyntheseSocieteCaracteristique.societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese]) {
      societeConfigAnalyseSynthesesToNormalize[societeConfigAnalyseSyntheseSocieteCaracteristique.societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese] = {
        ...societeConfigAnalyseSyntheseSocieteCaracteristique.societeConfigAnalyseSynthese,
        societeConfigAnalyseSyntheseSocieteCaracteristiques: societeConfigAnalyseSyntheseSocieteCaracteristique.societeConfigAnalyseSynthese.societeConfigAnalyseSyntheseSocieteCaracteristiques?.concat() ?? []
      };
    }
    (societeConfigAnalyseSynthesesToNormalize[societeConfigAnalyseSyntheseSocieteCaracteristique.societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese].societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]).push(
      societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique
    );
    relations['societeConfigAnalyseSynthese'] = societeConfigAnalyseSyntheseSocieteCaracteristique.societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese;
  }
}

function societeConfigAnalyseSyntheseSocieteCaracteristiqueNormalizeSocieteCaracteristique(
  societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState,
  societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeConfigAnalyseSyntheseSocieteCaracteristique.societeCaracteristique && typeof societeConfigAnalyseSyntheseSocieteCaracteristique.societeCaracteristique !== 'number') {
    if (!societeCaracteristiquesToNormalize[societeConfigAnalyseSyntheseSocieteCaracteristique.societeCaracteristique.idSocieteCaracteristique]) {
      societeCaracteristiquesToNormalize[societeConfigAnalyseSyntheseSocieteCaracteristique.societeCaracteristique.idSocieteCaracteristique] = {
        ...societeConfigAnalyseSyntheseSocieteCaracteristique.societeCaracteristique,
        societeConfigAnalyseSyntheseSocieteCaracteristiques: societeConfigAnalyseSyntheseSocieteCaracteristique.societeCaracteristique.societeConfigAnalyseSyntheseSocieteCaracteristiques?.concat() ?? []
      };
    }
    (societeCaracteristiquesToNormalize[societeConfigAnalyseSyntheseSocieteCaracteristique.societeCaracteristique.idSocieteCaracteristique].societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]).push(
      societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique
    );
    relations['societeCaracteristique'] = societeConfigAnalyseSyntheseSocieteCaracteristique.societeCaracteristique.idSocieteCaracteristique;
  }
}

/**
 *  SocieteComposant
 */
export function getActionsToNormalizeSocieteComposant(
  societeComposants: SocieteComposantEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const composantTemplatesToNormalize: { [id: number]: ComposantTemplateEntityState } = {};
  const societeComposantFamillesToNormalize: { [id: number]: SocieteComposantFamilleEntityState } = {};
  const composantGroupesToNormalize: { [id: number]: ComposantGroupeEntityState } = {};
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[] = [];
  const composantsToNormalize: ComposantEntityState[] = [];
  const composantAttendusToNormalize: ComposantAttenduEntityState[] = [];
  const societeComposantRattachementsToNormalize: SocieteComposantRattachementEntityState[] = [];

  const normalizedSocieteComposants = societeComposants.map((societeComposant: SocieteComposantEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeComposantNormalizeSocieteCaracteristique(societeComposant, societeCaracteristiquesToNormalize, relations);
    societeComposantNormalizeComposant(societeComposant, composantsToNormalize, relations);
    societeComposantNormalizeComposantAttendu(societeComposant, composantAttendusToNormalize, relations);
    societeComposantNormalizeSocieteComposantRattachement(societeComposant, societeComposantRattachementsToNormalize, relations);
    societeComposantNormalizeSociete(societeComposant, societesToNormalize, relations);
    societeComposantNormalizeComposantTemplate(societeComposant, composantTemplatesToNormalize, relations);
    societeComposantNormalizeSocieteComposantFamille(societeComposant, societeComposantFamillesToNormalize, relations);
    societeComposantNormalizeComposantGroupe(societeComposant, composantGroupesToNormalize, relations);
    societeComposantNormalizeFichier(societeComposant, fichiersToNormalize, relations);

    return {
      ...societeComposant,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const composantTemplatesToNormalizeArray = Object.values(composantTemplatesToNormalize);
  if (composantTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposantTemplate(composantTemplatesToNormalizeArray, actionType));
  }
  const societeComposantFamillesToNormalizeArray = Object.values(societeComposantFamillesToNormalize);
  if (societeComposantFamillesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteComposantFamille(societeComposantFamillesToNormalizeArray, actionType));
  }
  const composantGroupesToNormalizeArray = Object.values(composantGroupesToNormalize);
  if (composantGroupesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposantGroupe(composantGroupesToNormalizeArray, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  if (societeCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalize, actionType));
  }
  if (composantsToNormalize.length) {
    actions.push(...getActionsToNormalizeComposant(composantsToNormalize, actionType));
  }
  if (composantAttendusToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantAttendu(composantAttendusToNormalize, actionType));
  }
  if (societeComposantRattachementsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposantRattachement(societeComposantRattachementsToNormalize, actionType));
  }

  const societeComposantActionCreator = SocieteComposantGeneratedActions.upsertManySocieteComposantsSuccess;
  actions.push(societeComposantActionCreator({ societeComposants: normalizedSocieteComposants }));

  return actions;
}

function societeComposantNormalizeSocieteCaracteristique(
  societeComposant: SocieteComposantEntityState,
  societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposant.societeCaracteristiques?.length && typeof societeComposant.societeCaracteristiques[0] !== 'number') {
    const idsSocieteCaracteristiques: number[] = [];
    for (let i = 0; i < societeComposant.societeCaracteristiques.length; i++) {
      const societeCaracteristique: SocieteCaracteristique | number = societeComposant.societeCaracteristiques[i];
      if (typeof societeCaracteristique !== 'number') {
        societeCaracteristiquesToNormalize.push({ ...societeCaracteristique, societeComposant: societeComposant.idSocieteComposant });
        idsSocieteCaracteristiques.push(societeCaracteristique.idSocieteCaracteristique);
      } else {
        idsSocieteCaracteristiques.push(societeCaracteristique);
      }
    }
    relations['societeCaracteristiques'] = idsSocieteCaracteristiques;
  }
}

function societeComposantNormalizeComposant(
  societeComposant: SocieteComposantEntityState,
  composantsToNormalize: ComposantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposant.composants?.length && typeof societeComposant.composants[0] !== 'number') {
    const idsComposants: number[] = [];
    for (let i = 0; i < societeComposant.composants.length; i++) {
      const composant: Composant | number = societeComposant.composants[i];
      if (typeof composant !== 'number') {
        composantsToNormalize.push({ ...composant, societeComposant: societeComposant.idSocieteComposant });
        idsComposants.push(composant.idComposant);
      } else {
        idsComposants.push(composant);
      }
    }
    relations['composants'] = idsComposants;
  }
}

function societeComposantNormalizeComposantAttendu(
  societeComposant: SocieteComposantEntityState,
  composantAttendusToNormalize: ComposantAttenduEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposant.composantAttendus?.length && typeof societeComposant.composantAttendus[0] !== 'number') {
    const idsComposantAttendus: number[] = [];
    for (let i = 0; i < societeComposant.composantAttendus.length; i++) {
      const composantAttendu: ComposantAttendu | number = societeComposant.composantAttendus[i];
      if (typeof composantAttendu !== 'number') {
        composantAttendusToNormalize.push({ ...composantAttendu, societeComposant: societeComposant.idSocieteComposant });
        idsComposantAttendus.push(composantAttendu.idComposantAttendu);
      } else {
        idsComposantAttendus.push(composantAttendu);
      }
    }
    relations['composantAttendus'] = idsComposantAttendus;
  }
}

function societeComposantNormalizeSocieteComposantRattachement(
  societeComposant: SocieteComposantEntityState,
  societeComposantRattachementsToNormalize: SocieteComposantRattachementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposant.societeComposantRattachements?.length && typeof societeComposant.societeComposantRattachements[0] !== 'number') {
    const idsSocieteComposantRattachements: number[] = [];
    for (let i = 0; i < societeComposant.societeComposantRattachements.length; i++) {
      const societeComposantRattachement: SocieteComposantRattachement | number = societeComposant.societeComposantRattachements[i];
      if (typeof societeComposantRattachement !== 'number') {
        societeComposantRattachementsToNormalize.push({ ...societeComposantRattachement, societeComposant: societeComposant.idSocieteComposant });
        idsSocieteComposantRattachements.push(societeComposantRattachement.idSocieteComposantRattachement);
      } else {
        idsSocieteComposantRattachements.push(societeComposantRattachement);
      }
    }
    relations['societeComposantRattachements'] = idsSocieteComposantRattachements;
  }
}

function societeComposantNormalizeSociete(
  societeComposant: SocieteComposantEntityState,
  societesToNormalize: { [id: number]: SocieteEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposant.societe && typeof societeComposant.societe !== 'number') {
    if (!societesToNormalize[societeComposant.societe.idSociete]) {
      societesToNormalize[societeComposant.societe.idSociete] = {
        ...societeComposant.societe,
        societeComposants: societeComposant.societe.societeComposants?.concat() ?? []
      };
    }
    (societesToNormalize[societeComposant.societe.idSociete].societeComposants as number[]).push(
      societeComposant.idSocieteComposant
    );
    relations['societe'] = societeComposant.societe.idSociete;
  }
}

function societeComposantNormalizeComposantTemplate(
  societeComposant: SocieteComposantEntityState,
  composantTemplatesToNormalize: { [id: number]: ComposantTemplateEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposant.composantTemplate && typeof societeComposant.composantTemplate !== 'number') {
    if (!composantTemplatesToNormalize[societeComposant.composantTemplate.idComposantTemplate]) {
      composantTemplatesToNormalize[societeComposant.composantTemplate.idComposantTemplate] = {
        ...societeComposant.composantTemplate,
        societeComposants: societeComposant.composantTemplate.societeComposants?.concat() ?? []
      };
    }
    (composantTemplatesToNormalize[societeComposant.composantTemplate.idComposantTemplate].societeComposants as number[]).push(
      societeComposant.idSocieteComposant
    );
    relations['composantTemplate'] = societeComposant.composantTemplate.idComposantTemplate;
  }
}

function societeComposantNormalizeSocieteComposantFamille(
  societeComposant: SocieteComposantEntityState,
  societeComposantFamillesToNormalize: { [id: number]: SocieteComposantFamilleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposant.societeComposantFamille && typeof societeComposant.societeComposantFamille !== 'number') {
    if (!societeComposantFamillesToNormalize[societeComposant.societeComposantFamille.idSocieteComposantFamille]) {
      societeComposantFamillesToNormalize[societeComposant.societeComposantFamille.idSocieteComposantFamille] = {
        ...societeComposant.societeComposantFamille,
        societeComposants: societeComposant.societeComposantFamille.societeComposants?.concat() ?? []
      };
    }
    (societeComposantFamillesToNormalize[societeComposant.societeComposantFamille.idSocieteComposantFamille].societeComposants as number[]).push(
      societeComposant.idSocieteComposant
    );
    relations['societeComposantFamille'] = societeComposant.societeComposantFamille.idSocieteComposantFamille;
  }
}

function societeComposantNormalizeComposantGroupe(
  societeComposant: SocieteComposantEntityState,
  composantGroupesToNormalize: { [id: number]: ComposantGroupeEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposant.composantGroupe && typeof societeComposant.composantGroupe !== 'number') {
    if (!composantGroupesToNormalize[societeComposant.composantGroupe.idComposantGroupe]) {
      composantGroupesToNormalize[societeComposant.composantGroupe.idComposantGroupe] = {
        ...societeComposant.composantGroupe,
        societeComposants: societeComposant.composantGroupe.societeComposants?.concat() ?? []
      };
    }
    (composantGroupesToNormalize[societeComposant.composantGroupe.idComposantGroupe].societeComposants as number[]).push(
      societeComposant.idSocieteComposant
    );
    relations['composantGroupe'] = societeComposant.composantGroupe.idComposantGroupe;
  }
}

function societeComposantNormalizeFichier(
  societeComposant: SocieteComposantEntityState,
  fichiersToNormalize: { [id: number]: FichierEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposant.fichier && typeof societeComposant.fichier !== 'number') {
    if (!fichiersToNormalize[societeComposant.fichier.idFichier]) {
      fichiersToNormalize[societeComposant.fichier.idFichier] = {
        ...societeComposant.fichier,
        societeComposants: societeComposant.fichier.societeComposants?.concat() ?? []
      };
    }
    (fichiersToNormalize[societeComposant.fichier.idFichier].societeComposants as number[]).push(
      societeComposant.idSocieteComposant
    );
    relations['fichier'] = societeComposant.fichier.idFichier;
  }
}

/**
 *  SocieteComposantFamille
 */
export function getActionsToNormalizeSocieteComposantFamille(
  societeComposantFamilles: SocieteComposantFamilleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};
  const societeComposantsToNormalize: SocieteComposantEntityState[] = [];

  const normalizedSocieteComposantFamilles = societeComposantFamilles.map((societeComposantFamille: SocieteComposantFamilleEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeComposantFamilleNormalizeSocieteComposant(societeComposantFamille, societeComposantsToNormalize, relations);
    societeComposantFamilleNormalizeSociete(societeComposantFamille, societesToNormalize, relations);
    societeComposantFamilleNormalizeFichier(societeComposantFamille, fichiersToNormalize, relations);

    return {
      ...societeComposantFamille,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }
  if (societeComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalize, actionType));
  }

  const societeComposantFamilleActionCreator = SocieteComposantFamilleGeneratedActions.upsertManySocieteComposantFamillesSuccess;
  actions.push(societeComposantFamilleActionCreator({ societeComposantFamilles: normalizedSocieteComposantFamilles }));

  return actions;
}

function societeComposantFamilleNormalizeSocieteComposant(
  societeComposantFamille: SocieteComposantFamilleEntityState,
  societeComposantsToNormalize: SocieteComposantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposantFamille.societeComposants?.length && typeof societeComposantFamille.societeComposants[0] !== 'number') {
    const idsSocieteComposants: number[] = [];
    for (let i = 0; i < societeComposantFamille.societeComposants.length; i++) {
      const societeComposant: SocieteComposant | number = societeComposantFamille.societeComposants[i];
      if (typeof societeComposant !== 'number') {
        societeComposantsToNormalize.push({ ...societeComposant, societeComposantFamille: societeComposantFamille.idSocieteComposantFamille });
        idsSocieteComposants.push(societeComposant.idSocieteComposant);
      } else {
        idsSocieteComposants.push(societeComposant);
      }
    }
    relations['societeComposants'] = idsSocieteComposants;
  }
}

function societeComposantFamilleNormalizeSociete(
  societeComposantFamille: SocieteComposantFamilleEntityState,
  societesToNormalize: { [id: number]: SocieteEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposantFamille.societe && typeof societeComposantFamille.societe !== 'number') {
    if (!societesToNormalize[societeComposantFamille.societe.idSociete]) {
      societesToNormalize[societeComposantFamille.societe.idSociete] = {
        ...societeComposantFamille.societe,
        societeComposantFamilles: societeComposantFamille.societe.societeComposantFamilles?.concat() ?? []
      };
    }
    (societesToNormalize[societeComposantFamille.societe.idSociete].societeComposantFamilles as number[]).push(
      societeComposantFamille.idSocieteComposantFamille
    );
    relations['societe'] = societeComposantFamille.societe.idSociete;
  }
}

function societeComposantFamilleNormalizeFichier(
  societeComposantFamille: SocieteComposantFamilleEntityState,
  fichiersToNormalize: { [id: number]: FichierEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposantFamille.fichier && typeof societeComposantFamille.fichier !== 'number') {
    if (!fichiersToNormalize[societeComposantFamille.fichier.idFichier]) {
      fichiersToNormalize[societeComposantFamille.fichier.idFichier] = {
        ...societeComposantFamille.fichier,
        societeComposantFamilles: societeComposantFamille.fichier.societeComposantFamilles?.concat() ?? []
      };
    }
    (fichiersToNormalize[societeComposantFamille.fichier.idFichier].societeComposantFamilles as number[]).push(
      societeComposantFamille.idSocieteComposantFamille
    );
    relations['fichier'] = societeComposantFamille.fichier.idFichier;
  }
}

/**
 *  SocieteComposantRattachement
 */
export function getActionsToNormalizeSocieteComposantRattachement(
  societeComposantRattachements: SocieteComposantRattachementEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState } = {};
  const societeEspaceFamillesToNormalize: { [id: number]: SocieteEspaceFamilleEntityState } = {};
  const societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};

  const normalizedSocieteComposantRattachements = societeComposantRattachements.map((societeComposantRattachement: SocieteComposantRattachementEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeComposantRattachementNormalizeSocieteComposant(societeComposantRattachement, societeComposantsToNormalize, relations);
    societeComposantRattachementNormalizeSocieteEspaceFamille(societeComposantRattachement, societeEspaceFamillesToNormalize, relations);
    societeComposantRattachementNormalizeSocietePatrimoineHierarchie(societeComposantRattachement, societePatrimoineHierarchiesToNormalize, relations);

    return {
      ...societeComposantRattachement,
      ...relations
    };
  });
  const societeComposantsToNormalizeArray = Object.values(societeComposantsToNormalize);
  if (societeComposantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteComposant(societeComposantsToNormalizeArray, actionType));
  }
  const societeEspaceFamillesToNormalizeArray = Object.values(societeEspaceFamillesToNormalize);
  if (societeEspaceFamillesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteEspaceFamille(societeEspaceFamillesToNormalizeArray, actionType));
  }
  const societePatrimoineHierarchiesToNormalizeArray = Object.values(societePatrimoineHierarchiesToNormalize);
  if (societePatrimoineHierarchiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalizeArray, actionType));
  }

  const societeComposantRattachementActionCreator = SocieteComposantRattachementGeneratedActions.upsertManySocieteComposantRattachementsSuccess;
  actions.push(societeComposantRattachementActionCreator({ societeComposantRattachements: normalizedSocieteComposantRattachements }));

  return actions;
}

function societeComposantRattachementNormalizeSocieteComposant(
  societeComposantRattachement: SocieteComposantRattachementEntityState,
  societeComposantsToNormalize: { [id: number]: SocieteComposantEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposantRattachement.societeComposant && typeof societeComposantRattachement.societeComposant !== 'number') {
    if (!societeComposantsToNormalize[societeComposantRattachement.societeComposant.idSocieteComposant]) {
      societeComposantsToNormalize[societeComposantRattachement.societeComposant.idSocieteComposant] = {
        ...societeComposantRattachement.societeComposant,
        societeComposantRattachements: societeComposantRattachement.societeComposant.societeComposantRattachements?.concat() ?? []
      };
    }
    (societeComposantsToNormalize[societeComposantRattachement.societeComposant.idSocieteComposant].societeComposantRattachements as number[]).push(
      societeComposantRattachement.idSocieteComposantRattachement
    );
    relations['societeComposant'] = societeComposantRattachement.societeComposant.idSocieteComposant;
  }
}

function societeComposantRattachementNormalizeSocieteEspaceFamille(
  societeComposantRattachement: SocieteComposantRattachementEntityState,
  societeEspaceFamillesToNormalize: { [id: number]: SocieteEspaceFamilleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposantRattachement.societeEspaceFamille && typeof societeComposantRattachement.societeEspaceFamille !== 'number') {
    if (!societeEspaceFamillesToNormalize[societeComposantRattachement.societeEspaceFamille.idSocieteEspaceFamille]) {
      societeEspaceFamillesToNormalize[societeComposantRattachement.societeEspaceFamille.idSocieteEspaceFamille] = {
        ...societeComposantRattachement.societeEspaceFamille,
        societeComposantRattachements: societeComposantRattachement.societeEspaceFamille.societeComposantRattachements?.concat() ?? []
      };
    }
    (societeEspaceFamillesToNormalize[societeComposantRattachement.societeEspaceFamille.idSocieteEspaceFamille].societeComposantRattachements as number[]).push(
      societeComposantRattachement.idSocieteComposantRattachement
    );
    relations['societeEspaceFamille'] = societeComposantRattachement.societeEspaceFamille.idSocieteEspaceFamille;
  }
}

function societeComposantRattachementNormalizeSocietePatrimoineHierarchie(
  societeComposantRattachement: SocieteComposantRattachementEntityState,
  societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeComposantRattachement.societePatrimoineHierarchie && typeof societeComposantRattachement.societePatrimoineHierarchie !== 'number') {
    if (!societePatrimoineHierarchiesToNormalize[societeComposantRattachement.societePatrimoineHierarchie.idSocietePatrimoineHierarchie]) {
      societePatrimoineHierarchiesToNormalize[societeComposantRattachement.societePatrimoineHierarchie.idSocietePatrimoineHierarchie] = {
        ...societeComposantRattachement.societePatrimoineHierarchie,
        societeComposantRattachements: societeComposantRattachement.societePatrimoineHierarchie.societeComposantRattachements?.concat() ?? []
      };
    }
    (societePatrimoineHierarchiesToNormalize[societeComposantRattachement.societePatrimoineHierarchie.idSocietePatrimoineHierarchie].societeComposantRattachements as number[]).push(
      societeComposantRattachement.idSocieteComposantRattachement
    );
    relations['societePatrimoineHierarchie'] = societeComposantRattachement.societePatrimoineHierarchie.idSocietePatrimoineHierarchie;
  }
}

/**
 *  SocieteEspace
 */
export function getActionsToNormalizeSocieteEspace(
  societeEspaces: SocieteEspaceEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societeEspaceFamillesToNormalize: { [id: number]: SocieteEspaceFamilleEntityState } = {};
  const espacesToNormalize: EspaceEntityState[] = [];

  const normalizedSocieteEspaces = societeEspaces.map((societeEspace: SocieteEspaceEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeEspaceNormalizeEspace(societeEspace, espacesToNormalize, relations);
    societeEspaceNormalizeSocieteEspaceFamille(societeEspace, societeEspaceFamillesToNormalize, relations);

    return {
      ...societeEspace,
      ...relations
    };
  });
  const societeEspaceFamillesToNormalizeArray = Object.values(societeEspaceFamillesToNormalize);
  if (societeEspaceFamillesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteEspaceFamille(societeEspaceFamillesToNormalizeArray, actionType));
  }
  if (espacesToNormalize.length) {
    actions.push(...getActionsToNormalizeEspace(espacesToNormalize, actionType));
  }

  const societeEspaceActionCreator = SocieteEspaceGeneratedActions.upsertManySocieteEspacesSuccess;
  actions.push(societeEspaceActionCreator({ societeEspaces: normalizedSocieteEspaces }));

  return actions;
}

function societeEspaceNormalizeEspace(
  societeEspace: SocieteEspaceEntityState,
  espacesToNormalize: EspaceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeEspace.espaces?.length && typeof societeEspace.espaces[0] !== 'number') {
    const idsEspaces: number[] = [];
    for (let i = 0; i < societeEspace.espaces.length; i++) {
      const espace: Espace | number = societeEspace.espaces[i];
      if (typeof espace !== 'number') {
        espacesToNormalize.push({ ...espace, societeEspace: societeEspace.idSocieteEspace });
        idsEspaces.push(espace.idEspace);
      } else {
        idsEspaces.push(espace);
      }
    }
    relations['espaces'] = idsEspaces;
  }
}

function societeEspaceNormalizeSocieteEspaceFamille(
  societeEspace: SocieteEspaceEntityState,
  societeEspaceFamillesToNormalize: { [id: number]: SocieteEspaceFamilleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeEspace.societeEspaceFamille && typeof societeEspace.societeEspaceFamille !== 'number') {
    if (!societeEspaceFamillesToNormalize[societeEspace.societeEspaceFamille.idSocieteEspaceFamille]) {
      societeEspaceFamillesToNormalize[societeEspace.societeEspaceFamille.idSocieteEspaceFamille] = {
        ...societeEspace.societeEspaceFamille,
        societeEspaces: societeEspace.societeEspaceFamille.societeEspaces?.concat() ?? []
      };
    }
    (societeEspaceFamillesToNormalize[societeEspace.societeEspaceFamille.idSocieteEspaceFamille].societeEspaces as number[]).push(
      societeEspace.idSocieteEspace
    );
    relations['societeEspaceFamille'] = societeEspace.societeEspaceFamille.idSocieteEspaceFamille;
  }
}

/**
 *  SocieteEspaceFamille
 */
export function getActionsToNormalizeSocieteEspaceFamille(
  societeEspaceFamilles: SocieteEspaceFamilleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};
  const societeComposantRattachementsToNormalize: SocieteComposantRattachementEntityState[] = [];
  const societeEspacesToNormalize: SocieteEspaceEntityState[] = [];

  const normalizedSocieteEspaceFamilles = societeEspaceFamilles.map((societeEspaceFamille: SocieteEspaceFamilleEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeEspaceFamilleNormalizeSocieteComposantRattachement(societeEspaceFamille, societeComposantRattachementsToNormalize, relations);
    societeEspaceFamilleNormalizeSocieteEspace(societeEspaceFamille, societeEspacesToNormalize, relations);
    societeEspaceFamilleNormalizeSociete(societeEspaceFamille, societesToNormalize, relations);
    societeEspaceFamilleNormalizeSocietePatrimoineHierarchie(societeEspaceFamille, societePatrimoineHierarchiesToNormalize, relations);

    return {
      ...societeEspaceFamille,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const societePatrimoineHierarchiesToNormalizeArray = Object.values(societePatrimoineHierarchiesToNormalize);
  if (societePatrimoineHierarchiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchiesToNormalizeArray, actionType));
  }
  if (societeComposantRattachementsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposantRattachement(societeComposantRattachementsToNormalize, actionType));
  }
  if (societeEspacesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteEspace(societeEspacesToNormalize, actionType));
  }

  const societeEspaceFamilleActionCreator = SocieteEspaceFamilleGeneratedActions.upsertManySocieteEspaceFamillesSuccess;
  actions.push(societeEspaceFamilleActionCreator({ societeEspaceFamilles: normalizedSocieteEspaceFamilles }));

  return actions;
}

function societeEspaceFamilleNormalizeSocieteComposantRattachement(
  societeEspaceFamille: SocieteEspaceFamilleEntityState,
  societeComposantRattachementsToNormalize: SocieteComposantRattachementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeEspaceFamille.societeComposantRattachements?.length && typeof societeEspaceFamille.societeComposantRattachements[0] !== 'number') {
    const idsSocieteComposantRattachements: number[] = [];
    for (let i = 0; i < societeEspaceFamille.societeComposantRattachements.length; i++) {
      const societeComposantRattachement: SocieteComposantRattachement | number = societeEspaceFamille.societeComposantRattachements[i];
      if (typeof societeComposantRattachement !== 'number') {
        societeComposantRattachementsToNormalize.push({ ...societeComposantRattachement, societeEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille });
        idsSocieteComposantRattachements.push(societeComposantRattachement.idSocieteComposantRattachement);
      } else {
        idsSocieteComposantRattachements.push(societeComposantRattachement);
      }
    }
    relations['societeComposantRattachements'] = idsSocieteComposantRattachements;
  }
}

function societeEspaceFamilleNormalizeSocieteEspace(
  societeEspaceFamille: SocieteEspaceFamilleEntityState,
  societeEspacesToNormalize: SocieteEspaceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeEspaceFamille.societeEspaces?.length && typeof societeEspaceFamille.societeEspaces[0] !== 'number') {
    const idsSocieteEspaces: number[] = [];
    for (let i = 0; i < societeEspaceFamille.societeEspaces.length; i++) {
      const societeEspace: SocieteEspace | number = societeEspaceFamille.societeEspaces[i];
      if (typeof societeEspace !== 'number') {
        societeEspacesToNormalize.push({ ...societeEspace, societeEspaceFamille: societeEspaceFamille.idSocieteEspaceFamille });
        idsSocieteEspaces.push(societeEspace.idSocieteEspace);
      } else {
        idsSocieteEspaces.push(societeEspace);
      }
    }
    relations['societeEspaces'] = idsSocieteEspaces;
  }
}

function societeEspaceFamilleNormalizeSociete(
  societeEspaceFamille: SocieteEspaceFamilleEntityState,
  societesToNormalize: { [id: number]: SocieteEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeEspaceFamille.societe && typeof societeEspaceFamille.societe !== 'number') {
    if (!societesToNormalize[societeEspaceFamille.societe.idSociete]) {
      societesToNormalize[societeEspaceFamille.societe.idSociete] = {
        ...societeEspaceFamille.societe,
        societeEspaceFamilles: societeEspaceFamille.societe.societeEspaceFamilles?.concat() ?? []
      };
    }
    (societesToNormalize[societeEspaceFamille.societe.idSociete].societeEspaceFamilles as number[]).push(
      societeEspaceFamille.idSocieteEspaceFamille
    );
    relations['societe'] = societeEspaceFamille.societe.idSociete;
  }
}

function societeEspaceFamilleNormalizeSocietePatrimoineHierarchie(
  societeEspaceFamille: SocieteEspaceFamilleEntityState,
  societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeEspaceFamille.societePatrimoineHierarchie && typeof societeEspaceFamille.societePatrimoineHierarchie !== 'number') {
    if (!societePatrimoineHierarchiesToNormalize[societeEspaceFamille.societePatrimoineHierarchie.idSocietePatrimoineHierarchie]) {
      societePatrimoineHierarchiesToNormalize[societeEspaceFamille.societePatrimoineHierarchie.idSocietePatrimoineHierarchie] = {
        ...societeEspaceFamille.societePatrimoineHierarchie,
        societeEspaceFamilles: societeEspaceFamille.societePatrimoineHierarchie.societeEspaceFamilles?.concat() ?? []
      };
    }
    (societePatrimoineHierarchiesToNormalize[societeEspaceFamille.societePatrimoineHierarchie.idSocietePatrimoineHierarchie].societeEspaceFamilles as number[]).push(
      societeEspaceFamille.idSocieteEspaceFamille
    );
    relations['societePatrimoineHierarchie'] = societeEspaceFamille.societePatrimoineHierarchie.idSocietePatrimoineHierarchie;
  }
}

/**
 *  SocietePatrimoineHierarchie
 */
export function getActionsToNormalizeSocietePatrimoineHierarchie(
  societePatrimoineHierarchies: SocietePatrimoineHierarchieEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const patrimoineHierarchieTemplatesToNormalize: { [id: number]: PatrimoineHierarchieTemplateEntityState } = {};
  const societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[] = [];
  const patrimoinesToNormalize: PatrimoineEntityState[] = [];
  const hierarchieAncetresToNormalize: SocietePatrimoineHierarchieAncetreEntityState[] = [];
  const hierarchieDescendantsToNormalize: SocietePatrimoineHierarchieAncetreEntityState[] = [];
  const societeComposantRattachementsToNormalize: SocieteComposantRattachementEntityState[] = [];
  const societeEspaceFamillesToNormalize: SocieteEspaceFamilleEntityState[] = [];

  const normalizedSocietePatrimoineHierarchies = societePatrimoineHierarchies.map((societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societePatrimoineHierarchieNormalizeSocieteCaracteristique(societePatrimoineHierarchie, societeCaracteristiquesToNormalize, relations);
    societePatrimoineHierarchieNormalizePatrimoine(societePatrimoineHierarchie, patrimoinesToNormalize, relations);
    societePatrimoineHierarchieNormalizeHierarchieAncetres(societePatrimoineHierarchie, hierarchieAncetresToNormalize, relations);
    societePatrimoineHierarchieNormalizeHierarchieDescendants(societePatrimoineHierarchie, hierarchieDescendantsToNormalize, relations);
    societePatrimoineHierarchieNormalizeSocieteComposantRattachement(societePatrimoineHierarchie, societeComposantRattachementsToNormalize, relations);
    societePatrimoineHierarchieNormalizeSocieteEspaceFamille(societePatrimoineHierarchie, societeEspaceFamillesToNormalize, relations);
    societePatrimoineHierarchieNormalizeSociete(societePatrimoineHierarchie, societesToNormalize, relations);
    societePatrimoineHierarchieNormalizePatrimoineHierarchieTemplate(societePatrimoineHierarchie, patrimoineHierarchieTemplatesToNormalize, relations);

    return {
      ...societePatrimoineHierarchie,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const patrimoineHierarchieTemplatesToNormalizeArray = Object.values(patrimoineHierarchieTemplatesToNormalize);
  if (patrimoineHierarchieTemplatesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoineHierarchieTemplate(patrimoineHierarchieTemplatesToNormalizeArray, actionType));
  }
  if (societeCaracteristiquesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalize, actionType));
  }
  if (patrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalize, actionType));
  }
  if (hierarchieAncetresToNormalize.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchieAncetre(hierarchieAncetresToNormalize, actionType));
  }
  if (hierarchieDescendantsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchieAncetre(hierarchieDescendantsToNormalize, actionType));
  }
  if (societeComposantRattachementsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteComposantRattachement(societeComposantRattachementsToNormalize, actionType));
  }
  if (societeEspaceFamillesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteEspaceFamille(societeEspaceFamillesToNormalize, actionType));
  }

  const societePatrimoineHierarchieActionCreator = SocietePatrimoineHierarchieGeneratedActions.upsertManySocietePatrimoineHierarchiesSuccess;
  actions.push(societePatrimoineHierarchieActionCreator({ societePatrimoineHierarchies: normalizedSocietePatrimoineHierarchies }));

  return actions;
}

function societePatrimoineHierarchieNormalizeSocieteCaracteristique(
  societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState,
  societeCaracteristiquesToNormalize: SocieteCaracteristiqueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societePatrimoineHierarchie.societeCaracteristiques?.length && typeof societePatrimoineHierarchie.societeCaracteristiques[0] !== 'number') {
    const idsSocieteCaracteristiques: number[] = [];
    for (let i = 0; i < societePatrimoineHierarchie.societeCaracteristiques.length; i++) {
      const societeCaracteristique: SocieteCaracteristique | number = societePatrimoineHierarchie.societeCaracteristiques[i];
      if (typeof societeCaracteristique !== 'number') {
        societeCaracteristiquesToNormalize.push({ ...societeCaracteristique, societePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie });
        idsSocieteCaracteristiques.push(societeCaracteristique.idSocieteCaracteristique);
      } else {
        idsSocieteCaracteristiques.push(societeCaracteristique);
      }
    }
    relations['societeCaracteristiques'] = idsSocieteCaracteristiques;
  }
}

function societePatrimoineHierarchieNormalizePatrimoine(
  societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState,
  patrimoinesToNormalize: PatrimoineEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societePatrimoineHierarchie.patrimoines?.length && typeof societePatrimoineHierarchie.patrimoines[0] !== 'number') {
    const idsPatrimoines: number[] = [];
    for (let i = 0; i < societePatrimoineHierarchie.patrimoines.length; i++) {
      const patrimoine: Patrimoine | number = societePatrimoineHierarchie.patrimoines[i];
      if (typeof patrimoine !== 'number') {
        patrimoinesToNormalize.push({ ...patrimoine, societePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie });
        idsPatrimoines.push(patrimoine.idPatrimoine);
      } else {
        idsPatrimoines.push(patrimoine);
      }
    }
    relations['patrimoines'] = idsPatrimoines;
  }
}

function societePatrimoineHierarchieNormalizeHierarchieAncetres(
  societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState,
  societePatrimoineHierarchieAncetresToNormalize: SocietePatrimoineHierarchieAncetreEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societePatrimoineHierarchie.hierarchieAncetres?.length && typeof societePatrimoineHierarchie.hierarchieAncetres[0] !== 'number') {
    const idsSocietePatrimoineHierarchieAncetres: number[] = [];
    for (let i = 0; i < societePatrimoineHierarchie.hierarchieAncetres.length; i++) {
      const societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetre | number = societePatrimoineHierarchie.hierarchieAncetres[i];
      if (typeof societePatrimoineHierarchieAncetre !== 'number') {
        societePatrimoineHierarchieAncetresToNormalize.push({ ...societePatrimoineHierarchieAncetre, hierarchieDescendant: societePatrimoineHierarchie.idSocietePatrimoineHierarchie });
        idsSocietePatrimoineHierarchieAncetres.push(societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre);
      } else {
        idsSocietePatrimoineHierarchieAncetres.push(societePatrimoineHierarchieAncetre);
      }
    }
    relations['hierarchieAncetres'] = idsSocietePatrimoineHierarchieAncetres;
  }
}

function societePatrimoineHierarchieNormalizeHierarchieDescendants(
  societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState,
  societePatrimoineHierarchieAncetresToNormalize: SocietePatrimoineHierarchieAncetreEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societePatrimoineHierarchie.hierarchieDescendants?.length && typeof societePatrimoineHierarchie.hierarchieDescendants[0] !== 'number') {
    const idsSocietePatrimoineHierarchieAncetres: number[] = [];
    for (let i = 0; i < societePatrimoineHierarchie.hierarchieDescendants.length; i++) {
      const societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetre | number = societePatrimoineHierarchie.hierarchieDescendants[i];
      if (typeof societePatrimoineHierarchieAncetre !== 'number') {
        societePatrimoineHierarchieAncetresToNormalize.push({ ...societePatrimoineHierarchieAncetre, hierarchieAncetre: societePatrimoineHierarchie.idSocietePatrimoineHierarchie });
        idsSocietePatrimoineHierarchieAncetres.push(societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre);
      } else {
        idsSocietePatrimoineHierarchieAncetres.push(societePatrimoineHierarchieAncetre);
      }
    }
    relations['hierarchieDescendants'] = idsSocietePatrimoineHierarchieAncetres;
  }
}

function societePatrimoineHierarchieNormalizeSocieteComposantRattachement(
  societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState,
  societeComposantRattachementsToNormalize: SocieteComposantRattachementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societePatrimoineHierarchie.societeComposantRattachements?.length && typeof societePatrimoineHierarchie.societeComposantRattachements[0] !== 'number') {
    const idsSocieteComposantRattachements: number[] = [];
    for (let i = 0; i < societePatrimoineHierarchie.societeComposantRattachements.length; i++) {
      const societeComposantRattachement: SocieteComposantRattachement | number = societePatrimoineHierarchie.societeComposantRattachements[i];
      if (typeof societeComposantRattachement !== 'number') {
        societeComposantRattachementsToNormalize.push({ ...societeComposantRattachement, societePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie });
        idsSocieteComposantRattachements.push(societeComposantRattachement.idSocieteComposantRattachement);
      } else {
        idsSocieteComposantRattachements.push(societeComposantRattachement);
      }
    }
    relations['societeComposantRattachements'] = idsSocieteComposantRattachements;
  }
}

function societePatrimoineHierarchieNormalizeSocieteEspaceFamille(
  societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState,
  societeEspaceFamillesToNormalize: SocieteEspaceFamilleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societePatrimoineHierarchie.societeEspaceFamilles?.length && typeof societePatrimoineHierarchie.societeEspaceFamilles[0] !== 'number') {
    const idsSocieteEspaceFamilles: number[] = [];
    for (let i = 0; i < societePatrimoineHierarchie.societeEspaceFamilles.length; i++) {
      const societeEspaceFamille: SocieteEspaceFamille | number = societePatrimoineHierarchie.societeEspaceFamilles[i];
      if (typeof societeEspaceFamille !== 'number') {
        societeEspaceFamillesToNormalize.push({ ...societeEspaceFamille, societePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie });
        idsSocieteEspaceFamilles.push(societeEspaceFamille.idSocieteEspaceFamille);
      } else {
        idsSocieteEspaceFamilles.push(societeEspaceFamille);
      }
    }
    relations['societeEspaceFamilles'] = idsSocieteEspaceFamilles;
  }
}

function societePatrimoineHierarchieNormalizeSociete(
  societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState,
  societesToNormalize: { [id: number]: SocieteEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societePatrimoineHierarchie.societe && typeof societePatrimoineHierarchie.societe !== 'number') {
    if (!societesToNormalize[societePatrimoineHierarchie.societe.idSociete]) {
      societesToNormalize[societePatrimoineHierarchie.societe.idSociete] = {
        ...societePatrimoineHierarchie.societe,
        societePatrimoineHierarchies: societePatrimoineHierarchie.societe.societePatrimoineHierarchies?.concat() ?? []
      };
    }
    (societesToNormalize[societePatrimoineHierarchie.societe.idSociete].societePatrimoineHierarchies as number[]).push(
      societePatrimoineHierarchie.idSocietePatrimoineHierarchie
    );
    relations['societe'] = societePatrimoineHierarchie.societe.idSociete;
  }
}

function societePatrimoineHierarchieNormalizePatrimoineHierarchieTemplate(
  societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState,
  patrimoineHierarchieTemplatesToNormalize: { [id: number]: PatrimoineHierarchieTemplateEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societePatrimoineHierarchie.patrimoineHierarchieTemplate && typeof societePatrimoineHierarchie.patrimoineHierarchieTemplate !== 'number') {
    if (!patrimoineHierarchieTemplatesToNormalize[societePatrimoineHierarchie.patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate]) {
      patrimoineHierarchieTemplatesToNormalize[societePatrimoineHierarchie.patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate] = {
        ...societePatrimoineHierarchie.patrimoineHierarchieTemplate,
        societePatrimoineHierarchies: societePatrimoineHierarchie.patrimoineHierarchieTemplate.societePatrimoineHierarchies?.concat() ?? []
      };
    }
    (patrimoineHierarchieTemplatesToNormalize[societePatrimoineHierarchie.patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate].societePatrimoineHierarchies as number[]).push(
      societePatrimoineHierarchie.idSocietePatrimoineHierarchie
    );
    relations['patrimoineHierarchieTemplate'] = societePatrimoineHierarchie.patrimoineHierarchieTemplate.idPatrimoineHierarchieTemplate;
  }
}

/**
 *  SocietePatrimoineHierarchieAncetre
 */
export function getActionsToNormalizeSocietePatrimoineHierarchieAncetre(
  societePatrimoineHierarchieAncetres: SocietePatrimoineHierarchieAncetreEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const hierarchieAncetreToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};
  const hierarchieDescendantToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState } = {};

  const normalizedSocietePatrimoineHierarchieAncetres = societePatrimoineHierarchieAncetres.map((societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetreEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societePatrimoineHierarchieAncetreNormalizeHierarchieAncetre(societePatrimoineHierarchieAncetre, hierarchieAncetreToNormalize, relations);
    societePatrimoineHierarchieAncetreNormalizeHierarchieDescendant(societePatrimoineHierarchieAncetre, hierarchieDescendantToNormalize, relations);

    return {
      ...societePatrimoineHierarchieAncetre,
      ...relations
    };
  });
  const hierarchieAncetreToNormalizeArray = Object.values(hierarchieAncetreToNormalize);
  if (hierarchieAncetreToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(hierarchieAncetreToNormalizeArray, actionType));
  }
  const hierarchieDescendantToNormalizeArray = Object.values(hierarchieDescendantToNormalize);
  if (hierarchieDescendantToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocietePatrimoineHierarchie(hierarchieDescendantToNormalizeArray, actionType));
  }

  const societePatrimoineHierarchieAncetreActionCreator = SocietePatrimoineHierarchieAncetreGeneratedActions.upsertManySocietePatrimoineHierarchieAncetresSuccess;
  actions.push(societePatrimoineHierarchieAncetreActionCreator({ societePatrimoineHierarchieAncetres: normalizedSocietePatrimoineHierarchieAncetres }));

  return actions;
}

function societePatrimoineHierarchieAncetreNormalizeHierarchieAncetre(
  societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetreEntityState,
  societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societePatrimoineHierarchieAncetre.hierarchieAncetre && typeof societePatrimoineHierarchieAncetre.hierarchieAncetre !== 'number') {
    if (!societePatrimoineHierarchiesToNormalize[societePatrimoineHierarchieAncetre.hierarchieAncetre.idSocietePatrimoineHierarchie]) {
      societePatrimoineHierarchiesToNormalize[societePatrimoineHierarchieAncetre.hierarchieAncetre.idSocietePatrimoineHierarchie] = {
        ...societePatrimoineHierarchieAncetre.hierarchieAncetre,
        hierarchieAncetres: societePatrimoineHierarchieAncetre.hierarchieAncetre.hierarchieAncetres?.concat() ?? []
      };
    }
    (societePatrimoineHierarchiesToNormalize[societePatrimoineHierarchieAncetre.hierarchieAncetre.idSocietePatrimoineHierarchie].hierarchieAncetres as number[]).push(
      societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre
    );
    relations['hierarchieAncetre'] = societePatrimoineHierarchieAncetre.hierarchieAncetre.idSocietePatrimoineHierarchie;
  }
}

function societePatrimoineHierarchieAncetreNormalizeHierarchieDescendant(
  societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetreEntityState,
  societePatrimoineHierarchiesToNormalize: { [id: number]: SocietePatrimoineHierarchieEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societePatrimoineHierarchieAncetre.hierarchieDescendant && typeof societePatrimoineHierarchieAncetre.hierarchieDescendant !== 'number') {
    if (!societePatrimoineHierarchiesToNormalize[societePatrimoineHierarchieAncetre.hierarchieDescendant.idSocietePatrimoineHierarchie]) {
      societePatrimoineHierarchiesToNormalize[societePatrimoineHierarchieAncetre.hierarchieDescendant.idSocietePatrimoineHierarchie] = {
        ...societePatrimoineHierarchieAncetre.hierarchieDescendant,
        hierarchieDescendants: societePatrimoineHierarchieAncetre.hierarchieDescendant.hierarchieDescendants?.concat() ?? []
      };
    }
    (societePatrimoineHierarchiesToNormalize[societePatrimoineHierarchieAncetre.hierarchieDescendant.idSocietePatrimoineHierarchie].hierarchieDescendants as number[]).push(
      societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre
    );
    relations['hierarchieDescendant'] = societePatrimoineHierarchieAncetre.hierarchieDescendant.idSocietePatrimoineHierarchie;
  }
}

/**
 *  SocieteProfil
 */
export function getActionsToNormalizeSocieteProfil(
  societeProfils: SocieteProfilEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const userSocieteProfilsToNormalize: UserSocieteProfilEntityState[] = [];
  const societeProfilDroitsToNormalize: SocieteProfilDroitEntityState[] = [];
  const droitsToNormalize: { [id: number]: DroitEntityState } = {};

  const normalizedSocieteProfils = societeProfils.map((societeProfil: SocieteProfilEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeProfilNormalizeUser(societeProfil, usersToNormalize, relations);
    societeProfilNormalizeUserSocieteProfil(societeProfil, userSocieteProfilsToNormalize, relations);
    societeProfilNormalizeSocieteProfilDroit(societeProfil, societeProfilDroitsToNormalize, relations);
    societeProfilNormalizeDroit(societeProfil, droitsToNormalize, relations);
    societeProfilNormalizeSociete(societeProfil, societesToNormalize, relations);

    return {
      ...societeProfil,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (userSocieteProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeUserSocieteProfil(userSocieteProfilsToNormalize, actionType));
  }
  if (societeProfilDroitsToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteProfilDroit(societeProfilDroitsToNormalize, actionType));
  }
  const droitsToNormalizeArray = Object.values(droitsToNormalize);
  if (droitsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeDroit(droitsToNormalizeArray, actionType));
  }

  const societeProfilActionCreator = SocieteProfilGeneratedActions.upsertManySocieteProfilsSuccess;
  actions.push(societeProfilActionCreator({ societeProfils: normalizedSocieteProfils }));

  return actions;
}

function societeProfilNormalizeUser(
  societeProfil: SocieteProfilEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeProfil.users?.length && typeof societeProfil.users[0] !== 'number') {
    const idsUsers: number[] = [];
    for (let i = 0; i < societeProfil.users.length; i++) {
      const user: User | number = societeProfil.users[i];

      if (typeof user !== 'number') {
        if (!usersToNormalize[user.idUser]) {
          usersToNormalize[user.idUser] = {
            ...user,
            societeProfils: user.societeProfils?.concat() ?? []
          };
        }
        (usersToNormalize[user.idUser].societeProfils as number[]).push(societeProfil.idSocieteProfil);
        idsUsers.push(user.idUser);
      } else {
        idsUsers.push(user);
      }
    }
    relations['users'] = idsUsers;
  }
}

function societeProfilNormalizeUserSocieteProfil(
  societeProfil: SocieteProfilEntityState,
  userSocieteProfilsToNormalize: UserSocieteProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeProfil.userSocieteProfils?.length && typeof societeProfil.userSocieteProfils[0] !== 'number') {
    const idsUserSocieteProfils: number[] = [];
    for (let i = 0; i < societeProfil.userSocieteProfils.length; i++) {
      const userSocieteProfil: UserSocieteProfil | number = societeProfil.userSocieteProfils[i];
      if (typeof userSocieteProfil !== 'number') {
        userSocieteProfilsToNormalize.push({ ...userSocieteProfil, societeProfil: societeProfil.idSocieteProfil });
        idsUserSocieteProfils.push(userSocieteProfil.idUserSocieteProfil);
      } else {
        idsUserSocieteProfils.push(userSocieteProfil);
      }
    }
    relations['userSocieteProfils'] = idsUserSocieteProfils;
  }
}

function societeProfilNormalizeSocieteProfilDroit(
  societeProfil: SocieteProfilEntityState,
  societeProfilDroitsToNormalize: SocieteProfilDroitEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeProfil.societeProfilDroits?.length && typeof societeProfil.societeProfilDroits[0] !== 'number') {
    const idsSocieteProfilDroits: number[] = [];
    for (let i = 0; i < societeProfil.societeProfilDroits.length; i++) {
      const societeProfilDroit: SocieteProfilDroit | number = societeProfil.societeProfilDroits[i];
      if (typeof societeProfilDroit !== 'number') {
        societeProfilDroitsToNormalize.push({ ...societeProfilDroit, societeProfil: societeProfil.idSocieteProfil });
        idsSocieteProfilDroits.push(societeProfilDroit.idSocieteProfilDroit);
      } else {
        idsSocieteProfilDroits.push(societeProfilDroit);
      }
    }
    relations['societeProfilDroits'] = idsSocieteProfilDroits;
  }
}

function societeProfilNormalizeDroit(
  societeProfil: SocieteProfilEntityState,
  droitsToNormalize: { [id: number]: DroitEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeProfil.droits?.length && typeof societeProfil.droits[0] !== 'number') {
    const idsDroits: number[] = [];
    for (let i = 0; i < societeProfil.droits.length; i++) {
      const droit: Droit | number = societeProfil.droits[i];

      if (typeof droit !== 'number') {
        if (!droitsToNormalize[droit.idDroit]) {
          droitsToNormalize[droit.idDroit] = {
            ...droit,
            societeProfils: droit.societeProfils?.concat() ?? []
          };
        }
        (droitsToNormalize[droit.idDroit].societeProfils as number[]).push(societeProfil.idSocieteProfil);
        idsDroits.push(droit.idDroit);
      } else {
        idsDroits.push(droit);
      }
    }
    relations['droits'] = idsDroits;
  }
}

function societeProfilNormalizeSociete(
  societeProfil: SocieteProfilEntityState,
  societesToNormalize: { [id: number]: SocieteEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeProfil.societe && typeof societeProfil.societe !== 'number') {
    if (!societesToNormalize[societeProfil.societe.idSociete]) {
      societesToNormalize[societeProfil.societe.idSociete] = {
        ...societeProfil.societe,
        societeProfils: societeProfil.societe.societeProfils?.concat() ?? []
      };
    }
    (societesToNormalize[societeProfil.societe.idSociete].societeProfils as number[]).push(
      societeProfil.idSocieteProfil
    );
    relations['societe'] = societeProfil.societe.idSociete;
  }
}

/**
 *  SocieteProfilDroit
 */
export function getActionsToNormalizeSocieteProfilDroit(
  societeProfilDroits: SocieteProfilDroitEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState } = {};
  const droitsToNormalize: { [id: number]: DroitEntityState } = {};

  const normalizedSocieteProfilDroits = societeProfilDroits.map((societeProfilDroit: SocieteProfilDroitEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeProfilDroitNormalizeSocieteProfil(societeProfilDroit, societeProfilsToNormalize, relations);
    societeProfilDroitNormalizeDroit(societeProfilDroit, droitsToNormalize, relations);

    return {
      ...societeProfilDroit,
      ...relations
    };
  });
  const societeProfilsToNormalizeArray = Object.values(societeProfilsToNormalize);
  if (societeProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteProfil(societeProfilsToNormalizeArray, actionType));
  }
  const droitsToNormalizeArray = Object.values(droitsToNormalize);
  if (droitsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeDroit(droitsToNormalizeArray, actionType));
  }

  const societeProfilDroitActionCreator = SocieteProfilDroitGeneratedActions.upsertManySocieteProfilDroitsSuccess;
  actions.push(societeProfilDroitActionCreator({ societeProfilDroits: normalizedSocieteProfilDroits }));

  return actions;
}

function societeProfilDroitNormalizeSocieteProfil(
  societeProfilDroit: SocieteProfilDroitEntityState,
  societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeProfilDroit.societeProfil && typeof societeProfilDroit.societeProfil !== 'number') {
    if (!societeProfilsToNormalize[societeProfilDroit.societeProfil.idSocieteProfil]) {
      societeProfilsToNormalize[societeProfilDroit.societeProfil.idSocieteProfil] = {
        ...societeProfilDroit.societeProfil,
        societeProfilDroits: societeProfilDroit.societeProfil.societeProfilDroits?.concat() ?? []
      };
    }
    (societeProfilsToNormalize[societeProfilDroit.societeProfil.idSocieteProfil].societeProfilDroits as number[]).push(
      societeProfilDroit.idSocieteProfilDroit
    );
    relations['societeProfil'] = societeProfilDroit.societeProfil.idSocieteProfil;
  }
}

function societeProfilDroitNormalizeDroit(
  societeProfilDroit: SocieteProfilDroitEntityState,
  droitsToNormalize: { [id: number]: DroitEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeProfilDroit.droit && typeof societeProfilDroit.droit !== 'number') {
    if (!droitsToNormalize[societeProfilDroit.droit.idDroit]) {
      droitsToNormalize[societeProfilDroit.droit.idDroit] = {
        ...societeProfilDroit.droit,
        societeProfilDroits: societeProfilDroit.droit.societeProfilDroits?.concat() ?? []
      };
    }
    (droitsToNormalize[societeProfilDroit.droit.idDroit].societeProfilDroits as number[]).push(
      societeProfilDroit.idSocieteProfilDroit
    );
    relations['droit'] = societeProfilDroit.droit.idDroit;
  }
}

/**
 *  SocieteTerritoire
 */
export function getActionsToNormalizeSocieteTerritoire(
  societeTerritoires: SocieteTerritoireEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societesToNormalize: { [id: number]: SocieteEntityState } = {};
  const societeTerritoirePatrimoinesToNormalize: SocieteTerritoirePatrimoineEntityState[] = [];
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const societeTerritoireUsersToNormalize: SocieteTerritoireUserEntityState[] = [];

  const normalizedSocieteTerritoires = societeTerritoires.map((societeTerritoire: SocieteTerritoireEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeTerritoireNormalizeSocieteTerritoirePatrimoine(societeTerritoire, societeTerritoirePatrimoinesToNormalize, relations);
    societeTerritoireNormalizePatrimoine(societeTerritoire, patrimoinesToNormalize, relations);
    societeTerritoireNormalizeSocieteTerritoireUser(societeTerritoire, societeTerritoireUsersToNormalize, relations);
    societeTerritoireNormalizeSociete(societeTerritoire, societesToNormalize, relations);

    return {
      ...societeTerritoire,
      ...relations
    };
  });
  const societesToNormalizeArray = Object.values(societesToNormalize);
  if (societesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSociete(societesToNormalizeArray, actionType));
  }
  if (societeTerritoirePatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoirePatrimoine(societeTerritoirePatrimoinesToNormalize, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  if (societeTerritoireUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoireUser(societeTerritoireUsersToNormalize, actionType));
  }

  const societeTerritoireActionCreator = SocieteTerritoireGeneratedActions.upsertManySocieteTerritoiresSuccess;
  actions.push(societeTerritoireActionCreator({ societeTerritoires: normalizedSocieteTerritoires }));

  return actions;
}

function societeTerritoireNormalizeSocieteTerritoirePatrimoine(
  societeTerritoire: SocieteTerritoireEntityState,
  societeTerritoirePatrimoinesToNormalize: SocieteTerritoirePatrimoineEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeTerritoire.societeTerritoirePatrimoines?.length && typeof societeTerritoire.societeTerritoirePatrimoines[0] !== 'number') {
    const idsSocieteTerritoirePatrimoines: number[] = [];
    for (let i = 0; i < societeTerritoire.societeTerritoirePatrimoines.length; i++) {
      const societeTerritoirePatrimoine: SocieteTerritoirePatrimoine | number = societeTerritoire.societeTerritoirePatrimoines[i];
      if (typeof societeTerritoirePatrimoine !== 'number') {
        societeTerritoirePatrimoinesToNormalize.push({ ...societeTerritoirePatrimoine, societeTerritoire: societeTerritoire.idSocieteTerritoire });
        idsSocieteTerritoirePatrimoines.push(societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine);
      } else {
        idsSocieteTerritoirePatrimoines.push(societeTerritoirePatrimoine);
      }
    }
    relations['societeTerritoirePatrimoines'] = idsSocieteTerritoirePatrimoines;
  }
}

function societeTerritoireNormalizePatrimoine(
  societeTerritoire: SocieteTerritoireEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeTerritoire.patrimoines?.length && typeof societeTerritoire.patrimoines[0] !== 'number') {
    const idsPatrimoines: number[] = [];
    for (let i = 0; i < societeTerritoire.patrimoines.length; i++) {
      const patrimoine: Patrimoine | number = societeTerritoire.patrimoines[i];

      if (typeof patrimoine !== 'number') {
        if (!patrimoinesToNormalize[patrimoine.idPatrimoine]) {
          patrimoinesToNormalize[patrimoine.idPatrimoine] = {
            ...patrimoine,
            societeTerritoires: patrimoine.societeTerritoires?.concat() ?? []
          };
        }
        (patrimoinesToNormalize[patrimoine.idPatrimoine].societeTerritoires as number[]).push(societeTerritoire.idSocieteTerritoire);
        idsPatrimoines.push(patrimoine.idPatrimoine);
      } else {
        idsPatrimoines.push(patrimoine);
      }
    }
    relations['patrimoines'] = idsPatrimoines;
  }
}

function societeTerritoireNormalizeSocieteTerritoireUser(
  societeTerritoire: SocieteTerritoireEntityState,
  societeTerritoireUsersToNormalize: SocieteTerritoireUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (societeTerritoire.societeTerritoireUsers?.length && typeof societeTerritoire.societeTerritoireUsers[0] !== 'number') {
    const idsSocieteTerritoireUsers: number[] = [];
    for (let i = 0; i < societeTerritoire.societeTerritoireUsers.length; i++) {
      const societeTerritoireUser: SocieteTerritoireUser | number = societeTerritoire.societeTerritoireUsers[i];
      if (typeof societeTerritoireUser !== 'number') {
        societeTerritoireUsersToNormalize.push({ ...societeTerritoireUser, societeTerritoire: societeTerritoire.idSocieteTerritoire });
        idsSocieteTerritoireUsers.push(societeTerritoireUser.idSocieteTerritoireUser);
      } else {
        idsSocieteTerritoireUsers.push(societeTerritoireUser);
      }
    }
    relations['societeTerritoireUsers'] = idsSocieteTerritoireUsers;
  }
}

function societeTerritoireNormalizeSociete(
  societeTerritoire: SocieteTerritoireEntityState,
  societesToNormalize: { [id: number]: SocieteEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeTerritoire.societe && typeof societeTerritoire.societe !== 'number') {
    if (!societesToNormalize[societeTerritoire.societe.idSociete]) {
      societesToNormalize[societeTerritoire.societe.idSociete] = {
        ...societeTerritoire.societe,
        societeTerritoires: societeTerritoire.societe.societeTerritoires?.concat() ?? []
      };
    }
    (societesToNormalize[societeTerritoire.societe.idSociete].societeTerritoires as number[]).push(
      societeTerritoire.idSocieteTerritoire
    );
    relations['societe'] = societeTerritoire.societe.idSociete;
  }
}

/**
 *  SocieteTerritoirePatrimoine
 */
export function getActionsToNormalizeSocieteTerritoirePatrimoine(
  societeTerritoirePatrimoines: SocieteTerritoirePatrimoineEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societeTerritoiresToNormalize: { [id: number]: SocieteTerritoireEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedSocieteTerritoirePatrimoines = societeTerritoirePatrimoines.map((societeTerritoirePatrimoine: SocieteTerritoirePatrimoineEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeTerritoirePatrimoineNormalizeSocieteTerritoire(societeTerritoirePatrimoine, societeTerritoiresToNormalize, relations);
    societeTerritoirePatrimoineNormalizePatrimoine(societeTerritoirePatrimoine, patrimoinesToNormalize, relations);

    return {
      ...societeTerritoirePatrimoine,
      ...relations
    };
  });
  const societeTerritoiresToNormalizeArray = Object.values(societeTerritoiresToNormalize);
  if (societeTerritoiresToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoire(societeTerritoiresToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }

  const societeTerritoirePatrimoineActionCreator = SocieteTerritoirePatrimoineGeneratedActions.upsertManySocieteTerritoirePatrimoinesSuccess;
  actions.push(societeTerritoirePatrimoineActionCreator({ societeTerritoirePatrimoines: normalizedSocieteTerritoirePatrimoines }));

  return actions;
}

function societeTerritoirePatrimoineNormalizeSocieteTerritoire(
  societeTerritoirePatrimoine: SocieteTerritoirePatrimoineEntityState,
  societeTerritoiresToNormalize: { [id: number]: SocieteTerritoireEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeTerritoirePatrimoine.societeTerritoire && typeof societeTerritoirePatrimoine.societeTerritoire !== 'number') {
    if (!societeTerritoiresToNormalize[societeTerritoirePatrimoine.societeTerritoire.idSocieteTerritoire]) {
      societeTerritoiresToNormalize[societeTerritoirePatrimoine.societeTerritoire.idSocieteTerritoire] = {
        ...societeTerritoirePatrimoine.societeTerritoire,
        societeTerritoirePatrimoines: societeTerritoirePatrimoine.societeTerritoire.societeTerritoirePatrimoines?.concat() ?? []
      };
    }
    (societeTerritoiresToNormalize[societeTerritoirePatrimoine.societeTerritoire.idSocieteTerritoire].societeTerritoirePatrimoines as number[]).push(
      societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine
    );
    relations['societeTerritoire'] = societeTerritoirePatrimoine.societeTerritoire.idSocieteTerritoire;
  }
}

function societeTerritoirePatrimoineNormalizePatrimoine(
  societeTerritoirePatrimoine: SocieteTerritoirePatrimoineEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeTerritoirePatrimoine.patrimoine && typeof societeTerritoirePatrimoine.patrimoine !== 'number') {
    if (!patrimoinesToNormalize[societeTerritoirePatrimoine.patrimoine.idPatrimoine]) {
      patrimoinesToNormalize[societeTerritoirePatrimoine.patrimoine.idPatrimoine] = {
        ...societeTerritoirePatrimoine.patrimoine,
        societeTerritoirePatrimoines: societeTerritoirePatrimoine.patrimoine.societeTerritoirePatrimoines?.concat() ?? []
      };
    }
    (patrimoinesToNormalize[societeTerritoirePatrimoine.patrimoine.idPatrimoine].societeTerritoirePatrimoines as number[]).push(
      societeTerritoirePatrimoine.idSocieteTerritoirePatrimoine
    );
    relations['patrimoine'] = societeTerritoirePatrimoine.patrimoine.idPatrimoine;
  }
}

/**
 *  SocieteTerritoireUser
 */
export function getActionsToNormalizeSocieteTerritoireUser(
  societeTerritoireUsers: SocieteTerritoireUserEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societeTerritoiresToNormalize: { [id: number]: SocieteTerritoireEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedSocieteTerritoireUsers = societeTerritoireUsers.map((societeTerritoireUser: SocieteTerritoireUserEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    societeTerritoireUserNormalizeSocieteTerritoire(societeTerritoireUser, societeTerritoiresToNormalize, relations);
    societeTerritoireUserNormalizeUser(societeTerritoireUser, usersToNormalize, relations);

    return {
      ...societeTerritoireUser,
      ...relations
    };
  });
  const societeTerritoiresToNormalizeArray = Object.values(societeTerritoiresToNormalize);
  if (societeTerritoiresToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoire(societeTerritoiresToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const societeTerritoireUserActionCreator = SocieteTerritoireUserGeneratedActions.upsertManySocieteTerritoireUsersSuccess;
  actions.push(societeTerritoireUserActionCreator({ societeTerritoireUsers: normalizedSocieteTerritoireUsers }));

  return actions;
}

function societeTerritoireUserNormalizeSocieteTerritoire(
  societeTerritoireUser: SocieteTerritoireUserEntityState,
  societeTerritoiresToNormalize: { [id: number]: SocieteTerritoireEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeTerritoireUser.societeTerritoire && typeof societeTerritoireUser.societeTerritoire !== 'number') {
    if (!societeTerritoiresToNormalize[societeTerritoireUser.societeTerritoire.idSocieteTerritoire]) {
      societeTerritoiresToNormalize[societeTerritoireUser.societeTerritoire.idSocieteTerritoire] = {
        ...societeTerritoireUser.societeTerritoire,
        societeTerritoireUsers: societeTerritoireUser.societeTerritoire.societeTerritoireUsers?.concat() ?? []
      };
    }
    (societeTerritoiresToNormalize[societeTerritoireUser.societeTerritoire.idSocieteTerritoire].societeTerritoireUsers as number[]).push(
      societeTerritoireUser.idSocieteTerritoireUser
    );
    relations['societeTerritoire'] = societeTerritoireUser.societeTerritoire.idSocieteTerritoire;
  }
}

function societeTerritoireUserNormalizeUser(
  societeTerritoireUser: SocieteTerritoireUserEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (societeTerritoireUser.user && typeof societeTerritoireUser.user !== 'number') {
    if (!usersToNormalize[societeTerritoireUser.user.idUser]) {
      usersToNormalize[societeTerritoireUser.user.idUser] = {
        ...societeTerritoireUser.user,
        societeTerritoireUsers: societeTerritoireUser.user.societeTerritoireUsers?.concat() ?? []
      };
    }
    (usersToNormalize[societeTerritoireUser.user.idUser].societeTerritoireUsers as number[]).push(
      societeTerritoireUser.idSocieteTerritoireUser
    );
    relations['user'] = societeTerritoireUser.user.idUser;
  }
}

/**
 *  User
 */
export function getActionsToNormalizeUser(
  users: UserEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organisationsToNormalize: { [id: number]: OrganisationEntityState } = {};
  const societeTerritoireUsersToNormalize: SocieteTerritoireUserEntityState[] = [];
  const campagneUsersToNormalize: CampagneUserEntityState[] = [];
  const composantAttendusToNormalize: ComposantAttenduEntityState[] = [];
  const campagnesToNormalize: { [id: number]: CampagneEntityState } = {};
  const userSocieteProfilsToNormalize: UserSocieteProfilEntityState[] = [];
  const societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState } = {};
  const userPatrimoinesToNormalize: UserPatrimoineEntityState[] = [];
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const composantsToNormalize: ComposantEntityState[] = [];
  const valeursToNormalize: ValeurEntityState[] = [];
  const userNewslettersToNormalize: UserNewsletterEntityState[] = [];
  const newslettersToNormalize: { [id: number]: NewsletterEntityState } = {};
  const societeConfigAnalyseSynthesesToNormalize: SocieteConfigAnalyseSyntheseEntityState[] = [];

  const normalizedUsers = users.map((user: UserEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userNormalizeSocieteTerritoireUser(user, societeTerritoireUsersToNormalize, relations);
    userNormalizeCampagneUser(user, campagneUsersToNormalize, relations);
    userNormalizeComposantAttendu(user, composantAttendusToNormalize, relations);
    userNormalizeCampagne(user, campagnesToNormalize, relations);
    userNormalizeUserSocieteProfil(user, userSocieteProfilsToNormalize, relations);
    userNormalizeSocieteProfil(user, societeProfilsToNormalize, relations);
    userNormalizeUserPatrimoine(user, userPatrimoinesToNormalize, relations);
    userNormalizePatrimoine(user, patrimoinesToNormalize, relations);
    userNormalizeComposant(user, composantsToNormalize, relations);
    userNormalizeValeur(user, valeursToNormalize, relations);
    userNormalizeUserNewsletter(user, userNewslettersToNormalize, relations);
    userNormalizeNewsletter(user, newslettersToNormalize, relations);
    userNormalizeSocieteConfigAnalyseSynthese(user, societeConfigAnalyseSynthesesToNormalize, relations);
    userNormalizeOrganisation(user, organisationsToNormalize, relations);

    return {
      ...user,
      ...relations
    };
  });
  const organisationsToNormalizeArray = Object.values(organisationsToNormalize);
  if (organisationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganisation(organisationsToNormalizeArray, actionType));
  }
  if (societeTerritoireUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteTerritoireUser(societeTerritoireUsersToNormalize, actionType));
  }
  if (campagneUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeCampagneUser(campagneUsersToNormalize, actionType));
  }
  if (composantAttendusToNormalize.length) {
    actions.push(...getActionsToNormalizeComposantAttendu(composantAttendusToNormalize, actionType));
  }
  const campagnesToNormalizeArray = Object.values(campagnesToNormalize);
  if (campagnesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCampagne(campagnesToNormalizeArray, actionType));
  }
  if (userSocieteProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeUserSocieteProfil(userSocieteProfilsToNormalize, actionType));
  }
  const societeProfilsToNormalizeArray = Object.values(societeProfilsToNormalize);
  if (societeProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteProfil(societeProfilsToNormalizeArray, actionType));
  }
  if (userPatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeUserPatrimoine(userPatrimoinesToNormalize, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  if (composantsToNormalize.length) {
    actions.push(...getActionsToNormalizeComposant(composantsToNormalize, actionType));
  }
  if (valeursToNormalize.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalize, actionType));
  }
  if (userNewslettersToNormalize.length) {
    actions.push(...getActionsToNormalizeUserNewsletter(userNewslettersToNormalize, actionType));
  }
  const newslettersToNormalizeArray = Object.values(newslettersToNormalize);
  if (newslettersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeNewsletter(newslettersToNormalizeArray, actionType));
  }
  if (societeConfigAnalyseSynthesesToNormalize.length) {
    actions.push(...getActionsToNormalizeSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthesesToNormalize, actionType));
  }

  const userActionCreator = UserGeneratedActions.upsertManyUsersSuccess;
  actions.push(userActionCreator({ users: normalizedUsers }));

  return actions;
}

function userNormalizeSocieteTerritoireUser(
  user: UserEntityState,
  societeTerritoireUsersToNormalize: SocieteTerritoireUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.societeTerritoireUsers?.length && typeof user.societeTerritoireUsers[0] !== 'number') {
    const idsSocieteTerritoireUsers: number[] = [];
    for (let i = 0; i < user.societeTerritoireUsers.length; i++) {
      const societeTerritoireUser: SocieteTerritoireUser | number = user.societeTerritoireUsers[i];
      if (typeof societeTerritoireUser !== 'number') {
        societeTerritoireUsersToNormalize.push({ ...societeTerritoireUser, user: user.idUser });
        idsSocieteTerritoireUsers.push(societeTerritoireUser.idSocieteTerritoireUser);
      } else {
        idsSocieteTerritoireUsers.push(societeTerritoireUser);
      }
    }
    relations['societeTerritoireUsers'] = idsSocieteTerritoireUsers;
  }
}

function userNormalizeCampagneUser(
  user: UserEntityState,
  campagneUsersToNormalize: CampagneUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.campagneUsers?.length && typeof user.campagneUsers[0] !== 'number') {
    const idsCampagneUsers: number[] = [];
    for (let i = 0; i < user.campagneUsers.length; i++) {
      const campagneUser: CampagneUser | number = user.campagneUsers[i];
      if (typeof campagneUser !== 'number') {
        campagneUsersToNormalize.push({ ...campagneUser, user: user.idUser });
        idsCampagneUsers.push(campagneUser.idCampagneUser);
      } else {
        idsCampagneUsers.push(campagneUser);
      }
    }
    relations['campagneUsers'] = idsCampagneUsers;
  }
}

function userNormalizeComposantAttendu(
  user: UserEntityState,
  composantAttendusToNormalize: ComposantAttenduEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.composantAttendus?.length && typeof user.composantAttendus[0] !== 'number') {
    const idsComposantAttendus: number[] = [];
    for (let i = 0; i < user.composantAttendus.length; i++) {
      const composantAttendu: ComposantAttendu | number = user.composantAttendus[i];
      if (typeof composantAttendu !== 'number') {
        composantAttendusToNormalize.push({ ...composantAttendu, user: user.idUser });
        idsComposantAttendus.push(composantAttendu.idComposantAttendu);
      } else {
        idsComposantAttendus.push(composantAttendu);
      }
    }
    relations['composantAttendus'] = idsComposantAttendus;
  }
}

function userNormalizeCampagne(
  user: UserEntityState,
  campagnesToNormalize: { [id: number]: CampagneEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (user.campagnes?.length && typeof user.campagnes[0] !== 'number') {
    const idsCampagnes: number[] = [];
    for (let i = 0; i < user.campagnes.length; i++) {
      const campagne: Campagne | number = user.campagnes[i];

      if (typeof campagne !== 'number') {
        if (!campagnesToNormalize[campagne.idCampagne]) {
          campagnesToNormalize[campagne.idCampagne] = {
            ...campagne,
            users: campagne.users?.concat() ?? []
          };
        }
        (campagnesToNormalize[campagne.idCampagne].users as number[]).push(user.idUser);
        idsCampagnes.push(campagne.idCampagne);
      } else {
        idsCampagnes.push(campagne);
      }
    }
    relations['campagnes'] = idsCampagnes;
  }
}

function userNormalizeUserSocieteProfil(
  user: UserEntityState,
  userSocieteProfilsToNormalize: UserSocieteProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.userSocieteProfils?.length && typeof user.userSocieteProfils[0] !== 'number') {
    const idsUserSocieteProfils: number[] = [];
    for (let i = 0; i < user.userSocieteProfils.length; i++) {
      const userSocieteProfil: UserSocieteProfil | number = user.userSocieteProfils[i];
      if (typeof userSocieteProfil !== 'number') {
        userSocieteProfilsToNormalize.push({ ...userSocieteProfil, user: user.idUser });
        idsUserSocieteProfils.push(userSocieteProfil.idUserSocieteProfil);
      } else {
        idsUserSocieteProfils.push(userSocieteProfil);
      }
    }
    relations['userSocieteProfils'] = idsUserSocieteProfils;
  }
}

function userNormalizeSocieteProfil(
  user: UserEntityState,
  societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (user.societeProfils?.length && typeof user.societeProfils[0] !== 'number') {
    const idsSocieteProfils: number[] = [];
    for (let i = 0; i < user.societeProfils.length; i++) {
      const societeProfil: SocieteProfil | number = user.societeProfils[i];

      if (typeof societeProfil !== 'number') {
        if (!societeProfilsToNormalize[societeProfil.idSocieteProfil]) {
          societeProfilsToNormalize[societeProfil.idSocieteProfil] = {
            ...societeProfil,
            users: societeProfil.users?.concat() ?? []
          };
        }
        (societeProfilsToNormalize[societeProfil.idSocieteProfil].users as number[]).push(user.idUser);
        idsSocieteProfils.push(societeProfil.idSocieteProfil);
      } else {
        idsSocieteProfils.push(societeProfil);
      }
    }
    relations['societeProfils'] = idsSocieteProfils;
  }
}

function userNormalizeUserPatrimoine(
  user: UserEntityState,
  userPatrimoinesToNormalize: UserPatrimoineEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.userPatrimoines?.length && typeof user.userPatrimoines[0] !== 'number') {
    const idsUserPatrimoines: number[] = [];
    for (let i = 0; i < user.userPatrimoines.length; i++) {
      const userPatrimoine: UserPatrimoine | number = user.userPatrimoines[i];
      if (typeof userPatrimoine !== 'number') {
        userPatrimoinesToNormalize.push({ ...userPatrimoine, user: user.idUser });
        idsUserPatrimoines.push(userPatrimoine.idUserPatrimoine);
      } else {
        idsUserPatrimoines.push(userPatrimoine);
      }
    }
    relations['userPatrimoines'] = idsUserPatrimoines;
  }
}

function userNormalizePatrimoine(
  user: UserEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (user.patrimoines?.length && typeof user.patrimoines[0] !== 'number') {
    const idsPatrimoines: number[] = [];
    for (let i = 0; i < user.patrimoines.length; i++) {
      const patrimoine: Patrimoine | number = user.patrimoines[i];

      if (typeof patrimoine !== 'number') {
        if (!patrimoinesToNormalize[patrimoine.idPatrimoine]) {
          patrimoinesToNormalize[patrimoine.idPatrimoine] = {
            ...patrimoine,
            users: patrimoine.users?.concat() ?? []
          };
        }
        (patrimoinesToNormalize[patrimoine.idPatrimoine].users as number[]).push(user.idUser);
        idsPatrimoines.push(patrimoine.idPatrimoine);
      } else {
        idsPatrimoines.push(patrimoine);
      }
    }
    relations['patrimoines'] = idsPatrimoines;
  }
}

function userNormalizeComposant(
  user: UserEntityState,
  composantsToNormalize: ComposantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.composants?.length && typeof user.composants[0] !== 'number') {
    const idsComposants: number[] = [];
    for (let i = 0; i < user.composants.length; i++) {
      const composant: Composant | number = user.composants[i];
      if (typeof composant !== 'number') {
        composantsToNormalize.push({ ...composant, user: user.idUser });
        idsComposants.push(composant.idComposant);
      } else {
        idsComposants.push(composant);
      }
    }
    relations['composants'] = idsComposants;
  }
}

function userNormalizeValeur(
  user: UserEntityState,
  valeursToNormalize: ValeurEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.valeurs?.length && typeof user.valeurs[0] !== 'number') {
    const idsValeurs: number[] = [];
    for (let i = 0; i < user.valeurs.length; i++) {
      const valeur: Valeur | number = user.valeurs[i];
      if (typeof valeur !== 'number') {
        valeursToNormalize.push({ ...valeur, user: user.idUser });
        idsValeurs.push(valeur.idValeur);
      } else {
        idsValeurs.push(valeur);
      }
    }
    relations['valeurs'] = idsValeurs;
  }
}

function userNormalizeUserNewsletter(
  user: UserEntityState,
  userNewslettersToNormalize: UserNewsletterEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.userNewsletters?.length && typeof user.userNewsletters[0] !== 'number') {
    const idsUserNewsletters: number[] = [];
    for (let i = 0; i < user.userNewsletters.length; i++) {
      const userNewsletter: UserNewsletter | number = user.userNewsletters[i];
      if (typeof userNewsletter !== 'number') {
        userNewslettersToNormalize.push({ ...userNewsletter, user: user.idUser });
        idsUserNewsletters.push(userNewsletter.idUserNewsletter);
      } else {
        idsUserNewsletters.push(userNewsletter);
      }
    }
    relations['userNewsletters'] = idsUserNewsletters;
  }
}

function userNormalizeNewsletter(
  user: UserEntityState,
  newslettersToNormalize: { [id: number]: NewsletterEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (user.newsletters?.length && typeof user.newsletters[0] !== 'number') {
    const idsNewsletters: number[] = [];
    for (let i = 0; i < user.newsletters.length; i++) {
      const newsletter: Newsletter | number = user.newsletters[i];

      if (typeof newsletter !== 'number') {
        if (!newslettersToNormalize[newsletter.idNewsletter]) {
          newslettersToNormalize[newsletter.idNewsletter] = {
            ...newsletter,
            users: newsletter.users?.concat() ?? []
          };
        }
        (newslettersToNormalize[newsletter.idNewsletter].users as number[]).push(user.idUser);
        idsNewsletters.push(newsletter.idNewsletter);
      } else {
        idsNewsletters.push(newsletter);
      }
    }
    relations['newsletters'] = idsNewsletters;
  }
}

function userNormalizeSocieteConfigAnalyseSynthese(
  user: UserEntityState,
  societeConfigAnalyseSynthesesToNormalize: SocieteConfigAnalyseSyntheseEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.societeConfigAnalyseSyntheses?.length && typeof user.societeConfigAnalyseSyntheses[0] !== 'number') {
    const idsSocieteConfigAnalyseSyntheses: number[] = [];
    for (let i = 0; i < user.societeConfigAnalyseSyntheses.length; i++) {
      const societeConfigAnalyseSynthese: SocieteConfigAnalyseSynthese | number = user.societeConfigAnalyseSyntheses[i];
      if (typeof societeConfigAnalyseSynthese !== 'number') {
        societeConfigAnalyseSynthesesToNormalize.push({ ...societeConfigAnalyseSynthese, user: user.idUser });
        idsSocieteConfigAnalyseSyntheses.push(societeConfigAnalyseSynthese.idSocieteConfigAnalyseSynthese);
      } else {
        idsSocieteConfigAnalyseSyntheses.push(societeConfigAnalyseSynthese);
      }
    }
    relations['societeConfigAnalyseSyntheses'] = idsSocieteConfigAnalyseSyntheses;
  }
}

function userNormalizeOrganisation(
  user: UserEntityState,
  organisationsToNormalize: { [id: number]: OrganisationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (user.organisation && typeof user.organisation !== 'number') {
    if (!organisationsToNormalize[user.organisation.idOrganisation]) {
      organisationsToNormalize[user.organisation.idOrganisation] = {
        ...user.organisation,
        users: user.organisation.users?.concat() ?? []
      };
    }
    (organisationsToNormalize[user.organisation.idOrganisation].users as number[]).push(
      user.idUser
    );
    relations['organisation'] = user.organisation.idOrganisation;
  }
}

/**
 *  UserNewsletter
 */
export function getActionsToNormalizeUserNewsletter(
  userNewsletters: UserNewsletterEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const newslettersToNormalize: { [id: number]: NewsletterEntityState } = {};

  const normalizedUserNewsletters = userNewsletters.map((userNewsletter: UserNewsletterEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userNewsletterNormalizeUser(userNewsletter, usersToNormalize, relations);
    userNewsletterNormalizeNewsletter(userNewsletter, newslettersToNormalize, relations);

    return {
      ...userNewsletter,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const newslettersToNormalizeArray = Object.values(newslettersToNormalize);
  if (newslettersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeNewsletter(newslettersToNormalizeArray, actionType));
  }

  const userNewsletterActionCreator = UserNewsletterGeneratedActions.upsertManyUserNewslettersSuccess;
  actions.push(userNewsletterActionCreator({ userNewsletters: normalizedUserNewsletters }));

  return actions;
}

function userNewsletterNormalizeUser(
  userNewsletter: UserNewsletterEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userNewsletter.user && typeof userNewsletter.user !== 'number') {
    if (!usersToNormalize[userNewsletter.user.idUser]) {
      usersToNormalize[userNewsletter.user.idUser] = {
        ...userNewsletter.user,
        userNewsletters: userNewsletter.user.userNewsletters?.concat() ?? []
      };
    }
    (usersToNormalize[userNewsletter.user.idUser].userNewsletters as number[]).push(
      userNewsletter.idUserNewsletter
    );
    relations['user'] = userNewsletter.user.idUser;
  }
}

function userNewsletterNormalizeNewsletter(
  userNewsletter: UserNewsletterEntityState,
  newslettersToNormalize: { [id: number]: NewsletterEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userNewsletter.newsletter && typeof userNewsletter.newsletter !== 'number') {
    if (!newslettersToNormalize[userNewsletter.newsletter.idNewsletter]) {
      newslettersToNormalize[userNewsletter.newsletter.idNewsletter] = {
        ...userNewsletter.newsletter,
        userNewsletters: userNewsletter.newsletter.userNewsletters?.concat() ?? []
      };
    }
    (newslettersToNormalize[userNewsletter.newsletter.idNewsletter].userNewsletters as number[]).push(
      userNewsletter.idUserNewsletter
    );
    relations['newsletter'] = userNewsletter.newsletter.idNewsletter;
  }
}

/**
 *  UserPatrimoine
 */
export function getActionsToNormalizeUserPatrimoine(
  userPatrimoines: UserPatrimoineEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedUserPatrimoines = userPatrimoines.map((userPatrimoine: UserPatrimoineEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userPatrimoineNormalizeUser(userPatrimoine, usersToNormalize, relations);
    userPatrimoineNormalizePatrimoine(userPatrimoine, patrimoinesToNormalize, relations);

    return {
      ...userPatrimoine,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }

  const userPatrimoineActionCreator = UserPatrimoineGeneratedActions.upsertManyUserPatrimoinesSuccess;
  actions.push(userPatrimoineActionCreator({ userPatrimoines: normalizedUserPatrimoines }));

  return actions;
}

function userPatrimoineNormalizeUser(
  userPatrimoine: UserPatrimoineEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userPatrimoine.user && typeof userPatrimoine.user !== 'number') {
    if (!usersToNormalize[userPatrimoine.user.idUser]) {
      usersToNormalize[userPatrimoine.user.idUser] = {
        ...userPatrimoine.user,
        userPatrimoines: userPatrimoine.user.userPatrimoines?.concat() ?? []
      };
    }
    (usersToNormalize[userPatrimoine.user.idUser].userPatrimoines as number[]).push(
      userPatrimoine.idUserPatrimoine
    );
    relations['user'] = userPatrimoine.user.idUser;
  }
}

function userPatrimoineNormalizePatrimoine(
  userPatrimoine: UserPatrimoineEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userPatrimoine.patrimoine && typeof userPatrimoine.patrimoine !== 'number') {
    if (!patrimoinesToNormalize[userPatrimoine.patrimoine.idPatrimoine]) {
      patrimoinesToNormalize[userPatrimoine.patrimoine.idPatrimoine] = {
        ...userPatrimoine.patrimoine,
        userPatrimoines: userPatrimoine.patrimoine.userPatrimoines?.concat() ?? []
      };
    }
    (patrimoinesToNormalize[userPatrimoine.patrimoine.idPatrimoine].userPatrimoines as number[]).push(
      userPatrimoine.idUserPatrimoine
    );
    relations['patrimoine'] = userPatrimoine.patrimoine.idPatrimoine;
  }
}

/**
 *  UserSocieteProfil
 */
export function getActionsToNormalizeUserSocieteProfil(
  userSocieteProfils: UserSocieteProfilEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState } = {};

  const normalizedUserSocieteProfils = userSocieteProfils.map((userSocieteProfil: UserSocieteProfilEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userSocieteProfilNormalizeUser(userSocieteProfil, usersToNormalize, relations);
    userSocieteProfilNormalizeSocieteProfil(userSocieteProfil, societeProfilsToNormalize, relations);

    return {
      ...userSocieteProfil,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const societeProfilsToNormalizeArray = Object.values(societeProfilsToNormalize);
  if (societeProfilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteProfil(societeProfilsToNormalizeArray, actionType));
  }

  const userSocieteProfilActionCreator = UserSocieteProfilGeneratedActions.upsertManyUserSocieteProfilsSuccess;
  actions.push(userSocieteProfilActionCreator({ userSocieteProfils: normalizedUserSocieteProfils }));

  return actions;
}

function userSocieteProfilNormalizeUser(
  userSocieteProfil: UserSocieteProfilEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userSocieteProfil.user && typeof userSocieteProfil.user !== 'number') {
    if (!usersToNormalize[userSocieteProfil.user.idUser]) {
      usersToNormalize[userSocieteProfil.user.idUser] = {
        ...userSocieteProfil.user,
        userSocieteProfils: userSocieteProfil.user.userSocieteProfils?.concat() ?? []
      };
    }
    (usersToNormalize[userSocieteProfil.user.idUser].userSocieteProfils as number[]).push(
      userSocieteProfil.idUserSocieteProfil
    );
    relations['user'] = userSocieteProfil.user.idUser;
  }
}

function userSocieteProfilNormalizeSocieteProfil(
  userSocieteProfil: UserSocieteProfilEntityState,
  societeProfilsToNormalize: { [id: number]: SocieteProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (userSocieteProfil.societeProfil && typeof userSocieteProfil.societeProfil !== 'number') {
    if (!societeProfilsToNormalize[userSocieteProfil.societeProfil.idSocieteProfil]) {
      societeProfilsToNormalize[userSocieteProfil.societeProfil.idSocieteProfil] = {
        ...userSocieteProfil.societeProfil,
        userSocieteProfils: userSocieteProfil.societeProfil.userSocieteProfils?.concat() ?? []
      };
    }
    (societeProfilsToNormalize[userSocieteProfil.societeProfil.idSocieteProfil].userSocieteProfils as number[]).push(
      userSocieteProfil.idUserSocieteProfil
    );
    relations['societeProfil'] = userSocieteProfil.societeProfil.idSocieteProfil;
  }
}

/**
 *  Valeur
 */
export function getActionsToNormalizeValeur(
  valeurs: ValeurEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const societeCaracteristiqueChoicesToNormalize: { [id: number]: SocieteCaracteristiqueChoixEntityState } = {};
  const societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const valeurComposantsToNormalize: ValeurComposantEntityState[] = [];
  const composantsToNormalize: { [id: number]: ComposantEntityState } = {};
  const valeurPatrimoinesToNormalize: ValeurPatrimoineEntityState[] = [];
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};
  const valeurFichiersToNormalize: ValeurFichierEntityState[] = [];
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};

  const normalizedValeurs = valeurs.map((valeur: ValeurEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    valeurNormalizeValeurComposant(valeur, valeurComposantsToNormalize, relations);
    valeurNormalizeComposant(valeur, composantsToNormalize, relations);
    valeurNormalizeValeurPatrimoine(valeur, valeurPatrimoinesToNormalize, relations);
    valeurNormalizePatrimoine(valeur, patrimoinesToNormalize, relations);
    valeurNormalizeValeurFichier(valeur, valeurFichiersToNormalize, relations);
    valeurNormalizeFichier(valeur, fichiersToNormalize, relations);
    valeurNormalizeSocieteCaracteristiqueChoix(valeur, societeCaracteristiqueChoicesToNormalize, relations);
    valeurNormalizeSocieteCaracteristique(valeur, societeCaracteristiquesToNormalize, relations);
    valeurNormalizeUser(valeur, usersToNormalize, relations);

    return {
      ...valeur,
      ...relations
    };
  });
  const societeCaracteristiqueChoicesToNormalizeArray = Object.values(societeCaracteristiqueChoicesToNormalize);
  if (societeCaracteristiqueChoicesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristiqueChoix(societeCaracteristiqueChoicesToNormalizeArray, actionType));
  }
  const societeCaracteristiquesToNormalizeArray = Object.values(societeCaracteristiquesToNormalize);
  if (societeCaracteristiquesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSocieteCaracteristique(societeCaracteristiquesToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (valeurComposantsToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurComposant(valeurComposantsToNormalize, actionType));
  }
  const composantsToNormalizeArray = Object.values(composantsToNormalize);
  if (composantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposant(composantsToNormalizeArray, actionType));
  }
  if (valeurPatrimoinesToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurPatrimoine(valeurPatrimoinesToNormalize, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }
  if (valeurFichiersToNormalize.length) {
    actions.push(...getActionsToNormalizeValeurFichier(valeurFichiersToNormalize, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }

  const valeurActionCreator = ValeurGeneratedActions.upsertManyValeursSuccess;
  actions.push(valeurActionCreator({ valeurs: normalizedValeurs }));

  return actions;
}

function valeurNormalizeValeurComposant(
  valeur: ValeurEntityState,
  valeurComposantsToNormalize: ValeurComposantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.valeurComposants?.length && typeof valeur.valeurComposants[0] !== 'number') {
    const idsValeurComposants: number[] = [];
    for (let i = 0; i < valeur.valeurComposants.length; i++) {
      const valeurComposant: ValeurComposant | number = valeur.valeurComposants[i];
      if (typeof valeurComposant !== 'number') {
        valeurComposantsToNormalize.push({ ...valeurComposant, valeur: valeur.idValeur });
        idsValeurComposants.push(valeurComposant.idValeurComposant);
      } else {
        idsValeurComposants.push(valeurComposant);
      }
    }
    relations['valeurComposants'] = idsValeurComposants;
  }
}

function valeurNormalizeComposant(
  valeur: ValeurEntityState,
  composantsToNormalize: { [id: number]: ComposantEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.composants?.length && typeof valeur.composants[0] !== 'number') {
    const idsComposants: number[] = [];
    for (let i = 0; i < valeur.composants.length; i++) {
      const composant: Composant | number = valeur.composants[i];

      if (typeof composant !== 'number') {
        if (!composantsToNormalize[composant.idComposant]) {
          composantsToNormalize[composant.idComposant] = {
            ...composant,
            valeurs: composant.valeurs?.concat() ?? []
          };
        }
        (composantsToNormalize[composant.idComposant].valeurs as number[]).push(valeur.idValeur);
        idsComposants.push(composant.idComposant);
      } else {
        idsComposants.push(composant);
      }
    }
    relations['composants'] = idsComposants;
  }
}

function valeurNormalizeValeurPatrimoine(
  valeur: ValeurEntityState,
  valeurPatrimoinesToNormalize: ValeurPatrimoineEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.valeurPatrimoines?.length && typeof valeur.valeurPatrimoines[0] !== 'number') {
    const idsValeurPatrimoines: number[] = [];
    for (let i = 0; i < valeur.valeurPatrimoines.length; i++) {
      const valeurPatrimoine: ValeurPatrimoine | number = valeur.valeurPatrimoines[i];
      if (typeof valeurPatrimoine !== 'number') {
        valeurPatrimoinesToNormalize.push({ ...valeurPatrimoine, valeur: valeur.idValeur });
        idsValeurPatrimoines.push(valeurPatrimoine.idValeurPatrimoine);
      } else {
        idsValeurPatrimoines.push(valeurPatrimoine);
      }
    }
    relations['valeurPatrimoines'] = idsValeurPatrimoines;
  }
}

function valeurNormalizePatrimoine(
  valeur: ValeurEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.patrimoines?.length && typeof valeur.patrimoines[0] !== 'number') {
    const idsPatrimoines: number[] = [];
    for (let i = 0; i < valeur.patrimoines.length; i++) {
      const patrimoine: Patrimoine | number = valeur.patrimoines[i];

      if (typeof patrimoine !== 'number') {
        if (!patrimoinesToNormalize[patrimoine.idPatrimoine]) {
          patrimoinesToNormalize[patrimoine.idPatrimoine] = {
            ...patrimoine,
            valeurs: patrimoine.valeurs?.concat() ?? []
          };
        }
        (patrimoinesToNormalize[patrimoine.idPatrimoine].valeurs as number[]).push(valeur.idValeur);
        idsPatrimoines.push(patrimoine.idPatrimoine);
      } else {
        idsPatrimoines.push(patrimoine);
      }
    }
    relations['patrimoines'] = idsPatrimoines;
  }
}

function valeurNormalizeValeurFichier(
  valeur: ValeurEntityState,
  valeurFichiersToNormalize: ValeurFichierEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.valeurFichiers?.length && typeof valeur.valeurFichiers[0] !== 'number') {
    const idsValeurFichiers: number[] = [];
    for (let i = 0; i < valeur.valeurFichiers.length; i++) {
      const valeurFichier: ValeurFichier | number = valeur.valeurFichiers[i];
      if (typeof valeurFichier !== 'number') {
        valeurFichiersToNormalize.push({ ...valeurFichier, valeur: valeur.idValeur });
        idsValeurFichiers.push(valeurFichier.idValeurFichier);
      } else {
        idsValeurFichiers.push(valeurFichier);
      }
    }
    relations['valeurFichiers'] = idsValeurFichiers;
  }
}

function valeurNormalizeFichier(
  valeur: ValeurEntityState,
  fichiersToNormalize: { [id: number]: FichierEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.fichiers?.length && typeof valeur.fichiers[0] !== 'number') {
    const idsFichiers: number[] = [];
    for (let i = 0; i < valeur.fichiers.length; i++) {
      const fichier: Fichier | number = valeur.fichiers[i];

      if (typeof fichier !== 'number') {
        if (!fichiersToNormalize[fichier.idFichier]) {
          fichiersToNormalize[fichier.idFichier] = {
            ...fichier,
            valeurs: fichier.valeurs?.concat() ?? []
          };
        }
        (fichiersToNormalize[fichier.idFichier].valeurs as number[]).push(valeur.idValeur);
        idsFichiers.push(fichier.idFichier);
      } else {
        idsFichiers.push(fichier);
      }
    }
    relations['fichiers'] = idsFichiers;
  }
}

function valeurNormalizeSocieteCaracteristiqueChoix(
  valeur: ValeurEntityState,
  societeCaracteristiqueChoicesToNormalize: { [id: number]: SocieteCaracteristiqueChoixEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.societeCaracteristiqueChoix && typeof valeur.societeCaracteristiqueChoix !== 'number') {
    if (!societeCaracteristiqueChoicesToNormalize[valeur.societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix]) {
      societeCaracteristiqueChoicesToNormalize[valeur.societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix] = {
        ...valeur.societeCaracteristiqueChoix,
        valeurs: valeur.societeCaracteristiqueChoix.valeurs?.concat() ?? []
      };
    }
    (societeCaracteristiqueChoicesToNormalize[valeur.societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix].valeurs as number[]).push(
      valeur.idValeur
    );
    relations['societeCaracteristiqueChoix'] = valeur.societeCaracteristiqueChoix.idSocieteCaracteristiqueChoix;
  }
}

function valeurNormalizeSocieteCaracteristique(
  valeur: ValeurEntityState,
  societeCaracteristiquesToNormalize: { [id: number]: SocieteCaracteristiqueEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.societeCaracteristique && typeof valeur.societeCaracteristique !== 'number') {
    if (!societeCaracteristiquesToNormalize[valeur.societeCaracteristique.idSocieteCaracteristique]) {
      societeCaracteristiquesToNormalize[valeur.societeCaracteristique.idSocieteCaracteristique] = {
        ...valeur.societeCaracteristique,
        valeurs: valeur.societeCaracteristique.valeurs?.concat() ?? []
      };
    }
    (societeCaracteristiquesToNormalize[valeur.societeCaracteristique.idSocieteCaracteristique].valeurs as number[]).push(
      valeur.idValeur
    );
    relations['societeCaracteristique'] = valeur.societeCaracteristique.idSocieteCaracteristique;
  }
}

function valeurNormalizeUser(
  valeur: ValeurEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeur.user && typeof valeur.user !== 'number') {
    if (!usersToNormalize[valeur.user.idUser]) {
      usersToNormalize[valeur.user.idUser] = {
        ...valeur.user,
        valeurs: valeur.user.valeurs?.concat() ?? []
      };
    }
    (usersToNormalize[valeur.user.idUser].valeurs as number[]).push(
      valeur.idValeur
    );
    relations['user'] = valeur.user.idUser;
  }
}

/**
 *  ValeurFichier
 */
export function getActionsToNormalizeValeurFichier(
  valeurFichiers: ValeurFichierEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};
  const fichiersToNormalize: { [id: number]: FichierEntityState } = {};

  const normalizedValeurFichiers = valeurFichiers.map((valeurFichier: ValeurFichierEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    valeurFichierNormalizeValeur(valeurFichier, valeursToNormalize, relations);
    valeurFichierNormalizeFichier(valeurFichier, fichiersToNormalize, relations);

    return {
      ...valeurFichier,
      ...relations
    };
  });
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }
  const fichiersToNormalizeArray = Object.values(fichiersToNormalize);
  if (fichiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeFichier(fichiersToNormalizeArray, actionType));
  }

  const valeurFichierActionCreator = ValeurFichierGeneratedActions.upsertManyValeurFichiersSuccess;
  actions.push(valeurFichierActionCreator({ valeurFichiers: normalizedValeurFichiers }));

  return actions;
}

function valeurFichierNormalizeValeur(
  valeurFichier: ValeurFichierEntityState,
  valeursToNormalize: { [id: number]: ValeurEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeurFichier.valeur && typeof valeurFichier.valeur !== 'number') {
    if (!valeursToNormalize[valeurFichier.valeur.idValeur]) {
      valeursToNormalize[valeurFichier.valeur.idValeur] = {
        ...valeurFichier.valeur,
        valeurFichiers: valeurFichier.valeur.valeurFichiers?.concat() ?? []
      };
    }
    (valeursToNormalize[valeurFichier.valeur.idValeur].valeurFichiers as number[]).push(
      valeurFichier.idValeurFichier
    );
    relations['valeur'] = valeurFichier.valeur.idValeur;
  }
}

function valeurFichierNormalizeFichier(
  valeurFichier: ValeurFichierEntityState,
  fichiersToNormalize: { [id: number]: FichierEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeurFichier.fichier && typeof valeurFichier.fichier !== 'number') {
    if (!fichiersToNormalize[valeurFichier.fichier.idFichier]) {
      fichiersToNormalize[valeurFichier.fichier.idFichier] = {
        ...valeurFichier.fichier,
        valeurFichiers: valeurFichier.fichier.valeurFichiers?.concat() ?? []
      };
    }
    (fichiersToNormalize[valeurFichier.fichier.idFichier].valeurFichiers as number[]).push(
      valeurFichier.idValeurFichier
    );
    relations['fichier'] = valeurFichier.fichier.idFichier;
  }
}

/**
 *  ValeurComposant
 */
export function getActionsToNormalizeValeurComposant(
  valeurComposants: ValeurComposantEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};
  const composantsToNormalize: { [id: number]: ComposantEntityState } = {};

  const normalizedValeurComposants = valeurComposants.map((valeurComposant: ValeurComposantEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    valeurComposantNormalizeValeur(valeurComposant, valeursToNormalize, relations);
    valeurComposantNormalizeComposant(valeurComposant, composantsToNormalize, relations);

    return {
      ...valeurComposant,
      ...relations
    };
  });
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }
  const composantsToNormalizeArray = Object.values(composantsToNormalize);
  if (composantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeComposant(composantsToNormalizeArray, actionType));
  }

  const valeurComposantActionCreator = ValeurComposantGeneratedActions.upsertManyValeurComposantsSuccess;
  actions.push(valeurComposantActionCreator({ valeurComposants: normalizedValeurComposants }));

  return actions;
}

function valeurComposantNormalizeValeur(
  valeurComposant: ValeurComposantEntityState,
  valeursToNormalize: { [id: number]: ValeurEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeurComposant.valeur && typeof valeurComposant.valeur !== 'number') {
    if (!valeursToNormalize[valeurComposant.valeur.idValeur]) {
      valeursToNormalize[valeurComposant.valeur.idValeur] = {
        ...valeurComposant.valeur,
        valeurComposants: valeurComposant.valeur.valeurComposants?.concat() ?? []
      };
    }
    (valeursToNormalize[valeurComposant.valeur.idValeur].valeurComposants as number[]).push(
      valeurComposant.idValeurComposant
    );
    relations['valeur'] = valeurComposant.valeur.idValeur;
  }
}

function valeurComposantNormalizeComposant(
  valeurComposant: ValeurComposantEntityState,
  composantsToNormalize: { [id: number]: ComposantEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeurComposant.composant && typeof valeurComposant.composant !== 'number') {
    if (!composantsToNormalize[valeurComposant.composant.idComposant]) {
      composantsToNormalize[valeurComposant.composant.idComposant] = {
        ...valeurComposant.composant,
        valeurComposants: valeurComposant.composant.valeurComposants?.concat() ?? []
      };
    }
    (composantsToNormalize[valeurComposant.composant.idComposant].valeurComposants as number[]).push(
      valeurComposant.idValeurComposant
    );
    relations['composant'] = valeurComposant.composant.idComposant;
  }
}

/**
 *  ValeurPatrimoine
 */
export function getActionsToNormalizeValeurPatrimoine(
  valeurPatrimoines: ValeurPatrimoineEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const valeursToNormalize: { [id: number]: ValeurEntityState } = {};
  const patrimoinesToNormalize: { [id: number]: PatrimoineEntityState } = {};

  const normalizedValeurPatrimoines = valeurPatrimoines.map((valeurPatrimoine: ValeurPatrimoineEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    valeurPatrimoineNormalizeValeur(valeurPatrimoine, valeursToNormalize, relations);
    valeurPatrimoineNormalizePatrimoine(valeurPatrimoine, patrimoinesToNormalize, relations);

    return {
      ...valeurPatrimoine,
      ...relations
    };
  });
  const valeursToNormalizeArray = Object.values(valeursToNormalize);
  if (valeursToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeValeur(valeursToNormalizeArray, actionType));
  }
  const patrimoinesToNormalizeArray = Object.values(patrimoinesToNormalize);
  if (patrimoinesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizePatrimoine(patrimoinesToNormalizeArray, actionType));
  }

  const valeurPatrimoineActionCreator = ValeurPatrimoineGeneratedActions.upsertManyValeurPatrimoinesSuccess;
  actions.push(valeurPatrimoineActionCreator({ valeurPatrimoines: normalizedValeurPatrimoines }));

  return actions;
}

function valeurPatrimoineNormalizeValeur(
  valeurPatrimoine: ValeurPatrimoineEntityState,
  valeursToNormalize: { [id: number]: ValeurEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeurPatrimoine.valeur && typeof valeurPatrimoine.valeur !== 'number') {
    if (!valeursToNormalize[valeurPatrimoine.valeur.idValeur]) {
      valeursToNormalize[valeurPatrimoine.valeur.idValeur] = {
        ...valeurPatrimoine.valeur,
        valeurPatrimoines: valeurPatrimoine.valeur.valeurPatrimoines?.concat() ?? []
      };
    }
    (valeursToNormalize[valeurPatrimoine.valeur.idValeur].valeurPatrimoines as number[]).push(
      valeurPatrimoine.idValeurPatrimoine
    );
    relations['valeur'] = valeurPatrimoine.valeur.idValeur;
  }
}

function valeurPatrimoineNormalizePatrimoine(
  valeurPatrimoine: ValeurPatrimoineEntityState,
  patrimoinesToNormalize: { [id: number]: PatrimoineEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (valeurPatrimoine.patrimoine && typeof valeurPatrimoine.patrimoine !== 'number') {
    if (!patrimoinesToNormalize[valeurPatrimoine.patrimoine.idPatrimoine]) {
      patrimoinesToNormalize[valeurPatrimoine.patrimoine.idPatrimoine] = {
        ...valeurPatrimoine.patrimoine,
        valeurPatrimoines: valeurPatrimoine.patrimoine.valeurPatrimoines?.concat() ?? []
      };
    }
    (patrimoinesToNormalize[valeurPatrimoine.patrimoine.idPatrimoine].valeurPatrimoines as number[]).push(
      valeurPatrimoine.idValeurPatrimoine
    );
    relations['patrimoine'] = valeurPatrimoine.patrimoine.idPatrimoine;
  }
}
