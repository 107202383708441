import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Espace, EspaceEntityState } from '@get/api-interfaces';
import { EspaceApiService } from '@get/store/api-services';
import { EspaceGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeEspace } from '@get/store/configs/normalization';
import { EspaceSelectors } from '@get/store/selectors';
import { EspaceRelationsIds } from '@get/store/ids-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { SocieteEspaceGeneratedActions } from '@get/store/actions';
import { ComposantGeneratedActions } from '@get/store/actions';
import { Composant } from '@get/api-interfaces';

export function getDefaultAddEspaceActions(espace: EspaceEntityState, ids?: EspaceRelationsIds): Action[] {
  const actions: Action[] = [EspaceGeneratedActions.normalizeManyEspacesAfterUpsert({ espaces: [espace] })];

  if (ids?.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.addManyEspaceSuccess({
        idPatrimoine: ids.patrimoine,
        idEspaces: [espace.idEspace]
      })
    );
    actions.push(
      EspaceGeneratedActions.addPatrimoineSuccess({
        idEspace: espace.idEspace,
        idPatrimoine: ids.patrimoine
      })
    );
  }

  if (ids?.societeEspace) {
    actions.push(
      SocieteEspaceGeneratedActions.addManyEspaceSuccess({
        idSocieteEspace: ids.societeEspace,
        idEspaces: [espace.idEspace]
      })
    );
    actions.push(
      EspaceGeneratedActions.addSocieteEspaceSuccess({
        idEspace: espace.idEspace,
        idSocieteEspace: ids.societeEspace
      })
    );
  }

  if (ids?.composants) {
    if (!Array.isArray(ids.composants)) {
      actions.push(
        ComposantGeneratedActions.upsertOneComposant({
          composant: {
            idEspace: espace.idEspace,
            idComposant: ids.composants as number
          } as Composant
        })
      );
      actions.push(
        EspaceGeneratedActions.addManyComposantSuccess({
          idEspace: espace.idEspace,
          idComposants: [ids.composants as number]
        })
      );
    } else {
      actions.push(
        ComposantGeneratedActions.upsertManyComposants({
          composants: (ids.composants as number[]).map(
            (idComposant: number) => ({
              idEspace: espace.idEspace,
              idComposant
            })
          ) as Composant[]
        })
      );
      actions.push(
        EspaceGeneratedActions.addManyComposantSuccess({
          idEspace: espace.idEspace,
          idComposants: ids.composants as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteEspaceActions(espace: EspaceEntityState): Action[] {
  const actions: Action[] = [EspaceGeneratedActions.deleteOneEspaceSuccess({ idEspace: espace.idEspace })];

  if (espace.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyEspaceSuccess({
        idEspaces: [espace.idEspace],
        idPatrimoines: [espace.patrimoine as number]
      })
    );
  }

  if (espace.societeEspace) {
    actions.push(
      SocieteEspaceGeneratedActions.deleteManyEspaceSuccess({
        idEspaces: [espace.idEspace],
        idSocieteEspaces: [espace.societeEspace as number]
      })
    );
  }

  if (espace.composants) {
    actions.push(
      ComposantGeneratedActions.deleteManyEspaceSuccess({
        idEspaces: [espace.idEspace],
        idComposants: espace.composants as number[]
      })
    );
  }

  return actions;
}

export class GeneratedEspaceEffects {
  constructor(
    protected actions$: Actions,
    protected espaceApiService: EspaceApiService,
    protected store$: Store<AppState>
  ) {}

  getManyEspaces$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EspaceGeneratedActions.getManyEspaces),
      switchMap(({ params }) =>
        this.espaceApiService.getEspaces(params).pipe(
          map((espaces: Espace[]) => {
            return EspaceGeneratedActions.normalizeManyEspacesAfterUpsert({ espaces });
          }),
          catchError(error => of(EspaceGeneratedActions.espacesFailure({ error })))
        )
      )
    );
  });

  getOneEspace$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EspaceGeneratedActions.getOneEspace),
      switchMap(idEspace =>
        this.espaceApiService.getEspace(idEspace).pipe(
          map((espace: Espace) => {
            return EspaceGeneratedActions.normalizeManyEspacesAfterUpsert({ espaces: [espace] });
          }),
          catchError(error => of(EspaceGeneratedActions.espacesFailure({ error })))
        )
      )
    );
  });

  upsertOneEspace$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EspaceGeneratedActions.upsertOneEspace),
      concatMap(
        ({
          espace,
          ids
        }: {
          espace: Partial<Espace>;
          ids?: EspaceRelationsIds;
        }) => {
          if (espace.idEspace) {
            return this.espaceApiService.updateEspace(espace).pipe(
              map((espaceReturned: Espace) => {
                return EspaceGeneratedActions.normalizeManyEspacesAfterUpsert({ espaces: [espaceReturned] });
              }),
              catchError(error => of(EspaceGeneratedActions.espacesFailure({ error })))
            );
          } else {
            return this.espaceApiService.addEspace(espace).pipe(
              mergeMap((espaceReturned: Espace) => getDefaultAddEspaceActions(espaceReturned, ids)),
              catchError(error => of(EspaceGeneratedActions.espacesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneEspace$ = createEffect(() => {
    const selectEspaceState$ = this.store$.select(EspaceSelectors.selectEspaceState);
    return this.actions$.pipe(
      ofType(EspaceGeneratedActions.deleteOneEspace),
      withLatestFrom(selectEspaceState$),
      concatMap(([{ idEspace }, state]) =>
        this.espaceApiService.deleteEspace(idEspace).pipe(
          mergeMap(_success => getDefaultDeleteEspaceActions(state.entities[idEspace] as EspaceEntityState)),
          catchError(error => of(EspaceGeneratedActions.espacesFailure({ error })))
        )
      )
    );
  });

  normalizeManyEspacesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EspaceGeneratedActions.normalizeManyEspacesAfterUpsert),
      concatMap(({ espaces }) => {
        const actions: Action[] = getActionsToNormalizeEspace(espaces, StoreActionType.upsert);
        return [getMultiAction(actions, '[Espace] Normalization After Upsert Success')];
      })
    );
  });
}
