import { RepositoryService } from '@get/services/repository';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristique } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteConfigAnalyseSyntheseSocieteCaracteristiqueApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteConfigAnalyseSyntheseSocieteCaracteristiques(params?: any): Observable<SocieteConfigAnalyseSyntheseSocieteCaracteristique[]> {
    return this.repo.getData<SocieteConfigAnalyseSyntheseSocieteCaracteristique[]>('societe-config-analyse-synthese-societe-caracteristique', params);
  }

  public getSocieteConfigAnalyseSyntheseSocieteCaracteristique(params: { idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number; params?: any }): Observable<SocieteConfigAnalyseSyntheseSocieteCaracteristique> {
    return this.repo.getData<SocieteConfigAnalyseSyntheseSocieteCaracteristique>('societe-config-analyse-synthese-societe-caracteristique/' + params.idSocieteConfigAnalyseSyntheseSocieteCaracteristique, params.params);
  }

  public addSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeConfigAnalyseSyntheseSocieteCaracteristique: Partial<SocieteConfigAnalyseSyntheseSocieteCaracteristique>): Observable<SocieteConfigAnalyseSyntheseSocieteCaracteristique> {
    return this.repo.create<SocieteConfigAnalyseSyntheseSocieteCaracteristique>('societe-config-analyse-synthese-societe-caracteristique', societeConfigAnalyseSyntheseSocieteCaracteristique);
  }

  public updateSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeConfigAnalyseSyntheseSocieteCaracteristique: Partial<SocieteConfigAnalyseSyntheseSocieteCaracteristique>): Observable<SocieteConfigAnalyseSyntheseSocieteCaracteristique> {
    return this.repo.update('societe-config-analyse-synthese-societe-caracteristique', societeConfigAnalyseSyntheseSocieteCaracteristique);
  }

  public deleteSocieteConfigAnalyseSyntheseSocieteCaracteristique(idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number): Observable<number> {
    return this.repo.delete('societe-config-analyse-synthese-societe-caracteristique/' + +idSocieteConfigAnalyseSyntheseSocieteCaracteristique);
  }
}

