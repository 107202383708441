import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ValeurComposantGeneratedActions } from '@get/store/actions';
import { ValeurComposantState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ValeurComposantEntityState } from '@get/api-interfaces';

export const valeurComposantReducersGeneratedFunctions: ReducerTypes<ValeurComposantState.IState, readonly ActionCreator[]>[] = [
  on(ValeurComposantGeneratedActions.getOneValeurComposant, (state: ValeurComposantState.IState) => setLoadingsState(state, true)),
  on(ValeurComposantGeneratedActions.getManyValeurComposants, (state: ValeurComposantState.IState) => setLoadingsState(state, true)),
  on(ValeurComposantGeneratedActions.upsertOneValeurComposant, (state: ValeurComposantState.IState) => setLoadingsState(state, true)),

  on(ValeurComposantGeneratedActions.upsertManyValeurComposantsSuccess, (state: ValeurComposantState.IState, { valeurComposants }) =>
    ValeurComposantState.adapter.upsertMany(valeurComposants, setLoadingsState(state, false))
  ),
  on(ValeurComposantGeneratedActions.deleteOneValeurComposant, (state: ValeurComposantState.IState) => setLoadingsState(state, true)),
  on(ValeurComposantGeneratedActions.deleteOneValeurComposantSuccess, (state: ValeurComposantState.IState, { idValeurComposant }) =>
    ValeurComposantState.adapter.removeOne(idValeurComposant, setLoadingsState(state, false))
  ),
  on(ValeurComposantGeneratedActions.clearActivesValeurComposants, (state: ValeurComposantState.IState) => ({ ...state, actives: [] })),
  on(ValeurComposantGeneratedActions.addManyActivesValeurComposants, (state: ValeurComposantState.IState, { idValeurComposants }) => ({
    ...state,
    actives: state.actives.concat(idValeurComposants)
  })),
  on(ValeurComposantGeneratedActions.deleteOneActiveValeurComposant, (state: ValeurComposantState.IState, { idValeurComposant }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idValeurComposant)
  })),

  on(ValeurComposantGeneratedActions.clearValeurComposants, () => ValeurComposantState.initialState),

  on(
    ValeurComposantGeneratedActions.addValeurSuccess,
    (state: ValeurComposantState.IState, { idValeurComposant, idValeur }) => {
      if (!state.entities[idValeurComposant]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeurComposant]: {
            ...state.entities[idValeurComposant],
            valeur: idValeur
          }
        }
      };
    }
  ),

  on(
    ValeurComposantGeneratedActions.deleteManyValeurSuccess,
    (state: ValeurComposantState.IState, { idValeurs, idValeurComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurComposants.reduce((entities, idValeurComposant) => {
            if (!state.entities[idValeurComposant]?.valeur) {
              return entities;
            }
            entities[idValeurComposant] = {
              ...state.entities[idValeurComposant],
              valeur: idValeurs.some(
                (idValeur: number) => idValeur === state.entities[idValeurComposant]?.valeur
              )
                ? undefined
                : state.entities[idValeurComposant]?.valeur
            } as ValeurComposantEntityState;
            return entities;
          }, {} as Dictionary<ValeurComposantEntityState>)
        }
      };
    }
  ),

  on(
    ValeurComposantGeneratedActions.addComposantSuccess,
    (state: ValeurComposantState.IState, { idValeurComposant, idComposant }) => {
      if (!state.entities[idValeurComposant]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idValeurComposant]: {
            ...state.entities[idValeurComposant],
            composant: idComposant
          }
        }
      };
    }
  ),

  on(
    ValeurComposantGeneratedActions.deleteManyComposantSuccess,
    (state: ValeurComposantState.IState, { idComposants, idValeurComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idValeurComposants.reduce((entities, idValeurComposant) => {
            if (!state.entities[idValeurComposant]?.composant) {
              return entities;
            }
            entities[idValeurComposant] = {
              ...state.entities[idValeurComposant],
              composant: idComposants.some(
                (idComposant: number) => idComposant === state.entities[idValeurComposant]?.composant
              )
                ? undefined
                : state.entities[idValeurComposant]?.composant
            } as ValeurComposantEntityState;
            return entities;
          }, {} as Dictionary<ValeurComposantEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ValeurComposantState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): ValeurComposantState.IState {
  return { ...state, isLoaded, isLoading };
}
