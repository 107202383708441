import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteConfigAnalyseSynthese, SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristique, SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteConfigAnalyseSyntheseState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeConfigAnalyseSyntheseRelations: string[] = ['societeConfigAnalyseSyntheseSocieteCaracteristiques','societeCaracteristiques','societes','users',];

export const { selectEntities, selectAll } = SocieteConfigAnalyseSyntheseState.adapter.getSelectors();

export const selectSocieteConfigAnalyseSyntheseState = createFeatureSelector<SocieteConfigAnalyseSyntheseState.IState>(SocieteConfigAnalyseSyntheseState.societeConfigAnalyseSyntheseFeatureKey);

export const selectIsLoadedSocieteConfigAnalyseSynthese = createSelector(
  selectSocieteConfigAnalyseSyntheseState,
  (state: SocieteConfigAnalyseSyntheseState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteConfigAnalyseSynthese = createSelector(
  selectSocieteConfigAnalyseSyntheseState,
  (state: SocieteConfigAnalyseSyntheseState.IState) => state.isLoading
);

export const selectIsReadySocieteConfigAnalyseSynthese = createSelector(
  selectSocieteConfigAnalyseSyntheseState,
  (state: SocieteConfigAnalyseSyntheseState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteConfigAnalyseSynthese = createSelector(
  selectSocieteConfigAnalyseSyntheseState,
  (state: SocieteConfigAnalyseSyntheseState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteConfigAnalyseSynthesesEntities = createSelector(selectSocieteConfigAnalyseSyntheseState, selectEntities);

export const selectSocieteConfigAnalyseSynthesesArray = createSelector(selectSocieteConfigAnalyseSyntheseState, selectAll);

export const selectIdSocieteConfigAnalyseSynthesesActive = createSelector(
  selectSocieteConfigAnalyseSyntheseState,
  (state: SocieteConfigAnalyseSyntheseState.IState) => state.actives
);

const societeConfigAnalyseSynthesesInObject = (societeConfigAnalyseSyntheses: Dictionary<SocieteConfigAnalyseSyntheseEntityState>) => ({ societeConfigAnalyseSyntheses })

const selectSocieteConfigAnalyseSynthesesEntitiesDictionary = createSelector(selectSocieteConfigAnalyseSynthesesEntities, societeConfigAnalyseSynthesesInObject);

const selectAllSocieteConfigAnalyseSynthesesObject = createSelector(selectSocieteConfigAnalyseSynthesesEntities, societeConfigAnalyseSyntheses => {
  return hydrateAll({ societeConfigAnalyseSyntheses });
});

const selectOneSocieteConfigAnalyseSyntheseDictionary = (idSocieteConfigAnalyseSynthese : number) =>
  createSelector(selectSocieteConfigAnalyseSynthesesEntities, societeConfigAnalyseSyntheses => ({
    societeConfigAnalyseSyntheses: { [idSocieteConfigAnalyseSynthese]: societeConfigAnalyseSyntheses[idSocieteConfigAnalyseSynthese] }
  }));

const selectOneSocieteConfigAnalyseSyntheseDictionaryWithoutChild = (idSocieteConfigAnalyseSynthese : number) =>
  createSelector(selectSocieteConfigAnalyseSynthesesEntities, societeConfigAnalyseSyntheses => ({
    societeConfigAnalyseSynthese: societeConfigAnalyseSyntheses[idSocieteConfigAnalyseSynthese]
  }));

const selectActiveSocieteConfigAnalyseSynthesesEntities = createSelector(
  selectIdSocieteConfigAnalyseSynthesesActive,
  selectSocieteConfigAnalyseSynthesesEntities,
  (actives: number[], societeConfigAnalyseSyntheses: Dictionary<SocieteConfigAnalyseSyntheseEntityState>) => getSocieteConfigAnalyseSynthesesFromActives(actives, societeConfigAnalyseSyntheses)
);

function getSocieteConfigAnalyseSynthesesFromActives(
  actives: number[],
  societeConfigAnalyseSyntheses: Dictionary<SocieteConfigAnalyseSyntheseEntityState>
): Dictionary<SocieteConfigAnalyseSyntheseEntityState> {
  return actives.reduce((acc, idActive) => {
    if (societeConfigAnalyseSyntheses[idActive]) {
      acc[idActive] = societeConfigAnalyseSyntheses[idActive];
    }
    return acc;
  }, {} as Dictionary<SocieteConfigAnalyseSyntheseEntityState>);
}

const selectAllSocieteConfigAnalyseSynthesesSelectors: Dictionary<Selector> = {};
export function selectAllSocieteConfigAnalyseSyntheses(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteConfigAnalyseSynthese>(
      schema,
      selectAllSocieteConfigAnalyseSynthesesSelectors,
      selectSocieteConfigAnalyseSynthesesEntitiesDictionary,
      getRelationSelectors,
      societeConfigAnalyseSyntheseRelations,
      hydrateAll,
      'societeConfigAnalyseSynthese'
    );
  } else {
    return selectAllSocieteConfigAnalyseSynthesesObject;
  }
}

export function selectAllSocieteConfigAnalyseSynthesesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'societeConfigAnalyseSyntheses'
): Selector {
  return createSelector(selectAllSocieteConfigAnalyseSyntheses(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteConfigAnalyseSyntheseEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeConfigAnalyseSyntheses.length; i++) {
      res[customKey][result.societeConfigAnalyseSyntheses[i].idSocieteConfigAnalyseSynthese] = result.societeConfigAnalyseSyntheses[i];
    }
    return res;
  });
}

export function selectOneSocieteConfigAnalyseSynthese(
  schema: SelectSchema = {},
  idSocieteConfigAnalyseSynthese: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteConfigAnalyseSyntheseDictionary(idSocieteConfigAnalyseSynthese)];
  selectors.push(...getRelationSelectors(schema, societeConfigAnalyseSyntheseRelations, 'societeConfigAnalyseSynthese'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteConfigAnalyseSyntheseDictionaryWithoutChild(idSocieteConfigAnalyseSynthese);
  }
}

export function selectActiveSocieteConfigAnalyseSyntheses(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveSocieteConfigAnalyseSynthesesEntities, societeConfigAnalyseSyntheses => ({
      societeConfigAnalyseSyntheses
    }))
  ];
  selectors.push(...getRelationSelectors(schema, societeConfigAnalyseSyntheseRelations, 'societeConfigAnalyseSynthese'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  societeConfigAnalyseSyntheses: Dictionary<SocieteConfigAnalyseSyntheseEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  users?: Dictionary<UserEntityState>;
  societeConfigAnalyseSyntheseSocieteCaracteristiques?: Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState>;
  societeCaracteristiques?: Dictionary<SocieteCaracteristiqueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeConfigAnalyseSyntheses: (SocieteConfigAnalyseSynthese | null)[] } {
  const {
    societeConfigAnalyseSyntheses,
    societes,
    users,
    societeConfigAnalyseSyntheseSocieteCaracteristiques,
    societeCaracteristiques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeConfigAnalyseSyntheses: Object.keys(societeConfigAnalyseSyntheses).map(idSocieteConfigAnalyseSynthese =>
      hydrate(
        societeConfigAnalyseSyntheses[idSocieteConfigAnalyseSynthese] as SocieteConfigAnalyseSyntheseEntityState,
        societes,
        users,
        societeConfigAnalyseSyntheseSocieteCaracteristiques,
        societeCaracteristiques
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseEntityState | null } {
  const {
    societeConfigAnalyseSyntheses,
    societes,
    users,
    societeConfigAnalyseSyntheseSocieteCaracteristiques,
    societeCaracteristiques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeConfigAnalyseSynthese = Object.values(societeConfigAnalyseSyntheses)[0];
  return {
    societeConfigAnalyseSynthese: hydrate(
      societeConfigAnalyseSynthese as SocieteConfigAnalyseSyntheseEntityState,
      societes,
      users,
      societeConfigAnalyseSyntheseSocieteCaracteristiques,
      societeCaracteristiques
    )
  };
}

function hydrate(
  societeConfigAnalyseSynthese: SocieteConfigAnalyseSyntheseEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  societeConfigAnalyseSyntheseSocieteCaracteristiqueEntities?: Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState>,
  societeCaracteristiqueEntities?: Dictionary<SocieteCaracteristiqueEntityState>,
): SocieteConfigAnalyseSynthese | null {
  if (!societeConfigAnalyseSynthese) {
    return null;
  }

  const societeConfigAnalyseSyntheseHydrated: SocieteConfigAnalyseSyntheseEntityState = { ...societeConfigAnalyseSynthese };
  if (societeEntities) {
    societeConfigAnalyseSyntheseHydrated.societe = societeEntities[societeConfigAnalyseSynthese.societe as number] as Societe;
  } else {
    delete societeConfigAnalyseSyntheseHydrated.societe;
  }
  if (userEntities) {
    societeConfigAnalyseSyntheseHydrated.user = userEntities[societeConfigAnalyseSynthese.user as number] as User;
  } else {
    delete societeConfigAnalyseSyntheseHydrated.user;
  }

  if (societeConfigAnalyseSyntheseSocieteCaracteristiqueEntities) {
    societeConfigAnalyseSyntheseHydrated.societeConfigAnalyseSyntheseSocieteCaracteristiques = ((societeConfigAnalyseSyntheseHydrated.societeConfigAnalyseSyntheseSocieteCaracteristiques as number[]) || []).map(
      id => societeConfigAnalyseSyntheseSocieteCaracteristiqueEntities[id]
    ) as SocieteConfigAnalyseSyntheseSocieteCaracteristique[];
  } else {
    delete societeConfigAnalyseSyntheseHydrated.societeConfigAnalyseSyntheseSocieteCaracteristiques;
  }

  if (societeCaracteristiqueEntities) {
    societeConfigAnalyseSyntheseHydrated.societeCaracteristiques = ((societeConfigAnalyseSyntheseHydrated.societeCaracteristiques as number[]) || []).map(
      id => societeCaracteristiqueEntities[id]
    ) as SocieteCaracteristique[];
  } else {
    delete societeConfigAnalyseSyntheseHydrated.societeCaracteristiques;
  }

  return societeConfigAnalyseSyntheseHydrated as SocieteConfigAnalyseSynthese;
}
