import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UserPatrimoine } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { UserPatrimoineRelationsIds } from '@get/store/ids-interfaces';
import { UserPatrimoineGeneratedActions } from '@get/store/actions';
import { UserPatrimoineSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedUserPatrimoineService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(UserPatrimoineSelectors.selectIsLoadedUserPatrimoine));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(UserPatrimoineSelectors.selectIsLoadingUserPatrimoine));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      UserPatrimoineSelectors.selectIsReadyAndLoadedUserPatrimoine as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllUserPatrimoines(schema: SelectSchema = {}): Observable<UserPatrimoine[]> {
    return this.store$.pipe(select(UserPatrimoineSelectors.selectAllUserPatrimoines(schema))).pipe(
      switchMap(({ userPatrimoines }: { userPatrimoines: UserPatrimoine[] }) => {
        return this.getReady(schema).pipe(mapTo(userPatrimoines));
      })
    );
  }

  public selectOneUserPatrimoine(
    idUserPatrimoine: number,
    schema: SelectSchema = {}
  ): Observable<UserPatrimoine> {
    return this.store$
      .pipe(select(UserPatrimoineSelectors.selectOneUserPatrimoine(schema, idUserPatrimoine)))
      .pipe(
        switchMap(({ userPatrimoine }: { userPatrimoine: UserPatrimoine }) => {
          return this.getReady(schema).pipe(mapTo(userPatrimoine));
        })
      );
  }

  public selectAllActiveUserPatrimoines(schema: SelectSchema = {}): Observable<UserPatrimoine[]> {
    return this.store$.pipe(select(UserPatrimoineSelectors.selectActiveUserPatrimoines(schema))).pipe(
      switchMap(({ userPatrimoines }: { userPatrimoines: UserPatrimoine[] }) => {
        return this.getReady(schema).pipe(mapTo(userPatrimoines));
      })
    );
  }

  public selectIdUserPatrimoinesActive(): Observable<number[]> {
    return this.store$.pipe(select(UserPatrimoineSelectors.selectIdUserPatrimoinesActive)).pipe(
      switchMap((idUserPatrimoines: number[]) => {
        return this.getReady().pipe(mapTo(idUserPatrimoines));
      })
    );
  }

  public getOneUserPatrimoine(
    idUserPatrimoine: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<UserPatrimoine> {
    this.store$.dispatch(UserPatrimoineGeneratedActions.getOneUserPatrimoine({ idUserPatrimoine, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert,
        UserPatrimoineGeneratedActions.userPatrimoinesFailure,
        true
      );
    }
  }

  public getManyUserPatrimoines(
    params: any = {},
    getResult?: boolean
  ): void | Observable<UserPatrimoine[]> {
    this.store$.dispatch(UserPatrimoineGeneratedActions.getManyUserPatrimoines({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert,
        UserPatrimoineGeneratedActions.userPatrimoinesFailure
      );
    }
  }

  public upsertOneUserPatrimoine(
    userPatrimoine: Partial<UserPatrimoine>,
    ids: UserPatrimoineRelationsIds = {},
    getResult?: boolean
  ): void | Observable<UserPatrimoine> {
    this.store$.dispatch(UserPatrimoineGeneratedActions.upsertOneUserPatrimoine({ userPatrimoine, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert,
        UserPatrimoineGeneratedActions.userPatrimoinesFailure,
        true
      );
    }
  }

  public deleteOneUserPatrimoine(
    idUserPatrimoine: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(UserPatrimoineGeneratedActions.deleteOneUserPatrimoine({ idUserPatrimoine }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserPatrimoineGeneratedActions.deleteOneUserPatrimoineSuccess,
        UserPatrimoineGeneratedActions.userPatrimoinesFailure
      );
    }
  }

  public setActiveUserPatrimoines(idUserPatrimoines: number[]): void {
    this.store$.dispatch(UserPatrimoineGeneratedActions.clearActivesUserPatrimoines());
    this.store$.dispatch(UserPatrimoineGeneratedActions.addManyActivesUserPatrimoines({ idUserPatrimoines }));
  }
}
