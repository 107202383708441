import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PatrimoineHierarchieTemplate, PatrimoineHierarchieTemplateEntityState } from '@get/api-interfaces';
import { PatrimoineHierarchieTemplateRelationsIds } from '@get/store/ids-interfaces';

export const PatrimoineHierarchieTemplateGeneratedActions = createActionGroup({
  source: 'Patrimoine Hierarchie Template Generated',
  events: {
    'Get One Patrimoine Hierarchie Template': props<{ idPatrimoineHierarchieTemplate: number; params?: any }>(),
    'Get Many Patrimoine Hierarchie Templates': props<{ params: any }>(),
    'Add Many Actives Patrimoine Hierarchie Templates': props<{ idPatrimoineHierarchieTemplates: number[] }>(),
    'Delete One Active Patrimoine Hierarchie Template': props<{ idPatrimoineHierarchieTemplate: number }>(),
    'Clear Actives Patrimoine Hierarchie Templates': emptyProps(),
    'Upsert One Patrimoine Hierarchie Template': props<{ patrimoineHierarchieTemplate: Partial<PatrimoineHierarchieTemplate>; ids?: PatrimoineHierarchieTemplateRelationsIds; }>(),
    'Upsert Many Patrimoine Hierarchie Templates': props<{ patrimoineHierarchieTemplates: Partial<PatrimoineHierarchieTemplate>[]; ids?: PatrimoineHierarchieTemplateRelationsIds; }>(),
    'Upsert Many Patrimoine Hierarchie Templates Success': props<{ patrimoineHierarchieTemplates: PatrimoineHierarchieTemplateEntityState[] }>(),
    'Delete One Patrimoine Hierarchie Template': props<{ idPatrimoineHierarchieTemplate: number }>(),
    'Delete One Patrimoine Hierarchie Template Success': props<{ idPatrimoineHierarchieTemplate: number }>(),
    'Normalize Many Patrimoine Hierarchie Templates After Upsert': props<{ patrimoineHierarchieTemplates: PatrimoineHierarchieTemplateEntityState[] }>(),
    'Patrimoine Hierarchie Templates Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Patrimoine Hierarchie Templates': emptyProps(),
    'Add Many Societe Patrimoine Hierarchie Success': props<{ idPatrimoineHierarchieTemplate: number; idSocietePatrimoineHierarchies: number[] }>(),
    'Delete Many Societe Patrimoine Hierarchie Success': props<{ idSocietePatrimoineHierarchies: number[]; idPatrimoineHierarchieTemplates: number[] }>()
  }
});
