import { RepositoryService } from '@get/services/repository';
import { SocieteTerritoirePatrimoine } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteTerritoirePatrimoineApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteTerritoirePatrimoines(params?: any): Observable<SocieteTerritoirePatrimoine[]> {
    return this.repo.getData<SocieteTerritoirePatrimoine[]>('societe-territoire-patrimoine', params);
  }

  public getSocieteTerritoirePatrimoine(params: { idSocieteTerritoirePatrimoine: number; params?: any }): Observable<SocieteTerritoirePatrimoine> {
    return this.repo.getData<SocieteTerritoirePatrimoine>('societe-territoire-patrimoine/' + params.idSocieteTerritoirePatrimoine, params.params);
  }

  public addSocieteTerritoirePatrimoine(societeTerritoirePatrimoine: Partial<SocieteTerritoirePatrimoine>): Observable<SocieteTerritoirePatrimoine> {
    return this.repo.create<SocieteTerritoirePatrimoine>('societe-territoire-patrimoine', societeTerritoirePatrimoine);
  }

  public updateSocieteTerritoirePatrimoine(societeTerritoirePatrimoine: Partial<SocieteTerritoirePatrimoine>): Observable<SocieteTerritoirePatrimoine> {
    return this.repo.update('societe-territoire-patrimoine', societeTerritoirePatrimoine);
  }

  public deleteSocieteTerritoirePatrimoine(idSocieteTerritoirePatrimoine: number): Observable<number> {
    return this.repo.delete('societe-territoire-patrimoine/' + +idSocieteTerritoirePatrimoine);
  }
}

