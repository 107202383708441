import { RepositoryService } from '@get/services/repository';
import { ComposantTemplate } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedComposantTemplateApiService {
  constructor(protected repo: RepositoryService) {}

  public getComposantTemplates(params?: any): Observable<ComposantTemplate[]> {
    return this.repo.getData<ComposantTemplate[]>('composant-template', params);
  }

  public getComposantTemplate(params: { idComposantTemplate: number; params?: any }): Observable<ComposantTemplate> {
    return this.repo.getData<ComposantTemplate>('composant-template/' + params.idComposantTemplate, params.params);
  }

  public addComposantTemplate(composantTemplate: Partial<ComposantTemplate>): Observable<ComposantTemplate> {
    return this.repo.create<ComposantTemplate>('composant-template', composantTemplate);
  }

  public updateComposantTemplate(composantTemplate: Partial<ComposantTemplate>): Observable<ComposantTemplate> {
    return this.repo.update('composant-template', composantTemplate);
  }

  public deleteComposantTemplate(idComposantTemplate: number): Observable<number> {
    return this.repo.delete('composant-template/' + +idComposantTemplate);
  }
}

