import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocieteComposantState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteComposantEntityState } from '@get/api-interfaces';

export const societeComposantReducersGeneratedFunctions: ReducerTypes<SocieteComposantState.IState, readonly ActionCreator[]>[] = [
  on(SocieteComposantGeneratedActions.getOneSocieteComposant, (state: SocieteComposantState.IState) => setLoadingsState(state, true)),
  on(SocieteComposantGeneratedActions.getManySocieteComposants, (state: SocieteComposantState.IState) => setLoadingsState(state, true)),
  on(SocieteComposantGeneratedActions.upsertOneSocieteComposant, (state: SocieteComposantState.IState) => setLoadingsState(state, true)),

  on(SocieteComposantGeneratedActions.upsertManySocieteComposantsSuccess, (state: SocieteComposantState.IState, { societeComposants }) =>
    SocieteComposantState.adapter.upsertMany(societeComposants, setLoadingsState(state, false))
  ),
  on(SocieteComposantGeneratedActions.deleteOneSocieteComposant, (state: SocieteComposantState.IState) => setLoadingsState(state, true)),
  on(SocieteComposantGeneratedActions.deleteOneSocieteComposantSuccess, (state: SocieteComposantState.IState, { idSocieteComposant }) =>
    SocieteComposantState.adapter.removeOne(idSocieteComposant, setLoadingsState(state, false))
  ),
  on(SocieteComposantGeneratedActions.clearActivesSocieteComposants, (state: SocieteComposantState.IState) => ({ ...state, actives: [] })),
  on(SocieteComposantGeneratedActions.addManyActivesSocieteComposants, (state: SocieteComposantState.IState, { idSocieteComposants }) => ({
    ...state,
    actives: state.actives.concat(idSocieteComposants)
  })),
  on(SocieteComposantGeneratedActions.deleteOneActiveSocieteComposant, (state: SocieteComposantState.IState, { idSocieteComposant }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idSocieteComposant)
  })),

  on(SocieteComposantGeneratedActions.clearSocieteComposants, () => SocieteComposantState.initialState),

  on(
    SocieteComposantGeneratedActions.addManySocieteCaracteristiqueSuccess,
    (state: SocieteComposantState.IState, { idSocieteComposant, idSocieteCaracteristiques }) => {
      if (!state.entities[idSocieteComposant]) {
        return state;
      }
      const societeCaracteristiques = (state.entities[idSocieteComposant]?.societeCaracteristiques as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposant]: {
            ...state.entities[idSocieteComposant],
            societeCaracteristiques: societeCaracteristiques.concat(
              idSocieteCaracteristiques.filter(id => societeCaracteristiques.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.deleteManySocieteCaracteristiqueSuccess,
    (state: SocieteComposantState.IState, { idSocieteCaracteristiques, idSocieteComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposants.reduce((entities, idSocieteComposant) => {
            if (!state.entities[idSocieteComposant]?.societeCaracteristiques) {
              return entities;
            }
            entities[idSocieteComposant] = {
              ...state.entities[idSocieteComposant],
              societeCaracteristiques: (state.entities[idSocieteComposant]?.societeCaracteristiques as number[])?.filter(
                (idSocieteCaracteristique: number) => !idSocieteCaracteristiques.some((id: number) => id === idSocieteCaracteristique)
              )
            } as SocieteComposantEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.addManyComposantSuccess,
    (state: SocieteComposantState.IState, { idSocieteComposant, idComposants }) => {
      if (!state.entities[idSocieteComposant]) {
        return state;
      }
      const composants = (state.entities[idSocieteComposant]?.composants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposant]: {
            ...state.entities[idSocieteComposant],
            composants: composants.concat(
              idComposants.filter(id => composants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.deleteManyComposantSuccess,
    (state: SocieteComposantState.IState, { idComposants, idSocieteComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposants.reduce((entities, idSocieteComposant) => {
            if (!state.entities[idSocieteComposant]?.composants) {
              return entities;
            }
            entities[idSocieteComposant] = {
              ...state.entities[idSocieteComposant],
              composants: (state.entities[idSocieteComposant]?.composants as number[])?.filter(
                (idComposant: number) => !idComposants.some((id: number) => id === idComposant)
              )
            } as SocieteComposantEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.addManyComposantAttenduSuccess,
    (state: SocieteComposantState.IState, { idSocieteComposant, idComposantAttendus }) => {
      if (!state.entities[idSocieteComposant]) {
        return state;
      }
      const composantAttendus = (state.entities[idSocieteComposant]?.composantAttendus as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposant]: {
            ...state.entities[idSocieteComposant],
            composantAttendus: composantAttendus.concat(
              idComposantAttendus.filter(id => composantAttendus.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.deleteManyComposantAttenduSuccess,
    (state: SocieteComposantState.IState, { idComposantAttendus, idSocieteComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposants.reduce((entities, idSocieteComposant) => {
            if (!state.entities[idSocieteComposant]?.composantAttendus) {
              return entities;
            }
            entities[idSocieteComposant] = {
              ...state.entities[idSocieteComposant],
              composantAttendus: (state.entities[idSocieteComposant]?.composantAttendus as number[])?.filter(
                (idComposantAttendu: number) => !idComposantAttendus.some((id: number) => id === idComposantAttendu)
              )
            } as SocieteComposantEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.addManySocieteComposantRattachementSuccess,
    (state: SocieteComposantState.IState, { idSocieteComposant, idSocieteComposantRattachements }) => {
      if (!state.entities[idSocieteComposant]) {
        return state;
      }
      const societeComposantRattachements = (state.entities[idSocieteComposant]?.societeComposantRattachements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposant]: {
            ...state.entities[idSocieteComposant],
            societeComposantRattachements: societeComposantRattachements.concat(
              idSocieteComposantRattachements.filter(id => societeComposantRattachements.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.deleteManySocieteComposantRattachementSuccess,
    (state: SocieteComposantState.IState, { idSocieteComposantRattachements, idSocieteComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposants.reduce((entities, idSocieteComposant) => {
            if (!state.entities[idSocieteComposant]?.societeComposantRattachements) {
              return entities;
            }
            entities[idSocieteComposant] = {
              ...state.entities[idSocieteComposant],
              societeComposantRattachements: (state.entities[idSocieteComposant]?.societeComposantRattachements as number[])?.filter(
                (idSocieteComposantRattachement: number) => !idSocieteComposantRattachements.some((id: number) => id === idSocieteComposantRattachement)
              )
            } as SocieteComposantEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.addSocieteSuccess,
    (state: SocieteComposantState.IState, { idSocieteComposant, idSociete }) => {
      if (!state.entities[idSocieteComposant]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposant]: {
            ...state.entities[idSocieteComposant],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.deleteManySocieteSuccess,
    (state: SocieteComposantState.IState, { idSocietes, idSocieteComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposants.reduce((entities, idSocieteComposant) => {
            if (!state.entities[idSocieteComposant]?.societe) {
              return entities;
            }
            entities[idSocieteComposant] = {
              ...state.entities[idSocieteComposant],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idSocieteComposant]?.societe
              )
                ? undefined
                : state.entities[idSocieteComposant]?.societe
            } as SocieteComposantEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.addComposantTemplateSuccess,
    (state: SocieteComposantState.IState, { idSocieteComposant, idComposantTemplate }) => {
      if (!state.entities[idSocieteComposant]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposant]: {
            ...state.entities[idSocieteComposant],
            composantTemplate: idComposantTemplate
          }
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.deleteManyComposantTemplateSuccess,
    (state: SocieteComposantState.IState, { idComposantTemplates, idSocieteComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposants.reduce((entities, idSocieteComposant) => {
            if (!state.entities[idSocieteComposant]?.composantTemplate) {
              return entities;
            }
            entities[idSocieteComposant] = {
              ...state.entities[idSocieteComposant],
              composantTemplate: idComposantTemplates.some(
                (idComposantTemplate: number) => idComposantTemplate === state.entities[idSocieteComposant]?.composantTemplate
              )
                ? undefined
                : state.entities[idSocieteComposant]?.composantTemplate
            } as SocieteComposantEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.addSocieteComposantFamilleSuccess,
    (state: SocieteComposantState.IState, { idSocieteComposant, idSocieteComposantFamille }) => {
      if (!state.entities[idSocieteComposant]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposant]: {
            ...state.entities[idSocieteComposant],
            societeComposantFamille: idSocieteComposantFamille
          }
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.deleteManySocieteComposantFamilleSuccess,
    (state: SocieteComposantState.IState, { idSocieteComposantFamilles, idSocieteComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposants.reduce((entities, idSocieteComposant) => {
            if (!state.entities[idSocieteComposant]?.societeComposantFamille) {
              return entities;
            }
            entities[idSocieteComposant] = {
              ...state.entities[idSocieteComposant],
              societeComposantFamille: idSocieteComposantFamilles.some(
                (idSocieteComposantFamille: number) => idSocieteComposantFamille === state.entities[idSocieteComposant]?.societeComposantFamille
              )
                ? undefined
                : state.entities[idSocieteComposant]?.societeComposantFamille
            } as SocieteComposantEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.addComposantGroupeSuccess,
    (state: SocieteComposantState.IState, { idSocieteComposant, idComposantGroupe }) => {
      if (!state.entities[idSocieteComposant]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposant]: {
            ...state.entities[idSocieteComposant],
            composantGroupe: idComposantGroupe
          }
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.deleteManyComposantGroupeSuccess,
    (state: SocieteComposantState.IState, { idComposantGroupes, idSocieteComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposants.reduce((entities, idSocieteComposant) => {
            if (!state.entities[idSocieteComposant]?.composantGroupe) {
              return entities;
            }
            entities[idSocieteComposant] = {
              ...state.entities[idSocieteComposant],
              composantGroupe: idComposantGroupes.some(
                (idComposantGroupe: number) => idComposantGroupe === state.entities[idSocieteComposant]?.composantGroupe
              )
                ? undefined
                : state.entities[idSocieteComposant]?.composantGroupe
            } as SocieteComposantEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantEntityState>)
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.addFichierSuccess,
    (state: SocieteComposantState.IState, { idSocieteComposant, idFichier }) => {
      if (!state.entities[idSocieteComposant]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteComposant]: {
            ...state.entities[idSocieteComposant],
            fichier: idFichier
          }
        }
      };
    }
  ),

  on(
    SocieteComposantGeneratedActions.deleteManyFichierSuccess,
    (state: SocieteComposantState.IState, { idFichiers, idSocieteComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteComposants.reduce((entities, idSocieteComposant) => {
            if (!state.entities[idSocieteComposant]?.fichier) {
              return entities;
            }
            entities[idSocieteComposant] = {
              ...state.entities[idSocieteComposant],
              fichier: idFichiers.some(
                (idFichier: number) => idFichier === state.entities[idSocieteComposant]?.fichier
              )
                ? undefined
                : state.entities[idSocieteComposant]?.fichier
            } as SocieteComposantEntityState;
            return entities;
          }, {} as Dictionary<SocieteComposantEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteComposantState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): SocieteComposantState.IState {
  return { ...state, isLoaded, isLoading };
}
