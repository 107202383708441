import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { CaracteristiqueChoixTemplate, CaracteristiqueChoixTemplateEntityState } from '@get/api-interfaces';
import { CaracteristiqueChoixTemplateApiService } from '@get/store/api-services';
import { CaracteristiqueChoixTemplateGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeCaracteristiqueChoixTemplate } from '@get/store/configs/normalization';
import { CaracteristiqueChoixTemplateSelectors } from '@get/store/selectors';
import { CaracteristiqueChoixTemplateRelationsIds } from '@get/store/ids-interfaces';
import { CaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { FichierGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoix } from '@get/api-interfaces';

export function getDefaultAddCaracteristiqueChoixTemplateActions(caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateEntityState, ids?: CaracteristiqueChoixTemplateRelationsIds): Action[] {
  const actions: Action[] = [CaracteristiqueChoixTemplateGeneratedActions.normalizeManyCaracteristiqueChoixTemplatesAfterUpsert({ caracteristiqueChoixTemplates: [caracteristiqueChoixTemplate] })];

  if (ids?.caracteristiqueTemplate) {
    actions.push(
      CaracteristiqueTemplateGeneratedActions.addManyCaracteristiqueChoixTemplateSuccess({
        idCaracteristiqueTemplate: ids.caracteristiqueTemplate,
        idCaracteristiqueChoixTemplates: [caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate]
      })
    );
    actions.push(
      CaracteristiqueChoixTemplateGeneratedActions.addCaracteristiqueTemplateSuccess({
        idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
        idCaracteristiqueTemplate: ids.caracteristiqueTemplate
      })
    );
  }

  if (ids?.fichier) {
    actions.push(
      FichierGeneratedActions.addManyCaracteristiqueChoixTemplateSuccess({
        idFichier: ids.fichier,
        idCaracteristiqueChoixTemplates: [caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate]
      })
    );
    actions.push(
      CaracteristiqueChoixTemplateGeneratedActions.addFichierSuccess({
        idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
        idFichier: ids.fichier
      })
    );
  }

  if (ids?.societeCaracteristiqueChoices) {
    if (!Array.isArray(ids.societeCaracteristiqueChoices)) {
      actions.push(
        SocieteCaracteristiqueChoixGeneratedActions.upsertOneSocieteCaracteristiqueChoix({
          societeCaracteristiqueChoix: {
            idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
            idSocieteCaracteristiqueChoix: ids.societeCaracteristiqueChoices as number
          } as SocieteCaracteristiqueChoix
        })
      );
      actions.push(
        CaracteristiqueChoixTemplateGeneratedActions.addManySocieteCaracteristiqueChoixSuccess({
          idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
          idSocieteCaracteristiqueChoices: [ids.societeCaracteristiqueChoices as number]
        })
      );
    } else {
      actions.push(
        SocieteCaracteristiqueChoixGeneratedActions.upsertManySocieteCaracteristiqueChoices({
          societeCaracteristiqueChoices: (ids.societeCaracteristiqueChoices as number[]).map(
            (idSocieteCaracteristiqueChoix: number) => ({
              idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
              idSocieteCaracteristiqueChoix
            })
          ) as SocieteCaracteristiqueChoix[]
        })
      );
      actions.push(
        CaracteristiqueChoixTemplateGeneratedActions.addManySocieteCaracteristiqueChoixSuccess({
          idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate,
          idSocieteCaracteristiqueChoices: ids.societeCaracteristiqueChoices as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCaracteristiqueChoixTemplateActions(caracteristiqueChoixTemplate: CaracteristiqueChoixTemplateEntityState): Action[] {
  const actions: Action[] = [CaracteristiqueChoixTemplateGeneratedActions.deleteOneCaracteristiqueChoixTemplateSuccess({ idCaracteristiqueChoixTemplate: caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate })];

  if (caracteristiqueChoixTemplate.caracteristiqueTemplate) {
    actions.push(
      CaracteristiqueTemplateGeneratedActions.deleteManyCaracteristiqueChoixTemplateSuccess({
        idCaracteristiqueChoixTemplates: [caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate],
        idCaracteristiqueTemplates: [caracteristiqueChoixTemplate.caracteristiqueTemplate as number]
      })
    );
  }

  if (caracteristiqueChoixTemplate.fichier) {
    actions.push(
      FichierGeneratedActions.deleteManyCaracteristiqueChoixTemplateSuccess({
        idCaracteristiqueChoixTemplates: [caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate],
        idFichiers: [caracteristiqueChoixTemplate.fichier as number]
      })
    );
  }

  if (caracteristiqueChoixTemplate.societeCaracteristiqueChoices) {
    actions.push(
      SocieteCaracteristiqueChoixGeneratedActions.deleteManyCaracteristiqueChoixTemplateSuccess({
        idCaracteristiqueChoixTemplates: [caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate],
        idSocieteCaracteristiqueChoices: caracteristiqueChoixTemplate.societeCaracteristiqueChoices as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCaracteristiqueChoixTemplateEffects {
  constructor(
    protected actions$: Actions,
    protected caracteristiqueChoixTemplateApiService: CaracteristiqueChoixTemplateApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCaracteristiqueChoixTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaracteristiqueChoixTemplateGeneratedActions.getManyCaracteristiqueChoixTemplates),
      switchMap(({ params }) =>
        this.caracteristiqueChoixTemplateApiService.getCaracteristiqueChoixTemplates(params).pipe(
          map((caracteristiqueChoixTemplates: CaracteristiqueChoixTemplate[]) => {
            return CaracteristiqueChoixTemplateGeneratedActions.normalizeManyCaracteristiqueChoixTemplatesAfterUpsert({ caracteristiqueChoixTemplates });
          }),
          catchError(error => of(CaracteristiqueChoixTemplateGeneratedActions.caracteristiqueChoixTemplatesFailure({ error })))
        )
      )
    );
  });

  getOneCaracteristiqueChoixTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaracteristiqueChoixTemplateGeneratedActions.getOneCaracteristiqueChoixTemplate),
      switchMap(idCaracteristiqueChoixTemplate =>
        this.caracteristiqueChoixTemplateApiService.getCaracteristiqueChoixTemplate(idCaracteristiqueChoixTemplate).pipe(
          map((caracteristiqueChoixTemplate: CaracteristiqueChoixTemplate) => {
            return CaracteristiqueChoixTemplateGeneratedActions.normalizeManyCaracteristiqueChoixTemplatesAfterUpsert({ caracteristiqueChoixTemplates: [caracteristiqueChoixTemplate] });
          }),
          catchError(error => of(CaracteristiqueChoixTemplateGeneratedActions.caracteristiqueChoixTemplatesFailure({ error })))
        )
      )
    );
  });

  upsertOneCaracteristiqueChoixTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaracteristiqueChoixTemplateGeneratedActions.upsertOneCaracteristiqueChoixTemplate),
      concatMap(
        ({
          caracteristiqueChoixTemplate,
          ids
        }: {
          caracteristiqueChoixTemplate: Partial<CaracteristiqueChoixTemplate>;
          ids?: CaracteristiqueChoixTemplateRelationsIds;
        }) => {
          if (caracteristiqueChoixTemplate.idCaracteristiqueChoixTemplate) {
            return this.caracteristiqueChoixTemplateApiService.updateCaracteristiqueChoixTemplate(caracteristiqueChoixTemplate).pipe(
              map((caracteristiqueChoixTemplateReturned: CaracteristiqueChoixTemplate) => {
                return CaracteristiqueChoixTemplateGeneratedActions.normalizeManyCaracteristiqueChoixTemplatesAfterUpsert({ caracteristiqueChoixTemplates: [caracteristiqueChoixTemplateReturned] });
              }),
              catchError(error => of(CaracteristiqueChoixTemplateGeneratedActions.caracteristiqueChoixTemplatesFailure({ error })))
            );
          } else {
            return this.caracteristiqueChoixTemplateApiService.addCaracteristiqueChoixTemplate(caracteristiqueChoixTemplate).pipe(
              mergeMap((caracteristiqueChoixTemplateReturned: CaracteristiqueChoixTemplate) => getDefaultAddCaracteristiqueChoixTemplateActions(caracteristiqueChoixTemplateReturned, ids)),
              catchError(error => of(CaracteristiqueChoixTemplateGeneratedActions.caracteristiqueChoixTemplatesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCaracteristiqueChoixTemplate$ = createEffect(() => {
    const selectCaracteristiqueChoixTemplateState$ = this.store$.select(CaracteristiqueChoixTemplateSelectors.selectCaracteristiqueChoixTemplateState);
    return this.actions$.pipe(
      ofType(CaracteristiqueChoixTemplateGeneratedActions.deleteOneCaracteristiqueChoixTemplate),
      withLatestFrom(selectCaracteristiqueChoixTemplateState$),
      concatMap(([{ idCaracteristiqueChoixTemplate }, state]) =>
        this.caracteristiqueChoixTemplateApiService.deleteCaracteristiqueChoixTemplate(idCaracteristiqueChoixTemplate).pipe(
          mergeMap(_success => getDefaultDeleteCaracteristiqueChoixTemplateActions(state.entities[idCaracteristiqueChoixTemplate] as CaracteristiqueChoixTemplateEntityState)),
          catchError(error => of(CaracteristiqueChoixTemplateGeneratedActions.caracteristiqueChoixTemplatesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCaracteristiqueChoixTemplatesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaracteristiqueChoixTemplateGeneratedActions.normalizeManyCaracteristiqueChoixTemplatesAfterUpsert),
      concatMap(({ caracteristiqueChoixTemplates }) => {
        const actions: Action[] = getActionsToNormalizeCaracteristiqueChoixTemplate(caracteristiqueChoixTemplates, StoreActionType.upsert);
        return [getMultiAction(actions, '[CaracteristiqueChoixTemplate] Normalization After Upsert Success')];
      })
    );
  });
}
