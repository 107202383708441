import { DynamicType } from '@get/api-interfaces';

export function formatCodePostal(codePostal: number): string {
  return codePostal?.toString()?.padStart(5, '0');
}

export function aggregateAddresses(addresses: string[], oldAddress: string | null = null): string | null {
  const numbersObj: DynamicType<boolean> = {};
  const addressAfterNumbersObj: DynamicType<boolean> = {};
  for (let i = 0; i < addresses?.length; i++) {
    if (addresses[i]) {
      const address = addresses[i];
      const words = address.matchAll(/(\w+)/gi);
      for (const word of words) {
        const nb = +word[0];
        if (isNaN(nb)) {
          addressAfterNumbersObj[address.slice(word.index)] = true;
          break;
        }
        numbersObj[nb] = true;
      }
    }
  }
  const addressAfterNumbers = Object.keys(addressAfterNumbersObj);
  if (addressAfterNumbers.length === 1) {
    const nbs = Object.keys(numbersObj)
      .map(nb => +nb)
      .sort((a, b) => a - b);

    const { nbValues } = nbs.reduce(
      (acc, nb, idx) => {
        if (!acc.last) {
          acc.start = nb;
          acc.nbValues.push(nb);
        } else {
          const isInCloseRange = nb - acc.last >= 1 && nb - acc.last <= 2;
          if (!isInCloseRange && acc.start !== acc.last) {
            acc.start = nb;
            acc.nbValues.push('-');
            acc.nbValues.push(acc.last);
            acc.nbValues.push('|');
            acc.nbValues.push(nb);
          } else if (idx === nbs.length - 1 && isInCloseRange) {
            acc.start = nb;
            acc.nbValues.push('-');
            acc.nbValues.push(nb);
          } else if (!isInCloseRange && (idx === nbs.length - 1 || acc.start === acc.last)) {
            acc.start = nb;
            acc.nbValues.push('|');
            acc.nbValues.push(nb);
          }
        }
        acc.last = nb;
        return acc;
      },
      { last: undefined, start: undefined, nbValues: [] } as {
        last?: number;
        start?: number;
        nbValues: (string | number)[];
      }
    );
    return `${nbValues.join('')} ${addressAfterNumbers[0]}`;
  }
  return oldAddress || null;
}
