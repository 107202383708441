import { RepositoryService } from '@get/services/repository';
import { ValeurFichier } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedValeurFichierApiService {
  constructor(protected repo: RepositoryService) {}

  public getValeurFichiers(params?: any): Observable<ValeurFichier[]> {
    return this.repo.getData<ValeurFichier[]>('valeur-fichier', params);
  }

  public getValeurFichier(params: { idValeurFichier: number; params?: any }): Observable<ValeurFichier> {
    return this.repo.getData<ValeurFichier>('valeur-fichier/' + params.idValeurFichier, params.params);
  }

  public addValeurFichier(valeurFichier: Partial<ValeurFichier>): Observable<ValeurFichier> {
    return this.repo.create<ValeurFichier>('valeur-fichier', valeurFichier);
  }

  public updateValeurFichier(valeurFichier: Partial<ValeurFichier>): Observable<ValeurFichier> {
    return this.repo.update('valeur-fichier', valeurFichier);
  }

  public deleteValeurFichier(idValeurFichier: number): Observable<number> {
    return this.repo.delete('valeur-fichier/' + +idValeurFichier);
  }
}

