import { Injectable } from '@angular/core';
import { DynamicType, Organisation, User } from '@get/api-interfaces';
import { MyDatabaseCollections } from '@get/interfaces';
import { DbService, StorageService } from '@get/services/storage';
import {
  ComposantGroupeGeneratedActions,
  DroitGeneratedActions,
  FichierGeneratedActions,
  OrganisationActions,
  OrganisationGeneratedActions,
  SocieteComposantGeneratedActions
} from '@get/store/actions';
import {
  ComposantGroupeApiService,
  DroitApiService,
  FichierApiService,
  OrganisationApiService,
  SocieteComposantApiService
} from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import {
  DroitModel,
  SocieteModel,
  SocietePatrimoineHierarchieModel,
  SocieteProfilModel,
  UserModel,
  UserPatrimoineModel
} from '@get/store/selectors-model';
import { OrganisationService, UserService } from '@get/store/services';
import { Action, Store } from '@ngrx/store';
import { parseJwt } from '@utils';
import { RxCollection } from 'rxdb';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { concatMap, first, pairwise, startWith, takeUntil, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppResolver {
  private databaseCollections: MyDatabaseCollections;

  // Sert à couper les subscribes si le resolve est appelé à nouveau
  private resolveSubject$ = new Subject<void>();

  // Servent à accéder à l'app lorsque ces stores sont remplis car ils sont nécessaires au bon fonctionnement de l'app
  private orgasSubject$ = new ReplaySubject<void>(1);
  private usersSubject$ = new ReplaySubject<void>(1);

  constructor(
    private organisationService: OrganisationService,
    private storageService: StorageService,
    private userService: UserService,
    private dbService: DbService,
    private organisationApiService: OrganisationApiService,
    private droitApiService: DroitApiService,
    private fichierApiService: FichierApiService,
    private societeComposantApiService: SocieteComposantApiService,
    private composantGroupeApiService: ComposantGroupeApiService,
    private store$: Store<AppState>
  ) {}

  private async handleOrganisations(idUser: number): Promise<void> {
    this.handleIndexedDbRefillingStorage({
      tableKey: 'organisations',
      primaryKey: 'idOrganisation',
      storeKey: 'organisations',
      normalizationMethod: OrganisationGeneratedActions.normalizeManyOrganisationsAfterUpsert
    });

    this.organisationService
      .selectAllOrganisations({
        include: [{ model: SocieteModel, include: [SocietePatrimoineHierarchieModel] }, UserModel]
      })
      .pipe(
        takeUntil(this.resolveSubject$),
        tap((organisations: Organisation[]) => {
          this.organisationService.setCurrentOrganisation(organisations[0]);
          this.orgasSubject$.next();
        })
      )
      .subscribe();

    this.userService
      .selectOneUser(idUser, { include: [UserPatrimoineModel, { model: SocieteProfilModel, include: [DroitModel] }] })
      .pipe(
        takeUntil(this.resolveSubject$),
        tap((user: User) => {
          this.userService.updateCurrentUser(user);
          this.usersSubject$.next();
        })
      )
      .subscribe();

    setTimeout(() => {
      this.organisationApiService
        .getUserOrganisation({ idUser })
        .pipe(
          first(),
          tap(async organisation => {
            this.store$.dispatch(OrganisationActions.upsertOrganisationOnAppInit({ organisation }));
            this.databaseCollections['organisations'].upsert({
              ...organisation,
              idOrganisation: organisation.idOrganisation?.toString()
            });
            const storedOrgas = await this.databaseCollections['organisations'].find().exec();
            const elementsToRemove =
              storedOrgas?.filter(orga => +orga.idOrganisation !== organisation.idOrganisation) || [];
            if (elementsToRemove?.length) {
              this.databaseCollections['organisations'].bulkRemove(elementsToRemove.map(el => el.idOrganisation));
            }
          })
        )
        .subscribe();
    }, 0);
  }

  // TODO: Déplacer les 2 fonctions dans des utils ?! (pour l'instant pas nécessaire mais si besoin le faire)
  private async handleIndexedDbRefillingStorage<T>(params: {
    tableKey: keyof MyDatabaseCollections;
    storeKey: keyof DynamicType<T>;
    primaryKey: keyof T;
    normalizationMethod: (param: DynamicType<T[]>) => Action;
  }): Promise<void> {
    (this.databaseCollections[params.tableKey] as RxCollection)
      .find()
      .$.pipe(
        takeUntil(this.resolveSubject$),
        startWith([]),
        pairwise(),
        tap(([previous, current]) => {
          const previousValues = previous?.map(el => el.toJSON());
          const currentValues = current?.map(el => el.toJSON());
          const keysComparison =
            previous?.length !== current?.length || JSON.stringify(previousValues) !== JSON.stringify(currentValues);
          if (current.length && keysComparison) {
            this.store$.dispatch(
              params.normalizationMethod({
                [params.storeKey]: current.map(el => ({
                  ...el.toJSON(),
                  [params.primaryKey]: +el[params.primaryKey]
                }))
              })
            );
          }
        })
      )
      .subscribe();
  }

  private async handleIndexedDbRoute<T>(params: {
    tableKey: keyof MyDatabaseCollections;
    storeKey: keyof DynamicType<T>;
    primaryKey: keyof T;
    normalizationMethod: (param: DynamicType<T[]>) => Action;
    shouldCleanup?: boolean;
    apiMethod: () => Observable<T[]>;
  }): Promise<void> {
    this.handleIndexedDbRefillingStorage(params);

    setTimeout(() => {
      params
        .apiMethod()
        .pipe(
          first(),
          tap(async elements => {
            if (elements?.length) {
              this.store$.dispatch(
                params.normalizationMethod({
                  [params.storeKey]: elements
                })
              );
              this.databaseCollections[params.tableKey].bulkUpsert(
                elements.map(el => ({
                  ...el,
                  [params.primaryKey]: el[params.primaryKey]?.toString()
                }))
              );
              if (params.shouldCleanup) {
                const obj = elements.reduce((acc, curr) => {
                  acc[curr[params.primaryKey] as number] = true;
                  return acc;
                }, {} as DynamicType<boolean>);
                // TODO: Check si c'est possible de typer (ça paraît compliqué)
                const storedElements: any[] = await this.databaseCollections[params.tableKey].find().exec();
                const elementsToRemove = storedElements?.filter(elem => !obj[elem[params.primaryKey]]) || [];
                if (elementsToRemove?.length) {
                  this.databaseCollections[params.tableKey].bulkRemove(
                    elementsToRemove.map(el => el[params.primaryKey])
                  );
                }
              }
            }
          })
        )
        .subscribe();
    }, 0);
  }

  private async handleInitialisationRoutes(idUser: number): Promise<void> {
    this.databaseCollections = await this.dbService.getDatabaseCollection();

    this.handleOrganisations(idUser);

    setTimeout(() => {
      this.handleIndexedDbRoute({
        tableKey: 'droits',
        primaryKey: 'idDroit',
        storeKey: 'droits',
        shouldCleanup: true,
        normalizationMethod: DroitGeneratedActions.normalizeManyDroitsAfterUpsert,
        apiMethod: this.droitApiService.getDroits.bind(this.droitApiService)
      });
      this.handleIndexedDbRoute({
        tableKey: 'composant-groupes',
        primaryKey: 'idComposantGroupe',
        storeKey: 'composantGroupes',
        shouldCleanup: true,
        normalizationMethod: ComposantGroupeGeneratedActions.normalizeManyComposantGroupesAfterUpsert,
        apiMethod: this.composantGroupeApiService.getComposantGroupes.bind(this.composantGroupeApiService)
      });
      this.handleIndexedDbRoute({
        tableKey: 'fichiers',
        primaryKey: 'idFichier',
        storeKey: 'fichiers',
        shouldCleanup: true,
        normalizationMethod: FichierGeneratedActions.normalizeManyFichiersAfterUpsert,
        apiMethod: this.fichierApiService.getFichiers.bind(this.fichierApiService)
      });
      this.handleIndexedDbRoute({
        tableKey: 'societe-composant-descriptions',
        primaryKey: 'idSocieteComposant',
        storeKey: 'societeComposants',
        shouldCleanup: true,
        normalizationMethod: SocieteComposantGeneratedActions.normalizeManySocieteComposantsAfterUpsert,
        apiMethod: this.societeComposantApiService.getSocieteComposantDescriptions.bind(this.societeComposantApiService)
      });
    }, 0);
  }

  public resolve(): Observable<void> {
    this.usersSubject$ = new ReplaySubject<void>();
    this.orgasSubject$ = new ReplaySubject<void>();

    this.resolveSubject$.next();
    const decodedToken = parseJwt(this.storageService.getLocal('login')?.token);
    const idUser = decodedToken?.idUser;
    if (!idUser) {
      throw new Error('No idUser in token');
    }
    this.userService.setActiveUsers([idUser]);
    this.handleInitialisationRoutes(idUser);
    return this.orgasSubject$.pipe(concatMap(() => this.usersSubject$));
  }
}
