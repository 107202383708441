import { RepositoryService } from '@get/services/repository';
import { SocietePatrimoineHierarchie } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocietePatrimoineHierarchieApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocietePatrimoineHierarchies(params?: any): Observable<SocietePatrimoineHierarchie[]> {
    return this.repo.getData<SocietePatrimoineHierarchie[]>('societe-patrimoine-hierarchie', params);
  }

  public getSocietePatrimoineHierarchie(params: { idSocietePatrimoineHierarchie: number; params?: any }): Observable<SocietePatrimoineHierarchie> {
    return this.repo.getData<SocietePatrimoineHierarchie>('societe-patrimoine-hierarchie/' + params.idSocietePatrimoineHierarchie, params.params);
  }

  public addSocietePatrimoineHierarchie(societePatrimoineHierarchie: Partial<SocietePatrimoineHierarchie>): Observable<SocietePatrimoineHierarchie> {
    return this.repo.create<SocietePatrimoineHierarchie>('societe-patrimoine-hierarchie', societePatrimoineHierarchie);
  }

  public updateSocietePatrimoineHierarchie(societePatrimoineHierarchie: Partial<SocietePatrimoineHierarchie>): Observable<SocietePatrimoineHierarchie> {
    return this.repo.update('societe-patrimoine-hierarchie', societePatrimoineHierarchie);
  }

  public deleteSocietePatrimoineHierarchie(idSocietePatrimoineHierarchie: number): Observable<number> {
    return this.repo.delete('societe-patrimoine-hierarchie/' + +idSocietePatrimoineHierarchie);
  }
}

