import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ComposantGroupe, ComposantGroupeEntityState } from '@get/api-interfaces';
import { ComposantGroupeRelationsIds } from '@get/store/ids-interfaces';

export const ComposantGroupeGeneratedActions = createActionGroup({
  source: 'Composant Groupe Generated',
  events: {
    'Get One Composant Groupe': props<{ idComposantGroupe: number; params?: any }>(),
    'Get Many Composant Groupes': props<{ params: any }>(),
    'Add Many Actives Composant Groupes': props<{ idComposantGroupes: number[] }>(),
    'Delete One Active Composant Groupe': props<{ idComposantGroupe: number }>(),
    'Clear Actives Composant Groupes': emptyProps(),
    'Upsert One Composant Groupe': props<{ composantGroupe: Partial<ComposantGroupe>; ids?: ComposantGroupeRelationsIds; }>(),
    'Upsert Many Composant Groupes': props<{ composantGroupes: Partial<ComposantGroupe>[]; ids?: ComposantGroupeRelationsIds; }>(),
    'Upsert Many Composant Groupes Success': props<{ composantGroupes: ComposantGroupeEntityState[] }>(),
    'Delete One Composant Groupe': props<{ idComposantGroupe: number }>(),
    'Delete One Composant Groupe Success': props<{ idComposantGroupe: number }>(),
    'Normalize Many Composant Groupes After Upsert': props<{ composantGroupes: ComposantGroupeEntityState[] }>(),
    'Composant Groupes Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Composant Groupes': emptyProps(),
    'Add Many Societe Composant Success': props<{ idComposantGroupe: number; idSocieteComposants: number[] }>(),
    'Delete Many Societe Composant Success': props<{ idSocieteComposants: number[]; idComposantGroupes: number[] }>(),
    'Add Many Composant Template Success': props<{ idComposantGroupe: number; idComposantTemplates: number[] }>(),
    'Delete Many Composant Template Success': props<{ idComposantTemplates: number[]; idComposantGroupes: number[] }>(),
    'Add Fichier Success': props<{ idComposantGroupe: number; idFichier: number }>(),
    'Delete Many Fichier Success': props<{ idFichiers: number[]; idComposantGroupes: number[] }>()
  }
});
