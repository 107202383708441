import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { NewsletterGeneratedActions } from '@get/store/actions';
import { NewsletterState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { NewsletterEntityState } from '@get/api-interfaces';

export const newsletterReducersGeneratedFunctions: ReducerTypes<NewsletterState.IState, readonly ActionCreator[]>[] = [
  on(NewsletterGeneratedActions.getOneNewsletter, (state: NewsletterState.IState) => setLoadingsState(state, true)),
  on(NewsletterGeneratedActions.getManyNewsletters, (state: NewsletterState.IState) => setLoadingsState(state, true)),
  on(NewsletterGeneratedActions.upsertOneNewsletter, (state: NewsletterState.IState) => setLoadingsState(state, true)),

  on(NewsletterGeneratedActions.upsertManyNewslettersSuccess, (state: NewsletterState.IState, { newsletters }) =>
    NewsletterState.adapter.upsertMany(newsletters, setLoadingsState(state, false))
  ),
  on(NewsletterGeneratedActions.deleteOneNewsletter, (state: NewsletterState.IState) => setLoadingsState(state, true)),
  on(NewsletterGeneratedActions.deleteOneNewsletterSuccess, (state: NewsletterState.IState, { idNewsletter }) =>
    NewsletterState.adapter.removeOne(idNewsletter, setLoadingsState(state, false))
  ),
  on(NewsletterGeneratedActions.clearActivesNewsletters, (state: NewsletterState.IState) => ({ ...state, actives: [] })),
  on(NewsletterGeneratedActions.addManyActivesNewsletters, (state: NewsletterState.IState, { idNewsletters }) => ({
    ...state,
    actives: state.actives.concat(idNewsletters)
  })),
  on(NewsletterGeneratedActions.deleteOneActiveNewsletter, (state: NewsletterState.IState, { idNewsletter }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idNewsletter)
  })),

  on(NewsletterGeneratedActions.clearNewsletters, () => NewsletterState.initialState),

  on(
    NewsletterGeneratedActions.addManyUserNewsletterSuccess,
    (state: NewsletterState.IState, { idNewsletter, idUserNewsletters }) => {
      if (!state.entities[idNewsletter]) {
        return state;
      }
      const userNewsletters = (state.entities[idNewsletter]?.userNewsletters as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idNewsletter]: {
            ...state.entities[idNewsletter],
            userNewsletters: userNewsletters.concat(
              idUserNewsletters.filter(id => userNewsletters.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    NewsletterGeneratedActions.deleteManyUserNewsletterSuccess,
    (state: NewsletterState.IState, { idUserNewsletters, idNewsletters }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idNewsletters.reduce((entities, idNewsletter) => {
            if (!state.entities[idNewsletter]?.userNewsletters) {
              return entities;
            }
            entities[idNewsletter] = {
              ...state.entities[idNewsletter],
              userNewsletters: (state.entities[idNewsletter]?.userNewsletters as number[])?.filter(
                (idUserNewsletter: number) => !idUserNewsletters.some((id: number) => id === idUserNewsletter)
              )
            } as NewsletterEntityState;
            return entities;
          }, {} as Dictionary<NewsletterEntityState>)
        }
      };
    }
  ),

  on(
    NewsletterGeneratedActions.addManyOrganisationNewsletterSuccess,
    (state: NewsletterState.IState, { idNewsletter, idOrganisationNewsletters }) => {
      if (!state.entities[idNewsletter]) {
        return state;
      }
      const organisationNewsletters = (state.entities[idNewsletter]?.organisationNewsletters as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idNewsletter]: {
            ...state.entities[idNewsletter],
            organisationNewsletters: organisationNewsletters.concat(
              idOrganisationNewsletters.filter(id => organisationNewsletters.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    NewsletterGeneratedActions.deleteManyOrganisationNewsletterSuccess,
    (state: NewsletterState.IState, { idOrganisationNewsletters, idNewsletters }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idNewsletters.reduce((entities, idNewsletter) => {
            if (!state.entities[idNewsletter]?.organisationNewsletters) {
              return entities;
            }
            entities[idNewsletter] = {
              ...state.entities[idNewsletter],
              organisationNewsletters: (state.entities[idNewsletter]?.organisationNewsletters as number[])?.filter(
                (idOrganisationNewsletter: number) => !idOrganisationNewsletters.some((id: number) => id === idOrganisationNewsletter)
              )
            } as NewsletterEntityState;
            return entities;
          }, {} as Dictionary<NewsletterEntityState>)
        }
      };
    }
  ),

  on(
    NewsletterGeneratedActions.addManyUserSuccess,
    (state: NewsletterState.IState, { idNewsletter, idUsers }) => {
      if (!state.entities[idNewsletter]) {
        return state;
      }
      const users = (state.entities[idNewsletter]?.users as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idNewsletter]: {
            ...state.entities[idNewsletter],
            users: users.concat(
              idUsers.filter(id => users.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    NewsletterGeneratedActions.deleteManyUserSuccess,
    (state: NewsletterState.IState, { idUsers, idNewsletters }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idNewsletters.reduce((entities, idNewsletter) => {
            if (!state.entities[idNewsletter]?.users) {
              return entities;
            }
            entities[idNewsletter] = {
              ...state.entities[idNewsletter],
              users: (state.entities[idNewsletter]?.users as number[])?.filter(
                (idUser: number) => !idUsers.some((id: number) => id === idUser)
              )
            } as NewsletterEntityState;
            return entities;
          }, {} as Dictionary<NewsletterEntityState>)
        }
      };
    }
  ),

  on(
    NewsletterGeneratedActions.addManyOrganisationSuccess,
    (state: NewsletterState.IState, { idNewsletter, idOrganisations }) => {
      if (!state.entities[idNewsletter]) {
        return state;
      }
      const organisations = (state.entities[idNewsletter]?.organisations as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idNewsletter]: {
            ...state.entities[idNewsletter],
            organisations: organisations.concat(
              idOrganisations.filter(id => organisations.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    NewsletterGeneratedActions.deleteManyOrganisationSuccess,
    (state: NewsletterState.IState, { idOrganisations, idNewsletters }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idNewsletters.reduce((entities, idNewsletter) => {
            if (!state.entities[idNewsletter]?.organisations) {
              return entities;
            }
            entities[idNewsletter] = {
              ...state.entities[idNewsletter],
              organisations: (state.entities[idNewsletter]?.organisations as number[])?.filter(
                (idOrganisation: number) => !idOrganisations.some((id: number) => id === idOrganisation)
              )
            } as NewsletterEntityState;
            return entities;
          }, {} as Dictionary<NewsletterEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: NewsletterState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): NewsletterState.IState {
  return { ...state, isLoaded, isLoading };
}
