import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ComposantTemplate } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ComposantTemplateRelationsIds } from '@get/store/ids-interfaces';
import { ComposantTemplateGeneratedActions } from '@get/store/actions';
import { ComposantTemplateSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedComposantTemplateService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ComposantTemplateSelectors.selectIsLoadedComposantTemplate));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ComposantTemplateSelectors.selectIsLoadingComposantTemplate));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ComposantTemplateSelectors.selectIsReadyAndLoadedComposantTemplate as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllComposantTemplates(schema: SelectSchema = {}): Observable<ComposantTemplate[]> {
    return this.store$.pipe(select(ComposantTemplateSelectors.selectAllComposantTemplates(schema))).pipe(
      switchMap(({ composantTemplates }: { composantTemplates: ComposantTemplate[] }) => {
        return this.getReady(schema).pipe(mapTo(composantTemplates));
      })
    );
  }

  public selectOneComposantTemplate(
    idComposantTemplate: number,
    schema: SelectSchema = {}
  ): Observable<ComposantTemplate> {
    return this.store$
      .pipe(select(ComposantTemplateSelectors.selectOneComposantTemplate(schema, idComposantTemplate)))
      .pipe(
        switchMap(({ composantTemplate }: { composantTemplate: ComposantTemplate }) => {
          return this.getReady(schema).pipe(mapTo(composantTemplate));
        })
      );
  }

  public selectAllActiveComposantTemplates(schema: SelectSchema = {}): Observable<ComposantTemplate[]> {
    return this.store$.pipe(select(ComposantTemplateSelectors.selectActiveComposantTemplates(schema))).pipe(
      switchMap(({ composantTemplates }: { composantTemplates: ComposantTemplate[] }) => {
        return this.getReady(schema).pipe(mapTo(composantTemplates));
      })
    );
  }

  public selectIdComposantTemplatesActive(): Observable<number[]> {
    return this.store$.pipe(select(ComposantTemplateSelectors.selectIdComposantTemplatesActive)).pipe(
      switchMap((idComposantTemplates: number[]) => {
        return this.getReady().pipe(mapTo(idComposantTemplates));
      })
    );
  }

  public getOneComposantTemplate(
    idComposantTemplate: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<ComposantTemplate> {
    this.store$.dispatch(ComposantTemplateGeneratedActions.getOneComposantTemplate({ idComposantTemplate, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantTemplateGeneratedActions.normalizeManyComposantTemplatesAfterUpsert,
        ComposantTemplateGeneratedActions.composantTemplatesFailure,
        true
      );
    }
  }

  public getManyComposantTemplates(
    params: any = {},
    getResult?: boolean
  ): void | Observable<ComposantTemplate[]> {
    this.store$.dispatch(ComposantTemplateGeneratedActions.getManyComposantTemplates({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantTemplateGeneratedActions.normalizeManyComposantTemplatesAfterUpsert,
        ComposantTemplateGeneratedActions.composantTemplatesFailure
      );
    }
  }

  public upsertOneComposantTemplate(
    composantTemplate: Partial<ComposantTemplate>,
    ids: ComposantTemplateRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ComposantTemplate> {
    this.store$.dispatch(ComposantTemplateGeneratedActions.upsertOneComposantTemplate({ composantTemplate, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantTemplateGeneratedActions.normalizeManyComposantTemplatesAfterUpsert,
        ComposantTemplateGeneratedActions.composantTemplatesFailure,
        true
      );
    }
  }

  public deleteOneComposantTemplate(
    idComposantTemplate: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(ComposantTemplateGeneratedActions.deleteOneComposantTemplate({ idComposantTemplate }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantTemplateGeneratedActions.deleteOneComposantTemplateSuccess,
        ComposantTemplateGeneratedActions.composantTemplatesFailure
      );
    }
  }

  public setActiveComposantTemplates(idComposantTemplates: number[]): void {
    this.store$.dispatch(ComposantTemplateGeneratedActions.clearActivesComposantTemplates());
    this.store$.dispatch(ComposantTemplateGeneratedActions.addManyActivesComposantTemplates({ idComposantTemplates }));
  }
}
