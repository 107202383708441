import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocietePatrimoineHierarchieAncetre, SocietePatrimoineHierarchieAncetreEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchieAncetreApiService } from '@get/store/api-services';
import { SocietePatrimoineHierarchieAncetreGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocietePatrimoineHierarchieAncetre } from '@get/store/configs/normalization';
import { SocietePatrimoineHierarchieAncetreSelectors } from '@get/store/selectors';
import { SocietePatrimoineHierarchieAncetreRelationsIds } from '@get/store/ids-interfaces';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';

export function getDefaultAddSocietePatrimoineHierarchieAncetreActions(societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetreEntityState, ids?: SocietePatrimoineHierarchieAncetreRelationsIds): Action[] {
  const actions: Action[] = [SocietePatrimoineHierarchieAncetreGeneratedActions.normalizeManySocietePatrimoineHierarchieAncetresAfterUpsert({ societePatrimoineHierarchieAncetres: [societePatrimoineHierarchieAncetre] })];

  if (ids?.hierarchieAncetre) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.addManyHierarchieAncetresSuccess({
        idSocietePatrimoineHierarchie: ids.hierarchieAncetre,
        idHierarchieAncetres: [societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre]
      })
    );
    actions.push(
      SocietePatrimoineHierarchieAncetreGeneratedActions.addHierarchieAncetreSuccess({
        idSocietePatrimoineHierarchieAncetre: societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre,
        idHierarchieAncetre: ids.hierarchieAncetre
      })
    );
  }

  if (ids?.hierarchieDescendant) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.addManyHierarchieDescendantsSuccess({
        idSocietePatrimoineHierarchie: ids.hierarchieDescendant,
        idHierarchieDescendants: [societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre]
      })
    );
    actions.push(
      SocietePatrimoineHierarchieAncetreGeneratedActions.addHierarchieDescendantSuccess({
        idSocietePatrimoineHierarchieAncetre: societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre,
        idHierarchieDescendant: ids.hierarchieDescendant
      })
    );
  }

  return actions;
}

export function getDefaultDeleteSocietePatrimoineHierarchieAncetreActions(societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetreEntityState): Action[] {
  const actions: Action[] = [SocietePatrimoineHierarchieAncetreGeneratedActions.deleteOneSocietePatrimoineHierarchieAncetreSuccess({ idSocietePatrimoineHierarchieAncetre: societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre })];

  if (societePatrimoineHierarchieAncetre.hierarchieAncetre) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.deleteManyHierarchieAncetresSuccess({
        idHierarchieAncetres: [societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre],
        idSocietePatrimoineHierarchies: [societePatrimoineHierarchieAncetre.hierarchieAncetre as number]
      })
    );
  }

  if (societePatrimoineHierarchieAncetre.hierarchieDescendant) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.deleteManyHierarchieDescendantsSuccess({
        idHierarchieDescendants: [societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre],
        idSocietePatrimoineHierarchies: [societePatrimoineHierarchieAncetre.hierarchieDescendant as number]
      })
    );
  }

  return actions;
}

export class GeneratedSocietePatrimoineHierarchieAncetreEffects {
  constructor(
    protected actions$: Actions,
    protected societePatrimoineHierarchieAncetreApiService: SocietePatrimoineHierarchieAncetreApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocietePatrimoineHierarchieAncetres$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieAncetreGeneratedActions.getManySocietePatrimoineHierarchieAncetres),
      switchMap(({ params }) =>
        this.societePatrimoineHierarchieAncetreApiService.getSocietePatrimoineHierarchieAncetres(params).pipe(
          map((societePatrimoineHierarchieAncetres: SocietePatrimoineHierarchieAncetre[]) => {
            return SocietePatrimoineHierarchieAncetreGeneratedActions.normalizeManySocietePatrimoineHierarchieAncetresAfterUpsert({ societePatrimoineHierarchieAncetres });
          }),
          catchError(error => of(SocietePatrimoineHierarchieAncetreGeneratedActions.societePatrimoineHierarchieAncetresFailure({ error })))
        )
      )
    );
  });

  getOneSocietePatrimoineHierarchieAncetre$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieAncetreGeneratedActions.getOneSocietePatrimoineHierarchieAncetre),
      switchMap(idSocietePatrimoineHierarchieAncetre =>
        this.societePatrimoineHierarchieAncetreApiService.getSocietePatrimoineHierarchieAncetre(idSocietePatrimoineHierarchieAncetre).pipe(
          map((societePatrimoineHierarchieAncetre: SocietePatrimoineHierarchieAncetre) => {
            return SocietePatrimoineHierarchieAncetreGeneratedActions.normalizeManySocietePatrimoineHierarchieAncetresAfterUpsert({ societePatrimoineHierarchieAncetres: [societePatrimoineHierarchieAncetre] });
          }),
          catchError(error => of(SocietePatrimoineHierarchieAncetreGeneratedActions.societePatrimoineHierarchieAncetresFailure({ error })))
        )
      )
    );
  });

  upsertOneSocietePatrimoineHierarchieAncetre$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieAncetreGeneratedActions.upsertOneSocietePatrimoineHierarchieAncetre),
      concatMap(
        ({
          societePatrimoineHierarchieAncetre,
          ids
        }: {
          societePatrimoineHierarchieAncetre: Partial<SocietePatrimoineHierarchieAncetre>;
          ids?: SocietePatrimoineHierarchieAncetreRelationsIds;
        }) => {
          if (societePatrimoineHierarchieAncetre.idSocietePatrimoineHierarchieAncetre) {
            return this.societePatrimoineHierarchieAncetreApiService.updateSocietePatrimoineHierarchieAncetre(societePatrimoineHierarchieAncetre).pipe(
              map((societePatrimoineHierarchieAncetreReturned: SocietePatrimoineHierarchieAncetre) => {
                return SocietePatrimoineHierarchieAncetreGeneratedActions.normalizeManySocietePatrimoineHierarchieAncetresAfterUpsert({ societePatrimoineHierarchieAncetres: [societePatrimoineHierarchieAncetreReturned] });
              }),
              catchError(error => of(SocietePatrimoineHierarchieAncetreGeneratedActions.societePatrimoineHierarchieAncetresFailure({ error })))
            );
          } else {
            return this.societePatrimoineHierarchieAncetreApiService.addSocietePatrimoineHierarchieAncetre(societePatrimoineHierarchieAncetre).pipe(
              mergeMap((societePatrimoineHierarchieAncetreReturned: SocietePatrimoineHierarchieAncetre) => getDefaultAddSocietePatrimoineHierarchieAncetreActions(societePatrimoineHierarchieAncetreReturned, ids)),
              catchError(error => of(SocietePatrimoineHierarchieAncetreGeneratedActions.societePatrimoineHierarchieAncetresFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocietePatrimoineHierarchieAncetre$ = createEffect(() => {
    const selectSocietePatrimoineHierarchieAncetreState$ = this.store$.select(SocietePatrimoineHierarchieAncetreSelectors.selectSocietePatrimoineHierarchieAncetreState);
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieAncetreGeneratedActions.deleteOneSocietePatrimoineHierarchieAncetre),
      withLatestFrom(selectSocietePatrimoineHierarchieAncetreState$),
      concatMap(([{ idSocietePatrimoineHierarchieAncetre }, state]) =>
        this.societePatrimoineHierarchieAncetreApiService.deleteSocietePatrimoineHierarchieAncetre(idSocietePatrimoineHierarchieAncetre).pipe(
          mergeMap(_success => getDefaultDeleteSocietePatrimoineHierarchieAncetreActions(state.entities[idSocietePatrimoineHierarchieAncetre] as SocietePatrimoineHierarchieAncetreEntityState)),
          catchError(error => of(SocietePatrimoineHierarchieAncetreGeneratedActions.societePatrimoineHierarchieAncetresFailure({ error })))
        )
      )
    );
  });

  normalizeManySocietePatrimoineHierarchieAncetresAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieAncetreGeneratedActions.normalizeManySocietePatrimoineHierarchieAncetresAfterUpsert),
      concatMap(({ societePatrimoineHierarchieAncetres }) => {
        const actions: Action[] = getActionsToNormalizeSocietePatrimoineHierarchieAncetre(societePatrimoineHierarchieAncetres, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocietePatrimoineHierarchieAncetre] Normalization After Upsert Success')];
      })
    );
  });
}
