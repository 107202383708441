import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteCaracteristiqueChoix } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteCaracteristiqueChoixRelationsIds } from '@get/store/ids-interfaces';
import { SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoixSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedSocieteCaracteristiqueChoixService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteCaracteristiqueChoixSelectors.selectIsLoadedSocieteCaracteristiqueChoix));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteCaracteristiqueChoixSelectors.selectIsLoadingSocieteCaracteristiqueChoix));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteCaracteristiqueChoixSelectors.selectIsReadyAndLoadedSocieteCaracteristiqueChoix as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteCaracteristiqueChoices(schema: SelectSchema = {}): Observable<SocieteCaracteristiqueChoix[]> {
    return this.store$.pipe(select(SocieteCaracteristiqueChoixSelectors.selectAllSocieteCaracteristiqueChoices(schema))).pipe(
      switchMap(({ societeCaracteristiqueChoices }: { societeCaracteristiqueChoices: SocieteCaracteristiqueChoix[] }) => {
        return this.getReady(schema).pipe(mapTo(societeCaracteristiqueChoices));
      })
    );
  }

  public selectOneSocieteCaracteristiqueChoix(
    idSocieteCaracteristiqueChoix: number,
    schema: SelectSchema = {}
  ): Observable<SocieteCaracteristiqueChoix> {
    return this.store$
      .pipe(select(SocieteCaracteristiqueChoixSelectors.selectOneSocieteCaracteristiqueChoix(schema, idSocieteCaracteristiqueChoix)))
      .pipe(
        switchMap(({ societeCaracteristiqueChoix }: { societeCaracteristiqueChoix: SocieteCaracteristiqueChoix }) => {
          return this.getReady(schema).pipe(mapTo(societeCaracteristiqueChoix));
        })
      );
  }

  public selectAllActiveSocieteCaracteristiqueChoices(schema: SelectSchema = {}): Observable<SocieteCaracteristiqueChoix[]> {
    return this.store$.pipe(select(SocieteCaracteristiqueChoixSelectors.selectActiveSocieteCaracteristiqueChoices(schema))).pipe(
      switchMap(({ societeCaracteristiqueChoices }: { societeCaracteristiqueChoices: SocieteCaracteristiqueChoix[] }) => {
        return this.getReady(schema).pipe(mapTo(societeCaracteristiqueChoices));
      })
    );
  }

  public selectIdSocieteCaracteristiqueChoicesActive(): Observable<number[]> {
    return this.store$.pipe(select(SocieteCaracteristiqueChoixSelectors.selectIdSocieteCaracteristiqueChoicesActive)).pipe(
      switchMap((idSocieteCaracteristiqueChoices: number[]) => {
        return this.getReady().pipe(mapTo(idSocieteCaracteristiqueChoices));
      })
    );
  }

  public getOneSocieteCaracteristiqueChoix(
    idSocieteCaracteristiqueChoix: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteCaracteristiqueChoix> {
    this.store$.dispatch(SocieteCaracteristiqueChoixGeneratedActions.getOneSocieteCaracteristiqueChoix({ idSocieteCaracteristiqueChoix, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteCaracteristiqueChoixGeneratedActions.normalizeManySocieteCaracteristiqueChoicesAfterUpsert,
        SocieteCaracteristiqueChoixGeneratedActions.societeCaracteristiqueChoicesFailure,
        true
      );
    }
  }

  public getManySocieteCaracteristiqueChoices(
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteCaracteristiqueChoix[]> {
    this.store$.dispatch(SocieteCaracteristiqueChoixGeneratedActions.getManySocieteCaracteristiqueChoices({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteCaracteristiqueChoixGeneratedActions.normalizeManySocieteCaracteristiqueChoicesAfterUpsert,
        SocieteCaracteristiqueChoixGeneratedActions.societeCaracteristiqueChoicesFailure
      );
    }
  }

  public upsertOneSocieteCaracteristiqueChoix(
    societeCaracteristiqueChoix: Partial<SocieteCaracteristiqueChoix>,
    ids: SocieteCaracteristiqueChoixRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteCaracteristiqueChoix> {
    this.store$.dispatch(SocieteCaracteristiqueChoixGeneratedActions.upsertOneSocieteCaracteristiqueChoix({ societeCaracteristiqueChoix, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteCaracteristiqueChoixGeneratedActions.normalizeManySocieteCaracteristiqueChoicesAfterUpsert,
        SocieteCaracteristiqueChoixGeneratedActions.societeCaracteristiqueChoicesFailure,
        true
      );
    }
  }

  public deleteOneSocieteCaracteristiqueChoix(
    idSocieteCaracteristiqueChoix: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(SocieteCaracteristiqueChoixGeneratedActions.deleteOneSocieteCaracteristiqueChoix({ idSocieteCaracteristiqueChoix }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteCaracteristiqueChoixGeneratedActions.deleteOneSocieteCaracteristiqueChoixSuccess,
        SocieteCaracteristiqueChoixGeneratedActions.societeCaracteristiqueChoicesFailure
      );
    }
  }

  public setActiveSocieteCaracteristiqueChoices(idSocieteCaracteristiqueChoices: number[]): void {
    this.store$.dispatch(SocieteCaracteristiqueChoixGeneratedActions.clearActivesSocieteCaracteristiqueChoices());
    this.store$.dispatch(SocieteCaracteristiqueChoixGeneratedActions.addManyActivesSocieteCaracteristiqueChoices({ idSocieteCaracteristiqueChoices }));
  }
}
