import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserSocieteProfil, UserSocieteProfilEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { SocieteProfil, SocieteProfilEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { UserSocieteProfilState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const userSocieteProfilRelations: string[] = ['users','societeProfils',];

export const { selectEntities, selectAll } = UserSocieteProfilState.adapter.getSelectors();

export const selectUserSocieteProfilState = createFeatureSelector<UserSocieteProfilState.IState>(UserSocieteProfilState.userSocieteProfilFeatureKey);

export const selectIsLoadedUserSocieteProfil = createSelector(
  selectUserSocieteProfilState,
  (state: UserSocieteProfilState.IState) => state.isLoaded
);

export const selectIsLoadingUserSocieteProfil = createSelector(
  selectUserSocieteProfilState,
  (state: UserSocieteProfilState.IState) => state.isLoading
);

export const selectIsReadyUserSocieteProfil = createSelector(
  selectUserSocieteProfilState,
  (state: UserSocieteProfilState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUserSocieteProfil = createSelector(
  selectUserSocieteProfilState,
  (state: UserSocieteProfilState.IState) => state.isLoaded && !state.isLoading
);

export const selectUserSocieteProfilsEntities = createSelector(selectUserSocieteProfilState, selectEntities);

export const selectUserSocieteProfilsArray = createSelector(selectUserSocieteProfilState, selectAll);

export const selectIdUserSocieteProfilsActive = createSelector(
  selectUserSocieteProfilState,
  (state: UserSocieteProfilState.IState) => state.actives
);

const userSocieteProfilsInObject = (userSocieteProfils: Dictionary<UserSocieteProfilEntityState>) => ({ userSocieteProfils })

const selectUserSocieteProfilsEntitiesDictionary = createSelector(selectUserSocieteProfilsEntities, userSocieteProfilsInObject);

const selectAllUserSocieteProfilsObject = createSelector(selectUserSocieteProfilsEntities, userSocieteProfils => {
  return hydrateAll({ userSocieteProfils });
});

const selectOneUserSocieteProfilDictionary = (idUserSocieteProfil : number) =>
  createSelector(selectUserSocieteProfilsEntities, userSocieteProfils => ({
    userSocieteProfils: { [idUserSocieteProfil]: userSocieteProfils[idUserSocieteProfil] }
  }));

const selectOneUserSocieteProfilDictionaryWithoutChild = (idUserSocieteProfil : number) =>
  createSelector(selectUserSocieteProfilsEntities, userSocieteProfils => ({
    userSocieteProfil: userSocieteProfils[idUserSocieteProfil]
  }));

const selectActiveUserSocieteProfilsEntities = createSelector(
  selectIdUserSocieteProfilsActive,
  selectUserSocieteProfilsEntities,
  (actives: number[], userSocieteProfils: Dictionary<UserSocieteProfilEntityState>) => getUserSocieteProfilsFromActives(actives, userSocieteProfils)
);

function getUserSocieteProfilsFromActives(
  actives: number[],
  userSocieteProfils: Dictionary<UserSocieteProfilEntityState>
): Dictionary<UserSocieteProfilEntityState> {
  return actives.reduce((acc, idActive) => {
    if (userSocieteProfils[idActive]) {
      acc[idActive] = userSocieteProfils[idActive];
    }
    return acc;
  }, {} as Dictionary<UserSocieteProfilEntityState>);
}

const selectAllUserSocieteProfilsSelectors: Dictionary<Selector> = {};
export function selectAllUserSocieteProfils(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<UserSocieteProfil>(
      schema,
      selectAllUserSocieteProfilsSelectors,
      selectUserSocieteProfilsEntitiesDictionary,
      getRelationSelectors,
      userSocieteProfilRelations,
      hydrateAll,
      'userSocieteProfil'
    );
  } else {
    return selectAllUserSocieteProfilsObject;
  }
}

export function selectAllUserSocieteProfilsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'userSocieteProfils'
): Selector {
  return createSelector(selectAllUserSocieteProfils(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserSocieteProfilEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.userSocieteProfils.length; i++) {
      res[customKey][result.userSocieteProfils[i].idUserSocieteProfil] = result.userSocieteProfils[i];
    }
    return res;
  });
}

export function selectOneUserSocieteProfil(
  schema: SelectSchema = {},
  idUserSocieteProfil: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneUserSocieteProfilDictionary(idUserSocieteProfil)];
  selectors.push(...getRelationSelectors(schema, userSocieteProfilRelations, 'userSocieteProfil'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserSocieteProfilDictionaryWithoutChild(idUserSocieteProfil);
  }
}

export function selectActiveUserSocieteProfils(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveUserSocieteProfilsEntities, userSocieteProfils => ({
      userSocieteProfils
    }))
  ];
  selectors.push(...getRelationSelectors(schema, userSocieteProfilRelations, 'userSocieteProfil'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  userSocieteProfils: Dictionary<UserSocieteProfilEntityState>;
  users?: Dictionary<UserEntityState>;
  societeProfils?: Dictionary<SocieteProfilEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { userSocieteProfils: (UserSocieteProfil | null)[] } {
  const {
    userSocieteProfils,
    users,
    societeProfils
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    userSocieteProfils: Object.keys(userSocieteProfils).map(idUserSocieteProfil =>
      hydrate(
        userSocieteProfils[idUserSocieteProfil] as UserSocieteProfilEntityState,
        users,
        societeProfils
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { userSocieteProfil: UserSocieteProfilEntityState | null } {
  const {
    userSocieteProfils,
    users,
    societeProfils
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const userSocieteProfil = Object.values(userSocieteProfils)[0];
  return {
    userSocieteProfil: hydrate(
      userSocieteProfil as UserSocieteProfilEntityState,
      users,
      societeProfils
    )
  };
}

function hydrate(
  userSocieteProfil: UserSocieteProfilEntityState,
  userEntities?: Dictionary<UserEntityState>,
  societeProfilEntities?: Dictionary<SocieteProfilEntityState>,
): UserSocieteProfil | null {
  if (!userSocieteProfil) {
    return null;
  }

  const userSocieteProfilHydrated: UserSocieteProfilEntityState = { ...userSocieteProfil };
  if (userEntities) {
    userSocieteProfilHydrated.user = userEntities[userSocieteProfil.user as number] as User;
  } else {
    delete userSocieteProfilHydrated.user;
  }
  if (societeProfilEntities) {
    userSocieteProfilHydrated.societeProfil = societeProfilEntities[userSocieteProfil.societeProfil as number] as SocieteProfil;
  } else {
    delete userSocieteProfilHydrated.societeProfil;
  }

  return userSocieteProfilHydrated as UserSocieteProfil;
}
