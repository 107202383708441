import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteComposantRattachement, SocieteComposantRattachementEntityState } from '@get/api-interfaces';
import { SocieteComposantRattachementApiService } from '@get/store/api-services';
import { SocieteComposantRattachementGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteComposantRattachement } from '@get/store/configs/normalization';
import { SocieteComposantRattachementSelectors } from '@get/store/selectors';
import { SocieteComposantRattachementRelationsIds } from '@get/store/ids-interfaces';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocieteEspaceFamilleGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';

export function getDefaultAddSocieteComposantRattachementActions(societeComposantRattachement: SocieteComposantRattachementEntityState, ids?: SocieteComposantRattachementRelationsIds): Action[] {
  const actions: Action[] = [SocieteComposantRattachementGeneratedActions.normalizeManySocieteComposantRattachementsAfterUpsert({ societeComposantRattachements: [societeComposantRattachement] })];

  if (ids?.societeComposant) {
    actions.push(
      SocieteComposantGeneratedActions.addManySocieteComposantRattachementSuccess({
        idSocieteComposant: ids.societeComposant,
        idSocieteComposantRattachements: [societeComposantRattachement.idSocieteComposantRattachement]
      })
    );
    actions.push(
      SocieteComposantRattachementGeneratedActions.addSocieteComposantSuccess({
        idSocieteComposantRattachement: societeComposantRattachement.idSocieteComposantRattachement,
        idSocieteComposant: ids.societeComposant
      })
    );
  }

  if (ids?.societeEspaceFamille) {
    actions.push(
      SocieteEspaceFamilleGeneratedActions.addManySocieteComposantRattachementSuccess({
        idSocieteEspaceFamille: ids.societeEspaceFamille,
        idSocieteComposantRattachements: [societeComposantRattachement.idSocieteComposantRattachement]
      })
    );
    actions.push(
      SocieteComposantRattachementGeneratedActions.addSocieteEspaceFamilleSuccess({
        idSocieteComposantRattachement: societeComposantRattachement.idSocieteComposantRattachement,
        idSocieteEspaceFamille: ids.societeEspaceFamille
      })
    );
  }

  if (ids?.societePatrimoineHierarchie) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.addManySocieteComposantRattachementSuccess({
        idSocietePatrimoineHierarchie: ids.societePatrimoineHierarchie,
        idSocieteComposantRattachements: [societeComposantRattachement.idSocieteComposantRattachement]
      })
    );
    actions.push(
      SocieteComposantRattachementGeneratedActions.addSocietePatrimoineHierarchieSuccess({
        idSocieteComposantRattachement: societeComposantRattachement.idSocieteComposantRattachement,
        idSocietePatrimoineHierarchie: ids.societePatrimoineHierarchie
      })
    );
  }

  return actions;
}

export function getDefaultDeleteSocieteComposantRattachementActions(societeComposantRattachement: SocieteComposantRattachementEntityState): Action[] {
  const actions: Action[] = [SocieteComposantRattachementGeneratedActions.deleteOneSocieteComposantRattachementSuccess({ idSocieteComposantRattachement: societeComposantRattachement.idSocieteComposantRattachement })];

  if (societeComposantRattachement.societeComposant) {
    actions.push(
      SocieteComposantGeneratedActions.deleteManySocieteComposantRattachementSuccess({
        idSocieteComposantRattachements: [societeComposantRattachement.idSocieteComposantRattachement],
        idSocieteComposants: [societeComposantRattachement.societeComposant as number]
      })
    );
  }

  if (societeComposantRattachement.societeEspaceFamille) {
    actions.push(
      SocieteEspaceFamilleGeneratedActions.deleteManySocieteComposantRattachementSuccess({
        idSocieteComposantRattachements: [societeComposantRattachement.idSocieteComposantRattachement],
        idSocieteEspaceFamilles: [societeComposantRattachement.societeEspaceFamille as number]
      })
    );
  }

  if (societeComposantRattachement.societePatrimoineHierarchie) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.deleteManySocieteComposantRattachementSuccess({
        idSocieteComposantRattachements: [societeComposantRattachement.idSocieteComposantRattachement],
        idSocietePatrimoineHierarchies: [societeComposantRattachement.societePatrimoineHierarchie as number]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteComposantRattachementEffects {
  constructor(
    protected actions$: Actions,
    protected societeComposantRattachementApiService: SocieteComposantRattachementApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocieteComposantRattachements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantRattachementGeneratedActions.getManySocieteComposantRattachements),
      switchMap(({ params }) =>
        this.societeComposantRattachementApiService.getSocieteComposantRattachements(params).pipe(
          map((societeComposantRattachements: SocieteComposantRattachement[]) => {
            return SocieteComposantRattachementGeneratedActions.normalizeManySocieteComposantRattachementsAfterUpsert({ societeComposantRattachements });
          }),
          catchError(error => of(SocieteComposantRattachementGeneratedActions.societeComposantRattachementsFailure({ error })))
        )
      )
    );
  });

  getOneSocieteComposantRattachement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantRattachementGeneratedActions.getOneSocieteComposantRattachement),
      switchMap(idSocieteComposantRattachement =>
        this.societeComposantRattachementApiService.getSocieteComposantRattachement(idSocieteComposantRattachement).pipe(
          map((societeComposantRattachement: SocieteComposantRattachement) => {
            return SocieteComposantRattachementGeneratedActions.normalizeManySocieteComposantRattachementsAfterUpsert({ societeComposantRattachements: [societeComposantRattachement] });
          }),
          catchError(error => of(SocieteComposantRattachementGeneratedActions.societeComposantRattachementsFailure({ error })))
        )
      )
    );
  });

  upsertOneSocieteComposantRattachement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantRattachementGeneratedActions.upsertOneSocieteComposantRattachement),
      concatMap(
        ({
          societeComposantRattachement,
          ids
        }: {
          societeComposantRattachement: Partial<SocieteComposantRattachement>;
          ids?: SocieteComposantRattachementRelationsIds;
        }) => {
          if (societeComposantRattachement.idSocieteComposantRattachement) {
            return this.societeComposantRattachementApiService.updateSocieteComposantRattachement(societeComposantRattachement).pipe(
              map((societeComposantRattachementReturned: SocieteComposantRattachement) => {
                return SocieteComposantRattachementGeneratedActions.normalizeManySocieteComposantRattachementsAfterUpsert({ societeComposantRattachements: [societeComposantRattachementReturned] });
              }),
              catchError(error => of(SocieteComposantRattachementGeneratedActions.societeComposantRattachementsFailure({ error })))
            );
          } else {
            return this.societeComposantRattachementApiService.addSocieteComposantRattachement(societeComposantRattachement).pipe(
              mergeMap((societeComposantRattachementReturned: SocieteComposantRattachement) => getDefaultAddSocieteComposantRattachementActions(societeComposantRattachementReturned, ids)),
              catchError(error => of(SocieteComposantRattachementGeneratedActions.societeComposantRattachementsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteComposantRattachement$ = createEffect(() => {
    const selectSocieteComposantRattachementState$ = this.store$.select(SocieteComposantRattachementSelectors.selectSocieteComposantRattachementState);
    return this.actions$.pipe(
      ofType(SocieteComposantRattachementGeneratedActions.deleteOneSocieteComposantRattachement),
      withLatestFrom(selectSocieteComposantRattachementState$),
      concatMap(([{ idSocieteComposantRattachement }, state]) =>
        this.societeComposantRattachementApiService.deleteSocieteComposantRattachement(idSocieteComposantRattachement).pipe(
          mergeMap(_success => getDefaultDeleteSocieteComposantRattachementActions(state.entities[idSocieteComposantRattachement] as SocieteComposantRattachementEntityState)),
          catchError(error => of(SocieteComposantRattachementGeneratedActions.societeComposantRattachementsFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteComposantRattachementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantRattachementGeneratedActions.normalizeManySocieteComposantRattachementsAfterUpsert),
      concatMap(({ societeComposantRattachements }) => {
        const actions: Action[] = getActionsToNormalizeSocieteComposantRattachement(societeComposantRattachements, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteComposantRattachement] Normalization After Upsert Success')];
      })
    );
  });
}
