import { RepositoryService } from '@get/services/repository';
import { SocieteCaracteristiqueChoix } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteCaracteristiqueChoixApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteCaracteristiqueChoices(params?: any): Observable<SocieteCaracteristiqueChoix[]> {
    return this.repo.getData<SocieteCaracteristiqueChoix[]>('societe-caracteristique-choix', params);
  }

  public getSocieteCaracteristiqueChoix(params: { idSocieteCaracteristiqueChoix: number; params?: any }): Observable<SocieteCaracteristiqueChoix> {
    return this.repo.getData<SocieteCaracteristiqueChoix>('societe-caracteristique-choix/' + params.idSocieteCaracteristiqueChoix, params.params);
  }

  public addSocieteCaracteristiqueChoix(societeCaracteristiqueChoix: Partial<SocieteCaracteristiqueChoix>): Observable<SocieteCaracteristiqueChoix> {
    return this.repo.create<SocieteCaracteristiqueChoix>('societe-caracteristique-choix', societeCaracteristiqueChoix);
  }

  public updateSocieteCaracteristiqueChoix(societeCaracteristiqueChoix: Partial<SocieteCaracteristiqueChoix>): Observable<SocieteCaracteristiqueChoix> {
    return this.repo.update('societe-caracteristique-choix', societeCaracteristiqueChoix);
  }

  public deleteSocieteCaracteristiqueChoix(idSocieteCaracteristiqueChoix: number): Observable<number> {
    return this.repo.delete('societe-caracteristique-choix/' + +idSocieteCaracteristiqueChoix);
  }
}

