import { GridError, SocieteComposant, SocieteComposantDataRow } from '@get/api-interfaces';
import { ValidationErrorInterface } from '../validation-error.interface';

export function validateSocieteComposantProperties(
  societeCompoantToCreate: Partial<SocieteComposant>
): ValidationErrorInterface<SocieteComposant>[] {
  const errors: ValidationErrorInterface<SocieteComposant>[] = [];
  if (!societeCompoantToCreate.libelleTechnique) {
    errors.push({
      properties: ['libelleTechnique'],
      message: 'You need a libelleTechnique to do this action'
    });
  }
  return errors;
}

export function mergeSocieteComposantAndGridErrors(
  societeComposants: SocieteComposant[],
  gridErrors: GridError[]
): SocieteComposantDataRow[] {
  const societeComposantDataRows: SocieteComposantDataRow[] = [...(societeComposants as SocieteComposantDataRow[])];
  gridErrors?.forEach(gridErrorSocieteComposant => {
    if (gridErrorSocieteComposant.error.idSocieteComposant > 0) {
      const index = societeComposantDataRows.findIndex(
        composant => composant.idSocieteComposant === gridErrorSocieteComposant.error.idSocieteComposant
      );

      societeComposantDataRows[index] = { ...gridErrorSocieteComposant.error };
    } else {
      societeComposantDataRows.push({ ...gridErrorSocieteComposant.error });
    }
  });
  return societeComposantDataRows;
}
