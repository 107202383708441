import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { UserGeneratedActions } from '@get/store/actions';
import { UserState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { UserEntityState } from '@get/api-interfaces';

export const userReducersGeneratedFunctions: ReducerTypes<UserState.IState, readonly ActionCreator[]>[] = [
  on(UserGeneratedActions.getOneUser, (state: UserState.IState) => setLoadingsState(state, true)),
  on(UserGeneratedActions.getManyUsers, (state: UserState.IState) => setLoadingsState(state, true)),
  on(UserGeneratedActions.upsertOneUser, (state: UserState.IState) => setLoadingsState(state, true)),

  on(UserGeneratedActions.upsertManyUsersSuccess, (state: UserState.IState, { users }) =>
    UserState.adapter.upsertMany(users, setLoadingsState(state, false))
  ),
  on(UserGeneratedActions.deleteOneUser, (state: UserState.IState) => setLoadingsState(state, true)),
  on(UserGeneratedActions.deleteOneUserSuccess, (state: UserState.IState, { idUser }) =>
    UserState.adapter.removeOne(idUser, setLoadingsState(state, false))
  ),
  on(UserGeneratedActions.clearActivesUsers, (state: UserState.IState) => ({ ...state, actives: [] })),
  on(UserGeneratedActions.addManyActivesUsers, (state: UserState.IState, { idUsers }) => ({
    ...state,
    actives: state.actives.concat(idUsers)
  })),
  on(UserGeneratedActions.deleteOneActiveUser, (state: UserState.IState, { idUser }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idUser)
  })),

  on(UserGeneratedActions.clearUsers, () => UserState.initialState),

  on(
    UserGeneratedActions.addManySocieteTerritoireUserSuccess,
    (state: UserState.IState, { idUser, idSocieteTerritoireUsers }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const societeTerritoireUsers = (state.entities[idUser]?.societeTerritoireUsers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            societeTerritoireUsers: societeTerritoireUsers.concat(
              idSocieteTerritoireUsers.filter(id => societeTerritoireUsers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManySocieteTerritoireUserSuccess,
    (state: UserState.IState, { idSocieteTerritoireUsers, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.societeTerritoireUsers) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              societeTerritoireUsers: (state.entities[idUser]?.societeTerritoireUsers as number[])?.filter(
                (idSocieteTerritoireUser: number) => !idSocieteTerritoireUsers.some((id: number) => id === idSocieteTerritoireUser)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManyCampagneUserSuccess,
    (state: UserState.IState, { idUser, idCampagneUsers }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const campagneUsers = (state.entities[idUser]?.campagneUsers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            campagneUsers: campagneUsers.concat(
              idCampagneUsers.filter(id => campagneUsers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyCampagneUserSuccess,
    (state: UserState.IState, { idCampagneUsers, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.campagneUsers) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              campagneUsers: (state.entities[idUser]?.campagneUsers as number[])?.filter(
                (idCampagneUser: number) => !idCampagneUsers.some((id: number) => id === idCampagneUser)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManyComposantAttenduSuccess,
    (state: UserState.IState, { idUser, idComposantAttendus }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const composantAttendus = (state.entities[idUser]?.composantAttendus as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            composantAttendus: composantAttendus.concat(
              idComposantAttendus.filter(id => composantAttendus.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyComposantAttenduSuccess,
    (state: UserState.IState, { idComposantAttendus, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.composantAttendus) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              composantAttendus: (state.entities[idUser]?.composantAttendus as number[])?.filter(
                (idComposantAttendu: number) => !idComposantAttendus.some((id: number) => id === idComposantAttendu)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManyCampagneSuccess,
    (state: UserState.IState, { idUser, idCampagnes }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const campagnes = (state.entities[idUser]?.campagnes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            campagnes: campagnes.concat(
              idCampagnes.filter(id => campagnes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyCampagneSuccess,
    (state: UserState.IState, { idCampagnes, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.campagnes) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              campagnes: (state.entities[idUser]?.campagnes as number[])?.filter(
                (idCampagne: number) => !idCampagnes.some((id: number) => id === idCampagne)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManyUserSocieteProfilSuccess,
    (state: UserState.IState, { idUser, idUserSocieteProfils }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const userSocieteProfils = (state.entities[idUser]?.userSocieteProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            userSocieteProfils: userSocieteProfils.concat(
              idUserSocieteProfils.filter(id => userSocieteProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyUserSocieteProfilSuccess,
    (state: UserState.IState, { idUserSocieteProfils, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.userSocieteProfils) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              userSocieteProfils: (state.entities[idUser]?.userSocieteProfils as number[])?.filter(
                (idUserSocieteProfil: number) => !idUserSocieteProfils.some((id: number) => id === idUserSocieteProfil)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManySocieteProfilSuccess,
    (state: UserState.IState, { idUser, idSocieteProfils }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const societeProfils = (state.entities[idUser]?.societeProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            societeProfils: societeProfils.concat(
              idSocieteProfils.filter(id => societeProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManySocieteProfilSuccess,
    (state: UserState.IState, { idSocieteProfils, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.societeProfils) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              societeProfils: (state.entities[idUser]?.societeProfils as number[])?.filter(
                (idSocieteProfil: number) => !idSocieteProfils.some((id: number) => id === idSocieteProfil)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManyUserPatrimoineSuccess,
    (state: UserState.IState, { idUser, idUserPatrimoines }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const userPatrimoines = (state.entities[idUser]?.userPatrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            userPatrimoines: userPatrimoines.concat(
              idUserPatrimoines.filter(id => userPatrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyUserPatrimoineSuccess,
    (state: UserState.IState, { idUserPatrimoines, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.userPatrimoines) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              userPatrimoines: (state.entities[idUser]?.userPatrimoines as number[])?.filter(
                (idUserPatrimoine: number) => !idUserPatrimoines.some((id: number) => id === idUserPatrimoine)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManyPatrimoineSuccess,
    (state: UserState.IState, { idUser, idPatrimoines }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const patrimoines = (state.entities[idUser]?.patrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            patrimoines: patrimoines.concat(
              idPatrimoines.filter(id => patrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyPatrimoineSuccess,
    (state: UserState.IState, { idPatrimoines, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.patrimoines) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              patrimoines: (state.entities[idUser]?.patrimoines as number[])?.filter(
                (idPatrimoine: number) => !idPatrimoines.some((id: number) => id === idPatrimoine)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManyComposantSuccess,
    (state: UserState.IState, { idUser, idComposants }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const composants = (state.entities[idUser]?.composants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            composants: composants.concat(
              idComposants.filter(id => composants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyComposantSuccess,
    (state: UserState.IState, { idComposants, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.composants) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              composants: (state.entities[idUser]?.composants as number[])?.filter(
                (idComposant: number) => !idComposants.some((id: number) => id === idComposant)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManyValeurSuccess,
    (state: UserState.IState, { idUser, idValeurs }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const valeurs = (state.entities[idUser]?.valeurs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            valeurs: valeurs.concat(
              idValeurs.filter(id => valeurs.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyValeurSuccess,
    (state: UserState.IState, { idValeurs, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.valeurs) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              valeurs: (state.entities[idUser]?.valeurs as number[])?.filter(
                (idValeur: number) => !idValeurs.some((id: number) => id === idValeur)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManyUserNewsletterSuccess,
    (state: UserState.IState, { idUser, idUserNewsletters }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const userNewsletters = (state.entities[idUser]?.userNewsletters as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            userNewsletters: userNewsletters.concat(
              idUserNewsletters.filter(id => userNewsletters.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyUserNewsletterSuccess,
    (state: UserState.IState, { idUserNewsletters, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.userNewsletters) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              userNewsletters: (state.entities[idUser]?.userNewsletters as number[])?.filter(
                (idUserNewsletter: number) => !idUserNewsletters.some((id: number) => id === idUserNewsletter)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManyNewsletterSuccess,
    (state: UserState.IState, { idUser, idNewsletters }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const newsletters = (state.entities[idUser]?.newsletters as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            newsletters: newsletters.concat(
              idNewsletters.filter(id => newsletters.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyNewsletterSuccess,
    (state: UserState.IState, { idNewsletters, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.newsletters) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              newsletters: (state.entities[idUser]?.newsletters as number[])?.filter(
                (idNewsletter: number) => !idNewsletters.some((id: number) => id === idNewsletter)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addManySocieteConfigAnalyseSyntheseSuccess,
    (state: UserState.IState, { idUser, idSocieteConfigAnalyseSyntheses }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const societeConfigAnalyseSyntheses = (state.entities[idUser]?.societeConfigAnalyseSyntheses as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            societeConfigAnalyseSyntheses: societeConfigAnalyseSyntheses.concat(
              idSocieteConfigAnalyseSyntheses.filter(id => societeConfigAnalyseSyntheses.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess,
    (state: UserState.IState, { idSocieteConfigAnalyseSyntheses, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.societeConfigAnalyseSyntheses) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              societeConfigAnalyseSyntheses: (state.entities[idUser]?.societeConfigAnalyseSyntheses as number[])?.filter(
                (idSocieteConfigAnalyseSynthese: number) => !idSocieteConfigAnalyseSyntheses.some((id: number) => id === idSocieteConfigAnalyseSynthese)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserGeneratedActions.addOrganisationSuccess,
    (state: UserState.IState, { idUser, idOrganisation }) => {
      if (!state.entities[idUser]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            organisation: idOrganisation
          }
        }
      };
    }
  ),

  on(
    UserGeneratedActions.deleteManyOrganisationSuccess,
    (state: UserState.IState, { idOrganisations, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.organisation) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              organisation: idOrganisations.some(
                (idOrganisation: number) => idOrganisation === state.entities[idUser]?.organisation
              )
                ? undefined
                : state.entities[idUser]?.organisation
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: UserState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): UserState.IState {
  return { ...state, isLoaded, isLoading };
}
