import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Droit, DroitEntityState } from '@get/api-interfaces';
import { DroitRelationsIds } from '@get/store/ids-interfaces';

export const DroitGeneratedActions = createActionGroup({
  source: 'Droit Generated',
  events: {
    'Get One Droit': props<{ idDroit: number; params?: any }>(),
    'Get Many Droits': props<{ params: any }>(),
    'Add Many Actives Droits': props<{ idDroits: number[] }>(),
    'Delete One Active Droit': props<{ idDroit: number }>(),
    'Clear Actives Droits': emptyProps(),
    'Upsert One Droit': props<{ droit: Partial<Droit>; ids?: DroitRelationsIds; }>(),
    'Upsert Many Droits': props<{ droits: Partial<Droit>[]; ids?: DroitRelationsIds; }>(),
    'Upsert Many Droits Success': props<{ droits: DroitEntityState[] }>(),
    'Delete One Droit': props<{ idDroit: number }>(),
    'Delete One Droit Success': props<{ idDroit: number }>(),
    'Normalize Many Droits After Upsert': props<{ droits: DroitEntityState[] }>(),
    'Droits Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Droits': emptyProps(),
    'Add Many Societe Profil Droit Success': props<{ idDroit: number; idSocieteProfilDroits: number[] }>(),
    'Delete Many Societe Profil Droit Success': props<{ idSocieteProfilDroits: number[]; idDroits: number[] }>(),
    'Add Many Societe Profil Success': props<{ idDroit: number; idSocieteProfils: number[] }>(),
    'Delete Many Societe Profil Success': props<{ idSocieteProfils: number[]; idDroits: number[] }>()
  }
});
