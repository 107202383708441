import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PatrimoineHierarchieTemplate, PatrimoineHierarchieTemplateEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { PatrimoineHierarchieTemplateState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const patrimoineHierarchieTemplateRelations: string[] = ['societePatrimoineHierarchies',];

export const { selectEntities, selectAll } = PatrimoineHierarchieTemplateState.adapter.getSelectors();

export const selectPatrimoineHierarchieTemplateState = createFeatureSelector<PatrimoineHierarchieTemplateState.IState>(PatrimoineHierarchieTemplateState.patrimoineHierarchieTemplateFeatureKey);

export const selectIsLoadedPatrimoineHierarchieTemplate = createSelector(
  selectPatrimoineHierarchieTemplateState,
  (state: PatrimoineHierarchieTemplateState.IState) => state.isLoaded
);

export const selectIsLoadingPatrimoineHierarchieTemplate = createSelector(
  selectPatrimoineHierarchieTemplateState,
  (state: PatrimoineHierarchieTemplateState.IState) => state.isLoading
);

export const selectIsReadyPatrimoineHierarchieTemplate = createSelector(
  selectPatrimoineHierarchieTemplateState,
  (state: PatrimoineHierarchieTemplateState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedPatrimoineHierarchieTemplate = createSelector(
  selectPatrimoineHierarchieTemplateState,
  (state: PatrimoineHierarchieTemplateState.IState) => state.isLoaded && !state.isLoading
);

export const selectPatrimoineHierarchieTemplatesEntities = createSelector(selectPatrimoineHierarchieTemplateState, selectEntities);

export const selectPatrimoineHierarchieTemplatesArray = createSelector(selectPatrimoineHierarchieTemplateState, selectAll);

export const selectIdPatrimoineHierarchieTemplatesActive = createSelector(
  selectPatrimoineHierarchieTemplateState,
  (state: PatrimoineHierarchieTemplateState.IState) => state.actives
);

const patrimoineHierarchieTemplatesInObject = (patrimoineHierarchieTemplates: Dictionary<PatrimoineHierarchieTemplateEntityState>) => ({ patrimoineHierarchieTemplates })

const selectPatrimoineHierarchieTemplatesEntitiesDictionary = createSelector(selectPatrimoineHierarchieTemplatesEntities, patrimoineHierarchieTemplatesInObject);

const selectAllPatrimoineHierarchieTemplatesObject = createSelector(selectPatrimoineHierarchieTemplatesEntities, patrimoineHierarchieTemplates => {
  return hydrateAll({ patrimoineHierarchieTemplates });
});

const selectOnePatrimoineHierarchieTemplateDictionary = (idPatrimoineHierarchieTemplate : number) =>
  createSelector(selectPatrimoineHierarchieTemplatesEntities, patrimoineHierarchieTemplates => ({
    patrimoineHierarchieTemplates: { [idPatrimoineHierarchieTemplate]: patrimoineHierarchieTemplates[idPatrimoineHierarchieTemplate] }
  }));

const selectOnePatrimoineHierarchieTemplateDictionaryWithoutChild = (idPatrimoineHierarchieTemplate : number) =>
  createSelector(selectPatrimoineHierarchieTemplatesEntities, patrimoineHierarchieTemplates => ({
    patrimoineHierarchieTemplate: patrimoineHierarchieTemplates[idPatrimoineHierarchieTemplate]
  }));

const selectActivePatrimoineHierarchieTemplatesEntities = createSelector(
  selectIdPatrimoineHierarchieTemplatesActive,
  selectPatrimoineHierarchieTemplatesEntities,
  (actives: number[], patrimoineHierarchieTemplates: Dictionary<PatrimoineHierarchieTemplateEntityState>) => getPatrimoineHierarchieTemplatesFromActives(actives, patrimoineHierarchieTemplates)
);

function getPatrimoineHierarchieTemplatesFromActives(
  actives: number[],
  patrimoineHierarchieTemplates: Dictionary<PatrimoineHierarchieTemplateEntityState>
): Dictionary<PatrimoineHierarchieTemplateEntityState> {
  return actives.reduce((acc, idActive) => {
    if (patrimoineHierarchieTemplates[idActive]) {
      acc[idActive] = patrimoineHierarchieTemplates[idActive];
    }
    return acc;
  }, {} as Dictionary<PatrimoineHierarchieTemplateEntityState>);
}

const selectAllPatrimoineHierarchieTemplatesSelectors: Dictionary<Selector> = {};
export function selectAllPatrimoineHierarchieTemplates(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<PatrimoineHierarchieTemplate>(
      schema,
      selectAllPatrimoineHierarchieTemplatesSelectors,
      selectPatrimoineHierarchieTemplatesEntitiesDictionary,
      getRelationSelectors,
      patrimoineHierarchieTemplateRelations,
      hydrateAll,
      'patrimoineHierarchieTemplate'
    );
  } else {
    return selectAllPatrimoineHierarchieTemplatesObject;
  }
}

export function selectAllPatrimoineHierarchieTemplatesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'patrimoineHierarchieTemplates'
): Selector {
  return createSelector(selectAllPatrimoineHierarchieTemplates(schema), result => {
    const res = { [customKey]: {} as Dictionary<PatrimoineHierarchieTemplateEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.patrimoineHierarchieTemplates.length; i++) {
      res[customKey][result.patrimoineHierarchieTemplates[i].idPatrimoineHierarchieTemplate] = result.patrimoineHierarchieTemplates[i];
    }
    return res;
  });
}

export function selectOnePatrimoineHierarchieTemplate(
  schema: SelectSchema = {},
  idPatrimoineHierarchieTemplate: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOnePatrimoineHierarchieTemplateDictionary(idPatrimoineHierarchieTemplate)];
  selectors.push(...getRelationSelectors(schema, patrimoineHierarchieTemplateRelations, 'patrimoineHierarchieTemplate'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOnePatrimoineHierarchieTemplateDictionaryWithoutChild(idPatrimoineHierarchieTemplate);
  }
}

export function selectActivePatrimoineHierarchieTemplates(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActivePatrimoineHierarchieTemplatesEntities, patrimoineHierarchieTemplates => ({
      patrimoineHierarchieTemplates
    }))
  ];
  selectors.push(...getRelationSelectors(schema, patrimoineHierarchieTemplateRelations, 'patrimoineHierarchieTemplate'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  patrimoineHierarchieTemplates: Dictionary<PatrimoineHierarchieTemplateEntityState>;
  societePatrimoineHierarchies?: Dictionary<SocietePatrimoineHierarchieEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { patrimoineHierarchieTemplates: (PatrimoineHierarchieTemplate | null)[] } {
  const {
    patrimoineHierarchieTemplates,
    societePatrimoineHierarchies
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    patrimoineHierarchieTemplates: Object.keys(patrimoineHierarchieTemplates).map(idPatrimoineHierarchieTemplate =>
      hydrate(
        patrimoineHierarchieTemplates[idPatrimoineHierarchieTemplate] as PatrimoineHierarchieTemplateEntityState,
        societePatrimoineHierarchies
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { patrimoineHierarchieTemplate: PatrimoineHierarchieTemplateEntityState | null } {
  const {
    patrimoineHierarchieTemplates,
    societePatrimoineHierarchies
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const patrimoineHierarchieTemplate = Object.values(patrimoineHierarchieTemplates)[0];
  return {
    patrimoineHierarchieTemplate: hydrate(
      patrimoineHierarchieTemplate as PatrimoineHierarchieTemplateEntityState,
      societePatrimoineHierarchies
    )
  };
}

function hydrate(
  patrimoineHierarchieTemplate: PatrimoineHierarchieTemplateEntityState,
  societePatrimoineHierarchieEntities?: Dictionary<SocietePatrimoineHierarchieEntityState>,
): PatrimoineHierarchieTemplate | null {
  if (!patrimoineHierarchieTemplate) {
    return null;
  }

  const patrimoineHierarchieTemplateHydrated: PatrimoineHierarchieTemplateEntityState = { ...patrimoineHierarchieTemplate };

  if (societePatrimoineHierarchieEntities) {
    patrimoineHierarchieTemplateHydrated.societePatrimoineHierarchies = ((patrimoineHierarchieTemplateHydrated.societePatrimoineHierarchies as number[]) || []).map(
      id => societePatrimoineHierarchieEntities[id]
    ) as SocietePatrimoineHierarchie[];
  } else {
    delete patrimoineHierarchieTemplateHydrated.societePatrimoineHierarchies;
  }

  return patrimoineHierarchieTemplateHydrated as PatrimoineHierarchieTemplate;
}
