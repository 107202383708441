import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchieApiService } from '@get/store/api-services';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocietePatrimoineHierarchie } from '@get/store/configs/normalization';
import { SocietePatrimoineHierarchieSelectors } from '@get/store/selectors';
import { SocietePatrimoineHierarchieRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { PatrimoineHierarchieTemplateGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristique } from '@get/api-interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { Patrimoine } from '@get/api-interfaces';
import { SocietePatrimoineHierarchieAncetreGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchieAncetre } from '@get/api-interfaces';
import { SocieteComposantRattachementGeneratedActions } from '@get/store/actions';
import { SocieteComposantRattachement } from '@get/api-interfaces';
import { SocieteEspaceFamilleGeneratedActions } from '@get/store/actions';
import { SocieteEspaceFamille } from '@get/api-interfaces';

export function getDefaultAddSocietePatrimoineHierarchieActions(societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState, ids?: SocietePatrimoineHierarchieRelationsIds): Action[] {
  const actions: Action[] = [SocietePatrimoineHierarchieGeneratedActions.normalizeManySocietePatrimoineHierarchiesAfterUpsert({ societePatrimoineHierarchies: [societePatrimoineHierarchie] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManySocietePatrimoineHierarchieSuccess({
        idSociete: ids.societe,
        idSocietePatrimoineHierarchies: [societePatrimoineHierarchie.idSocietePatrimoineHierarchie]
      })
    );
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.addSocieteSuccess({
        idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.patrimoineHierarchieTemplate) {
    actions.push(
      PatrimoineHierarchieTemplateGeneratedActions.addManySocietePatrimoineHierarchieSuccess({
        idPatrimoineHierarchieTemplate: ids.patrimoineHierarchieTemplate,
        idSocietePatrimoineHierarchies: [societePatrimoineHierarchie.idSocietePatrimoineHierarchie]
      })
    );
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.addPatrimoineHierarchieTemplateSuccess({
        idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
        idPatrimoineHierarchieTemplate: ids.patrimoineHierarchieTemplate
      })
    );
  }

  if (ids?.societeCaracteristiques) {
    if (!Array.isArray(ids.societeCaracteristiques)) {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertOneSocieteCaracteristique({
          societeCaracteristique: {
            idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
            idSocieteCaracteristique: ids.societeCaracteristiques as number
          } as SocieteCaracteristique
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idSocieteCaracteristiques: [ids.societeCaracteristiques as number]
        })
      );
    } else {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertManySocieteCaracteristiques({
          societeCaracteristiques: (ids.societeCaracteristiques as number[]).map(
            (idSocieteCaracteristique: number) => ({
              idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
              idSocieteCaracteristique
            })
          ) as SocieteCaracteristique[]
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idSocieteCaracteristiques: ids.societeCaracteristiques as number[]
        })
      );
    }
  }

  if (ids?.patrimoines) {
    if (!Array.isArray(ids.patrimoines)) {
      actions.push(
        PatrimoineGeneratedActions.upsertOnePatrimoine({
          patrimoine: {
            idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
            idPatrimoine: ids.patrimoines as number
          } as Patrimoine
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManyPatrimoineSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idPatrimoines: [ids.patrimoines as number]
        })
      );
    } else {
      actions.push(
        PatrimoineGeneratedActions.upsertManyPatrimoines({
          patrimoines: (ids.patrimoines as number[]).map(
            (idPatrimoine: number) => ({
              idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
              idPatrimoine
            })
          ) as Patrimoine[]
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManyPatrimoineSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idPatrimoines: ids.patrimoines as number[]
        })
      );
    }
  }

  if (ids?.hierarchieAncetres) {
    if (!Array.isArray(ids.hierarchieAncetres)) {
      actions.push(
        SocietePatrimoineHierarchieAncetreGeneratedActions.upsertOneSocietePatrimoineHierarchieAncetre({
          societePatrimoineHierarchieAncetre: {
            idHierarchieDescendant: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
            idSocietePatrimoineHierarchieAncetre: ids.hierarchieAncetres as number
          } as SocietePatrimoineHierarchieAncetre
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManyHierarchieAncetresSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idHierarchieAncetres: [ids.hierarchieAncetres as number]
        })
      );
    } else {
      actions.push(
        SocietePatrimoineHierarchieAncetreGeneratedActions.upsertManySocietePatrimoineHierarchieAncetres({
          societePatrimoineHierarchieAncetres: (ids.hierarchieAncetres as number[]).map(
            (idSocietePatrimoineHierarchieAncetre: number) => ({
              idHierarchieDescendant: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
              idSocietePatrimoineHierarchieAncetre
            })
          ) as SocietePatrimoineHierarchieAncetre[]
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManyHierarchieAncetresSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idHierarchieAncetres: ids.hierarchieAncetres as number[]
        })
      );
    }
  }

  if (ids?.hierarchieDescendants) {
    if (!Array.isArray(ids.hierarchieDescendants)) {
      actions.push(
        SocietePatrimoineHierarchieAncetreGeneratedActions.upsertOneSocietePatrimoineHierarchieAncetre({
          societePatrimoineHierarchieAncetre: {
            idHierarchieAncetre: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
            idSocietePatrimoineHierarchieAncetre: ids.hierarchieDescendants as number
          } as SocietePatrimoineHierarchieAncetre
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManyHierarchieDescendantsSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idHierarchieDescendants: [ids.hierarchieDescendants as number]
        })
      );
    } else {
      actions.push(
        SocietePatrimoineHierarchieAncetreGeneratedActions.upsertManySocietePatrimoineHierarchieAncetres({
          societePatrimoineHierarchieAncetres: (ids.hierarchieDescendants as number[]).map(
            (idSocietePatrimoineHierarchieAncetre: number) => ({
              idHierarchieAncetre: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
              idSocietePatrimoineHierarchieAncetre
            })
          ) as SocietePatrimoineHierarchieAncetre[]
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManyHierarchieDescendantsSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idHierarchieDescendants: ids.hierarchieDescendants as number[]
        })
      );
    }
  }

  if (ids?.societeComposantRattachements) {
    if (!Array.isArray(ids.societeComposantRattachements)) {
      actions.push(
        SocieteComposantRattachementGeneratedActions.upsertOneSocieteComposantRattachement({
          societeComposantRattachement: {
            idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
            idSocieteComposantRattachement: ids.societeComposantRattachements as number
          } as SocieteComposantRattachement
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManySocieteComposantRattachementSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idSocieteComposantRattachements: [ids.societeComposantRattachements as number]
        })
      );
    } else {
      actions.push(
        SocieteComposantRattachementGeneratedActions.upsertManySocieteComposantRattachements({
          societeComposantRattachements: (ids.societeComposantRattachements as number[]).map(
            (idSocieteComposantRattachement: number) => ({
              idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
              idSocieteComposantRattachement
            })
          ) as SocieteComposantRattachement[]
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManySocieteComposantRattachementSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idSocieteComposantRattachements: ids.societeComposantRattachements as number[]
        })
      );
    }
  }

  if (ids?.societeEspaceFamilles) {
    if (!Array.isArray(ids.societeEspaceFamilles)) {
      actions.push(
        SocieteEspaceFamilleGeneratedActions.upsertOneSocieteEspaceFamille({
          societeEspaceFamille: {
            idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
            idSocieteEspaceFamille: ids.societeEspaceFamilles as number
          } as SocieteEspaceFamille
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManySocieteEspaceFamilleSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idSocieteEspaceFamilles: [ids.societeEspaceFamilles as number]
        })
      );
    } else {
      actions.push(
        SocieteEspaceFamilleGeneratedActions.upsertManySocieteEspaceFamilles({
          societeEspaceFamilles: (ids.societeEspaceFamilles as number[]).map(
            (idSocieteEspaceFamille: number) => ({
              idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
              idSocieteEspaceFamille
            })
          ) as SocieteEspaceFamille[]
        })
      );
      actions.push(
        SocietePatrimoineHierarchieGeneratedActions.addManySocieteEspaceFamilleSuccess({
          idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie,
          idSocieteEspaceFamilles: ids.societeEspaceFamilles as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSocietePatrimoineHierarchieActions(societePatrimoineHierarchie: SocietePatrimoineHierarchieEntityState): Action[] {
  const actions: Action[] = [SocietePatrimoineHierarchieGeneratedActions.deleteOneSocietePatrimoineHierarchieSuccess({ idSocietePatrimoineHierarchie: societePatrimoineHierarchie.idSocietePatrimoineHierarchie })];

  if (societePatrimoineHierarchie.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess({
        idSocietePatrimoineHierarchies: [societePatrimoineHierarchie.idSocietePatrimoineHierarchie],
        idSocietes: [societePatrimoineHierarchie.societe as number]
      })
    );
  }

  if (societePatrimoineHierarchie.patrimoineHierarchieTemplate) {
    actions.push(
      PatrimoineHierarchieTemplateGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess({
        idSocietePatrimoineHierarchies: [societePatrimoineHierarchie.idSocietePatrimoineHierarchie],
        idPatrimoineHierarchieTemplates: [societePatrimoineHierarchie.patrimoineHierarchieTemplate as number]
      })
    );
  }

  if (societePatrimoineHierarchie.societeCaracteristiques) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess({
        idSocietePatrimoineHierarchies: [societePatrimoineHierarchie.idSocietePatrimoineHierarchie],
        idSocieteCaracteristiques: societePatrimoineHierarchie.societeCaracteristiques as number[]
      })
    );
  }

  if (societePatrimoineHierarchie.patrimoines) {
    actions.push(
      PatrimoineGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess({
        idSocietePatrimoineHierarchies: [societePatrimoineHierarchie.idSocietePatrimoineHierarchie],
        idPatrimoines: societePatrimoineHierarchie.patrimoines as number[]
      })
    );
  }

  if (societePatrimoineHierarchie.hierarchieAncetres) {
    actions.push(
      SocietePatrimoineHierarchieAncetreGeneratedActions.deleteManyHierarchieDescendantSuccess({
        idHierarchieDescendant: [societePatrimoineHierarchie.idSocietePatrimoineHierarchie],
        idSocietePatrimoineHierarchieAncetres: societePatrimoineHierarchie.hierarchieAncetres as number[]
      })
    );
  }

  if (societePatrimoineHierarchie.hierarchieDescendants) {
    actions.push(
      SocietePatrimoineHierarchieAncetreGeneratedActions.deleteManyHierarchieAncetreSuccess({
        idHierarchieAncetre: [societePatrimoineHierarchie.idSocietePatrimoineHierarchie],
        idSocietePatrimoineHierarchieAncetres: societePatrimoineHierarchie.hierarchieDescendants as number[]
      })
    );
  }

  if (societePatrimoineHierarchie.societeComposantRattachements) {
    actions.push(
      SocieteComposantRattachementGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess({
        idSocietePatrimoineHierarchies: [societePatrimoineHierarchie.idSocietePatrimoineHierarchie],
        idSocieteComposantRattachements: societePatrimoineHierarchie.societeComposantRattachements as number[]
      })
    );
  }

  if (societePatrimoineHierarchie.societeEspaceFamilles) {
    actions.push(
      SocieteEspaceFamilleGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess({
        idSocietePatrimoineHierarchies: [societePatrimoineHierarchie.idSocietePatrimoineHierarchie],
        idSocieteEspaceFamilles: societePatrimoineHierarchie.societeEspaceFamilles as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSocietePatrimoineHierarchieEffects {
  constructor(
    protected actions$: Actions,
    protected societePatrimoineHierarchieApiService: SocietePatrimoineHierarchieApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocietePatrimoineHierarchies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieGeneratedActions.getManySocietePatrimoineHierarchies),
      switchMap(({ params }) =>
        this.societePatrimoineHierarchieApiService.getSocietePatrimoineHierarchies(params).pipe(
          map((societePatrimoineHierarchies: SocietePatrimoineHierarchie[]) => {
            return SocietePatrimoineHierarchieGeneratedActions.normalizeManySocietePatrimoineHierarchiesAfterUpsert({ societePatrimoineHierarchies });
          }),
          catchError(error => of(SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure({ error })))
        )
      )
    );
  });

  getOneSocietePatrimoineHierarchie$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieGeneratedActions.getOneSocietePatrimoineHierarchie),
      switchMap(idSocietePatrimoineHierarchie =>
        this.societePatrimoineHierarchieApiService.getSocietePatrimoineHierarchie(idSocietePatrimoineHierarchie).pipe(
          map((societePatrimoineHierarchie: SocietePatrimoineHierarchie) => {
            return SocietePatrimoineHierarchieGeneratedActions.normalizeManySocietePatrimoineHierarchiesAfterUpsert({ societePatrimoineHierarchies: [societePatrimoineHierarchie] });
          }),
          catchError(error => of(SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure({ error })))
        )
      )
    );
  });

  upsertOneSocietePatrimoineHierarchie$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieGeneratedActions.upsertOneSocietePatrimoineHierarchie),
      concatMap(
        ({
          societePatrimoineHierarchie,
          ids
        }: {
          societePatrimoineHierarchie: Partial<SocietePatrimoineHierarchie>;
          ids?: SocietePatrimoineHierarchieRelationsIds;
        }) => {
          if (societePatrimoineHierarchie.idSocietePatrimoineHierarchie) {
            return this.societePatrimoineHierarchieApiService.updateSocietePatrimoineHierarchie(societePatrimoineHierarchie).pipe(
              map((societePatrimoineHierarchieReturned: SocietePatrimoineHierarchie) => {
                return SocietePatrimoineHierarchieGeneratedActions.normalizeManySocietePatrimoineHierarchiesAfterUpsert({ societePatrimoineHierarchies: [societePatrimoineHierarchieReturned] });
              }),
              catchError(error => of(SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure({ error })))
            );
          } else {
            return this.societePatrimoineHierarchieApiService.addSocietePatrimoineHierarchie(societePatrimoineHierarchie).pipe(
              mergeMap((societePatrimoineHierarchieReturned: SocietePatrimoineHierarchie) => getDefaultAddSocietePatrimoineHierarchieActions(societePatrimoineHierarchieReturned, ids)),
              catchError(error => of(SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocietePatrimoineHierarchie$ = createEffect(() => {
    const selectSocietePatrimoineHierarchieState$ = this.store$.select(SocietePatrimoineHierarchieSelectors.selectSocietePatrimoineHierarchieState);
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieGeneratedActions.deleteOneSocietePatrimoineHierarchie),
      withLatestFrom(selectSocietePatrimoineHierarchieState$),
      concatMap(([{ idSocietePatrimoineHierarchie }, state]) =>
        this.societePatrimoineHierarchieApiService.deleteSocietePatrimoineHierarchie(idSocietePatrimoineHierarchie).pipe(
          mergeMap(_success => getDefaultDeleteSocietePatrimoineHierarchieActions(state.entities[idSocietePatrimoineHierarchie] as SocietePatrimoineHierarchieEntityState)),
          catchError(error => of(SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure({ error })))
        )
      )
    );
  });

  normalizeManySocietePatrimoineHierarchiesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieGeneratedActions.normalizeManySocietePatrimoineHierarchiesAfterUpsert),
      concatMap(({ societePatrimoineHierarchies }) => {
        const actions: Action[] = getActionsToNormalizeSocietePatrimoineHierarchie(societePatrimoineHierarchies, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocietePatrimoineHierarchie] Normalization After Upsert Success')];
      })
    );
  });
}
