import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Campagne, CampagneEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { CampagneUser, CampagneUserEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { CampagnePatrimoine, CampagnePatrimoineEntityState } from '@get/api-interfaces';
import { CampagneSocieteCaracteristique, CampagneSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { CampagneState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const campagneRelations: string[] = ['patrimoines','campagneUsers','users','societeCaracteristiques','campagnePatrimoines','campagneSocieteCaracteristiques','societes',];

export const { selectEntities, selectAll } = CampagneState.adapter.getSelectors();

export const selectCampagneState = createFeatureSelector<CampagneState.IState>(CampagneState.campagneFeatureKey);

export const selectIsLoadedCampagne = createSelector(
  selectCampagneState,
  (state: CampagneState.IState) => state.isLoaded
);

export const selectIsLoadingCampagne = createSelector(
  selectCampagneState,
  (state: CampagneState.IState) => state.isLoading
);

export const selectIsReadyCampagne = createSelector(
  selectCampagneState,
  (state: CampagneState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCampagne = createSelector(
  selectCampagneState,
  (state: CampagneState.IState) => state.isLoaded && !state.isLoading
);

export const selectCampagnesEntities = createSelector(selectCampagneState, selectEntities);

export const selectCampagnesArray = createSelector(selectCampagneState, selectAll);

export const selectIdCampagnesActive = createSelector(
  selectCampagneState,
  (state: CampagneState.IState) => state.actives
);

const campagnesInObject = (campagnes: Dictionary<CampagneEntityState>) => ({ campagnes })

const selectCampagnesEntitiesDictionary = createSelector(selectCampagnesEntities, campagnesInObject);

const selectAllCampagnesObject = createSelector(selectCampagnesEntities, campagnes => {
  return hydrateAll({ campagnes });
});

const selectOneCampagneDictionary = (idCampagne : number) =>
  createSelector(selectCampagnesEntities, campagnes => ({
    campagnes: { [idCampagne]: campagnes[idCampagne] }
  }));

const selectOneCampagneDictionaryWithoutChild = (idCampagne : number) =>
  createSelector(selectCampagnesEntities, campagnes => ({
    campagne: campagnes[idCampagne]
  }));

const selectActiveCampagnesEntities = createSelector(
  selectIdCampagnesActive,
  selectCampagnesEntities,
  (actives: number[], campagnes: Dictionary<CampagneEntityState>) => getCampagnesFromActives(actives, campagnes)
);

function getCampagnesFromActives(
  actives: number[],
  campagnes: Dictionary<CampagneEntityState>
): Dictionary<CampagneEntityState> {
  return actives.reduce((acc, idActive) => {
    if (campagnes[idActive]) {
      acc[idActive] = campagnes[idActive];
    }
    return acc;
  }, {} as Dictionary<CampagneEntityState>);
}

const selectAllCampagnesSelectors: Dictionary<Selector> = {};
export function selectAllCampagnes(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Campagne>(
      schema,
      selectAllCampagnesSelectors,
      selectCampagnesEntitiesDictionary,
      getRelationSelectors,
      campagneRelations,
      hydrateAll,
      'campagne'
    );
  } else {
    return selectAllCampagnesObject;
  }
}

export function selectAllCampagnesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'campagnes'
): Selector {
  return createSelector(selectAllCampagnes(schema), result => {
    const res = { [customKey]: {} as Dictionary<CampagneEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.campagnes.length; i++) {
      res[customKey][result.campagnes[i].idCampagne] = result.campagnes[i];
    }
    return res;
  });
}

export function selectOneCampagne(
  schema: SelectSchema = {},
  idCampagne: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneCampagneDictionary(idCampagne)];
  selectors.push(...getRelationSelectors(schema, campagneRelations, 'campagne'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCampagneDictionaryWithoutChild(idCampagne);
  }
}

export function selectActiveCampagnes(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCampagnesEntities, campagnes => ({
      campagnes
    }))
  ];
  selectors.push(...getRelationSelectors(schema, campagneRelations, 'campagne'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  campagnes: Dictionary<CampagneEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
  campagneUsers?: Dictionary<CampagneUserEntityState>;
  users?: Dictionary<UserEntityState>;
  societeCaracteristiques?: Dictionary<SocieteCaracteristiqueEntityState>;
  campagnePatrimoines?: Dictionary<CampagnePatrimoineEntityState>;
  campagneSocieteCaracteristiques?: Dictionary<CampagneSocieteCaracteristiqueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { campagnes: (Campagne | null)[] } {
  const {
    campagnes,
    societes,
    patrimoines,
    campagneUsers,
    users,
    societeCaracteristiques,
    campagnePatrimoines,
    campagneSocieteCaracteristiques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    campagnes: Object.keys(campagnes).map(idCampagne =>
      hydrate(
        campagnes[idCampagne] as CampagneEntityState,
        societes,
        patrimoines,
        campagneUsers,
        users,
        societeCaracteristiques,
        campagnePatrimoines,
        campagneSocieteCaracteristiques
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { campagne: CampagneEntityState | null } {
  const {
    campagnes,
    societes,
    patrimoines,
    campagneUsers,
    users,
    societeCaracteristiques,
    campagnePatrimoines,
    campagneSocieteCaracteristiques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const campagne = Object.values(campagnes)[0];
  return {
    campagne: hydrate(
      campagne as CampagneEntityState,
      societes,
      patrimoines,
      campagneUsers,
      users,
      societeCaracteristiques,
      campagnePatrimoines,
      campagneSocieteCaracteristiques
    )
  };
}

function hydrate(
  campagne: CampagneEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
  campagneUserEntities?: Dictionary<CampagneUserEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  societeCaracteristiqueEntities?: Dictionary<SocieteCaracteristiqueEntityState>,
  campagnePatrimoineEntities?: Dictionary<CampagnePatrimoineEntityState>,
  campagneSocieteCaracteristiqueEntities?: Dictionary<CampagneSocieteCaracteristiqueEntityState>,
): Campagne | null {
  if (!campagne) {
    return null;
  }

  const campagneHydrated: CampagneEntityState = { ...campagne };
  if (societeEntities) {
    campagneHydrated.societe = societeEntities[campagne.societe as number] as Societe;
  } else {
    delete campagneHydrated.societe;
  }

  if (patrimoineEntities) {
    campagneHydrated.patrimoines = ((campagneHydrated.patrimoines as number[]) || []).map(
      id => patrimoineEntities[id]
    ) as Patrimoine[];
  } else {
    delete campagneHydrated.patrimoines;
  }

  if (campagneUserEntities) {
    campagneHydrated.campagneUsers = ((campagneHydrated.campagneUsers as number[]) || []).map(
      id => campagneUserEntities[id]
    ) as CampagneUser[];
  } else {
    delete campagneHydrated.campagneUsers;
  }

  if (userEntities) {
    campagneHydrated.users = ((campagneHydrated.users as number[]) || []).map(
      id => userEntities[id]
    ) as User[];
  } else {
    delete campagneHydrated.users;
  }

  if (societeCaracteristiqueEntities) {
    campagneHydrated.societeCaracteristiques = ((campagneHydrated.societeCaracteristiques as number[]) || []).map(
      id => societeCaracteristiqueEntities[id]
    ) as SocieteCaracteristique[];
  } else {
    delete campagneHydrated.societeCaracteristiques;
  }

  if (campagnePatrimoineEntities) {
    campagneHydrated.campagnePatrimoines = ((campagneHydrated.campagnePatrimoines as number[]) || []).map(
      id => campagnePatrimoineEntities[id]
    ) as CampagnePatrimoine[];
  } else {
    delete campagneHydrated.campagnePatrimoines;
  }

  if (campagneSocieteCaracteristiqueEntities) {
    campagneHydrated.campagneSocieteCaracteristiques = ((campagneHydrated.campagneSocieteCaracteristiques as number[]) || []).map(
      id => campagneSocieteCaracteristiqueEntities[id]
    ) as CampagneSocieteCaracteristique[];
  } else {
    delete campagneHydrated.campagneSocieteCaracteristiques;
  }

  return campagneHydrated as Campagne;
}
