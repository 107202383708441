import { RepositoryService } from '@get/services/repository';
import { SocieteConfigAnalyseSynthese } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteConfigAnalyseSyntheseApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteConfigAnalyseSyntheses(params?: any): Observable<SocieteConfigAnalyseSynthese[]> {
    return this.repo.getData<SocieteConfigAnalyseSynthese[]>('societe-config-analyse-synthese', params);
  }

  public getSocieteConfigAnalyseSynthese(params: { idSocieteConfigAnalyseSynthese: number; params?: any }): Observable<SocieteConfigAnalyseSynthese> {
    return this.repo.getData<SocieteConfigAnalyseSynthese>('societe-config-analyse-synthese/' + params.idSocieteConfigAnalyseSynthese, params.params);
  }

  public addSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthese: Partial<SocieteConfigAnalyseSynthese>): Observable<SocieteConfigAnalyseSynthese> {
    return this.repo.create<SocieteConfigAnalyseSynthese>('societe-config-analyse-synthese', societeConfigAnalyseSynthese);
  }

  public updateSocieteConfigAnalyseSynthese(societeConfigAnalyseSynthese: Partial<SocieteConfigAnalyseSynthese>): Observable<SocieteConfigAnalyseSynthese> {
    return this.repo.update('societe-config-analyse-synthese', societeConfigAnalyseSynthese);
  }

  public deleteSocieteConfigAnalyseSynthese(idSocieteConfigAnalyseSynthese: number): Observable<number> {
    return this.repo.delete('societe-config-analyse-synthese/' + +idSocieteConfigAnalyseSynthese);
  }
}

