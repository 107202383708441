import { RepositoryService } from '@get/services/repository';
import { SocieteComposantRattachement } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteComposantRattachementApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteComposantRattachements(params?: any): Observable<SocieteComposantRattachement[]> {
    return this.repo.getData<SocieteComposantRattachement[]>('societe-composant-rattachement', params);
  }

  public getSocieteComposantRattachement(params: { idSocieteComposantRattachement: number; params?: any }): Observable<SocieteComposantRattachement> {
    return this.repo.getData<SocieteComposantRattachement>('societe-composant-rattachement/' + params.idSocieteComposantRattachement, params.params);
  }

  public addSocieteComposantRattachement(societeComposantRattachement: Partial<SocieteComposantRattachement>): Observable<SocieteComposantRattachement> {
    return this.repo.create<SocieteComposantRattachement>('societe-composant-rattachement', societeComposantRattachement);
  }

  public updateSocieteComposantRattachement(societeComposantRattachement: Partial<SocieteComposantRattachement>): Observable<SocieteComposantRattachement> {
    return this.repo.update('societe-composant-rattachement', societeComposantRattachement);
  }

  public deleteSocieteComposantRattachement(idSocieteComposantRattachement: number): Observable<number> {
    return this.repo.delete('societe-composant-rattachement/' + +idSocieteComposantRattachement);
  }
}

