import { Injectable } from '@angular/core';
import { Fichier } from '@get/api-interfaces';
import { FichierApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { FichierSelectors } from '@get/store/selectors';
import { SelectSchema } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { mapTo, Observable, switchMap, tap } from 'rxjs';
import { GeneratedFichierService } from './fichier-generated.service';
import { ImportTypeFileExampleEnum } from '@enums';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class FichierService extends GeneratedFichierService {
  constructor(private fichiersApiService: FichierApiService, store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public selectAllIconFichiers(schema: SelectSchema = {}): Observable<Fichier[]> {
    return this.store$.pipe(select(FichierSelectors.selectAllIconFichiers(schema))).pipe(
      switchMap(({ fichiers }: { fichiers: Fichier[] }) => {
        return this.getReady(schema).pipe(mapTo(fichiers));
      })
    );
  }

  public uploadMany(files: File[], queryParams: { idOrganisation?: number } = {}): Observable<Fichier[]> {
    return this.fichiersApiService.uploadMany(files, queryParams);
  }

  public importTemplate(
    idOrganisation: number,
    idSociete: number,
    dataTypeToImport: ImportTypeFileExampleEnum[]
  ): void {
    let fileName = 'GET_' + idSociete + '_';
    if (dataTypeToImport.length === 1 && dataTypeToImport[0] !== ImportTypeFileExampleEnum.allTypes) {
      fileName = fileName + 'Import_' + dataTypeToImport[0];
    } else {
      fileName = fileName + 'Imports';
    }
    fileName = fileName + '_' + dayjs().format('YYYY-MM-DD_HH[h]mm') + '.xlsx';
    this.fichiersApiService
      .importTemplate(idOrganisation, idSociete, dataTypeToImport)
      .pipe(tap(blob => saveAs(blob, fileName)))
      .subscribe();
  }

  public uploadGeneratedFile(idOrganisation: number, idSociete: number, pathFile: string): void {
    const fileName = pathFile.split('/');
    this.fichiersApiService
      .uploadGeneratedFile(idOrganisation, idSociete, pathFile)
      .pipe(tap(blob => saveAs(blob, fileName[fileName.length - 1])))
      .subscribe();
  }
}
