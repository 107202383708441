import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CaracteristiqueChoixTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueChoixTemplateState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { CaracteristiqueChoixTemplateEntityState } from '@get/api-interfaces';

export const caracteristiqueChoixTemplateReducersGeneratedFunctions: ReducerTypes<CaracteristiqueChoixTemplateState.IState, readonly ActionCreator[]>[] = [
  on(CaracteristiqueChoixTemplateGeneratedActions.getOneCaracteristiqueChoixTemplate, (state: CaracteristiqueChoixTemplateState.IState) => setLoadingsState(state, true)),
  on(CaracteristiqueChoixTemplateGeneratedActions.getManyCaracteristiqueChoixTemplates, (state: CaracteristiqueChoixTemplateState.IState) => setLoadingsState(state, true)),
  on(CaracteristiqueChoixTemplateGeneratedActions.upsertOneCaracteristiqueChoixTemplate, (state: CaracteristiqueChoixTemplateState.IState) => setLoadingsState(state, true)),

  on(CaracteristiqueChoixTemplateGeneratedActions.upsertManyCaracteristiqueChoixTemplatesSuccess, (state: CaracteristiqueChoixTemplateState.IState, { caracteristiqueChoixTemplates }) =>
    CaracteristiqueChoixTemplateState.adapter.upsertMany(caracteristiqueChoixTemplates, setLoadingsState(state, false))
  ),
  on(CaracteristiqueChoixTemplateGeneratedActions.deleteOneCaracteristiqueChoixTemplate, (state: CaracteristiqueChoixTemplateState.IState) => setLoadingsState(state, true)),
  on(CaracteristiqueChoixTemplateGeneratedActions.deleteOneCaracteristiqueChoixTemplateSuccess, (state: CaracteristiqueChoixTemplateState.IState, { idCaracteristiqueChoixTemplate }) =>
    CaracteristiqueChoixTemplateState.adapter.removeOne(idCaracteristiqueChoixTemplate, setLoadingsState(state, false))
  ),
  on(CaracteristiqueChoixTemplateGeneratedActions.clearActivesCaracteristiqueChoixTemplates, (state: CaracteristiqueChoixTemplateState.IState) => ({ ...state, actives: [] })),
  on(CaracteristiqueChoixTemplateGeneratedActions.addManyActivesCaracteristiqueChoixTemplates, (state: CaracteristiqueChoixTemplateState.IState, { idCaracteristiqueChoixTemplates }) => ({
    ...state,
    actives: state.actives.concat(idCaracteristiqueChoixTemplates)
  })),
  on(CaracteristiqueChoixTemplateGeneratedActions.deleteOneActiveCaracteristiqueChoixTemplate, (state: CaracteristiqueChoixTemplateState.IState, { idCaracteristiqueChoixTemplate }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCaracteristiqueChoixTemplate)
  })),

  on(CaracteristiqueChoixTemplateGeneratedActions.clearCaracteristiqueChoixTemplates, () => CaracteristiqueChoixTemplateState.initialState),

  on(
    CaracteristiqueChoixTemplateGeneratedActions.addManySocieteCaracteristiqueChoixSuccess,
    (state: CaracteristiqueChoixTemplateState.IState, { idCaracteristiqueChoixTemplate, idSocieteCaracteristiqueChoices }) => {
      if (!state.entities[idCaracteristiqueChoixTemplate]) {
        return state;
      }
      const societeCaracteristiqueChoices = (state.entities[idCaracteristiqueChoixTemplate]?.societeCaracteristiqueChoices as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCaracteristiqueChoixTemplate]: {
            ...state.entities[idCaracteristiqueChoixTemplate],
            societeCaracteristiqueChoices: societeCaracteristiqueChoices.concat(
              idSocieteCaracteristiqueChoices.filter(id => societeCaracteristiqueChoices.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CaracteristiqueChoixTemplateGeneratedActions.deleteManySocieteCaracteristiqueChoixSuccess,
    (state: CaracteristiqueChoixTemplateState.IState, { idSocieteCaracteristiqueChoices, idCaracteristiqueChoixTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCaracteristiqueChoixTemplates.reduce((entities, idCaracteristiqueChoixTemplate) => {
            if (!state.entities[idCaracteristiqueChoixTemplate]?.societeCaracteristiqueChoices) {
              return entities;
            }
            entities[idCaracteristiqueChoixTemplate] = {
              ...state.entities[idCaracteristiqueChoixTemplate],
              societeCaracteristiqueChoices: (state.entities[idCaracteristiqueChoixTemplate]?.societeCaracteristiqueChoices as number[])?.filter(
                (idSocieteCaracteristiqueChoix: number) => !idSocieteCaracteristiqueChoices.some((id: number) => id === idSocieteCaracteristiqueChoix)
              )
            } as CaracteristiqueChoixTemplateEntityState;
            return entities;
          }, {} as Dictionary<CaracteristiqueChoixTemplateEntityState>)
        }
      };
    }
  ),

  on(
    CaracteristiqueChoixTemplateGeneratedActions.addCaracteristiqueTemplateSuccess,
    (state: CaracteristiqueChoixTemplateState.IState, { idCaracteristiqueChoixTemplate, idCaracteristiqueTemplate }) => {
      if (!state.entities[idCaracteristiqueChoixTemplate]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCaracteristiqueChoixTemplate]: {
            ...state.entities[idCaracteristiqueChoixTemplate],
            caracteristiqueTemplate: idCaracteristiqueTemplate
          }
        }
      };
    }
  ),

  on(
    CaracteristiqueChoixTemplateGeneratedActions.deleteManyCaracteristiqueTemplateSuccess,
    (state: CaracteristiqueChoixTemplateState.IState, { idCaracteristiqueTemplates, idCaracteristiqueChoixTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCaracteristiqueChoixTemplates.reduce((entities, idCaracteristiqueChoixTemplate) => {
            if (!state.entities[idCaracteristiqueChoixTemplate]?.caracteristiqueTemplate) {
              return entities;
            }
            entities[idCaracteristiqueChoixTemplate] = {
              ...state.entities[idCaracteristiqueChoixTemplate],
              caracteristiqueTemplate: idCaracteristiqueTemplates.some(
                (idCaracteristiqueTemplate: number) => idCaracteristiqueTemplate === state.entities[idCaracteristiqueChoixTemplate]?.caracteristiqueTemplate
              )
                ? undefined
                : state.entities[idCaracteristiqueChoixTemplate]?.caracteristiqueTemplate
            } as CaracteristiqueChoixTemplateEntityState;
            return entities;
          }, {} as Dictionary<CaracteristiqueChoixTemplateEntityState>)
        }
      };
    }
  ),

  on(
    CaracteristiqueChoixTemplateGeneratedActions.addFichierSuccess,
    (state: CaracteristiqueChoixTemplateState.IState, { idCaracteristiqueChoixTemplate, idFichier }) => {
      if (!state.entities[idCaracteristiqueChoixTemplate]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCaracteristiqueChoixTemplate]: {
            ...state.entities[idCaracteristiqueChoixTemplate],
            fichier: idFichier
          }
        }
      };
    }
  ),

  on(
    CaracteristiqueChoixTemplateGeneratedActions.deleteManyFichierSuccess,
    (state: CaracteristiqueChoixTemplateState.IState, { idFichiers, idCaracteristiqueChoixTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCaracteristiqueChoixTemplates.reduce((entities, idCaracteristiqueChoixTemplate) => {
            if (!state.entities[idCaracteristiqueChoixTemplate]?.fichier) {
              return entities;
            }
            entities[idCaracteristiqueChoixTemplate] = {
              ...state.entities[idCaracteristiqueChoixTemplate],
              fichier: idFichiers.some(
                (idFichier: number) => idFichier === state.entities[idCaracteristiqueChoixTemplate]?.fichier
              )
                ? undefined
                : state.entities[idCaracteristiqueChoixTemplate]?.fichier
            } as CaracteristiqueChoixTemplateEntityState;
            return entities;
          }, {} as Dictionary<CaracteristiqueChoixTemplateEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: CaracteristiqueChoixTemplateState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CaracteristiqueChoixTemplateState.IState {
  return { ...state, isLoaded, isLoading };
}
