import { Injectable } from '@angular/core';
import {
  Composant,
  ComposantAttendu,
  DynamicType,
  PatrimoineWithChildren,
  SocieteCaracteristique,
  SocieteComposant,
  SocieteEspace,
  SocietePatrimoineHierarchie
} from '@get/api-interfaces';
import {
  ComposantGroupeModel,
  FichierModel,
  SocieteComposantFamilleModel,
  SocieteComposantModel,
  SocieteComposantRattachementModel,
  SocieteEspaceFamilleModel,
  SocieteEspaceModel,
  SocietePatrimoineHierarchieModel
} from '@get/store/selectors-model';
import {
  SocieteCaracteristiqueService,
  SocieteComposantService,
  SocieteEspaceService,
  SocietePatrimoineHierarchieService
} from '@get/store/services';
import { filterChildrenWithExistingComposantMassDuplication, patrimoineWithTitleInfos } from '@get/utils';
import { compareStrings, transformAncestorsArrayToTree, transformArrayToObject } from '@utils';
import { first, tap } from 'rxjs';
import { DbService } from './db.service';
import { PatrimoineDbService } from './patrimoine.db.service';

@Injectable({ providedIn: 'root' })
export class GatheringDataDbService {
  constructor(
    private dbService: DbService,
    private patrimoineDbService: PatrimoineDbService,
    private societePatrimoineHierarchieService: SocietePatrimoineHierarchieService,
    private societeComposantService: SocieteComposantService,
    private societeCaracteristiqueService: SocieteCaracteristiqueService,
    private societeEspaceService: SocieteEspaceService
  ) {}

  private selectSocietePatrimoineHierarchiesObj(): DynamicType<SocietePatrimoineHierarchie> {
    let societePatrimoineHierarchiesObj: DynamicType<SocietePatrimoineHierarchie> = {};
    this.societePatrimoineHierarchieService
      .selectAllSocietePatrimoineHierarchies({
        include: [
          {
            model: SocieteComposantRattachementModel,
            include: [
              { model: SocieteEspaceFamilleModel, include: [SocieteEspaceModel] },
              { model: SocieteComposantModel, include: [SocieteComposantFamilleModel] },
              SocietePatrimoineHierarchieModel
            ]
          }
        ]
      })
      .pipe(
        first(),
        tap(
          hierarchies =>
            (societePatrimoineHierarchiesObj = transformArrayToObject(hierarchies, {
              key: 'idSocietePatrimoineHierarchie'
            }))
        )
      )
      .subscribe();
    return societePatrimoineHierarchiesObj;
  }

  private selectSocieteComposantsObj(): DynamicType<SocieteComposant> {
    let societeComposantsObj: DynamicType<SocieteComposant> = {};
    this.societeComposantService
      .selectAllSocieteComposants({
        include: [
          FichierModel,
          { model: SocieteComposantFamilleModel, include: [FichierModel] },
          { model: ComposantGroupeModel, include: [FichierModel] },
          {
            model: SocieteComposantRattachementModel,
            include: [{ model: SocieteEspaceFamilleModel, include: [SocieteEspaceModel] }]
          }
        ]
      })
      .pipe(
        first(),
        tap(
          societeComposants =>
            (societeComposantsObj = transformArrayToObject(societeComposants, {
              key: 'idSocieteComposant'
            }))
        )
      )
      .subscribe();
    return societeComposantsObj;
  }

  private selectSocieteCaracteristiquesObj(): DynamicType<SocieteCaracteristique> {
    let societeCaracteristiquesObj: DynamicType<SocieteCaracteristique> = {};
    this.societeCaracteristiqueService
      .selectAllSocieteCaracteristiques()
      .pipe(
        first(),
        tap(
          societeCaracteristiques =>
            (societeCaracteristiquesObj = transformArrayToObject(societeCaracteristiques, {
              key: 'idSocieteCaracteristique'
            }))
        )
      )
      .subscribe();
    return societeCaracteristiquesObj;
  }

  private selectSocieteEspacesObj(): DynamicType<SocieteEspace> {
    let societeEspacesObj: DynamicType<SocieteEspace> = {};
    this.societeEspaceService
      .selectAllSocieteEspaces({ include: [SocieteEspaceFamilleModel] })
      .pipe(
        first(),
        tap(
          societeEspaces =>
            (societeEspacesObj = transformArrayToObject(societeEspaces, {
              key: 'idSocieteEspace'
            }))
        )
      )
      .subscribe();
    return societeEspacesObj;
  }

  public async gatherPatrimoineData(idPatrimoine: number): Promise<PatrimoineWithChildren[]> {
    const societePatrimoineHierarchiesObj = this.selectSocietePatrimoineHierarchiesObj();
    const societeComposantsObj = this.selectSocieteComposantsObj();
    const societeCaracteristiquesObj = this.selectSocieteCaracteristiquesObj();
    const societeEspacesObj = this.selectSocieteEspacesObj();

    const databaseCollections = await this.dbService.getDatabaseCollection();
    const highestPatrimoines = await this.patrimoineDbService.getHighestPatrimoineForIdPatrimoine(
      idPatrimoine,
      databaseCollections
    );
    const idHighestPatrimoine = highestPatrimoines[0].idPatrimoine;
    const composantAttendusDocuments = await databaseCollections['composant-attendus'].find().exec();
    const composantsDocuments = await databaseCollections['composants'].find().exec();

    const composantAttendus = composantAttendusDocuments.map(el => ({
      ...el.toJSON(),
      idComposantAttendu: +el.idComposantAttendu
    }));
    const composants = composantsDocuments.map(el => {
      const composant = el.toJSON();
      return {
        ...composant,
        societeComposant: societeComposantsObj[composant.idSocieteComposant],
        valeurs: composant.valeurs?.map(valeur => ({
          ...valeur,
          societeCaracteristique: societeCaracteristiquesObj[valeur.idSocieteCaracteristique]
        }))
      };
    });

    const composantAttendusObj: DynamicType<ComposantAttendu> = transformArrayToObject(composantAttendus, {
      key: 'idComposantAttendu'
    });
    const composantObjPerEspace = (composants as unknown as Composant[]).reduce((acc, curr) => {
      if (acc[curr.idEspace]?.length) {
        acc[curr.idEspace].push(curr);
      } else {
        acc[curr.idEspace] = [curr];
      }
      return acc;
    }, {} as DynamicType<Composant[]>);

    const patrimoines = highestPatrimoines
      .map(patrimoine => ({
        ...patrimoine,
        societePatrimoineHierarchie: {
          ...(societePatrimoineHierarchiesObj[patrimoine.idSocietePatrimoineHierarchie] ||
            patrimoine.societePatrimoineHierarchie)
        },
        composantAttendus: patrimoine.composantAttendus?.map(composantAttendu => {
          const el = composantAttendusObj[composantAttendu.idComposantAttendu] || composantAttendu;
          return {
            ...el,
            societeComposant:
              typeof el.societeComposant === 'number'
                ? societeComposantsObj[el.societeComposant]
                : societeComposantsObj[el.idSocieteComposant]
          };
        }),
        espaces: patrimoine.espaces?.map(espace => ({
          ...espace,
          societeEspace:
            typeof espace.societeEspace === 'number'
              ? societeEspacesObj[espace.societeEspace]
              : societeEspacesObj[espace.idSocieteEspace],
          composants: composantObjPerEspace[espace.idEspace] || []
        }))
      }))
      .sort((a, b) => compareStrings(a.libelle, b.libelle));

    const patrimoineTargetMassDuplication = transformAncestorsArrayToTree(idHighestPatrimoine, patrimoines, {
      key: 'idPatrimoine',
      childrenKey: 'children',
      parentListKey: 'ancetres',
      parentListIdentifierKey: 'idAncetrePatrimoine'
    });
    const filteredArray = filterChildrenWithExistingComposantMassDuplication(patrimoineTargetMassDuplication);
    return patrimoineWithTitleInfos(filteredArray);
  }
}
