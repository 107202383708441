export enum LibelleInternRightsEnum {
  accessConsulterEtEnrichir = 'consulter_et_enrichir_access',
  accessCampagneCollecte = 'campagne_collecte_access',
  accessAnalyser = 'analyser_access',
  accessSupport = 'support_access',
  updatePatrimoine = 'patrimoine_U',
  updatePatrimoineCaracteristique = 'patrimoine_caracteristique_U',
  createDeleteComposant = 'composant_CD',
  updateComposant = 'composant_U',
  createUpdateDeletecampagne = 'campagne_CUD',
  createUpdateDeleteUser = 'user_CUD',
  createDeletePatrimoine = 'patrimoine_CUD',
  createUpdateDeleteSetting = 'setting_CUD',
  createUpdateDeleteImportExcel = 'import_excel_CUD',
  deleteGlobal = 'delete_all',
  getApi = 'api_G',
  createUpdateDeleteApi = 'api_CUD'
}
