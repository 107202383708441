// TODO: Typer les fonctions de ce fichier
export function transformArrayToTree(
  values: any[],
  identifiers: { key: string; parentKey: string; childrenKey: string; sortKey?: string }
): any[] {
  if (!values?.length) {
    return [];
  }
  const obj = transformArrayToObject(values, identifiers);

  let i = 0;
  while (i < values.length) {
    if (values[i][identifiers.parentKey] && obj[values[i][identifiers.parentKey]]?.[identifiers.childrenKey]) {
      obj[values[i][identifiers.parentKey]][identifiers.childrenKey].push(obj[values[i][identifiers.key]]);
    }
    i++;
  }
  const res = values.filter(value => !value[identifiers.parentKey])?.map(value => obj[value[identifiers.key]]);
  if (identifiers.sortKey) {
    res.sort((a, b) => a[identifiers.sortKey as string] - b[identifiers.sortKey as string]);
  }
  return res;
}

export function transformArrayToTreeObj(
  values: any[],
  identifiers: { key: string; parentKey: string; childrenKey: string; sortKey?: string }
): any {
  if (!values?.length) {
    return {};
  }
  const obj = transformArrayToObject(values, identifiers);

  let i = 0;
  while (i < values.length) {
    if (values[i][identifiers.parentKey] && obj[values[i][identifiers.parentKey]]?.[identifiers.childrenKey]) {
      obj[values[i][identifiers.parentKey]][identifiers.childrenKey].push(obj[values[i][identifiers.key]]);
    }
    i++;
  }
  return obj;
}

export function transformArrayToObject(values: any[], identifiers: { key: string; childrenKey?: string }): any {
  const obj = {} as any;

  let i = 0;
  while (i < values?.length) {
    const value = values[i];
    obj[value[identifiers.key]] = identifiers.childrenKey ? { ...value, [identifiers.childrenKey]: [] } : { ...value };
    i++;
  }
  return obj;
}

export function transformArrayToObjectWithReduce(
  values: any[],
  identifiers: { key: string; childrenKey?: string }
): any {
  return (
    values?.reduce((acc, value) => {
      acc[value[identifiers.key]] = identifiers.childrenKey
        ? { ...value, [identifiers.childrenKey]: [] }
        : { ...value };
      return acc;
    }, {}) || {}
  );
}
