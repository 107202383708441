import { createEntityAdapter } from '@ngrx/entity';
import { CampagneEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<CampagneEntityState>;

export const adapter = createEntityAdapter<CampagneEntityState>({
  selectId: o => o.idCampagne
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const campagneFeatureKey = 'campagne';
