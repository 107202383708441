import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { UserPatrimoine, UserPatrimoineEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { ValeurPatrimoine, ValeurPatrimoineEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { PatrimoineAncetre, PatrimoineAncetreEntityState } from '@get/api-interfaces';
import { Espace, EspaceEntityState } from '@get/api-interfaces';
import { CampagnePatrimoine, CampagnePatrimoineEntityState } from '@get/api-interfaces';
import { Campagne, CampagneEntityState } from '@get/api-interfaces';
import { SocieteTerritoirePatrimoine, SocieteTerritoirePatrimoineEntityState } from '@get/api-interfaces';
import { SocieteTerritoire, SocieteTerritoireEntityState } from '@get/api-interfaces';
import { ComposantAttendu, ComposantAttenduEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { PatrimoineState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const patrimoineRelations: string[] = ['userPatrimoines','users','valeurPatrimoines','valeurs','ancetres','descendants','espaces','campagnePatrimoines','campagnes','societeTerritoirePatrimoines','societeTerritoires','composantAttendus','societePatrimoineHierarchies','societes',];

export const { selectEntities, selectAll } = PatrimoineState.adapter.getSelectors();

export const selectPatrimoineState = createFeatureSelector<PatrimoineState.IState>(PatrimoineState.patrimoineFeatureKey);

export const selectIsLoadedPatrimoine = createSelector(
  selectPatrimoineState,
  (state: PatrimoineState.IState) => state.isLoaded
);

export const selectIsLoadingPatrimoine = createSelector(
  selectPatrimoineState,
  (state: PatrimoineState.IState) => state.isLoading
);

export const selectIsReadyPatrimoine = createSelector(
  selectPatrimoineState,
  (state: PatrimoineState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedPatrimoine = createSelector(
  selectPatrimoineState,
  (state: PatrimoineState.IState) => state.isLoaded && !state.isLoading
);

export const selectPatrimoinesEntities = createSelector(selectPatrimoineState, selectEntities);

export const selectPatrimoinesArray = createSelector(selectPatrimoineState, selectAll);

export const selectIdPatrimoinesActive = createSelector(
  selectPatrimoineState,
  (state: PatrimoineState.IState) => state.actives
);

const patrimoinesInObject = (patrimoines: Dictionary<PatrimoineEntityState>) => ({ patrimoines })

const selectPatrimoinesEntitiesDictionary = createSelector(selectPatrimoinesEntities, patrimoinesInObject);

const selectAllPatrimoinesObject = createSelector(selectPatrimoinesEntities, patrimoines => {
  return hydrateAll({ patrimoines });
});

const selectOnePatrimoineDictionary = (idPatrimoine : number) =>
  createSelector(selectPatrimoinesEntities, patrimoines => ({
    patrimoines: { [idPatrimoine]: patrimoines[idPatrimoine] }
  }));

const selectOnePatrimoineDictionaryWithoutChild = (idPatrimoine : number) =>
  createSelector(selectPatrimoinesEntities, patrimoines => ({
    patrimoine: patrimoines[idPatrimoine]
  }));

const selectActivePatrimoinesEntities = createSelector(
  selectIdPatrimoinesActive,
  selectPatrimoinesEntities,
  (actives: number[], patrimoines: Dictionary<PatrimoineEntityState>) => getPatrimoinesFromActives(actives, patrimoines)
);

function getPatrimoinesFromActives(
  actives: number[],
  patrimoines: Dictionary<PatrimoineEntityState>
): Dictionary<PatrimoineEntityState> {
  return actives.reduce((acc, idActive) => {
    if (patrimoines[idActive]) {
      acc[idActive] = patrimoines[idActive];
    }
    return acc;
  }, {} as Dictionary<PatrimoineEntityState>);
}

const selectAllPatrimoinesSelectors: Dictionary<Selector> = {};
export function selectAllPatrimoines(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Patrimoine>(
      schema,
      selectAllPatrimoinesSelectors,
      selectPatrimoinesEntitiesDictionary,
      getRelationSelectors,
      patrimoineRelations,
      hydrateAll,
      'patrimoine'
    );
  } else {
    return selectAllPatrimoinesObject;
  }
}

export function selectAllPatrimoinesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'patrimoines'
): Selector {
  return createSelector(selectAllPatrimoines(schema), result => {
    const res = { [customKey]: {} as Dictionary<PatrimoineEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.patrimoines.length; i++) {
      res[customKey][result.patrimoines[i].idPatrimoine] = result.patrimoines[i];
    }
    return res;
  });
}

export function selectOnePatrimoine(
  schema: SelectSchema = {},
  idPatrimoine: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOnePatrimoineDictionary(idPatrimoine)];
  selectors.push(...getRelationSelectors(schema, patrimoineRelations, 'patrimoine'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOnePatrimoineDictionaryWithoutChild(idPatrimoine);
  }
}

export function selectActivePatrimoines(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActivePatrimoinesEntities, patrimoines => ({
      patrimoines
    }))
  ];
  selectors.push(...getRelationSelectors(schema, patrimoineRelations, 'patrimoine'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  patrimoines: Dictionary<PatrimoineEntityState>;
  societePatrimoineHierarchies?: Dictionary<SocietePatrimoineHierarchieEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  userPatrimoines?: Dictionary<UserPatrimoineEntityState>;
  users?: Dictionary<UserEntityState>;
  valeurPatrimoines?: Dictionary<ValeurPatrimoineEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
  ancetres?: Dictionary<PatrimoineAncetreEntityState>;
  descendants?: Dictionary<PatrimoineAncetreEntityState>;
  espaces?: Dictionary<EspaceEntityState>;
  campagnePatrimoines?: Dictionary<CampagnePatrimoineEntityState>;
  campagnes?: Dictionary<CampagneEntityState>;
  societeTerritoirePatrimoines?: Dictionary<SocieteTerritoirePatrimoineEntityState>;
  societeTerritoires?: Dictionary<SocieteTerritoireEntityState>;
  composantAttendus?: Dictionary<ComposantAttenduEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { patrimoines: (Patrimoine | null)[] } {
  const {
    patrimoines,
    societePatrimoineHierarchies,
    societes,
    userPatrimoines,
    users,
    valeurPatrimoines,
    valeurs,
    ancetres,
    descendants,
    espaces,
    campagnePatrimoines,
    campagnes,
    societeTerritoirePatrimoines,
    societeTerritoires,
    composantAttendus
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    patrimoines: Object.keys(patrimoines).map(idPatrimoine =>
      hydrate(
        patrimoines[idPatrimoine] as PatrimoineEntityState,
        societePatrimoineHierarchies,
        societes,
        userPatrimoines,
        users,
        valeurPatrimoines,
        valeurs,
        ancetres,
        descendants,
        espaces,
        campagnePatrimoines,
        campagnes,
        societeTerritoirePatrimoines,
        societeTerritoires,
        composantAttendus
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { patrimoine: PatrimoineEntityState | null } {
  const {
    patrimoines,
    societePatrimoineHierarchies,
    societes,
    userPatrimoines,
    users,
    valeurPatrimoines,
    valeurs,
    ancetres,
    descendants,
    espaces,
    campagnePatrimoines,
    campagnes,
    societeTerritoirePatrimoines,
    societeTerritoires,
    composantAttendus
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const patrimoine = Object.values(patrimoines)[0];
  return {
    patrimoine: hydrate(
      patrimoine as PatrimoineEntityState,
      societePatrimoineHierarchies,
      societes,
      userPatrimoines,
      users,
      valeurPatrimoines,
      valeurs,
      ancetres,
      descendants,
      espaces,
      campagnePatrimoines,
      campagnes,
      societeTerritoirePatrimoines,
      societeTerritoires,
      composantAttendus
    )
  };
}

function hydrate(
  patrimoine: PatrimoineEntityState,
  societePatrimoineHierarchieEntities?: Dictionary<SocietePatrimoineHierarchieEntityState>,
  societeEntities?: Dictionary<SocieteEntityState>,
  userPatrimoineEntities?: Dictionary<UserPatrimoineEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  valeurPatrimoineEntities?: Dictionary<ValeurPatrimoineEntityState>,
  valeurEntities?: Dictionary<ValeurEntityState>,
  ancetresEntities?: Dictionary<PatrimoineAncetreEntityState>,
  descendantsEntities?: Dictionary<PatrimoineAncetreEntityState>,
  espaceEntities?: Dictionary<EspaceEntityState>,
  campagnePatrimoineEntities?: Dictionary<CampagnePatrimoineEntityState>,
  campagneEntities?: Dictionary<CampagneEntityState>,
  societeTerritoirePatrimoineEntities?: Dictionary<SocieteTerritoirePatrimoineEntityState>,
  societeTerritoireEntities?: Dictionary<SocieteTerritoireEntityState>,
  composantAttenduEntities?: Dictionary<ComposantAttenduEntityState>,
): Patrimoine | null {
  if (!patrimoine) {
    return null;
  }

  const patrimoineHydrated: PatrimoineEntityState = { ...patrimoine };
  if (societePatrimoineHierarchieEntities) {
    patrimoineHydrated.societePatrimoineHierarchie = societePatrimoineHierarchieEntities[patrimoine.societePatrimoineHierarchie as number] as SocietePatrimoineHierarchie;
  } else {
    delete patrimoineHydrated.societePatrimoineHierarchie;
  }
  if (societeEntities) {
    patrimoineHydrated.societe = societeEntities[patrimoine.societe as number] as Societe;
  } else {
    delete patrimoineHydrated.societe;
  }

  if (userPatrimoineEntities) {
    patrimoineHydrated.userPatrimoines = ((patrimoineHydrated.userPatrimoines as number[]) || []).map(
      id => userPatrimoineEntities[id]
    ) as UserPatrimoine[];
  } else {
    delete patrimoineHydrated.userPatrimoines;
  }

  if (userEntities) {
    patrimoineHydrated.users = ((patrimoineHydrated.users as number[]) || []).map(
      id => userEntities[id]
    ) as User[];
  } else {
    delete patrimoineHydrated.users;
  }

  if (valeurPatrimoineEntities) {
    patrimoineHydrated.valeurPatrimoines = ((patrimoineHydrated.valeurPatrimoines as number[]) || []).map(
      id => valeurPatrimoineEntities[id]
    ) as ValeurPatrimoine[];
  } else {
    delete patrimoineHydrated.valeurPatrimoines;
  }

  if (valeurEntities) {
    patrimoineHydrated.valeurs = ((patrimoineHydrated.valeurs as number[]) || []).map(
      id => valeurEntities[id]
    ) as Valeur[];
  } else {
    delete patrimoineHydrated.valeurs;
  }

  if (ancetresEntities) {
    patrimoineHydrated.ancetres = ((patrimoineHydrated.ancetres as number[]) || []).map(
      id => ancetresEntities[id]
    ) as PatrimoineAncetre[];
  } else {
    delete patrimoineHydrated.ancetres;
  }

  if (descendantsEntities) {
    patrimoineHydrated.descendants = ((patrimoineHydrated.descendants as number[]) || []).map(
      id => descendantsEntities[id]
    ) as PatrimoineAncetre[];
  } else {
    delete patrimoineHydrated.descendants;
  }

  if (espaceEntities) {
    patrimoineHydrated.espaces = ((patrimoineHydrated.espaces as number[]) || []).map(
      id => espaceEntities[id]
    ) as Espace[];
  } else {
    delete patrimoineHydrated.espaces;
  }

  if (campagnePatrimoineEntities) {
    patrimoineHydrated.campagnePatrimoines = ((patrimoineHydrated.campagnePatrimoines as number[]) || []).map(
      id => campagnePatrimoineEntities[id]
    ) as CampagnePatrimoine[];
  } else {
    delete patrimoineHydrated.campagnePatrimoines;
  }

  if (campagneEntities) {
    patrimoineHydrated.campagnes = ((patrimoineHydrated.campagnes as number[]) || []).map(
      id => campagneEntities[id]
    ) as Campagne[];
  } else {
    delete patrimoineHydrated.campagnes;
  }

  if (societeTerritoirePatrimoineEntities) {
    patrimoineHydrated.societeTerritoirePatrimoines = ((patrimoineHydrated.societeTerritoirePatrimoines as number[]) || []).map(
      id => societeTerritoirePatrimoineEntities[id]
    ) as SocieteTerritoirePatrimoine[];
  } else {
    delete patrimoineHydrated.societeTerritoirePatrimoines;
  }

  if (societeTerritoireEntities) {
    patrimoineHydrated.societeTerritoires = ((patrimoineHydrated.societeTerritoires as number[]) || []).map(
      id => societeTerritoireEntities[id]
    ) as SocieteTerritoire[];
  } else {
    delete patrimoineHydrated.societeTerritoires;
  }

  if (composantAttenduEntities) {
    patrimoineHydrated.composantAttendus = ((patrimoineHydrated.composantAttendus as number[]) || []).map(
      id => composantAttenduEntities[id]
    ) as ComposantAttendu[];
  } else {
    delete patrimoineHydrated.composantAttendus;
  }

  return patrimoineHydrated as Patrimoine;
}
