import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteTerritoireUser, SocieteTerritoireUserEntityState } from '@get/api-interfaces';
import { SocieteTerritoireUserApiService } from '@get/store/api-services';
import { SocieteTerritoireUserGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteTerritoireUser } from '@get/store/configs/normalization';
import { SocieteTerritoireUserSelectors } from '@get/store/selectors';
import { SocieteTerritoireUserRelationsIds } from '@get/store/ids-interfaces';
import { SocieteTerritoireGeneratedActions } from '@get/store/actions';
import { UserGeneratedActions } from '@get/store/actions';

export function getDefaultAddSocieteTerritoireUserActions(societeTerritoireUser: SocieteTerritoireUserEntityState, ids?: SocieteTerritoireUserRelationsIds): Action[] {
  const actions: Action[] = [SocieteTerritoireUserGeneratedActions.normalizeManySocieteTerritoireUsersAfterUpsert({ societeTerritoireUsers: [societeTerritoireUser] })];

  if (ids?.societeTerritoire) {
    actions.push(
      SocieteTerritoireGeneratedActions.addManySocieteTerritoireUserSuccess({
        idSocieteTerritoire: ids.societeTerritoire,
        idSocieteTerritoireUsers: [societeTerritoireUser.idSocieteTerritoireUser]
      })
    );
    actions.push(
      SocieteTerritoireUserGeneratedActions.addSocieteTerritoireSuccess({
        idSocieteTerritoireUser: societeTerritoireUser.idSocieteTerritoireUser,
        idSocieteTerritoire: ids.societeTerritoire
      })
    );
  }

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManySocieteTerritoireUserSuccess({
        idUser: ids.user,
        idSocieteTerritoireUsers: [societeTerritoireUser.idSocieteTerritoireUser]
      })
    );
    actions.push(
      SocieteTerritoireUserGeneratedActions.addUserSuccess({
        idSocieteTerritoireUser: societeTerritoireUser.idSocieteTerritoireUser,
        idUser: ids.user
      })
    );
  }

  return actions;
}

export function getDefaultDeleteSocieteTerritoireUserActions(societeTerritoireUser: SocieteTerritoireUserEntityState): Action[] {
  const actions: Action[] = [SocieteTerritoireUserGeneratedActions.deleteOneSocieteTerritoireUserSuccess({ idSocieteTerritoireUser: societeTerritoireUser.idSocieteTerritoireUser })];

  if (societeTerritoireUser.societeTerritoire) {
    actions.push(
      SocieteTerritoireGeneratedActions.deleteManySocieteTerritoireUserSuccess({
        idSocieteTerritoireUsers: [societeTerritoireUser.idSocieteTerritoireUser],
        idSocieteTerritoires: [societeTerritoireUser.societeTerritoire as number]
      })
    );
  }

  if (societeTerritoireUser.user) {
    actions.push(
      UserGeneratedActions.deleteManySocieteTerritoireUserSuccess({
        idSocieteTerritoireUsers: [societeTerritoireUser.idSocieteTerritoireUser],
        idUsers: [societeTerritoireUser.user as number]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteTerritoireUserEffects {
  constructor(
    protected actions$: Actions,
    protected societeTerritoireUserApiService: SocieteTerritoireUserApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocieteTerritoireUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteTerritoireUserGeneratedActions.getManySocieteTerritoireUsers),
      switchMap(({ params }) =>
        this.societeTerritoireUserApiService.getSocieteTerritoireUsers(params).pipe(
          map((societeTerritoireUsers: SocieteTerritoireUser[]) => {
            return SocieteTerritoireUserGeneratedActions.normalizeManySocieteTerritoireUsersAfterUpsert({ societeTerritoireUsers });
          }),
          catchError(error => of(SocieteTerritoireUserGeneratedActions.societeTerritoireUsersFailure({ error })))
        )
      )
    );
  });

  getOneSocieteTerritoireUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteTerritoireUserGeneratedActions.getOneSocieteTerritoireUser),
      switchMap(idSocieteTerritoireUser =>
        this.societeTerritoireUserApiService.getSocieteTerritoireUser(idSocieteTerritoireUser).pipe(
          map((societeTerritoireUser: SocieteTerritoireUser) => {
            return SocieteTerritoireUserGeneratedActions.normalizeManySocieteTerritoireUsersAfterUpsert({ societeTerritoireUsers: [societeTerritoireUser] });
          }),
          catchError(error => of(SocieteTerritoireUserGeneratedActions.societeTerritoireUsersFailure({ error })))
        )
      )
    );
  });

  upsertOneSocieteTerritoireUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteTerritoireUserGeneratedActions.upsertOneSocieteTerritoireUser),
      concatMap(
        ({
          societeTerritoireUser,
          ids
        }: {
          societeTerritoireUser: Partial<SocieteTerritoireUser>;
          ids?: SocieteTerritoireUserRelationsIds;
        }) => {
          if (societeTerritoireUser.idSocieteTerritoireUser) {
            return this.societeTerritoireUserApiService.updateSocieteTerritoireUser(societeTerritoireUser).pipe(
              map((societeTerritoireUserReturned: SocieteTerritoireUser) => {
                return SocieteTerritoireUserGeneratedActions.normalizeManySocieteTerritoireUsersAfterUpsert({ societeTerritoireUsers: [societeTerritoireUserReturned] });
              }),
              catchError(error => of(SocieteTerritoireUserGeneratedActions.societeTerritoireUsersFailure({ error })))
            );
          } else {
            return this.societeTerritoireUserApiService.addSocieteTerritoireUser(societeTerritoireUser).pipe(
              mergeMap((societeTerritoireUserReturned: SocieteTerritoireUser) => getDefaultAddSocieteTerritoireUserActions(societeTerritoireUserReturned, ids)),
              catchError(error => of(SocieteTerritoireUserGeneratedActions.societeTerritoireUsersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteTerritoireUser$ = createEffect(() => {
    const selectSocieteTerritoireUserState$ = this.store$.select(SocieteTerritoireUserSelectors.selectSocieteTerritoireUserState);
    return this.actions$.pipe(
      ofType(SocieteTerritoireUserGeneratedActions.deleteOneSocieteTerritoireUser),
      withLatestFrom(selectSocieteTerritoireUserState$),
      concatMap(([{ idSocieteTerritoireUser }, state]) =>
        this.societeTerritoireUserApiService.deleteSocieteTerritoireUser(idSocieteTerritoireUser).pipe(
          mergeMap(_success => getDefaultDeleteSocieteTerritoireUserActions(state.entities[idSocieteTerritoireUser] as SocieteTerritoireUserEntityState)),
          catchError(error => of(SocieteTerritoireUserGeneratedActions.societeTerritoireUsersFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteTerritoireUsersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteTerritoireUserGeneratedActions.normalizeManySocieteTerritoireUsersAfterUpsert),
      concatMap(({ societeTerritoireUsers }) => {
        const actions: Action[] = getActionsToNormalizeSocieteTerritoireUser(societeTerritoireUsers, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteTerritoireUser] Normalization After Upsert Success')];
      })
    );
  });
}
