import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteTerritoirePatrimoineGeneratedActions } from '@get/store/actions';
import { SocieteTerritoirePatrimoineState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteTerritoirePatrimoineEntityState } from '@get/api-interfaces';

export const societeTerritoirePatrimoineReducersGeneratedFunctions: ReducerTypes<SocieteTerritoirePatrimoineState.IState, readonly ActionCreator[]>[] = [
  on(SocieteTerritoirePatrimoineGeneratedActions.getOneSocieteTerritoirePatrimoine, (state: SocieteTerritoirePatrimoineState.IState) => setLoadingsState(state, true)),
  on(SocieteTerritoirePatrimoineGeneratedActions.getManySocieteTerritoirePatrimoines, (state: SocieteTerritoirePatrimoineState.IState) => setLoadingsState(state, true)),
  on(SocieteTerritoirePatrimoineGeneratedActions.upsertOneSocieteTerritoirePatrimoine, (state: SocieteTerritoirePatrimoineState.IState) => setLoadingsState(state, true)),

  on(SocieteTerritoirePatrimoineGeneratedActions.upsertManySocieteTerritoirePatrimoinesSuccess, (state: SocieteTerritoirePatrimoineState.IState, { societeTerritoirePatrimoines }) =>
    SocieteTerritoirePatrimoineState.adapter.upsertMany(societeTerritoirePatrimoines, setLoadingsState(state, false))
  ),
  on(SocieteTerritoirePatrimoineGeneratedActions.deleteOneSocieteTerritoirePatrimoine, (state: SocieteTerritoirePatrimoineState.IState) => setLoadingsState(state, true)),
  on(SocieteTerritoirePatrimoineGeneratedActions.deleteOneSocieteTerritoirePatrimoineSuccess, (state: SocieteTerritoirePatrimoineState.IState, { idSocieteTerritoirePatrimoine }) =>
    SocieteTerritoirePatrimoineState.adapter.removeOne(idSocieteTerritoirePatrimoine, setLoadingsState(state, false))
  ),
  on(SocieteTerritoirePatrimoineGeneratedActions.clearActivesSocieteTerritoirePatrimoines, (state: SocieteTerritoirePatrimoineState.IState) => ({ ...state, actives: [] })),
  on(SocieteTerritoirePatrimoineGeneratedActions.addManyActivesSocieteTerritoirePatrimoines, (state: SocieteTerritoirePatrimoineState.IState, { idSocieteTerritoirePatrimoines }) => ({
    ...state,
    actives: state.actives.concat(idSocieteTerritoirePatrimoines)
  })),
  on(SocieteTerritoirePatrimoineGeneratedActions.deleteOneActiveSocieteTerritoirePatrimoine, (state: SocieteTerritoirePatrimoineState.IState, { idSocieteTerritoirePatrimoine }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idSocieteTerritoirePatrimoine)
  })),

  on(SocieteTerritoirePatrimoineGeneratedActions.clearSocieteTerritoirePatrimoines, () => SocieteTerritoirePatrimoineState.initialState),

  on(
    SocieteTerritoirePatrimoineGeneratedActions.addSocieteTerritoireSuccess,
    (state: SocieteTerritoirePatrimoineState.IState, { idSocieteTerritoirePatrimoine, idSocieteTerritoire }) => {
      if (!state.entities[idSocieteTerritoirePatrimoine]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteTerritoirePatrimoine]: {
            ...state.entities[idSocieteTerritoirePatrimoine],
            societeTerritoire: idSocieteTerritoire
          }
        }
      };
    }
  ),

  on(
    SocieteTerritoirePatrimoineGeneratedActions.deleteManySocieteTerritoireSuccess,
    (state: SocieteTerritoirePatrimoineState.IState, { idSocieteTerritoires, idSocieteTerritoirePatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteTerritoirePatrimoines.reduce((entities, idSocieteTerritoirePatrimoine) => {
            if (!state.entities[idSocieteTerritoirePatrimoine]?.societeTerritoire) {
              return entities;
            }
            entities[idSocieteTerritoirePatrimoine] = {
              ...state.entities[idSocieteTerritoirePatrimoine],
              societeTerritoire: idSocieteTerritoires.some(
                (idSocieteTerritoire: number) => idSocieteTerritoire === state.entities[idSocieteTerritoirePatrimoine]?.societeTerritoire
              )
                ? undefined
                : state.entities[idSocieteTerritoirePatrimoine]?.societeTerritoire
            } as SocieteTerritoirePatrimoineEntityState;
            return entities;
          }, {} as Dictionary<SocieteTerritoirePatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    SocieteTerritoirePatrimoineGeneratedActions.addPatrimoineSuccess,
    (state: SocieteTerritoirePatrimoineState.IState, { idSocieteTerritoirePatrimoine, idPatrimoine }) => {
      if (!state.entities[idSocieteTerritoirePatrimoine]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteTerritoirePatrimoine]: {
            ...state.entities[idSocieteTerritoirePatrimoine],
            patrimoine: idPatrimoine
          }
        }
      };
    }
  ),

  on(
    SocieteTerritoirePatrimoineGeneratedActions.deleteManyPatrimoineSuccess,
    (state: SocieteTerritoirePatrimoineState.IState, { idPatrimoines, idSocieteTerritoirePatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteTerritoirePatrimoines.reduce((entities, idSocieteTerritoirePatrimoine) => {
            if (!state.entities[idSocieteTerritoirePatrimoine]?.patrimoine) {
              return entities;
            }
            entities[idSocieteTerritoirePatrimoine] = {
              ...state.entities[idSocieteTerritoirePatrimoine],
              patrimoine: idPatrimoines.some(
                (idPatrimoine: number) => idPatrimoine === state.entities[idSocieteTerritoirePatrimoine]?.patrimoine
              )
                ? undefined
                : state.entities[idSocieteTerritoirePatrimoine]?.patrimoine
            } as SocieteTerritoirePatrimoineEntityState;
            return entities;
          }, {} as Dictionary<SocieteTerritoirePatrimoineEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteTerritoirePatrimoineState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): SocieteTerritoirePatrimoineState.IState {
  return { ...state, isLoaded, isLoading };
}
