import { RepositoryService } from '@get/services/repository';
import { OrganisationAzureLogin } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganisationAzureLoginApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganisationAzureLogins(params?: any): Observable<OrganisationAzureLogin[]> {
    return this.repo.getData<OrganisationAzureLogin[]>('organisation-azure-login', params);
  }

  public getOrganisationAzureLogin(params: { idOrganisationAzureLogin: number; params?: any }): Observable<OrganisationAzureLogin> {
    return this.repo.getData<OrganisationAzureLogin>('organisation-azure-login/' + params.idOrganisationAzureLogin, params.params);
  }

  public addOrganisationAzureLogin(organisationAzureLogin: Partial<OrganisationAzureLogin>): Observable<OrganisationAzureLogin> {
    return this.repo.create<OrganisationAzureLogin>('organisation-azure-login', organisationAzureLogin);
  }

  public updateOrganisationAzureLogin(organisationAzureLogin: Partial<OrganisationAzureLogin>): Observable<OrganisationAzureLogin> {
    return this.repo.update('organisation-azure-login', organisationAzureLogin);
  }

  public deleteOrganisationAzureLogin(idOrganisationAzureLogin: number): Observable<number> {
    return this.repo.delete('organisation-azure-login/' + +idOrganisationAzureLogin);
  }
}

