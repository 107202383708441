import { RepositoryService } from '@get/services/repository';
import { CampagnePatrimoine } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCampagnePatrimoineApiService {
  constructor(protected repo: RepositoryService) {}

  public getCampagnePatrimoines(params?: any): Observable<CampagnePatrimoine[]> {
    return this.repo.getData<CampagnePatrimoine[]>('campagne-patrimoine', params);
  }

  public getCampagnePatrimoine(params: { idCampagnePatrimoine: number; params?: any }): Observable<CampagnePatrimoine> {
    return this.repo.getData<CampagnePatrimoine>('campagne-patrimoine/' + params.idCampagnePatrimoine, params.params);
  }

  public addCampagnePatrimoine(campagnePatrimoine: Partial<CampagnePatrimoine>): Observable<CampagnePatrimoine> {
    return this.repo.create<CampagnePatrimoine>('campagne-patrimoine', campagnePatrimoine);
  }

  public updateCampagnePatrimoine(campagnePatrimoine: Partial<CampagnePatrimoine>): Observable<CampagnePatrimoine> {
    return this.repo.update('campagne-patrimoine', campagnePatrimoine);
  }

  public deleteCampagnePatrimoine(idCampagnePatrimoine: number): Observable<number> {
    return this.repo.delete('campagne-patrimoine/' + +idCampagnePatrimoine);
  }
}

