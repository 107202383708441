import { Injectable } from '@angular/core';
import { StoreActionType } from '@enums';
import {
  Composant,
  ComposantAttendu,
  DynamicType,
  Espace,
  Patrimoine,
  SocieteComposant,
  SocieteComposantFamille
} from '@get/api-interfaces';
import { ComposantDocument, MyDatabaseCollections } from '@get/interfaces';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { PatrimoineApiService } from '@get/store/api-services';
import { getMultiAction } from '@get/store/configs/batched-actions';
import {
  getActionsToNormalizeComposant,
  getActionsToNormalizeComposantAttendu,
  getActionsToNormalizePatrimoine
} from '@get/store/configs/normalization';
import { AppState } from '@get/store/configs/reducers';
import {
  filterOnlyUsefulComposants,
  findHighestAncestorWithDescendants,
  mapPatrimoinesWithUsefulInfos
} from '@get/utils';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { compareNumberCustomWithNegatives, transformArrayToObject } from '@utils';
import fastDeepEqual from 'fast-deep-equal';
import { ReplaySubject, combineLatest, distinctUntilChanged, first, map, startWith, tap } from 'rxjs';
import { v4 as uuidGenerator } from 'uuid';
import { DbService } from './db.service';

@Injectable({ providedIn: 'root' })
export class PatrimoineDbService {
  constructor(
    private store$: Store<AppState>,
    private patrimoineApiService: PatrimoineApiService,
    private dbService: DbService
  ) {}

  public async getHighestPatrimoineForIdPatrimoine(
    idPatrimoine: number,
    databaseCollections?: MyDatabaseCollections
  ): Promise<Patrimoine[]> {
    if (!databaseCollections) {
      databaseCollections = await this.dbService.getDatabaseCollection();
    }
    const patrimoinesDocuments = await databaseCollections.patrimoines.find().exec();
    const patrimoines = patrimoinesDocuments
      .map(el => el.getLatest().toJSON() as unknown as Patrimoine)
      .map(el => ({ ...el, idPatrimoine: +el.idPatrimoine }));
    const patrimoinesObj = transformArrayToObject(patrimoines, { key: 'idPatrimoine' });
    return findHighestAncestorWithDescendants(patrimoinesObj, idPatrimoine);
  }

  private getComposantsFormattedFromPatrimoines(patrimoines: Patrimoine[]): Composant[] {
    return (
      patrimoines?.map(
        patrimoine =>
          patrimoine.espaces?.map(
            espace =>
              espace.composants.map(composant => ({
                ...composant,
                uuid: composant.uuid ?? uuidGenerator(),
                espace: { idEspace: espace.idEspace } as Espace
              })) || []
          ) || []
      ) || []
    ).flat(3);
  }

  private formatPatrimoines(patrimoines: Patrimoine[]): Patrimoine[] {
    return (
      patrimoines?.map(patrimoine => ({
        ...patrimoine,
        composantAttendus:
          patrimoine.composantAttendus?.map(
            composantAttendu =>
              ({
                idComposantAttendu: composantAttendu.idComposantAttendu
              } as ComposantAttendu)
          ) || [],
        espaces:
          patrimoine.espaces?.map(espace => {
            const { composants, ...esp } = espace;
            return esp as Espace;
          }) || []
      })) || []
    );
  }

  private formatComposantAttendus(patrimoines: Patrimoine[]): ComposantAttendu[] {
    return (
      patrimoines
        ?.map(patrimoine =>
          patrimoine?.composantAttendus?.map(composantAttendu => ({
            ...composantAttendu,
            patrimoine: {
              idPatrimoine: patrimoine.idPatrimoine
            } as Patrimoine
          }))
        )
        .flat() || []
    );
  }

  private async updateIndexedDb<T>(
    databaseCollections: MyDatabaseCollections,
    tableName: keyof MyDatabaseCollections,
    elements: T[],
    offlinePrimaryKey: keyof T,
    condition?: (el: any) => boolean
  ): Promise<void> {
    // Get previous data in indexedDb to filter and remove elements we are not expecting to be stored
    const previousElements =
      (await databaseCollections[tableName].find().exec())?.map(el => el.getLatest().toJSON()) || [];
    const newArrayObj = elements.reduce((acc, curr) => {
      acc[curr[offlinePrimaryKey] as number] = true;
      return acc;
    }, {} as DynamicType<boolean>);
    // Removing only elements that are not useful but matching condition (synced for exemple)
    const previousElementsToRemove = (previousElements as any)
      .filter((el: any) => !newArrayObj[el[offlinePrimaryKey]] && (!condition || condition(el)))
      .map((el: any) => el[offlinePrimaryKey]);
    if (previousElementsToRemove?.length) {
      await databaseCollections[tableName].bulkRemove(previousElementsToRemove);
    }

    // Filter elements to prevent overriding not synced composants
    const previousElementsObj = transformArrayToObject(previousElements, { key: offlinePrimaryKey as string });
    const elementsToUpsert = !condition
      ? elements
      : elements.filter(el => condition(previousElementsObj[el[offlinePrimaryKey]]));

    // Update table in indexedDb
    await databaseCollections[tableName].bulkUpsert(
      elementsToUpsert.map(el => ({
        ...el,
        [offlinePrimaryKey]: el?.[offlinePrimaryKey]?.toString()
      }))
    );
  }

  public fetchCompletePatrimoineAndUpsertDb(
    idPatrimoine: number,
    completedRoute$?: ReplaySubject<void>,
    composantOrFamille?: SocieteComposant | SocieteComposantFamille
  ): void {
    this.patrimoineApiService
      .getOnePatrimoineComplete({ idPatrimoine })
      .pipe(
        first(),
        tap(async elements => {
          if (elements?.length) {
            const composants = this.getComposantsFormattedFromPatrimoines(elements);
            const composantAttendus = this.formatComposantAttendus(elements);
            const patrimoines = this.formatPatrimoines(elements);
            const composantsForStore = filterOnlyUsefulComposants(composants, composantOrFamille);
            const patrimoinesForStore = mapPatrimoinesWithUsefulInfos(patrimoines, idPatrimoine, [
              'valeurs',
              'valeurPatrimoines'
            ]) as Patrimoine[];

            // TODO: MultiAction
            this.store$.dispatch(
              getMultiAction(
                [
                  ...getActionsToNormalizePatrimoine(patrimoinesForStore, StoreActionType.upsert),
                  ...getActionsToNormalizeComposant(composantsForStore, StoreActionType.upsert),
                  ...getActionsToNormalizeComposantAttendu(composantAttendus, StoreActionType.upsert)
                ],
                '[Complete] - Normalization'
              )
            );

            const databaseCollections = await this.dbService.getDatabaseCollection();
            const composantsDocuments = await databaseCollections.composants.find().exec();
            const composantsJson = composantsDocuments.map(el => el.getLatest().toMutableJSON());
            const composantsObj: DynamicType<ComposantDocument> = transformArrayToObject(composantsJson, {
              key: 'idComposant'
            });
            for (let i = 0; i < composants?.length; i++) {
              if (composantsObj[composants[i].idComposant]?.uuid) {
                composants[i].uuid = composantsObj[composants[i].idComposant]?.uuid;
              }
            }

            await Promise.all([
              this.updateIndexedDb(
                databaseCollections,
                'composant-attendus',
                composantAttendus,
                'idComposantAttendu',
                el => el?.isSynced !== false
              ),
              this.updateIndexedDb(databaseCollections, 'composants', composants, 'uuid', el => el?.isSynced !== false),
              this.updateIndexedDb(databaseCollections, 'patrimoines', patrimoines, 'idPatrimoine')
            ]);
            completedRoute$?.next();
          }
        })
      )
      .subscribe();
  }

  // ====================================================== //
  // ==================== REFILL STORE ==================== //
  // ====================================================== //
  public handleIndexedDbRefillingStoragePatrimoines<U>(params: {
    databaseCollections: MyDatabaseCollections;
    idPatrimoine?: number;
    component: U;
    forceSubjects$?: ReplaySubject<void>[];
  }) {
    return combineLatest([
      params.databaseCollections['patrimoines'].find().$.pipe(
        startWith([]),
        map(values => values?.map(el => el.getLatest().toMutableJSON())),
        map(values => values.sort((a, b) => compareNumberCustomWithNegatives(+a.idPatrimoine, +b.idPatrimoine))),
        distinctUntilChanged((prev, curr) => fastDeepEqual(prev, curr))
      ),
      ...(params.forceSubjects$ || [])
    ]).pipe(
      untilDestroyed(params.component),
      tap(([currentValues]) => {
        if (currentValues?.length) {
          const patrimoinesForStore = mapPatrimoinesWithUsefulInfos(currentValues, params.idPatrimoine, [
            'valeurs',
            'valeurPatrimoines'
          ]);
          this.store$.dispatch(
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({
              patrimoines: patrimoinesForStore.map(
                el =>
                  ({
                    ...el,
                    idPatrimoine: +el.idPatrimoine
                  } as Patrimoine)
              )
            })
          );
        }
      })
    );
  }
}
