import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ValeurFichier, ValeurFichierEntityState } from '@get/api-interfaces';
import { ValeurFichierApiService } from '@get/store/api-services';
import { ValeurFichierGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeValeurFichier } from '@get/store/configs/normalization';
import { ValeurFichierSelectors } from '@get/store/selectors';
import { ValeurFichierRelationsIds } from '@get/store/ids-interfaces';
import { ValeurGeneratedActions } from '@get/store/actions';
import { FichierGeneratedActions } from '@get/store/actions';

export function getDefaultAddValeurFichierActions(valeurFichier: ValeurFichierEntityState, ids?: ValeurFichierRelationsIds): Action[] {
  const actions: Action[] = [ValeurFichierGeneratedActions.normalizeManyValeurFichiersAfterUpsert({ valeurFichiers: [valeurFichier] })];

  if (ids?.valeur) {
    actions.push(
      ValeurGeneratedActions.addManyValeurFichierSuccess({
        idValeur: ids.valeur,
        idValeurFichiers: [valeurFichier.idValeurFichier]
      })
    );
    actions.push(
      ValeurFichierGeneratedActions.addValeurSuccess({
        idValeurFichier: valeurFichier.idValeurFichier,
        idValeur: ids.valeur
      })
    );
  }

  if (ids?.fichier) {
    actions.push(
      FichierGeneratedActions.addManyValeurFichierSuccess({
        idFichier: ids.fichier,
        idValeurFichiers: [valeurFichier.idValeurFichier]
      })
    );
    actions.push(
      ValeurFichierGeneratedActions.addFichierSuccess({
        idValeurFichier: valeurFichier.idValeurFichier,
        idFichier: ids.fichier
      })
    );
  }

  return actions;
}

export function getDefaultDeleteValeurFichierActions(valeurFichier: ValeurFichierEntityState): Action[] {
  const actions: Action[] = [ValeurFichierGeneratedActions.deleteOneValeurFichierSuccess({ idValeurFichier: valeurFichier.idValeurFichier })];

  if (valeurFichier.valeur) {
    actions.push(
      ValeurGeneratedActions.deleteManyValeurFichierSuccess({
        idValeurFichiers: [valeurFichier.idValeurFichier],
        idValeurs: [valeurFichier.valeur as number]
      })
    );
  }

  if (valeurFichier.fichier) {
    actions.push(
      FichierGeneratedActions.deleteManyValeurFichierSuccess({
        idValeurFichiers: [valeurFichier.idValeurFichier],
        idFichiers: [valeurFichier.fichier as number]
      })
    );
  }

  return actions;
}

export class GeneratedValeurFichierEffects {
  constructor(
    protected actions$: Actions,
    protected valeurFichierApiService: ValeurFichierApiService,
    protected store$: Store<AppState>
  ) {}

  getManyValeurFichiers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurFichierGeneratedActions.getManyValeurFichiers),
      switchMap(({ params }) =>
        this.valeurFichierApiService.getValeurFichiers(params).pipe(
          map((valeurFichiers: ValeurFichier[]) => {
            return ValeurFichierGeneratedActions.normalizeManyValeurFichiersAfterUpsert({ valeurFichiers });
          }),
          catchError(error => of(ValeurFichierGeneratedActions.valeurFichiersFailure({ error })))
        )
      )
    );
  });

  getOneValeurFichier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurFichierGeneratedActions.getOneValeurFichier),
      switchMap(idValeurFichier =>
        this.valeurFichierApiService.getValeurFichier(idValeurFichier).pipe(
          map((valeurFichier: ValeurFichier) => {
            return ValeurFichierGeneratedActions.normalizeManyValeurFichiersAfterUpsert({ valeurFichiers: [valeurFichier] });
          }),
          catchError(error => of(ValeurFichierGeneratedActions.valeurFichiersFailure({ error })))
        )
      )
    );
  });

  upsertOneValeurFichier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurFichierGeneratedActions.upsertOneValeurFichier),
      concatMap(
        ({
          valeurFichier,
          ids
        }: {
          valeurFichier: Partial<ValeurFichier>;
          ids?: ValeurFichierRelationsIds;
        }) => {
          if (valeurFichier.idValeurFichier) {
            return this.valeurFichierApiService.updateValeurFichier(valeurFichier).pipe(
              map((valeurFichierReturned: ValeurFichier) => {
                return ValeurFichierGeneratedActions.normalizeManyValeurFichiersAfterUpsert({ valeurFichiers: [valeurFichierReturned] });
              }),
              catchError(error => of(ValeurFichierGeneratedActions.valeurFichiersFailure({ error })))
            );
          } else {
            return this.valeurFichierApiService.addValeurFichier(valeurFichier).pipe(
              mergeMap((valeurFichierReturned: ValeurFichier) => getDefaultAddValeurFichierActions(valeurFichierReturned, ids)),
              catchError(error => of(ValeurFichierGeneratedActions.valeurFichiersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneValeurFichier$ = createEffect(() => {
    const selectValeurFichierState$ = this.store$.select(ValeurFichierSelectors.selectValeurFichierState);
    return this.actions$.pipe(
      ofType(ValeurFichierGeneratedActions.deleteOneValeurFichier),
      withLatestFrom(selectValeurFichierState$),
      concatMap(([{ idValeurFichier }, state]) =>
        this.valeurFichierApiService.deleteValeurFichier(idValeurFichier).pipe(
          mergeMap(_success => getDefaultDeleteValeurFichierActions(state.entities[idValeurFichier] as ValeurFichierEntityState)),
          catchError(error => of(ValeurFichierGeneratedActions.valeurFichiersFailure({ error })))
        )
      )
    );
  });

  normalizeManyValeurFichiersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValeurFichierGeneratedActions.normalizeManyValeurFichiersAfterUpsert),
      concatMap(({ valeurFichiers }) => {
        const actions: Action[] = getActionsToNormalizeValeurFichier(valeurFichiers, StoreActionType.upsert);
        return [getMultiAction(actions, '[ValeurFichier] Normalization After Upsert Success')];
      })
    );
  });
}
