import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { OrganisationAzureLogin, OrganisationAzureLoginEntityState } from '@get/api-interfaces';
import { OrganisationAzureLoginApiService } from '@get/store/api-services';
import { OrganisationAzureLoginGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeOrganisationAzureLogin } from '@get/store/configs/normalization';
import { OrganisationAzureLoginSelectors } from '@get/store/selectors';
import { OrganisationAzureLoginRelationsIds } from '@get/store/ids-interfaces';
import { OrganisationGeneratedActions } from '@get/store/actions';

export function getDefaultAddOrganisationAzureLoginActions(organisationAzureLogin: OrganisationAzureLoginEntityState, ids?: OrganisationAzureLoginRelationsIds): Action[] {
  const actions: Action[] = [OrganisationAzureLoginGeneratedActions.normalizeManyOrganisationAzureLoginsAfterUpsert({ organisationAzureLogins: [organisationAzureLogin] })];

  if (ids?.organisation) {
    actions.push(
      OrganisationGeneratedActions.addManyOrganisationAzureLoginSuccess({
        idOrganisation: ids.organisation,
        idOrganisationAzureLogins: [organisationAzureLogin.idOrganisationAzureLogin]
      })
    );
    actions.push(
      OrganisationAzureLoginGeneratedActions.addOrganisationSuccess({
        idOrganisationAzureLogin: organisationAzureLogin.idOrganisationAzureLogin,
        idOrganisation: ids.organisation
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganisationAzureLoginActions(organisationAzureLogin: OrganisationAzureLoginEntityState): Action[] {
  const actions: Action[] = [OrganisationAzureLoginGeneratedActions.deleteOneOrganisationAzureLoginSuccess({ idOrganisationAzureLogin: organisationAzureLogin.idOrganisationAzureLogin })];

  if (organisationAzureLogin.organisation) {
    actions.push(
      OrganisationGeneratedActions.deleteManyOrganisationAzureLoginSuccess({
        idOrganisationAzureLogins: [organisationAzureLogin.idOrganisationAzureLogin],
        idOrganisations: [organisationAzureLogin.organisation as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganisationAzureLoginEffects {
  constructor(
    protected actions$: Actions,
    protected organisationAzureLoginApiService: OrganisationAzureLoginApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganisationAzureLogins$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAzureLoginGeneratedActions.getManyOrganisationAzureLogins),
      switchMap(({ params }) =>
        this.organisationAzureLoginApiService.getOrganisationAzureLogins(params).pipe(
          map((organisationAzureLogins: OrganisationAzureLogin[]) => {
            return OrganisationAzureLoginGeneratedActions.normalizeManyOrganisationAzureLoginsAfterUpsert({ organisationAzureLogins });
          }),
          catchError(error => of(OrganisationAzureLoginGeneratedActions.organisationAzureLoginsFailure({ error })))
        )
      )
    );
  });

  getOneOrganisationAzureLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAzureLoginGeneratedActions.getOneOrganisationAzureLogin),
      switchMap(idOrganisationAzureLogin =>
        this.organisationAzureLoginApiService.getOrganisationAzureLogin(idOrganisationAzureLogin).pipe(
          map((organisationAzureLogin: OrganisationAzureLogin) => {
            return OrganisationAzureLoginGeneratedActions.normalizeManyOrganisationAzureLoginsAfterUpsert({ organisationAzureLogins: [organisationAzureLogin] });
          }),
          catchError(error => of(OrganisationAzureLoginGeneratedActions.organisationAzureLoginsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganisationAzureLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAzureLoginGeneratedActions.upsertOneOrganisationAzureLogin),
      concatMap(
        ({
          organisationAzureLogin,
          ids
        }: {
          organisationAzureLogin: Partial<OrganisationAzureLogin>;
          ids?: OrganisationAzureLoginRelationsIds;
        }) => {
          if (organisationAzureLogin.idOrganisationAzureLogin) {
            return this.organisationAzureLoginApiService.updateOrganisationAzureLogin(organisationAzureLogin).pipe(
              map((organisationAzureLoginReturned: OrganisationAzureLogin) => {
                return OrganisationAzureLoginGeneratedActions.normalizeManyOrganisationAzureLoginsAfterUpsert({ organisationAzureLogins: [organisationAzureLoginReturned] });
              }),
              catchError(error => of(OrganisationAzureLoginGeneratedActions.organisationAzureLoginsFailure({ error })))
            );
          } else {
            return this.organisationAzureLoginApiService.addOrganisationAzureLogin(organisationAzureLogin).pipe(
              mergeMap((organisationAzureLoginReturned: OrganisationAzureLogin) => getDefaultAddOrganisationAzureLoginActions(organisationAzureLoginReturned, ids)),
              catchError(error => of(OrganisationAzureLoginGeneratedActions.organisationAzureLoginsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganisationAzureLogin$ = createEffect(() => {
    const selectOrganisationAzureLoginState$ = this.store$.select(OrganisationAzureLoginSelectors.selectOrganisationAzureLoginState);
    return this.actions$.pipe(
      ofType(OrganisationAzureLoginGeneratedActions.deleteOneOrganisationAzureLogin),
      withLatestFrom(selectOrganisationAzureLoginState$),
      concatMap(([{ idOrganisationAzureLogin }, state]) =>
        this.organisationAzureLoginApiService.deleteOrganisationAzureLogin(idOrganisationAzureLogin).pipe(
          mergeMap(_success => getDefaultDeleteOrganisationAzureLoginActions(state.entities[idOrganisationAzureLogin] as OrganisationAzureLoginEntityState)),
          catchError(error => of(OrganisationAzureLoginGeneratedActions.organisationAzureLoginsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganisationAzureLoginsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationAzureLoginGeneratedActions.normalizeManyOrganisationAzureLoginsAfterUpsert),
      concatMap(({ organisationAzureLogins }) => {
        const actions: Action[] = getActionsToNormalizeOrganisationAzureLogin(organisationAzureLogins, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganisationAzureLogin] Normalization After Upsert Success')];
      })
    );
  });
}
