import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteTerritoirePatrimoine, SocieteTerritoirePatrimoineEntityState } from '@get/api-interfaces';
import { SocieteTerritoire, SocieteTerritoireEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteTerritoirePatrimoineState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeTerritoirePatrimoineRelations: string[] = ['societeTerritoires','patrimoines',];

export const { selectEntities, selectAll } = SocieteTerritoirePatrimoineState.adapter.getSelectors();

export const selectSocieteTerritoirePatrimoineState = createFeatureSelector<SocieteTerritoirePatrimoineState.IState>(SocieteTerritoirePatrimoineState.societeTerritoirePatrimoineFeatureKey);

export const selectIsLoadedSocieteTerritoirePatrimoine = createSelector(
  selectSocieteTerritoirePatrimoineState,
  (state: SocieteTerritoirePatrimoineState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteTerritoirePatrimoine = createSelector(
  selectSocieteTerritoirePatrimoineState,
  (state: SocieteTerritoirePatrimoineState.IState) => state.isLoading
);

export const selectIsReadySocieteTerritoirePatrimoine = createSelector(
  selectSocieteTerritoirePatrimoineState,
  (state: SocieteTerritoirePatrimoineState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteTerritoirePatrimoine = createSelector(
  selectSocieteTerritoirePatrimoineState,
  (state: SocieteTerritoirePatrimoineState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteTerritoirePatrimoinesEntities = createSelector(selectSocieteTerritoirePatrimoineState, selectEntities);

export const selectSocieteTerritoirePatrimoinesArray = createSelector(selectSocieteTerritoirePatrimoineState, selectAll);

export const selectIdSocieteTerritoirePatrimoinesActive = createSelector(
  selectSocieteTerritoirePatrimoineState,
  (state: SocieteTerritoirePatrimoineState.IState) => state.actives
);

const societeTerritoirePatrimoinesInObject = (societeTerritoirePatrimoines: Dictionary<SocieteTerritoirePatrimoineEntityState>) => ({ societeTerritoirePatrimoines })

const selectSocieteTerritoirePatrimoinesEntitiesDictionary = createSelector(selectSocieteTerritoirePatrimoinesEntities, societeTerritoirePatrimoinesInObject);

const selectAllSocieteTerritoirePatrimoinesObject = createSelector(selectSocieteTerritoirePatrimoinesEntities, societeTerritoirePatrimoines => {
  return hydrateAll({ societeTerritoirePatrimoines });
});

const selectOneSocieteTerritoirePatrimoineDictionary = (idSocieteTerritoirePatrimoine : number) =>
  createSelector(selectSocieteTerritoirePatrimoinesEntities, societeTerritoirePatrimoines => ({
    societeTerritoirePatrimoines: { [idSocieteTerritoirePatrimoine]: societeTerritoirePatrimoines[idSocieteTerritoirePatrimoine] }
  }));

const selectOneSocieteTerritoirePatrimoineDictionaryWithoutChild = (idSocieteTerritoirePatrimoine : number) =>
  createSelector(selectSocieteTerritoirePatrimoinesEntities, societeTerritoirePatrimoines => ({
    societeTerritoirePatrimoine: societeTerritoirePatrimoines[idSocieteTerritoirePatrimoine]
  }));

const selectActiveSocieteTerritoirePatrimoinesEntities = createSelector(
  selectIdSocieteTerritoirePatrimoinesActive,
  selectSocieteTerritoirePatrimoinesEntities,
  (actives: number[], societeTerritoirePatrimoines: Dictionary<SocieteTerritoirePatrimoineEntityState>) => getSocieteTerritoirePatrimoinesFromActives(actives, societeTerritoirePatrimoines)
);

function getSocieteTerritoirePatrimoinesFromActives(
  actives: number[],
  societeTerritoirePatrimoines: Dictionary<SocieteTerritoirePatrimoineEntityState>
): Dictionary<SocieteTerritoirePatrimoineEntityState> {
  return actives.reduce((acc, idActive) => {
    if (societeTerritoirePatrimoines[idActive]) {
      acc[idActive] = societeTerritoirePatrimoines[idActive];
    }
    return acc;
  }, {} as Dictionary<SocieteTerritoirePatrimoineEntityState>);
}

const selectAllSocieteTerritoirePatrimoinesSelectors: Dictionary<Selector> = {};
export function selectAllSocieteTerritoirePatrimoines(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteTerritoirePatrimoine>(
      schema,
      selectAllSocieteTerritoirePatrimoinesSelectors,
      selectSocieteTerritoirePatrimoinesEntitiesDictionary,
      getRelationSelectors,
      societeTerritoirePatrimoineRelations,
      hydrateAll,
      'societeTerritoirePatrimoine'
    );
  } else {
    return selectAllSocieteTerritoirePatrimoinesObject;
  }
}

export function selectAllSocieteTerritoirePatrimoinesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'societeTerritoirePatrimoines'
): Selector {
  return createSelector(selectAllSocieteTerritoirePatrimoines(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteTerritoirePatrimoineEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeTerritoirePatrimoines.length; i++) {
      res[customKey][result.societeTerritoirePatrimoines[i].idSocieteTerritoirePatrimoine] = result.societeTerritoirePatrimoines[i];
    }
    return res;
  });
}

export function selectOneSocieteTerritoirePatrimoine(
  schema: SelectSchema = {},
  idSocieteTerritoirePatrimoine: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteTerritoirePatrimoineDictionary(idSocieteTerritoirePatrimoine)];
  selectors.push(...getRelationSelectors(schema, societeTerritoirePatrimoineRelations, 'societeTerritoirePatrimoine'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteTerritoirePatrimoineDictionaryWithoutChild(idSocieteTerritoirePatrimoine);
  }
}

export function selectActiveSocieteTerritoirePatrimoines(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveSocieteTerritoirePatrimoinesEntities, societeTerritoirePatrimoines => ({
      societeTerritoirePatrimoines
    }))
  ];
  selectors.push(...getRelationSelectors(schema, societeTerritoirePatrimoineRelations, 'societeTerritoirePatrimoine'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  societeTerritoirePatrimoines: Dictionary<SocieteTerritoirePatrimoineEntityState>;
  societeTerritoires?: Dictionary<SocieteTerritoireEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeTerritoirePatrimoines: (SocieteTerritoirePatrimoine | null)[] } {
  const {
    societeTerritoirePatrimoines,
    societeTerritoires,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeTerritoirePatrimoines: Object.keys(societeTerritoirePatrimoines).map(idSocieteTerritoirePatrimoine =>
      hydrate(
        societeTerritoirePatrimoines[idSocieteTerritoirePatrimoine] as SocieteTerritoirePatrimoineEntityState,
        societeTerritoires,
        patrimoines
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeTerritoirePatrimoine: SocieteTerritoirePatrimoineEntityState | null } {
  const {
    societeTerritoirePatrimoines,
    societeTerritoires,
    patrimoines
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeTerritoirePatrimoine = Object.values(societeTerritoirePatrimoines)[0];
  return {
    societeTerritoirePatrimoine: hydrate(
      societeTerritoirePatrimoine as SocieteTerritoirePatrimoineEntityState,
      societeTerritoires,
      patrimoines
    )
  };
}

function hydrate(
  societeTerritoirePatrimoine: SocieteTerritoirePatrimoineEntityState,
  societeTerritoireEntities?: Dictionary<SocieteTerritoireEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
): SocieteTerritoirePatrimoine | null {
  if (!societeTerritoirePatrimoine) {
    return null;
  }

  const societeTerritoirePatrimoineHydrated: SocieteTerritoirePatrimoineEntityState = { ...societeTerritoirePatrimoine };
  if (societeTerritoireEntities) {
    societeTerritoirePatrimoineHydrated.societeTerritoire = societeTerritoireEntities[societeTerritoirePatrimoine.societeTerritoire as number] as SocieteTerritoire;
  } else {
    delete societeTerritoirePatrimoineHydrated.societeTerritoire;
  }
  if (patrimoineEntities) {
    societeTerritoirePatrimoineHydrated.patrimoine = patrimoineEntities[societeTerritoirePatrimoine.patrimoine as number] as Patrimoine;
  } else {
    delete societeTerritoirePatrimoineHydrated.patrimoine;
  }

  return societeTerritoirePatrimoineHydrated as SocieteTerritoirePatrimoine;
}
