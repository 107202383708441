import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Droit, DroitEntityState } from '@get/api-interfaces';
import { DroitApiService } from '@get/store/api-services';
import { DroitGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeDroit } from '@get/store/configs/normalization';
import { DroitSelectors } from '@get/store/selectors';
import { DroitRelationsIds } from '@get/store/ids-interfaces';
import { SocieteProfilDroitGeneratedActions } from '@get/store/actions';
import { SocieteProfilDroit } from '@get/api-interfaces';
import { SocieteProfilGeneratedActions } from '@get/store/actions';
import { SocieteProfil } from '@get/api-interfaces';

export function getDefaultAddDroitActions(droit: DroitEntityState, ids?: DroitRelationsIds): Action[] {
  const actions: Action[] = [DroitGeneratedActions.normalizeManyDroitsAfterUpsert({ droits: [droit] })];

  if (ids?.societeProfilDroits) {
    if (!Array.isArray(ids.societeProfilDroits)) {
      actions.push(
        SocieteProfilDroitGeneratedActions.upsertOneSocieteProfilDroit({
          societeProfilDroit: {
            idDroit: droit.idDroit,
            idSocieteProfilDroit: ids.societeProfilDroits as number
          } as SocieteProfilDroit
        })
      );
      actions.push(
        DroitGeneratedActions.addManySocieteProfilDroitSuccess({
          idDroit: droit.idDroit,
          idSocieteProfilDroits: [ids.societeProfilDroits as number]
        })
      );
    } else {
      actions.push(
        SocieteProfilDroitGeneratedActions.upsertManySocieteProfilDroits({
          societeProfilDroits: (ids.societeProfilDroits as number[]).map(
            (idSocieteProfilDroit: number) => ({
              idDroit: droit.idDroit,
              idSocieteProfilDroit
            })
          ) as SocieteProfilDroit[]
        })
      );
      actions.push(
        DroitGeneratedActions.addManySocieteProfilDroitSuccess({
          idDroit: droit.idDroit,
          idSocieteProfilDroits: ids.societeProfilDroits as number[]
        })
      );
    }
  }

  if (ids?.societeProfils) {
    if (!Array.isArray(ids.societeProfils)) {
      actions.push(
        SocieteProfilGeneratedActions.upsertOneSocieteProfil({
          societeProfil: {
            idDroit: droit.idDroit,
            idSocieteProfil: ids.societeProfils as number
          } as SocieteProfil & any
        })
      );
      actions.push(
        DroitGeneratedActions.addManySocieteProfilSuccess({
          idDroit: droit.idDroit,
          idSocieteProfils: [ids.societeProfils as number]
        })
      );
    } else {
      actions.push(
        SocieteProfilGeneratedActions.upsertManySocieteProfils({
          societeProfils: (ids.societeProfils as number[]).map(
            (idSocieteProfil: number) => ({
              idDroit: droit.idDroit,
              idSocieteProfil
            })
          ) as SocieteProfil[] & any[]
        })
      );
      actions.push(
        DroitGeneratedActions.addManySocieteProfilSuccess({
          idDroit: droit.idDroit,
          idSocieteProfils: ids.societeProfils as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteDroitActions(droit: DroitEntityState): Action[] {
  const actions: Action[] = [DroitGeneratedActions.deleteOneDroitSuccess({ idDroit: droit.idDroit })];

  if (droit.societeProfilDroits) {
    actions.push(
      SocieteProfilDroitGeneratedActions.deleteManyDroitSuccess({
        idDroits: [droit.idDroit],
        idSocieteProfilDroits: droit.societeProfilDroits as number[]
      })
    );
  }

  if (droit.societeProfils) {
    actions.push(
      SocieteProfilGeneratedActions.deleteManyDroitSuccess({
        idDroits: [droit.idDroit],
        idSocieteProfils: droit.societeProfils as number[]
      })
    );
  }

  return actions;
}

export class GeneratedDroitEffects {
  constructor(
    protected actions$: Actions,
    protected droitApiService: DroitApiService,
    protected store$: Store<AppState>
  ) {}

  getManyDroits$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DroitGeneratedActions.getManyDroits),
      switchMap(({ params }) =>
        this.droitApiService.getDroits(params).pipe(
          map((droits: Droit[]) => {
            return DroitGeneratedActions.normalizeManyDroitsAfterUpsert({ droits });
          }),
          catchError(error => of(DroitGeneratedActions.droitsFailure({ error })))
        )
      )
    );
  });

  getOneDroit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DroitGeneratedActions.getOneDroit),
      switchMap(idDroit =>
        this.droitApiService.getDroit(idDroit).pipe(
          map((droit: Droit) => {
            return DroitGeneratedActions.normalizeManyDroitsAfterUpsert({ droits: [droit] });
          }),
          catchError(error => of(DroitGeneratedActions.droitsFailure({ error })))
        )
      )
    );
  });

  upsertOneDroit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DroitGeneratedActions.upsertOneDroit),
      concatMap(
        ({
          droit,
          ids
        }: {
          droit: Partial<Droit>;
          ids?: DroitRelationsIds;
        }) => {
          if (droit.idDroit) {
            return this.droitApiService.updateDroit(droit).pipe(
              map((droitReturned: Droit) => {
                return DroitGeneratedActions.normalizeManyDroitsAfterUpsert({ droits: [droitReturned] });
              }),
              catchError(error => of(DroitGeneratedActions.droitsFailure({ error })))
            );
          } else {
            return this.droitApiService.addDroit(droit).pipe(
              mergeMap((droitReturned: Droit) => getDefaultAddDroitActions(droitReturned, ids)),
              catchError(error => of(DroitGeneratedActions.droitsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneDroit$ = createEffect(() => {
    const selectDroitState$ = this.store$.select(DroitSelectors.selectDroitState);
    return this.actions$.pipe(
      ofType(DroitGeneratedActions.deleteOneDroit),
      withLatestFrom(selectDroitState$),
      concatMap(([{ idDroit }, state]) =>
        this.droitApiService.deleteDroit(idDroit).pipe(
          mergeMap(_success => getDefaultDeleteDroitActions(state.entities[idDroit] as DroitEntityState)),
          catchError(error => of(DroitGeneratedActions.droitsFailure({ error })))
        )
      )
    );
  });

  normalizeManyDroitsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DroitGeneratedActions.normalizeManyDroitsAfterUpsert),
      concatMap(({ droits }) => {
        const actions: Action[] = getActionsToNormalizeDroit(droits, StoreActionType.upsert);
        return [getMultiAction(actions, '[Droit] Normalization After Upsert Success')];
      })
    );
  });
}
