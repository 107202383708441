import { ActivatedRouteSnapshot } from '@angular/router';
import { ModulePathKeyEnum } from '@enums';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RouterUtils {
  export function pageUrlRegex(url: string): string {
    return `^${url}/[0-9]*`;
  }

  function getAppArrayUrl(path: ModulePathKeyEnum): string[] {
    if (!ModulePathKeyEnum[path]) {
      throw new Error(`${path} path inexistant`);
    }
    return ['app', path];
  }

  export function getAppUrl(path?: ModulePathKeyEnum | string): string {
    if (path) {
      if (path.includes('/')) {
        const startPath: ModulePathKeyEnum = path.split('/')[0] as ModulePathKeyEnum;
        return getAppArrayUrl(startPath).concat(path.split('/').slice(1)).join('/');
      }
      return getAppArrayUrl(path as ModulePathKeyEnum).join('/');
    }
    return 'app';
  }

  export function getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/');
  }

  export function getResolvedUrlWithParams(route: ActivatedRouteSnapshot, param: string): string {
    if (!param) {
      return getResolvedUrl(route);
    }
    return route.pathFromRoot
      .map(v =>
        v.url
          .map(segment => segment.path)
          .filter(segment => segment !== '' && segment !== param)
          .join('/')
      )
      .filter(segment => segment !== '' && segment !== param)
      .join('/');
  }
}
