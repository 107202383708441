import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteProfilDroit, SocieteProfilDroitEntityState } from '@get/api-interfaces';
import { SocieteProfilDroitRelationsIds } from '@get/store/ids-interfaces';

export const SocieteProfilDroitGeneratedActions = createActionGroup({
  source: 'Societe Profil Droit Generated',
  events: {
    'Get One Societe Profil Droit': props<{ idSocieteProfilDroit: number; params?: any }>(),
    'Get Many Societe Profil Droits': props<{ params: any }>(),
    'Add Many Actives Societe Profil Droits': props<{ idSocieteProfilDroits: number[] }>(),
    'Delete One Active Societe Profil Droit': props<{ idSocieteProfilDroit: number }>(),
    'Clear Actives Societe Profil Droits': emptyProps(),
    'Upsert One Societe Profil Droit': props<{ societeProfilDroit: Partial<SocieteProfilDroit>; ids?: SocieteProfilDroitRelationsIds; }>(),
    'Upsert Many Societe Profil Droits': props<{ societeProfilDroits: Partial<SocieteProfilDroit>[]; ids?: SocieteProfilDroitRelationsIds; }>(),
    'Upsert Many Societe Profil Droits Success': props<{ societeProfilDroits: SocieteProfilDroitEntityState[] }>(),
    'Delete One Societe Profil Droit': props<{ idSocieteProfilDroit: number }>(),
    'Delete One Societe Profil Droit Success': props<{ idSocieteProfilDroit: number }>(),
    'Normalize Many Societe Profil Droits After Upsert': props<{ societeProfilDroits: SocieteProfilDroitEntityState[] }>(),
    'Societe Profil Droits Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Profil Droits': emptyProps(),
    'Add Societe Profil Success': props<{ idSocieteProfilDroit: number; idSocieteProfil: number }>(),
    'Delete Many Societe Profil Success': props<{ idSocieteProfils: number[]; idSocieteProfilDroits: number[] }>(),
    'Add Droit Success': props<{ idSocieteProfilDroit: number; idDroit: number }>(),
    'Delete Many Droit Success': props<{ idDroits: number[]; idSocieteProfilDroits: number[] }>()
  }
});
