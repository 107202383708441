import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ComposantGroupe, ComposantGroupeEntityState } from '@get/api-interfaces';
import { ComposantGroupeApiService } from '@get/store/api-services';
import { ComposantGroupeGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeComposantGroupe } from '@get/store/configs/normalization';
import { ComposantGroupeSelectors } from '@get/store/selectors';
import { ComposantGroupeRelationsIds } from '@get/store/ids-interfaces';
import { FichierGeneratedActions } from '@get/store/actions';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocieteComposant } from '@get/api-interfaces';
import { ComposantTemplateGeneratedActions } from '@get/store/actions';
import { ComposantTemplate } from '@get/api-interfaces';

export function getDefaultAddComposantGroupeActions(composantGroupe: ComposantGroupeEntityState, ids?: ComposantGroupeRelationsIds): Action[] {
  const actions: Action[] = [ComposantGroupeGeneratedActions.normalizeManyComposantGroupesAfterUpsert({ composantGroupes: [composantGroupe] })];

  if (ids?.fichier) {
    actions.push(
      FichierGeneratedActions.addManyComposantGroupeSuccess({
        idFichier: ids.fichier,
        idComposantGroupes: [composantGroupe.idComposantGroupe]
      })
    );
    actions.push(
      ComposantGroupeGeneratedActions.addFichierSuccess({
        idComposantGroupe: composantGroupe.idComposantGroupe,
        idFichier: ids.fichier
      })
    );
  }

  if (ids?.societeComposants) {
    if (!Array.isArray(ids.societeComposants)) {
      actions.push(
        SocieteComposantGeneratedActions.upsertOneSocieteComposant({
          societeComposant: {
            idComposantGroupe: composantGroupe.idComposantGroupe,
            idSocieteComposant: ids.societeComposants as number
          } as SocieteComposant
        })
      );
      actions.push(
        ComposantGroupeGeneratedActions.addManySocieteComposantSuccess({
          idComposantGroupe: composantGroupe.idComposantGroupe,
          idSocieteComposants: [ids.societeComposants as number]
        })
      );
    } else {
      actions.push(
        SocieteComposantGeneratedActions.upsertManySocieteComposants({
          societeComposants: (ids.societeComposants as number[]).map(
            (idSocieteComposant: number) => ({
              idComposantGroupe: composantGroupe.idComposantGroupe,
              idSocieteComposant
            })
          ) as SocieteComposant[]
        })
      );
      actions.push(
        ComposantGroupeGeneratedActions.addManySocieteComposantSuccess({
          idComposantGroupe: composantGroupe.idComposantGroupe,
          idSocieteComposants: ids.societeComposants as number[]
        })
      );
    }
  }

  if (ids?.composantTemplates) {
    if (!Array.isArray(ids.composantTemplates)) {
      actions.push(
        ComposantTemplateGeneratedActions.upsertOneComposantTemplate({
          composantTemplate: {
            idComposantGroupe: composantGroupe.idComposantGroupe,
            idComposantTemplate: ids.composantTemplates as number
          } as ComposantTemplate
        })
      );
      actions.push(
        ComposantGroupeGeneratedActions.addManyComposantTemplateSuccess({
          idComposantGroupe: composantGroupe.idComposantGroupe,
          idComposantTemplates: [ids.composantTemplates as number]
        })
      );
    } else {
      actions.push(
        ComposantTemplateGeneratedActions.upsertManyComposantTemplates({
          composantTemplates: (ids.composantTemplates as number[]).map(
            (idComposantTemplate: number) => ({
              idComposantGroupe: composantGroupe.idComposantGroupe,
              idComposantTemplate
            })
          ) as ComposantTemplate[]
        })
      );
      actions.push(
        ComposantGroupeGeneratedActions.addManyComposantTemplateSuccess({
          idComposantGroupe: composantGroupe.idComposantGroupe,
          idComposantTemplates: ids.composantTemplates as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteComposantGroupeActions(composantGroupe: ComposantGroupeEntityState): Action[] {
  const actions: Action[] = [ComposantGroupeGeneratedActions.deleteOneComposantGroupeSuccess({ idComposantGroupe: composantGroupe.idComposantGroupe })];

  if (composantGroupe.fichier) {
    actions.push(
      FichierGeneratedActions.deleteManyComposantGroupeSuccess({
        idComposantGroupes: [composantGroupe.idComposantGroupe],
        idFichiers: [composantGroupe.fichier as number]
      })
    );
  }

  if (composantGroupe.societeComposants) {
    actions.push(
      SocieteComposantGeneratedActions.deleteManyComposantGroupeSuccess({
        idComposantGroupes: [composantGroupe.idComposantGroupe],
        idSocieteComposants: composantGroupe.societeComposants as number[]
      })
    );
  }

  if (composantGroupe.composantTemplates) {
    actions.push(
      ComposantTemplateGeneratedActions.deleteManyComposantGroupeSuccess({
        idComposantGroupes: [composantGroupe.idComposantGroupe],
        idComposantTemplates: composantGroupe.composantTemplates as number[]
      })
    );
  }

  return actions;
}

export class GeneratedComposantGroupeEffects {
  constructor(
    protected actions$: Actions,
    protected composantGroupeApiService: ComposantGroupeApiService,
    protected store$: Store<AppState>
  ) {}

  getManyComposantGroupes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantGroupeGeneratedActions.getManyComposantGroupes),
      switchMap(({ params }) =>
        this.composantGroupeApiService.getComposantGroupes(params).pipe(
          map((composantGroupes: ComposantGroupe[]) => {
            return ComposantGroupeGeneratedActions.normalizeManyComposantGroupesAfterUpsert({ composantGroupes });
          }),
          catchError(error => of(ComposantGroupeGeneratedActions.composantGroupesFailure({ error })))
        )
      )
    );
  });

  getOneComposantGroupe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantGroupeGeneratedActions.getOneComposantGroupe),
      switchMap(idComposantGroupe =>
        this.composantGroupeApiService.getComposantGroupe(idComposantGroupe).pipe(
          map((composantGroupe: ComposantGroupe) => {
            return ComposantGroupeGeneratedActions.normalizeManyComposantGroupesAfterUpsert({ composantGroupes: [composantGroupe] });
          }),
          catchError(error => of(ComposantGroupeGeneratedActions.composantGroupesFailure({ error })))
        )
      )
    );
  });

  upsertOneComposantGroupe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantGroupeGeneratedActions.upsertOneComposantGroupe),
      concatMap(
        ({
          composantGroupe,
          ids
        }: {
          composantGroupe: Partial<ComposantGroupe>;
          ids?: ComposantGroupeRelationsIds;
        }) => {
          if (composantGroupe.idComposantGroupe) {
            return this.composantGroupeApiService.updateComposantGroupe(composantGroupe).pipe(
              map((composantGroupeReturned: ComposantGroupe) => {
                return ComposantGroupeGeneratedActions.normalizeManyComposantGroupesAfterUpsert({ composantGroupes: [composantGroupeReturned] });
              }),
              catchError(error => of(ComposantGroupeGeneratedActions.composantGroupesFailure({ error })))
            );
          } else {
            return this.composantGroupeApiService.addComposantGroupe(composantGroupe).pipe(
              mergeMap((composantGroupeReturned: ComposantGroupe) => getDefaultAddComposantGroupeActions(composantGroupeReturned, ids)),
              catchError(error => of(ComposantGroupeGeneratedActions.composantGroupesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneComposantGroupe$ = createEffect(() => {
    const selectComposantGroupeState$ = this.store$.select(ComposantGroupeSelectors.selectComposantGroupeState);
    return this.actions$.pipe(
      ofType(ComposantGroupeGeneratedActions.deleteOneComposantGroupe),
      withLatestFrom(selectComposantGroupeState$),
      concatMap(([{ idComposantGroupe }, state]) =>
        this.composantGroupeApiService.deleteComposantGroupe(idComposantGroupe).pipe(
          mergeMap(_success => getDefaultDeleteComposantGroupeActions(state.entities[idComposantGroupe] as ComposantGroupeEntityState)),
          catchError(error => of(ComposantGroupeGeneratedActions.composantGroupesFailure({ error })))
        )
      )
    );
  });

  normalizeManyComposantGroupesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantGroupeGeneratedActions.normalizeManyComposantGroupesAfterUpsert),
      concatMap(({ composantGroupes }) => {
        const actions: Action[] = getActionsToNormalizeComposantGroupe(composantGroupes, StoreActionType.upsert);
        return [getMultiAction(actions, '[ComposantGroupe] Normalization After Upsert Success')];
      })
    );
  });
}
