import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ComposantGeneratedActions } from '@get/store/actions';
import { ComposantState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ComposantEntityState } from '@get/api-interfaces';

export const composantReducersGeneratedFunctions: ReducerTypes<ComposantState.IState, readonly ActionCreator[]>[] = [
  on(ComposantGeneratedActions.getOneComposant, (state: ComposantState.IState) => setLoadingsState(state, true)),
  on(ComposantGeneratedActions.getManyComposants, (state: ComposantState.IState) => setLoadingsState(state, true)),
  on(ComposantGeneratedActions.upsertOneComposant, (state: ComposantState.IState) => setLoadingsState(state, true)),

  on(ComposantGeneratedActions.upsertManyComposantsSuccess, (state: ComposantState.IState, { composants }) =>
    ComposantState.adapter.upsertMany(composants, setLoadingsState(state, false))
  ),
  on(ComposantGeneratedActions.deleteOneComposant, (state: ComposantState.IState) => setLoadingsState(state, true)),
  on(ComposantGeneratedActions.deleteOneComposantSuccess, (state: ComposantState.IState, { idComposant }) =>
    ComposantState.adapter.removeOne(idComposant, setLoadingsState(state, false))
  ),
  on(ComposantGeneratedActions.clearActivesComposants, (state: ComposantState.IState) => ({ ...state, actives: [] })),
  on(ComposantGeneratedActions.addManyActivesComposants, (state: ComposantState.IState, { idComposants }) => ({
    ...state,
    actives: state.actives.concat(idComposants)
  })),
  on(ComposantGeneratedActions.deleteOneActiveComposant, (state: ComposantState.IState, { idComposant }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idComposant)
  })),

  on(ComposantGeneratedActions.clearComposants, () => ComposantState.initialState),

  on(
    ComposantGeneratedActions.addManyValeurComposantSuccess,
    (state: ComposantState.IState, { idComposant, idValeurComposants }) => {
      if (!state.entities[idComposant]) {
        return state;
      }
      const valeurComposants = (state.entities[idComposant]?.valeurComposants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposant]: {
            ...state.entities[idComposant],
            valeurComposants: valeurComposants.concat(
              idValeurComposants.filter(id => valeurComposants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ComposantGeneratedActions.deleteManyValeurComposantSuccess,
    (state: ComposantState.IState, { idValeurComposants, idComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposants.reduce((entities, idComposant) => {
            if (!state.entities[idComposant]?.valeurComposants) {
              return entities;
            }
            entities[idComposant] = {
              ...state.entities[idComposant],
              valeurComposants: (state.entities[idComposant]?.valeurComposants as number[])?.filter(
                (idValeurComposant: number) => !idValeurComposants.some((id: number) => id === idValeurComposant)
              )
            } as ComposantEntityState;
            return entities;
          }, {} as Dictionary<ComposantEntityState>)
        }
      };
    }
  ),

  on(
    ComposantGeneratedActions.addManyValeurSuccess,
    (state: ComposantState.IState, { idComposant, idValeurs }) => {
      if (!state.entities[idComposant]) {
        return state;
      }
      const valeurs = (state.entities[idComposant]?.valeurs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposant]: {
            ...state.entities[idComposant],
            valeurs: valeurs.concat(
              idValeurs.filter(id => valeurs.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ComposantGeneratedActions.deleteManyValeurSuccess,
    (state: ComposantState.IState, { idValeurs, idComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposants.reduce((entities, idComposant) => {
            if (!state.entities[idComposant]?.valeurs) {
              return entities;
            }
            entities[idComposant] = {
              ...state.entities[idComposant],
              valeurs: (state.entities[idComposant]?.valeurs as number[])?.filter(
                (idValeur: number) => !idValeurs.some((id: number) => id === idValeur)
              )
            } as ComposantEntityState;
            return entities;
          }, {} as Dictionary<ComposantEntityState>)
        }
      };
    }
  ),

  on(
    ComposantGeneratedActions.addEspaceSuccess,
    (state: ComposantState.IState, { idComposant, idEspace }) => {
      if (!state.entities[idComposant]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposant]: {
            ...state.entities[idComposant],
            espace: idEspace
          }
        }
      };
    }
  ),

  on(
    ComposantGeneratedActions.deleteManyEspaceSuccess,
    (state: ComposantState.IState, { idEspaces, idComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposants.reduce((entities, idComposant) => {
            if (!state.entities[idComposant]?.espace) {
              return entities;
            }
            entities[idComposant] = {
              ...state.entities[idComposant],
              espace: idEspaces.some(
                (idEspace: number) => idEspace === state.entities[idComposant]?.espace
              )
                ? undefined
                : state.entities[idComposant]?.espace
            } as ComposantEntityState;
            return entities;
          }, {} as Dictionary<ComposantEntityState>)
        }
      };
    }
  ),

  on(
    ComposantGeneratedActions.addSocieteComposantSuccess,
    (state: ComposantState.IState, { idComposant, idSocieteComposant }) => {
      if (!state.entities[idComposant]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposant]: {
            ...state.entities[idComposant],
            societeComposant: idSocieteComposant
          }
        }
      };
    }
  ),

  on(
    ComposantGeneratedActions.deleteManySocieteComposantSuccess,
    (state: ComposantState.IState, { idSocieteComposants, idComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposants.reduce((entities, idComposant) => {
            if (!state.entities[idComposant]?.societeComposant) {
              return entities;
            }
            entities[idComposant] = {
              ...state.entities[idComposant],
              societeComposant: idSocieteComposants.some(
                (idSocieteComposant: number) => idSocieteComposant === state.entities[idComposant]?.societeComposant
              )
                ? undefined
                : state.entities[idComposant]?.societeComposant
            } as ComposantEntityState;
            return entities;
          }, {} as Dictionary<ComposantEntityState>)
        }
      };
    }
  ),

  on(
    ComposantGeneratedActions.addUserSuccess,
    (state: ComposantState.IState, { idComposant, idUser }) => {
      if (!state.entities[idComposant]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposant]: {
            ...state.entities[idComposant],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    ComposantGeneratedActions.deleteManyUserSuccess,
    (state: ComposantState.IState, { idUsers, idComposants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposants.reduce((entities, idComposant) => {
            if (!state.entities[idComposant]?.user) {
              return entities;
            }
            entities[idComposant] = {
              ...state.entities[idComposant],
              user: idUsers.some(
                (idUser: number) => idUser === state.entities[idComposant]?.user
              )
                ? undefined
                : state.entities[idComposant]?.user
            } as ComposantEntityState;
            return entities;
          }, {} as Dictionary<ComposantEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ComposantState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): ComposantState.IState {
  return { ...state, isLoaded, isLoading };
}
