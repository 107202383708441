import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';
import { SocietePatrimoineHierarchieState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';

export const societePatrimoineHierarchieReducersGeneratedFunctions: ReducerTypes<SocietePatrimoineHierarchieState.IState, readonly ActionCreator[]>[] = [
  on(SocietePatrimoineHierarchieGeneratedActions.getOneSocietePatrimoineHierarchie, (state: SocietePatrimoineHierarchieState.IState) => setLoadingsState(state, true)),
  on(SocietePatrimoineHierarchieGeneratedActions.getManySocietePatrimoineHierarchies, (state: SocietePatrimoineHierarchieState.IState) => setLoadingsState(state, true)),
  on(SocietePatrimoineHierarchieGeneratedActions.upsertOneSocietePatrimoineHierarchie, (state: SocietePatrimoineHierarchieState.IState) => setLoadingsState(state, true)),

  on(SocietePatrimoineHierarchieGeneratedActions.upsertManySocietePatrimoineHierarchiesSuccess, (state: SocietePatrimoineHierarchieState.IState, { societePatrimoineHierarchies }) =>
    SocietePatrimoineHierarchieState.adapter.upsertMany(societePatrimoineHierarchies, setLoadingsState(state, false))
  ),
  on(SocietePatrimoineHierarchieGeneratedActions.deleteOneSocietePatrimoineHierarchie, (state: SocietePatrimoineHierarchieState.IState) => setLoadingsState(state, true)),
  on(SocietePatrimoineHierarchieGeneratedActions.deleteOneSocietePatrimoineHierarchieSuccess, (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie }) =>
    SocietePatrimoineHierarchieState.adapter.removeOne(idSocietePatrimoineHierarchie, setLoadingsState(state, false))
  ),
  on(SocietePatrimoineHierarchieGeneratedActions.clearActivesSocietePatrimoineHierarchies, (state: SocietePatrimoineHierarchieState.IState) => ({ ...state, actives: [] })),
  on(SocietePatrimoineHierarchieGeneratedActions.addManyActivesSocietePatrimoineHierarchies, (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchies }) => ({
    ...state,
    actives: state.actives.concat(idSocietePatrimoineHierarchies)
  })),
  on(SocietePatrimoineHierarchieGeneratedActions.deleteOneActiveSocietePatrimoineHierarchie, (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idSocietePatrimoineHierarchie)
  })),

  on(SocietePatrimoineHierarchieGeneratedActions.clearSocietePatrimoineHierarchies, () => SocietePatrimoineHierarchieState.initialState),

  on(
    SocietePatrimoineHierarchieGeneratedActions.addManySocieteCaracteristiqueSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie, idSocieteCaracteristiques }) => {
      if (!state.entities[idSocietePatrimoineHierarchie]) {
        return state;
      }
      const societeCaracteristiques = (state.entities[idSocietePatrimoineHierarchie]?.societeCaracteristiques as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchie]: {
            ...state.entities[idSocietePatrimoineHierarchie],
            societeCaracteristiques: societeCaracteristiques.concat(
              idSocieteCaracteristiques.filter(id => societeCaracteristiques.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.deleteManySocieteCaracteristiqueSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocieteCaracteristiques, idSocietePatrimoineHierarchies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchies.reduce((entities, idSocietePatrimoineHierarchie) => {
            if (!state.entities[idSocietePatrimoineHierarchie]?.societeCaracteristiques) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchie] = {
              ...state.entities[idSocietePatrimoineHierarchie],
              societeCaracteristiques: (state.entities[idSocietePatrimoineHierarchie]?.societeCaracteristiques as number[])?.filter(
                (idSocieteCaracteristique: number) => !idSocieteCaracteristiques.some((id: number) => id === idSocieteCaracteristique)
              )
            } as SocietePatrimoineHierarchieEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieEntityState>)
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.addManyPatrimoineSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie, idPatrimoines }) => {
      if (!state.entities[idSocietePatrimoineHierarchie]) {
        return state;
      }
      const patrimoines = (state.entities[idSocietePatrimoineHierarchie]?.patrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchie]: {
            ...state.entities[idSocietePatrimoineHierarchie],
            patrimoines: patrimoines.concat(
              idPatrimoines.filter(id => patrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.deleteManyPatrimoineSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idPatrimoines, idSocietePatrimoineHierarchies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchies.reduce((entities, idSocietePatrimoineHierarchie) => {
            if (!state.entities[idSocietePatrimoineHierarchie]?.patrimoines) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchie] = {
              ...state.entities[idSocietePatrimoineHierarchie],
              patrimoines: (state.entities[idSocietePatrimoineHierarchie]?.patrimoines as number[])?.filter(
                (idPatrimoine: number) => !idPatrimoines.some((id: number) => id === idPatrimoine)
              )
            } as SocietePatrimoineHierarchieEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieEntityState>)
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.addManyHierarchieAncetresSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie, idHierarchieAncetres }) => {
      if (!state.entities[idSocietePatrimoineHierarchie]) {
        return state;
      }
      const societePatrimoineHierarchieAncetres = (state.entities[idSocietePatrimoineHierarchie]?.hierarchieAncetres as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchie]: {
            ...state.entities[idSocietePatrimoineHierarchie],
            societePatrimoineHierarchieAncetres: societePatrimoineHierarchieAncetres.concat(
              idHierarchieAncetres.filter(id => societePatrimoineHierarchieAncetres.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.deleteManyHierarchieAncetresSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idHierarchieAncetres : idHierarchieAncetres, idSocietePatrimoineHierarchies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchies.reduce((entities, idSocietePatrimoineHierarchie) => {
            if (!state.entities[idSocietePatrimoineHierarchie]?.hierarchieAncetres) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchie] = {
              ...state.entities[idSocietePatrimoineHierarchie],
              societePatrimoineHierarchieAncetres: (state.entities[idSocietePatrimoineHierarchie]?.hierarchieAncetres as number[])?.filter(
                (idSocietePatrimoineHierarchieAncetre: number) => !idHierarchieAncetres.some((id: number) => id === idSocietePatrimoineHierarchieAncetre)
              )
            } as SocietePatrimoineHierarchieEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieEntityState>)
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.addManyHierarchieDescendantsSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie, idHierarchieDescendants }) => {
      if (!state.entities[idSocietePatrimoineHierarchie]) {
        return state;
      }
      const societePatrimoineHierarchieAncetres = (state.entities[idSocietePatrimoineHierarchie]?.hierarchieDescendants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchie]: {
            ...state.entities[idSocietePatrimoineHierarchie],
            societePatrimoineHierarchieAncetres: societePatrimoineHierarchieAncetres.concat(
              idHierarchieDescendants.filter(id => societePatrimoineHierarchieAncetres.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.deleteManyHierarchieDescendantsSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idHierarchieDescendants : idHierarchieDescendants, idSocietePatrimoineHierarchies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchies.reduce((entities, idSocietePatrimoineHierarchie) => {
            if (!state.entities[idSocietePatrimoineHierarchie]?.hierarchieDescendants) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchie] = {
              ...state.entities[idSocietePatrimoineHierarchie],
              societePatrimoineHierarchieAncetres: (state.entities[idSocietePatrimoineHierarchie]?.hierarchieDescendants as number[])?.filter(
                (idSocietePatrimoineHierarchieAncetre: number) => !idHierarchieDescendants.some((id: number) => id === idSocietePatrimoineHierarchieAncetre)
              )
            } as SocietePatrimoineHierarchieEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieEntityState>)
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.addManySocieteComposantRattachementSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie, idSocieteComposantRattachements }) => {
      if (!state.entities[idSocietePatrimoineHierarchie]) {
        return state;
      }
      const societeComposantRattachements = (state.entities[idSocietePatrimoineHierarchie]?.societeComposantRattachements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchie]: {
            ...state.entities[idSocietePatrimoineHierarchie],
            societeComposantRattachements: societeComposantRattachements.concat(
              idSocieteComposantRattachements.filter(id => societeComposantRattachements.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.deleteManySocieteComposantRattachementSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocieteComposantRattachements, idSocietePatrimoineHierarchies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchies.reduce((entities, idSocietePatrimoineHierarchie) => {
            if (!state.entities[idSocietePatrimoineHierarchie]?.societeComposantRattachements) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchie] = {
              ...state.entities[idSocietePatrimoineHierarchie],
              societeComposantRattachements: (state.entities[idSocietePatrimoineHierarchie]?.societeComposantRattachements as number[])?.filter(
                (idSocieteComposantRattachement: number) => !idSocieteComposantRattachements.some((id: number) => id === idSocieteComposantRattachement)
              )
            } as SocietePatrimoineHierarchieEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieEntityState>)
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.addManySocieteEspaceFamilleSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie, idSocieteEspaceFamilles }) => {
      if (!state.entities[idSocietePatrimoineHierarchie]) {
        return state;
      }
      const societeEspaceFamilles = (state.entities[idSocietePatrimoineHierarchie]?.societeEspaceFamilles as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchie]: {
            ...state.entities[idSocietePatrimoineHierarchie],
            societeEspaceFamilles: societeEspaceFamilles.concat(
              idSocieteEspaceFamilles.filter(id => societeEspaceFamilles.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.deleteManySocieteEspaceFamilleSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocieteEspaceFamilles, idSocietePatrimoineHierarchies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchies.reduce((entities, idSocietePatrimoineHierarchie) => {
            if (!state.entities[idSocietePatrimoineHierarchie]?.societeEspaceFamilles) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchie] = {
              ...state.entities[idSocietePatrimoineHierarchie],
              societeEspaceFamilles: (state.entities[idSocietePatrimoineHierarchie]?.societeEspaceFamilles as number[])?.filter(
                (idSocieteEspaceFamille: number) => !idSocieteEspaceFamilles.some((id: number) => id === idSocieteEspaceFamille)
              )
            } as SocietePatrimoineHierarchieEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieEntityState>)
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.addSocieteSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie, idSociete }) => {
      if (!state.entities[idSocietePatrimoineHierarchie]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchie]: {
            ...state.entities[idSocietePatrimoineHierarchie],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.deleteManySocieteSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocietes, idSocietePatrimoineHierarchies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchies.reduce((entities, idSocietePatrimoineHierarchie) => {
            if (!state.entities[idSocietePatrimoineHierarchie]?.societe) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchie] = {
              ...state.entities[idSocietePatrimoineHierarchie],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idSocietePatrimoineHierarchie]?.societe
              )
                ? undefined
                : state.entities[idSocietePatrimoineHierarchie]?.societe
            } as SocietePatrimoineHierarchieEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieEntityState>)
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.addPatrimoineHierarchieTemplateSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie, idPatrimoineHierarchieTemplate }) => {
      if (!state.entities[idSocietePatrimoineHierarchie]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchie]: {
            ...state.entities[idSocietePatrimoineHierarchie],
            patrimoineHierarchieTemplate: idPatrimoineHierarchieTemplate
          }
        }
      };
    }
  ),

  on(
    SocietePatrimoineHierarchieGeneratedActions.deleteManyPatrimoineHierarchieTemplateSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idPatrimoineHierarchieTemplates, idSocietePatrimoineHierarchies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchies.reduce((entities, idSocietePatrimoineHierarchie) => {
            if (!state.entities[idSocietePatrimoineHierarchie]?.patrimoineHierarchieTemplate) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchie] = {
              ...state.entities[idSocietePatrimoineHierarchie],
              patrimoineHierarchieTemplate: idPatrimoineHierarchieTemplates.some(
                (idPatrimoineHierarchieTemplate: number) => idPatrimoineHierarchieTemplate === state.entities[idSocietePatrimoineHierarchie]?.patrimoineHierarchieTemplate
              )
                ? undefined
                : state.entities[idSocietePatrimoineHierarchie]?.patrimoineHierarchieTemplate
            } as SocietePatrimoineHierarchieEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocietePatrimoineHierarchieState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): SocietePatrimoineHierarchieState.IState {
  return { ...state, isLoaded, isLoading };
}
