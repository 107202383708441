import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganisationNewsletter, OrganisationNewsletterEntityState } from '@get/api-interfaces';
import { Organisation, OrganisationEntityState } from '@get/api-interfaces';
import { Newsletter, NewsletterEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { OrganisationNewsletterState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const organisationNewsletterRelations: string[] = ['organisations','newsletters',];

export const { selectEntities, selectAll } = OrganisationNewsletterState.adapter.getSelectors();

export const selectOrganisationNewsletterState = createFeatureSelector<OrganisationNewsletterState.IState>(OrganisationNewsletterState.organisationNewsletterFeatureKey);

export const selectIsLoadedOrganisationNewsletter = createSelector(
  selectOrganisationNewsletterState,
  (state: OrganisationNewsletterState.IState) => state.isLoaded
);

export const selectIsLoadingOrganisationNewsletter = createSelector(
  selectOrganisationNewsletterState,
  (state: OrganisationNewsletterState.IState) => state.isLoading
);

export const selectIsReadyOrganisationNewsletter = createSelector(
  selectOrganisationNewsletterState,
  (state: OrganisationNewsletterState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganisationNewsletter = createSelector(
  selectOrganisationNewsletterState,
  (state: OrganisationNewsletterState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganisationNewslettersEntities = createSelector(selectOrganisationNewsletterState, selectEntities);

export const selectOrganisationNewslettersArray = createSelector(selectOrganisationNewsletterState, selectAll);

export const selectIdOrganisationNewslettersActive = createSelector(
  selectOrganisationNewsletterState,
  (state: OrganisationNewsletterState.IState) => state.actives
);

const organisationNewslettersInObject = (organisationNewsletters: Dictionary<OrganisationNewsletterEntityState>) => ({ organisationNewsletters })

const selectOrganisationNewslettersEntitiesDictionary = createSelector(selectOrganisationNewslettersEntities, organisationNewslettersInObject);

const selectAllOrganisationNewslettersObject = createSelector(selectOrganisationNewslettersEntities, organisationNewsletters => {
  return hydrateAll({ organisationNewsletters });
});

const selectOneOrganisationNewsletterDictionary = (idOrganisationNewsletter : number) =>
  createSelector(selectOrganisationNewslettersEntities, organisationNewsletters => ({
    organisationNewsletters: { [idOrganisationNewsletter]: organisationNewsletters[idOrganisationNewsletter] }
  }));

const selectOneOrganisationNewsletterDictionaryWithoutChild = (idOrganisationNewsletter : number) =>
  createSelector(selectOrganisationNewslettersEntities, organisationNewsletters => ({
    organisationNewsletter: organisationNewsletters[idOrganisationNewsletter]
  }));

const selectActiveOrganisationNewslettersEntities = createSelector(
  selectIdOrganisationNewslettersActive,
  selectOrganisationNewslettersEntities,
  (actives: number[], organisationNewsletters: Dictionary<OrganisationNewsletterEntityState>) => getOrganisationNewslettersFromActives(actives, organisationNewsletters)
);

function getOrganisationNewslettersFromActives(
  actives: number[],
  organisationNewsletters: Dictionary<OrganisationNewsletterEntityState>
): Dictionary<OrganisationNewsletterEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organisationNewsletters[idActive]) {
      acc[idActive] = organisationNewsletters[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganisationNewsletterEntityState>);
}

const selectAllOrganisationNewslettersSelectors: Dictionary<Selector> = {};
export function selectAllOrganisationNewsletters(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganisationNewsletter>(
      schema,
      selectAllOrganisationNewslettersSelectors,
      selectOrganisationNewslettersEntitiesDictionary,
      getRelationSelectors,
      organisationNewsletterRelations,
      hydrateAll,
      'organisationNewsletter'
    );
  } else {
    return selectAllOrganisationNewslettersObject;
  }
}

export function selectAllOrganisationNewslettersDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organisationNewsletters'
): Selector {
  return createSelector(selectAllOrganisationNewsletters(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganisationNewsletterEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organisationNewsletters.length; i++) {
      res[customKey][result.organisationNewsletters[i].idOrganisationNewsletter] = result.organisationNewsletters[i];
    }
    return res;
  });
}

export function selectOneOrganisationNewsletter(
  schema: SelectSchema = {},
  idOrganisationNewsletter: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneOrganisationNewsletterDictionary(idOrganisationNewsletter)];
  selectors.push(...getRelationSelectors(schema, organisationNewsletterRelations, 'organisationNewsletter'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganisationNewsletterDictionaryWithoutChild(idOrganisationNewsletter);
  }
}

export function selectActiveOrganisationNewsletters(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganisationNewslettersEntities, organisationNewsletters => ({
      organisationNewsletters
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organisationNewsletterRelations, 'organisationNewsletter'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organisationNewsletters: Dictionary<OrganisationNewsletterEntityState>;
  organisations?: Dictionary<OrganisationEntityState>;
  newsletters?: Dictionary<NewsletterEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organisationNewsletters: (OrganisationNewsletter | null)[] } {
  const {
    organisationNewsletters,
    organisations,
    newsletters
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organisationNewsletters: Object.keys(organisationNewsletters).map(idOrganisationNewsletter =>
      hydrate(
        organisationNewsletters[idOrganisationNewsletter] as OrganisationNewsletterEntityState,
        organisations,
        newsletters
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organisationNewsletter: OrganisationNewsletterEntityState | null } {
  const {
    organisationNewsletters,
    organisations,
    newsletters
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organisationNewsletter = Object.values(organisationNewsletters)[0];
  return {
    organisationNewsletter: hydrate(
      organisationNewsletter as OrganisationNewsletterEntityState,
      organisations,
      newsletters
    )
  };
}

function hydrate(
  organisationNewsletter: OrganisationNewsletterEntityState,
  organisationEntities?: Dictionary<OrganisationEntityState>,
  newsletterEntities?: Dictionary<NewsletterEntityState>,
): OrganisationNewsletter | null {
  if (!organisationNewsletter) {
    return null;
  }

  const organisationNewsletterHydrated: OrganisationNewsletterEntityState = { ...organisationNewsletter };
  if (organisationEntities) {
    organisationNewsletterHydrated.organisation = organisationEntities[organisationNewsletter.organisation as number] as Organisation;
  } else {
    delete organisationNewsletterHydrated.organisation;
  }
  if (newsletterEntities) {
    organisationNewsletterHydrated.newsletter = newsletterEntities[organisationNewsletter.newsletter as number] as Newsletter;
  } else {
    delete organisationNewsletterHydrated.newsletter;
  }

  return organisationNewsletterHydrated as OrganisationNewsletter;
}
