import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristique, SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueRelationsIds } from '@get/store/ids-interfaces';

export const SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions = createActionGroup({
  source: 'Societe Config Analyse Synthese Societe Caracteristique Generated',
  events: {
    'Get One Societe Config Analyse Synthese Societe Caracteristique': props<{ idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number; params?: any }>(),
    'Get Many Societe Config Analyse Synthese Societe Caracteristiques': props<{ params: any }>(),
    'Add Many Actives Societe Config Analyse Synthese Societe Caracteristiques': props<{ idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: number[] }>(),
    'Delete One Active Societe Config Analyse Synthese Societe Caracteristique': props<{ idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number }>(),
    'Clear Actives Societe Config Analyse Synthese Societe Caracteristiques': emptyProps(),
    'Upsert One Societe Config Analyse Synthese Societe Caracteristique': props<{ societeConfigAnalyseSyntheseSocieteCaracteristique: Partial<SocieteConfigAnalyseSyntheseSocieteCaracteristique>; ids?: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueRelationsIds; }>(),
    'Upsert Many Societe Config Analyse Synthese Societe Caracteristiques': props<{ societeConfigAnalyseSyntheseSocieteCaracteristiques: Partial<SocieteConfigAnalyseSyntheseSocieteCaracteristique>[]; ids?: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueRelationsIds; }>(),
    'Upsert Many Societe Config Analyse Synthese Societe Caracteristiques Success': props<{ societeConfigAnalyseSyntheseSocieteCaracteristiques: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState[] }>(),
    'Delete One Societe Config Analyse Synthese Societe Caracteristique': props<{ idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number }>(),
    'Delete One Societe Config Analyse Synthese Societe Caracteristique Success': props<{ idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number }>(),
    'Normalize Many Societe Config Analyse Synthese Societe Caracteristiques After Upsert': props<{ societeConfigAnalyseSyntheseSocieteCaracteristiques: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState[] }>(),
    'Societe Config Analyse Synthese Societe Caracteristiques Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Config Analyse Synthese Societe Caracteristiques': emptyProps(),
    'Add Societe Config Analyse Synthese Success': props<{ idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number; idSocieteConfigAnalyseSynthese: number }>(),
    'Delete Many Societe Config Analyse Synthese Success': props<{ idSocieteConfigAnalyseSyntheses: number[]; idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: number[] }>(),
    'Add Societe Caracteristique Success': props<{ idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number; idSocieteCaracteristique: number }>(),
    'Delete Many Societe Caracteristique Success': props<{ idSocieteCaracteristiques: number[]; idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: number[] }>()
  }
});
