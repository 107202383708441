import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocieteProfil } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocieteProfilRelationsIds } from '@get/store/ids-interfaces';
import { SocieteProfilGeneratedActions } from '@get/store/actions';
import { SocieteProfilSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedSocieteProfilService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(SocieteProfilSelectors.selectIsLoadedSocieteProfil));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(SocieteProfilSelectors.selectIsLoadingSocieteProfil));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      SocieteProfilSelectors.selectIsReadyAndLoadedSocieteProfil as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSocieteProfils(schema: SelectSchema = {}): Observable<SocieteProfil[]> {
    return this.store$.pipe(select(SocieteProfilSelectors.selectAllSocieteProfils(schema))).pipe(
      switchMap(({ societeProfils }: { societeProfils: SocieteProfil[] }) => {
        return this.getReady(schema).pipe(mapTo(societeProfils));
      })
    );
  }

  public selectOneSocieteProfil(
    idSocieteProfil: number,
    schema: SelectSchema = {}
  ): Observable<SocieteProfil> {
    return this.store$
      .pipe(select(SocieteProfilSelectors.selectOneSocieteProfil(schema, idSocieteProfil)))
      .pipe(
        switchMap(({ societeProfil }: { societeProfil: SocieteProfil }) => {
          return this.getReady(schema).pipe(mapTo(societeProfil));
        })
      );
  }

  public selectAllActiveSocieteProfils(schema: SelectSchema = {}): Observable<SocieteProfil[]> {
    return this.store$.pipe(select(SocieteProfilSelectors.selectActiveSocieteProfils(schema))).pipe(
      switchMap(({ societeProfils }: { societeProfils: SocieteProfil[] }) => {
        return this.getReady(schema).pipe(mapTo(societeProfils));
      })
    );
  }

  public selectIdSocieteProfilsActive(): Observable<number[]> {
    return this.store$.pipe(select(SocieteProfilSelectors.selectIdSocieteProfilsActive)).pipe(
      switchMap((idSocieteProfils: number[]) => {
        return this.getReady().pipe(mapTo(idSocieteProfils));
      })
    );
  }

  public getOneSocieteProfil(
    idSocieteProfil: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteProfil> {
    this.store$.dispatch(SocieteProfilGeneratedActions.getOneSocieteProfil({ idSocieteProfil, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteProfilGeneratedActions.normalizeManySocieteProfilsAfterUpsert,
        SocieteProfilGeneratedActions.societeProfilsFailure,
        true
      );
    }
  }

  public getManySocieteProfils(
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteProfil[]> {
    this.store$.dispatch(SocieteProfilGeneratedActions.getManySocieteProfils({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteProfilGeneratedActions.normalizeManySocieteProfilsAfterUpsert,
        SocieteProfilGeneratedActions.societeProfilsFailure
      );
    }
  }

  public upsertOneSocieteProfil(
    societeProfil: Partial<SocieteProfil>,
    ids: SocieteProfilRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteProfil> {
    this.store$.dispatch(SocieteProfilGeneratedActions.upsertOneSocieteProfil({ societeProfil, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteProfilGeneratedActions.normalizeManySocieteProfilsAfterUpsert,
        SocieteProfilGeneratedActions.societeProfilsFailure,
        true
      );
    }
  }

  public deleteOneSocieteProfil(
    idSocieteProfil: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(SocieteProfilGeneratedActions.deleteOneSocieteProfil({ idSocieteProfil }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteProfilGeneratedActions.deleteOneSocieteProfilSuccess,
        SocieteProfilGeneratedActions.societeProfilsFailure
      );
    }
  }

  public setActiveSocieteProfils(idSocieteProfils: number[]): void {
    this.store$.dispatch(SocieteProfilGeneratedActions.clearActivesSocieteProfils());
    this.store$.dispatch(SocieteProfilGeneratedActions.addManyActivesSocieteProfils({ idSocieteProfils }));
  }
}
