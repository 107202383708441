import { RepositoryService } from '@get/services/repository';
import { UserSocieteProfil } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUserSocieteProfilApiService {
  constructor(protected repo: RepositoryService) {}

  public getUserSocieteProfils(params?: any): Observable<UserSocieteProfil[]> {
    return this.repo.getData<UserSocieteProfil[]>('user-societe-profil', params);
  }

  public getUserSocieteProfil(params: { idUserSocieteProfil: number; params?: any }): Observable<UserSocieteProfil> {
    return this.repo.getData<UserSocieteProfil>('user-societe-profil/' + params.idUserSocieteProfil, params.params);
  }

  public addUserSocieteProfil(userSocieteProfil: Partial<UserSocieteProfil>): Observable<UserSocieteProfil> {
    return this.repo.create<UserSocieteProfil>('user-societe-profil', userSocieteProfil);
  }

  public updateUserSocieteProfil(userSocieteProfil: Partial<UserSocieteProfil>): Observable<UserSocieteProfil> {
    return this.repo.update('user-societe-profil', userSocieteProfil);
  }

  public deleteUserSocieteProfil(idUserSocieteProfil: number): Observable<number> {
    return this.repo.delete('user-societe-profil/' + +idUserSocieteProfil);
  }
}

