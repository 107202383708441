import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteProfil, SocieteProfilEntityState } from '@get/api-interfaces';
import { SocieteProfilRelationsIds } from '@get/store/ids-interfaces';

export const SocieteProfilGeneratedActions = createActionGroup({
  source: 'Societe Profil Generated',
  events: {
    'Get One Societe Profil': props<{ idSocieteProfil: number; params?: any }>(),
    'Get Many Societe Profils': props<{ params: any }>(),
    'Add Many Actives Societe Profils': props<{ idSocieteProfils: number[] }>(),
    'Delete One Active Societe Profil': props<{ idSocieteProfil: number }>(),
    'Clear Actives Societe Profils': emptyProps(),
    'Upsert One Societe Profil': props<{ societeProfil: Partial<SocieteProfil>; ids?: SocieteProfilRelationsIds; }>(),
    'Upsert Many Societe Profils': props<{ societeProfils: Partial<SocieteProfil>[]; ids?: SocieteProfilRelationsIds; }>(),
    'Upsert Many Societe Profils Success': props<{ societeProfils: SocieteProfilEntityState[] }>(),
    'Delete One Societe Profil': props<{ idSocieteProfil: number }>(),
    'Delete One Societe Profil Success': props<{ idSocieteProfil: number }>(),
    'Normalize Many Societe Profils After Upsert': props<{ societeProfils: SocieteProfilEntityState[] }>(),
    'Societe Profils Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Profils': emptyProps(),
    'Add Many User Success': props<{ idSocieteProfil: number; idUsers: number[] }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idSocieteProfils: number[] }>(),
    'Add Many User Societe Profil Success': props<{ idSocieteProfil: number; idUserSocieteProfils: number[] }>(),
    'Delete Many User Societe Profil Success': props<{ idUserSocieteProfils: number[]; idSocieteProfils: number[] }>(),
    'Add Many Societe Profil Droit Success': props<{ idSocieteProfil: number; idSocieteProfilDroits: number[] }>(),
    'Delete Many Societe Profil Droit Success': props<{ idSocieteProfilDroits: number[]; idSocieteProfils: number[] }>(),
    'Add Many Droit Success': props<{ idSocieteProfil: number; idDroits: number[] }>(),
    'Delete Many Droit Success': props<{ idDroits: number[]; idSocieteProfils: number[] }>(),
    'Add Societe Success': props<{ idSocieteProfil: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idSocieteProfils: number[] }>()
  }
});
