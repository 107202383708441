import { RepositoryService } from '@get/services/repository';
import { ComposantAttendu } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedComposantAttenduApiService {
  constructor(protected repo: RepositoryService) {}

  public getComposantAttendus(params?: any): Observable<ComposantAttendu[]> {
    return this.repo.getData<ComposantAttendu[]>('composant-attendu', params);
  }

  public getComposantAttendu(params: { idComposantAttendu: number; params?: any }): Observable<ComposantAttendu> {
    return this.repo.getData<ComposantAttendu>('composant-attendu/' + params.idComposantAttendu, params.params);
  }

  public addComposantAttendu(composantAttendu: Partial<ComposantAttendu>): Observable<ComposantAttendu> {
    return this.repo.create<ComposantAttendu>('composant-attendu', composantAttendu);
  }

  public updateComposantAttendu(composantAttendu: Partial<ComposantAttendu>): Observable<ComposantAttendu> {
    return this.repo.update('composant-attendu', composantAttendu);
  }

  public deleteComposantAttendu(idComposantAttendu: number): Observable<number> {
    return this.repo.delete('composant-attendu/' + +idComposantAttendu);
  }
}

