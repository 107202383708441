import { RepositoryService } from '@get/services/repository';
import { SocieteComposantFamille } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteComposantFamilleApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteComposantFamilles(params?: any): Observable<SocieteComposantFamille[]> {
    return this.repo.getData<SocieteComposantFamille[]>('societe-composant-famille', params);
  }

  public getSocieteComposantFamille(params: { idSocieteComposantFamille: number; params?: any }): Observable<SocieteComposantFamille> {
    return this.repo.getData<SocieteComposantFamille>('societe-composant-famille/' + params.idSocieteComposantFamille, params.params);
  }

  public addSocieteComposantFamille(societeComposantFamille: Partial<SocieteComposantFamille>): Observable<SocieteComposantFamille> {
    return this.repo.create<SocieteComposantFamille>('societe-composant-famille', societeComposantFamille);
  }

  public updateSocieteComposantFamille(societeComposantFamille: Partial<SocieteComposantFamille>): Observable<SocieteComposantFamille> {
    return this.repo.update('societe-composant-famille', societeComposantFamille);
  }

  public deleteSocieteComposantFamille(idSocieteComposantFamille: number): Observable<number> {
    return this.repo.delete('societe-composant-famille/' + +idSocieteComposantFamille);
  }
}

