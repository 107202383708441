import { AncestorNumber, DynamicAncestorsNumbers, PatrimoineAncetre } from '@get/api-interfaces';
import { sortByMultipleKey } from './arrays.utils';
import { transformToPlural } from './string.utils';

export function sortAncestorsNumbers(numbers: AncestorNumber[]): AncestorNumber[] {
  return numbers?.sort(sortByMultipleKey([{ name: 'niveau' }, { name: 'libelle' }])) || [];
}

export function formatAncestorNumber(nb: AncestorNumber): string {
  return `${nb.value} ${nb.value > 1 ? transformToPlural(nb.libelle) : nb.libelle}`;
}

export function getAncestorsNumbersFormatted(numbers: AncestorNumber[]): string[] {
  return numbers?.map(formatAncestorNumber) || [];
}

/**
 * @param elements Need to include: descendantPatrimoine & societePatrimoineHierarchie
 * @returns DynamicAncestorsNumbers
 */
export function getAncestorsNumbers(elements: PatrimoineAncetre[]): DynamicAncestorsNumbers {
  return (
    elements?.reduce((acc, value) => {
      const structure = value.descendantPatrimoine?.societePatrimoineHierarchie;
      const libelle = structure?.libelle || 'Non défini';
      acc[libelle] = {
        idSocietePatrimoineHierarchie: structure?.idSocietePatrimoineHierarchie,
        libelle,
        displayed: structure?.displayed,
        niveau: value.niveau,
        value: (acc[libelle]?.value ?? 0) + 1,
        lastIdPatrimoine: value.descendantPatrimoine?.idPatrimoine
      };
      return acc;
    }, {} as DynamicAncestorsNumbers) || {}
  );
}

export function getDirectAncestors<T extends { niveau: number }>(elements: T[]): T[] {
  return elements?.filter(el => el.niveau === 1) || [];
}

export function getDirectAncestor<T extends { niveau: number }>(elements: T[]): T | null {
  return elements?.find(el => el.niveau === 1) || null;
}

export function transformAncestorsArrayToTree(
  idElement: number,
  values: any[],
  identifiers: {
    key: keyof any;
    parentListKey: keyof any;
    childrenKey: string;
    parentListIdentifierKey: string;
  }
): any[] {
  if (!values?.length) {
    return [];
  }
  const obj = transformAncestorsArrayToObject<any>(values, identifiers);

  let i = 0;
  while (i < values.length) {
    if (values[i][identifiers.parentListKey]?.length) {
      const directParent = getDirectAncestors<any>(values[i][identifiers.parentListKey])?.[0];
      if (
        directParent?.[identifiers.parentListIdentifierKey] &&
        obj[directParent[identifiers.parentListIdentifierKey]] &&
        obj[values[i][identifiers.key]]
      ) {
        obj[directParent[identifiers.parentListIdentifierKey]][identifiers.childrenKey].push(
          obj[values[i][identifiers.key]]
        );
      }
    }
    i++;
  }

  const res = values.filter(value => value[identifiers.key] === idElement)?.map(value => obj[value[identifiers.key]]);
  return res;
}

export function transformAncestorsArrayToObject<T>(
  values: T[],
  identifiers: { key: keyof T; childrenKey?: string }
): any {
  const obj = {} as any;
  for (let i = 0; i < values?.length; i++) {
    const value = values[i];
    obj[value[identifiers.key]] = identifiers.childrenKey ? { ...value, [identifiers.childrenKey]: [] } : { ...value };
  }
  return obj;
}

export function getHighestAncestor(ancestors: PatrimoineAncetre[]): PatrimoineAncetre {
  let ancestor: PatrimoineAncetre = ancestors?.[0];
  for (let i = 0; i < ancestors?.length; i++) {
    if (ancestors[i]?.niveau > ancestor?.niveau) {
      ancestor = ancestors[i];
    }
  }

  return ancestor;
}
