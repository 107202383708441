import { RepositoryService } from '@get/services/repository';
import { Organisation } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganisationApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganisations(params?: any): Observable<Organisation[]> {
    return this.repo.getData<Organisation[]>('organisation', params);
  }

  public getOrganisation(params: { idOrganisation: number; params?: any }): Observable<Organisation> {
    return this.repo.getData<Organisation>('organisation/' + params.idOrganisation, params.params);
  }

  public addOrganisation(organisation: Partial<Organisation>): Observable<Organisation> {
    return this.repo.create<Organisation>('organisation', organisation);
  }

  public updateOrganisation(organisation: Partial<Organisation>): Observable<Organisation> {
    return this.repo.update('organisation', organisation);
  }

  public deleteOrganisation(idOrganisation: number): Observable<number> {
    return this.repo.delete('organisation/' + +idOrganisation);
  }
}

