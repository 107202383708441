import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CampagnePatrimoineGeneratedActions } from '@get/store/actions';
import { CampagnePatrimoineState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { CampagnePatrimoineEntityState } from '@get/api-interfaces';

export const campagnePatrimoineReducersGeneratedFunctions: ReducerTypes<CampagnePatrimoineState.IState, readonly ActionCreator[]>[] = [
  on(CampagnePatrimoineGeneratedActions.getOneCampagnePatrimoine, (state: CampagnePatrimoineState.IState) => setLoadingsState(state, true)),
  on(CampagnePatrimoineGeneratedActions.getManyCampagnePatrimoines, (state: CampagnePatrimoineState.IState) => setLoadingsState(state, true)),
  on(CampagnePatrimoineGeneratedActions.upsertOneCampagnePatrimoine, (state: CampagnePatrimoineState.IState) => setLoadingsState(state, true)),

  on(CampagnePatrimoineGeneratedActions.upsertManyCampagnePatrimoinesSuccess, (state: CampagnePatrimoineState.IState, { campagnePatrimoines }) =>
    CampagnePatrimoineState.adapter.upsertMany(campagnePatrimoines, setLoadingsState(state, false))
  ),
  on(CampagnePatrimoineGeneratedActions.deleteOneCampagnePatrimoine, (state: CampagnePatrimoineState.IState) => setLoadingsState(state, true)),
  on(CampagnePatrimoineGeneratedActions.deleteOneCampagnePatrimoineSuccess, (state: CampagnePatrimoineState.IState, { idCampagnePatrimoine }) =>
    CampagnePatrimoineState.adapter.removeOne(idCampagnePatrimoine, setLoadingsState(state, false))
  ),
  on(CampagnePatrimoineGeneratedActions.clearActivesCampagnePatrimoines, (state: CampagnePatrimoineState.IState) => ({ ...state, actives: [] })),
  on(CampagnePatrimoineGeneratedActions.addManyActivesCampagnePatrimoines, (state: CampagnePatrimoineState.IState, { idCampagnePatrimoines }) => ({
    ...state,
    actives: state.actives.concat(idCampagnePatrimoines)
  })),
  on(CampagnePatrimoineGeneratedActions.deleteOneActiveCampagnePatrimoine, (state: CampagnePatrimoineState.IState, { idCampagnePatrimoine }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCampagnePatrimoine)
  })),

  on(CampagnePatrimoineGeneratedActions.clearCampagnePatrimoines, () => CampagnePatrimoineState.initialState),

  on(
    CampagnePatrimoineGeneratedActions.addCampagneSuccess,
    (state: CampagnePatrimoineState.IState, { idCampagnePatrimoine, idCampagne }) => {
      if (!state.entities[idCampagnePatrimoine]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagnePatrimoine]: {
            ...state.entities[idCampagnePatrimoine],
            campagne: idCampagne
          }
        }
      };
    }
  ),

  on(
    CampagnePatrimoineGeneratedActions.deleteManyCampagneSuccess,
    (state: CampagnePatrimoineState.IState, { idCampagnes, idCampagnePatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagnePatrimoines.reduce((entities, idCampagnePatrimoine) => {
            if (!state.entities[idCampagnePatrimoine]?.campagne) {
              return entities;
            }
            entities[idCampagnePatrimoine] = {
              ...state.entities[idCampagnePatrimoine],
              campagne: idCampagnes.some(
                (idCampagne: number) => idCampagne === state.entities[idCampagnePatrimoine]?.campagne
              )
                ? undefined
                : state.entities[idCampagnePatrimoine]?.campagne
            } as CampagnePatrimoineEntityState;
            return entities;
          }, {} as Dictionary<CampagnePatrimoineEntityState>)
        }
      };
    }
  ),

  on(
    CampagnePatrimoineGeneratedActions.addPatrimoineSuccess,
    (state: CampagnePatrimoineState.IState, { idCampagnePatrimoine, idPatrimoine }) => {
      if (!state.entities[idCampagnePatrimoine]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagnePatrimoine]: {
            ...state.entities[idCampagnePatrimoine],
            patrimoine: idPatrimoine
          }
        }
      };
    }
  ),

  on(
    CampagnePatrimoineGeneratedActions.deleteManyPatrimoineSuccess,
    (state: CampagnePatrimoineState.IState, { idPatrimoines, idCampagnePatrimoines }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagnePatrimoines.reduce((entities, idCampagnePatrimoine) => {
            if (!state.entities[idCampagnePatrimoine]?.patrimoine) {
              return entities;
            }
            entities[idCampagnePatrimoine] = {
              ...state.entities[idCampagnePatrimoine],
              patrimoine: idPatrimoines.some(
                (idPatrimoine: number) => idPatrimoine === state.entities[idCampagnePatrimoine]?.patrimoine
              )
                ? undefined
                : state.entities[idCampagnePatrimoine]?.patrimoine
            } as CampagnePatrimoineEntityState;
            return entities;
          }, {} as Dictionary<CampagnePatrimoineEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: CampagnePatrimoineState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CampagnePatrimoineState.IState {
  return { ...state, isLoaded, isLoading };
}
