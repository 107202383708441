import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdminPatrimoineListQueryParams,
  AnalyzeGraphicDtoInterface,
  AnalyzeGraphicPatrimoine,
  AnalyzePerPatrimoineParams,
  AnalyzeSyntheticPatrimoinesQueryParams,
  AnalyzeSyntheticQueryParams,
  CountPatrimoineInterface,
  FetchPatrimoineForCampagne,
  Fichier,
  Patrimoine,
  PatrimoineRattachementFindOptions,
  UpdatePatrimoineRattachement,
  Valeur
} from '@get/api-interfaces';
import { environment } from '@get/environment';
import { RepositoryService } from '@get/services/repository';
import { EMPTY, Observable, catchError, first, firstValueFrom, map } from 'rxjs';
import { GeneratedPatrimoineApiService } from './patrimoine-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class PatrimoineApiService extends GeneratedPatrimoineApiService {
  constructor(repo: RepositoryService, private readonly http: HttpClient) {
    super(repo);
  }

  public getOnePatrimoineComplete(params: { idPatrimoine: number; params?: any }): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/complete/' + params.idPatrimoine, params.params);
  }

  public getOnePatrimoineWithComposantValeurs(params: {
    idPatrimoine: number;
    params?: any;
  }): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/with-composant-valeurs/' + params.idPatrimoine, params.params);
  }

  public getManyPatrimoinesWithValeurs(params?: any): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/with-valeurs', params);
  }

  public getCountPatrimoineHierarchie(params?: AnalyzeGraphicDtoInterface): Promise<CountPatrimoineInterface[]> {
    return firstValueFrom(
      this.repo.getData<CountPatrimoineInterface[]>('patrimoine/count-patrimoine-hierarchie', params)
    );
  }

  public getCountPatrimoineCaracteristiques(params?: AnalyzeGraphicDtoInterface): Promise<AnalyzeGraphicPatrimoine> {
    return firstValueFrom(
      this.repo.getData<AnalyzeGraphicPatrimoine>('patrimoine/count-patrimoine-caracteristiques', params)
    );
  }

  public getPatrimoinesRattachement(params: PatrimoineRattachementFindOptions): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/rattachement', params);
  }

  public getManyPatrimoinesForUserRattachement(params: PatrimoineRattachementFindOptions): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/for-user-rattachement', params);
  }

  public getDescendantsById(params: { idPatrimoine: number; params?: any }): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/descendants/' + params.idPatrimoine, params.params);
  }

  public updatePatrimoineRattachement(params: UpdatePatrimoineRattachement): Observable<Patrimoine> {
    return this.repo.create('patrimoine/rattachement', params);
  }

  public fetchAnalyzeSyntheticElements(params: AnalyzeSyntheticQueryParams): Promise<any[]> {
    return firstValueFrom(this.repo.getData<any[]>('patrimoine/analyze/synthetic', params));
  }

  public getPatrimoinesProgress(params?: any): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/avancement', params);
  }

  public getPatrimoinesForPerTypeComposant(params?: any): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/per-type-patrimoines', params);
  }

  public getManyPatrimoineAnalyze(params?: AnalyzePerPatrimoineParams): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/analyze', params);
  }

  public getTreePatrimoine(params?: AnalyzeSyntheticQueryParams): Promise<any[]> {
    return firstValueFrom(this.repo.getData<any[]>('patrimoine/tree', params));
  }

  public getOnePatrimoineForCampagne(params: FetchPatrimoineForCampagne): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/campagne', params);
  }

  public fetchAnalyzeSyntheticPatrimoines(params: AnalyzeSyntheticPatrimoinesQueryParams): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/analyze/synthetic/patrimoines', params);
  }

  public fetchAdminPatrimoineList(params: AdminPatrimoineListQueryParams): Observable<Patrimoine[]> {
    return this.repo.getData<Patrimoine[]>('patrimoine/admin/patrimoine-list', params);
  }

  public uploadManyImages(
    files: Array<File>,
    queryParams: { idOrganisation?: number } = {}
  ): Observable<Array<Fichier>> {
    const formData = new FormData();
    const route = `${new URL(environment.apiUrl + '/patrimoine/images').href}${
      queryParams?.idOrganisation ? '?idOrganisation=' + queryParams.idOrganisation : ''
    }`;
    files.forEach(media => {
      formData.append('files', media);
    });

    return this.http.post(route, formData).pipe(
      first(),
      map((res: any) => res.data),
      catchError(() => EMPTY)
    );
  }

  public uploadMany(files: Array<File>, queryParams: { idOrganisation?: number } = {}): Observable<Array<Fichier>> {
    const formData = new FormData();
    const route = `${new URL(environment.apiUrl + '/patrimoine/files').href}${
      queryParams?.idOrganisation ? '?idOrganisation=' + queryParams.idOrganisation : ''
    }`;
    files.forEach(media => {
      formData.append('files', media);
    });

    return this.http.post(route, formData).pipe(
      first(),
      map((res: any) => res.data),
      catchError(() => EMPTY)
    );
  }

  public downloadOneFichier(idFichier: number): Observable<Blob> {
    return this.http
      .get(environment.apiUrl + `/patrimoine/fichier/download/${idFichier}`, {
        responseType: 'blob'
      })
      .pipe(first());
  }

  public updateValeur(valeur: Partial<Valeur>): Observable<Valeur> {
    return this.repo.update('patrimoine/valeur', valeur);
  }

  public deleteAllPatrimoines(idSociete: number): Observable<number> {
    return this.repo.delete('patrimoine/by-societe/' + +idSociete);
  }

  public deleteValeurFichier(idValeurFichier: number): Observable<number> {
    return this.repo.delete('patrimoine/fichier/' + +idValeurFichier);
  }

  public calculateCoordinates(idSociete?: number, mode: 'force' | 'missing' = 'missing'): Observable<void> {
    return this.repo.update(
      `patrimoine/calculate-coordinate-for-all-patrimoines${idSociete ? '/' + idSociete : ''}${
        mode ? '?mode=' + mode : ''
      }`,
      {}
    );
  }

  public calculateAvancement(idSociete?: number): Observable<any> {
    return this.repo.update(`patrimoine/avancement${idSociete ? '/' + idSociete : ''}`, {});
  }

  public deleteOnePatrimoineAndReturnAllDeletedPatrimoines(
    idPatrimoine: number
  ): Observable<{ affectedRows: number; idsPatrimoine: number[] }> {
    return this.repo.delete('patrimoine/return-deleted-patrimoines/' + +idPatrimoine);
  }
}
