import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { PatrimoineApiService } from '@get/store/api-services';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizePatrimoine } from '@get/store/configs/normalization';
import { PatrimoineSelectors } from '@get/store/selectors';
import { PatrimoineRelationsIds } from '@get/store/ids-interfaces';
import { SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';
import { SocieteGeneratedActions } from '@get/store/actions';
import { UserPatrimoineGeneratedActions } from '@get/store/actions';
import { UserPatrimoine } from '@get/api-interfaces';
import { UserGeneratedActions } from '@get/store/actions';
import { User } from '@get/api-interfaces';
import { ValeurPatrimoineGeneratedActions } from '@get/store/actions';
import { ValeurPatrimoine } from '@get/api-interfaces';
import { ValeurGeneratedActions } from '@get/store/actions';
import { Valeur } from '@get/api-interfaces';
import { PatrimoineAncetreGeneratedActions } from '@get/store/actions';
import { PatrimoineAncetre } from '@get/api-interfaces';
import { EspaceGeneratedActions } from '@get/store/actions';
import { Espace } from '@get/api-interfaces';
import { CampagnePatrimoineGeneratedActions } from '@get/store/actions';
import { CampagnePatrimoine } from '@get/api-interfaces';
import { CampagneGeneratedActions } from '@get/store/actions';
import { Campagne } from '@get/api-interfaces';
import { SocieteTerritoirePatrimoineGeneratedActions } from '@get/store/actions';
import { SocieteTerritoirePatrimoine } from '@get/api-interfaces';
import { SocieteTerritoireGeneratedActions } from '@get/store/actions';
import { SocieteTerritoire } from '@get/api-interfaces';
import { ComposantAttenduGeneratedActions } from '@get/store/actions';
import { ComposantAttendu } from '@get/api-interfaces';

export function getDefaultAddPatrimoineActions(patrimoine: PatrimoineEntityState, ids?: PatrimoineRelationsIds): Action[] {
  const actions: Action[] = [PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines: [patrimoine] })];

  if (ids?.societePatrimoineHierarchie) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.addManyPatrimoineSuccess({
        idSocietePatrimoineHierarchie: ids.societePatrimoineHierarchie,
        idPatrimoines: [patrimoine.idPatrimoine]
      })
    );
    actions.push(
      PatrimoineGeneratedActions.addSocietePatrimoineHierarchieSuccess({
        idPatrimoine: patrimoine.idPatrimoine,
        idSocietePatrimoineHierarchie: ids.societePatrimoineHierarchie
      })
    );
  }

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManyPatrimoineSuccess({
        idSociete: ids.societe,
        idPatrimoines: [patrimoine.idPatrimoine]
      })
    );
    actions.push(
      PatrimoineGeneratedActions.addSocieteSuccess({
        idPatrimoine: patrimoine.idPatrimoine,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.userPatrimoines) {
    if (!Array.isArray(ids.userPatrimoines)) {
      actions.push(
        UserPatrimoineGeneratedActions.upsertOneUserPatrimoine({
          userPatrimoine: {
            idPatrimoine: patrimoine.idPatrimoine,
            idUserPatrimoine: ids.userPatrimoines as number
          } as UserPatrimoine
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyUserPatrimoineSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idUserPatrimoines: [ids.userPatrimoines as number]
        })
      );
    } else {
      actions.push(
        UserPatrimoineGeneratedActions.upsertManyUserPatrimoines({
          userPatrimoines: (ids.userPatrimoines as number[]).map(
            (idUserPatrimoine: number) => ({
              idPatrimoine: patrimoine.idPatrimoine,
              idUserPatrimoine
            })
          ) as UserPatrimoine[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyUserPatrimoineSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idUserPatrimoines: ids.userPatrimoines as number[]
        })
      );
    }
  }

  if (ids?.users) {
    if (!Array.isArray(ids.users)) {
      actions.push(
        UserGeneratedActions.upsertOneUser({
          user: {
            idPatrimoine: patrimoine.idPatrimoine,
            idUser: ids.users as number
          } as User & any
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyUserSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idUsers: [ids.users as number]
        })
      );
    } else {
      actions.push(
        UserGeneratedActions.upsertManyUsers({
          users: (ids.users as number[]).map(
            (idUser: number) => ({
              idPatrimoine: patrimoine.idPatrimoine,
              idUser
            })
          ) as User[] & any[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyUserSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idUsers: ids.users as number[]
        })
      );
    }
  }

  if (ids?.valeurPatrimoines) {
    if (!Array.isArray(ids.valeurPatrimoines)) {
      actions.push(
        ValeurPatrimoineGeneratedActions.upsertOneValeurPatrimoine({
          valeurPatrimoine: {
            idPatrimoine: patrimoine.idPatrimoine,
            idValeurPatrimoine: ids.valeurPatrimoines as number
          } as ValeurPatrimoine
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyValeurPatrimoineSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idValeurPatrimoines: [ids.valeurPatrimoines as number]
        })
      );
    } else {
      actions.push(
        ValeurPatrimoineGeneratedActions.upsertManyValeurPatrimoines({
          valeurPatrimoines: (ids.valeurPatrimoines as number[]).map(
            (idValeurPatrimoine: number) => ({
              idPatrimoine: patrimoine.idPatrimoine,
              idValeurPatrimoine
            })
          ) as ValeurPatrimoine[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyValeurPatrimoineSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idValeurPatrimoines: ids.valeurPatrimoines as number[]
        })
      );
    }
  }

  if (ids?.valeurs) {
    if (!Array.isArray(ids.valeurs)) {
      actions.push(
        ValeurGeneratedActions.upsertOneValeur({
          valeur: {
            idPatrimoine: patrimoine.idPatrimoine,
            idValeur: ids.valeurs as number
          } as Valeur & any
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyValeurSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idValeurs: [ids.valeurs as number]
        })
      );
    } else {
      actions.push(
        ValeurGeneratedActions.upsertManyValeurs({
          valeurs: (ids.valeurs as number[]).map(
            (idValeur: number) => ({
              idPatrimoine: patrimoine.idPatrimoine,
              idValeur
            })
          ) as Valeur[] & any[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyValeurSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idValeurs: ids.valeurs as number[]
        })
      );
    }
  }

  if (ids?.ancetres) {
    if (!Array.isArray(ids.ancetres)) {
      actions.push(
        PatrimoineAncetreGeneratedActions.upsertOnePatrimoineAncetre({
          patrimoineAncetre: {
            idDescendantPatrimoine: patrimoine.idPatrimoine,
            idPatrimoineAncetre: ids.ancetres as number
          } as PatrimoineAncetre
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyAncetresSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idAncetres: [ids.ancetres as number]
        })
      );
    } else {
      actions.push(
        PatrimoineAncetreGeneratedActions.upsertManyPatrimoineAncetres({
          patrimoineAncetres: (ids.ancetres as number[]).map(
            (idPatrimoineAncetre: number) => ({
              idDescendantPatrimoine: patrimoine.idPatrimoine,
              idPatrimoineAncetre
            })
          ) as PatrimoineAncetre[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyAncetresSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idAncetres: ids.ancetres as number[]
        })
      );
    }
  }

  if (ids?.descendants) {
    if (!Array.isArray(ids.descendants)) {
      actions.push(
        PatrimoineAncetreGeneratedActions.upsertOnePatrimoineAncetre({
          patrimoineAncetre: {
            idAncetrePatrimoine: patrimoine.idPatrimoine,
            idPatrimoineAncetre: ids.descendants as number
          } as PatrimoineAncetre
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyDescendantsSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idDescendants: [ids.descendants as number]
        })
      );
    } else {
      actions.push(
        PatrimoineAncetreGeneratedActions.upsertManyPatrimoineAncetres({
          patrimoineAncetres: (ids.descendants as number[]).map(
            (idPatrimoineAncetre: number) => ({
              idAncetrePatrimoine: patrimoine.idPatrimoine,
              idPatrimoineAncetre
            })
          ) as PatrimoineAncetre[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyDescendantsSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idDescendants: ids.descendants as number[]
        })
      );
    }
  }

  if (ids?.espaces) {
    if (!Array.isArray(ids.espaces)) {
      actions.push(
        EspaceGeneratedActions.upsertOneEspace({
          espace: {
            idPatrimoine: patrimoine.idPatrimoine,
            idEspace: ids.espaces as number
          } as Espace
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyEspaceSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idEspaces: [ids.espaces as number]
        })
      );
    } else {
      actions.push(
        EspaceGeneratedActions.upsertManyEspaces({
          espaces: (ids.espaces as number[]).map(
            (idEspace: number) => ({
              idPatrimoine: patrimoine.idPatrimoine,
              idEspace
            })
          ) as Espace[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyEspaceSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idEspaces: ids.espaces as number[]
        })
      );
    }
  }

  if (ids?.campagnePatrimoines) {
    if (!Array.isArray(ids.campagnePatrimoines)) {
      actions.push(
        CampagnePatrimoineGeneratedActions.upsertOneCampagnePatrimoine({
          campagnePatrimoine: {
            idPatrimoine: patrimoine.idPatrimoine,
            idCampagnePatrimoine: ids.campagnePatrimoines as number
          } as CampagnePatrimoine
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyCampagnePatrimoineSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idCampagnePatrimoines: [ids.campagnePatrimoines as number]
        })
      );
    } else {
      actions.push(
        CampagnePatrimoineGeneratedActions.upsertManyCampagnePatrimoines({
          campagnePatrimoines: (ids.campagnePatrimoines as number[]).map(
            (idCampagnePatrimoine: number) => ({
              idPatrimoine: patrimoine.idPatrimoine,
              idCampagnePatrimoine
            })
          ) as CampagnePatrimoine[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyCampagnePatrimoineSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idCampagnePatrimoines: ids.campagnePatrimoines as number[]
        })
      );
    }
  }

  if (ids?.campagnes) {
    if (!Array.isArray(ids.campagnes)) {
      actions.push(
        CampagneGeneratedActions.upsertOneCampagne({
          campagne: {
            idPatrimoine: patrimoine.idPatrimoine,
            idCampagne: ids.campagnes as number
          } as Campagne & any
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyCampagneSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idCampagnes: [ids.campagnes as number]
        })
      );
    } else {
      actions.push(
        CampagneGeneratedActions.upsertManyCampagnes({
          campagnes: (ids.campagnes as number[]).map(
            (idCampagne: number) => ({
              idPatrimoine: patrimoine.idPatrimoine,
              idCampagne
            })
          ) as Campagne[] & any[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyCampagneSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idCampagnes: ids.campagnes as number[]
        })
      );
    }
  }

  if (ids?.societeTerritoirePatrimoines) {
    if (!Array.isArray(ids.societeTerritoirePatrimoines)) {
      actions.push(
        SocieteTerritoirePatrimoineGeneratedActions.upsertOneSocieteTerritoirePatrimoine({
          societeTerritoirePatrimoine: {
            idPatrimoine: patrimoine.idPatrimoine,
            idSocieteTerritoirePatrimoine: ids.societeTerritoirePatrimoines as number
          } as SocieteTerritoirePatrimoine
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManySocieteTerritoirePatrimoineSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idSocieteTerritoirePatrimoines: [ids.societeTerritoirePatrimoines as number]
        })
      );
    } else {
      actions.push(
        SocieteTerritoirePatrimoineGeneratedActions.upsertManySocieteTerritoirePatrimoines({
          societeTerritoirePatrimoines: (ids.societeTerritoirePatrimoines as number[]).map(
            (idSocieteTerritoirePatrimoine: number) => ({
              idPatrimoine: patrimoine.idPatrimoine,
              idSocieteTerritoirePatrimoine
            })
          ) as SocieteTerritoirePatrimoine[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManySocieteTerritoirePatrimoineSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idSocieteTerritoirePatrimoines: ids.societeTerritoirePatrimoines as number[]
        })
      );
    }
  }

  if (ids?.societeTerritoires) {
    if (!Array.isArray(ids.societeTerritoires)) {
      actions.push(
        SocieteTerritoireGeneratedActions.upsertOneSocieteTerritoire({
          societeTerritoire: {
            idPatrimoine: patrimoine.idPatrimoine,
            idSocieteTerritoire: ids.societeTerritoires as number
          } as SocieteTerritoire & any
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManySocieteTerritoireSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idSocieteTerritoires: [ids.societeTerritoires as number]
        })
      );
    } else {
      actions.push(
        SocieteTerritoireGeneratedActions.upsertManySocieteTerritoires({
          societeTerritoires: (ids.societeTerritoires as number[]).map(
            (idSocieteTerritoire: number) => ({
              idPatrimoine: patrimoine.idPatrimoine,
              idSocieteTerritoire
            })
          ) as SocieteTerritoire[] & any[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManySocieteTerritoireSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idSocieteTerritoires: ids.societeTerritoires as number[]
        })
      );
    }
  }

  if (ids?.composantAttendus) {
    if (!Array.isArray(ids.composantAttendus)) {
      actions.push(
        ComposantAttenduGeneratedActions.upsertOneComposantAttendu({
          composantAttendu: {
            idPatrimoine: patrimoine.idPatrimoine,
            idComposantAttendu: ids.composantAttendus as number
          } as ComposantAttendu
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyComposantAttenduSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idComposantAttendus: [ids.composantAttendus as number]
        })
      );
    } else {
      actions.push(
        ComposantAttenduGeneratedActions.upsertManyComposantAttendus({
          composantAttendus: (ids.composantAttendus as number[]).map(
            (idComposantAttendu: number) => ({
              idPatrimoine: patrimoine.idPatrimoine,
              idComposantAttendu
            })
          ) as ComposantAttendu[]
        })
      );
      actions.push(
        PatrimoineGeneratedActions.addManyComposantAttenduSuccess({
          idPatrimoine: patrimoine.idPatrimoine,
          idComposantAttendus: ids.composantAttendus as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeletePatrimoineActions(patrimoine: PatrimoineEntityState): Action[] {
  const actions: Action[] = [PatrimoineGeneratedActions.deleteOnePatrimoineSuccess({ idPatrimoine: patrimoine.idPatrimoine })];

  if (patrimoine.societePatrimoineHierarchie) {
    actions.push(
      SocietePatrimoineHierarchieGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idSocietePatrimoineHierarchies: [patrimoine.societePatrimoineHierarchie as number]
      })
    );
  }

  if (patrimoine.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idSocietes: [patrimoine.societe as number]
      })
    );
  }

  if (patrimoine.userPatrimoines) {
    actions.push(
      UserPatrimoineGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idUserPatrimoines: patrimoine.userPatrimoines as number[]
      })
    );
  }

  if (patrimoine.users) {
    actions.push(
      UserGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idUsers: patrimoine.users as number[]
      })
    );
  }

  if (patrimoine.valeurPatrimoines) {
    actions.push(
      ValeurPatrimoineGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idValeurPatrimoines: patrimoine.valeurPatrimoines as number[]
      })
    );
  }

  if (patrimoine.valeurs) {
    actions.push(
      ValeurGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idValeurs: patrimoine.valeurs as number[]
      })
    );
  }

  if (patrimoine.ancetres) {
    actions.push(
      PatrimoineAncetreGeneratedActions.deleteManyDescendantPatrimoineSuccess({
        idDescendantPatrimoine: [patrimoine.idPatrimoine],
        idPatrimoineAncetres: patrimoine.ancetres as number[]
      })
    );
  }

  if (patrimoine.descendants) {
    actions.push(
      PatrimoineAncetreGeneratedActions.deleteManyAncetrePatrimoineSuccess({
        idAncetrePatrimoine: [patrimoine.idPatrimoine],
        idPatrimoineAncetres: patrimoine.descendants as number[]
      })
    );
  }

  if (patrimoine.espaces) {
    actions.push(
      EspaceGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idEspaces: patrimoine.espaces as number[]
      })
    );
  }

  if (patrimoine.campagnePatrimoines) {
    actions.push(
      CampagnePatrimoineGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idCampagnePatrimoines: patrimoine.campagnePatrimoines as number[]
      })
    );
  }

  if (patrimoine.campagnes) {
    actions.push(
      CampagneGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idCampagnes: patrimoine.campagnes as number[]
      })
    );
  }

  if (patrimoine.societeTerritoirePatrimoines) {
    actions.push(
      SocieteTerritoirePatrimoineGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idSocieteTerritoirePatrimoines: patrimoine.societeTerritoirePatrimoines as number[]
      })
    );
  }

  if (patrimoine.societeTerritoires) {
    actions.push(
      SocieteTerritoireGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idSocieteTerritoires: patrimoine.societeTerritoires as number[]
      })
    );
  }

  if (patrimoine.composantAttendus) {
    actions.push(
      ComposantAttenduGeneratedActions.deleteManyPatrimoineSuccess({
        idPatrimoines: [patrimoine.idPatrimoine],
        idComposantAttendus: patrimoine.composantAttendus as number[]
      })
    );
  }

  return actions;
}

export class GeneratedPatrimoineEffects {
  constructor(
    protected actions$: Actions,
    protected patrimoineApiService: PatrimoineApiService,
    protected store$: Store<AppState>
  ) {}

  getManyPatrimoines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineGeneratedActions.getManyPatrimoines),
      switchMap(({ params }) =>
        this.patrimoineApiService.getPatrimoines(params).pipe(
          map((patrimoines: Patrimoine[]) => {
            return PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines });
          }),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  getOnePatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineGeneratedActions.getOnePatrimoine),
      switchMap(idPatrimoine =>
        this.patrimoineApiService.getPatrimoine(idPatrimoine).pipe(
          map((patrimoine: Patrimoine) => {
            return PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines: [patrimoine] });
          }),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  upsertOnePatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineGeneratedActions.upsertOnePatrimoine),
      concatMap(
        ({
          patrimoine,
          ids
        }: {
          patrimoine: Partial<Patrimoine>;
          ids?: PatrimoineRelationsIds;
        }) => {
          if (patrimoine.idPatrimoine) {
            return this.patrimoineApiService.updatePatrimoine(patrimoine).pipe(
              map((patrimoineReturned: Patrimoine) => {
                return PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines: [patrimoineReturned] });
              }),
              catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
            );
          } else {
            return this.patrimoineApiService.addPatrimoine(patrimoine).pipe(
              mergeMap((patrimoineReturned: Patrimoine) => getDefaultAddPatrimoineActions(patrimoineReturned, ids)),
              catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOnePatrimoine$ = createEffect(() => {
    const selectPatrimoineState$ = this.store$.select(PatrimoineSelectors.selectPatrimoineState);
    return this.actions$.pipe(
      ofType(PatrimoineGeneratedActions.deleteOnePatrimoine),
      withLatestFrom(selectPatrimoineState$),
      concatMap(([{ idPatrimoine }, state]) =>
        this.patrimoineApiService.deletePatrimoine(idPatrimoine).pipe(
          mergeMap(_success => getDefaultDeletePatrimoineActions(state.entities[idPatrimoine] as PatrimoineEntityState)),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  normalizeManyPatrimoinesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert),
      concatMap(({ patrimoines }) => {
        const actions: Action[] = getActionsToNormalizePatrimoine(patrimoines, StoreActionType.upsert);
        return [getMultiAction(actions, '[Patrimoine] Normalization After Upsert Success')];
      })
    );
  });
}
