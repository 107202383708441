import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ComposantTemplate, ComposantTemplateEntityState } from '@get/api-interfaces';
import { ComposantTemplateRelationsIds } from '@get/store/ids-interfaces';

export const ComposantTemplateGeneratedActions = createActionGroup({
  source: 'Composant Template Generated',
  events: {
    'Get One Composant Template': props<{ idComposantTemplate: number; params?: any }>(),
    'Get Many Composant Templates': props<{ params: any }>(),
    'Add Many Actives Composant Templates': props<{ idComposantTemplates: number[] }>(),
    'Delete One Active Composant Template': props<{ idComposantTemplate: number }>(),
    'Clear Actives Composant Templates': emptyProps(),
    'Upsert One Composant Template': props<{ composantTemplate: Partial<ComposantTemplate>; ids?: ComposantTemplateRelationsIds; }>(),
    'Upsert Many Composant Templates': props<{ composantTemplates: Partial<ComposantTemplate>[]; ids?: ComposantTemplateRelationsIds; }>(),
    'Upsert Many Composant Templates Success': props<{ composantTemplates: ComposantTemplateEntityState[] }>(),
    'Delete One Composant Template': props<{ idComposantTemplate: number }>(),
    'Delete One Composant Template Success': props<{ idComposantTemplate: number }>(),
    'Normalize Many Composant Templates After Upsert': props<{ composantTemplates: ComposantTemplateEntityState[] }>(),
    'Composant Templates Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Composant Templates': emptyProps(),
    'Add Many Caracteristique Template Success': props<{ idComposantTemplate: number; idCaracteristiqueTemplates: number[] }>(),
    'Delete Many Caracteristique Template Success': props<{ idCaracteristiqueTemplates: number[]; idComposantTemplates: number[] }>(),
    'Add Many Societe Composant Success': props<{ idComposantTemplate: number; idSocieteComposants: number[] }>(),
    'Delete Many Societe Composant Success': props<{ idSocieteComposants: number[]; idComposantTemplates: number[] }>(),
    'Add Fichier Success': props<{ idComposantTemplate: number; idFichier: number }>(),
    'Delete Many Fichier Success': props<{ idFichiers: number[]; idComposantTemplates: number[] }>(),
    'Add Composant Groupe Success': props<{ idComposantTemplate: number; idComposantGroupe: number }>(),
    'Delete Many Composant Groupe Success': props<{ idComposantGroupes: number[]; idComposantTemplates: number[] }>()
  }
});
