import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteCaracteristiqueChoix, SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { CaracteristiqueChoixTemplate, CaracteristiqueChoixTemplateEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteCaracteristiqueChoixState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeCaracteristiqueChoixRelations: string[] = ['valeurs','societeCaracteristiques','fichiers','caracteristiqueChoixTemplates',];

export const { selectEntities, selectAll } = SocieteCaracteristiqueChoixState.adapter.getSelectors();

export const selectSocieteCaracteristiqueChoixState = createFeatureSelector<SocieteCaracteristiqueChoixState.IState>(SocieteCaracteristiqueChoixState.societeCaracteristiqueChoixFeatureKey);

export const selectIsLoadedSocieteCaracteristiqueChoix = createSelector(
  selectSocieteCaracteristiqueChoixState,
  (state: SocieteCaracteristiqueChoixState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteCaracteristiqueChoix = createSelector(
  selectSocieteCaracteristiqueChoixState,
  (state: SocieteCaracteristiqueChoixState.IState) => state.isLoading
);

export const selectIsReadySocieteCaracteristiqueChoix = createSelector(
  selectSocieteCaracteristiqueChoixState,
  (state: SocieteCaracteristiqueChoixState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteCaracteristiqueChoix = createSelector(
  selectSocieteCaracteristiqueChoixState,
  (state: SocieteCaracteristiqueChoixState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteCaracteristiqueChoicesEntities = createSelector(selectSocieteCaracteristiqueChoixState, selectEntities);

export const selectSocieteCaracteristiqueChoicesArray = createSelector(selectSocieteCaracteristiqueChoixState, selectAll);

export const selectIdSocieteCaracteristiqueChoicesActive = createSelector(
  selectSocieteCaracteristiqueChoixState,
  (state: SocieteCaracteristiqueChoixState.IState) => state.actives
);

const societeCaracteristiqueChoicesInObject = (societeCaracteristiqueChoices: Dictionary<SocieteCaracteristiqueChoixEntityState>) => ({ societeCaracteristiqueChoices })

const selectSocieteCaracteristiqueChoicesEntitiesDictionary = createSelector(selectSocieteCaracteristiqueChoicesEntities, societeCaracteristiqueChoicesInObject);

const selectAllSocieteCaracteristiqueChoicesObject = createSelector(selectSocieteCaracteristiqueChoicesEntities, societeCaracteristiqueChoices => {
  return hydrateAll({ societeCaracteristiqueChoices });
});

const selectOneSocieteCaracteristiqueChoixDictionary = (idSocieteCaracteristiqueChoix : number) =>
  createSelector(selectSocieteCaracteristiqueChoicesEntities, societeCaracteristiqueChoices => ({
    societeCaracteristiqueChoices: { [idSocieteCaracteristiqueChoix]: societeCaracteristiqueChoices[idSocieteCaracteristiqueChoix] }
  }));

const selectOneSocieteCaracteristiqueChoixDictionaryWithoutChild = (idSocieteCaracteristiqueChoix : number) =>
  createSelector(selectSocieteCaracteristiqueChoicesEntities, societeCaracteristiqueChoices => ({
    societeCaracteristiqueChoix: societeCaracteristiqueChoices[idSocieteCaracteristiqueChoix]
  }));

const selectActiveSocieteCaracteristiqueChoicesEntities = createSelector(
  selectIdSocieteCaracteristiqueChoicesActive,
  selectSocieteCaracteristiqueChoicesEntities,
  (actives: number[], societeCaracteristiqueChoices: Dictionary<SocieteCaracteristiqueChoixEntityState>) => getSocieteCaracteristiqueChoicesFromActives(actives, societeCaracteristiqueChoices)
);

function getSocieteCaracteristiqueChoicesFromActives(
  actives: number[],
  societeCaracteristiqueChoices: Dictionary<SocieteCaracteristiqueChoixEntityState>
): Dictionary<SocieteCaracteristiqueChoixEntityState> {
  return actives.reduce((acc, idActive) => {
    if (societeCaracteristiqueChoices[idActive]) {
      acc[idActive] = societeCaracteristiqueChoices[idActive];
    }
    return acc;
  }, {} as Dictionary<SocieteCaracteristiqueChoixEntityState>);
}

const selectAllSocieteCaracteristiqueChoicesSelectors: Dictionary<Selector> = {};
export function selectAllSocieteCaracteristiqueChoices(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteCaracteristiqueChoix>(
      schema,
      selectAllSocieteCaracteristiqueChoicesSelectors,
      selectSocieteCaracteristiqueChoicesEntitiesDictionary,
      getRelationSelectors,
      societeCaracteristiqueChoixRelations,
      hydrateAll,
      'societeCaracteristiqueChoix'
    );
  } else {
    return selectAllSocieteCaracteristiqueChoicesObject;
  }
}

export function selectAllSocieteCaracteristiqueChoicesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'societeCaracteristiqueChoices'
): Selector {
  return createSelector(selectAllSocieteCaracteristiqueChoices(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteCaracteristiqueChoixEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeCaracteristiqueChoices.length; i++) {
      res[customKey][result.societeCaracteristiqueChoices[i].idSocieteCaracteristiqueChoix] = result.societeCaracteristiqueChoices[i];
    }
    return res;
  });
}

export function selectOneSocieteCaracteristiqueChoix(
  schema: SelectSchema = {},
  idSocieteCaracteristiqueChoix: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteCaracteristiqueChoixDictionary(idSocieteCaracteristiqueChoix)];
  selectors.push(...getRelationSelectors(schema, societeCaracteristiqueChoixRelations, 'societeCaracteristiqueChoix'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteCaracteristiqueChoixDictionaryWithoutChild(idSocieteCaracteristiqueChoix);
  }
}

export function selectActiveSocieteCaracteristiqueChoices(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveSocieteCaracteristiqueChoicesEntities, societeCaracteristiqueChoices => ({
      societeCaracteristiqueChoices
    }))
  ];
  selectors.push(...getRelationSelectors(schema, societeCaracteristiqueChoixRelations, 'societeCaracteristiqueChoix'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  societeCaracteristiqueChoices: Dictionary<SocieteCaracteristiqueChoixEntityState>;
  societeCaracteristiques?: Dictionary<SocieteCaracteristiqueEntityState>;
  fichiers?: Dictionary<FichierEntityState>;
  caracteristiqueChoixTemplates?: Dictionary<CaracteristiqueChoixTemplateEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeCaracteristiqueChoices: (SocieteCaracteristiqueChoix | null)[] } {
  const {
    societeCaracteristiqueChoices,
    societeCaracteristiques,
    fichiers,
    caracteristiqueChoixTemplates,
    valeurs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeCaracteristiqueChoices: Object.keys(societeCaracteristiqueChoices).map(idSocieteCaracteristiqueChoix =>
      hydrate(
        societeCaracteristiqueChoices[idSocieteCaracteristiqueChoix] as SocieteCaracteristiqueChoixEntityState,
        societeCaracteristiques,
        fichiers,
        caracteristiqueChoixTemplates,
        valeurs
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeCaracteristiqueChoix: SocieteCaracteristiqueChoixEntityState | null } {
  const {
    societeCaracteristiqueChoices,
    societeCaracteristiques,
    fichiers,
    caracteristiqueChoixTemplates,
    valeurs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeCaracteristiqueChoix = Object.values(societeCaracteristiqueChoices)[0];
  return {
    societeCaracteristiqueChoix: hydrate(
      societeCaracteristiqueChoix as SocieteCaracteristiqueChoixEntityState,
      societeCaracteristiques,
      fichiers,
      caracteristiqueChoixTemplates,
      valeurs
    )
  };
}

function hydrate(
  societeCaracteristiqueChoix: SocieteCaracteristiqueChoixEntityState,
  societeCaracteristiqueEntities?: Dictionary<SocieteCaracteristiqueEntityState>,
  fichierEntities?: Dictionary<FichierEntityState>,
  caracteristiqueChoixTemplateEntities?: Dictionary<CaracteristiqueChoixTemplateEntityState>,
  valeurEntities?: Dictionary<ValeurEntityState>,
): SocieteCaracteristiqueChoix | null {
  if (!societeCaracteristiqueChoix) {
    return null;
  }

  const societeCaracteristiqueChoixHydrated: SocieteCaracteristiqueChoixEntityState = { ...societeCaracteristiqueChoix };
  if (societeCaracteristiqueEntities) {
    societeCaracteristiqueChoixHydrated.societeCaracteristique = societeCaracteristiqueEntities[societeCaracteristiqueChoix.societeCaracteristique as number] as SocieteCaracteristique;
  } else {
    delete societeCaracteristiqueChoixHydrated.societeCaracteristique;
  }
  if (fichierEntities) {
    societeCaracteristiqueChoixHydrated.fichier = fichierEntities[societeCaracteristiqueChoix.fichier as number] as Fichier;
  } else {
    delete societeCaracteristiqueChoixHydrated.fichier;
  }
  if (caracteristiqueChoixTemplateEntities) {
    societeCaracteristiqueChoixHydrated.caracteristiqueChoixTemplate = caracteristiqueChoixTemplateEntities[societeCaracteristiqueChoix.caracteristiqueChoixTemplate as number] as CaracteristiqueChoixTemplate;
  } else {
    delete societeCaracteristiqueChoixHydrated.caracteristiqueChoixTemplate;
  }

  if (valeurEntities) {
    societeCaracteristiqueChoixHydrated.valeurs = ((societeCaracteristiqueChoixHydrated.valeurs as number[]) || []).map(
      id => valeurEntities[id]
    ) as Valeur[];
  } else {
    delete societeCaracteristiqueChoixHydrated.valeurs;
  }

  return societeCaracteristiqueChoixHydrated as SocieteCaracteristiqueChoix;
}
