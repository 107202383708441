import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteTerritoireUser, SocieteTerritoireUserEntityState } from '@get/api-interfaces';
import { SocieteTerritoireUserRelationsIds } from '@get/store/ids-interfaces';

export const SocieteTerritoireUserGeneratedActions = createActionGroup({
  source: 'Societe Territoire User Generated',
  events: {
    'Get One Societe Territoire User': props<{ idSocieteTerritoireUser: number; params?: any }>(),
    'Get Many Societe Territoire Users': props<{ params: any }>(),
    'Add Many Actives Societe Territoire Users': props<{ idSocieteTerritoireUsers: number[] }>(),
    'Delete One Active Societe Territoire User': props<{ idSocieteTerritoireUser: number }>(),
    'Clear Actives Societe Territoire Users': emptyProps(),
    'Upsert One Societe Territoire User': props<{ societeTerritoireUser: Partial<SocieteTerritoireUser>; ids?: SocieteTerritoireUserRelationsIds; }>(),
    'Upsert Many Societe Territoire Users': props<{ societeTerritoireUsers: Partial<SocieteTerritoireUser>[]; ids?: SocieteTerritoireUserRelationsIds; }>(),
    'Upsert Many Societe Territoire Users Success': props<{ societeTerritoireUsers: SocieteTerritoireUserEntityState[] }>(),
    'Delete One Societe Territoire User': props<{ idSocieteTerritoireUser: number }>(),
    'Delete One Societe Territoire User Success': props<{ idSocieteTerritoireUser: number }>(),
    'Normalize Many Societe Territoire Users After Upsert': props<{ societeTerritoireUsers: SocieteTerritoireUserEntityState[] }>(),
    'Societe Territoire Users Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Territoire Users': emptyProps(),
    'Add Societe Territoire Success': props<{ idSocieteTerritoireUser: number; idSocieteTerritoire: number }>(),
    'Delete Many Societe Territoire Success': props<{ idSocieteTerritoires: number[]; idSocieteTerritoireUsers: number[] }>(),
    'Add User Success': props<{ idSocieteTerritoireUser: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idSocieteTerritoireUsers: number[] }>()
  }
});
