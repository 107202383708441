import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { UserSocieteProfil, UserSocieteProfilEntityState } from '@get/api-interfaces';
import { UserSocieteProfilApiService } from '@get/store/api-services';
import { UserSocieteProfilGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeUserSocieteProfil } from '@get/store/configs/normalization';
import { UserSocieteProfilSelectors } from '@get/store/selectors';
import { UserSocieteProfilRelationsIds } from '@get/store/ids-interfaces';
import { UserGeneratedActions } from '@get/store/actions';
import { SocieteProfilGeneratedActions } from '@get/store/actions';

export function getDefaultAddUserSocieteProfilActions(userSocieteProfil: UserSocieteProfilEntityState, ids?: UserSocieteProfilRelationsIds): Action[] {
  const actions: Action[] = [UserSocieteProfilGeneratedActions.normalizeManyUserSocieteProfilsAfterUpsert({ userSocieteProfils: [userSocieteProfil] })];

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyUserSocieteProfilSuccess({
        idUser: ids.user,
        idUserSocieteProfils: [userSocieteProfil.idUserSocieteProfil]
      })
    );
    actions.push(
      UserSocieteProfilGeneratedActions.addUserSuccess({
        idUserSocieteProfil: userSocieteProfil.idUserSocieteProfil,
        idUser: ids.user
      })
    );
  }

  if (ids?.societeProfil) {
    actions.push(
      SocieteProfilGeneratedActions.addManyUserSocieteProfilSuccess({
        idSocieteProfil: ids.societeProfil,
        idUserSocieteProfils: [userSocieteProfil.idUserSocieteProfil]
      })
    );
    actions.push(
      UserSocieteProfilGeneratedActions.addSocieteProfilSuccess({
        idUserSocieteProfil: userSocieteProfil.idUserSocieteProfil,
        idSocieteProfil: ids.societeProfil
      })
    );
  }

  return actions;
}

export function getDefaultDeleteUserSocieteProfilActions(userSocieteProfil: UserSocieteProfilEntityState): Action[] {
  const actions: Action[] = [UserSocieteProfilGeneratedActions.deleteOneUserSocieteProfilSuccess({ idUserSocieteProfil: userSocieteProfil.idUserSocieteProfil })];

  if (userSocieteProfil.user) {
    actions.push(
      UserGeneratedActions.deleteManyUserSocieteProfilSuccess({
        idUserSocieteProfils: [userSocieteProfil.idUserSocieteProfil],
        idUsers: [userSocieteProfil.user as number]
      })
    );
  }

  if (userSocieteProfil.societeProfil) {
    actions.push(
      SocieteProfilGeneratedActions.deleteManyUserSocieteProfilSuccess({
        idUserSocieteProfils: [userSocieteProfil.idUserSocieteProfil],
        idSocieteProfils: [userSocieteProfil.societeProfil as number]
      })
    );
  }

  return actions;
}

export class GeneratedUserSocieteProfilEffects {
  constructor(
    protected actions$: Actions,
    protected userSocieteProfilApiService: UserSocieteProfilApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUserSocieteProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserSocieteProfilGeneratedActions.getManyUserSocieteProfils),
      switchMap(({ params }) =>
        this.userSocieteProfilApiService.getUserSocieteProfils(params).pipe(
          map((userSocieteProfils: UserSocieteProfil[]) => {
            return UserSocieteProfilGeneratedActions.normalizeManyUserSocieteProfilsAfterUpsert({ userSocieteProfils });
          }),
          catchError(error => of(UserSocieteProfilGeneratedActions.userSocieteProfilsFailure({ error })))
        )
      )
    );
  });

  getOneUserSocieteProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserSocieteProfilGeneratedActions.getOneUserSocieteProfil),
      switchMap(idUserSocieteProfil =>
        this.userSocieteProfilApiService.getUserSocieteProfil(idUserSocieteProfil).pipe(
          map((userSocieteProfil: UserSocieteProfil) => {
            return UserSocieteProfilGeneratedActions.normalizeManyUserSocieteProfilsAfterUpsert({ userSocieteProfils: [userSocieteProfil] });
          }),
          catchError(error => of(UserSocieteProfilGeneratedActions.userSocieteProfilsFailure({ error })))
        )
      )
    );
  });

  upsertOneUserSocieteProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserSocieteProfilGeneratedActions.upsertOneUserSocieteProfil),
      concatMap(
        ({
          userSocieteProfil,
          ids
        }: {
          userSocieteProfil: Partial<UserSocieteProfil>;
          ids?: UserSocieteProfilRelationsIds;
        }) => {
          if (userSocieteProfil.idUserSocieteProfil) {
            return this.userSocieteProfilApiService.updateUserSocieteProfil(userSocieteProfil).pipe(
              map((userSocieteProfilReturned: UserSocieteProfil) => {
                return UserSocieteProfilGeneratedActions.normalizeManyUserSocieteProfilsAfterUpsert({ userSocieteProfils: [userSocieteProfilReturned] });
              }),
              catchError(error => of(UserSocieteProfilGeneratedActions.userSocieteProfilsFailure({ error })))
            );
          } else {
            return this.userSocieteProfilApiService.addUserSocieteProfil(userSocieteProfil).pipe(
              mergeMap((userSocieteProfilReturned: UserSocieteProfil) => getDefaultAddUserSocieteProfilActions(userSocieteProfilReturned, ids)),
              catchError(error => of(UserSocieteProfilGeneratedActions.userSocieteProfilsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneUserSocieteProfil$ = createEffect(() => {
    const selectUserSocieteProfilState$ = this.store$.select(UserSocieteProfilSelectors.selectUserSocieteProfilState);
    return this.actions$.pipe(
      ofType(UserSocieteProfilGeneratedActions.deleteOneUserSocieteProfil),
      withLatestFrom(selectUserSocieteProfilState$),
      concatMap(([{ idUserSocieteProfil }, state]) =>
        this.userSocieteProfilApiService.deleteUserSocieteProfil(idUserSocieteProfil).pipe(
          mergeMap(_success => getDefaultDeleteUserSocieteProfilActions(state.entities[idUserSocieteProfil] as UserSocieteProfilEntityState)),
          catchError(error => of(UserSocieteProfilGeneratedActions.userSocieteProfilsFailure({ error })))
        )
      )
    );
  });

  normalizeManyUserSocieteProfilsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserSocieteProfilGeneratedActions.normalizeManyUserSocieteProfilsAfterUpsert),
      concatMap(({ userSocieteProfils }) => {
        const actions: Action[] = getActionsToNormalizeUserSocieteProfil(userSocieteProfils, StoreActionType.upsert);
        return [getMultiAction(actions, '[UserSocieteProfil] Normalization After Upsert Success')];
      })
    );
  });
}
