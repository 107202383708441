import { RepositoryService } from '@get/services/repository';
import { Droit } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedDroitApiService {
  constructor(protected repo: RepositoryService) {}

  public getDroits(params?: any): Observable<Droit[]> {
    return this.repo.getData<Droit[]>('droit', params);
  }

  public getDroit(params: { idDroit: number; params?: any }): Observable<Droit> {
    return this.repo.getData<Droit>('droit/' + params.idDroit, params.params);
  }

  public addDroit(droit: Partial<Droit>): Observable<Droit> {
    return this.repo.create<Droit>('droit', droit);
  }

  public updateDroit(droit: Partial<Droit>): Observable<Droit> {
    return this.repo.update('droit', droit);
  }

  public deleteDroit(idDroit: number): Observable<number> {
    return this.repo.delete('droit/' + +idDroit);
  }
}

