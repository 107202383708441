import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CampagneSocieteCaracteristique, CampagneSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { Campagne, CampagneEntityState } from '@get/api-interfaces';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { CampagneSocieteCaracteristiqueState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const campagneSocieteCaracteristiqueRelations: string[] = ['campagnes','societeCaracteristiques',];

export const { selectEntities, selectAll } = CampagneSocieteCaracteristiqueState.adapter.getSelectors();

export const selectCampagneSocieteCaracteristiqueState = createFeatureSelector<CampagneSocieteCaracteristiqueState.IState>(CampagneSocieteCaracteristiqueState.campagneSocieteCaracteristiqueFeatureKey);

export const selectIsLoadedCampagneSocieteCaracteristique = createSelector(
  selectCampagneSocieteCaracteristiqueState,
  (state: CampagneSocieteCaracteristiqueState.IState) => state.isLoaded
);

export const selectIsLoadingCampagneSocieteCaracteristique = createSelector(
  selectCampagneSocieteCaracteristiqueState,
  (state: CampagneSocieteCaracteristiqueState.IState) => state.isLoading
);

export const selectIsReadyCampagneSocieteCaracteristique = createSelector(
  selectCampagneSocieteCaracteristiqueState,
  (state: CampagneSocieteCaracteristiqueState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCampagneSocieteCaracteristique = createSelector(
  selectCampagneSocieteCaracteristiqueState,
  (state: CampagneSocieteCaracteristiqueState.IState) => state.isLoaded && !state.isLoading
);

export const selectCampagneSocieteCaracteristiquesEntities = createSelector(selectCampagneSocieteCaracteristiqueState, selectEntities);

export const selectCampagneSocieteCaracteristiquesArray = createSelector(selectCampagneSocieteCaracteristiqueState, selectAll);

export const selectIdCampagneSocieteCaracteristiquesActive = createSelector(
  selectCampagneSocieteCaracteristiqueState,
  (state: CampagneSocieteCaracteristiqueState.IState) => state.actives
);

const campagneSocieteCaracteristiquesInObject = (campagneSocieteCaracteristiques: Dictionary<CampagneSocieteCaracteristiqueEntityState>) => ({ campagneSocieteCaracteristiques })

const selectCampagneSocieteCaracteristiquesEntitiesDictionary = createSelector(selectCampagneSocieteCaracteristiquesEntities, campagneSocieteCaracteristiquesInObject);

const selectAllCampagneSocieteCaracteristiquesObject = createSelector(selectCampagneSocieteCaracteristiquesEntities, campagneSocieteCaracteristiques => {
  return hydrateAll({ campagneSocieteCaracteristiques });
});

const selectOneCampagneSocieteCaracteristiqueDictionary = (idCampagneSocieteCaracteristique : number) =>
  createSelector(selectCampagneSocieteCaracteristiquesEntities, campagneSocieteCaracteristiques => ({
    campagneSocieteCaracteristiques: { [idCampagneSocieteCaracteristique]: campagneSocieteCaracteristiques[idCampagneSocieteCaracteristique] }
  }));

const selectOneCampagneSocieteCaracteristiqueDictionaryWithoutChild = (idCampagneSocieteCaracteristique : number) =>
  createSelector(selectCampagneSocieteCaracteristiquesEntities, campagneSocieteCaracteristiques => ({
    campagneSocieteCaracteristique: campagneSocieteCaracteristiques[idCampagneSocieteCaracteristique]
  }));

const selectActiveCampagneSocieteCaracteristiquesEntities = createSelector(
  selectIdCampagneSocieteCaracteristiquesActive,
  selectCampagneSocieteCaracteristiquesEntities,
  (actives: number[], campagneSocieteCaracteristiques: Dictionary<CampagneSocieteCaracteristiqueEntityState>) => getCampagneSocieteCaracteristiquesFromActives(actives, campagneSocieteCaracteristiques)
);

function getCampagneSocieteCaracteristiquesFromActives(
  actives: number[],
  campagneSocieteCaracteristiques: Dictionary<CampagneSocieteCaracteristiqueEntityState>
): Dictionary<CampagneSocieteCaracteristiqueEntityState> {
  return actives.reduce((acc, idActive) => {
    if (campagneSocieteCaracteristiques[idActive]) {
      acc[idActive] = campagneSocieteCaracteristiques[idActive];
    }
    return acc;
  }, {} as Dictionary<CampagneSocieteCaracteristiqueEntityState>);
}

const selectAllCampagneSocieteCaracteristiquesSelectors: Dictionary<Selector> = {};
export function selectAllCampagneSocieteCaracteristiques(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CampagneSocieteCaracteristique>(
      schema,
      selectAllCampagneSocieteCaracteristiquesSelectors,
      selectCampagneSocieteCaracteristiquesEntitiesDictionary,
      getRelationSelectors,
      campagneSocieteCaracteristiqueRelations,
      hydrateAll,
      'campagneSocieteCaracteristique'
    );
  } else {
    return selectAllCampagneSocieteCaracteristiquesObject;
  }
}

export function selectAllCampagneSocieteCaracteristiquesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'campagneSocieteCaracteristiques'
): Selector {
  return createSelector(selectAllCampagneSocieteCaracteristiques(schema), result => {
    const res = { [customKey]: {} as Dictionary<CampagneSocieteCaracteristiqueEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.campagneSocieteCaracteristiques.length; i++) {
      res[customKey][result.campagneSocieteCaracteristiques[i].idCampagneSocieteCaracteristique] = result.campagneSocieteCaracteristiques[i];
    }
    return res;
  });
}

export function selectOneCampagneSocieteCaracteristique(
  schema: SelectSchema = {},
  idCampagneSocieteCaracteristique: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneCampagneSocieteCaracteristiqueDictionary(idCampagneSocieteCaracteristique)];
  selectors.push(...getRelationSelectors(schema, campagneSocieteCaracteristiqueRelations, 'campagneSocieteCaracteristique'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCampagneSocieteCaracteristiqueDictionaryWithoutChild(idCampagneSocieteCaracteristique);
  }
}

export function selectActiveCampagneSocieteCaracteristiques(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCampagneSocieteCaracteristiquesEntities, campagneSocieteCaracteristiques => ({
      campagneSocieteCaracteristiques
    }))
  ];
  selectors.push(...getRelationSelectors(schema, campagneSocieteCaracteristiqueRelations, 'campagneSocieteCaracteristique'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  campagneSocieteCaracteristiques: Dictionary<CampagneSocieteCaracteristiqueEntityState>;
  campagnes?: Dictionary<CampagneEntityState>;
  societeCaracteristiques?: Dictionary<SocieteCaracteristiqueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { campagneSocieteCaracteristiques: (CampagneSocieteCaracteristique | null)[] } {
  const {
    campagneSocieteCaracteristiques,
    campagnes,
    societeCaracteristiques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    campagneSocieteCaracteristiques: Object.keys(campagneSocieteCaracteristiques).map(idCampagneSocieteCaracteristique =>
      hydrate(
        campagneSocieteCaracteristiques[idCampagneSocieteCaracteristique] as CampagneSocieteCaracteristiqueEntityState,
        campagnes,
        societeCaracteristiques
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { campagneSocieteCaracteristique: CampagneSocieteCaracteristiqueEntityState | null } {
  const {
    campagneSocieteCaracteristiques,
    campagnes,
    societeCaracteristiques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const campagneSocieteCaracteristique = Object.values(campagneSocieteCaracteristiques)[0];
  return {
    campagneSocieteCaracteristique: hydrate(
      campagneSocieteCaracteristique as CampagneSocieteCaracteristiqueEntityState,
      campagnes,
      societeCaracteristiques
    )
  };
}

function hydrate(
  campagneSocieteCaracteristique: CampagneSocieteCaracteristiqueEntityState,
  campagneEntities?: Dictionary<CampagneEntityState>,
  societeCaracteristiqueEntities?: Dictionary<SocieteCaracteristiqueEntityState>,
): CampagneSocieteCaracteristique | null {
  if (!campagneSocieteCaracteristique) {
    return null;
  }

  const campagneSocieteCaracteristiqueHydrated: CampagneSocieteCaracteristiqueEntityState = { ...campagneSocieteCaracteristique };
  if (campagneEntities) {
    campagneSocieteCaracteristiqueHydrated.campagne = campagneEntities[campagneSocieteCaracteristique.campagne as number] as Campagne;
  } else {
    delete campagneSocieteCaracteristiqueHydrated.campagne;
  }
  if (societeCaracteristiqueEntities) {
    campagneSocieteCaracteristiqueHydrated.societeCaracteristique = societeCaracteristiqueEntities[campagneSocieteCaracteristique.societeCaracteristique as number] as SocieteCaracteristique;
  } else {
    delete campagneSocieteCaracteristiqueHydrated.societeCaracteristique;
  }

  return campagneSocieteCaracteristiqueHydrated as CampagneSocieteCaracteristique;
}
