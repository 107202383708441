import { createEntityAdapter } from '@ngrx/entity';
import { SocieteEspaceFamilleEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<SocieteEspaceFamilleEntityState>;

export const adapter = createEntityAdapter<SocieteEspaceFamilleEntityState>({
  selectId: o => o.idSocieteEspaceFamille
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const societeEspaceFamilleFeatureKey = 'societeEspaceFamille';
