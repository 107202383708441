import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ComposantGroupe } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { ComposantGroupeRelationsIds } from '@get/store/ids-interfaces';
import { ComposantGroupeGeneratedActions } from '@get/store/actions';
import { ComposantGroupeSelectors } from '@get/store/selectors';
import { catchApiActions } from '@get/utils';
import { getIsReadySelectors, Selector, SelectSchema } from '@get/utils';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedComposantGroupeService {
  constructor(protected store$: Store<AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(ComposantGroupeSelectors.selectIsLoadedComposantGroupe));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(ComposantGroupeSelectors.selectIsLoadingComposantGroupe));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [
      ComposantGroupeSelectors.selectIsReadyAndLoadedComposantGroupe as Selector
    ].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllComposantGroupes(schema: SelectSchema = {}): Observable<ComposantGroupe[]> {
    return this.store$.pipe(select(ComposantGroupeSelectors.selectAllComposantGroupes(schema))).pipe(
      switchMap(({ composantGroupes }: { composantGroupes: ComposantGroupe[] }) => {
        return this.getReady(schema).pipe(mapTo(composantGroupes));
      })
    );
  }

  public selectOneComposantGroupe(
    idComposantGroupe: number,
    schema: SelectSchema = {}
  ): Observable<ComposantGroupe> {
    return this.store$
      .pipe(select(ComposantGroupeSelectors.selectOneComposantGroupe(schema, idComposantGroupe)))
      .pipe(
        switchMap(({ composantGroupe }: { composantGroupe: ComposantGroupe }) => {
          return this.getReady(schema).pipe(mapTo(composantGroupe));
        })
      );
  }

  public selectAllActiveComposantGroupes(schema: SelectSchema = {}): Observable<ComposantGroupe[]> {
    return this.store$.pipe(select(ComposantGroupeSelectors.selectActiveComposantGroupes(schema))).pipe(
      switchMap(({ composantGroupes }: { composantGroupes: ComposantGroupe[] }) => {
        return this.getReady(schema).pipe(mapTo(composantGroupes));
      })
    );
  }

  public selectIdComposantGroupesActive(): Observable<number[]> {
    return this.store$.pipe(select(ComposantGroupeSelectors.selectIdComposantGroupesActive)).pipe(
      switchMap((idComposantGroupes: number[]) => {
        return this.getReady().pipe(mapTo(idComposantGroupes));
      })
    );
  }

  public getOneComposantGroupe(
    idComposantGroupe: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<ComposantGroupe> {
    this.store$.dispatch(ComposantGroupeGeneratedActions.getOneComposantGroupe({ idComposantGroupe, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGroupeGeneratedActions.normalizeManyComposantGroupesAfterUpsert,
        ComposantGroupeGeneratedActions.composantGroupesFailure,
        true
      );
    }
  }

  public getManyComposantGroupes(
    params: any = {},
    getResult?: boolean
  ): void | Observable<ComposantGroupe[]> {
    this.store$.dispatch(ComposantGroupeGeneratedActions.getManyComposantGroupes({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGroupeGeneratedActions.normalizeManyComposantGroupesAfterUpsert,
        ComposantGroupeGeneratedActions.composantGroupesFailure
      );
    }
  }

  public upsertOneComposantGroupe(
    composantGroupe: Partial<ComposantGroupe>,
    ids: ComposantGroupeRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ComposantGroupe> {
    this.store$.dispatch(ComposantGroupeGeneratedActions.upsertOneComposantGroupe({ composantGroupe, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGroupeGeneratedActions.normalizeManyComposantGroupesAfterUpsert,
        ComposantGroupeGeneratedActions.composantGroupesFailure,
        true
      );
    }
  }

  public deleteOneComposantGroupe(
    idComposantGroupe: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(ComposantGroupeGeneratedActions.deleteOneComposantGroupe({ idComposantGroupe }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGroupeGeneratedActions.deleteOneComposantGroupeSuccess,
        ComposantGroupeGeneratedActions.composantGroupesFailure
      );
    }
  }

  public setActiveComposantGroupes(idComposantGroupes: number[]): void {
    this.store$.dispatch(ComposantGroupeGeneratedActions.clearActivesComposantGroupes());
    this.store$.dispatch(ComposantGroupeGeneratedActions.addManyActivesComposantGroupes({ idComposantGroupes }));
  }
}
