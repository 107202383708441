import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Organisation, OrganisationEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { User, UserEntityState } from '@get/api-interfaces';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { OrganisationAzureLogin, OrganisationAzureLoginEntityState } from '@get/api-interfaces';
import { OrganisationNewsletter, OrganisationNewsletterEntityState } from '@get/api-interfaces';
import { Newsletter, NewsletterEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { OrganisationState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const organisationRelations: string[] = ['societes','users','fichiers','organisationAzureLogins','organisationNewsletters','newsletters',];

export const { selectEntities, selectAll } = OrganisationState.adapter.getSelectors();

export const selectOrganisationState = createFeatureSelector<OrganisationState.IState>(OrganisationState.organisationFeatureKey);

export const selectIsLoadedOrganisation = createSelector(
  selectOrganisationState,
  (state: OrganisationState.IState) => state.isLoaded
);

export const selectIsLoadingOrganisation = createSelector(
  selectOrganisationState,
  (state: OrganisationState.IState) => state.isLoading
);

export const selectIsReadyOrganisation = createSelector(
  selectOrganisationState,
  (state: OrganisationState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganisation = createSelector(
  selectOrganisationState,
  (state: OrganisationState.IState) => state.isLoaded && !state.isLoading
);

export const selectOrganisationsEntities = createSelector(selectOrganisationState, selectEntities);

export const selectOrganisationsArray = createSelector(selectOrganisationState, selectAll);

export const selectIdOrganisationsActive = createSelector(
  selectOrganisationState,
  (state: OrganisationState.IState) => state.actives
);

const organisationsInObject = (organisations: Dictionary<OrganisationEntityState>) => ({ organisations })

const selectOrganisationsEntitiesDictionary = createSelector(selectOrganisationsEntities, organisationsInObject);

const selectAllOrganisationsObject = createSelector(selectOrganisationsEntities, organisations => {
  return hydrateAll({ organisations });
});

const selectOneOrganisationDictionary = (idOrganisation : number) =>
  createSelector(selectOrganisationsEntities, organisations => ({
    organisations: { [idOrganisation]: organisations[idOrganisation] }
  }));

const selectOneOrganisationDictionaryWithoutChild = (idOrganisation : number) =>
  createSelector(selectOrganisationsEntities, organisations => ({
    organisation: organisations[idOrganisation]
  }));

const selectActiveOrganisationsEntities = createSelector(
  selectIdOrganisationsActive,
  selectOrganisationsEntities,
  (actives: number[], organisations: Dictionary<OrganisationEntityState>) => getOrganisationsFromActives(actives, organisations)
);

function getOrganisationsFromActives(
  actives: number[],
  organisations: Dictionary<OrganisationEntityState>
): Dictionary<OrganisationEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organisations[idActive]) {
      acc[idActive] = organisations[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganisationEntityState>);
}

const selectAllOrganisationsSelectors: Dictionary<Selector> = {};
export function selectAllOrganisations(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Organisation>(
      schema,
      selectAllOrganisationsSelectors,
      selectOrganisationsEntitiesDictionary,
      getRelationSelectors,
      organisationRelations,
      hydrateAll,
      'organisation'
    );
  } else {
    return selectAllOrganisationsObject;
  }
}

export function selectAllOrganisationsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organisations'
): Selector {
  return createSelector(selectAllOrganisations(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganisationEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organisations.length; i++) {
      res[customKey][result.organisations[i].idOrganisation] = result.organisations[i];
    }
    return res;
  });
}

export function selectOneOrganisation(
  schema: SelectSchema = {},
  idOrganisation: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneOrganisationDictionary(idOrganisation)];
  selectors.push(...getRelationSelectors(schema, organisationRelations, 'organisation'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganisationDictionaryWithoutChild(idOrganisation);
  }
}

export function selectActiveOrganisations(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganisationsEntities, organisations => ({
      organisations
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organisationRelations, 'organisation'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organisations: Dictionary<OrganisationEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  users?: Dictionary<UserEntityState>;
  fichiers?: Dictionary<FichierEntityState>;
  organisationAzureLogins?: Dictionary<OrganisationAzureLoginEntityState>;
  organisationNewsletters?: Dictionary<OrganisationNewsletterEntityState>;
  newsletters?: Dictionary<NewsletterEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organisations: (Organisation | null)[] } {
  const {
    organisations,
    societes,
    users,
    fichiers,
    organisationAzureLogins,
    organisationNewsletters,
    newsletters
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organisations: Object.keys(organisations).map(idOrganisation =>
      hydrate(
        organisations[idOrganisation] as OrganisationEntityState,
        societes,
        users,
        fichiers,
        organisationAzureLogins,
        organisationNewsletters,
        newsletters
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organisation: OrganisationEntityState | null } {
  const {
    organisations,
    societes,
    users,
    fichiers,
    organisationAzureLogins,
    organisationNewsletters,
    newsletters
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organisation = Object.values(organisations)[0];
  return {
    organisation: hydrate(
      organisation as OrganisationEntityState,
      societes,
      users,
      fichiers,
      organisationAzureLogins,
      organisationNewsletters,
      newsletters
    )
  };
}

function hydrate(
  organisation: OrganisationEntityState,
  societeEntities?: Dictionary<SocieteEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  fichierEntities?: Dictionary<FichierEntityState>,
  organisationAzureLoginEntities?: Dictionary<OrganisationAzureLoginEntityState>,
  organisationNewsletterEntities?: Dictionary<OrganisationNewsletterEntityState>,
  newsletterEntities?: Dictionary<NewsletterEntityState>,
): Organisation | null {
  if (!organisation) {
    return null;
  }

  const organisationHydrated: OrganisationEntityState = { ...organisation };

  if (societeEntities) {
    organisationHydrated.societes = ((organisationHydrated.societes as number[]) || []).map(
      id => societeEntities[id]
    ) as Societe[];
  } else {
    delete organisationHydrated.societes;
  }

  if (userEntities) {
    organisationHydrated.users = ((organisationHydrated.users as number[]) || []).map(
      id => userEntities[id]
    ) as User[];
  } else {
    delete organisationHydrated.users;
  }

  if (fichierEntities) {
    organisationHydrated.fichiers = ((organisationHydrated.fichiers as number[]) || []).map(
      id => fichierEntities[id]
    ) as Fichier[];
  } else {
    delete organisationHydrated.fichiers;
  }

  if (organisationAzureLoginEntities) {
    organisationHydrated.organisationAzureLogins = ((organisationHydrated.organisationAzureLogins as number[]) || []).map(
      id => organisationAzureLoginEntities[id]
    ) as OrganisationAzureLogin[];
  } else {
    delete organisationHydrated.organisationAzureLogins;
  }

  if (organisationNewsletterEntities) {
    organisationHydrated.organisationNewsletters = ((organisationHydrated.organisationNewsletters as number[]) || []).map(
      id => organisationNewsletterEntities[id]
    ) as OrganisationNewsletter[];
  } else {
    delete organisationHydrated.organisationNewsletters;
  }

  if (newsletterEntities) {
    organisationHydrated.newsletters = ((organisationHydrated.newsletters as number[]) || []).map(
      id => newsletterEntities[id]
    ) as Newsletter[];
  } else {
    delete organisationHydrated.newsletters;
  }

  return organisationHydrated as Organisation;
}
