import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CampagneUserGeneratedActions } from '@get/store/actions';
import { CampagneUserState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { CampagneUserEntityState } from '@get/api-interfaces';

export const campagneUserReducersGeneratedFunctions: ReducerTypes<CampagneUserState.IState, readonly ActionCreator[]>[] = [
  on(CampagneUserGeneratedActions.getOneCampagneUser, (state: CampagneUserState.IState) => setLoadingsState(state, true)),
  on(CampagneUserGeneratedActions.getManyCampagneUsers, (state: CampagneUserState.IState) => setLoadingsState(state, true)),
  on(CampagneUserGeneratedActions.upsertOneCampagneUser, (state: CampagneUserState.IState) => setLoadingsState(state, true)),

  on(CampagneUserGeneratedActions.upsertManyCampagneUsersSuccess, (state: CampagneUserState.IState, { campagneUsers }) =>
    CampagneUserState.adapter.upsertMany(campagneUsers, setLoadingsState(state, false))
  ),
  on(CampagneUserGeneratedActions.deleteOneCampagneUser, (state: CampagneUserState.IState) => setLoadingsState(state, true)),
  on(CampagneUserGeneratedActions.deleteOneCampagneUserSuccess, (state: CampagneUserState.IState, { idCampagneUser }) =>
    CampagneUserState.adapter.removeOne(idCampagneUser, setLoadingsState(state, false))
  ),
  on(CampagneUserGeneratedActions.clearActivesCampagneUsers, (state: CampagneUserState.IState) => ({ ...state, actives: [] })),
  on(CampagneUserGeneratedActions.addManyActivesCampagneUsers, (state: CampagneUserState.IState, { idCampagneUsers }) => ({
    ...state,
    actives: state.actives.concat(idCampagneUsers)
  })),
  on(CampagneUserGeneratedActions.deleteOneActiveCampagneUser, (state: CampagneUserState.IState, { idCampagneUser }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCampagneUser)
  })),

  on(CampagneUserGeneratedActions.clearCampagneUsers, () => CampagneUserState.initialState),

  on(
    CampagneUserGeneratedActions.addUserSuccess,
    (state: CampagneUserState.IState, { idCampagneUser, idUser }) => {
      if (!state.entities[idCampagneUser]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagneUser]: {
            ...state.entities[idCampagneUser],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    CampagneUserGeneratedActions.deleteManyUserSuccess,
    (state: CampagneUserState.IState, { idUsers, idCampagneUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagneUsers.reduce((entities, idCampagneUser) => {
            if (!state.entities[idCampagneUser]?.user) {
              return entities;
            }
            entities[idCampagneUser] = {
              ...state.entities[idCampagneUser],
              user: idUsers.some(
                (idUser: number) => idUser === state.entities[idCampagneUser]?.user
              )
                ? undefined
                : state.entities[idCampagneUser]?.user
            } as CampagneUserEntityState;
            return entities;
          }, {} as Dictionary<CampagneUserEntityState>)
        }
      };
    }
  ),

  on(
    CampagneUserGeneratedActions.addCampagneSuccess,
    (state: CampagneUserState.IState, { idCampagneUser, idCampagne }) => {
      if (!state.entities[idCampagneUser]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagneUser]: {
            ...state.entities[idCampagneUser],
            campagne: idCampagne
          }
        }
      };
    }
  ),

  on(
    CampagneUserGeneratedActions.deleteManyCampagneSuccess,
    (state: CampagneUserState.IState, { idCampagnes, idCampagneUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagneUsers.reduce((entities, idCampagneUser) => {
            if (!state.entities[idCampagneUser]?.campagne) {
              return entities;
            }
            entities[idCampagneUser] = {
              ...state.entities[idCampagneUser],
              campagne: idCampagnes.some(
                (idCampagne: number) => idCampagne === state.entities[idCampagneUser]?.campagne
              )
                ? undefined
                : state.entities[idCampagneUser]?.campagne
            } as CampagneUserEntityState;
            return entities;
          }, {} as Dictionary<CampagneUserEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: CampagneUserState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CampagneUserState.IState {
  return { ...state, isLoaded, isLoading };
}
