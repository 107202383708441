import { RepositoryService } from '@get/services/repository';
import { Societe } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocietes(params?: any): Observable<Societe[]> {
    return this.repo.getData<Societe[]>('societe', params);
  }

  public getSociete(params: { idSociete: number; params?: any }): Observable<Societe> {
    return this.repo.getData<Societe>('societe/' + params.idSociete, params.params);
  }

  public addSociete(societe: Partial<Societe>): Observable<Societe> {
    return this.repo.create<Societe>('societe', societe);
  }

  public updateSociete(societe: Partial<Societe>): Observable<Societe> {
    return this.repo.update('societe', societe);
  }

  public deleteSociete(idSociete: number): Observable<number> {
    return this.repo.delete('societe/' + +idSociete);
  }
}

