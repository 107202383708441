import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristique, SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSynthese, SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeConfigAnalyseSyntheseSocieteCaracteristiqueRelations: string[] = ['societeConfigAnalyseSyntheses','societeCaracteristiques',];

export const { selectEntities, selectAll } = SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.adapter.getSelectors();

export const selectSocieteConfigAnalyseSyntheseSocieteCaracteristiqueState = createFeatureSelector<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState>(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.societeConfigAnalyseSyntheseSocieteCaracteristiqueFeatureKey);

export const selectIsLoadedSocieteConfigAnalyseSyntheseSocieteCaracteristique = createSelector(
  selectSocieteConfigAnalyseSyntheseSocieteCaracteristiqueState,
  (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState) => state.isLoaded
);

export const selectIsLoadingSocieteConfigAnalyseSyntheseSocieteCaracteristique = createSelector(
  selectSocieteConfigAnalyseSyntheseSocieteCaracteristiqueState,
  (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState) => state.isLoading
);

export const selectIsReadySocieteConfigAnalyseSyntheseSocieteCaracteristique = createSelector(
  selectSocieteConfigAnalyseSyntheseSocieteCaracteristiqueState,
  (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSocieteConfigAnalyseSyntheseSocieteCaracteristique = createSelector(
  selectSocieteConfigAnalyseSyntheseSocieteCaracteristiqueState,
  (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocieteConfigAnalyseSyntheseSocieteCaracteristiquesEntities = createSelector(selectSocieteConfigAnalyseSyntheseSocieteCaracteristiqueState, selectEntities);

export const selectSocieteConfigAnalyseSyntheseSocieteCaracteristiquesArray = createSelector(selectSocieteConfigAnalyseSyntheseSocieteCaracteristiqueState, selectAll);

export const selectIdSocieteConfigAnalyseSyntheseSocieteCaracteristiquesActive = createSelector(
  selectSocieteConfigAnalyseSyntheseSocieteCaracteristiqueState,
  (state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState) => state.actives
);

const societeConfigAnalyseSyntheseSocieteCaracteristiquesInObject = (societeConfigAnalyseSyntheseSocieteCaracteristiques: Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState>) => ({ societeConfigAnalyseSyntheseSocieteCaracteristiques })

const selectSocieteConfigAnalyseSyntheseSocieteCaracteristiquesEntitiesDictionary = createSelector(selectSocieteConfigAnalyseSyntheseSocieteCaracteristiquesEntities, societeConfigAnalyseSyntheseSocieteCaracteristiquesInObject);

const selectAllSocieteConfigAnalyseSyntheseSocieteCaracteristiquesObject = createSelector(selectSocieteConfigAnalyseSyntheseSocieteCaracteristiquesEntities, societeConfigAnalyseSyntheseSocieteCaracteristiques => {
  return hydrateAll({ societeConfigAnalyseSyntheseSocieteCaracteristiques });
});

const selectOneSocieteConfigAnalyseSyntheseSocieteCaracteristiqueDictionary = (idSocieteConfigAnalyseSyntheseSocieteCaracteristique : number) =>
  createSelector(selectSocieteConfigAnalyseSyntheseSocieteCaracteristiquesEntities, societeConfigAnalyseSyntheseSocieteCaracteristiques => ({
    societeConfigAnalyseSyntheseSocieteCaracteristiques: { [idSocieteConfigAnalyseSyntheseSocieteCaracteristique]: societeConfigAnalyseSyntheseSocieteCaracteristiques[idSocieteConfigAnalyseSyntheseSocieteCaracteristique] }
  }));

const selectOneSocieteConfigAnalyseSyntheseSocieteCaracteristiqueDictionaryWithoutChild = (idSocieteConfigAnalyseSyntheseSocieteCaracteristique : number) =>
  createSelector(selectSocieteConfigAnalyseSyntheseSocieteCaracteristiquesEntities, societeConfigAnalyseSyntheseSocieteCaracteristiques => ({
    societeConfigAnalyseSyntheseSocieteCaracteristique: societeConfigAnalyseSyntheseSocieteCaracteristiques[idSocieteConfigAnalyseSyntheseSocieteCaracteristique]
  }));

const selectActiveSocieteConfigAnalyseSyntheseSocieteCaracteristiquesEntities = createSelector(
  selectIdSocieteConfigAnalyseSyntheseSocieteCaracteristiquesActive,
  selectSocieteConfigAnalyseSyntheseSocieteCaracteristiquesEntities,
  (actives: number[], societeConfigAnalyseSyntheseSocieteCaracteristiques: Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState>) => getSocieteConfigAnalyseSyntheseSocieteCaracteristiquesFromActives(actives, societeConfigAnalyseSyntheseSocieteCaracteristiques)
);

function getSocieteConfigAnalyseSyntheseSocieteCaracteristiquesFromActives(
  actives: number[],
  societeConfigAnalyseSyntheseSocieteCaracteristiques: Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState>
): Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState> {
  return actives.reduce((acc, idActive) => {
    if (societeConfigAnalyseSyntheseSocieteCaracteristiques[idActive]) {
      acc[idActive] = societeConfigAnalyseSyntheseSocieteCaracteristiques[idActive];
    }
    return acc;
  }, {} as Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState>);
}

const selectAllSocieteConfigAnalyseSyntheseSocieteCaracteristiquesSelectors: Dictionary<Selector> = {};
export function selectAllSocieteConfigAnalyseSyntheseSocieteCaracteristiques(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteConfigAnalyseSyntheseSocieteCaracteristique>(
      schema,
      selectAllSocieteConfigAnalyseSyntheseSocieteCaracteristiquesSelectors,
      selectSocieteConfigAnalyseSyntheseSocieteCaracteristiquesEntitiesDictionary,
      getRelationSelectors,
      societeConfigAnalyseSyntheseSocieteCaracteristiqueRelations,
      hydrateAll,
      'societeConfigAnalyseSyntheseSocieteCaracteristique'
    );
  } else {
    return selectAllSocieteConfigAnalyseSyntheseSocieteCaracteristiquesObject;
  }
}

export function selectAllSocieteConfigAnalyseSyntheseSocieteCaracteristiquesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'societeConfigAnalyseSyntheseSocieteCaracteristiques'
): Selector {
  return createSelector(selectAllSocieteConfigAnalyseSyntheseSocieteCaracteristiques(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societeConfigAnalyseSyntheseSocieteCaracteristiques.length; i++) {
      res[customKey][result.societeConfigAnalyseSyntheseSocieteCaracteristiques[i].idSocieteConfigAnalyseSyntheseSocieteCaracteristique] = result.societeConfigAnalyseSyntheseSocieteCaracteristiques[i];
    }
    return res;
  });
}

export function selectOneSocieteConfigAnalyseSyntheseSocieteCaracteristique(
  schema: SelectSchema = {},
  idSocieteConfigAnalyseSyntheseSocieteCaracteristique: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteConfigAnalyseSyntheseSocieteCaracteristiqueDictionary(idSocieteConfigAnalyseSyntheseSocieteCaracteristique)];
  selectors.push(...getRelationSelectors(schema, societeConfigAnalyseSyntheseSocieteCaracteristiqueRelations, 'societeConfigAnalyseSyntheseSocieteCaracteristique'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteConfigAnalyseSyntheseSocieteCaracteristiqueDictionaryWithoutChild(idSocieteConfigAnalyseSyntheseSocieteCaracteristique);
  }
}

export function selectActiveSocieteConfigAnalyseSyntheseSocieteCaracteristiques(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveSocieteConfigAnalyseSyntheseSocieteCaracteristiquesEntities, societeConfigAnalyseSyntheseSocieteCaracteristiques => ({
      societeConfigAnalyseSyntheseSocieteCaracteristiques
    }))
  ];
  selectors.push(...getRelationSelectors(schema, societeConfigAnalyseSyntheseSocieteCaracteristiqueRelations, 'societeConfigAnalyseSyntheseSocieteCaracteristique'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  societeConfigAnalyseSyntheseSocieteCaracteristiques: Dictionary<SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState>;
  societeConfigAnalyseSyntheses?: Dictionary<SocieteConfigAnalyseSyntheseEntityState>;
  societeCaracteristiques?: Dictionary<SocieteCaracteristiqueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societeConfigAnalyseSyntheseSocieteCaracteristiques: (SocieteConfigAnalyseSyntheseSocieteCaracteristique | null)[] } {
  const {
    societeConfigAnalyseSyntheseSocieteCaracteristiques,
    societeConfigAnalyseSyntheses,
    societeCaracteristiques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societeConfigAnalyseSyntheseSocieteCaracteristiques: Object.keys(societeConfigAnalyseSyntheseSocieteCaracteristiques).map(idSocieteConfigAnalyseSyntheseSocieteCaracteristique =>
      hydrate(
        societeConfigAnalyseSyntheseSocieteCaracteristiques[idSocieteConfigAnalyseSyntheseSocieteCaracteristique] as SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState,
        societeConfigAnalyseSyntheses,
        societeCaracteristiques
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState | null } {
  const {
    societeConfigAnalyseSyntheseSocieteCaracteristiques,
    societeConfigAnalyseSyntheses,
    societeCaracteristiques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societeConfigAnalyseSyntheseSocieteCaracteristique = Object.values(societeConfigAnalyseSyntheseSocieteCaracteristiques)[0];
  return {
    societeConfigAnalyseSyntheseSocieteCaracteristique: hydrate(
      societeConfigAnalyseSyntheseSocieteCaracteristique as SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState,
      societeConfigAnalyseSyntheses,
      societeCaracteristiques
    )
  };
}

function hydrate(
  societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState,
  societeConfigAnalyseSyntheseEntities?: Dictionary<SocieteConfigAnalyseSyntheseEntityState>,
  societeCaracteristiqueEntities?: Dictionary<SocieteCaracteristiqueEntityState>,
): SocieteConfigAnalyseSyntheseSocieteCaracteristique | null {
  if (!societeConfigAnalyseSyntheseSocieteCaracteristique) {
    return null;
  }

  const societeConfigAnalyseSyntheseSocieteCaracteristiqueHydrated: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState = { ...societeConfigAnalyseSyntheseSocieteCaracteristique };
  if (societeConfigAnalyseSyntheseEntities) {
    societeConfigAnalyseSyntheseSocieteCaracteristiqueHydrated.societeConfigAnalyseSynthese = societeConfigAnalyseSyntheseEntities[societeConfigAnalyseSyntheseSocieteCaracteristique.societeConfigAnalyseSynthese as number] as SocieteConfigAnalyseSynthese;
  } else {
    delete societeConfigAnalyseSyntheseSocieteCaracteristiqueHydrated.societeConfigAnalyseSynthese;
  }
  if (societeCaracteristiqueEntities) {
    societeConfigAnalyseSyntheseSocieteCaracteristiqueHydrated.societeCaracteristique = societeCaracteristiqueEntities[societeConfigAnalyseSyntheseSocieteCaracteristique.societeCaracteristique as number] as SocieteCaracteristique;
  } else {
    delete societeConfigAnalyseSyntheseSocieteCaracteristiqueHydrated.societeCaracteristique;
  }

  return societeConfigAnalyseSyntheseSocieteCaracteristiqueHydrated as SocieteConfigAnalyseSyntheseSocieteCaracteristique;
}
