import { RepositoryService } from '@get/services/repository';
import { CaracteristiqueChoixTemplate } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCaracteristiqueChoixTemplateApiService {
  constructor(protected repo: RepositoryService) {}

  public getCaracteristiqueChoixTemplates(params?: any): Observable<CaracteristiqueChoixTemplate[]> {
    return this.repo.getData<CaracteristiqueChoixTemplate[]>('caracteristique-choix-template', params);
  }

  public getCaracteristiqueChoixTemplate(params: { idCaracteristiqueChoixTemplate: number; params?: any }): Observable<CaracteristiqueChoixTemplate> {
    return this.repo.getData<CaracteristiqueChoixTemplate>('caracteristique-choix-template/' + params.idCaracteristiqueChoixTemplate, params.params);
  }

  public addCaracteristiqueChoixTemplate(caracteristiqueChoixTemplate: Partial<CaracteristiqueChoixTemplate>): Observable<CaracteristiqueChoixTemplate> {
    return this.repo.create<CaracteristiqueChoixTemplate>('caracteristique-choix-template', caracteristiqueChoixTemplate);
  }

  public updateCaracteristiqueChoixTemplate(caracteristiqueChoixTemplate: Partial<CaracteristiqueChoixTemplate>): Observable<CaracteristiqueChoixTemplate> {
    return this.repo.update('caracteristique-choix-template', caracteristiqueChoixTemplate);
  }

  public deleteCaracteristiqueChoixTemplate(idCaracteristiqueChoixTemplate: number): Observable<number> {
    return this.repo.delete('caracteristique-choix-template/' + +idCaracteristiqueChoixTemplate);
  }
}

