import { RepositoryService } from '@get/services/repository';
import { CampagneSocieteCaracteristique } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedCampagneSocieteCaracteristiqueApiService {
  constructor(protected repo: RepositoryService) {}

  public getCampagneSocieteCaracteristiques(params?: any): Observable<CampagneSocieteCaracteristique[]> {
    return this.repo.getData<CampagneSocieteCaracteristique[]>('campagne-societe-caracteristique', params);
  }

  public getCampagneSocieteCaracteristique(params: { idCampagneSocieteCaracteristique: number; params?: any }): Observable<CampagneSocieteCaracteristique> {
    return this.repo.getData<CampagneSocieteCaracteristique>('campagne-societe-caracteristique/' + params.idCampagneSocieteCaracteristique, params.params);
  }

  public addCampagneSocieteCaracteristique(campagneSocieteCaracteristique: Partial<CampagneSocieteCaracteristique>): Observable<CampagneSocieteCaracteristique> {
    return this.repo.create<CampagneSocieteCaracteristique>('campagne-societe-caracteristique', campagneSocieteCaracteristique);
  }

  public updateCampagneSocieteCaracteristique(campagneSocieteCaracteristique: Partial<CampagneSocieteCaracteristique>): Observable<CampagneSocieteCaracteristique> {
    return this.repo.update('campagne-societe-caracteristique', campagneSocieteCaracteristique);
  }

  public deleteCampagneSocieteCaracteristique(idCampagneSocieteCaracteristique: number): Observable<number> {
    return this.repo.delete('campagne-societe-caracteristique/' + +idCampagneSocieteCaracteristique);
  }
}

