import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { SocieteComposantFamille, SocieteComposantFamilleEntityState } from '@get/api-interfaces';
import { ComposantGroupe, ComposantGroupeEntityState } from '@get/api-interfaces';
import { ComposantTemplate, ComposantTemplateEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoix, SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { CaracteristiqueChoixTemplate, CaracteristiqueChoixTemplateEntityState } from '@get/api-interfaces';
import { ValeurFichier, ValeurFichierEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { Organisation, OrganisationEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { FichierState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const fichierRelations: string[] = ['societeComposants','societeComposantFamilles','composantGroupes','composantTemplates','societeCaracteristiqueChoices','caracteristiqueChoixTemplates','valeurFichiers','valeurs','organisations',];

export const { selectEntities, selectAll } = FichierState.adapter.getSelectors();

export const selectFichierState = createFeatureSelector<FichierState.IState>(FichierState.fichierFeatureKey);

export const selectIsLoadedFichier = createSelector(
  selectFichierState,
  (state: FichierState.IState) => state.isLoaded
);

export const selectIsLoadingFichier = createSelector(
  selectFichierState,
  (state: FichierState.IState) => state.isLoading
);

export const selectIsReadyFichier = createSelector(
  selectFichierState,
  (state: FichierState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedFichier = createSelector(
  selectFichierState,
  (state: FichierState.IState) => state.isLoaded && !state.isLoading
);

export const selectFichiersEntities = createSelector(selectFichierState, selectEntities);

export const selectFichiersArray = createSelector(selectFichierState, selectAll);

export const selectIdFichiersActive = createSelector(
  selectFichierState,
  (state: FichierState.IState) => state.actives
);

const fichiersInObject = (fichiers: Dictionary<FichierEntityState>) => ({ fichiers })

const selectFichiersEntitiesDictionary = createSelector(selectFichiersEntities, fichiersInObject);

const selectAllFichiersObject = createSelector(selectFichiersEntities, fichiers => {
  return hydrateAll({ fichiers });
});

const selectOneFichierDictionary = (idFichier : number) =>
  createSelector(selectFichiersEntities, fichiers => ({
    fichiers: { [idFichier]: fichiers[idFichier] }
  }));

const selectOneFichierDictionaryWithoutChild = (idFichier : number) =>
  createSelector(selectFichiersEntities, fichiers => ({
    fichier: fichiers[idFichier]
  }));

const selectActiveFichiersEntities = createSelector(
  selectIdFichiersActive,
  selectFichiersEntities,
  (actives: number[], fichiers: Dictionary<FichierEntityState>) => getFichiersFromActives(actives, fichiers)
);

function getFichiersFromActives(
  actives: number[],
  fichiers: Dictionary<FichierEntityState>
): Dictionary<FichierEntityState> {
  return actives.reduce((acc, idActive) => {
    if (fichiers[idActive]) {
      acc[idActive] = fichiers[idActive];
    }
    return acc;
  }, {} as Dictionary<FichierEntityState>);
}

const selectAllFichiersSelectors: Dictionary<Selector> = {};
export function selectAllFichiers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Fichier>(
      schema,
      selectAllFichiersSelectors,
      selectFichiersEntitiesDictionary,
      getRelationSelectors,
      fichierRelations,
      hydrateAll,
      'fichier'
    );
  } else {
    return selectAllFichiersObject;
  }
}

export function selectAllFichiersDictionary(
  schema: SelectSchema = {},
  customKey: string = 'fichiers'
): Selector {
  return createSelector(selectAllFichiers(schema), result => {
    const res = { [customKey]: {} as Dictionary<FichierEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.fichiers.length; i++) {
      res[customKey][result.fichiers[i].idFichier] = result.fichiers[i];
    }
    return res;
  });
}

export function selectOneFichier(
  schema: SelectSchema = {},
  idFichier: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneFichierDictionary(idFichier)];
  selectors.push(...getRelationSelectors(schema, fichierRelations, 'fichier'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneFichierDictionaryWithoutChild(idFichier);
  }
}

export function selectActiveFichiers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveFichiersEntities, fichiers => ({
      fichiers
    }))
  ];
  selectors.push(...getRelationSelectors(schema, fichierRelations, 'fichier'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  fichiers: Dictionary<FichierEntityState>;
  organisations?: Dictionary<OrganisationEntityState>;
  societeComposants?: Dictionary<SocieteComposantEntityState>;
  societeComposantFamilles?: Dictionary<SocieteComposantFamilleEntityState>;
  composantGroupes?: Dictionary<ComposantGroupeEntityState>;
  composantTemplates?: Dictionary<ComposantTemplateEntityState>;
  societeCaracteristiqueChoices?: Dictionary<SocieteCaracteristiqueChoixEntityState>;
  caracteristiqueChoixTemplates?: Dictionary<CaracteristiqueChoixTemplateEntityState>;
  valeurFichiers?: Dictionary<ValeurFichierEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { fichiers: (Fichier | null)[] } {
  const {
    fichiers,
    organisations,
    societeComposants,
    societeComposantFamilles,
    composantGroupes,
    composantTemplates,
    societeCaracteristiqueChoices,
    caracteristiqueChoixTemplates,
    valeurFichiers,
    valeurs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    fichiers: Object.keys(fichiers).map(idFichier =>
      hydrate(
        fichiers[idFichier] as FichierEntityState,
        organisations,
        societeComposants,
        societeComposantFamilles,
        composantGroupes,
        composantTemplates,
        societeCaracteristiqueChoices,
        caracteristiqueChoixTemplates,
        valeurFichiers,
        valeurs
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { fichier: FichierEntityState | null } {
  const {
    fichiers,
    organisations,
    societeComposants,
    societeComposantFamilles,
    composantGroupes,
    composantTemplates,
    societeCaracteristiqueChoices,
    caracteristiqueChoixTemplates,
    valeurFichiers,
    valeurs
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const fichier = Object.values(fichiers)[0];
  return {
    fichier: hydrate(
      fichier as FichierEntityState,
      organisations,
      societeComposants,
      societeComposantFamilles,
      composantGroupes,
      composantTemplates,
      societeCaracteristiqueChoices,
      caracteristiqueChoixTemplates,
      valeurFichiers,
      valeurs
    )
  };
}

function hydrate(
  fichier: FichierEntityState,
  organisationEntities?: Dictionary<OrganisationEntityState>,
  societeComposantEntities?: Dictionary<SocieteComposantEntityState>,
  societeComposantFamilleEntities?: Dictionary<SocieteComposantFamilleEntityState>,
  composantGroupeEntities?: Dictionary<ComposantGroupeEntityState>,
  composantTemplateEntities?: Dictionary<ComposantTemplateEntityState>,
  societeCaracteristiqueChoixEntities?: Dictionary<SocieteCaracteristiqueChoixEntityState>,
  caracteristiqueChoixTemplateEntities?: Dictionary<CaracteristiqueChoixTemplateEntityState>,
  valeurFichierEntities?: Dictionary<ValeurFichierEntityState>,
  valeurEntities?: Dictionary<ValeurEntityState>,
): Fichier | null {
  if (!fichier) {
    return null;
  }

  const fichierHydrated: FichierEntityState = { ...fichier };
  if (organisationEntities) {
    fichierHydrated.organisation = organisationEntities[fichier.organisation as number] as Organisation;
  } else {
    delete fichierHydrated.organisation;
  }

  if (societeComposantEntities) {
    fichierHydrated.societeComposants = ((fichierHydrated.societeComposants as number[]) || []).map(
      id => societeComposantEntities[id]
    ) as SocieteComposant[];
  } else {
    delete fichierHydrated.societeComposants;
  }

  if (societeComposantFamilleEntities) {
    fichierHydrated.societeComposantFamilles = ((fichierHydrated.societeComposantFamilles as number[]) || []).map(
      id => societeComposantFamilleEntities[id]
    ) as SocieteComposantFamille[];
  } else {
    delete fichierHydrated.societeComposantFamilles;
  }

  if (composantGroupeEntities) {
    fichierHydrated.composantGroupes = ((fichierHydrated.composantGroupes as number[]) || []).map(
      id => composantGroupeEntities[id]
    ) as ComposantGroupe[];
  } else {
    delete fichierHydrated.composantGroupes;
  }

  if (composantTemplateEntities) {
    fichierHydrated.composantTemplates = ((fichierHydrated.composantTemplates as number[]) || []).map(
      id => composantTemplateEntities[id]
    ) as ComposantTemplate[];
  } else {
    delete fichierHydrated.composantTemplates;
  }

  if (societeCaracteristiqueChoixEntities) {
    fichierHydrated.societeCaracteristiqueChoices = ((fichierHydrated.societeCaracteristiqueChoices as number[]) || []).map(
      id => societeCaracteristiqueChoixEntities[id]
    ) as SocieteCaracteristiqueChoix[];
  } else {
    delete fichierHydrated.societeCaracteristiqueChoices;
  }

  if (caracteristiqueChoixTemplateEntities) {
    fichierHydrated.caracteristiqueChoixTemplates = ((fichierHydrated.caracteristiqueChoixTemplates as number[]) || []).map(
      id => caracteristiqueChoixTemplateEntities[id]
    ) as CaracteristiqueChoixTemplate[];
  } else {
    delete fichierHydrated.caracteristiqueChoixTemplates;
  }

  if (valeurFichierEntities) {
    fichierHydrated.valeurFichiers = ((fichierHydrated.valeurFichiers as number[]) || []).map(
      id => valeurFichierEntities[id]
    ) as ValeurFichier[];
  } else {
    delete fichierHydrated.valeurFichiers;
  }

  if (valeurEntities) {
    fichierHydrated.valeurs = ((fichierHydrated.valeurs as number[]) || []).map(
      id => valeurEntities[id]
    ) as Valeur[];
  } else {
    delete fichierHydrated.valeurs;
  }

  return fichierHydrated as Fichier;
}
