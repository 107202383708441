import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristique, SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueApiService } from '@get/store/api-services';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique } from '@get/store/configs/normalization';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueSelectors } from '@get/store/selectors';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueRelationsIds } from '@get/store/ids-interfaces';
import { SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';

export function getDefaultAddSocieteConfigAnalyseSyntheseSocieteCaracteristiqueActions(societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState, ids?: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueRelationsIds): Action[] {
  const actions: Action[] = [SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.normalizeManySocieteConfigAnalyseSyntheseSocieteCaracteristiquesAfterUpsert({ societeConfigAnalyseSyntheseSocieteCaracteristiques: [societeConfigAnalyseSyntheseSocieteCaracteristique] })];

  if (ids?.societeConfigAnalyseSynthese) {
    actions.push(
      SocieteConfigAnalyseSyntheseGeneratedActions.addManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess({
        idSocieteConfigAnalyseSynthese: ids.societeConfigAnalyseSynthese,
        idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: [societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique]
      })
    );
    actions.push(
      SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.addSocieteConfigAnalyseSyntheseSuccess({
        idSocieteConfigAnalyseSyntheseSocieteCaracteristique: societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique,
        idSocieteConfigAnalyseSynthese: ids.societeConfigAnalyseSynthese
      })
    );
  }

  if (ids?.societeCaracteristique) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.addManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess({
        idSocieteCaracteristique: ids.societeCaracteristique,
        idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: [societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique]
      })
    );
    actions.push(
      SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.addSocieteCaracteristiqueSuccess({
        idSocieteConfigAnalyseSyntheseSocieteCaracteristique: societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique,
        idSocieteCaracteristique: ids.societeCaracteristique
      })
    );
  }

  return actions;
}

export function getDefaultDeleteSocieteConfigAnalyseSyntheseSocieteCaracteristiqueActions(societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState): Action[] {
  const actions: Action[] = [SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.deleteOneSocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess({ idSocieteConfigAnalyseSyntheseSocieteCaracteristique: societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique })];

  if (societeConfigAnalyseSyntheseSocieteCaracteristique.societeConfigAnalyseSynthese) {
    actions.push(
      SocieteConfigAnalyseSyntheseGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess({
        idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: [societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique],
        idSocieteConfigAnalyseSyntheses: [societeConfigAnalyseSyntheseSocieteCaracteristique.societeConfigAnalyseSynthese as number]
      })
    );
  }

  if (societeConfigAnalyseSyntheseSocieteCaracteristique.societeCaracteristique) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSocieteCaracteristiqueSuccess({
        idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: [societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique],
        idSocieteCaracteristiques: [societeConfigAnalyseSyntheseSocieteCaracteristique.societeCaracteristique as number]
      })
    );
  }

  return actions;
}

export class GeneratedSocieteConfigAnalyseSyntheseSocieteCaracteristiqueEffects {
  constructor(
    protected actions$: Actions,
    protected societeConfigAnalyseSyntheseSocieteCaracteristiqueApiService: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueApiService,
    protected store$: Store<AppState>
  ) {}

  getManySocieteConfigAnalyseSyntheseSocieteCaracteristiques$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.getManySocieteConfigAnalyseSyntheseSocieteCaracteristiques),
      switchMap(({ params }) =>
        this.societeConfigAnalyseSyntheseSocieteCaracteristiqueApiService.getSocieteConfigAnalyseSyntheseSocieteCaracteristiques(params).pipe(
          map((societeConfigAnalyseSyntheseSocieteCaracteristiques: SocieteConfigAnalyseSyntheseSocieteCaracteristique[]) => {
            return SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.normalizeManySocieteConfigAnalyseSyntheseSocieteCaracteristiquesAfterUpsert({ societeConfigAnalyseSyntheseSocieteCaracteristiques });
          }),
          catchError(error => of(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.societeConfigAnalyseSyntheseSocieteCaracteristiquesFailure({ error })))
        )
      )
    );
  });

  getOneSocieteConfigAnalyseSyntheseSocieteCaracteristique$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.getOneSocieteConfigAnalyseSyntheseSocieteCaracteristique),
      switchMap(idSocieteConfigAnalyseSyntheseSocieteCaracteristique =>
        this.societeConfigAnalyseSyntheseSocieteCaracteristiqueApiService.getSocieteConfigAnalyseSyntheseSocieteCaracteristique(idSocieteConfigAnalyseSyntheseSocieteCaracteristique).pipe(
          map((societeConfigAnalyseSyntheseSocieteCaracteristique: SocieteConfigAnalyseSyntheseSocieteCaracteristique) => {
            return SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.normalizeManySocieteConfigAnalyseSyntheseSocieteCaracteristiquesAfterUpsert({ societeConfigAnalyseSyntheseSocieteCaracteristiques: [societeConfigAnalyseSyntheseSocieteCaracteristique] });
          }),
          catchError(error => of(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.societeConfigAnalyseSyntheseSocieteCaracteristiquesFailure({ error })))
        )
      )
    );
  });

  upsertOneSocieteConfigAnalyseSyntheseSocieteCaracteristique$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.upsertOneSocieteConfigAnalyseSyntheseSocieteCaracteristique),
      concatMap(
        ({
          societeConfigAnalyseSyntheseSocieteCaracteristique,
          ids
        }: {
          societeConfigAnalyseSyntheseSocieteCaracteristique: Partial<SocieteConfigAnalyseSyntheseSocieteCaracteristique>;
          ids?: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueRelationsIds;
        }) => {
          if (societeConfigAnalyseSyntheseSocieteCaracteristique.idSocieteConfigAnalyseSyntheseSocieteCaracteristique) {
            return this.societeConfigAnalyseSyntheseSocieteCaracteristiqueApiService.updateSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeConfigAnalyseSyntheseSocieteCaracteristique).pipe(
              map((societeConfigAnalyseSyntheseSocieteCaracteristiqueReturned: SocieteConfigAnalyseSyntheseSocieteCaracteristique) => {
                return SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.normalizeManySocieteConfigAnalyseSyntheseSocieteCaracteristiquesAfterUpsert({ societeConfigAnalyseSyntheseSocieteCaracteristiques: [societeConfigAnalyseSyntheseSocieteCaracteristiqueReturned] });
              }),
              catchError(error => of(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.societeConfigAnalyseSyntheseSocieteCaracteristiquesFailure({ error })))
            );
          } else {
            return this.societeConfigAnalyseSyntheseSocieteCaracteristiqueApiService.addSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeConfigAnalyseSyntheseSocieteCaracteristique).pipe(
              mergeMap((societeConfigAnalyseSyntheseSocieteCaracteristiqueReturned: SocieteConfigAnalyseSyntheseSocieteCaracteristique) => getDefaultAddSocieteConfigAnalyseSyntheseSocieteCaracteristiqueActions(societeConfigAnalyseSyntheseSocieteCaracteristiqueReturned, ids)),
              catchError(error => of(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.societeConfigAnalyseSyntheseSocieteCaracteristiquesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteConfigAnalyseSyntheseSocieteCaracteristique$ = createEffect(() => {
    const selectSocieteConfigAnalyseSyntheseSocieteCaracteristiqueState$ = this.store$.select(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueSelectors.selectSocieteConfigAnalyseSyntheseSocieteCaracteristiqueState);
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.deleteOneSocieteConfigAnalyseSyntheseSocieteCaracteristique),
      withLatestFrom(selectSocieteConfigAnalyseSyntheseSocieteCaracteristiqueState$),
      concatMap(([{ idSocieteConfigAnalyseSyntheseSocieteCaracteristique }, state]) =>
        this.societeConfigAnalyseSyntheseSocieteCaracteristiqueApiService.deleteSocieteConfigAnalyseSyntheseSocieteCaracteristique(idSocieteConfigAnalyseSyntheseSocieteCaracteristique).pipe(
          mergeMap(_success => getDefaultDeleteSocieteConfigAnalyseSyntheseSocieteCaracteristiqueActions(state.entities[idSocieteConfigAnalyseSyntheseSocieteCaracteristique] as SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEntityState)),
          catchError(error => of(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.societeConfigAnalyseSyntheseSocieteCaracteristiquesFailure({ error })))
        )
      )
    );
  });

  normalizeManySocieteConfigAnalyseSyntheseSocieteCaracteristiquesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseSocieteCaracteristiqueGeneratedActions.normalizeManySocieteConfigAnalyseSyntheseSocieteCaracteristiquesAfterUpsert),
      concatMap(({ societeConfigAnalyseSyntheseSocieteCaracteristiques }) => {
        const actions: Action[] = getActionsToNormalizeSocieteConfigAnalyseSyntheseSocieteCaracteristique(societeConfigAnalyseSyntheseSocieteCaracteristiques, StoreActionType.upsert);
        return [getMultiAction(actions, '[SocieteConfigAnalyseSyntheseSocieteCaracteristique] Normalization After Upsert Success')];
      })
    );
  });
}
