import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CaracteristiqueTemplate, CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { CaracteristiqueTemplateRelationsIds } from '@get/store/ids-interfaces';

export const CaracteristiqueTemplateGeneratedActions = createActionGroup({
  source: 'Caracteristique Template Generated',
  events: {
    'Get One Caracteristique Template': props<{ idCaracteristiqueTemplate: number; params?: any }>(),
    'Get Many Caracteristique Templates': props<{ params: any }>(),
    'Add Many Actives Caracteristique Templates': props<{ idCaracteristiqueTemplates: number[] }>(),
    'Delete One Active Caracteristique Template': props<{ idCaracteristiqueTemplate: number }>(),
    'Clear Actives Caracteristique Templates': emptyProps(),
    'Upsert One Caracteristique Template': props<{ caracteristiqueTemplate: Partial<CaracteristiqueTemplate>; ids?: CaracteristiqueTemplateRelationsIds; }>(),
    'Upsert Many Caracteristique Templates': props<{ caracteristiqueTemplates: Partial<CaracteristiqueTemplate>[]; ids?: CaracteristiqueTemplateRelationsIds; }>(),
    'Upsert Many Caracteristique Templates Success': props<{ caracteristiqueTemplates: CaracteristiqueTemplateEntityState[] }>(),
    'Delete One Caracteristique Template': props<{ idCaracteristiqueTemplate: number }>(),
    'Delete One Caracteristique Template Success': props<{ idCaracteristiqueTemplate: number }>(),
    'Normalize Many Caracteristique Templates After Upsert': props<{ caracteristiqueTemplates: CaracteristiqueTemplateEntityState[] }>(),
    'Caracteristique Templates Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Caracteristique Templates': emptyProps(),
    'Add Many Societe Caracteristique Success': props<{ idCaracteristiqueTemplate: number; idSocieteCaracteristiques: number[] }>(),
    'Delete Many Societe Caracteristique Success': props<{ idSocieteCaracteristiques: number[]; idCaracteristiqueTemplates: number[] }>(),
    'Add Many Caracteristique Choix Template Success': props<{ idCaracteristiqueTemplate: number; idCaracteristiqueChoixTemplates: number[] }>(),
    'Delete Many Caracteristique Choix Template Success': props<{ idCaracteristiqueChoixTemplates: number[]; idCaracteristiqueTemplates: number[] }>(),
    'Add Composant Template Success': props<{ idCaracteristiqueTemplate: number; idComposantTemplate: number }>(),
    'Delete Many Composant Template Success': props<{ idComposantTemplates: number[]; idCaracteristiqueTemplates: number[] }>()
  }
});
