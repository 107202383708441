import { RepositoryService } from '@get/services/repository';
import { SocieteEspaceFamille } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedSocieteEspaceFamilleApiService {
  constructor(protected repo: RepositoryService) {}

  public getSocieteEspaceFamilles(params?: any): Observable<SocieteEspaceFamille[]> {
    return this.repo.getData<SocieteEspaceFamille[]>('societe-espace-famille', params);
  }

  public getSocieteEspaceFamille(params: { idSocieteEspaceFamille: number; params?: any }): Observable<SocieteEspaceFamille> {
    return this.repo.getData<SocieteEspaceFamille>('societe-espace-famille/' + params.idSocieteEspaceFamille, params.params);
  }

  public addSocieteEspaceFamille(societeEspaceFamille: Partial<SocieteEspaceFamille>): Observable<SocieteEspaceFamille> {
    return this.repo.create<SocieteEspaceFamille>('societe-espace-famille', societeEspaceFamille);
  }

  public updateSocieteEspaceFamille(societeEspaceFamille: Partial<SocieteEspaceFamille>): Observable<SocieteEspaceFamille> {
    return this.repo.update('societe-espace-famille', societeEspaceFamille);
  }

  public deleteSocieteEspaceFamille(idSocieteEspaceFamille: number): Observable<number> {
    return this.repo.delete('societe-espace-famille/' + +idSocieteEspaceFamille);
  }
}

