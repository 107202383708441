import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteTerritoireUserGeneratedActions } from '@get/store/actions';
import { SocieteTerritoireUserState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteTerritoireUserEntityState } from '@get/api-interfaces';

export const societeTerritoireUserReducersGeneratedFunctions: ReducerTypes<SocieteTerritoireUserState.IState, readonly ActionCreator[]>[] = [
  on(SocieteTerritoireUserGeneratedActions.getOneSocieteTerritoireUser, (state: SocieteTerritoireUserState.IState) => setLoadingsState(state, true)),
  on(SocieteTerritoireUserGeneratedActions.getManySocieteTerritoireUsers, (state: SocieteTerritoireUserState.IState) => setLoadingsState(state, true)),
  on(SocieteTerritoireUserGeneratedActions.upsertOneSocieteTerritoireUser, (state: SocieteTerritoireUserState.IState) => setLoadingsState(state, true)),

  on(SocieteTerritoireUserGeneratedActions.upsertManySocieteTerritoireUsersSuccess, (state: SocieteTerritoireUserState.IState, { societeTerritoireUsers }) =>
    SocieteTerritoireUserState.adapter.upsertMany(societeTerritoireUsers, setLoadingsState(state, false))
  ),
  on(SocieteTerritoireUserGeneratedActions.deleteOneSocieteTerritoireUser, (state: SocieteTerritoireUserState.IState) => setLoadingsState(state, true)),
  on(SocieteTerritoireUserGeneratedActions.deleteOneSocieteTerritoireUserSuccess, (state: SocieteTerritoireUserState.IState, { idSocieteTerritoireUser }) =>
    SocieteTerritoireUserState.adapter.removeOne(idSocieteTerritoireUser, setLoadingsState(state, false))
  ),
  on(SocieteTerritoireUserGeneratedActions.clearActivesSocieteTerritoireUsers, (state: SocieteTerritoireUserState.IState) => ({ ...state, actives: [] })),
  on(SocieteTerritoireUserGeneratedActions.addManyActivesSocieteTerritoireUsers, (state: SocieteTerritoireUserState.IState, { idSocieteTerritoireUsers }) => ({
    ...state,
    actives: state.actives.concat(idSocieteTerritoireUsers)
  })),
  on(SocieteTerritoireUserGeneratedActions.deleteOneActiveSocieteTerritoireUser, (state: SocieteTerritoireUserState.IState, { idSocieteTerritoireUser }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idSocieteTerritoireUser)
  })),

  on(SocieteTerritoireUserGeneratedActions.clearSocieteTerritoireUsers, () => SocieteTerritoireUserState.initialState),

  on(
    SocieteTerritoireUserGeneratedActions.addSocieteTerritoireSuccess,
    (state: SocieteTerritoireUserState.IState, { idSocieteTerritoireUser, idSocieteTerritoire }) => {
      if (!state.entities[idSocieteTerritoireUser]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteTerritoireUser]: {
            ...state.entities[idSocieteTerritoireUser],
            societeTerritoire: idSocieteTerritoire
          }
        }
      };
    }
  ),

  on(
    SocieteTerritoireUserGeneratedActions.deleteManySocieteTerritoireSuccess,
    (state: SocieteTerritoireUserState.IState, { idSocieteTerritoires, idSocieteTerritoireUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteTerritoireUsers.reduce((entities, idSocieteTerritoireUser) => {
            if (!state.entities[idSocieteTerritoireUser]?.societeTerritoire) {
              return entities;
            }
            entities[idSocieteTerritoireUser] = {
              ...state.entities[idSocieteTerritoireUser],
              societeTerritoire: idSocieteTerritoires.some(
                (idSocieteTerritoire: number) => idSocieteTerritoire === state.entities[idSocieteTerritoireUser]?.societeTerritoire
              )
                ? undefined
                : state.entities[idSocieteTerritoireUser]?.societeTerritoire
            } as SocieteTerritoireUserEntityState;
            return entities;
          }, {} as Dictionary<SocieteTerritoireUserEntityState>)
        }
      };
    }
  ),

  on(
    SocieteTerritoireUserGeneratedActions.addUserSuccess,
    (state: SocieteTerritoireUserState.IState, { idSocieteTerritoireUser, idUser }) => {
      if (!state.entities[idSocieteTerritoireUser]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocieteTerritoireUser]: {
            ...state.entities[idSocieteTerritoireUser],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    SocieteTerritoireUserGeneratedActions.deleteManyUserSuccess,
    (state: SocieteTerritoireUserState.IState, { idUsers, idSocieteTerritoireUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocieteTerritoireUsers.reduce((entities, idSocieteTerritoireUser) => {
            if (!state.entities[idSocieteTerritoireUser]?.user) {
              return entities;
            }
            entities[idSocieteTerritoireUser] = {
              ...state.entities[idSocieteTerritoireUser],
              user: idUsers.some(
                (idUser: number) => idUser === state.entities[idSocieteTerritoireUser]?.user
              )
                ? undefined
                : state.entities[idSocieteTerritoireUser]?.user
            } as SocieteTerritoireUserEntityState;
            return entities;
          }, {} as Dictionary<SocieteTerritoireUserEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteTerritoireUserState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): SocieteTerritoireUserState.IState {
  return { ...state, isLoaded, isLoading };
}
