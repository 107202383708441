import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteEspace, SocieteEspaceEntityState } from '@get/api-interfaces';
import { SocieteEspaceRelationsIds } from '@get/store/ids-interfaces';

export const SocieteEspaceGeneratedActions = createActionGroup({
  source: 'Societe Espace Generated',
  events: {
    'Get One Societe Espace': props<{ idSocieteEspace: number; params?: any }>(),
    'Get Many Societe Espaces': props<{ params: any }>(),
    'Add Many Actives Societe Espaces': props<{ idSocieteEspaces: number[] }>(),
    'Delete One Active Societe Espace': props<{ idSocieteEspace: number }>(),
    'Clear Actives Societe Espaces': emptyProps(),
    'Upsert One Societe Espace': props<{ societeEspace: Partial<SocieteEspace>; ids?: SocieteEspaceRelationsIds; }>(),
    'Upsert Many Societe Espaces': props<{ societeEspaces: Partial<SocieteEspace>[]; ids?: SocieteEspaceRelationsIds; }>(),
    'Upsert Many Societe Espaces Success': props<{ societeEspaces: SocieteEspaceEntityState[] }>(),
    'Delete One Societe Espace': props<{ idSocieteEspace: number }>(),
    'Delete One Societe Espace Success': props<{ idSocieteEspace: number }>(),
    'Normalize Many Societe Espaces After Upsert': props<{ societeEspaces: SocieteEspaceEntityState[] }>(),
    'Societe Espaces Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Espaces': emptyProps(),
    'Add Many Espace Success': props<{ idSocieteEspace: number; idEspaces: number[] }>(),
    'Delete Many Espace Success': props<{ idEspaces: number[]; idSocieteEspaces: number[] }>(),
    'Add Societe Espace Famille Success': props<{ idSocieteEspace: number; idSocieteEspaceFamille: number }>(),
    'Delete Many Societe Espace Famille Success': props<{ idSocieteEspaceFamilles: number[]; idSocieteEspaces: number[] }>()
  }
});
