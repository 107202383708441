import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteConfigAnalyseSynthese, SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseRelationsIds } from '@get/store/ids-interfaces';

export const SocieteConfigAnalyseSyntheseGeneratedActions = createActionGroup({
  source: 'Societe Config Analyse Synthese Generated',
  events: {
    'Get One Societe Config Analyse Synthese': props<{ idSocieteConfigAnalyseSynthese: number; params?: any }>(),
    'Get Many Societe Config Analyse Syntheses': props<{ params: any }>(),
    'Add Many Actives Societe Config Analyse Syntheses': props<{ idSocieteConfigAnalyseSyntheses: number[] }>(),
    'Delete One Active Societe Config Analyse Synthese': props<{ idSocieteConfigAnalyseSynthese: number }>(),
    'Clear Actives Societe Config Analyse Syntheses': emptyProps(),
    'Upsert One Societe Config Analyse Synthese': props<{ societeConfigAnalyseSynthese: Partial<SocieteConfigAnalyseSynthese>; ids?: SocieteConfigAnalyseSyntheseRelationsIds; }>(),
    'Upsert Many Societe Config Analyse Syntheses': props<{ societeConfigAnalyseSyntheses: Partial<SocieteConfigAnalyseSynthese>[]; ids?: SocieteConfigAnalyseSyntheseRelationsIds; }>(),
    'Upsert Many Societe Config Analyse Syntheses Success': props<{ societeConfigAnalyseSyntheses: SocieteConfigAnalyseSyntheseEntityState[] }>(),
    'Delete One Societe Config Analyse Synthese': props<{ idSocieteConfigAnalyseSynthese: number }>(),
    'Delete One Societe Config Analyse Synthese Success': props<{ idSocieteConfigAnalyseSynthese: number }>(),
    'Normalize Many Societe Config Analyse Syntheses After Upsert': props<{ societeConfigAnalyseSyntheses: SocieteConfigAnalyseSyntheseEntityState[] }>(),
    'Societe Config Analyse Syntheses Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Config Analyse Syntheses': emptyProps(),
    'Add Many Societe Config Analyse Synthese Societe Caracteristique Success': props<{ idSocieteConfigAnalyseSynthese: number; idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: number[] }>(),
    'Delete Many Societe Config Analyse Synthese Societe Caracteristique Success': props<{ idSocieteConfigAnalyseSyntheseSocieteCaracteristiques: number[]; idSocieteConfigAnalyseSyntheses: number[] }>(),
    'Add Many Societe Caracteristique Success': props<{ idSocieteConfigAnalyseSynthese: number; idSocieteCaracteristiques: number[] }>(),
    'Delete Many Societe Caracteristique Success': props<{ idSocieteCaracteristiques: number[]; idSocieteConfigAnalyseSyntheses: number[] }>(),
    'Add Societe Success': props<{ idSocieteConfigAnalyseSynthese: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idSocieteConfigAnalyseSyntheses: number[] }>(),
    'Add User Success': props<{ idSocieteConfigAnalyseSynthese: number; idUser: number }>(),
    'Delete Many User Success': props<{ idUsers: number[]; idSocieteConfigAnalyseSyntheses: number[] }>()
  }
});
