import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CampagnePatrimoine, CampagnePatrimoineEntityState } from '@get/api-interfaces';
import { CampagnePatrimoineRelationsIds } from '@get/store/ids-interfaces';

export const CampagnePatrimoineGeneratedActions = createActionGroup({
  source: 'Campagne Patrimoine Generated',
  events: {
    'Get One Campagne Patrimoine': props<{ idCampagnePatrimoine: number; params?: any }>(),
    'Get Many Campagne Patrimoines': props<{ params: any }>(),
    'Add Many Actives Campagne Patrimoines': props<{ idCampagnePatrimoines: number[] }>(),
    'Delete One Active Campagne Patrimoine': props<{ idCampagnePatrimoine: number }>(),
    'Clear Actives Campagne Patrimoines': emptyProps(),
    'Upsert One Campagne Patrimoine': props<{ campagnePatrimoine: Partial<CampagnePatrimoine>; ids?: CampagnePatrimoineRelationsIds; }>(),
    'Upsert Many Campagne Patrimoines': props<{ campagnePatrimoines: Partial<CampagnePatrimoine>[]; ids?: CampagnePatrimoineRelationsIds; }>(),
    'Upsert Many Campagne Patrimoines Success': props<{ campagnePatrimoines: CampagnePatrimoineEntityState[] }>(),
    'Delete One Campagne Patrimoine': props<{ idCampagnePatrimoine: number }>(),
    'Delete One Campagne Patrimoine Success': props<{ idCampagnePatrimoine: number }>(),
    'Normalize Many Campagne Patrimoines After Upsert': props<{ campagnePatrimoines: CampagnePatrimoineEntityState[] }>(),
    'Campagne Patrimoines Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Campagne Patrimoines': emptyProps(),
    'Add Campagne Success': props<{ idCampagnePatrimoine: number; idCampagne: number }>(),
    'Delete Many Campagne Success': props<{ idCampagnes: number[]; idCampagnePatrimoines: number[] }>(),
    'Add Patrimoine Success': props<{ idCampagnePatrimoine: number; idPatrimoine: number }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idCampagnePatrimoines: number[] }>()
  }
});
