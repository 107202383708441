import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteTerritoire, SocieteTerritoireEntityState } from '@get/api-interfaces';
import { SocieteTerritoireRelationsIds } from '@get/store/ids-interfaces';

export const SocieteTerritoireGeneratedActions = createActionGroup({
  source: 'Societe Territoire Generated',
  events: {
    'Get One Societe Territoire': props<{ idSocieteTerritoire: number; params?: any }>(),
    'Get Many Societe Territoires': props<{ params: any }>(),
    'Add Many Actives Societe Territoires': props<{ idSocieteTerritoires: number[] }>(),
    'Delete One Active Societe Territoire': props<{ idSocieteTerritoire: number }>(),
    'Clear Actives Societe Territoires': emptyProps(),
    'Upsert One Societe Territoire': props<{ societeTerritoire: Partial<SocieteTerritoire>; ids?: SocieteTerritoireRelationsIds; }>(),
    'Upsert Many Societe Territoires': props<{ societeTerritoires: Partial<SocieteTerritoire>[]; ids?: SocieteTerritoireRelationsIds; }>(),
    'Upsert Many Societe Territoires Success': props<{ societeTerritoires: SocieteTerritoireEntityState[] }>(),
    'Delete One Societe Territoire': props<{ idSocieteTerritoire: number }>(),
    'Delete One Societe Territoire Success': props<{ idSocieteTerritoire: number }>(),
    'Normalize Many Societe Territoires After Upsert': props<{ societeTerritoires: SocieteTerritoireEntityState[] }>(),
    'Societe Territoires Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Territoires': emptyProps(),
    'Add Many Societe Territoire Patrimoine Success': props<{ idSocieteTerritoire: number; idSocieteTerritoirePatrimoines: number[] }>(),
    'Delete Many Societe Territoire Patrimoine Success': props<{ idSocieteTerritoirePatrimoines: number[]; idSocieteTerritoires: number[] }>(),
    'Add Many Patrimoine Success': props<{ idSocieteTerritoire: number; idPatrimoines: number[] }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idSocieteTerritoires: number[] }>(),
    'Add Many Societe Territoire User Success': props<{ idSocieteTerritoire: number; idSocieteTerritoireUsers: number[] }>(),
    'Delete Many Societe Territoire User Success': props<{ idSocieteTerritoireUsers: number[]; idSocieteTerritoires: number[] }>(),
    'Add Societe Success': props<{ idSocieteTerritoire: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idSocieteTerritoires: number[] }>()
  }
});
