import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CampagneSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { CampagneSocieteCaracteristiqueState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { CampagneSocieteCaracteristiqueEntityState } from '@get/api-interfaces';

export const campagneSocieteCaracteristiqueReducersGeneratedFunctions: ReducerTypes<CampagneSocieteCaracteristiqueState.IState, readonly ActionCreator[]>[] = [
  on(CampagneSocieteCaracteristiqueGeneratedActions.getOneCampagneSocieteCaracteristique, (state: CampagneSocieteCaracteristiqueState.IState) => setLoadingsState(state, true)),
  on(CampagneSocieteCaracteristiqueGeneratedActions.getManyCampagneSocieteCaracteristiques, (state: CampagneSocieteCaracteristiqueState.IState) => setLoadingsState(state, true)),
  on(CampagneSocieteCaracteristiqueGeneratedActions.upsertOneCampagneSocieteCaracteristique, (state: CampagneSocieteCaracteristiqueState.IState) => setLoadingsState(state, true)),

  on(CampagneSocieteCaracteristiqueGeneratedActions.upsertManyCampagneSocieteCaracteristiquesSuccess, (state: CampagneSocieteCaracteristiqueState.IState, { campagneSocieteCaracteristiques }) =>
    CampagneSocieteCaracteristiqueState.adapter.upsertMany(campagneSocieteCaracteristiques, setLoadingsState(state, false))
  ),
  on(CampagneSocieteCaracteristiqueGeneratedActions.deleteOneCampagneSocieteCaracteristique, (state: CampagneSocieteCaracteristiqueState.IState) => setLoadingsState(state, true)),
  on(CampagneSocieteCaracteristiqueGeneratedActions.deleteOneCampagneSocieteCaracteristiqueSuccess, (state: CampagneSocieteCaracteristiqueState.IState, { idCampagneSocieteCaracteristique }) =>
    CampagneSocieteCaracteristiqueState.adapter.removeOne(idCampagneSocieteCaracteristique, setLoadingsState(state, false))
  ),
  on(CampagneSocieteCaracteristiqueGeneratedActions.clearActivesCampagneSocieteCaracteristiques, (state: CampagneSocieteCaracteristiqueState.IState) => ({ ...state, actives: [] })),
  on(CampagneSocieteCaracteristiqueGeneratedActions.addManyActivesCampagneSocieteCaracteristiques, (state: CampagneSocieteCaracteristiqueState.IState, { idCampagneSocieteCaracteristiques }) => ({
    ...state,
    actives: state.actives.concat(idCampagneSocieteCaracteristiques)
  })),
  on(CampagneSocieteCaracteristiqueGeneratedActions.deleteOneActiveCampagneSocieteCaracteristique, (state: CampagneSocieteCaracteristiqueState.IState, { idCampagneSocieteCaracteristique }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCampagneSocieteCaracteristique)
  })),

  on(CampagneSocieteCaracteristiqueGeneratedActions.clearCampagneSocieteCaracteristiques, () => CampagneSocieteCaracteristiqueState.initialState),

  on(
    CampagneSocieteCaracteristiqueGeneratedActions.addCampagneSuccess,
    (state: CampagneSocieteCaracteristiqueState.IState, { idCampagneSocieteCaracteristique, idCampagne }) => {
      if (!state.entities[idCampagneSocieteCaracteristique]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagneSocieteCaracteristique]: {
            ...state.entities[idCampagneSocieteCaracteristique],
            campagne: idCampagne
          }
        }
      };
    }
  ),

  on(
    CampagneSocieteCaracteristiqueGeneratedActions.deleteManyCampagneSuccess,
    (state: CampagneSocieteCaracteristiqueState.IState, { idCampagnes, idCampagneSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagneSocieteCaracteristiques.reduce((entities, idCampagneSocieteCaracteristique) => {
            if (!state.entities[idCampagneSocieteCaracteristique]?.campagne) {
              return entities;
            }
            entities[idCampagneSocieteCaracteristique] = {
              ...state.entities[idCampagneSocieteCaracteristique],
              campagne: idCampagnes.some(
                (idCampagne: number) => idCampagne === state.entities[idCampagneSocieteCaracteristique]?.campagne
              )
                ? undefined
                : state.entities[idCampagneSocieteCaracteristique]?.campagne
            } as CampagneSocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<CampagneSocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),

  on(
    CampagneSocieteCaracteristiqueGeneratedActions.addSocieteCaracteristiqueSuccess,
    (state: CampagneSocieteCaracteristiqueState.IState, { idCampagneSocieteCaracteristique, idSocieteCaracteristique }) => {
      if (!state.entities[idCampagneSocieteCaracteristique]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampagneSocieteCaracteristique]: {
            ...state.entities[idCampagneSocieteCaracteristique],
            societeCaracteristique: idSocieteCaracteristique
          }
        }
      };
    }
  ),

  on(
    CampagneSocieteCaracteristiqueGeneratedActions.deleteManySocieteCaracteristiqueSuccess,
    (state: CampagneSocieteCaracteristiqueState.IState, { idSocieteCaracteristiques, idCampagneSocieteCaracteristiques }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampagneSocieteCaracteristiques.reduce((entities, idCampagneSocieteCaracteristique) => {
            if (!state.entities[idCampagneSocieteCaracteristique]?.societeCaracteristique) {
              return entities;
            }
            entities[idCampagneSocieteCaracteristique] = {
              ...state.entities[idCampagneSocieteCaracteristique],
              societeCaracteristique: idSocieteCaracteristiques.some(
                (idSocieteCaracteristique: number) => idSocieteCaracteristique === state.entities[idCampagneSocieteCaracteristique]?.societeCaracteristique
              )
                ? undefined
                : state.entities[idCampagneSocieteCaracteristique]?.societeCaracteristique
            } as CampagneSocieteCaracteristiqueEntityState;
            return entities;
          }, {} as Dictionary<CampagneSocieteCaracteristiqueEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: CampagneSocieteCaracteristiqueState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CampagneSocieteCaracteristiqueState.IState {
  return { ...state, isLoaded, isLoading };
}
