import { RepositoryService } from '@get/services/repository';
import { OrganisationNewsletter } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedOrganisationNewsletterApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganisationNewsletters(params?: any): Observable<OrganisationNewsletter[]> {
    return this.repo.getData<OrganisationNewsletter[]>('organisation-newsletter', params);
  }

  public getOrganisationNewsletter(params: { idOrganisationNewsletter: number; params?: any }): Observable<OrganisationNewsletter> {
    return this.repo.getData<OrganisationNewsletter>('organisation-newsletter/' + params.idOrganisationNewsletter, params.params);
  }

  public addOrganisationNewsletter(organisationNewsletter: Partial<OrganisationNewsletter>): Observable<OrganisationNewsletter> {
    return this.repo.create<OrganisationNewsletter>('organisation-newsletter', organisationNewsletter);
  }

  public updateOrganisationNewsletter(organisationNewsletter: Partial<OrganisationNewsletter>): Observable<OrganisationNewsletter> {
    return this.repo.update('organisation-newsletter', organisationNewsletter);
  }

  public deleteOrganisationNewsletter(idOrganisationNewsletter: number): Observable<number> {
    return this.repo.delete('organisation-newsletter/' + +idOrganisationNewsletter);
  }
}

