import { AbstractControl, ValidatorFn } from '@angular/forms';

const passwordLength = 8;
const validator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const str: string = control.value;
    const isStrong = isPasswordStrong(str);
    return isStrong ? null : { password: true };
  };
}

export function isPasswordStrong(password: string): boolean {
  return password?.length >= passwordLength && validator.test(password);
}
