import { RepositoryService } from '@get/services/repository';
import { UserNewsletter } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedUserNewsletterApiService {
  constructor(protected repo: RepositoryService) {}

  public getUserNewsletters(params?: any): Observable<UserNewsletter[]> {
    return this.repo.getData<UserNewsletter[]>('user-newsletter', params);
  }

  public getUserNewsletter(params: { idUserNewsletter: number; params?: any }): Observable<UserNewsletter> {
    return this.repo.getData<UserNewsletter>('user-newsletter/' + params.idUserNewsletter, params.params);
  }

  public addUserNewsletter(userNewsletter: Partial<UserNewsletter>): Observable<UserNewsletter> {
    return this.repo.create<UserNewsletter>('user-newsletter', userNewsletter);
  }

  public updateUserNewsletter(userNewsletter: Partial<UserNewsletter>): Observable<UserNewsletter> {
    return this.repo.update('user-newsletter', userNewsletter);
  }

  public deleteUserNewsletter(idUserNewsletter: number): Observable<number> {
    return this.repo.delete('user-newsletter/' + +idUserNewsletter);
  }
}

