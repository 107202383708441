<div mat-dialog-title [ngClass]="theme">
  <p>{{ title }}</p>
</div>
<div *ngIf="!isQuestionArray" mat-dialog-content>
  <p class="text-padding" [innerHTML]="question"></p>
</div>
<div *ngIf="isQuestionArray" mat-dialog-content>
  <p *ngFor="let q of questions" class="text-padding">{{ q }}</p>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" [mat-dialog-close]>Ok</button>
</div>
