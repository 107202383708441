import { ComposantAttenduReponseEnum } from '@enums';
import {
  ComposantAttendu,
  DynamicType,
  Patrimoine,
  PatrimoineDictWithChildrenCountInterface
} from '@get/api-interfaces';
import { extractNumberFromComposant } from './composants.utils';

export function updatePatrimoineComposantAttendus(
  idPatrimoine: number,
  patrimoinesObj: PatrimoineDictWithChildrenCountInterface
): void {
  const pat = patrimoinesObj[idPatrimoine];
  if (pat && !pat.updated) {
    for (let i = 0; i < pat.descendants.length; i++) {
      const idDescendant = pat.descendants[i].idDescendantPatrimoine;
      updatePatrimoineComposantAttendus(idDescendant, patrimoinesObj);
      if (pat.descendants[i].niveau === 1) {
        const patrimoineDescendant = patrimoinesObj[idDescendant];
        if (patrimoineDescendant) {
          const idsSocieteComposant = Object.keys(patrimoineDescendant.societeComposants);
          for (let j = 0; j < idsSocieteComposant.length; j++) {
            const idSocieteComposant = idsSocieteComposant[j];
            pat.societeComposants[idSocieteComposant] =
              (pat.societeComposants[idSocieteComposant] ?? 0) +
              (patrimoineDescendant.societeComposants[idSocieteComposant] ?? 0);
            if (pat.composantAttendus[idSocieteComposant]) {
              if (patrimoineDescendant.composantAttendus[idSocieteComposant]) {
                if (patrimoineDescendant.composantAttendus[idSocieteComposant]?.valeur) {
                  pat.composantAttendus[idSocieteComposant].valeur =
                    (pat.composantAttendus[idSocieteComposant].valeur ?? 0) +
                    (patrimoineDescendant.composantAttendus[idSocieteComposant]?.valeur ?? 0);
                }
                if (patrimoineDescendant.composantAttendus[idSocieteComposant]?.nbReponsesRempli) {
                  pat.composantAttendus[idSocieteComposant].nbReponsesRempli =
                    (pat.composantAttendus[idSocieteComposant].nbReponsesRempli ?? 0) +
                    (patrimoineDescendant.composantAttendus[idSocieteComposant]?.nbReponsesRempli ?? 0);
                }
                if (patrimoineDescendant.composantAttendus[idSocieteComposant]?.nbReponsesAttendu) {
                  pat.composantAttendus[idSocieteComposant].nbReponsesAttendu =
                    (pat.composantAttendus[idSocieteComposant].nbReponsesAttendu ?? 0) +
                    (patrimoineDescendant.composantAttendus[idSocieteComposant]?.nbReponsesAttendu ?? 0);
                }
              }
            } else {
              pat.composantAttendus[idSocieteComposant] = {
                valeur: patrimoineDescendant.composantAttendus[idSocieteComposant]?.valeur ?? 0,
                nbReponsesRempli: patrimoineDescendant.composantAttendus[idSocieteComposant]?.nbReponsesRempli ?? 0,
                nbReponsesAttendu: patrimoineDescendant.composantAttendus[idSocieteComposant]?.nbReponsesAttendu ?? 0
              };
            }
            if (pat.composantAttendus[idSocieteComposant].nbReponsesRempli !== 0) {
              pat.composantAttendus[idSocieteComposant].calcule = false;
            }
          }
        }
      }
    }
    pat.updated = true;
  }
}

export function turnPatrimoineToSocieteComposantsObj(patrimoine: Patrimoine): DynamicType<number> {
  // TODO: Check pour utiliser Object.assign au lieu de remplir les clés à la main sur les reduce de toute l'app
  const obj =
    patrimoine.societePatrimoineHierarchie?.societeComposantRattachements?.reduce(
      (acc, societeComposantRattachement) => {
        acc[societeComposantRattachement.idSocieteComposant] = 0;
        return acc;
      },
      {} as DynamicType<number>
    ) || {};

  for (let i = 0; i < patrimoine.espaces?.length; i++) {
    const espace = patrimoine.espaces[i];
    for (let j = 0; j < espace.composants?.length; j++) {
      const composant = espace.composants[j];
      obj[composant.idSocieteComposant] = (obj[composant.idSocieteComposant] ?? 0) + 1;
    }
  }

  return obj;
}

/**
 * @example
 * patrimoine
 * --> composantAttendu
 * --> societePatrimoineHierarchie
 * ----> societeComposantRattachements
 * --> espaces
 * ----> composants
 * ------> valeurs
 * --------> societeCaracteristique
 */
export function turnPatrimoineToComposantsAttendusObj(
  patrimoine: Patrimoine,
  idUser: number
): DynamicType<Partial<ComposantAttendu>> {
  // On recompte le nombre de réponses à chaque fois donc on repart de 0
  const composantsAttendusDict =
    patrimoine.composantAttendus?.reduce((accComposantAttendus, composantAttendu) => {
      accComposantAttendus[composantAttendu.idSocieteComposant] = {
        ...composantAttendu,
        // On écrase les réponse à oui par null pour gérer le cas où on enlève le dernier composant d'un patrimoine, la réponse doit être vidée
        reponse:
          composantAttendu.reponse === ComposantAttenduReponseEnum.oui
            ? (null as unknown as ComposantAttenduReponseEnum)
            : composantAttendu.reponse,
        valeur: 0,
        nbReponsesRempli: 0,
        nbReponsesAttendu: 0,
        idUser: idUser
      };
      return accComposantAttendus;
    }, {} as DynamicType<Partial<ComposantAttendu>>) || {};
  // Si jamais le patrimoine est lié au societe composant on met nbReponsesAttendu à 1
  // Si c'est un de ces enfants qui est lié au societe composant on laisse nbReponsesAttendu à 0
  for (let i = 0; i < patrimoine.societePatrimoineHierarchie?.societeComposantRattachements?.length; i++) {
    const societeComposantRattachement = patrimoine.societePatrimoineHierarchie?.societeComposantRattachements[i];
    if (!composantsAttendusDict[societeComposantRattachement.idSocieteComposant]) {
      composantsAttendusDict[societeComposantRattachement.idSocieteComposant] = {
        valeur: 0,
        calcule: true,
        nbReponsesRempli: 0,
        nbReponsesAttendu: 1
      };
    } else {
      const composantAttenduForSocieteComposant =
        composantsAttendusDict[societeComposantRattachement.idSocieteComposant];
      composantAttenduForSocieteComposant.nbReponsesAttendu = 1;
      // Si calcule est false ça veut dire que quelqu'un a répondu "non" ce qui compte comme une réponse
      if (!composantAttenduForSocieteComposant.calcule && composantAttenduForSocieteComposant.reponse !== null) {
        composantAttenduForSocieteComposant.nbReponsesRempli = 1;
      } else if (!composantAttenduForSocieteComposant.calcule && composantAttenduForSocieteComposant.reponse === null) {
        composantAttenduForSocieteComposant.calcule = true;
      }
    }
  }

  for (let i = 0; i < patrimoine.espaces?.length; i++) {
    const espace = patrimoine.espaces[i];
    for (let j = 0; j < espace.composants?.length; j++) {
      const composant = espace.composants[j];
      if (composantsAttendusDict[composant.idSocieteComposant]) {
        const nbToAdd = extractNumberFromComposant(composant);
        composantsAttendusDict[composant.idSocieteComposant].valeur =
          (composantsAttendusDict[composant.idSocieteComposant].valeur ?? 0) + nbToAdd;
        if (
          (composantsAttendusDict[composant.idSocieteComposant].valeur ?? 0) > 0 &&
          composantsAttendusDict[composant.idSocieteComposant].reponse !== ComposantAttenduReponseEnum.oui
        ) {
          composantsAttendusDict[composant.idSocieteComposant].reponse = ComposantAttenduReponseEnum.oui;
        }
        if (
          composantsAttendusDict[composant.idSocieteComposant].nbReponsesRempli !== 1 &&
          composantsAttendusDict[composant.idSocieteComposant].nbReponsesAttendu !== 0
        ) {
          composantsAttendusDict[composant.idSocieteComposant].nbReponsesRempli = 1;
        }
        if (composantsAttendusDict[composant.idSocieteComposant].calcule !== false) {
          composantsAttendusDict[composant.idSocieteComposant].calcule = false;
        }
      }
    }
  }
  return composantsAttendusDict;
}

export function areComposantAttenduEqual(
  composantAttenduA: Partial<ComposantAttendu>,
  composantAttenduB: Partial<ComposantAttendu>
): boolean {
  return (
    composantAttenduA.calcule === composantAttenduB.calcule &&
    composantAttenduA.reponse === composantAttenduB.reponse &&
    composantAttenduA.nbReponsesAttendu === composantAttenduB.nbReponsesAttendu &&
    composantAttenduA.nbReponsesRempli === composantAttenduB.nbReponsesRempli &&
    composantAttenduA.valeur === composantAttenduB.valeur
  );
}
