import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SocieteComposantFamille, SocieteComposantFamilleEntityState } from '@get/api-interfaces';
import { SocieteComposantFamilleRelationsIds } from '@get/store/ids-interfaces';

export const SocieteComposantFamilleGeneratedActions = createActionGroup({
  source: 'Societe Composant Famille Generated',
  events: {
    'Get One Societe Composant Famille': props<{ idSocieteComposantFamille: number; params?: any }>(),
    'Get Many Societe Composant Familles': props<{ params: any }>(),
    'Add Many Actives Societe Composant Familles': props<{ idSocieteComposantFamilles: number[] }>(),
    'Delete One Active Societe Composant Famille': props<{ idSocieteComposantFamille: number }>(),
    'Clear Actives Societe Composant Familles': emptyProps(),
    'Upsert One Societe Composant Famille': props<{ societeComposantFamille: Partial<SocieteComposantFamille>; ids?: SocieteComposantFamilleRelationsIds; }>(),
    'Upsert Many Societe Composant Familles': props<{ societeComposantFamilles: Partial<SocieteComposantFamille>[]; ids?: SocieteComposantFamilleRelationsIds; }>(),
    'Upsert Many Societe Composant Familles Success': props<{ societeComposantFamilles: SocieteComposantFamilleEntityState[] }>(),
    'Delete One Societe Composant Famille': props<{ idSocieteComposantFamille: number }>(),
    'Delete One Societe Composant Famille Success': props<{ idSocieteComposantFamille: number }>(),
    'Normalize Many Societe Composant Familles After Upsert': props<{ societeComposantFamilles: SocieteComposantFamilleEntityState[] }>(),
    'Societe Composant Familles Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Societe Composant Familles': emptyProps(),
    'Add Many Societe Composant Success': props<{ idSocieteComposantFamille: number; idSocieteComposants: number[] }>(),
    'Delete Many Societe Composant Success': props<{ idSocieteComposants: number[]; idSocieteComposantFamilles: number[] }>(),
    'Add Societe Success': props<{ idSocieteComposantFamille: number; idSociete: number }>(),
    'Delete Many Societe Success': props<{ idSocietes: number[]; idSocieteComposantFamilles: number[] }>(),
    'Add Fichier Success': props<{ idSocieteComposantFamille: number; idFichier: number }>(),
    'Delete Many Fichier Success': props<{ idFichiers: number[]; idSocieteComposantFamilles: number[] }>()
  }
});
