import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ComposantAttendu, ComposantAttenduEntityState } from '@get/api-interfaces';
import { ComposantAttenduApiService } from '@get/store/api-services';
import { ComposantAttenduGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeComposantAttendu } from '@get/store/configs/normalization';
import { ComposantAttenduSelectors } from '@get/store/selectors';
import { ComposantAttenduRelationsIds } from '@get/store/ids-interfaces';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { UserGeneratedActions } from '@get/store/actions';

export function getDefaultAddComposantAttenduActions(composantAttendu: ComposantAttenduEntityState, ids?: ComposantAttenduRelationsIds): Action[] {
  const actions: Action[] = [ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({ composantAttendus: [composantAttendu] })];

  if (ids?.societeComposant) {
    actions.push(
      SocieteComposantGeneratedActions.addManyComposantAttenduSuccess({
        idSocieteComposant: ids.societeComposant,
        idComposantAttendus: [composantAttendu.idComposantAttendu]
      })
    );
    actions.push(
      ComposantAttenduGeneratedActions.addSocieteComposantSuccess({
        idComposantAttendu: composantAttendu.idComposantAttendu,
        idSocieteComposant: ids.societeComposant
      })
    );
  }

  if (ids?.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.addManyComposantAttenduSuccess({
        idPatrimoine: ids.patrimoine,
        idComposantAttendus: [composantAttendu.idComposantAttendu]
      })
    );
    actions.push(
      ComposantAttenduGeneratedActions.addPatrimoineSuccess({
        idComposantAttendu: composantAttendu.idComposantAttendu,
        idPatrimoine: ids.patrimoine
      })
    );
  }

  if (ids?.user) {
    actions.push(
      UserGeneratedActions.addManyComposantAttenduSuccess({
        idUser: ids.user,
        idComposantAttendus: [composantAttendu.idComposantAttendu]
      })
    );
    actions.push(
      ComposantAttenduGeneratedActions.addUserSuccess({
        idComposantAttendu: composantAttendu.idComposantAttendu,
        idUser: ids.user
      })
    );
  }

  return actions;
}

export function getDefaultDeleteComposantAttenduActions(composantAttendu: ComposantAttenduEntityState): Action[] {
  const actions: Action[] = [ComposantAttenduGeneratedActions.deleteOneComposantAttenduSuccess({ idComposantAttendu: composantAttendu.idComposantAttendu })];

  if (composantAttendu.societeComposant) {
    actions.push(
      SocieteComposantGeneratedActions.deleteManyComposantAttenduSuccess({
        idComposantAttendus: [composantAttendu.idComposantAttendu],
        idSocieteComposants: [composantAttendu.societeComposant as number]
      })
    );
  }

  if (composantAttendu.patrimoine) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyComposantAttenduSuccess({
        idComposantAttendus: [composantAttendu.idComposantAttendu],
        idPatrimoines: [composantAttendu.patrimoine as number]
      })
    );
  }

  if (composantAttendu.user) {
    actions.push(
      UserGeneratedActions.deleteManyComposantAttenduSuccess({
        idComposantAttendus: [composantAttendu.idComposantAttendu],
        idUsers: [composantAttendu.user as number]
      })
    );
  }

  return actions;
}

export class GeneratedComposantAttenduEffects {
  constructor(
    protected actions$: Actions,
    protected composantAttenduApiService: ComposantAttenduApiService,
    protected store$: Store<AppState>
  ) {}

  getManyComposantAttendus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantAttenduGeneratedActions.getManyComposantAttendus),
      switchMap(({ params }) =>
        this.composantAttenduApiService.getComposantAttendus(params).pipe(
          map((composantAttendus: ComposantAttendu[]) => {
            return ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({ composantAttendus });
          }),
          catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
        )
      )
    );
  });

  getOneComposantAttendu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantAttenduGeneratedActions.getOneComposantAttendu),
      switchMap(idComposantAttendu =>
        this.composantAttenduApiService.getComposantAttendu(idComposantAttendu).pipe(
          map((composantAttendu: ComposantAttendu) => {
            return ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({ composantAttendus: [composantAttendu] });
          }),
          catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
        )
      )
    );
  });

  upsertOneComposantAttendu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantAttenduGeneratedActions.upsertOneComposantAttendu),
      concatMap(
        ({
          composantAttendu,
          ids
        }: {
          composantAttendu: Partial<ComposantAttendu>;
          ids?: ComposantAttenduRelationsIds;
        }) => {
          if (composantAttendu.idComposantAttendu) {
            return this.composantAttenduApiService.updateComposantAttendu(composantAttendu).pipe(
              map((composantAttenduReturned: ComposantAttendu) => {
                return ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({ composantAttendus: [composantAttenduReturned] });
              }),
              catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
            );
          } else {
            return this.composantAttenduApiService.addComposantAttendu(composantAttendu).pipe(
              mergeMap((composantAttenduReturned: ComposantAttendu) => getDefaultAddComposantAttenduActions(composantAttenduReturned, ids)),
              catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneComposantAttendu$ = createEffect(() => {
    const selectComposantAttenduState$ = this.store$.select(ComposantAttenduSelectors.selectComposantAttenduState);
    return this.actions$.pipe(
      ofType(ComposantAttenduGeneratedActions.deleteOneComposantAttendu),
      withLatestFrom(selectComposantAttenduState$),
      concatMap(([{ idComposantAttendu }, state]) =>
        this.composantAttenduApiService.deleteComposantAttendu(idComposantAttendu).pipe(
          mergeMap(_success => getDefaultDeleteComposantAttenduActions(state.entities[idComposantAttendu] as ComposantAttenduEntityState)),
          catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
        )
      )
    );
  });

  normalizeManyComposantAttendusAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert),
      concatMap(({ composantAttendus }) => {
        const actions: Action[] = getActionsToNormalizeComposantAttendu(composantAttendus, StoreActionType.upsert);
        return [getMultiAction(actions, '[ComposantAttendu] Normalization After Upsert Success')];
      })
    );
  });
}
