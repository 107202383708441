import { DynamicType, Patrimoine } from '@get/api-interfaces';
import { PatrimoineDocument } from '@get/interfaces';
import { getDirectAncestor } from '@utils';

export function getPatrimoineTitleWithPrefix(patrimoine: Patrimoine, separator = ' '): string {
  const prefix = patrimoine.libelle
    ?.toLowerCase()
    ?.includes(patrimoine.societePatrimoineHierarchie?.libelle?.toLowerCase());
  return (
    (!prefix ? (patrimoine.societePatrimoineHierarchie?.libelle ?? '') + separator : '') + (patrimoine.libelle ?? '')
  );
}

export function mapPatrimoinesWithUsefulInfos(
  patrimoines: (PatrimoineDocument | Patrimoine)[],
  idPatrimoine?: number,
  keysToOmit: (keyof Patrimoine)[] = ['valeurs', 'valeurPatrimoines']
) {
  return patrimoines.map(patrimoine =>
    +patrimoine.idPatrimoine === idPatrimoine
      ? patrimoine
      : {
          ...patrimoine,
          ...(keysToOmit || []).reduce((acc, curr) => {
            acc[curr as string] = undefined;
            return acc;
          }, {} as DynamicType<undefined>)
        }
  );
}

export function findHighestAncestorWithDescendants(
  patrimoineObj: DynamicType<Patrimoine>,
  idPatrimoine: number
): Patrimoine[] {
  const patrimoine = patrimoineObj[idPatrimoine];
  if (patrimoine) {
    const directAncestor = getDirectAncestor(patrimoine.ancetres);
    if (directAncestor) {
      return findHighestAncestorWithDescendants(patrimoineObj, directAncestor.idAncetrePatrimoine);
    }
    return [patrimoine].concat(patrimoine.descendants?.map(el => patrimoineObj[el.idDescendantPatrimoine]) || []);
  }
  return [];
}
