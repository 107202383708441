import { RepositoryService } from '@get/services/repository';
import { Newsletter } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedNewsletterApiService {
  constructor(protected repo: RepositoryService) {}

  public getNewsletters(params?: any): Observable<Newsletter[]> {
    return this.repo.getData<Newsletter[]>('newsletter', params);
  }

  public getNewsletter(params: { idNewsletter: number; params?: any }): Observable<Newsletter> {
    return this.repo.getData<Newsletter>('newsletter/' + params.idNewsletter, params.params);
  }

  public addNewsletter(newsletter: Partial<Newsletter>): Observable<Newsletter> {
    return this.repo.create<Newsletter>('newsletter', newsletter);
  }

  public updateNewsletter(newsletter: Partial<Newsletter>): Observable<Newsletter> {
    return this.repo.update('newsletter', newsletter);
  }

  public deleteNewsletter(idNewsletter: number): Observable<number> {
    return this.repo.delete('newsletter/' + +idNewsletter);
  }
}

