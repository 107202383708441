import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { CaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueTemplateState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { CaracteristiqueTemplateEntityState } from '@get/api-interfaces';

export const caracteristiqueTemplateReducersGeneratedFunctions: ReducerTypes<CaracteristiqueTemplateState.IState, readonly ActionCreator[]>[] = [
  on(CaracteristiqueTemplateGeneratedActions.getOneCaracteristiqueTemplate, (state: CaracteristiqueTemplateState.IState) => setLoadingsState(state, true)),
  on(CaracteristiqueTemplateGeneratedActions.getManyCaracteristiqueTemplates, (state: CaracteristiqueTemplateState.IState) => setLoadingsState(state, true)),
  on(CaracteristiqueTemplateGeneratedActions.upsertOneCaracteristiqueTemplate, (state: CaracteristiqueTemplateState.IState) => setLoadingsState(state, true)),

  on(CaracteristiqueTemplateGeneratedActions.upsertManyCaracteristiqueTemplatesSuccess, (state: CaracteristiqueTemplateState.IState, { caracteristiqueTemplates }) =>
    CaracteristiqueTemplateState.adapter.upsertMany(caracteristiqueTemplates, setLoadingsState(state, false))
  ),
  on(CaracteristiqueTemplateGeneratedActions.deleteOneCaracteristiqueTemplate, (state: CaracteristiqueTemplateState.IState) => setLoadingsState(state, true)),
  on(CaracteristiqueTemplateGeneratedActions.deleteOneCaracteristiqueTemplateSuccess, (state: CaracteristiqueTemplateState.IState, { idCaracteristiqueTemplate }) =>
    CaracteristiqueTemplateState.adapter.removeOne(idCaracteristiqueTemplate, setLoadingsState(state, false))
  ),
  on(CaracteristiqueTemplateGeneratedActions.clearActivesCaracteristiqueTemplates, (state: CaracteristiqueTemplateState.IState) => ({ ...state, actives: [] })),
  on(CaracteristiqueTemplateGeneratedActions.addManyActivesCaracteristiqueTemplates, (state: CaracteristiqueTemplateState.IState, { idCaracteristiqueTemplates }) => ({
    ...state,
    actives: state.actives.concat(idCaracteristiqueTemplates)
  })),
  on(CaracteristiqueTemplateGeneratedActions.deleteOneActiveCaracteristiqueTemplate, (state: CaracteristiqueTemplateState.IState, { idCaracteristiqueTemplate }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCaracteristiqueTemplate)
  })),

  on(CaracteristiqueTemplateGeneratedActions.clearCaracteristiqueTemplates, () => CaracteristiqueTemplateState.initialState),

  on(
    CaracteristiqueTemplateGeneratedActions.addManySocieteCaracteristiqueSuccess,
    (state: CaracteristiqueTemplateState.IState, { idCaracteristiqueTemplate, idSocieteCaracteristiques }) => {
      if (!state.entities[idCaracteristiqueTemplate]) {
        return state;
      }
      const societeCaracteristiques = (state.entities[idCaracteristiqueTemplate]?.societeCaracteristiques as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCaracteristiqueTemplate]: {
            ...state.entities[idCaracteristiqueTemplate],
            societeCaracteristiques: societeCaracteristiques.concat(
              idSocieteCaracteristiques.filter(id => societeCaracteristiques.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CaracteristiqueTemplateGeneratedActions.deleteManySocieteCaracteristiqueSuccess,
    (state: CaracteristiqueTemplateState.IState, { idSocieteCaracteristiques, idCaracteristiqueTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCaracteristiqueTemplates.reduce((entities, idCaracteristiqueTemplate) => {
            if (!state.entities[idCaracteristiqueTemplate]?.societeCaracteristiques) {
              return entities;
            }
            entities[idCaracteristiqueTemplate] = {
              ...state.entities[idCaracteristiqueTemplate],
              societeCaracteristiques: (state.entities[idCaracteristiqueTemplate]?.societeCaracteristiques as number[])?.filter(
                (idSocieteCaracteristique: number) => !idSocieteCaracteristiques.some((id: number) => id === idSocieteCaracteristique)
              )
            } as CaracteristiqueTemplateEntityState;
            return entities;
          }, {} as Dictionary<CaracteristiqueTemplateEntityState>)
        }
      };
    }
  ),

  on(
    CaracteristiqueTemplateGeneratedActions.addManyCaracteristiqueChoixTemplateSuccess,
    (state: CaracteristiqueTemplateState.IState, { idCaracteristiqueTemplate, idCaracteristiqueChoixTemplates }) => {
      if (!state.entities[idCaracteristiqueTemplate]) {
        return state;
      }
      const caracteristiqueChoixTemplates = (state.entities[idCaracteristiqueTemplate]?.caracteristiqueChoixTemplates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCaracteristiqueTemplate]: {
            ...state.entities[idCaracteristiqueTemplate],
            caracteristiqueChoixTemplates: caracteristiqueChoixTemplates.concat(
              idCaracteristiqueChoixTemplates.filter(id => caracteristiqueChoixTemplates.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CaracteristiqueTemplateGeneratedActions.deleteManyCaracteristiqueChoixTemplateSuccess,
    (state: CaracteristiqueTemplateState.IState, { idCaracteristiqueChoixTemplates, idCaracteristiqueTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCaracteristiqueTemplates.reduce((entities, idCaracteristiqueTemplate) => {
            if (!state.entities[idCaracteristiqueTemplate]?.caracteristiqueChoixTemplates) {
              return entities;
            }
            entities[idCaracteristiqueTemplate] = {
              ...state.entities[idCaracteristiqueTemplate],
              caracteristiqueChoixTemplates: (state.entities[idCaracteristiqueTemplate]?.caracteristiqueChoixTemplates as number[])?.filter(
                (idCaracteristiqueChoixTemplate: number) => !idCaracteristiqueChoixTemplates.some((id: number) => id === idCaracteristiqueChoixTemplate)
              )
            } as CaracteristiqueTemplateEntityState;
            return entities;
          }, {} as Dictionary<CaracteristiqueTemplateEntityState>)
        }
      };
    }
  ),

  on(
    CaracteristiqueTemplateGeneratedActions.addComposantTemplateSuccess,
    (state: CaracteristiqueTemplateState.IState, { idCaracteristiqueTemplate, idComposantTemplate }) => {
      if (!state.entities[idCaracteristiqueTemplate]) {
        return state;
      };
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCaracteristiqueTemplate]: {
            ...state.entities[idCaracteristiqueTemplate],
            composantTemplate: idComposantTemplate
          }
        }
      };
    }
  ),

  on(
    CaracteristiqueTemplateGeneratedActions.deleteManyComposantTemplateSuccess,
    (state: CaracteristiqueTemplateState.IState, { idComposantTemplates, idCaracteristiqueTemplates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCaracteristiqueTemplates.reduce((entities, idCaracteristiqueTemplate) => {
            if (!state.entities[idCaracteristiqueTemplate]?.composantTemplate) {
              return entities;
            }
            entities[idCaracteristiqueTemplate] = {
              ...state.entities[idCaracteristiqueTemplate],
              composantTemplate: idComposantTemplates.some(
                (idComposantTemplate: number) => idComposantTemplate === state.entities[idCaracteristiqueTemplate]?.composantTemplate
              )
                ? undefined
                : state.entities[idCaracteristiqueTemplate]?.composantTemplate
            } as CaracteristiqueTemplateEntityState;
            return entities;
          }, {} as Dictionary<CaracteristiqueTemplateEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: CaracteristiqueTemplateState.IState,
  isLoading: boolean,
  isLoaded: boolean = true
): CaracteristiqueTemplateState.IState {
  return { ...state, isLoaded, isLoading };
}
