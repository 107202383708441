import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { ComposantTemplate, ComposantTemplateEntityState } from '@get/api-interfaces';
import { ComposantTemplateApiService } from '@get/store/api-services';
import { ComposantTemplateGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeComposantTemplate } from '@get/store/configs/normalization';
import { ComposantTemplateSelectors } from '@get/store/selectors';
import { ComposantTemplateRelationsIds } from '@get/store/ids-interfaces';
import { FichierGeneratedActions } from '@get/store/actions';
import { ComposantGroupeGeneratedActions } from '@get/store/actions';
import { CaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueTemplate } from '@get/api-interfaces';
import { SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocieteComposant } from '@get/api-interfaces';

export function getDefaultAddComposantTemplateActions(composantTemplate: ComposantTemplateEntityState, ids?: ComposantTemplateRelationsIds): Action[] {
  const actions: Action[] = [ComposantTemplateGeneratedActions.normalizeManyComposantTemplatesAfterUpsert({ composantTemplates: [composantTemplate] })];

  if (ids?.fichier) {
    actions.push(
      FichierGeneratedActions.addManyComposantTemplateSuccess({
        idFichier: ids.fichier,
        idComposantTemplates: [composantTemplate.idComposantTemplate]
      })
    );
    actions.push(
      ComposantTemplateGeneratedActions.addFichierSuccess({
        idComposantTemplate: composantTemplate.idComposantTemplate,
        idFichier: ids.fichier
      })
    );
  }

  if (ids?.composantGroupe) {
    actions.push(
      ComposantGroupeGeneratedActions.addManyComposantTemplateSuccess({
        idComposantGroupe: ids.composantGroupe,
        idComposantTemplates: [composantTemplate.idComposantTemplate]
      })
    );
    actions.push(
      ComposantTemplateGeneratedActions.addComposantGroupeSuccess({
        idComposantTemplate: composantTemplate.idComposantTemplate,
        idComposantGroupe: ids.composantGroupe
      })
    );
  }

  if (ids?.caracteristiqueTemplates) {
    if (!Array.isArray(ids.caracteristiqueTemplates)) {
      actions.push(
        CaracteristiqueTemplateGeneratedActions.upsertOneCaracteristiqueTemplate({
          caracteristiqueTemplate: {
            idComposantTemplate: composantTemplate.idComposantTemplate,
            idCaracteristiqueTemplate: ids.caracteristiqueTemplates as number
          } as CaracteristiqueTemplate
        })
      );
      actions.push(
        ComposantTemplateGeneratedActions.addManyCaracteristiqueTemplateSuccess({
          idComposantTemplate: composantTemplate.idComposantTemplate,
          idCaracteristiqueTemplates: [ids.caracteristiqueTemplates as number]
        })
      );
    } else {
      actions.push(
        CaracteristiqueTemplateGeneratedActions.upsertManyCaracteristiqueTemplates({
          caracteristiqueTemplates: (ids.caracteristiqueTemplates as number[]).map(
            (idCaracteristiqueTemplate: number) => ({
              idComposantTemplate: composantTemplate.idComposantTemplate,
              idCaracteristiqueTemplate
            })
          ) as CaracteristiqueTemplate[]
        })
      );
      actions.push(
        ComposantTemplateGeneratedActions.addManyCaracteristiqueTemplateSuccess({
          idComposantTemplate: composantTemplate.idComposantTemplate,
          idCaracteristiqueTemplates: ids.caracteristiqueTemplates as number[]
        })
      );
    }
  }

  if (ids?.societeComposants) {
    if (!Array.isArray(ids.societeComposants)) {
      actions.push(
        SocieteComposantGeneratedActions.upsertOneSocieteComposant({
          societeComposant: {
            idComposantTemplate: composantTemplate.idComposantTemplate,
            idSocieteComposant: ids.societeComposants as number
          } as SocieteComposant
        })
      );
      actions.push(
        ComposantTemplateGeneratedActions.addManySocieteComposantSuccess({
          idComposantTemplate: composantTemplate.idComposantTemplate,
          idSocieteComposants: [ids.societeComposants as number]
        })
      );
    } else {
      actions.push(
        SocieteComposantGeneratedActions.upsertManySocieteComposants({
          societeComposants: (ids.societeComposants as number[]).map(
            (idSocieteComposant: number) => ({
              idComposantTemplate: composantTemplate.idComposantTemplate,
              idSocieteComposant
            })
          ) as SocieteComposant[]
        })
      );
      actions.push(
        ComposantTemplateGeneratedActions.addManySocieteComposantSuccess({
          idComposantTemplate: composantTemplate.idComposantTemplate,
          idSocieteComposants: ids.societeComposants as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteComposantTemplateActions(composantTemplate: ComposantTemplateEntityState): Action[] {
  const actions: Action[] = [ComposantTemplateGeneratedActions.deleteOneComposantTemplateSuccess({ idComposantTemplate: composantTemplate.idComposantTemplate })];

  if (composantTemplate.fichier) {
    actions.push(
      FichierGeneratedActions.deleteManyComposantTemplateSuccess({
        idComposantTemplates: [composantTemplate.idComposantTemplate],
        idFichiers: [composantTemplate.fichier as number]
      })
    );
  }

  if (composantTemplate.composantGroupe) {
    actions.push(
      ComposantGroupeGeneratedActions.deleteManyComposantTemplateSuccess({
        idComposantTemplates: [composantTemplate.idComposantTemplate],
        idComposantGroupes: [composantTemplate.composantGroupe as number]
      })
    );
  }

  if (composantTemplate.caracteristiqueTemplates) {
    actions.push(
      CaracteristiqueTemplateGeneratedActions.deleteManyComposantTemplateSuccess({
        idComposantTemplates: [composantTemplate.idComposantTemplate],
        idCaracteristiqueTemplates: composantTemplate.caracteristiqueTemplates as number[]
      })
    );
  }

  if (composantTemplate.societeComposants) {
    actions.push(
      SocieteComposantGeneratedActions.deleteManyComposantTemplateSuccess({
        idComposantTemplates: [composantTemplate.idComposantTemplate],
        idSocieteComposants: composantTemplate.societeComposants as number[]
      })
    );
  }

  return actions;
}

export class GeneratedComposantTemplateEffects {
  constructor(
    protected actions$: Actions,
    protected composantTemplateApiService: ComposantTemplateApiService,
    protected store$: Store<AppState>
  ) {}

  getManyComposantTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantTemplateGeneratedActions.getManyComposantTemplates),
      switchMap(({ params }) =>
        this.composantTemplateApiService.getComposantTemplates(params).pipe(
          map((composantTemplates: ComposantTemplate[]) => {
            return ComposantTemplateGeneratedActions.normalizeManyComposantTemplatesAfterUpsert({ composantTemplates });
          }),
          catchError(error => of(ComposantTemplateGeneratedActions.composantTemplatesFailure({ error })))
        )
      )
    );
  });

  getOneComposantTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantTemplateGeneratedActions.getOneComposantTemplate),
      switchMap(idComposantTemplate =>
        this.composantTemplateApiService.getComposantTemplate(idComposantTemplate).pipe(
          map((composantTemplate: ComposantTemplate) => {
            return ComposantTemplateGeneratedActions.normalizeManyComposantTemplatesAfterUpsert({ composantTemplates: [composantTemplate] });
          }),
          catchError(error => of(ComposantTemplateGeneratedActions.composantTemplatesFailure({ error })))
        )
      )
    );
  });

  upsertOneComposantTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantTemplateGeneratedActions.upsertOneComposantTemplate),
      concatMap(
        ({
          composantTemplate,
          ids
        }: {
          composantTemplate: Partial<ComposantTemplate>;
          ids?: ComposantTemplateRelationsIds;
        }) => {
          if (composantTemplate.idComposantTemplate) {
            return this.composantTemplateApiService.updateComposantTemplate(composantTemplate).pipe(
              map((composantTemplateReturned: ComposantTemplate) => {
                return ComposantTemplateGeneratedActions.normalizeManyComposantTemplatesAfterUpsert({ composantTemplates: [composantTemplateReturned] });
              }),
              catchError(error => of(ComposantTemplateGeneratedActions.composantTemplatesFailure({ error })))
            );
          } else {
            return this.composantTemplateApiService.addComposantTemplate(composantTemplate).pipe(
              mergeMap((composantTemplateReturned: ComposantTemplate) => getDefaultAddComposantTemplateActions(composantTemplateReturned, ids)),
              catchError(error => of(ComposantTemplateGeneratedActions.composantTemplatesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneComposantTemplate$ = createEffect(() => {
    const selectComposantTemplateState$ = this.store$.select(ComposantTemplateSelectors.selectComposantTemplateState);
    return this.actions$.pipe(
      ofType(ComposantTemplateGeneratedActions.deleteOneComposantTemplate),
      withLatestFrom(selectComposantTemplateState$),
      concatMap(([{ idComposantTemplate }, state]) =>
        this.composantTemplateApiService.deleteComposantTemplate(idComposantTemplate).pipe(
          mergeMap(_success => getDefaultDeleteComposantTemplateActions(state.entities[idComposantTemplate] as ComposantTemplateEntityState)),
          catchError(error => of(ComposantTemplateGeneratedActions.composantTemplatesFailure({ error })))
        )
      )
    );
  });

  normalizeManyComposantTemplatesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ComposantTemplateGeneratedActions.normalizeManyComposantTemplatesAfterUpsert),
      concatMap(({ composantTemplates }) => {
        const actions: Action[] = getActionsToNormalizeComposantTemplate(composantTemplates, StoreActionType.upsert);
        return [getMultiAction(actions, '[ComposantTemplate] Normalization After Upsert Success')];
      })
    );
  });
}
